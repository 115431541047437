import React, { useContext, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { action } from 'typesafe-actions';
import useTranslation from 'helpers/useTranslation';

import FileUploadContext from './module/FileUploadContext';
import * as FileUploadReducer from './module/FileUploadReducer';
import { FileUploadProps, getClassName, SHOWN_ERRORS } from './FileUpload';
import { warning } from 'services/toastr';

const FileUploadZone: React.FC<FileUploadProps> = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const { dispatch } = useContext(FileUploadContext);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: (acceptedFiles) => {
      dispatch(action(FileUploadReducer.FILES_ADDED, acceptedFiles));
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map((rejection) =>
        rejection.errors
          .filter((error) => SHOWN_ERRORS.includes(error.code))
          .map((error) =>
            warning(tHtml('validation.file_upload.' + error.code, { file: rejection.file.name })),
          ),
      );
    },
    ...props,
  });

  const className = useMemo(
    () => getClassName(isFocused, isDragAccept, isDragReject, props.disabled),
    [isFocused, isDragAccept, isDragReject, props.disabled],
  );

  return (
    <>
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
        <div>
          <i className={'icon-upload'} />
          {tHtml('placeholder.file_upload.drag_and_drop')}
        </div>
        <div className={'splitter'}>{tHtml('placeholder.file_upload.or')}</div>
        <div>
          <a>{tHtml('placeholder.file_upload.open_file_browser')}</a>
        </div>
      </div>
    </>
  );
};

export default FileUploadZone;
