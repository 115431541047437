import Api from 'api';
import { ExternalMediaResponseDto } from 'api/types/common';
import { responseToCsv } from '../helpers/utils';

export function openMedia(url: string): Promise<ExternalMediaResponseDto> {
  return Api.media.fetchExternal(url).then((response) => {
    window.open(response.url, '_blank');
    return response;
  });
}

export function downloadCSVFile(url: string, name: string): Promise<any> {
  return Api.media.fetchExternal(url).then((response) => {
    fetch(response.url)
      .then((response) => response.blob())
      .then((blob) => {
        responseToCsv(blob, `${name}.csv`);
      });

    return response;
  });
}

export function downloadXLSXFile(url: string, name: string): Promise<any> {
  return Api.media.fetchExternal(url).then((response) => {
    fetch(response.url)
      .then((response) => response.blob())
      .then((blob) => {
        responseToCsv(blob, `${name}.xlsx`);
      });

    return response;
  });
}

export function downloadXMLFile(url: string): Promise<any> {
  return Api.media.fetchExternal(url).then((response) => {
    fetch(response.url)
      .then((response) => response.blob())
      .then((blob) => {
        responseToCsv(blob, 'operations.xml');
      });

    return response;
  });
}
