import { AvailableCountriesEnum } from 'helpers/enums/AvailableCountriesEnum';
import { InvestmentPurposeEnum } from 'helpers/enums/InvestmentPurposeEnum';
import { ProjectInvestmentTypeEnum } from 'helpers/enums/ProjectInvestmentTypeEnum';
import { RatingEnum } from 'helpers/enums/RatingEnum';
import { RiskCategoryEnum } from 'helpers/enums/RiskCategoryEnum';
import { SecurityMeasuresEnum } from 'helpers/enums/SecurityMeasuresEnum';

export type InvestorInvestmentListResponseDto = {
  id: string;
  project_id: string;
  investment_purpose: string | null;
  project_pid: string;
  project_name: string;
  is_project_funded: string;
  investment_type: ProjectInvestmentTypeEnum;
  investment_amount: number;
  invested_at: string;
  confirmed_at: string;
  finished_at: string;
  is_refundable: boolean;
  credit_duration: number;
  total_interest: number;
  bonus_system: BonusSystemResponseDto;
  interest_received: number | null;
  interest_returned: number | null;
  fine: number | null;
  total: number | null;
  investment_balance: number | null;
  sellable: boolean;
  sub_rows: InvestorInvestmentListResponseDto[];
  subRows?: InvestorInvestmentListResponseDto[];
};

export type InvestorLateInvestmentListResponseDto = {
  id: string;
  project_name: string | null;
  project_id: string;
  investment_type: ProjectInvestmentTypeEnum;
  investment_amount: number;
  invested_at: string;
  confirmed_at: string;
  finished_at: string;
  is_refundable: boolean;
  credit_duration: number;
  total_interest: number;
  bonus_system: BonusSystemResponseDto;
  interest_received: number | null;
  interest_returned: number | null;
  fine: number | null;
  total: number | null;
  investment_balance: number | null;
  late_at: string;
  sellable: boolean;
  sub_rows: InvestorLateInvestmentListResponseDto[];
  subRows?: InvestorLateInvestmentListResponseDto[];
};

export type InvestorOtherInvestmentListResponseDto = {
  id: string;
  project_name: string | null;
  project_id: string;
  investment_type: ProjectInvestmentTypeEnum;
  investment_amount: number;
  invested_at: string;
  confirmed_at: string;
  finished_at: string;
  is_refundable: boolean;
  credit_duration: number;
  total_interest: number;
  bonus_system: BonusSystemResponseDto;
  interest_received: number | null;
  interest_returned: number | null;
  fine: number | null;
  total: number | null;
  investment_balance: number | null;
  comment: string;
  sellable: boolean;
  sub_rows: InvestorOtherInvestmentListResponseDto[];
  subRows?: InvestorOtherInvestmentListResponseDto[];
};

export enum InvestorAutoInvestmentConfigPackEnum {
  BASIC_PACK = 'basic_pack',
  FIRST_PACK = 'first_pack',
  SECOND_PACK = 'second_pack',
}

export enum InvestorAutoInvestmentConfigCriteriaEnum {
  INTEREST_FROM = 'interest_from',
  INTEREST_TO = 'interest_to',
  CREDIT_DURATION_FROM = 'credit_duration_from',
  CREDIT_DURATION_TO = 'credit_duration_to',
  CAN_INVEST_LESS = 'can_invest_less',
  COUNTRY = 'country',
  RATING_PROFITUS = 'rating_profitus',
  INVESTMENT_PURPOSE = 'investment_purpose',
  SECURITY_MEASURES = 'security_measures',
}

export interface InvestorAutoInvestmentConfigI {
  min_amount: number;
  pack_name: string;
  criteria: {
    [key in InvestorAutoInvestmentConfigCriteriaEnum | string]: boolean;
  };
}

export type InvestorAutoInvestmentFullConfig = {
  [pack in InvestorAutoInvestmentConfigPackEnum]: InvestorAutoInvestmentConfigI;
};

export type InvestorAutoInvestmentUpdateStatusRequestDto = {
  is_active: boolean;
};

export type InvestorAutoInvestmentResponseDto = {
  disabled_at: string | null;
  max_share_long_term: number | null;
  max_share_per_borrower: number | null;
  strategies: InvestorAutoStrategy[];
};

export type AutoInvestPrediction = {
  estimated_investment_amount: number;
  estimated_project_count: number;
  real_project_count: number;
};

export type AutoStrategyAvgAnnualInterest = { active_average_annual_interest: number | null };
export type AutoStrategyAvgWeightedRating = { average_weighted_rating: number | null };
export type AutoStrategyChatData = {
  by_rating: ChartData;
  by_country: ChartData;
  by_loan_status: ChartData;
};

export type ChartData = {
  [key: string]: {
    investment_count: number;
    investment_total_amount: number;
    investment_active_amount: number;
  };
};

export type InvestorAutoStrategy = Omit<
  StoreStrategyDto,
  'max_share_long_term' | 'max_share_per_borrower'
> & { id: string; activated_at: string };

export type StoreStrategyDto = {
  amount: number | undefined;
  can_invest_less: boolean | null;
  name: string;
  credit_duration_from: number;
  credit_duration_to: number;
  interest_from: number;
  interest_to: number;
  max_share_long_term: number | null;
  max_share_per_borrower: number | null;
  is_max_share_per_borrower: string;
  risk_category_to: RiskCategoryEnum | null;
  country: AvailableCountriesEnum[];
  investment_purpose: InvestmentPurposeEnum[];
  rating_profitus: RatingEnum[];
  security_measures: SecurityMeasuresEnum[];
  agreement: boolean;
};

type InvestmentDocumentFile = {
  name: string;
  url: string;
};

export type InvestmentDocumentResponseDto = {
  id: string;
  project_id: string;
  project_name: string;
  investment_purpose: string | null;
  project_pid: string;
  invested_at: string;
  investment_conditions: InvestmentDocumentFile | null;
  loan_agreement: InvestmentDocumentFile | null;
  payment_schedule: InvestmentDocumentFile | null;
  other_documents: InvestmentDocumentFile[] | null;
  subRows: InvestmentDocumentResponseDto[];
};

export type BonusSystemResponseDto = {
  bonus_code_amount_applied: boolean;
  individual_bonus_code_amount_applied: boolean;
  bonus_code_percentage_applied: boolean;
  individual_bonus_code_percentage_applied: boolean;
};

export interface RefundedReasonI {
  refunded_reason: string | null;
}
