import React, { ReactElement, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import { DocumentResponse } from 'api/types/company/document';
import { IconArrowDown, IconArrowUp } from 'components/Icons';
import DownloadButtonAuthorized from 'components/DownloadButtonAuthorized';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';

interface Props {
  title: string | ReactElement;
  documents: DocumentResponse[];
  isProject?: boolean;
  projectId?: string;
  isBlockOpen?: boolean;
}

const DocumentsBlock: React.FC<Props> = ({
  title,
  documents,
  isProject = false,
  projectId = null,
  isBlockOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isBlockOpen);
  const { tHtml } = useTranslation();
  return (
    <div>
      <div className={'mb-6'}>
        <h2 className={'mb-2'}>
          {isProject && projectId ? (
            <ProjectTitleLink title={title} projectId={projectId} isTitleHighlighted={false} />
          ) : (
            title
          )}
        </h2>
        <div className={'documents-block'}>
          <div className={'documents-block__header'} onClick={() => setIsOpen(!isOpen)}>
            <span className={'documents-block__header-text'}>
              {tHtml('common.active_documents')} ({documents.length})
            </span>
            {isOpen ? (
              <IconArrowUp classes={'icon-dropdown'} />
            ) : (
              <IconArrowDown classes={'icon-dropdown'} />
            )}
          </div>
          <Collapse isOpen={isOpen}>
            <div className={'documents-block__body'}>
              <Row>
                {documents.map((document: DocumentResponse, idx: number) => (
                  <DownloadButtonAuthorized url={document.url} key={idx}>
                    <Col lg="3" md="4" sm="6" className={'documents-block__body-item'}>
                      <div className={'download-icon'}>
                        <i className="icon icon-download-file" />
                      </div>
                      <div>
                        <h6 className={'mb-0'}>{document.name}</h6>
                      </div>
                    </Col>
                  </DownloadButtonAuthorized>
                ))}
              </Row>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default DocumentsBlock;
