import React from 'react';
import { useAutoInvestmentData, withAutoInvestmentData } from '../AutoInvestmentProvider';
import { SectionConfigs } from './SectionConfigs';
import SectionAutoInvestorInformation from './SectionAutoInvestorInformation';
import { StrategyList } from './StrategyList';
import SectionConfigsSkeleton from '../skeleton/SectionConfigsSkeleton';
import SectionAutoInvestorInformationSkeleton from '../skeleton/SectionAutoInvestorInformationSkeleton';
import StrategyListSkeleton from '../skeleton/StrategyListSkeleton';

const AutoInvestmentSections: React.FC = () => {
  const { isLoading } = useAutoInvestmentData();

  return (
    <div className="d-flex flex-column">
      {isLoading ? <SectionAutoInvestorInformationSkeleton /> : <SectionAutoInvestorInformation />}
      {isLoading ? <SectionConfigsSkeleton /> : <SectionConfigs />}
      {isLoading ? <StrategyListSkeleton /> : <StrategyList />}
    </div>
  );
};

export default withAutoInvestmentData(AutoInvestmentSections);
