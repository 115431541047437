import React from 'react';
import useUserType from '../../../helpers/useUserType';
import InvestorPageLayout from '../../investor/containers/PageLayout';
import DeveloperPageLayout from '../../developer/containers/PageLayout';
import GuestPageLayout from '../../guest/containers/GuestPageLayout';

export const SharedLayoutWrapper: React.FC = ({ children }) => {
  const userType = useUserType();

  if (userType.isInvestor()) {
    return <InvestorPageLayout>{children}</InvestorPageLayout>;
  }

  if (userType.isDeveloper()) {
    return <DeveloperPageLayout>{children}</DeveloperPageLayout>;
  }

  if (userType.isGuest()) {
    return <GuestPageLayout>{children}</GuestPageLayout>;
  }

  return <></>;
};

export default SharedLayoutWrapper;
