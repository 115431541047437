import React from 'react';
import { GenericMediaResponseDto } from 'api/types/common';
import { ApplicationState } from '../../module/ApplicationReducer';
import useTranslation from 'helpers/useTranslation';

interface DocumentsReviewProps {
  state: ApplicationState;
}

const DocumentsReview = ({ state }: DocumentsReviewProps) => {
  const { tHtml } = useTranslation();

  return (
    <>
      <div className="data-block">
        <div className="title review-data-single">{tHtml('application.documents')}</div>
        <div className="text review-data-single">
          {state.application?.documents?.documents?.length === 0 && <span>-</span>}
          <ol>
            {state.application?.documents?.documents?.map((document: GenericMediaResponseDto) => (
              <li key={document.id}>{document.name}</li>
            ))}
          </ol>
        </div>
      </div>

      <div className="data-block">
        <div className="title review-data-single">{tHtml('application.pictures')}</div>
        <div className="text review-data-single">
          {state.application?.documents?.pictures?.length === 0 && <span>-</span>}
          <ol>
            {state.application?.documents?.pictures?.map((document: GenericMediaResponseDto) => (
              <li key={document.id}>{document.name}</li>
            ))}
          </ol>
        </div>
      </div>
    </>
  );
};

export default DocumentsReview;
