import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container } from 'reactstrap';

const InvestorQuizSkeleton: React.FC = () => (
  <>
    <Container className={'main-content d-block mx-auto px-2 px-sm-4 px-md-6'}>
      <Skeleton height={40} className={'mb-6'} />
      <Skeleton height={200} className={'mb-6'} />
      <Skeleton height={250} className={'mb-6'} />
      <Skeleton height={250} className={'mb-6'} />
      <Skeleton height={250} className={'mb-6'} />
      <Skeleton height={250} className={'mb-6'} />
    </Container>
  </>
);

export default InvestorQuizSkeleton;
