import React, { ReactElement, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import { CompanyRepresentativeTitleEnum } from 'scopes/user/helpers/enums/CompanyRepresentativeTitleEnum';
import SelectInput, { SelectOption } from '../../SelectInput';

interface Props {
  name: string;
  placeholder: string | ReactElement;
  isDisabled?: boolean;
}

const SelectRepresentativeTitleInput: React.FC<Props> = ({
  name,
  placeholder,
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  const [options] = useState<SelectOption[]>([
    {
      label: t('placeholder.company.proxy'),
      value: CompanyRepresentativeTitleEnum.PROXY,
    },
    {
      label: t('placeholder.company.board_member'),
      value: CompanyRepresentativeTitleEnum.BOARD_MEMBER,
    },
    {
      label: t('placeholder.company.director'),
      value: CompanyRepresentativeTitleEnum.DIRECTOR,
    },
    {
      label: t('placeholder.other'),
      value: CompanyRepresentativeTitleEnum.OTHER,
    },
  ]);

  return (
    <SelectInput isDisabled={isDisabled} name={name} options={options} placeholder={placeholder} />
  );
};

export default SelectRepresentativeTitleInput;
