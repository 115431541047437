import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import { StackedBarChart } from 'components/StackedBarChart';
import { StackedBarChartColumnProps } from 'components/StackedBarChart/StackedBarChart';
import { InvestorDashboardStatisticsCommonResponseDto } from 'api/types/investor/dashboard';
import { RatingEnum } from 'helpers/enums/RatingEnum';

interface Props {
  ratingsData: { [key: string]: InvestorDashboardStatisticsCommonResponseDto };
  height?: number | string;
}

const InvestorPortfolioStackedBarChart: React.FC<Props> = ({ ratingsData, height }) => {
  const { t } = useTranslation();
  const [ratingDataArr, setRatingDataArr] = useState<
    (InvestorDashboardStatisticsCommonResponseDto & { name: string; share?: number })[]
  >([]);

  useEffect(() => {
    setRatingDataArr(
      Object.keys(ratingsData).map((key) => {
        return { name: key, ...ratingsData[key] };
      }),
    );
  }, [setRatingDataArr, ratingsData]);

  const columns: StackedBarChartColumnProps[] = [
    {
      key: 'AAA',
      fill: 'var(--profitus-ranking-A-bg-color)',
    },
    {
      key: 'AA+',
      fill: 'var(--profitus-ranking-A-bg-color)',
    },
    {
      key: 'AA',
      fill: 'var(--profitus-ranking-A-bg-color)',
    },
    {
      key: 'AA-',
      fill: 'var(--profitus-ranking-B-bg-color)',
    },
    {
      key: 'A+',
      fill: 'var(--profitus-ranking-B-bg-color)',
    },
    {
      key: 'A',
      fill: 'var(--profitus-ranking-B-bg-color)',
    },
    {
      key: 'A-',
      fill: 'var(--profitus-ranking-C-bg-color)',
    },
    {
      key: 'BBB+',
      fill: 'var(--profitus-ranking-C-bg-color)',
    },
    {
      key: 'BBB',
      fill: 'var(--profitus-ranking-C-bg-color)',
    },
    {
      key: 'BBB-',
      fill: 'var(--profitus-ranking-D-bg-color)',
    },
    {
      key: 'default',
      fill: 'var(--profitus-ranking-color)',
    },
  ];

  const sortedData = React.useMemo(() => {
    const clonedData = [...ratingDataArr];
    const ratingOrder = Object.values(RatingEnum);
    clonedData.sort(
      (a, b) => ratingOrder.indexOf(a.name as any) - ratingOrder.indexOf(b.name as any),
    );
    return clonedData.map((item) => {
      const status = item.name;
      return {
        name: item.name,
        [status]: item.investment_count,
      };
    });
  }, [ratingDataArr]);

  return (
    <StackedBarChart
      data={sortedData}
      showLegend={false}
      legendTitle={t('chart.initial_rating')}
      columns={columns}
      height={height}
    />
  );
};

export default InvestorPortfolioStackedBarChart;
