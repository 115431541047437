import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import Tooltip from 'components/Tooltip';
import WithdrawButton from 'components/Wallet/WithdrawButton';
import TopUpButton from 'components/Wallet/TopUpButton';
import WalletAmount from 'components/Wallet/WalletAmount';

const TopUpWithdraw: React.FC = () => {
  const { tHtml } = useTranslation();

  return (
    <>
      <div className={'page-layout h-100 wallet'}>
        <div className={'panel mb-0 h-100'}>
          <Card body className={'light-pink balance py-2 py-md-4 px-1 px-md-3'}>
            <Row className={'balance-items'}>
              <Col xl={7} className={'balance-information'}>
                <WalletAmount />
                <div className={'balance-description'}>
                  {tHtml('wallet.account_balance')}
                  <i className={'icon icon-info position-absolute'} id={'account-balance-icon'} />
                </div>
                <Tooltip
                  id={'account-balance-icon'}
                  text={tHtml('dashboard.investor.tooltip.account_balance')}
                />
              </Col>
              <Col xl={5}>
                <div className={'d-flex flex-center gap-2 py-3'}>
                  <WithdrawButton className={'w-100'} />
                  <TopUpButton className={'w-100'} />
                </div>
                <div className="text-muted text-muted-important small text-right">
                  {tHtml('wallet.investor_top_up_withdraw_note')}
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </>
  );
};

export default TopUpWithdraw;
