import { ValueOfObject } from 'types/generics';

const jwtStorageKey = 'jt';
const permissionStorageKey = 'p';

type JwtStorageKey = typeof jwtStorageKey;

const authEvent = {
  login: 'login',
  refresh: 'refresh',
  logout: 'logout',
  permissionsChanged: 'permissionsChanged',
} as const;

type AuthEvent = ValueOfObject<typeof authEvent>;

interface JwtInterface {
  user_id: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  prv: string;
  sub: string;
  company_id: string | undefined;
  original_id: string | undefined;
  type: 'guest' | 'investor' | 'project_developer' | null;
}

type AuthJwtObject = {
  token: string;
  expires: number;
};

export type { JwtStorageKey, JwtInterface, AuthJwtObject, AuthEvent };
export { authEvent, jwtStorageKey, permissionStorageKey };
