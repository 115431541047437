import { FacebookSocialNetwork } from './facebook';
import { GoogleSocialNetwork } from './google';

const socialMap = {
  facebook: FacebookSocialNetwork,
  google: GoogleSocialNetwork,
};
type Keys = keyof typeof socialMap;
type socialTypes = typeof socialMap[Keys];
type ExtractInstanceType<T> = T extends new () => infer R ? R : never;

export class SocialNetworkFactory {
  // private static cache: ExtractInstanceType<socialTypes>[];

  static get(key: string): ExtractInstanceType<socialTypes> {
    // TODO add caching
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return new socialMap[key]();
  }

  static setupAll(): void {
    this.get('facebook').setup();
    this.get('google').setup();
  }

  static setup(key: string): void {
    this.get(key).setup();
  }
}
