import styled from 'styled-components';

interface StyledTrProps {
  isSelected?: boolean;
}

export const StyledTd = styled.td`
  && {
    width: calc(100% / 2);
    height: 0;
    padding: 2px;
    vertical-align: middle;
  }
`;

export const StyledTr = styled.tr<StyledTrProps>`
  background-color: ${(props) =>
    props.isSelected ? 'var(--profitus-table-thead-cell-active-bg-color)' : 'inherit'};
  color: ${(props) =>
    props.isSelected ? 'var(--profitus-table-thead-cell-active-color)' : 'inherit'};
`;
export const StyledTh = styled.th<StyledTrProps>`
  && {
    background-color: inherit;
    padding: 1px;
  }
`;
