import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import * as Yup from 'yup';
import { Form } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';

import Api from 'api';
import { ConfirmPasswordResetRequestDto } from 'api/types/guest/authenticate';
import { RouteList } from 'routes';
import { success } from 'services/toastr';
import TextInput from 'components/Formik/TextInput';
import PrimaryButton from 'components/Formik/PrimaryButton';
import { setGlobalLoading } from 'modules/app/actions';

const ConfirmResetPassword: React.FC<RouteComponentProps> = ({ history }) => {
  const { t, tHtml } = useTranslation();
  const { token } = useParams<{ token: string }>();

  const ConfirmResetPasswordSchema = Yup.object().shape({
    password: Yup.string().required(),
    password_confirmation: Yup.string()
      .required()
      .test('passwords-match', t('validation.passwords-match'), function (value) {
        return this.parent.password === value;
      }),
  });

  const [confirmResetPasswordRequest] = useState<ConfirmPasswordResetRequestDto>({
    password: '',
    password_confirmation: '',
    token: token,
  });

  const onSubmit = useCallback(
    async (
      request: ConfirmPasswordResetRequestDto,
      helpers: FormikHelpers<ConfirmPasswordResetRequestDto>,
    ) => {
      try {
        const response = await Api.guest.auth.confirmPasswordReset(request);

        if (response) {
          success(tHtml('authentication.password_changed_successfully'));
          history.push(RouteList.GUEST.AUTHENTICATION.LOGIN);
        }
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [history, tHtml],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <div className={'top-title d-flex flex-row'}>
        <h4 className={'me-auto mb-0 align-self-center'}>
          {tHtml('authentication.change_password')}
        </h4>
      </div>
      <Formik
        initialValues={confirmResetPasswordRequest}
        validationSchema={ConfirmResetPasswordSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={'mb-4'}>
              <TextInput name="password" type="password" autoComplete="on" />
            </div>
            <div className={'mb-4'}>
              <TextInput name="password_confirmation" type="password" autoComplete="on" />
            </div>
            <div className={'mt-6'}>
              <PrimaryButton submitting={isSubmitting}>{tHtml('common.submit')}</PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withRouter(ConfirmResetPassword);
