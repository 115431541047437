// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as Yup from 'yup';
import Lazy from 'yup/lib/Lazy';
import { AnyObject, Maybe, Optionals } from 'yup/lib/types';
import BaseSchema, { AnySchema } from 'yup/lib/schema';
import { Asserts, TypeOf } from 'yup/lib/util/types';
import i18n from 'i18next';
import { isValidBIC, isValidIBAN } from 'ibantools';

import {
  BeneficiaryValidationSchema,
  BeneficiaryValidationSchemaStrict,
} from 'scopes/user/pages/CreateCompany/ValidationSchemas';
import { CompanyRepresentativeTitleEnum } from 'scopes/user/helpers/enums/CompanyRepresentativeTitleEnum';
import { GovernmentDocumentEnum } from 'helpers/enums/GovernmentDocumentEnum';
import { CompanyManagementStructureEnum } from 'helpers/enums/CompanyManagementStructureEnum';
import { CountryDialCodeEnum } from 'helpers/enums/CountryDialCodeEnum';
import Api from 'api';
import ShareholderValidationSchema from '../../scopes/user/pages/CreateCompany/ValidationSchemas/ShareholderValidationSchema';

declare module 'yup' {
  interface StringSchema {
    id(): StringSchema;
    password(): StringSchema;
    governmentCode(field: string): StringSchema;
    passwordMatch(field: string): StringSchema;
    confirmationCode(): StringSchema;
    firstName(): StringSchema;
    middleName(): StringSchema;
    lastName(): StringSchema;
    fullName(): StringSchema;
    phone(): StringSchema;
    gender(): StringSchema;
    nationality(): StringSchema;
    companyLegalCode(): StringSchema;
    companyRepresentativeTitle(): StringSchema;
    city(): StringSchema;
    addressLine(): StringSchema;
    companyVatCode(): StringSchema;
    companyName(): StringSchema;
    residence(): StringSchema;
    governmentDocument(): StringSchema;
    companyManagementStructure(): StringSchema;
    maxLength(): StringSchema;
    maxLengthLongText(): StringSchema;
    iban(): StringSchema;
    swift(): StringSchema;
  }
  interface NumberSchema {
    money(): NumberSchema;
    greaterThanField(field: string, fieldTranslation: string): NumberSchema;
    greaterThanOrEqualField(field: string, fieldTranslation: string): NumberSchema;
    lessThanField(field: string, fieldTranslation: string): NumberSchema;
    lessThanOrEqualField(field: string, fieldTranslation: string): NumberSchema;
  }
  interface ArraySchema<
    T extends AnySchema | Lazy<any, any>,
    C extends AnyObject = AnyObject,
    TIn extends Maybe<TypeOf<T>[]> = TypeOf<T>[] | undefined,
    TOut extends Maybe<Asserts<T>[]> = Asserts<T>[] | Optionals<TIn>,
  > extends BaseSchema<TIn, C, TOut> {
    shareholderArray(): ArraySchema<any>;
    beneficiaryArray(): ArraySchema<any>;
    pepArray(): ArraySchema<any>;
  }
}

const rPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).+$/;
const rDigits = /^[0-9]*$/;
const rLtGovCodeStart = /^[3456]/;

Yup.addMethod(Yup.string, 'id', function (): any {
  return Yup.string().min(1).max(20);
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buildYupValidations = () => {
  Yup.addMethod(Yup.string, 'residence', function (): any {
    return Yup.string().oneOf([], i18n.t('validation.invalid'));
  });

  Api.countries.fetchCountries().then((countries) => {
    Yup.addMethod(Yup.string, 'residence', function (): any {
      return Yup.string().oneOf(
        countries.primary_countries.concat(countries.secondary_countries),
        i18n.t('validation.invalid'),
      );
    });
  });

  Yup.addMethod(Yup.string, 'password', function (): any {
    return Yup.string()
      .matches(rPassword, {
        name: 'password',
        message: i18n.t('validation.passwords-invalid'),
        excludeEmptyString: true,
      })
      .min(8)
      .max(124);
  });

  Yup.addMethod(Yup.string, 'passwordMatch', function (matchingVield): any {
    return Yup.string().test(
      'passwords-match',
      i18n.t('validation.passwords-match'),
      function (value) {
        return this.parent[matchingVield] === value;
      },
    );
  });

  Yup.addMethod(Yup.string, 'confirmationCode', function (): any {
    return Yup.string()
      .matches(rDigits, {
        message: i18n.t('validation.invalid'),
        excludeEmptyString: true,
      })
      .length(6);
  });

  Yup.addMethod(Yup.string, 'firstName', function (): any {
    return Yup.string().min(2).max(30);
  });

  Yup.addMethod(Yup.string, 'middleName', function (): any {
    return Yup.string().min(2).max(30);
  });

  Yup.addMethod(Yup.string, 'lastName', function (): any {
    return Yup.string().min(2).max(50);
  });

  Yup.addMethod(Yup.string, 'phone', function (): any {
    return Yup.string().when({
      is: (val: string | undefined) => val && val.startsWith(CountryDialCodeEnum.LT),
      then: (schema) => schema.length(11),
      otherwise: (schema) => schema.min(8).max(21),
    });
  });

  Yup.addMethod(Yup.string, 'governmentCode', function (residence): any {
    return Yup.string().when(residence, {
      is: 'lt',
      then: Yup.string()
        .length(11)
        .matches(rDigits, {
          message: i18n.t('validation.invalid'),
          excludeEmptyString: true,
        })
        .matches(rLtGovCodeStart, {
          name: 'startsWith',
          message: i18n.t('validation.government_code.must_start', { symbols: '3, 4, 5, 6' }),
          excludeEmptyString: true,
        }),
      otherwise: Yup.string().min(6).max(20),
    });
  });

  Yup.addMethod(Yup.string, 'gender', function (): any {
    return Yup.string().min(1).max(30);
  });

  Yup.addMethod(Yup.string, 'nationality', function (): any {
    return Yup.string().min(1).max(50);
  });

  Yup.addMethod(Yup.string, 'companyLegalCode', function (): any {
    return Yup.string().max(20);
  });

  Yup.addMethod(Yup.string, 'companyRepresentativeTitle', function (): any {
    return Yup.string().oneOf([
      CompanyRepresentativeTitleEnum.PROXY,
      CompanyRepresentativeTitleEnum.BOARD_MEMBER,
      CompanyRepresentativeTitleEnum.DIRECTOR,
      CompanyRepresentativeTitleEnum.OTHER,
    ]);
  });

  Yup.addMethod(Yup.string, 'city', function (): any {
    return Yup.string().min(1).max(85);
  });

  Yup.addMethod(Yup.string, 'addressLine', function (): any {
    return Yup.string().min(1).max(100);
  });

  Yup.addMethod(Yup.string, 'companyVatCode', function (): any {
    return Yup.string().min(1).max(15);
  });

  Yup.addMethod(Yup.string, 'companyName', function (): any {
    return Yup.string().min(1).max(150);
  });

  Yup.addMethod(Yup.string, 'governmentDocument', function (): any {
    return Yup.string().oneOf([
      GovernmentDocumentEnum.IdentificationCard,
      GovernmentDocumentEnum.IdCard,
      GovernmentDocumentEnum.Passport,
      GovernmentDocumentEnum.DriverLicense,
      GovernmentDocumentEnum.ResidencePermit,
      GovernmentDocumentEnum.InternalPassport,
      GovernmentDocumentEnum.SocialId,
    ]);
  });

  Yup.addMethod(Yup.string, 'fullName', (): any => {
    return Yup.string().min(1).max(80);
  });

  Yup.addMethod(Yup.string, 'companyManagementStructure', (): any => {
    return Yup.string().oneOf([
      CompanyManagementStructureEnum.Director,
      CompanyManagementStructureEnum.Board,
      CompanyManagementStructureEnum.SupervisorsBoard,
      CompanyManagementStructureEnum.ShareholderMeeting,
    ]);
  });

  Yup.addMethod(Yup.string, 'maxLength', (): any => {
    return Yup.string().min(1).max(255);
  });

  Yup.addMethod(Yup.string, 'maxLengthLongText', (): any => {
    return Yup.string().min(1).max(2047);
  });

  Yup.addMethod(Yup.number, 'money', (): any => {
    return Yup.number().min(1.0).max(9999999999);
  });

  Yup.addMethod(Yup.array, 'shareholderArray', (): any => {
    return Yup.array().when('has_shareholders_with_more_than_25_percent', {
      is: true,
      then: Yup.array().min(1).of(ShareholderValidationSchema()),
      otherwise: Yup.array(),
    });
  });

  Yup.addMethod(Yup.array, 'beneficiaryArray', (): any => {
    // return Yup.array() // todo update?
    //   .min(1)
    //   .max(5)
    //   .of(BeneficiaryValidationSchema())
    //   .test('shareSumTest', i18n.t('validation.shares_sum_validation'), function (values) {
    //     return values?.reduce((acc, curr) => acc + curr.shares, 0) <= 100;
    //   });
    return Yup.array().when('has_shareholders_with_more_than_25_percent', {
      is: true,
      then: Yup.array().min(1).of(BeneficiaryValidationSchemaStrict()),
      otherwise: Yup.array().min(1).of(BeneficiaryValidationSchema()),
    });
  });

  Yup.addMethod(Yup.array, 'pepArray', (): any => {
    return Yup.array().when('public_positions_held_by_related_individuals', {
      is: true,
      then: Yup.array()
        .min(1)
        .of(
          Yup.object().shape({
            name: Yup.string().fullName().required(),
            gov_code: Yup.string().min(6).max(20).required(),
            responsibilities: Yup.string().max(255).required(),
            relation_with_manager_or_representative: Yup.string().required(),
          }),
        ),
      otherwise: Yup.array(),
    });
  });

  Yup.addMethod(Yup.string, 'iban', (): any => {
    return Yup.string()
      .min(15)
      .max(34)
      .test('validateIban', i18n.t('validation.invalid'), function (value) {
        return isValidIBAN(value ?? '');
      });
  });

  Yup.addMethod(Yup.string, 'swift', (): any => {
    return Yup.string()
      .min(8)
      .max(11)
      .test('validateSwift', i18n.t('validation.invalid'), function (value) {
        return isValidBIC(value ?? '');
      });
  });

  Yup.addMethod(
    Yup.number,
    'greaterThanField',
    function (anotherField, anotherFieldTranslation): any {
      return Yup.number().test(
        'greater-than-field',
        i18n.t('validation.greater_than_field', { field: anotherFieldTranslation }),
        function (value) {
          if (value) return this.parent[anotherField] < value;
          return true;
        },
      );
    },
  );

  Yup.addMethod(
    Yup.number,
    'greaterThanOrEqualField',
    function (anotherField, anotherFieldTranslation): any {
      return Yup.number().test(
        'greater-than-or-equal-field',
        i18n.t('validation.greater_than_equal_field', { field: anotherFieldTranslation }),
        function (value) {
          if (value) return this.parent[anotherField] <= value;
          return true;
        },
      );
    },
  );

  Yup.addMethod(Yup.number, 'lessThanField', function (anotherField, anotherFieldTranslation): any {
    return Yup.number().test(
      'less-than-field',
      i18n.t('validation.less_than_field', { field: anotherFieldTranslation }),
      function (value) {
        if (value) return this.parent[anotherField] > value;
        return true;
      },
    );
  });

  Yup.addMethod(
    Yup.number,
    'lessThanOrEqualField',
    function (anotherField, anotherFieldTranslation): any {
      return Yup.number().test(
        'less-than-or-equal-field',
        i18n.t('validation.less_than_equal_field', { field: anotherFieldTranslation }),
        function (value) {
          if (value) return this.parent[anotherField] >= value;
          return true;
        },
      );
    },
  );
};
