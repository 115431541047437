import React from 'react';
import useTranslation from 'helpers/useTranslation';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';
import DataBlockBetween from 'components/DataBlock/DataBlockBetween/DataBlockBetween';

type Props = SecondaryMarketInvestmentContextState;

const ProjectTopData: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  const { tHtml } = useTranslation();

  if (!secondaryMarketInvestment) return null;

  return (
    <>
      <div className={'mb-5'}>
        <DataBlockBetween
          title={tHtml('secondary_market.project.buy_investment.loan_left_amount')}
          content={tHtml('common.money', { value: secondaryMarketInvestment.loan_left_amount })}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.buy_investment.term')}
          content={tHtml('common.days_interval', {
            passed: secondaryMarketInvestment.term.passed,
            total: secondaryMarketInvestment.term.total,
          })}
          tooltipId={'term'}
          tooltipText={tHtml('secondary_market.tooltip.term')}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.buy_investment.interest_rate_offer')}
          content={tHtml('common.percents', { value: secondaryMarketInvestment.basic_interest })}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.buy_investment.status')}
          content={tHtml(`secondary_market.status.${secondaryMarketInvestment.status}`)}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.buy_investment.receivable_amount_left')}
          content={tHtml('common.money', {
            value: secondaryMarketInvestment.receivable_amount_left,
          })}
          tooltipId={'receivable_amount_left'}
          tooltipText={tHtml('secondary_market.tooltip.receivable_amount_left')}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.buy_investment.sale_price')}
          content={tHtml('common.money', { value: secondaryMarketInvestment.sale_price })}
        />
      </div>
    </>
  );
};

export default withSecondaryMarketInvestment(ProjectTopData);
