import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import InvestorPortfolioStackedBarChart from './InvestorPortfolio/InvestorPortfolioStackedBarChart';
import InvestorPortfolioByProject from './InvestorPortfolio/InvestorPortfolioByProject';
import InvestorPortfolioByCompany from './InvestorPortfolio/InvestorPortfolioByCompany';
import InvestorPortfolioByCountry from './InvestorPortfolio/InvestorPortfolioByCountry';
import { InvestorDashboardStatisticsResponseDto } from 'api/types/investor/dashboard';
import PortfolioStatisticsSkeleton from './InvestorPortfolio/PortfolioStatisticsSkeleton';
import DataNotFoundBlock from 'components/DataNotFoundBlock';
import Api from 'api';
import { emptyObject } from 'helpers/utils';
import InvestorPortfolioByInvestmentPurpose from './InvestorPortfolio/InvestorPortfolioByInvestmentPurpose';
import ActiveInvestmentsMobileTable from './ActiveInvestmentsMobileTable';
import useDisplay from 'helpers/useDisplay';

const PortfolioStatistics: React.FC = () => {
  const { tHtml, currentLanguage } = useTranslation();
  const { isMobile } = useDisplay();

  const [isHistoricDataSelected, setIsHistoricDataSelected] = useState<boolean>(false);
  const [displayData, setDisplayData] = useState<InvestorDashboardStatisticsResponseDto | null>(
    null,
  );
  const [historicData, setHistoricData] = useState<InvestorDashboardStatisticsResponseDto | null>(
    null,
  );
  const [activeData, setActiveData] = useState<InvestorDashboardStatisticsResponseDto | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    Api.investor.dashboard.fetchActiveInvestmentPortfolioStatistics().then((response) => {
      setActiveData(response);
      setDisplayData(response);
      setIsLoading(false);

      if (emptyObject(response)) {
        setIsHistoricDataSelected(true);
      } else {
        setIsHistoricDataSelected(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isHistoricDataSelected && !historicData) {
      setIsLoading(true);
      Api.investor.dashboard.fetchHistoricInvestmentPortfolioStatistics().then((response) => {
        setHistoricData(response);
        setDisplayData(response);
        setIsLoading(false);
      });
    } else if (isHistoricDataSelected) {
      setDisplayData(historicData);
    } else {
      setDisplayData(activeData);
    }
  }, [isHistoricDataSelected, activeData, historicData]);

  useEffect(() => {
    setIsLoading(true);

    Promise.all([
      Api.investor.dashboard.fetchActiveInvestmentPortfolioStatistics(),
      Api.investor.dashboard.fetchHistoricInvestmentPortfolioStatistics(),
    ]).then(([activeResponse, historicResponse]) => {
      setActiveData(activeResponse);
      setHistoricData(historicResponse);

      setIsLoading(false);
    });
  }, [currentLanguage]);

  const amountColumn = 'investment_total_amount';

  return (
    <div className={'mb-6 statistics-section'}>
      <div className={'page-layout'}>
        <div className={'d-flex justify-content-between align-items-center flex-wrap'}>
          <h2 className={'m-0 py-2 pe-2'}>{tHtml('dashboard.portfolio.statistics')}</h2>
          <div className={'d-flex align-items-center flex-nowrap tab-container'}>
            <div className={'d-flex tabs'}>
              <button
                disabled={emptyObject(activeData)}
                className={`me-1 me-md-2 filter-button ${!isHistoricDataSelected ? 'active' : ''} ${
                  emptyObject(activeData) ? 'filter-button-disabled' : ''
                }`}
                onClick={() => setIsHistoricDataSelected(false)}
              >
                {tHtml('dashboard.portfolio.active')}
              </button>
              <button
                className={`filter-button ${isHistoricDataSelected ? 'active' : ''}`}
                onClick={() => setIsHistoricDataSelected(true)}
              >
                {tHtml('dashboard.portfolio.historic')}
              </button>
            </div>
          </div>
        </div>
        <div className="panel mobile mt-4 p-2 pb-4  p-lg-5">
          {isLoading && <PortfolioStatisticsSkeleton />}
          {!isLoading && emptyObject(displayData ?? {}) && (
            <DataNotFoundBlock
              label={tHtml('dashboard.portfolio.statistics.investments_not_found')}
            />
          )}

          {!isLoading && !isHistoricDataSelected && isMobile && (
            <Col xs={12} className={'pb-4'}>
              <h3 className={'title'}>{tHtml('dashboard.active_investments')}</h3>
              <ActiveInvestmentsMobileTable />
            </Col>
          )}

          {!isLoading && displayData && !emptyObject(displayData) && (
            <div>
              <Row key={isHistoricDataSelected ? 'historic_data_table' : 'active_data_table'}>
                {!emptyObject(displayData.by_name) && (
                  <Col xs={12} className={'pb-4'}>
                    <h3 className={'title'}>{tHtml('dashboard.portfolio.statistics.project')}</h3>
                    <InvestorPortfolioByProject
                      projectsData={displayData.by_name}
                      amountColumn={amountColumn}
                    />
                  </Col>
                )}
                {!emptyObject(displayData.by_investment_purpose) && (
                  <Col xs={12} lg={6} className={'py-4'}>
                    <h3 className={'title'}>
                      {tHtml('dashboard.portfolio.statistics.investment_purpose')}
                    </h3>
                    <InvestorPortfolioByInvestmentPurpose
                      investmentTypesData={displayData.by_investment_purpose}
                      amountColumn={amountColumn}
                    />
                  </Col>
                )}
                {!emptyObject(displayData.by_owner) && (
                  <Col xs={12} lg={6} className={'py-4'}>
                    <h3 className={'title'}>{tHtml('dashboard.portfolio.statistics.company')}</h3>
                    <InvestorPortfolioByCompany
                      companiesData={displayData.by_owner}
                      amountColumn={amountColumn}
                    />
                  </Col>
                )}
                <Col xs={12} lg={6} className={'py-4'}>
                  <h3 className={'title'}>
                    {isHistoricDataSelected
                      ? tHtml('dashboard.portfolio.statistics.initial_rating')
                      : tHtml('dashboard.portfolio.statistics.actual_rating')}
                  </h3>
                  <InvestorPortfolioStackedBarChart ratingsData={displayData.by_rating} />
                </Col>
                {!emptyObject(displayData.by_country) && (
                  <Col xs={12} lg={6} className={'py-4'}>
                    <h3 className={'title'}>{tHtml('dashboard.portfolio.statistics.country')}</h3>
                    <InvestorPortfolioByCountry
                      countryData={displayData.by_country}
                      amountColumn={amountColumn}
                    />
                  </Col>
                )}
              </Row>
              {/* TODO PRO-582 - We need design for excel. */}
              {/*<hr />*/}
              {/*<Row>*/}
              {/*  <Col>*/}
              {/*    <span className={'ms-3'}>{tHtml('dashboard.portfolio.download')}</span>*/}
              {/*    <div>*/}
              {/*      <div className={'download-button mt-3'}>*/}
              {/*        <DashboardDownloadLink*/}
              {/*          icon={'icon-download-file'}*/}
              {/*          url={''}*/}
              {/*          name={''}*/}
              {/*          type={'excel'}*/}
              {/*          size={''}*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioStatistics;
