import * as Yup from 'yup';
import { CompanyRepresentativeTitleEnum } from 'scopes/user/helpers/enums/CompanyRepresentativeTitleEnum';

export const isRepresentativeExecutive = (
  is_representative_executive: boolean,
  representative_title: string,
) =>
  representative_title !== CompanyRepresentativeTitleEnum.DIRECTOR && !is_representative_executive;

export const CompanyValidationSchema = (
  isCompanySizeRequired: boolean | null,
  isVerified: boolean | null,
): any => {
  let rules: any = {
    vat_code: Yup.string().companyVatCode().nullable(),
    email: Yup.string().email().required(),
    phone: Yup.string().phone().required(),
    bank_name: Yup.string().maxLength().required(),
    iban: Yup.string().iban().required(),
    management_structure: Yup.string().companyManagementStructure().required(),
    representative_title: Yup.string().companyRepresentativeTitle().required(),
    is_representative_executive: Yup.boolean().required(),
    size: isCompanySizeRequired ? Yup.string().required() : Yup.string().nullable(),
    residential_address_street: Yup.string().addressLine().required(),
    residential_address_address: Yup.string().addressLine().required(),
    residential_address_city: Yup.string().city().required(),
    contact_address_street: Yup.string().when('is_contact_address_match_residential', {
      is: false,
      then: Yup.string().addressLine().required(),
    }),
    contact_address_address: Yup.string().when('is_contact_address_match_residential', {
      is: false,
      then: Yup.string().addressLine().required(),
    }),
    contact_address_city: Yup.string().when('is_contact_address_match_residential', {
      is: false,
      then: Yup.string().city().required(),
    }),
    executive_phone: Yup.string().when(['is_representative_executive', 'representative_title'], {
      is: isRepresentativeExecutive,
      then: Yup.string().phone().required(),
      otherwise: Yup.string().phone().nullable(),
    }),
    executive_email: Yup.string().when(['is_representative_executive', 'representative_title'], {
      is: isRepresentativeExecutive,
      then: Yup.string().email().required(),
      otherwise: Yup.string().email().nullable(),
    }),
    executive_address_house_number: Yup.string().when(
      ['is_representative_executive', 'representative_title'],
      {
        is: isRepresentativeExecutive,
        then: Yup.string().addressLine().required(),
        otherwise: Yup.string().addressLine().nullable(),
      },
    ),
    executive_address_street: Yup.string().when(
      ['is_representative_executive', 'representative_title'],
      {
        is: isRepresentativeExecutive,
        then: Yup.string().addressLine().required(),
        otherwise: Yup.string().addressLine().nullable(),
      },
    ),
    executive_address_city: Yup.string().when(
      ['is_representative_executive', 'representative_title'],
      {
        is: isRepresentativeExecutive,
        then: Yup.string().addressLine().required(),
        otherwise: Yup.string().addressLine().nullable(),
      },
    ),
    executive_tax_country: Yup.string().when(
      ['is_representative_executive', 'representative_title'],
      {
        is: isRepresentativeExecutive,
        then: Yup.string().residence().required(),
        otherwise: Yup.string().residence().nullable(),
      },
    ),
    executive_nationality: Yup.string().when(
      ['is_representative_executive', 'representative_title'],
      {
        is: isRepresentativeExecutive,
        then: Yup.string().residence().required(),
        otherwise: Yup.string().residence().nullable(),
      },
    ),
  };

  if (!isVerified) {
    rules = {
      ...rules,
      name: Yup.string().companyName().required(),
      legal_code: Yup.string().companyLegalCode().required(),
      residence: Yup.string().residence().required(),
      established_at: Yup.date().required(),
      executive_first_name: Yup.string().when(
        ['is_representative_executive', 'representative_title'],
        {
          is: isRepresentativeExecutive,
          then: Yup.string().firstName().required(),
          otherwise: Yup.string().firstName().nullable(),
        },
      ),
      executive_last_name: Yup.string().when(
        ['is_representative_executive', 'representative_title'],
        {
          is: isRepresentativeExecutive,
          then: Yup.string().lastName().required(),
          otherwise: Yup.string().lastName().nullable(),
        },
      ),
      executive_address_country: Yup.string().when(
        ['is_representative_executive', 'representative_title'],
        {
          is: isRepresentativeExecutive,
          then: Yup.string().residence().required(),
          otherwise: Yup.string().residence().nullable(),
        },
      ),
      executive_gov_code: Yup.string().when(
        ['is_representative_executive', 'representative_title'],
        {
          is: isRepresentativeExecutive,
          then: Yup.string().governmentCode('executive_gov_document_issue_country').required(),
          otherwise: Yup.string().governmentCode('executive_gov_document_issue_country').nullable(),
        },
      ),
      executive_gov_document_type: Yup.string().when(
        ['is_representative_executive', 'representative_title'],
        {
          is: isRepresentativeExecutive,
          then: Yup.string().governmentDocument().required(),
          otherwise: Yup.string().governmentDocument().nullable(),
        },
      ),
      executive_gov_document_number: Yup.string().when(
        ['is_representative_executive', 'representative_title'],
        {
          is: isRepresentativeExecutive,
          then: Yup.string().min(1).max(30).required(),
          otherwise: Yup.string().min(1).max(30).nullable(),
        },
      ),
      executive_gov_document_issue_country: Yup.string().when(
        ['is_representative_executive', 'representative_title'],
        {
          is: isRepresentativeExecutive,
          then: Yup.string().residence().required(),
          otherwise: Yup.string().residence().nullable(),
        },
      ),
      executive_gov_document_expiration_date: Yup.date().when(
        ['is_representative_executive', 'representative_title'],
        {
          is: isRepresentativeExecutive,
          then: Yup.date().required(),
          otherwise: Yup.date().nullable(),
        },
      ),
    };
  } else {
    rules = {
      ...rules,
      name: Yup.string().companyName().nullable(),
      legal_code: Yup.string().companyLegalCode().nullable(),
      residence: Yup.string().residence().nullable(),
      established_at: Yup.date().nullable(),
      executive_first_name: Yup.string().firstName().nullable(),
      executive_last_name: Yup.string().lastName().nullable(),
      executive_address_country: Yup.string().residence().nullable(),
      executive_gov_code: Yup.string()
        .governmentCode('executive_gov_document_issue_country')
        .nullable(),
      executive_gov_document_type: Yup.string().governmentDocument().nullable(),
      executive_gov_document_number: Yup.string().min(1).max(30).nullable(),
      executive_gov_document_issue_country: Yup.string().residence().nullable(),
      executive_gov_document_expiration_date: Yup.date().nullable(),
    };
  }

  return Yup.object().shape(rules);
};
