import React from 'react';
import AuthRoute from 'routes/AuthRoute';
import { GuestPageLayout } from 'scopes/guest/containers';
import ProjectWrapper from '.';
import { RouteList } from 'routes';
import * as Roles from 'helpers/auth/roles';

const ProjectListRoutes: any = () => [
  <AuthRoute
    path={RouteList.GUEST.PROJECTS.VIEW}
    component={ProjectWrapper}
    layout={GuestPageLayout}
    permissions={Roles.All.permissions}
    key={RouteList.GUEST.PROJECTS.VIEW}
    exact
  />,
];

export default ProjectListRoutes;
