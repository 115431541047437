import { create } from 'zustand';
import {
  StepsModalStateStoreActionsObject,
  StepsModalStateStoreActions,
} from './ResultLanguage.types';

type Store = StepsModalStateStoreActionsObject & StepsModalStateStoreActions;

const useStepsModalStateStore = create<Store>((set) => {
  return {
    shouldBeShown: true,
    setShouldBeShown: ({ state }) => set(() => ({ shouldBeShown: state })),
    reset: () => set({ shouldBeShown: true }),
  };
});

export { useStepsModalStateStore };
