import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const StyledBottomNavigation = styled.div`
  width: 100%;
  bottom: 0;
  position: fixed;
  z-index: 99;
  background-color: var(--profitus-bg-color-2);
`;

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  width: 25%;
  font-size: 9px;
  color: var(--profitus-bottom-navigation-link);
  padding: 10px 0px 10px;

  i {
    background: var(--profitus-bottom-navigation-link);
  }

  &.active {
    color: var(--profitus-bottom-navigation-active);

    i {
      background: var(--profitus-bottom-navigation-active);
    }
  }
`;

export const StyledDisabledLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  width: 25%;
  font-size: 9px;
  color: var(--profitus-bottom-navigation-link);
  padding: 10px 0px 10px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;

    i {
      background: var(--profitus-bottom-navigation-link);
    }
  }
`;
