import React from 'react';

import * as Roles from 'helpers/auth/roles';
import { RouteList } from 'routes';
import PageLayout from 'scopes/investor/containers/PageLayout';
import Documents from '.';
import AuthRoute from 'routes/AuthRoute';

const DocumentRoutes: any = () => [
  <AuthRoute
    path={RouteList.INVESTOR.DOCUMENTS.LIST}
    component={Documents}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.INVESTOR.DOCUMENTS.LIST}
    exact
  />,
];

export default DocumentRoutes;
