import { defaultOptions, UseQueryOptionsObject } from '../queries.utils';
import { useMakeQuery } from '../useMakeQuery';
import { queries } from '../index';
import Api from '../../index';
import { UserProfileResponseDto } from '../../types/shared/user/profile';
import useScope from 'helpers/useScope';

const useUserProfileQuery = (options?: UseQueryOptionsObject<UserProfileResponseDto>) => {
  const { isUser } = useScope();

  return useMakeQuery<UserProfileResponseDto>(
    [queries.profile],
    () => Api.shared.user.profile.fetchUserProfile(),
    {
      staleTime: 120 * 1000,
      enabled: isUser(),
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useUserProfileQuery };
