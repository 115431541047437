import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { PrimaryButton } from '../Button';

interface Props {
  title: string | ReactElement;
  buttonLink: string;
  buttonText?: string | ReactElement;
  description?: string | ReactElement;
}

const NotFilledProfileSection: React.FC<Props> = ({
  title,
  buttonLink,
  buttonText,
  description,
}) => {
  const { tHtml } = useTranslation();

  return (
    <div className={'d-flex justify-content-between flex-wrap'}>
      <div className={'pb-3'}>
        <h2 className={'m-0'}>{title}</h2>
        {description && <span>{description}</span>}
      </div>
      <div>
        <Link to={buttonLink}>
          <PrimaryButton>{buttonText ?? tHtml('common.start')}</PrimaryButton>
        </Link>
      </div>
    </div>
  );
};

export default NotFilledProfileSection;
