import React from 'react';

import { RouteList } from 'routes';
import * as Roles from 'helpers/auth/roles';
import AuthRoute from 'routes/AuthRoute';
import PageLayout from 'scopes/developer/containers/PageLayout';
import Transactions from './Transactions';
import TransactionsLoanRepayment from './components/TransactionsLoanRepayment';
import TransactionsLoanReceive from './components/TransactionsLoanReceive';
import TransactionsAll from './components/TransactionsAll';

const TransactionRoutes: any = () => [
  <AuthRoute
    path={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.LIST}
    component={Transactions}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.ALL}
    component={TransactionsAll}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.ALL}
    exact
  />,
  <AuthRoute
    path={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.LOAN_RECEIVE}
    component={TransactionsLoanReceive}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.LOAN_RECEIVE}
    exact
  />,
  <AuthRoute
    path={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.LOAN_REPAYMENT}
    component={TransactionsLoanRepayment}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.LOAN_REPAYMENT}
    exact
  />,
];

export default TransactionRoutes;
