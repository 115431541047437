import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import SelectInput, { SelectEnumProps, SelectOption } from '../../SelectInput';
import { GovernmentDocumentEnum } from 'helpers/enums/GovernmentDocumentEnum';

interface Props extends SelectEnumProps {
  disabled?: boolean;
}

const SelectGovDocumentTypeInput: React.FC<Props> = ({ name, placeholder, disabled = false }) => {
  const { t } = useTranslation();

  const [options] = useState<SelectOption[]>([
    {
      label: t(`placeholder.gov.document.${GovernmentDocumentEnum.IdentificationCard}`),
      value: GovernmentDocumentEnum.IdentificationCard,
    },
    {
      label: t(`placeholder.gov.document.${GovernmentDocumentEnum.IdCard}`),
      value: GovernmentDocumentEnum.IdCard,
    },
    {
      label: t(`placeholder.gov.document.${GovernmentDocumentEnum.Passport}`),
      value: GovernmentDocumentEnum.Passport,
    },
    {
      label: t(`placeholder.gov.document.${GovernmentDocumentEnum.DriverLicense}`),
      value: GovernmentDocumentEnum.DriverLicense,
    },
    {
      label: t(`placeholder.gov.document.${GovernmentDocumentEnum.ResidencePermit}`),
      value: GovernmentDocumentEnum.ResidencePermit,
    },
    {
      label: t(`placeholder.gov.document.${GovernmentDocumentEnum.InternalPassport}`),
      value: GovernmentDocumentEnum.InternalPassport,
    },
    {
      label: t(`placeholder.gov.document.${GovernmentDocumentEnum.SocialId}`),
      value: GovernmentDocumentEnum.SocialId,
    },
  ]);

  return (
    <SelectInput isDisabled={disabled} name={name} options={options} placeholder={placeholder} />
  );
};

export default SelectGovDocumentTypeInput;
