import React from 'react';

import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import * as Roles from 'helpers/auth/roles';
import PageLayout from 'scopes/investor/containers/PageLayout';
import { MyInvestments } from '.';
import MyInvestmentsInnerPage from './MyInvestmentsInnerPage';

const MyInvestmentRoutes: any = () => [
  <AuthRoute
    path={RouteList.INVESTOR.MY_INVESTMENTS.LIST}
    component={MyInvestments}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    redirect={Roles.All.redirect}
    key={RouteList.INVESTOR.MY_INVESTMENTS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.MY_INVESTMENTS.ACTIVE}
    component={MyInvestmentsInnerPage}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    redirect={Roles.All.redirect}
    key={RouteList.INVESTOR.MY_INVESTMENTS.ACTIVE}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.MY_INVESTMENTS.INACTIVE}
    component={MyInvestmentsInnerPage}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    redirect={Roles.All.redirect}
    key={RouteList.INVESTOR.MY_INVESTMENTS.INACTIVE}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.MY_INVESTMENTS.LATE}
    component={MyInvestmentsInnerPage}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    redirect={Roles.All.redirect}
    key={RouteList.INVESTOR.MY_INVESTMENTS.LATE}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.MY_INVESTMENTS.SOLD}
    component={MyInvestmentsInnerPage}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    redirect={Roles.All.redirect}
    key={RouteList.INVESTOR.MY_INVESTMENTS.SOLD}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.MY_INVESTMENTS.RECOVERING}
    component={MyInvestmentsInnerPage}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    redirect={Roles.All.redirect}
    key={RouteList.INVESTOR.MY_INVESTMENTS.RECOVERING}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.MY_INVESTMENTS.OTHER}
    component={MyInvestmentsInnerPage}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    redirect={Roles.All.redirect}
    key={RouteList.INVESTOR.MY_INVESTMENTS.OTHER}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.MY_INVESTMENTS.TRANSFERABLE}
    component={MyInvestmentsInnerPage}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    redirect={Roles.All.redirect}
    key={RouteList.INVESTOR.MY_INVESTMENTS.TRANSFERABLE}
    exact
  />,
];

export default MyInvestmentRoutes;
