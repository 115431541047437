import React, { ReactElement } from 'react';
import { TFunctionResult } from 'i18next';

import Tooltip from 'components/Tooltip';

export interface SecondaryMarketDataBlockProps {
  title: string | ReactElement;
  content: string | number | TFunctionResult | ReactElement;
  tooltipId?: string;
  tooltipText?: string;
}

const DataBlockStacked: React.FC<SecondaryMarketDataBlockProps> = ({
  title,
  content,
  tooltipId,
  tooltipText,
}) => {
  return (
    <div className={'data-block-stacked'}>
      <div className={'data-block__title'}>
        <span>{title}</span>
        {tooltipText && tooltipId && (
          <>
            <i className={'icon icon-info'} id={`tooltip-icon-${tooltipId}`} />
            <Tooltip id={`tooltip-icon-${tooltipId}`} text={tooltipText} />
          </>
        )}
      </div>
      <p className={'data-block__content'}>{content ?? '-'}</p>
    </div>
  );
};

export default DataBlockStacked;
