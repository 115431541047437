import { useCallback } from 'react';
import { create } from 'zustand';
import { Permission } from 'helpers/auth/permissions';
import { usePermissionsQuery } from '../api/queries/usePermissionsQuery';

interface usePermissionsState {
  permissions: string[];
  setPermissions: (permissions: string[]) => void;
  resetPermissions: () => void;
}

const permissionStore = create<usePermissionsState>()((set) => ({
  permissions: [Permission.GUEST],
  setPermissions: (permissions) => set(() => ({ permissions })),
  resetPermissions: () => set(() => ({ permissions: [Permission.GUEST] })),
}));

const usePermissions = () => {
  const { permissions, setPermissions, resetPermissions } = permissionStore();

  const query = usePermissionsQuery({ enabled: false });

  const fetchPermissions = async (): Promise<Array<string> | undefined> => {
    const { data } = await query.refetch();

    return data?.permissions;
  };

  const refreshPermissions = async (): Promise<any> => {
    const permissions = await fetchPermissions();

    if (permissions) {
      setPermissions(permissions);
    }
  };

  const convertPermissions = (permission: string | string[]): string[] => {
    if (Array.isArray(permission)) {
      return permission;
    } else {
      return permission.split('|');
    }
  };

  const hasAny = useCallback(
    (permission: string | string[]): boolean => {
      const arrayPermissions = convertPermissions(permission);

      if (permissions.length > 0) {
        for (const permission of arrayPermissions) {
          if (permissions.includes(permission)) {
            return true;
          }
        }
      }

      return false;
    },
    [permissions],
  );

  const hasAll = useCallback(
    (permission: string | string[]): boolean => {
      const arrayPermissions = convertPermissions(permission);

      const includedPermissions: string[] = [];

      if (permissions.length > 0) {
        for (const permission of arrayPermissions) {
          if (permissions.includes(permission)) {
            includedPermissions.push(permission);
          }
        }
      }
      return includedPermissions.length === arrayPermissions.length;
    },
    [permissions],
  );

  const doesNotHaveAll = (permission: string | string[]): boolean => {
    const arrayPermissions = convertPermissions(permission);

    const includedPermissions: string[] = [];
    if (permissions.length > 0) {
      for (const permission of arrayPermissions) {
        if (!permissions.includes(permission)) {
          includedPermissions.push(permission);
        }
      }
    }

    return includedPermissions.length === arrayPermissions.length;
  };

  const doesNotHaveAny = (permission: string | string[]): boolean => {
    const arrayPermissions = convertPermissions(permission);

    if (permissions.length > 0) {
      for (const permission of arrayPermissions) {
        if (!permissions.includes(permission)) {
          return true;
        }
      }
    }
    return false;
  };

  const can = hasAny;
  const cannot = doesNotHaveAny;

  return {
    hasAny,
    hasAll,
    doesNotHaveAny,
    doesNotHaveAll,
    can,
    cannot,
    setPermissions,
    resetPermissions,
    fetchPermissions,
    refreshPermissions,
    permissions,
  };
};

export default usePermissions;
