import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import PrimaryButton from 'components/Formik/PrimaryButton';

const AffiliateSendModal: React.FC<ModalComponentProps> = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  return (
    <React.Fragment>
      <ModalComponent size={'lg'} {...props} className={'modal position-relative'}>
        <i className={'icon icon-close mb-4 cursor pointer '} onClick={hideModal} />
        <div className={'p-3 pt-4 p-lg-4'}>
          <ModalHeader className={'d-flex justify-content-between align-items-center'}>
            <span>{tHtml('affiliate.modal.title')}</span>
          </ModalHeader>
          <ModalBody className={'modal-body'}>
            <p>{tHtml('affiliate.modal.body')}</p>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-start'}>
            <div className={'close-button'}>
              <PrimaryButton onClick={hideModal}>{tHtml('common.close')}</PrimaryButton>
            </div>
          </ModalFooter>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default AffiliateSendModal;
