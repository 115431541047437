import React, { useCallback } from 'react';
import useTranslation from 'helpers/useTranslation';
import { ModalBody, ModalHeader } from 'reactstrap';
import Api from 'api';
import { success } from 'services/toastr';
import { Form } from 'reactstrap';
import * as Yup from 'yup';

import { ModalComponent, ModalComponentProps } from '../ModalComponent';
import { useGlobalModalContext } from '../GlobalModal';
import ModalCloseButton from '../ModalCloseButton';
import { HighlightButton } from '../../Button';
import { PrimaryButton, TextInput } from '../../Formik';
import { Formik, FormikHelpers } from 'formik';
import { RefundedReasonI } from 'api/types/investor/investment';

interface Props extends ModalComponentProps {
  title: string | JSX.Element;
  investmentId: string;
  is_project_funded: boolean;
  onSuccess: () => Promise<void>;
}

const RefundModal: React.FC<Props> = ({
  title,
  investmentId,
  is_project_funded,
  onSuccess,
  ...props
}) => {
  const { t, tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const onSubmit = useCallback(
    async (request: RefundedReasonI, helpers: FormikHelpers<any>) => {
      try {
        Api.investor.investments.refundInvestment(investmentId, request).then(() => {
          success(tHtml('investments.refund_modal.success'));
          onSuccess();
        });
        hideModal();
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tHtml, investmentId],
  );

  const schema = Yup.object().shape({
    refunded_reason: Yup.string().max(255).nullable(),
  });

  return (
    <React.Fragment>
      <ModalComponent size={'md'} {...props} contentClassName={'modal-investment'}>
        <div className={'p-3'}>
          <ModalHeader
            toggle={hideModal}
            close={<ModalCloseButton />}
            tag={'h2'}
            className={'mt-2'}
          >
            {title}
            {is_project_funded && <h2>{tHtml('investments.refund_modal.details')}</h2>}
          </ModalHeader>
          <ModalBody>
            <div className={'w-100'}>
              <Formik
                initialValues={{ refunded_reason: null }}
                onSubmit={onSubmit}
                validationSchema={schema}
              >
                {(helpers) => (
                  <Form onSubmit={helpers.handleSubmit}>
                    <TextInput
                      name={'refunded_reason'}
                      placeholder={t('placeholder.refunded_reason')}
                    />
                    <div className={'d-flex justify-content-around mt-4'}>
                      <HighlightButton
                        className={'btn btn-highlight w-25 fw-normal me-3'}
                        onClick={hideModal}
                      >
                        {tHtml('common.no')}
                      </HighlightButton>
                      <PrimaryButton
                        className={'btn btn-primary w-25 fw-normal'}
                        submitting={helpers.isSubmitting}
                      >
                        {tHtml('common.yes')}
                      </PrimaryButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default RefundModal;
