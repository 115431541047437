import React, { useState } from 'react';
import { PrimaryButton } from 'components/Button';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import useTranslation from 'helpers/useTranslation';
import Api from 'api';
import Loader from 'components/Loader';

const IdReverificationButton = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { tHtml } = useTranslation();
  const { data: userProfileData } = useUserProfileQuery();

  const handleReverification = async () => {
    setLoading(true);
    const response = await Api.shared.user.signUpSteps.registrationVerification();
    window.open(response.redirectUrl, '_self', 'noopener,noreferrer');
  };

  return (
    <>
      {userProfileData?.id_verification?.reverification_needed && (
        <div className="inline mr-5">
          {loading ? (
            <PrimaryButton disabled={true}>
              <Loader size={'sm'} />
            </PrimaryButton>
          ) : (
            <PrimaryButton onClick={handleReverification}>
              {tHtml('profile.start_reverification')}
            </PrimaryButton>
          )}
        </div>
      )}
    </>
  );
};

export default IdReverificationButton;
