import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Cell, Column, UseTableCellProps } from 'react-table';
import { Link, useLocation } from 'react-router-dom';

import { RouteList } from 'routes';
import { PaginationData, PaginationDataFilter } from 'api/types';
import Table, { DateFilter } from 'components/Table/Table';
import { FunctionalButton } from 'components/Button';
import {
  InvestorInvestmentListResponseDto,
  InvestorLateInvestmentListResponseDto,
  InvestorOtherInvestmentListResponseDto,
} from 'api/types/investor/investment';
import Api from 'api';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';
import { BonusAmountTooltip } from './Tooltips/BonusAmountTooltip';
import { AutomaticInvestmentTooltip } from './Tooltips/AutomaticInvestmentTooltip';
import { BonusPercentageTooltip } from './Tooltips/BonusPercentageTooltip';
import { resolveTranslatableValueDto, useDifferenceBetweenTwoDatesToString } from 'helpers/utils';
import { TableInitialSortBy } from 'api/types/common';
import { useCalculateCreditDuration } from './helpers/calculateCreditDuration';
import Expander from 'components/Table/Expander';
import { reformatResponse } from './MyInvestments';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import { ProjectInvestmentStatusEnum } from '../../../../helpers/enums/ProjectInvestmentTypeEnum';
import { error } from 'services/toastr';
import { downloadXMLFile, downloadXLSXFile, downloadCSVFile } from 'services/media';
import RefundInvestment from './helpers/RefundInvestment';
import TransferInvestment from './helpers/TransferInvestment';
import { TransferTypeInvestmentTooltip } from './Tooltips/TransferTypeInvestmentTooltip';

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'invested_at',
    desc: true,
  },
];

const MyInvestmentsInnerPage: React.FC = () => {
  const { t, currentLanguage, tHtml } = useTranslation();
  const calculateCreditDuration = useCalculateCreditDuration();
  const differenceBetweenTwoDatesToString = useDifferenceBetweenTwoDatesToString();
  const [paginationRequest, setPaginationRequest] = useState<PaginationDataFilter | undefined>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const title = {
    active: t('investments.active'),
    inactive: t('investments.inactive'),
    late: t('investments.late'),
    sold: t('investments.sold'),
    recovering: t('investments.recovering'),
    other: t('investments.other'),
    transferable: t('investments.transferable'),
  };

  const [data, setData] =
    useState<
      PaginationData<
        | InvestorInvestmentListResponseDto
        | InvestorLateInvestmentListResponseDto
        | InvestorOtherInvestmentListResponseDto
      >
    >();

  const { pathname } = useLocation();

  const genericColumns = useMemo<Column[] | any>(
    () => [
      {
        id: 'expander',
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
      {
        Header: t('table.pid'),
        accessor: 'pid',
        sortType: 'string',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => {
          if (cell.row.depth === 0) {
            return (
              <ProjectTitleLink
                title={cell.row.original.project_name ?? ''}
                projectId={cell.row.original.project_id}
                privateId={cell.row.original.project_pid}
              />
            );
          }

          return '';
        },
      },
      {
        Header: t('table.investment'),
        accessor: 'investment_amount',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => {
          return (
            <span className={'d-flex align-items-center'}>
              <span>{tHtml('common.money', { value: cell.value })}</span>
              <BonusAmountTooltip cell={cell} />
            </span>
          );
        },
      },
      {
        Header: t('table.investment_date'),
        accessor: 'invested_at',
        Filter: DateFilter,
        sortType: '',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => {
          return (
            <span className={'d-flex align-items-center'}>
              <span>{tHtml('common.date', { date: cell.value })}</span>
              <AutomaticInvestmentTooltip cell={cell} />
              <TransferTypeInvestmentTooltip cell={cell} />
            </span>
          );
        },
      },
      {
        Header: t('table.investment_term'),
        accessor: 'credit_duration',
        sortType: '',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => {
          return calculateCreditDuration(cell.row.original);
        },
      },
      {
        Header: t('table.interest'),
        accessor: 'total_interest',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => {
          return (
            <span className={'d-flex align-items-center'}>
              <span>{tHtml('common.percents', { value: cell.value })}</span>
              <BonusPercentageTooltip cell={cell} />
            </span>
          );
        },
      },
      {
        Header: t('table.received_interest'),
        accessor: 'interest_received',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) =>
          t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.returned_loan_amount'),
        accessor: 'interest_returned',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) =>
          t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.loan_fine'),
        accessor: 'fine',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) =>
          t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.received_total'),
        accessor: 'total',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) =>
          t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.loan_left_amount'),
        accessor: 'investment_balance',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) =>
          t('common.money', { value: cell.value }),
      },
      {
        Header: '',
        disableSortBy: true,
        accessor: 'is_refundable',
        Cell: (cell: any) => (
          <>
            {cell.value ? (
              <RefundInvestment
                onSuccess={() => cell.dispatch({ type: 'refreshData' })}
                investmentId={cell.row.original.id}
                is_project_funded={cell.row.original.is_project_funded}
              />
            ) : null}
          </>
        ),
      },
      {
        Header: '',
        disableSortBy: true,
        accessor: 'allow_project_investment_transfers',
        Cell: (cell: any) => (
          <>
            {cell.value &&
            cell.row.original.refunded_at === null &&
            cell.row.original.refinancing_project_id !== null ? (
              <TransferInvestment
                data={{
                  project_id: cell.row.original.project_id,
                  project_pid: cell.row.original.project_pid,
                  project_name: cell.row.original.project_name,
                  investment: [
                    { amount: cell.row.original.investment_amount, id: cell.row.original.id },
                  ],
                  refinancing_project_id: cell.row.original.refinancing_project_id,
                  refinancing_project_pid: cell.row.original.refinancing_project_pid,
                  refinancing_project_name: cell.row.original.refinancing_project_name,
                  onTransfer: () => setRefresh((val) => !val),
                }}
              />
            ) : null}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, tHtml],
  );

  const columns: Column[] | any = useMemo(
    () => [
      ...genericColumns,
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [genericColumns, t],
  );

  const inactiveInvestmentColumns: Column[] | any = useMemo(
    () => [
      ...genericColumns,
      {
        Header: t('table.finished_at'),
        accessor: 'finished_at',
        sortType: '',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => (
          <span>{tHtml('common.date', { date: cell.value })}</span>
        ),
      },
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [genericColumns, t],
  );

  const lateInvestmentColumns: Column[] | any = useMemo(
    () => [
      ...genericColumns,
      {
        Header: t('table.late_at'),
        accessor: 'late_at',
        sortType: '',
        Cell: (cell: Cell<InvestorLateInvestmentListResponseDto>) => {
          return (
            <span className={'d-flex align-items-center'}>
              <span>{cell.value ? differenceBetweenTwoDatesToString(cell.value) : '-'}</span>
            </span>
          );
        },
      },
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [genericColumns, t],
  );

  const otherInvestmentColumns: Column[] | any = useMemo(
    () => [
      ...genericColumns,
      {
        Header: t('table.comment'),
        accessor: 'comment',
        sortType: 'string',
        Cell: (cell: Cell<InvestorOtherInvestmentListResponseDto>) => {
          return <span>{resolveTranslatableValueDto(cell.value)}</span>;
        },
      },
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
    ],
    [genericColumns, t],
  );

  const recoveringInvestmentColumns: Column[] | any = useMemo(
    () => [
      ...genericColumns,
      {
        Header: t('table.comment'),
        accessor: 'comment',
        sortType: 'string',
        Cell: (cell: Cell<InvestorOtherInvestmentListResponseDto>) => {
          return <span>{resolveTranslatableValueDto(cell.value)}</span>;
        },
      },
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
    ],
    [genericColumns, t],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      let callback = undefined;
      setPaginationRequest(request);

      switch (pathname) {
        case RouteList.INVESTOR.MY_INVESTMENTS.ACTIVE:
          callback = Api.investor.investments.fetchActiveInvestments(request);
          break;
        case RouteList.INVESTOR.MY_INVESTMENTS.INACTIVE:
          callback = Api.investor.investments.fetchInactiveInvestments(request);
          break;
        case RouteList.INVESTOR.MY_INVESTMENTS.LATE:
          callback = Api.investor.investments.fetchLateInvestments(request);
          break;
        case RouteList.INVESTOR.MY_INVESTMENTS.SOLD:
          callback = Api.investor.investments.fetchSoldInvestments(request);
          break;
        case RouteList.INVESTOR.MY_INVESTMENTS.RECOVERING:
          callback = Api.investor.investments.fetchRecoveringInvestments(request);
          break;
        case RouteList.INVESTOR.MY_INVESTMENTS.OTHER:
          callback = Api.investor.investments.fetchOtherInvestments(request);
          break;
        case RouteList.INVESTOR.MY_INVESTMENTS.TRANSFERABLE:
          callback = Api.investor.investments.fetchTransferableInvestments(request);
          break;
      }

      if (callback != undefined) {
        return callback.then((response) => {
          setData(reformatResponse(response));
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, currentLanguage, refresh],
  );

  const resolveTitle = (): string => {
    switch (pathname) {
      case RouteList.INVESTOR.MY_INVESTMENTS.ACTIVE:
        return title.active;
      case RouteList.INVESTOR.MY_INVESTMENTS.INACTIVE:
        return title.inactive;
      case RouteList.INVESTOR.MY_INVESTMENTS.LATE:
        return title.late;
      case RouteList.INVESTOR.MY_INVESTMENTS.SOLD:
        return title.sold;
      case RouteList.INVESTOR.MY_INVESTMENTS.RECOVERING:
        return title.recovering;
      case RouteList.INVESTOR.MY_INVESTMENTS.OTHER:
        return title.other;
      case RouteList.INVESTOR.MY_INVESTMENTS.TRANSFERABLE:
        return title.transferable;
      default:
        return '';
    }
  };

  const resolveColumns = () => {
    switch (pathname) {
      case RouteList.INVESTOR.MY_INVESTMENTS.LATE:
        return lateInvestmentColumns;
      case RouteList.INVESTOR.MY_INVESTMENTS.OTHER:
        return otherInvestmentColumns;
      case RouteList.INVESTOR.MY_INVESTMENTS.RECOVERING:
        return recoveringInvestmentColumns;
      case RouteList.INVESTOR.MY_INVESTMENTS.INACTIVE:
        return inactiveInvestmentColumns;
      default:
        return columns;
    }
  };

  const resolveExportTableType = (): ProjectInvestmentStatusEnum | null => {
    switch (pathname) {
      case RouteList.INVESTOR.MY_INVESTMENTS.ACTIVE:
        return ProjectInvestmentStatusEnum.ACTIVE;
      case RouteList.INVESTOR.MY_INVESTMENTS.INACTIVE:
        return ProjectInvestmentStatusEnum.INACTIVE;
      case RouteList.INVESTOR.MY_INVESTMENTS.LATE:
        return ProjectInvestmentStatusEnum.LATE;
      case RouteList.INVESTOR.MY_INVESTMENTS.SOLD:
        return ProjectInvestmentStatusEnum.SOLD;
      case RouteList.INVESTOR.MY_INVESTMENTS.RECOVERING:
        return ProjectInvestmentStatusEnum.RECOVERING;
      case RouteList.INVESTOR.MY_INVESTMENTS.OTHER:
        return ProjectInvestmentStatusEnum.OTHER;
      case RouteList.INVESTOR.MY_INVESTMENTS.TRANSFERABLE:
        return ProjectInvestmentStatusEnum.TRANSFERABLE;
      default:
        return null;
    }
  };

  const exportCsv = (request: PaginationDataFilter | undefined) => async () => {
    const type: ProjectInvestmentStatusEnum | null = resolveExportTableType();

    if (!type) {
      error(t('common.error'));
      return;
    }

    const response = await Api.investor.investments.exportInvestments(
      type,
      CommonDocumentTypeEnum.CSV,
      request,
    );

    await downloadCSVFile(response.url, 'investments');
  };

  const exportXlsx = (request: PaginationDataFilter | undefined) => async () => {
    const type: ProjectInvestmentStatusEnum | null = resolveExportTableType();

    if (!type) {
      error(t('common.error'));
      return;
    }

    const response = await Api.investor.investments.exportInvestments(
      type,
      CommonDocumentTypeEnum.XLSX,
      request,
    );

    await downloadXLSXFile(response.url, 'investments');
  };

  const exportXml = (request: PaginationDataFilter | undefined) => async () => {
    const type: ProjectInvestmentStatusEnum | null = resolveExportTableType();

    if (!type) {
      error(t('common.error'));
      return;
    }

    const response = await Api.investor.investments.exportInvestments(
      type,
      CommonDocumentTypeEnum.XML,
      request,
    );
    await downloadXMLFile(response.url);
  };

  return (
    <div className={'page-layout investor-my-investments'}>
      <div className={'mb-5 d-flex justify-content-start'}>
        <Link to={RouteList.INVESTOR.MY_INVESTMENTS.LIST}>
          <FunctionalButton>
            <i className={'icon icon-arrow-left'} /> {tHtml('menu.my_investments')}
          </FunctionalButton>
        </Link>
      </div>

      <div>
        <h2>{resolveTitle()}</h2>
        <div>
          <Table
            columns={resolveColumns()}
            data={data}
            onFetchData={fetchData}
            showPageSizes={true}
            searchable={true}
            defaultLimit={20}
            exportLinks={[
              {
                onClick: exportCsv,
                request: paginationRequest,
                type: CommonDocumentTypeEnum.CSV,
              },
              {
                onClick: exportXlsx,
                request: paginationRequest,
                type: CommonDocumentTypeEnum.XLSX,
              },
              {
                onClick: exportXml,
                request: paginationRequest,
                type: CommonDocumentTypeEnum.XML,
              },
            ]}
            initialSortBy={INITIAL_SORT_ORDER}
            hideSubRowColumns={['project_name']}
            hideColumnsOnDesktop={['expander-mobile']}
            hideColumnsOnMobile={['expander']}
            overrideTotalCount={data?.meta.total_override}
          />
        </div>
      </div>
    </div>
  );
};

export default MyInvestmentsInnerPage;
