import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container, Row } from 'reactstrap';

import StepFormHeader from 'containers/StepFormLayout/StepFormHeader';
import { RouteList } from 'routes';

const ProjectProposalSkeleton: React.FC = () => {
  return (
    <>
      <StepFormHeader to={RouteList.DASHBOARD} text={'common.back'} />
      <Container className={'px-2 px-sm-4 px-md-6 my-5'}>
        <Container className={'d-block developer-proposal'}>
          <Row className={'mb-4'}>
            <Skeleton height={100} />
          </Row>
          <Skeleton height={400} />
          <hr className={'my-4'} />
          <Row className={'terms'}>
            <Skeleton height={700} />
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default ProjectProposalSkeleton;
