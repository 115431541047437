import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { ErrorMessage, useField, useFormikContext } from 'formik';

import RadioInput from 'components/Formik/RadioGroup/RadioInput';
import CheckboxInputValue from 'components/Formik/CheckboxGroup/CheckboxInputValue';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import QuizAlertModal from './QuizAlertModal';
import { QuestionAnswerRequestDto, QuizAnswersRequestDto } from 'api/types/investor/quiz';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  index: number;
  stepIndex: number;
  stepSize: number;
  name: string;
  values: string[];
  type: string;
  alerts: any;
  agreedAlerts: string[];
  setAgreedAlerts: any;
  enabledWhen: string[] | null;
}

export const isQuestionEnabled = (
  enabledWhen: string[] | null,
  formValues: QuizAnswersRequestDto | Array<QuestionAnswerRequestDto> | null,
) => {
  if (!enabledWhen) {
    return false;
  }

  const values = (formValues as QuizAnswersRequestDto).questions_answers ?? formValues;

  if (!values || !Array.isArray(values)) {
    return false;
  }

  return !values.some((question) => {
    return question.answers.some((answer) => enabledWhen?.includes(answer));
  });
};

export const isQuestionDisabled = (
  disabledWhen: string[] | null,
  formValues: QuizAnswersRequestDto | Array<QuestionAnswerRequestDto> | null,
) => {
  if (!disabledWhen) {
    return false;
  }

  const values = (formValues as QuizAnswersRequestDto).questions_answers ?? formValues;

  if (!values || !Array.isArray(values)) {
    return false;
  }

  return values.some((question) =>
    question.answers.some((answer) => disabledWhen?.includes(answer)),
  );
};

const CardInputGroup: React.FC<Props> = ({ ...props }) => {
  const absoluteIndex = props.stepSize + 1;
  const fieldNameAnswers = `questions_answers.${absoluteIndex - 1}.answers`;

  const { t, tHtml } = useTranslation();
  const [field, meta] = useField(fieldNameAnswers);
  const formik = useFormikContext();
  const { showModal } = useGlobalModalContext();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;

  useEffect(() => {
    meta.value.map((value: string) => {
      if (props.alerts?.hasOwnProperty(value) && !props.agreedAlerts.includes(value)) {
        showModal(<QuizAlertModal body_text={props.alerts[value].message} value={value} />);
        props.setAgreedAlerts([...props.agreedAlerts, value]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.name, meta, props.alerts, t]);

  useEffect(() => {
    const disabled = isQuestionEnabled(props.enabledWhen, formik.values as QuizAnswersRequestDto);

    if (disabled && field.value.length > 0) {
      formik.setFieldValue(fieldNameAnswers, [], false);
    }

    setIsDisabled(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const CardItem: React.FC<{
    fieldName: string;
    value: string;
    type: string;
    showPlaceholder: boolean;
    disabled: boolean;
  }> = ({ ...props }) => {
    const [, meta] = useField(props.fieldName);

    return (
      <li className={`list-group-item ${meta.error ? 'is-invalid' : ''}`}>
        {props.type === 'single' && (
          <RadioInput
            name={`${props.fieldName}[0]`}
            value={props.value}
            showPlaceholder={props.showPlaceholder}
            placeholder={tHtml('quiz.' + props.value)}
            disabled={props.disabled}
          />
        )}
        {props.type === 'multi' && (
          <CheckboxInputValue
            key={props.value}
            name={props.fieldName}
            value={props.value}
            showPlaceholder={props.showPlaceholder}
            placeholder={tHtml('quiz.' + props.value)}
            disabled={props.disabled}
          />
        )}
      </li>
    );
  };

  return (
    <div className={'form-input'} hidden={isDisabled}>
      <div className={'mb-3 fw-bold'}>{tHtml('quiz.' + props.name)}</div>
      <div className={`card shadow-sm p-1 ${meta.error ? 'is-invalid' : ''}`}>
        <ul className={`list-group list-group-flush ${meta.error ? 'is-invalid' : ''}`}>
          {props.values.map((value) => {
            return (
              <CardItem
                fieldName={fieldNameAnswers}
                value={value}
                type={props.type}
                showPlaceholder={true}
                key={value}
                disabled={isDisabled}
              />
            );
          })}
        </ul>
      </div>
      {isError && (
        <div className={'invalid-feedback ms-2'}>
          <ErrorMessage name={fieldNameAnswers} />
        </div>
      )}
    </div>
  );
};

export default CardInputGroup;
