import React from 'react';
import Loader from 'components/Loader';
import useTranslation from 'helpers/useTranslation';
import { useWalletQuery } from '../../api/queries/useWalletQuery';

type Props = {
  loaderSize?: 'sm' | undefined;
};

export const WalletAmount: React.FC<Props> = ({ loaderSize }) => {
  const { tHtml } = useTranslation();

  const { data: wallet, isLoading } = useWalletQuery();

  return isLoading ? (
    <Loader size={loaderSize} />
  ) : (
    <div className={'balance-amount'}>
      {wallet && wallet.balance !== null ? (
        <>{tHtml('common.money', { value: wallet.balance })}</>
      ) : (
        <Loader size={loaderSize} />
      )}
    </div>
  );
};

export default WalletAmount;
