import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PrimaryButton from 'components/Formik/PrimaryButton';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';

const TaxModal: React.FC<ModalComponentProps> = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  return (
    <React.Fragment>
      <ModalComponent size={'lg'} {...props}>
        <div className={'p-2 p-lg-5'}>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {tHtml('modal.tax.title')}
          </ModalHeader>
          <ModalBody className={''}>
            <p className={'text-pre'}>{tHtml('modal.tax.body')}</p>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-center'}>
            <div className={'w-50'}>
              <PrimaryButton onClick={hideModal}>{tHtml('modal.tax.cta')}</PrimaryButton>
            </div>
          </ModalFooter>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default TaxModal;
