import { create } from 'zustand';

interface ProfileMenuState {
  profileMenu: {
    [name: string]: boolean;
  };
  toggleProfileMenu: (name: string) => void;
  openOneCloseOther: (name: string) => void;
  isOpen: (name: string) => boolean;
}

const useProfileMenuState = create<ProfileMenuState>()((set, get) => ({
  profileMenu: {},
  isOpen: (name) => get().profileMenu[name],
  openOneCloseOther: (name) => set({ profileMenu: { [name]: true } }),
  toggleProfileMenu: (name) =>
    set((prevState) => ({
      profileMenu: { ...prevState.profileMenu, [name]: !prevState.profileMenu[name] },
    })),
}));

export default useProfileMenuState;
