import TagManager from 'react-gtm-module';
import { default as env } from 'env.json';

interface GTMEcommerceObject {
  currency: string;
  value: number;
  transaction_id: string;
  items: {
    item_name: string;
    item_id: string;
    price: number;
    quantity: number;
  }[];
}
const event = {
  purchase: 'purchase',
} as const;

class GoogleTagManager {
  public static init = () => {
    TagManager.initialize({
      gtmId: env.GTM_APP_ID,
    });
  };

  public static event: (
    e: keyof typeof event,
    data: Record<string, any>,
    options?: Record<string, any>,
  ) => void = (event, data, options = {}) => {
    GoogleTagManager.push({
      event,
      eventModel: data,
      ...options,
    });
  };

  public static purchase = (ecommerce: GTMEcommerceObject) => {
    GoogleTagManager.event(event.purchase, {
      ecommerce,
    });
  };

  public static push = (obj: Record<string, any>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.dataLayer.push(obj);
  };
}

export { GoogleTagManager };
