import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Nav, NavbarToggler } from 'reactstrap';

import { RouteList } from 'routes';
import LocaleSwitcher from 'components/LocaleSwitcher';
import { Props } from '../Desktop/SidebarDashboard';
import {
  StyledMobileBurgerButton,
  StyledMobileSidebarArrow,
  StyledMobileSidebarBlock,
  StyledMobileSidebarHeader,
  StyledMobileSidebarWrapper,
} from './SidebarMobile.style';
import { useAuth } from 'services/useAuth';
import useDisplay from 'helpers/useDisplay';

import ThemeSwitcher from 'components/ThemeSwitcher/ThemeSwitcher';
import ApplicationFund from '../../ApplicationFund';
import AuthenticatedAccountMobile from 'scopes/shared/components/AuthenticatedAccountMobile';

const SidebarMobile: React.FC<Props> = (props) => {
  const { t, tHtml } = useTranslation();
  const { isApp } = useDisplay();

  const { logout } = useAuth();

  interface MenuItem {
    name: string;
    path?: string;
    isHidden?: boolean;
  }

  const menuItems: MenuItem[] = [
    {
      name: t('menu.account_overview'),
      path: RouteList.DASHBOARD,
      isHidden: isApp,
    },
    {
      name: t('menu.transactions'),
      path: RouteList.INVESTOR.TRANSACTIONS.LIST,
    },
    {
      name: t('menu.documents'),
      path: RouteList.INVESTOR.DOCUMENTS.LIST,
    },
    {
      name: t('menu.my_projects'),
      path: RouteList.PROJECT_DEVELOPER.PROJECTS.LIST,
      isHidden: isApp,
    },
    {
      name: t('menu.payments'),
      path: RouteList.PROJECT_DEVELOPER.PAYMENTS.VIEW,
    },
  ];

  const renderItem = (name: string, path: string | null | undefined) => {
    return (
      <Link to={path ?? '#'} style={{ whiteSpace: 'nowrap' }} key={path}>
        <span>{name}</span>
        <StyledMobileSidebarArrow>
          <i className={'icon icon-arrow-sidebar'} />
        </StyledMobileSidebarArrow>
      </Link>
    );
  };

  const toggle = () => props.setIsOpen((value) => !value);

  const renderMenu = (menu: MenuItem[]) => {
    return menu.map((menuItem) => !menuItem.isHidden && renderItem(menuItem.name, menuItem.path));
  };

  return (
    <React.Fragment>
      <Nav vertical={true} className="h-100">
        <StyledMobileSidebarWrapper className="h-100 justify-content-between">
          <div>
            <StyledMobileSidebarHeader>
              <LocaleSwitcher />
              <StyledMobileSidebarBlock>
                {!isApp && <ThemeSwitcher />}
                <StyledMobileBurgerButton>
                  <NavbarToggler onClick={toggle} className={'sidebar-toggler'} />
                </StyledMobileBurgerButton>
              </StyledMobileSidebarBlock>
            </StyledMobileSidebarHeader>
            <AuthenticatedAccountMobile />
            <div className="w-100 my-4">
              <ApplicationFund />
            </div>
            {renderMenu(menuItems)}
          </div>

          <Link
            onClick={() => {
              logout();
            }}
            className="logout mb-4"
            to={RouteList.GUEST.AUTHENTICATION.LOGIN}
          >
            <i className={'icon icon-logout'} />
            {tHtml('common.logout')}
          </Link>
        </StyledMobileSidebarWrapper>
      </Nav>
    </React.Fragment>
  );
};

export default SidebarMobile;
