import Client from '../client';
import Http from '../../http';
import {
  SocialLoginRequestDto,
  SocialLoginResponseDto,
  SocialSignUpRequestDto,
  SocialSignUpResponseDto,
} from '../../types/guest/social';

class GuestSocial extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      SOCIAL_LOGIN: this.defaultUrl(`${this.http.baseUrl}/guest/auth/social/login`),
      SOCIAL_SIGNUP: this.defaultUrl(`${this.http.baseUrl}/guest/auth/social/sign-up`),
    };
  }

  socialLogin = (data: SocialLoginRequestDto): Promise<SocialLoginResponseDto> => {
    return this.http.post(this.api.SOCIAL_LOGIN, data);
  };

  socialSignUp = (data: SocialSignUpRequestDto): Promise<SocialSignUpResponseDto> => {
    return this.http.post(this.api.SOCIAL_SIGNUP, data);
  };
}

export default GuestSocial;
