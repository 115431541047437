import Client from '../client';
import Http from '../../http';
import { PaginationData, PaginationDataFilter } from '../../types';
import { GuestProjectListResponse, GuestProjectResponse } from '../../types/guest/project';

class GuestProject extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_PROJECT: this.defaultUrl(`${this.http.baseUrl}/guest/projects/project/{projectId}`),
      FETCH_PROJECTS: this.defaultUrl(`${this.http.baseUrl}/guest/projects`),
    };
  }

  fetchProject = (projectId: string | number): Promise<GuestProjectResponse> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT, { projectId });
    return this.http.get(url);
  };

  fetchProjects = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<GuestProjectListResponse>> => {
    return this.http.get(this.api.FETCH_PROJECTS, { params: request });
  };
}

export default GuestProject;
