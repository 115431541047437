import React, { useCallback, useEffect } from 'react';
import { ErrorMessage, useField } from 'formik';
import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input';
import ScrollToOnError from 'components/Formik/ScrollToOnError';
import useMobileDetect from 'helpers/useMobileDetect';
const CodeInput: React.FC<ReactCodeInputProps> = (props): JSX.Element => {
  const [field, meta, helper] = useField(props.name);
  const mobileDetect = useMobileDetect();

  useEffect(() => {
    if (field.value) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const onChange = useCallback(
    (v: string) => {
      helper.setValue(v);
    },
    [helper],
  );
  const styleForSafari = mobileDetect.isIos() ? '0.5rem' : '0.1rem';
  const inputStyle = {
    inputStyle: {
      fontFamily: 'monospace',
      margin: '0.3rem',
      width: '2.25rem',
      height: '2.625rem',
      fontSize: '1.5rem',
      borderRadius: '0.375rem',
      border: '1px solid',
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
      paddingLeft: '0.6rem',
      paddingRight: 0,
      paddingTop: styleForSafari, // 0.2 - kiti , 0.5 - iPhone
    },
    inputStyleInvalid: {
      fontFamily: 'monospace',
      margin: '0.3rem',
      width: '2.25rem',
      height: '2.625rem',
      fontSize: '1.5rem',
      borderRadius: '0.375rem',
      border: '1px solid red',
      paddingLeft: '0.6rem',
      paddingRight: 0,
      paddingTop: styleForSafari, // 0.2 - kiti , 0.5 - iPhone
      backgroundColor: 'rgb(242, 222, 222)',
      color: 'rgb(185, 74, 72)',
    },
  };

  return (
    <>
      <div className={meta.error ? 'is-invalid' : ''}>
        <ScrollToOnError name={props.name} isError={!!meta?.error ?? false}>
          <label className={'form-label mb-1'}>{props.placeholder}</label>
          <ReactCodeInput onChange={onChange} isValid={!meta.error} {...inputStyle} {...props} />
        </ScrollToOnError>
        {meta.error && (
          <div className={'invalid-feedback text-pre'} style={{ display: 'block' }}>
            <ErrorMessage
              component={'div'}
              name={props.name}
              render={(msg: string | string[]) => {
                if (typeof msg === 'string') {
                  return msg;
                }

                return msg.join('\n');
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CodeInput;
