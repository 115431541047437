import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { LanguageEnum } from 'helpers/enums/LanguageEnum';
import Flag from './Flag';
import useUserType from 'helpers/useUserType';
import { useLocalStorage } from 'helpers';
import { CommonEnum } from 'helpers/enums/CommonEnum';

const LocaleSwitcher: React.FC = () => {
  const {
    currentLanguage,
    i18n: { changeLanguage },
  } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const userType = useUserType();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLanguage] = useLocalStorage<string>(CommonEnum.Language, currentLanguage);

  return (
    <React.Fragment>
      <Dropdown
        className={'custom-dropdown'}
        isOpen={isOpen}
        toggle={() => setOpen((prev) => !prev)}
      >
        <DropdownToggle className={`d-flex align-items-center ${isOpen ? 'open' : ''}`} caret>
          <Flag countryCode={currentLanguage} />
          <span className={'label'}>{currentLanguage?.toUpperCase()}</span>
          <span className="caret" />
        </DropdownToggle>
        <DropdownMenu>
          {Object.entries(LanguageEnum)
            .filter(([, value]) => value != currentLanguage)
            .map(([, value], idx) => {
              return (
                <DropdownItem
                  key={idx}
                  onClick={async () => {
                    await changeLanguage(value);
                    if (!userType.isGuest()) {
                      setLanguage(value);
                    }
                  }}
                >
                  <Flag countryCode={value} />
                  {value.toUpperCase()}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default LocaleSwitcher;
