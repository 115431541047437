import React from 'react';
import useTranslation from 'helpers/useTranslation';

import { PrimaryButton } from 'components/Button';
import ApplicationModal from 'scopes/developer/components/Modals/ApplicationModal';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';

const ApplicationFund: React.FC = () => {
  const { showModal } = useGlobalModalContext();
  const { tHtml } = useTranslation();

  return (
    <>
      <PrimaryButton
        onClick={() => {
          showModal(<ApplicationModal />);
        }}
      >
        {tHtml('application.menu.funding')}
      </PrimaryButton>
    </>
  );
};

export default ApplicationFund;
