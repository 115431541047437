import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import { DataBlock } from 'components/DataBlock';
import CountryLabel from 'components/Labels/CountryLabel';
import useOnboarding from 'helpers/useOnboarding';
import NotFilledProfileSection from 'components/Profile/NotFilledProfileSection';
import CompanyProfileSection from 'scopes/company/components/CompanyProfileSection';
import { CompanyMenuEnum } from 'helpers/enums/CompanyMenuEnum';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';

const ContactInfo: React.FC = () => {
  const { tHtml } = useTranslation();
  const { isContactDetailsFilled } = useOnboarding();

  const { data: companyProfileData } = useUserCompanyProfileQuery();

  if (!isContactDetailsFilled) {
    return (
      <NotFilledProfileSection
        title={tHtml('menu.contact_info')}
        buttonLink={RouteList.COMPANY.EDIT.CONTACT_INFO}
      />
    );
  }

  return (
    <CompanyProfileSection
      name={CompanyMenuEnum.ContactInfo}
      title={tHtml('menu.contact_info')}
      buttonLink={RouteList.COMPANY.EDIT.CONTACT_INFO}
    >
      <div className={'content'}>
        <DataBlock
          title={tHtml('placeholder.company.representative_title')}
          data={
            companyProfileData?.company.representative_title
              ? tHtml(`placeholder.company.${companyProfileData?.company.representative_title}`)
              : ''
          }
        />
        <DataBlock
          title={tHtml('placeholder.company.name')}
          data={companyProfileData?.company.name ?? ''}
        />
        <DataBlock
          title={tHtml('placeholder.type')}
          data={
            companyProfileData?.company.type
              ? tHtml(`common.${companyProfileData?.company.type}`)
              : ''
          }
        />
        <DataBlock
          title={tHtml('placeholder.company.legal_code')}
          data={companyProfileData?.company.legal_code ?? ''}
        />
        <DataBlock
          title={tHtml('placeholder.vat_code')}
          data={companyProfileData?.company.vat_code ?? ''}
        />
        <DataBlock
          title={tHtml('placeholder.email')}
          data={companyProfileData?.company.email ?? ''}
        />
        <DataBlock
          title={tHtml('placeholder.phone')}
          data={companyProfileData?.company.phone ?? ''}
        />
        <DataBlock
          title={tHtml('placeholder.residence')}
          data={
            companyProfileData?.company.country ? (
              <CountryLabel code={companyProfileData?.company.country} />
            ) : (
              ''
            )
          }
        />
      </div>
    </CompanyProfileSection>
  );
};

export default withRouter(ContactInfo);
