import { Link } from 'react-router-dom';
import { FunctionalButton } from 'components/Button';
import React from 'react';
import useTranslation from 'helpers/useTranslation';

interface Props {
  buttonLink: string;
  title?: string;
}

const EditLinkAction = ({ buttonLink, title }: Props) => {
  const { tHtml } = useTranslation();

  return (
    <div>
      <Link to={buttonLink}>
        <FunctionalButton
          className={'site btn btn-functional edit-button'}
          icon={'icon-pencil-edit'}
        >
          {title ?? tHtml('common.edit')}
        </FunctionalButton>
      </Link>
    </div>
  );
};

export default EditLinkAction;
