import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import Tooltip from 'components/Tooltip';
import Loader from 'components/Loader';
import { useStatisticQuery } from 'api/queries/dashboard';
import Api from 'api';

const AvgAnnualInterest: React.FC = () => {
  const { tHtml } = useTranslation();

  const { data: averageAnnualInterest, isFetching } = useStatisticQuery(
    ['averageAnnualInterest'],
    () => Api.investor.dashboard.fetchAverageAnnualInterest(),
  );

  return (
    <>
      <div className={'page-layout h-100 wallet'}>
        <div className={'panel h-100 mb-0'}>
          <Card body className={'light-pink balance py-2 py-md-4 px-1 px-md-3 h-100'}>
            <Row className={'balance-items'}>
              <Col lg={12} className={'balance-information'}>
                {isFetching || !averageAnnualInterest ? (
                  <Loader />
                ) : (
                  <div className={'balance-amount'}>
                    {tHtml('common.percents', {
                      value: averageAnnualInterest.active_average_annual_interest ?? 0,
                    })}
                  </div>
                )}
              </Col>
              <Col lg={12} className={'balance-information d-flex align-items-center'}>
                <div className={'balance-description me-1'}>
                  {tHtml('dashboard.average_annual_interest')}
                  <i
                    className={'icon icon-info position-absolute'}
                    id={'average-annual-interest-tooltip-icon'}
                  />
                </div>
                <Tooltip
                  id={'average-annual-interest-tooltip-icon'}
                  text={tHtml('dashboard.investor.tooltip.average_annual_interest')}
                />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AvgAnnualInterest;
