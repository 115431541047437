import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Nav, NavLink } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';

import {
  StyledDesktopSidebarWrapper,
  StyledDesktopSidebarWrapperVerticalMenu,
} from './SidebarDashboard.style';

export interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarDashboard: React.FC = () => {
  const { t } = useTranslation();

  interface MenuItem {
    name: string;
    path: string;
    icon: string;
  }

  const menuItems: MenuItem[] = [
    {
      name: t('menu.account_overview'),
      path: RouteList.DASHBOARD,
      icon: 'icon-home-alt',
    },
    {
      name: t('menu.my_projects'),
      path: RouteList.PROJECT_DEVELOPER.PROJECTS.LIST,
      icon: 'icon-bag',
    },
    {
      name: t('menu.transactions'),
      path: RouteList.PROJECT_DEVELOPER.TRANSACTIONS.LIST,
      icon: 'icon-arrows-down-up',
    },
    {
      name: t('menu.payments'),
      path: RouteList.PROJECT_DEVELOPER.PAYMENTS.VIEW,
      icon: 'icon-credit-card',
    },
    {
      name: t('menu.documents'),
      path: RouteList.PROJECT_DEVELOPER.DOCUMENTS.LIST,
      icon: 'icon-file-text',
    },
  ];

  const renderMenu = (menu: MenuItem[]) => {
    return menu.map((menuItem, index) => (
      <li key={index}>
        <NavLink to={menuItem.path ?? '#'} tag={Link}>
          <i className={`icon ${menuItem.icon} me-2`} />
          <span>{menuItem.name}</span>
        </NavLink>
      </li>
    ));
  };

  return (
    <React.Fragment>
      <Nav vertical={true}>
        <StyledDesktopSidebarWrapper>
          <StyledDesktopSidebarWrapperVerticalMenu className={'list-unstyled'}>
            {renderMenu(menuItems)}
          </StyledDesktopSidebarWrapperVerticalMenu>
        </StyledDesktopSidebarWrapper>
      </Nav>
    </React.Fragment>
  );
};
export default SidebarDashboard;
