import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import SidebarImage from 'assets/images/img.png';
import Logo from 'components/Logo/Logo';
import LocaleSwitcher from 'components/LocaleSwitcher';
import Footer from 'components/Footer';
import { LANDING_ROUTES, RouteList } from 'routes';
import useTranslation from 'helpers/useTranslation';
import PrimaryButton from 'components/Formik/PrimaryButton';
import { generatePath, Link } from 'react-router-dom';
import { Form } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TextInput from 'components/Formik/TextInput';
import Api from 'api';
import { InquireApplicationsRequestDto } from 'api/types/guest/prospectApplication';
import { success } from 'services/toastr';

export const IntroView = () => {
  const { t, tHtml, currentLanguage } = useTranslation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  React.useEffect(() => {
    localStorage.removeItem('uuid');
  }, []);

  const EmailSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const [emailRequest] = React.useState<InquireApplicationsRequestDto>({
    email: '',
  });

  const onSubmit = React.useCallback(
    async (request: InquireApplicationsRequestDto) => {
      try {
        await Api.guest.prospect_application.inquireApplications(request).then(() => {
          success(tHtml('prospect_application.inquire_application_success'));
          setIsSubmitted(true);
        });
      } catch (e) {}
    },
    [tHtml],
  );

  const FormOrSuccessMessage = () =>
    isSubmitted ? (
      <div className="mt-4">{tHtml('prospect_application.inquire_application_success')}</div>
    ) : (
      <Formik initialValues={emailRequest} validationSchema={EmailSchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className={'clearfix'}>
              <TextInput name="email" />
            </div>
            <div className="mb-3">
              <PrimaryButton
                disabled={isSubmitting}
                type={'submit'}
                className={'site btn btn-highlight w-100 mt-4'}
              >
                {t('prospect_application.intro.fill_old')}
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    );

  return (
    <React.Fragment>
      <header />
      <div className="guest-layout prospect-application vertical-center">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center">
              <div className="content">
                <div className="header d-flex justify-content-between">
                  <Logo path={LANDING_ROUTES[currentLanguage].HOME} />
                  <LocaleSwitcher />
                </div>
                <div className="h-50 d-flex flex-column justify-content-center">
                  <Link
                    to={{
                      pathname: generatePath(
                        RouteList.GUEST.PROSPECT_APPLICATION.CREATE_APPLICATION,
                        { applicationId: undefined },
                      ),
                    }}
                  >
                    <PrimaryButton className={'site btn btn-primary w-100 mb-4'}>
                      {t('prospect_application.intro.fill_new')}
                    </PrimaryButton>
                  </Link>
                  <div className="d-flex justify-content-evenly align-items-center text-muted">
                    <div className="divider text-between" />
                    {tHtml('prospect_application.intro.or')}
                    <div className="divider text-between" />
                  </div>

                  <div>
                    <FormOrSuccessMessage />
                  </div>
                </div>
              </div>
            </Col>
            <Col className="d-none d-md-block">
              <div className="sidebar d-flex justify-content-center">
                <img src={SidebarImage} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
};
