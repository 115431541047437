import React, { useEffect, useState } from 'react';
import { ArrayHelpers } from 'formik';
import { Card } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { SingleSecurityDepositDto } from 'api/types/guest/prospectApplication';
import { DataBlock } from 'components/DataBlock';
import { FunctionalButton } from 'components/Button';
import SelectInput, { SelectOption } from 'components/Formik/SelectInput';
import { TextInput } from 'components/Formik';
import { ApplicationRealEstateType } from 'scopes/guest/helpers/enums/ApplicationRealEstateType';
import { ButtonsDiv, RealEstatePlotDiv, RealEstateTypeDiv } from './SecurityDeposits.style';

const SingleSecurityDeposit: React.FC<{
  deposit: SingleSecurityDepositDto;
  index: number;
  formikHelpers: any;
  formikArrayHelpers: ArrayHelpers;
}> = ({ deposit, index, formikHelpers, formikArrayHelpers }) => {
  const { t, tHtml } = useTranslation();
  const [show, setShow] = useState<boolean>(true);

  const [applicationRealEstateTypes] = useState<SelectOption[]>(() =>
    Object.values(ApplicationRealEstateType).map((value) => {
      return {
        label: t(`placeholder.application.real_estate_unique.${value}`),
        value: value,
      };
    }),
  );

  const realEstateType = `security_deposits[${index}].real_estate_type`;
  const realEstateUnique = `security_deposits[${index}].real_estate_unique`;
  const realEstatePlot = `security_deposits[${index}].real_estate_plot`;
  const realEstateAddress = `security_deposits[${index}].real_estate_address`;
  const realEstateValue = `security_deposits[${index}].real_estate_value`;

  const setVisibility = async () => {
    const errors = await formikHelpers.validateForm();

    let show = false;
    if (
      errors != undefined &&
      errors.security_deposits != undefined &&
      errors.security_deposits[index] != undefined
    ) {
      show = true;
    }

    setShow(show);
  };

  useEffect(() => {
    setVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    index,
    formikHelpers.validateForm,
    formikHelpers.submitCount,
    formikHelpers.values.security_deposits.length,
  ]);

  if (!show) {
    return (
      <div className={'step-form-cards'}>
        <Card body className={'light-pink'}>
          <div className={'d-flex justify-content-between align-items-center w-100'}>
            <RealEstateTypeDiv>
              <DataBlock
                title={t(`placeholder.application.real_estate_unique.${deposit.real_estate_type}`)}
                data={deposit.real_estate_address || deposit.real_estate_unique}
              />
            </RealEstateTypeDiv>
            <RealEstatePlotDiv>
              <DataBlock
                title={`${deposit.real_estate_plot} ${t(
                  'prospect_application.security_deposits.real_estate_plot',
                )}`}
                data={`€ ${deposit.real_estate_value}`}
              />
            </RealEstatePlotDiv>
            <ButtonsDiv className="buttons">
              <FunctionalButton onClick={() => setShow(true)}>
                {tHtml('common.edit')}
              </FunctionalButton>
              <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
                {tHtml('common.remove')}
              </FunctionalButton>
            </ButtonsDiv>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="sub-form">
      <div className="mb-4">
        <SelectInput
          name={realEstateType}
          options={applicationRealEstateTypes}
          placeholder={tHtml('placeholder.application.real_estate_type')}
          onChange={(value: any) => {
            if (value.value === ApplicationRealEstateType.LAND_PLOT)
              formikHelpers.setFieldValue(realEstateAddress, '');
            else formikHelpers.setFieldValue(realEstateUnique, '');
          }}
        />
      </div>
      {formikHelpers.values.security_deposits[index].real_estate_type ===
      ApplicationRealEstateType.LAND_PLOT ? (
        <div className="mb-4">
          <TextInput
            name={realEstateUnique}
            placeholder={t('placeholder.application.real_estate_unique')}
          />
        </div>
      ) : (
        <div className="mb-4">
          <TextInput
            name={realEstateAddress}
            placeholder={t('placeholder.application.real_estate_address')}
          />
        </div>
      )}
      <div className="mb-4">
        <TextInput
          type={'number'}
          name={realEstatePlot}
          placeholder={t('placeholder.application.real_estate_plot')}
        />
      </div>

      <div className="mb-4">
        <TextInput
          type={'number'}
          name={realEstateValue}
          placeholder={t('placeholder.application.real_estate_value')}
        />
      </div>

      <div className="mb-3">
        <div className="buttons">
          <FunctionalButton onClick={() => setVisibility()}>
            {tHtml('common.save')}
          </FunctionalButton>
          <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
            {tHtml('common.remove')}
          </FunctionalButton>
        </div>
      </div>
    </div>
  );
};

export default SingleSecurityDeposit;
