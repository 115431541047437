import Client from '../client';
import Http from '../../http';
import {
  ProjectDeveloperAllProjectsResponse,
  ProjectDeveloperProjectResponseDto,
  ProjectDeveloperProjectsListResponseDto,
} from '../../types/project-developer/project';
import { PaginationData, PaginationDataFilter } from '../../types';

class ProjectDeveloperProject extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_PROJECT: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/projects/project/{projectId}`,
      ),
      FETCH_DEVELOPER_PROJECTS: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/projects`,
      ),
      FILTER_DEVELOPER_PROJECT_LIST: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/projects/filter`,
      ),
    };
  }

  fetchProject = (projectId: string | number): Promise<ProjectDeveloperProjectResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT, { projectId });
    return this.http.get(url);
  };

  fetchProjects = (): Promise<ProjectDeveloperProjectsListResponseDto> => {
    return this.http.get(this.api.FETCH_DEVELOPER_PROJECTS);
  };

  filterDeveloperProjectsList = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ProjectDeveloperAllProjectsResponse>> => {
    return this.http.get(this.api.FILTER_DEVELOPER_PROJECT_LIST, { params: request });
  };
}

export default ProjectDeveloperProject;
