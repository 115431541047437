import React from 'react';
import * as Roles from 'helpers/auth/roles';
import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import { EditProfileLayout } from '../../containers';
import {
  ContactInfo,
  KnowYourCustomer,
  MarketingSettings,
  Password,
  UserFinancialRiskCalculator,
} from '.';

const EditProfileRoutes: any = () => [
  <AuthRoute
    path={RouteList.USER.EDIT.CONTACT_INFO}
    component={ContactInfo}
    layout={EditProfileLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.USER.EDIT.CONTACT_INFO}
    exact
  />,
  <AuthRoute
    path={RouteList.USER.EDIT.KNOW_YOUR_CUSTOMER}
    component={KnowYourCustomer}
    layout={EditProfileLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.USER.EDIT.KNOW_YOUR_CUSTOMER}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.FINANCIAL_RISK_CALCULATOR.VIEW}
    component={UserFinancialRiskCalculator}
    layout={EditProfileLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.INVESTOR.FINANCIAL_RISK_CALCULATOR.VIEW}
    exact
  />,
  <AuthRoute
    path={RouteList.USER.EDIT.PASSWORD}
    component={Password}
    layout={EditProfileLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.USER.EDIT.PASSWORD}
    exact
  />,
  <AuthRoute
    path={RouteList.USER.EDIT.MARKETING_SETTINGS}
    component={MarketingSettings}
    layout={EditProfileLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.USER.EDIT.MARKETING_SETTINGS}
    exact
  />,
];

export default EditProfileRoutes;
