import React from 'react';

import AuthRoute from 'routes/AuthRoute';
import { RouteList } from 'routes';
import SecondaryMarketBuyer from './Buyer';
import SecondaryMarketSeller from './Seller';
import PageLayout from 'scopes/investor/containers/PageLayout';
import SecondaryMarketInvestment from './Buyer/SecondaryMarketInvestment';
import ProjectInvestment from './Seller/ProjectInvestment';
import { Permission } from 'helpers/auth/permissions';

// TODO: check permissions
const SecondaryMarketRoutes: any = () => [
  <AuthRoute
    path={RouteList.INVESTOR.SECONDARY_MARKET.BUYER.OVERVIEW}
    component={SecondaryMarketBuyer}
    layout={PageLayout}
    permissions={[Permission.ENABLED_SECONDARY_MARKET]}
    key={RouteList.INVESTOR.SECONDARY_MARKET.BUYER.OVERVIEW}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.SECONDARY_MARKET.SELLER.OVERVIEW}
    component={SecondaryMarketSeller}
    layout={PageLayout}
    permissions={[Permission.ENABLED_SECONDARY_MARKET]}
    key={RouteList.INVESTOR.SECONDARY_MARKET.SELLER.OVERVIEW}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.SECONDARY_MARKET.BUYER.SECONDARY_MARKET_INVESTMENT}
    component={SecondaryMarketInvestment}
    layout={PageLayout}
    permissions={[Permission.ENABLED_SECONDARY_MARKET]}
    key={RouteList.INVESTOR.SECONDARY_MARKET.BUYER.SECONDARY_MARKET_INVESTMENT}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.SECONDARY_MARKET.SELLER.PROJECT_INVESTMENT}
    component={ProjectInvestment}
    layout={PageLayout}
    permissions={[Permission.ENABLED_SECONDARY_MARKET]}
    key={RouteList.INVESTOR.SECONDARY_MARKET.SELLER.PROJECT_INVESTMENT}
    exact
  />,
];

export default SecondaryMarketRoutes;
