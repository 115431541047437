import styled, { css } from 'styled-components';

export const ModalWrapper = styled.div`
  width: 100%;
`;
export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

interface StepDivProps {
  lastElement?: boolean;
}

export const StepDiv = styled.div<StepDivProps>`
  display: flex;
  flex-direction: row;
  margin: 0.25rem;
  padding-right: 0.25rem;
  align-items: center;
  width: 100%;

  .steps {
    margin: 0 1rem 0 0;

    ${({ lastElement }) =>
      !lastElement &&
      css`
        div::after {
          position: absolute;
          content: '';
          border-bottom: 0.188rem solid var(--profitus-step-completed-border-color);
          transform: rotate(90deg);
          width: 3.75rem;
          top: 3.6rem;
        }
      `}
    .number {
      z-index: 2;
    }

    .number::after {
      z-index: 1;
    }
  }
`;

export const StepText = styled.div`
  flex: 1;
  min-width: 7.5rem;
`;
