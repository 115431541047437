import { queries, useAccountQuery } from 'api/queries';
import { useQueryClient } from 'react-query';
import usePermissions from '../../helpers/usePermissions';
import { AuthJwtObject, jwtStorageKey } from './Auth.types';
import { isAuthJwtObject } from './Auth.guards';
import Api, { Http } from '../../api';
import { AuthUtils } from './Auth.utils';
import { navigate } from '../../helpers';
import useAppState from '../../modules/appState';
import { ScopeEnum } from '../../helpers/enums/ScopeEnum';
import { useRef } from 'react';
import Queue from './Queue';
import * as H from 'history';

const useAuth = () => {
  const queue = useRef(new Queue());

  const { setAppStarted, setIsImpersonated, setScope, setIsQuietImpersonated } = useAppState();

  const { setPermissions, resetPermissions, fetchPermissions } = usePermissions();

  const queryClient = useQueryClient();

  const query = useAccountQuery({ enabled: false });

  const isLoaded = query.isSuccess || query.isError,
    isLoading = query.isLoading;
  const isInitialLoading = query.isLoading || query.isFetching;

  const startSession = async (jwt?: string | AuthJwtObject, permissions?: string[]) => {
    await clearQueries();

    const jwtToken = !jwt ? AuthUtils.getJwt() : jwt;

    if (jwtToken === '' || jwtToken === undefined || jwtToken === null) {
      console.error('Invalid token');

      await endSession();

      return;
    }

    const token = isAuthJwtObject(jwtToken) ? jwtToken : AuthUtils.make(jwtToken);

    if (!token) {
      // console.log('Token is not valid', token);
      await endSession();

      return;
    }

    Http.setBearer(token.token);
    setIsImpersonated(AuthUtils.isImpersonated(token));
    setScope(AuthUtils.getScope(token));

    if (permissions === undefined || permissions.length === 0) {
      // console.log('no permissions, fetcinh');
      try {
        permissions = await fetchPermissions();

        if (permissions === undefined) {
          throw Error(`Failed to fetch permissions`);
        }

        setPermissions(permissions);
      } catch (e) {
        // console.log('failed to fetch or set perm', e);
        return endSession();
      }
    } else {
      // console.log('received perms from arguments');
      setPermissions(permissions);
    }

    const { isError, error } = await query.refetch();

    if (isError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw Error(`Failed to fetch profile: ${error}`);
    }
  };

  const clearQueries = () => {
    return Promise.all(
      Object.values(queries).map(async (queryKey) => {
        await queryClient.cancelQueries({ queryKey: [queryKey] });
        queryClient.removeQueries({ queryKey: [queryKey] });
      }),
    );
  };

  const endSession = async () => {
    appLoadingTaskStarted();

    try {
      await clearQueries();

      Http.removeBearer();
      AuthUtils.removeJwt();
      resetPermissions();
      setScope(ScopeEnum.Unknown);
      setIsImpersonated(false);
    } finally {
      appLoadingTaskFinished();
    }
  };

  const login = async (
    access_token: string,
    permissions?: string[],
    redirect_url: string | H.Location | null = null,
  ): Promise<void> => {
    appLoadingTaskStarted();

    try {
      Http.setBearer(access_token);
      AuthUtils.setJwt(access_token);

      await startSession(access_token, permissions);

      if (redirect_url) {
        navigate(redirect_url);
      } else {
        navigate('/dashboard');
      }
    } finally {
      appLoadingTaskFinished();
    }
  };

  const loginQuietImpersonate = async (
    access_token: string,
    permissions?: string[],
  ): Promise<void> => {
    appLoadingTaskStarted();

    try {
      Http.setBearer(access_token);
      AuthUtils.setJwt(access_token);

      await startSession(access_token, permissions);
      setIsQuietImpersonated(true);
    } finally {
      appLoadingTaskFinished();
    }
  };

  const logout = async (): Promise<void> => {
    Api.shared.authenticatedAccount.logoutCurrent();

    await endSession();
  };

  const refresh = async () => {
    try {
      appLoadingTaskStarted();
      // No token
      if (AuthUtils.getPayload() === null) {
        return;
      }

      if (AuthUtils.isExpired()) {
        await endSession();

        return;
      }

      Http.setBearer(AuthUtils.getJwt().token);
      const response = await Api.shared.authenticatedAccount.refreshCurrent();

      AuthUtils.setJwt(response.access_token);

      await startSession(response.access_token, response.permissions);
    } finally {
      appLoadingTaskFinished();
    }
  };

  const handleStorageTokenChange = async (e: StorageEvent): Promise<void> => {
    if (e.key !== jwtStorageKey && e.isTrusted) {
      return;
    }

    appLoadingTaskStarted();

    try {
      if (e.oldValue && !e.newValue) {
        // Means user has logged out
        await endSession();

        navigate('/login');
        return;
      }

      const newJwt = AuthUtils.make(e.newValue ?? '');

      if (!isAuthJwtObject(newJwt)) {
        await endSession();

        navigate('/login');

        return;
      }

      if (!e.oldValue) {
        // Means user has logged in
        await login(newJwt.token);

        navigate('/dashboard');

        return;
      }

      if (e.oldValue && e.newValue) {
        // Means token has been refreshed, probably
        const oldJwt = AuthUtils.make(e.oldValue);
        if (
          isAuthJwtObject(oldJwt) &&
          !AuthUtils.isExpired(newJwt) &&
          oldJwt.token !== newJwt.token
        ) {
          await startSession(newJwt);
        }
      }
    } finally {
      appLoadingTaskFinished();
    }
  };

  const appLoadingTaskStarted = () => {
    if (queue.current.isEmpty()) {
      setAppStarted(false);
    }

    queue.current.enqueue();
  };

  const appLoadingTaskFinished = () => {
    queue.current.dequeue();

    if (queue.current.isEmpty()) {
      setAppStarted(true);
    }
  };

  const mount = () => {
    window.addEventListener('storage', handleStorageTokenChange);
  };

  const dismount = () => {
    window.removeEventListener('storage', handleStorageTokenChange);
  };

  return {
    isLoaded,
    isLoading,
    isInitialLoading,
    login,
    loginQuietImpersonate,
    logout,
    refresh,
    startSession,
    endSession,
    mount,
    dismount,
  };
};
export { useAuth };
