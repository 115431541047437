import React, { ReactElement, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import SelectInput, { SelectEnumProps, SelectOption } from './SelectInput';

interface SelectYesNoProps extends SelectEnumProps {
  name: string;
  defaultValue?: string | number | undefined;
  hideLabel?: boolean;
  true_label?: string | ReactElement;
  false_label?: string | ReactElement;
}

const SelectYesNo: React.FC<SelectYesNoProps> = ({ true_label, false_label, ...props }) => {
  const { tHtml } = useTranslation();
  const [options] = useState<SelectOption[]>([
    {
      label: true_label ?? tHtml('common.yes'),
      value: true,
    },
    {
      label: false_label ?? tHtml('common.no'),
      value: false,
    },
  ]);

  return (
    <>
      <SelectInput options={options} {...props} />
    </>
  );
};
export default SelectYesNo;
