import React, { ReactElement } from 'react';
import useTranslation from 'helpers/useTranslation';

import FAQSection from 'components/Sections/FAQ/FAQSection';

export interface FAQOption {
  title: string | ReactElement;
  content: string | ReactElement;
}

const SectionFaq: React.FC = () => {
  const { tHtml } = useTranslation();

  const items: FAQOption[] = [
    {
      title: tHtml('wallet.faq.question_1'),
      content: tHtml('wallet.faq.answer_1'),
    },
    {
      title: tHtml('wallet.faq.question_2'),
      content: tHtml('wallet.faq.answer_2'),
    },
    {
      title: tHtml('wallet.faq.question_3'),
      content: tHtml('wallet.faq.answer_3'),
    },
    {
      title: tHtml('wallet.faq.question_4'),
      content: tHtml('wallet.faq.answer_4'),
    },
    {
      title: tHtml('wallet.faq.question_5'),
      content: tHtml('wallet.faq.answer_5'),
    },
    {
      title: tHtml('wallet.faq.question_6'),
      content: tHtml('wallet.faq.answer_6'),
    },
    {
      title: tHtml('wallet.faq.question_7'),
      content: tHtml('wallet.faq.answer_7'),
    },
  ];

  return <FAQSection items={items} />;
};

export default SectionFaq;
