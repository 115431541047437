import * as Yup from 'yup';

export default (): any => {
  return Yup.object().shape({
    full_name: Yup.string().fullName().required(),
    shares_direct: Yup.number().min(0).required(),
    shares_not_direct: Yup.number().min(0).required(),
    gov_code: Yup.string().governmentCode('nationality').required(),
    is_public_official: Yup.boolean().required(),
    nationality: Yup.string().required(),
    tax_residency_country: Yup.string().required(),
    shareholder_id: Yup.string().nullable(),
  });
};
