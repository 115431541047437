import React from 'react';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import { DataBlock } from 'components/DataBlock';
import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';
import UserProfileSectionMultipleActions from 'scopes/user/components/UserProfileSectionMultipleActions';
import EditLinkAction from 'scopes/user/components/EditLinkAction';
import EditHandlerAction from 'scopes/user/components/EditHandlerAction';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import EnableTwoFactorAuthModal from './Modals/2FA/EnableTwoFactorAuthModal';
import DisableTwoFactorAuthModal from './Modals/2FA/DisableTwoFactorAuthModal';

interface Props {
  two_factor_auth_enabled: boolean;
}

const Security = ({ two_factor_auth_enabled }: Props) => {
  const { tHtml, t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const handleEnable2FA = () => {
    showModal(<EnableTwoFactorAuthModal />);
  };

  const handleDisable2FA = () => {
    showModal(<DisableTwoFactorAuthModal />);
  };

  return (
    <UserProfileSectionMultipleActions
      menu={UserMenuEnum.Security}
      title={tHtml('menu.security')}
      buttonLink={RouteList.USER.EDIT.PASSWORD}
    >
      <div className={'mt-3 mt-lg-4'}>
        <div className={'profile-section flex-column-reverse flex-md-row'}>
          <div className={'content'}>
            <DataBlock title={tHtml('placeholder.password')} data={'**********'} />
          </div>
          <EditLinkAction buttonLink={RouteList.USER.EDIT.PASSWORD} />
        </div>
      </div>
      <div className={'mt-3 mt-lg-4'}>
        <div className={'profile-section flex-column-reverse flex-md-row'}>
          {two_factor_auth_enabled ? (
            <>
              <div className={'content'}>
                <DataBlock title={tHtml('placeholder.2fa')} data={tHtml('placeholder.activated')} />
              </div>
              <EditHandlerAction handler={handleDisable2FA} title={t('placeholder.disable')} />
            </>
          ) : (
            <>
              <div className={'content'}>
                <DataBlock
                  title={tHtml('placeholder.2fa')}
                  data={tHtml('placeholder.deactivated')}
                />
              </div>
              <EditHandlerAction handler={handleEnable2FA} title={t('placeholder.enable')} />
            </>
          )}
        </div>
      </div>
    </UserProfileSectionMultipleActions>
  );
};

export default Security;
