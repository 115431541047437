import React from 'react';
import { Col, ColProps, Row } from 'reactstrap';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';
import DownloadButton from '../DownloadButton';

type Props = SecondaryMarketInvestmentContextState;

const TabDocuments: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  if (!secondaryMarketInvestment) return null;

  const colProps: ColProps = {
    lg: 3,
    md: 6,
    sm: 6,
    xs: 6,
  };

  const documents = secondaryMarketInvestment.documents?.map((document, key) => (
    <Col {...colProps} className={'mb-3'} key={key}>
      <DownloadButton url={document.url} name={document.name} type={document.mime_type} />
    </Col>
  ));

  return (
    <>
      <section>
        <Row>{documents}</Row>
      </section>
    </>
  );
};

export default withSecondaryMarketInvestment(TabDocuments);
