import React from 'react';
import Skeleton from 'react-loading-skeleton';

const PartialReturnSkeleton: React.FC = () => (
  <div className={'mt-4'}>
    <Skeleton height={600} className={'mb-4'} />
    <Skeleton height={650} className={'mb-4'} />
  </div>
);

export default PartialReturnSkeleton;
