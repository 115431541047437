import React from 'react';
import useTranslation from 'helpers/useTranslation';

const ImpersonatedNotification: React.FC = () => {
  const { tHtml } = useTranslation();
  return (
    <React.Fragment>
      <div className={'impersonated-notification'}>{tHtml('authentication.impersonated')}</div>
    </React.Fragment>
  );
};

export default ImpersonatedNotification;
