import Client from '../client';
import Http from '../../http';
import { UserCompanyProjectDeveloperDocumentResponse } from '../../types/company/document';

class ProjectDeveloperDocument extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_PROJECT_DEVELOPER_DOCUMENTS: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/documents/filter`,
      ),
    };
  }

  fetchProjectDeveloperDocuments = (): Promise<UserCompanyProjectDeveloperDocumentResponse> => {
    return this.http.get(this.api.FETCH_PROJECT_DEVELOPER_DOCUMENTS);
  };
}

export default ProjectDeveloperDocument;
