import React from 'react';
import { Col, Input, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

interface Props {
  pageSizes: number[];
  currentPageSize: number;
  setPageSize: (pageSize: number) => void;
}

const PageSizes: React.FC<Props> = (props) => {
  const { t, tHtml } = useTranslation();

  const isActive = (pageSize: number) => {
    return props.currentPageSize == pageSize;
  };

  return (
    <>
      <Row className={'d-md-none mt-4 justify-content-end'}>
        <Col lg={2} xl={2} sm={6} xs={6} className={'text-end'}>
          <Input
            id={'page-sizes'}
            className="d-inline-block form-select w-100"
            type={'select'}
            value={props.currentPageSize}
            onChange={(e) => {
              props.setPageSize(Number(e.target.value));
            }}
          >
            {props.pageSizes.map((pageSize, key) => (
              <option key={key} value={pageSize}>
                {t('common.show')} {pageSize}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row className={'mt-4 justify-content-end d-none d-md-flex'}>
        <Col lg={4} className={'text-end page-sizes'}>
          {tHtml('common.show')}:
          {props.pageSizes.map((pageSize, key) => (
            <a
              key={key}
              onClick={() => props.setPageSize(Number(pageSize))}
              className={isActive(pageSize) ? 'page-sizes-item active' : 'page-sizes-item'}
              title={`${t('show.view')} ${pageSize}`}
            >
              {pageSize}
            </a>
          ))}
        </Col>
      </Row>
    </>
  );
};

export default PageSizes;
