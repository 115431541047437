import React from 'react';
import useTranslation from 'helpers/useTranslation';
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext,
} from 'formik';

import { ApplicationSingleSecurityDepositDto } from 'api/types/project-developer/application';
import { FunctionalButton } from 'components/Button';
import SingleSecurityDeposit from './SingleSecurityDeposit';

interface Props {
  values: any;
  fieldName: string;
  formikHelpers: any;
}

const SecurityDepositsFormBLock: React.FC<Props> = ({ values, fieldName, formikHelpers }) => {
  const { tHtml } = useTranslation();
  const [, meta] = useField(fieldName);
  const formik = useFormikContext();
  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;

  const initialDeposit: ApplicationSingleSecurityDepositDto = {
    real_estate_type: '',
    real_estate_unique: '',
    real_estate_plot: '',
    real_estate_address: '',
    real_estate_value: null,
    real_estate_appraiser: '',
    real_estate_appraised_at: '',
  };

  return (
    <div className={isError ? 'is-invalid' : ''}>
      {isError && (
        <div className={'invalid-feedback-visible ms-2'}>
          {typeof meta.error === 'string' && <ErrorMessage name={fieldName} />}
        </div>
      )}
      <FieldArray
        name={fieldName}
        render={(arrayHelpers: FieldArrayRenderProps) => {
          const securityDeposits = values.security_deposits;
          return (
            <>
              {securityDeposits.map(
                (deposit: ApplicationSingleSecurityDepositDto, index: number) => {
                  return (
                    <SingleSecurityDeposit
                      deposit={deposit}
                      index={index}
                      key={index}
                      formikHelpers={formikHelpers}
                      formikArrayHelpers={arrayHelpers}
                    />
                  );
                },
              )}

              <div className={'mt-4 mb-4'}>
                <FunctionalButton
                  type={'button'}
                  onClick={async () => {
                    arrayHelpers.push({ ...initialDeposit });
                    // const errors = await validateForm();
                    // setTouched(setNestedObjectValues<FormikTouched<IForm>>(errors, true));
                  }}
                >
                  {tHtml('application.add_deposit')}
                </FunctionalButton>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default SecurityDepositsFormBLock;
