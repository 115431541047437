import React from 'react';
import { Card } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const SectionConfigsSkeleton: React.FC = () => (
  <>
    <div
      className="d-flex flex-wrap gap-3 justify-content-evenly"
      id="auto-invest-configs-skeleton"
    >
      <Card className={'auto-investments-config'}>
        <Skeleton height={460} />
      </Card>
      <Card className={'auto-investments-config'}>
        <Skeleton height={460} />
      </Card>
      <Card className={'auto-investments-config'}>
        <Skeleton height={460} />
      </Card>
    </div>
  </>
);

export default SectionConfigsSkeleton;
