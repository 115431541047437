import React from 'react';

import DownloadButtonAuthorized from './DownloadButtonAuthorized';

interface Props {
  icon?: string;
  url: string;
  name: string;
  type: string;
  showMeta?: boolean;
}

const DownloadFileIcon: React.FC = () => (
  <div className={'download-icon'}>
    <i className="icon icon-download-file" />
  </div>
);

const DownloadLink: React.FC<Props> = ({ icon = 'download-file', showMeta = true, ...props }) => {
  return (
    <DownloadButtonAuthorized url={props.url}>
      <div className={'download-icon-wrapper'}>
        {icon && icon === 'download-file' ? <DownloadFileIcon /> : ''}
        <div>
          <h6 className={'mb-0'}>{props.name}</h6>
          {showMeta && <p className={'mb-0'}>{props.type}</p>}
        </div>
      </div>
    </DownloadButtonAuthorized>
  );
};

export default DownloadLink;
