import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from '../../../services/useAuth';

const LogoutTimer = () => {
  const { logout } = useAuth();

  useIdleTimer({
    onIdle: logout,
    timeout: 30 * 60 * 1000, // 30 minutes
    crossTab: true,
  });

  return <> </>;
};

export default LogoutTimer;
