import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Link, withRouter } from 'react-router-dom';

import { RouteList } from 'routes';
import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';
import NotFilledProfileSection from 'components/Profile/NotFilledProfileSection';
import { FunctionalButton } from 'components/Button';
import useProfileMenuState from './ProfileMenuState';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';

const FinancialRiskCalculator: React.FC = () => {
  const { tHtml } = useTranslation();

  const { data: userProfileData } = useUserCompanyProfileQuery();
  const { toggleProfileMenu } = useProfileMenuState();

  if (!userProfileData?.company?.has_financial_risk_calculator_filled) {
    return (
      <NotFilledProfileSection
        title={tHtml('menu.financial_risk_calculator')}
        buttonLink={RouteList.INVESTOR.FINANCIAL_RISK_CALCULATOR.VIEW}
      />
    );
  }

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div
          className={'d-flex w-100 justify-content-between align-items-center'}
          onClick={() => toggleProfileMenu(UserMenuEnum.FinancialRiskCalculator)}
        >
          <h2 className={'pointer me-2'}>{tHtml('menu.financial_risk_calculator')}</h2>
          <div>
            <Link to={RouteList.INVESTOR.FINANCIAL_RISK_CALCULATOR.VIEW}>
              <FunctionalButton
                className={'site btn btn-functional edit-button'}
                icon={'icon-pencil-edit'}
              >
                {tHtml('common.edit')}
              </FunctionalButton>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(FinancialRiskCalculator);
