import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import { UPDATE_APPLICATION } from '../module/ApplicationReducer';
import { action } from 'typesafe-actions';
import * as Yup from 'yup';

import Api from 'api';
import { PrimaryButton, TextInput } from 'components/Formik';
import transformErrors from 'helpers/formik';
import { ApplicationContext } from '../module/ApplicationContext';
import { redirect } from 'scopes/guest/helpers/utils';
import { ApplicationPageProps } from '../steps';
import { CompanyExecutiveDto } from 'api/types/guest/prospectApplication';
import Loader from 'components/Loader';
import CreatableSelectInput from 'components/Formik/CreatableSelectInput';
import { PhoneNumberInput } from 'components/Formik/Inputs';
import RepresentativeForm from './RepresentativeForm';
import ExecutiveForm from './ExecutiveForm';
import { ExecutiveTypeEnum } from 'scopes/guest/helpers/enums/ExecutiveTypeEnum';

const CompanyExecutive: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { state, dispatch } = useContext(ApplicationContext);
  const [initialFormValues, setInitialFormValues] = useState<CompanyExecutiveDto | null>(null);

  const executiveTypesOptions = Object.values(ExecutiveTypeEnum).map((type) => ({
    value: t(`prospect_application.executive.${type}`),
    label: t(`prospect_application.executive.${type}`),
  }));

  useEffect(() => {
    if (state.application?.prospect_application.id !== applicationId) return;
    const companyInfo = state.application?.prospect_application.company;
    if (companyInfo) {
      setInitialFormValues(companyInfo);
    } else {
      setInitialFormValues({
        executive_first_name: '',
        executive_type: '',
        executive_last_name: '',
        executive_gov_code: '',
        executive_address: '',
        executive_phone: '',
        executive_email: '',
        executive_country: '',
        executive_is_public_official: false,
        representative_first_name: null,
        representative_last_name: null,
        representative_email: null,
        representative_country: null,
        representative_gov_code: null,
        representative_phone: null,
        representative_address: null,
        representative_is_public_official: false,
        accountant_first_name: '',
        accountant_last_name: '',
        accountant_phone: '',
        accountant_email: '',
        representative_documents: [],
        executive_documents: [],
      });
    }
  }, [t, applicationId, state]);

  const onSubmit = useCallback(
    async (request: CompanyExecutiveDto, helpers: FormikHelpers<CompanyExecutiveDto>) => {
      try {
        await Api.guest.prospect_application
          .storeApplicationCompanyExecutive(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  const schema = Yup.object().shape({
    executive_type: Yup.string().required(),
    representative_first_name: Yup.string().when('executive_type', {
      is: t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`),
      then: Yup.string().nullable(),
      otherwise: Yup.string().firstName().required(),
    }),
    representative_last_name: Yup.string().when('executive_type', {
      is: t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`),
      then: Yup.string().nullable(),
      otherwise: Yup.string().lastName().required(),
    }),
    representative_email: Yup.string().when('executive_type', {
      is: t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`),
      then: Yup.string().email(t('validation.invalid')).nullable(),
      otherwise: Yup.string().email(t('validation.invalid')).required(),
    }),
    representative_country: Yup.string().when('executive_type', {
      is: t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`),
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(),
    }),
    representative_gov_code: Yup.string().when('executive_type', {
      is: t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`),
      then: Yup.string().nullable(),
      otherwise: Yup.string().required(),
    }),
    representative_phone: Yup.string().when('executive_type', {
      is: t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`),
      then: Yup.string().nullable(),
      otherwise: Yup.string().phone().required(),
    }),
    representative_address: Yup.string().when('executive_type', {
      is: t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`),
      then: Yup.string().nullable(),
      otherwise: Yup.string().addressLine().required(),
    }),
    representative_documents: Yup.array().when('executive_type', {
      is: t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`),
      then: Yup.array(),
      otherwise: Yup.array().min(1).required(),
    }),
    representative_is_public_official: Yup.boolean().required(),

    executive_first_name: Yup.string().firstName().required(),
    executive_last_name: Yup.string().lastName().required(),
    executive_gov_code: Yup.string().required(),
    executive_address: Yup.string().addressLine().required(),
    executive_phone: Yup.string().phone().required(),
    executive_email: Yup.string().email(t('validation.invalid')).required(),
    executive_country: Yup.string().required(),
    executive_is_public_official: Yup.boolean().required(),

    accountant_first_name: Yup.string().firstName().required(),
    accountant_last_name: Yup.string().lastName().required(),
    accountant_phone: Yup.string().phone().required(),
    accountant_email: Yup.string().email(t('validation.invalid')).required(),
    executive_documents: Yup.array().min(1).required(),
  });

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          {initialFormValues ? (
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              initialValues={initialFormValues}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {(helpers) => (
                <Form onSubmit={helpers.handleSubmit}>
                  <FormGroup>
                    <h2 className={'mb-4'}>{tHtml('prospect_application.company_executive')}</h2>
                    <div className="mb-3">
                      <CreatableSelectInput
                        name={'executive_type'}
                        formatCreateLabel={(val: string) => (
                          <>
                            {t('placeholder.prospect_application.company_executive.create_type', {
                              value: val,
                            })}
                          </>
                        )}
                        formatOptionLabel={(o: any) => o.value}
                        options={executiveTypesOptions}
                        placeholder={t(
                          'placeholder.prospect_application.company_executive.executive_type',
                        )}
                      />
                    </div>
                    <hr />
                    {helpers.values?.executive_type !==
                      t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`) && (
                      <RepresentativeForm {...helpers} />
                    )}
                    <ExecutiveForm {...helpers} />
                    <hr />
                    <h3>{tHtml('prospect_application.company_executive.accountant')}</h3>
                    <div className="mb-4 mt-4">
                      <TextInput
                        name={'accountant_first_name'}
                        placeholder={t('placeholder.first_name')}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'accountant_last_name'}
                        placeholder={t('placeholder.last_name')}
                      />
                    </div>
                    <div className="mb-4">
                      <PhoneNumberInput name={'accountant_phone'} label={t('placeholder.phone')} />
                    </div>
                    <div className="mb-4">
                      <TextInput name={'accountant_email'} placeholder={t('placeholder.email')} />
                    </div>
                  </FormGroup>
                  <hr />
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <PrimaryButton
                      className={'btn btn-grey w-25'}
                      type={'button'}
                      onClick={() => {
                        redirect(history, previousPage, applicationId);
                      }}
                    >
                      {tHtml('common.back')}
                    </PrimaryButton>
                    <PrimaryButton
                      className={'btn btn-primary w-25'}
                      submitting={helpers.isSubmitting}
                    >
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                  <p className="text-muted mt-5">
                    {tHtml('prospect_application.is_public_official_info')}
                  </p>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default withRouter(CompanyExecutive);
