import React from 'react';
import useTranslation from 'helpers/useTranslation';
import countries from 'i18n-iso-countries';

import Flag from 'components/Flag';

interface LabelProps {
  code: string;
}

const CountryLabel: React.FC<LabelProps> = ({ code }) => {
  const { tHtml } = useTranslation();
  let nCode = code;

  if (code.split('').length === 3) {
    nCode = countries.alpha3ToAlpha2(code) as string;
  }

  return (
    <div className={'d-flex align-items-center'}>
      <Flag countryCode={nCode} /> <div className={'ms-2'}>{tHtml('countries.' + code)}</div>
    </div>
  );
};

export default CountryLabel;
