import React from 'react';
import useTranslation from 'helpers/useTranslation';

import { SelectOption, SelectProps } from 'components/Formik/SelectInput';
import { SelectInput } from 'components/Formik';

const InterestSelectInput: React.FC<Omit<SelectProps, 'options'>> = (props) => {
  const { t } = useTranslation();

  const options: SelectOption[] = [
    { value: 0, label: t('secondary_market.filter.interest.from_0') },
    { value: 2.5, label: t('secondary_market.filter.interest.from_2.5') },
    { value: 5, label: t('secondary_market.filter.interest.from_5') },
    { value: 7.5, label: t('secondary_market.filter.interest.from_7.5') },
    { value: 10, label: t('secondary_market.filter.interest.from_10') },
  ];

  return (
    <>
      <SelectInput options={options} {...props} />
    </>
  );
};
export default InterestSelectInput;
