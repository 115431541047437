import React from 'react';
import useTranslation from 'helpers/useTranslation';
import Placeholder from 'assets/images/placeholder_63x40.svg';

interface Props {
  thumbnailUrl?: string;
}

const ProjectThumbnail: React.FC<Props> = ({ thumbnailUrl }) => {
  const { t } = useTranslation();
  return (
    <div className={'thumbnail'}>
      {thumbnailUrl && <img src={thumbnailUrl} alt={t('common.project')} />}
      {!thumbnailUrl && <img src={Placeholder} alt={t('common.project')} />}
    </div>
  );
};
export default ProjectThumbnail;
