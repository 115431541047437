import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { action } from 'typesafe-actions';

import Api from 'api';
import {
  ApplicationSecurityDepositSuretyDto,
  StoreApplicationSecurityDepositsRequestDto,
} from 'api/types/project-developer/application';
import { setGlobalLoading } from 'modules/app/actions';
import { PrimaryButton, TextInput } from 'components/Formik';
import transformErrors from 'helpers/formik';
import SelectYesNo from 'components/Formik/SelectYesNoInput';
import { ApplicationRealEstateType } from 'scopes/developer/helpers/enums/ApplicationRealEstateType';
import { ApplicationContext } from './module/ApplicationContext';
import { UPDATE_APPLICATION } from './module/ApplicationReducer';
import { redirect } from './CreateApplication';
import SecurityDepositsFormBLock from './SecurityDepositsFormBlock';
import { ApplicationPageProps } from './steps';

const initialSurety: ApplicationSecurityDepositSuretyDto = {
  surety_exists: false,
  surety_name: '',
  surety_code: '',
};

const SecurityDeposits: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { state, setApplicationId, dispatch } = useContext(ApplicationContext);

  useEffect(() => {
    if (applicationId !== undefined) {
      setApplicationId(applicationId);
    }

    setGlobalLoading(false);
  }, [t, applicationId, setApplicationId]);

  const [initialFormValues, setInitialFormValues] =
    useState<StoreApplicationSecurityDepositsRequestDto>({
      security_deposits: [],
      surety: initialSurety,
    });

  useEffect(() => {
    if (state.application?.security_deposits) {
      const deposits = state.application?.security_deposits;
      setInitialFormValues({
        security_deposits: deposits.security_deposits ? [...deposits.security_deposits] : [],
        surety: deposits.surety ?? null,
      });
    }
  }, [state]);

  // Proper validation happens only onSubmit. We should trigger validation when a new security deposit is added
  const onSubmit = useCallback(
    async (
      request: StoreApplicationSecurityDepositsRequestDto,
      helpers: FormikHelpers<StoreApplicationSecurityDepositsRequestDto>,
    ) => {
      if (request.surety) {
        request.surety.surety_exists = request.surety?.surety_exists === true;
      }
      try {
        await Api.projectDeveloper.applications
          .storeApplicationSecurityDeposits(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  const schema = Yup.object().shape({
    security_deposits: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          real_estate_type: Yup.string()
            .oneOf(Object.values(ApplicationRealEstateType).map((value) => value))
            .required(),
          real_estate_unique: Yup.string().required(),
          real_estate_plot: Yup.string().required(),
          real_estate_address: Yup.string().required(),
          real_estate_value: Yup.number().nullable(),
          real_estate_appraiser: Yup.string(),
          real_estate_appraised_at: Yup.date(),
        }),
      ),
    surety: Yup.object().shape({
      surety_exists: Yup.boolean().required(),
      surety_name: Yup.string().when('surety_exists', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(),
      }),
      surety_code: Yup.string().when('surety_exists', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(),
      }),
    }),
  });

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          <h1 className="mb-4 mb-md-6">{tHtml('application.security_deposits')}</h1>
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            initialValues={initialFormValues}
            validationSchema={schema}
            onSubmit={onSubmit}
          >
            {(helpers) => (
              <Form onSubmit={helpers.handleSubmit}>
                <FormGroup>
                  <div className="mb-3 mt-6">
                    <h4 className={'mb-5'}>{tHtml('application.surety')}</h4>
                    <SelectYesNo
                      name={'surety.surety_exists'}
                      placeholder={t('placeholder.application.surety_exists')}
                    />
                  </div>
                  {(helpers.values?.surety?.surety_exists === 'true' ||
                    helpers.values?.surety?.surety_exists === true) && (
                    <>
                      <div className="mb-3">
                        <TextInput
                          name={'surety.surety_name'}
                          placeholder={t('placeholder.application.surety_name')}
                        />
                      </div>
                      <div className="mb-3">
                        <TextInput
                          name={'surety.surety_code'}
                          placeholder={t('placeholder.application.surety_code')}
                        />
                      </div>
                    </>
                  )}
                </FormGroup>
                <h4 className={'mt-5'}>{tHtml('application.deposits')}</h4>
                <SecurityDepositsFormBLock
                  fieldName={'security_deposits'}
                  values={helpers.values}
                  formikHelpers={helpers}
                />

                <hr />
                <div className={'d-flex justify-content-between align-items-center'}>
                  <PrimaryButton
                    className={'btn btn-grey w-25'}
                    type={'button'}
                    onClick={() => {
                      redirect(history, previousPage, applicationId);
                    }}
                  >
                    {tHtml('common.back')}
                  </PrimaryButton>
                  <PrimaryButton
                    className={'btn btn-primary w-25'}
                    submitting={helpers.isSubmitting}
                  >
                    {tHtml('common.continue')}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Row>
    </>
  );
};
export default withRouter(SecurityDeposits);
