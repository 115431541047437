import React, { useEffect } from 'react';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import { InvestorKybForm, ProjectDeveloperKybForm } from 'components/KYB';
import StepFormHeader from 'containers/StepFormLayout/StepFormHeader';
import StepFormStepsWizard from 'containers/StepFormLayout/StepFormStepsWizard';
import { createCompanySteps } from './steps';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';

interface LocationProps {
  state: {
    id: string;
    type: string;
  };
}

const CreateCompanyKYB: React.FC = () => {
  const { tHtml } = useTranslation();
  const location: LocationProps = useLocation();
  const history = useHistory();
  const { id, type } = location.state;

  useEffect(() => {
    if (!location.state || !id || !type) history.push(RouteList.NOT_FOUND);
  }, [id, type, history, location]);

  const isCompanyTypeInvestor: boolean = type === UserTypeEnum.INVESTOR;

  return (
    <>
      <StepFormHeader />
      <Container className={'main-content d-block mx-auto px-2 px-sm-4 px-md-6'}>
        <StepFormStepsWizard steps={createCompanySteps} />
        <Row>
          <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
            <h1 className="mb-4 mb-md-6">{tHtml('placeholder.company.get_information')}</h1>
            {isCompanyTypeInvestor ? (
              <InvestorKybForm companyId={id} />
            ) : (
              <ProjectDeveloperKybForm companyId={id} />
            )}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(CreateCompanyKYB);
