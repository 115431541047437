import React from 'react';
import { ApplicationState } from '../module/ApplicationReducer';
import SecurityDepositsReview from './Shared/SecurityDepositsReview';
import DocumentsReview from './Shared/DocumentsReview';
import useTranslation from 'helpers/useTranslation';

interface RealEstateDevelopmentReviewProps {
  state: ApplicationState;
}

const RealEstateDevelopmentReview = ({ state }: RealEstateDevelopmentReviewProps) => {
  const { tHtml } = useTranslation();

  return (
    <div className="content">
      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.credit_purpose')}
          </div>
          <div className="text review-data-single">
            {tHtml(`application.type.${state.application?.credit_purpose}`)}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.required_amount')}
          </div>
          <div className="text review-data-single">{state.application?.required_amount}</div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.minimal_credit_amount')}
          </div>
          <div className="text review-data-single">{state.application?.minimal_credit_amount}</div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.credit_return')}
          </div>
          <div className="text review-data-single">
            {tHtml(`placeholder.credit_return.${state.application?.credit_return}`)}
          </div>
        </div>
      </div>

      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.interest_frequency')}
          </div>
          <div className="text review-data-single">
            {tHtml(`application.interest_frequency.${state.application?.interest_frequency}`)}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.source_of_funds')}
          </div>
          <div className="text review-data-single">{state.application?.source_of_funds}</div>
        </div>
        <br />
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.project_name')}
          </div>
          <div className="text review-data-single">{state.application?.project_name}</div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.project_address')}
          </div>
          <div className="text review-data-single">
            {state.application?.development?.project_address}
          </div>
        </div>
      </div>

      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.object_name')}
          </div>
          <div className="text review-data-single">
            {state.application?.development?.object_name}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.project_status')}
          </div>
          <div className="text review-data-single">
            {state.application?.development?.project_status}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.start_construction_at')}
          </div>
          <div className="text review-data-single">
            {state.application?.development?.start_construction_at}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.end_construction_at')}
          </div>
          <div className="text review-data-single">
            {state.application?.development?.end_construction_at}
          </div>
        </div>
      </div>

      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.number_of_re')}
          </div>
          <div className="text review-data-single">
            {state.application?.development?.number_of_re}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.total_area')}
          </div>
          <div className="text review-data-single">
            {state.application?.development?.total_area}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.useful_area')}
          </div>
          <div className="text review-data-single">
            {state.application?.development?.useful_area}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.credit_duration')}
          </div>
          <div className="text review-data-single">
            {tHtml('placeholder.credit_duration.custom', {
              month: state.application?.credit_duration,
            })}
          </div>
        </div>
      </div>

      <hr />

      <h2>{tHtml('application.estimate')}</h2>

      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.project_plot_price')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.project_plot_price}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.project_engineering_price')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.project_engineering_price}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('application.existing_investment_documentation_price_title')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.project_documentation_price}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.project_construction_price')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.project_construction_price}
          </div>
        </div>
      </div>

      <hr />

      <h2>{tHtml('application.estimate.project_investment')}</h2>
      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.existing_investment_plot_price')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.existing_investment_plot_price}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.existing_investment_engineering_price')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.existing_investment_engineering_price}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.existing_investment_documentation_price_title')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.existing_investment_documentation_price}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.existing_investment_construction_price')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.existing_investment_construction_price}
          </div>
        </div>
      </div>

      <hr />

      <h2>{tHtml('application.estimate.presale_contracts')}</h2>

      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.presale_contracts')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.presale_contracts}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.presale_contracts_amount')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.presale_contracts_amount}
          </div>
        </div>
        <div className="data-block review-data" />
        <div className="data-block review-data" />
      </div>

      <hr />

      <h2>{tHtml('application.estimate.notarised_contracts')}</h2>
      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.notarised_contracts')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.notarised_contracts}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.notarised_contracts_amount')}
          </div>
          <div className="text review-data-single">
            {state.application?.estimate?.notarised_contracts_amount}
          </div>
        </div>
        <div className="data-block review-data" />
        <div className="data-block review-data" />
        <br />
      </div>
      <div className="data-block review-data">
        <div className="title review-data-single">
          {tHtml('placeholder.application.building_permit')}
        </div>
        <div className="text review-data-single">
          {state.application?.estimate?.building_permit
            ? tHtml('common.present')
            : tHtml('common.absent')}
        </div>
      </div>

      <hr />

      <h2>{tHtml('application.security_deposits')}</h2>

      <SecurityDepositsReview state={state} />

      <hr />
      <br />
      <h2>{tHtml('application.documents')}</h2>

      <DocumentsReview state={state} />
    </div>
  );
};

export default RealEstateDevelopmentReview;
