import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import Header from './Header/Desktop/Header';
import useWindowSize, { smallerThanLg } from 'helpers/useWindowsSize';
import HeaderMobile from './Header/Mobile/HeaderMobile';
import { BaseLayout } from 'containers';

const NotFound: React.FC = ({ children }) => {
  const { t, tHtml } = useTranslation();

  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = React.useState(!smallerThanLg(width));
  const toggle = () => setIsOpen((value) => !value);

  if (!isOpen && !smallerThanLg(width)) setIsOpen(true);

  return (
    <React.Fragment>
      <BaseLayout>
        <div className="not-found-layout">
          {smallerThanLg(width) ? (
            <HeaderMobile toggleMenuCallback={toggle} />
          ) : (
            <Header toggleMenuCallback={toggle} />
          )}
          <main>
            <i className={'icon icon-data-not-found'} />
            <div className={'title'}>{tHtml('not_found.page_not_found')}</div>
            <div className={'text'}>{tHtml('not_found.useful_links')}</div>
            {children}
            <div className="contact-box">
              <div className={'text'}>{tHtml('not_found.have_questions')}</div>
              <a className="active" href={`mailto:${t('not_found.support_email')}`}>
                {tHtml('not_found.support_email')}
              </a>
            </div>
          </main>
        </div>
      </BaseLayout>
    </React.Fragment>
  );
};

export default withRouter(NotFound);
