import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouteList } from 'routes';
import { Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

interface MenuItem {
  name: string;
  path: string;
  icon: string;
}

const NotFound: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  const menuItems: MenuItem[] = [
    {
      name: t('menu.account_overview'),
      path: RouteList.DASHBOARD,
      icon: 'icon-home-alt',
    },
    {
      name: t('menu.my_investments'),
      path: RouteList.INVESTOR.MY_INVESTMENTS.LIST,
      icon: 'icon-bag',
    },
    {
      name: t('menu.transactions'),
      path: RouteList.INVESTOR.TRANSACTIONS.LIST,
      icon: 'icon-arrows-down-up',
    },
    {
      name: t('menu.affiliate'),
      path: RouteList.INVESTOR.AFFILIATE.VIEW,
      icon: 'icon-handshake',
    },
  ];

  return (
    <nav>
      {menuItems.map((item, index) => (
        <Link to={{ pathname: item.path }} key={index} className="link-box">
          <div className="icon-box">
            <i className={`icon ${item.icon}`} />
          </div>
          <span className={'text-link'}>{item.name}</span>
        </Link>
      ))}
    </nav>
  );
};

export default withRouter(NotFound);
