import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { CompanyManagementStructureEnum } from 'helpers/enums/CompanyManagementStructureEnum';
import SelectInput, { SelectEnumProps, SelectOption } from '../SelectInput';

const SelectCompanyManagementStructureInput: React.FC<SelectEnumProps> = ({
  name,
  placeholder,
  highlightLabel,
}) => {
  const { t } = useTranslation();

  const [options] = useState<SelectOption[]>([
    {
      label: t('placeholder.company.management_structure.director'),
      value: CompanyManagementStructureEnum.Director,
    },
    {
      label: t('placeholder.company.management_structure.board'),
      value: CompanyManagementStructureEnum.Board,
    },
    {
      label: t('placeholder.company.management_structure.shareholder_meeting'),
      value: CompanyManagementStructureEnum.ShareholderMeeting,
    },
    {
      label: t('placeholder.company.management_structure.supervisors_board'),
      value: CompanyManagementStructureEnum.SupervisorsBoard,
    },
  ]);

  return (
    <SelectInput
      name={name}
      options={options}
      placeholder={placeholder}
      highlightLabel={highlightLabel}
    />
  );
};

export default SelectCompanyManagementStructureInput;
