import React, { useEffect, useState } from 'react';
import { endOfDay, endOfMonth, startOfDay, startOfMonth, subDays } from 'date-fns';
import useTranslation from 'helpers/useTranslation';

interface Props {
  startDate?: Date | null;
  endDate?: Date | null;
  onChange: (dates: [Date | null, Date | null]) => void;
}

const PredefinedDates: React.FC<Props> = (props) => {
  const [dates, setDates] = useState<Date[]>([props.startDate as Date, props.endDate as Date]);
  const { tHtml } = useTranslation();

  useEffect(() => {
    setDates([props.startDate as Date, props.endDate as Date]);
  }, [props.startDate, props.endDate]);

  const changeDates = (dates: [Date | null, Date | null]) => {
    props.onChange(dates);
  };

  const isDatesEqual = (dates: Date[], otherDates: Date[]) => {
    if (dates.length != otherDates.length || dates.length != 2) return false;

    if (!dates[0] || !dates[1] || !otherDates[0] || !otherDates[1]) return false;

    return (
      dates[0].toDateString() == otherDates[0].toDateString() &&
      dates[1].toDateString() == otherDates[1].toDateString()
    );
  };

  const getToday = (): [Date, Date] => {
    const date = new Date();

    return [startOfDay(date), endOfDay(date)];
  };

  const getYesterday = (): [Date, Date] => {
    const date = subDays(new Date(), 1);
    return [startOfDay(date), endOfDay(date)];
  };

  const getLast7Days = (): [Date, Date] => {
    const startDate = subDays(new Date(), 6);
    const endDate = new Date();
    return [startOfDay(startDate), endOfDay(endDate)];
  };

  const getLast30Days = (): [Date, Date] => {
    const startDate = subDays(new Date(), 29);
    const endDate = new Date();
    return [startOfDay(startDate), endOfDay(endDate)];
  };

  const getCurrentMonth = (): [Date, Date] => {
    const date = new Date();
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);
    return [startDate, endDate];
  };

  const getLastMonth = (): [Date, Date] => {
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);
    return [startDate, endDate];
  };

  return (
    <div className={'predefined-dates'}>
      <a
        className={isDatesEqual(getToday(), dates) ? 'active' : ''}
        onClick={() => changeDates(getToday())}
      >
        {tHtml('common.today')}
      </a>
      <a
        className={isDatesEqual(getYesterday(), dates) ? 'active' : ''}
        onClick={() => changeDates(getYesterday())}
      >
        {tHtml('common.yesterday')}
      </a>
      <a
        className={isDatesEqual(getLast7Days(), dates) ? 'active' : ''}
        onClick={() => changeDates(getLast7Days())}
      >
        {tHtml('common.last_seven_days')}
      </a>
      <a
        className={isDatesEqual(getLast30Days(), dates) ? 'active' : ''}
        onClick={() => changeDates(getLast30Days())}
      >
        {tHtml('common.last_thirty_days')}
      </a>
      <a
        className={isDatesEqual(getCurrentMonth(), dates) ? 'active' : ''}
        onClick={() => changeDates(getCurrentMonth())}
      >
        {tHtml('common.this_month')}
      </a>
      <a
        className={isDatesEqual(getLastMonth(), dates) ? 'active' : ''}
        onClick={() => changeDates(getLastMonth())}
      >
        {tHtml('common.last_month')}
      </a>
    </div>
  );
};

export default PredefinedDates;
