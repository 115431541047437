import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import { PieChart } from 'components/PieChart';
import { PieChartDataEntry } from 'components/PieChart/PieChart';
import {
  calculateChartTotalAmount,
  getChartValue,
  InvestorStatisticsChartData,
  InvestorStatisticsDataByKey,
  transformToChartData,
} from './chartHelpers';

interface Props {
  countryData: InvestorStatisticsDataByKey;
  amountColumn: string;
}

const InvestorPortfolioByCountry: React.FC<Props> = ({ countryData, amountColumn }) => {
  const [pieChartData, setPieChartData] = useState<PieChartDataEntry[]>([]);
  const [pieChartActiveIndex, setPieChartActiveIndex] = useState<number>(-1);
  const [countryDataArr, setCountryDataArr] = useState<InvestorStatisticsChartData>([]);

  const { t, tHtml } = useTranslation();

  useEffect(() => {
    setCountryDataArr(transformToChartData(countryData));
  }, [setCountryDataArr, countryData]);

  const totalAmount = calculateChartTotalAmount(countryDataArr, amountColumn);

  useEffect(() => {
    setPieChartData(
      countryDataArr.map((item) => {
        return {
          name: item['name'] ? t('countries.' + item['name']) : t('countries.unknown'),
          value: getChartValue(item, totalAmount, amountColumn),
        };
      }),
    );
  }, [amountColumn, countryDataArr, t, totalAmount]);

  return (
    <Row>
      <Col xs={12}>
        <PieChart
          data={pieChartData}
          indexState={{ index: pieChartActiveIndex, setIndex: setPieChartActiveIndex }}
        />
        <div className={'pie-chart-legend'}>
          {pieChartData.map((item: PieChartDataEntry, index: number) => (
            <div key={index} className={'legend'}>
              <span className={'me-2'}>{item.name}</span>{' '}
              {tHtml('common.percents', { value: item.value })}
            </div>
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default InvestorPortfolioByCountry;
