import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Cell, Column } from 'react-table';

import Api from 'api';
import { RouteList } from 'routes';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { OperationsResponseDto } from 'api/types/shared/wallet';
import TableOverview from 'components/Table/TableOverview';
import WalletOperationTypeBadge from 'components/Badge/WalletOperationTypeBadge';
import WalletOperationStatusBadge from 'components/Badge/WalletOperationStatusBadge';
import WalletOperationAmountBadge from 'components/Badge/WalletOperationAmountBadge';
import { TableInitialSortBy } from 'api/types/common';
import { AmountFilter, DateFilter } from 'components/Table/Table';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import { downloadCSVFile, downloadXLSXFile, downloadXMLFile } from 'services/media';

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'executed_at',
    desc: true,
  },
];

const Transactions: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const [allTransactionData, setAllTransactionData] =
    useState<PaginationData<OperationsResponseDto>>();
  const [allTransactionPaginationRequest, setAllTransactionPaginationRequest] = useState<
    PaginationDataFilter | undefined
  >();

  const [investmentRepaymentTransactionData, setInvestmentRepaymentTransactionData] =
    useState<PaginationData<OperationsResponseDto>>();
  const [
    investmentRepaymentTransactionPaginationRequest,
    setInvestmentRepaymentTransactionPaginationRequest,
  ] = useState<PaginationDataFilter | undefined>();

  const [investmentTransactionData, setInvestmentTransactionData] =
    useState<PaginationData<OperationsResponseDto>>();
  const [investmentTransactionPaginationRequest, setInvestmentTransactionPaginationRequest] =
    useState<PaginationDataFilter | undefined>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.investor_transaction_type'),
        accessor: 'type',
        sortType: 'string',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return (
            <WalletOperationTypeBadge
              type={row.type}
              private_data={row.private_data}
              amount={row.amount}
            />
          );
        },
      },
      {
        Header: t('table.executed_at'),
        accessor: 'executed_at',
        sortType: '',
        Filter: DateFilter,
        Cell: (cell: Cell<OperationsResponseDto>) => (
          <span>{tHtml('common.date_full', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return <WalletOperationStatusBadge status={row.status} />;
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return <WalletOperationAmountBadge amount={row.amount} is_receiver={row.is_receiver} />;
        },
        Filter: AmountFilter,
        filterProps: {
          start: -1000,
          end: 50000,
          ignoreLimits: true,
        },
      },
    ],
    [t, tHtml],
  );

  const fetchAllTransactions = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setAllTransactionPaginationRequest(request);
      return Api.investor.transactions
        .fetchAllTransactions(request)
        .then((response) => setAllTransactionData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const fetchInvestmentsTransactions = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setInvestmentTransactionPaginationRequest(request);
      return Api.investor.transactions
        .fetchInvestmentTransactions(request)
        .then((response) => setInvestmentTransactionData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const fetchInvestmentRepaymentTransactions = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setInvestmentRepaymentTransactionPaginationRequest(request);
      return Api.investor.transactions
        .fetchInvestmentRepaymentTransactions(request)
        .then((response) => setInvestmentRepaymentTransactionData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const exportAllTransactionsCsv = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.investor.transactions.exportAllTransactions(
      CommonDocumentTypeEnum.CSV,
      request,
    );

    await downloadCSVFile(response.url, 'transactions-all');
  };

  const exportAllTransactionsXlsx = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.investor.transactions.exportAllTransactions(
      CommonDocumentTypeEnum.XLSX,
      request,
    );

    await downloadXLSXFile(response.url, 'transactions-all');
  };

  const exportAllTransactionsXml = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.investor.transactions.exportAllTransactions(
      CommonDocumentTypeEnum.XML,
      request,
    );

    await downloadXMLFile(response.url);
  };

  const exportInvestmentRepaymentTransactionCsv =
    (request: PaginationDataFilter | undefined) => async () => {
      const response = await Api.investor.transactions.exportInvestmentRepaymentTransactions(
        CommonDocumentTypeEnum.CSV,
        request,
      );
      await downloadCSVFile(response.url, 'investment-repayment-transactions');
    };

  const exportInvestmentRepaymentTransactionXlsx =
    (request: PaginationDataFilter | undefined) => async () => {
      const response = await Api.investor.transactions.exportInvestmentRepaymentTransactions(
        CommonDocumentTypeEnum.XLSX,
        request,
      );
      await downloadXLSXFile(response.url, 'investment-repayment-transactions');
    };

  const exportInvestmentRepaymentTransactionXml =
    (request: PaginationDataFilter | undefined) => async () => {
      const response = await Api.investor.transactions.exportInvestmentRepaymentTransactions(
        CommonDocumentTypeEnum.XML,
        request,
      );

      await downloadXMLFile(response.url);
    };

  const exportInvestmentTransactionCsv =
    (request: PaginationDataFilter | undefined) => async () => {
      const response = await Api.investor.transactions.exportInvestmentTransactions(
        CommonDocumentTypeEnum.CSV,
        request,
      );
      await downloadCSVFile(response.url, 'investment-transactions');
    };

  const exportInvestmentTransactionXlsx =
    (request: PaginationDataFilter | undefined) => async () => {
      const response = await Api.investor.transactions.exportInvestmentTransactions(
        CommonDocumentTypeEnum.XLSX,
        request,
      );
      await downloadXLSXFile(response.url, 'investment-transactions');
    };

  const exportInvestmentTransactionXml =
    (request: PaginationDataFilter | undefined) => async () => {
      const response = await Api.investor.transactions.exportInvestmentTransactions(
        CommonDocumentTypeEnum.XML,
        request,
      );
      await downloadXMLFile(response.url);
    };

  return (
    <div className={'operations py-4'}>
      <TableOverview
        title={tHtml('transactions.all_investor')}
        columns={columns}
        link={RouteList.INVESTOR.TRANSACTIONS.ALL}
        data={allTransactionData}
        fetchData={fetchAllTransactions}
        initialSortBy={INITIAL_SORT_ORDER}
        exportLinks={[
          {
            onClick: exportAllTransactionsCsv,
            request: allTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.CSV,
          },
          {
            onClick: exportAllTransactionsXlsx,
            request: allTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.XLSX,
          },
          {
            onClick: exportAllTransactionsXml,
            request: allTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.XML,
          },
        ]}
      />
      <TableOverview
        title={tHtml('transactions.investment_repayment')}
        columns={columns}
        link={RouteList.INVESTOR.TRANSACTIONS.INVESTMENT_REPAYMENT}
        data={investmentRepaymentTransactionData}
        fetchData={fetchInvestmentRepaymentTransactions}
        initialSortBy={INITIAL_SORT_ORDER}
        exportLinks={[
          {
            onClick: exportInvestmentRepaymentTransactionCsv,
            request: investmentRepaymentTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.CSV,
          },
          {
            onClick: exportInvestmentRepaymentTransactionXlsx,
            request: investmentRepaymentTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.XLSX,
          },
          {
            onClick: exportInvestmentRepaymentTransactionXml,
            request: investmentRepaymentTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.XML,
          },
        ]}
      />
      <TableOverview
        title={tHtml('transactions.investment')}
        columns={columns}
        link={RouteList.INVESTOR.TRANSACTIONS.INVESTMENT}
        data={investmentTransactionData}
        fetchData={fetchInvestmentsTransactions}
        initialSortBy={INITIAL_SORT_ORDER}
        exportLinks={[
          {
            onClick: exportInvestmentTransactionCsv,
            request: investmentTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.CSV,
          },
          {
            onClick: exportInvestmentTransactionXlsx,
            request: investmentTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.XLSX,
          },
          {
            onClick: exportInvestmentTransactionXml,
            request: investmentTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.XML,
          },
        ]}
      />
    </div>
  );
};
export default Transactions;
