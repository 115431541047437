import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';

export const StyledDesktopThemeSwitcher = styled.div`
  display: flex;
  flex-direction: column;
  width: 1.688rem;
  height: 3.125rem;
  background-color: var(--profitus-theme-switcher-bg-color);
  border-radius: 0.563rem;
  gap: 0.188rem;
  align-items: center;
  padding: 0.313rem 0;

  ${breakpointDown.LG} {
    margin-right: 1rem;
    flex-direction: row;
    width: 3.5rem;
    height: 2rem;
    justify-content: space-evenly;
  }
`;

export const StyledDesktopThemeSwitcherButton = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.313rem;
  height: 1.313rem;
  padding: 0;
`;

export const StyledDesktopThemeSwitcherButtonLight = styled(StyledDesktopThemeSwitcherButton)`
  background-color: var(--profitus-theme-switcher-light-color);
  border-radius: 0.375rem;

  img {
    width: 0.875rem;
  }
`;

export const StyledDesktopThemeSwitcherButtonDark = styled(StyledDesktopThemeSwitcherButton)`
  background-color: var(--profitus-theme-switcher-dark-color);
  border-radius: 0.375rem;

  img {
    width: 0.875rem;
  }
`;
