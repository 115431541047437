import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import { ProjectCard, ProjectCardApp } from './components/ProjectCard';
import { ProjectListSkeleton } from './components/ProjectListSkeleton';
import { useInfiniteQuery } from 'react-query';
import { queries } from 'api/queries';
import Api from 'api';
import useTranslation from 'helpers/useTranslation';
import SkeletonLoader from 'components/SkeletonLoader';
import useDisplay from 'helpers/useDisplay';

const ProjectList: React.FC = () => {
  const { isApp } = useDisplay();
  const { currentLanguage, t } = useTranslation();

  const { data, isLoading, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [queries.projects, currentLanguage],
    async ({ pageParam = 1 }) => {
      return await Api.investor.projects.fetchProjects({ page: pageParam, limit: 24 });
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.meta.current_page < lastPage.meta.last_page
          ? lastPage.meta.current_page + 1
          : undefined;
      },
      keepPreviousData: true,
    },
  );

  return (
    <div className={'mb-6'}>
      <SkeletonLoader loading={isLoading} customPlaceholder={<ProjectListSkeleton />}>
        <Row>
          {data?.pages.map((group) =>
            group.data.map((project) => (
              <Col xl="3" lg="4" sm="6" key={project.id}>
                {isApp ? <ProjectCardApp project={project} /> : <ProjectCard project={project} />}
              </Col>
            )),
          )}
          {isFetching && <ProjectListSkeleton />}
        </Row>
        <Row>
          <Col className="d-flex justify-content-center mt-3">
            {hasNextPage && (
              <Button
                onClick={() => fetchNextPage()}
                disabled={isLoading || isFetching}
                className="w-50"
              >
                {t('common.load_more')}
              </Button>
            )}
          </Col>
        </Row>
      </SkeletonLoader>
    </div>
  );
};

export default ProjectList;
