import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Card } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import {
  ContactInfo,
  IdVerification,
  KnowYourCustomer,
  MarketingSettings,
  ProfileInvestorQuiz,
} from '.';
import { RegistrationSteps } from 'components/RegistrationSteps';
import { HighlightButton, PrimaryButton } from 'components/Button';
import useOnboarding from 'helpers/useOnboarding';
import useUserType from 'helpers/useUserType';
import InfoBlock from 'components/InfoBlock/InfoBlock';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';
import { useAccountQuery } from 'api/queries';
import FinancialRiskCalculator from './FinancialRiskCalculator';
import Security from './Security';

const Index: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const { isInvestor } = useUserType();

  const { data } = useAccountQuery();
  const { data: userProfileData } = useUserProfileQuery();
  const onBoarding = useOnboarding();

  const kycIsSet = !!userProfileData?.know_your_customer;

  const showIdVerificationSection =
    !(onBoarding.isAccredited && userProfileData?.id_verification == null) &&
    onBoarding.isContactDetailsFilled;

  return (
    <div className={'profile'}>
      <div className={'site profile-cards'}>
        {!onBoarding.isCompleted && (
          <Card body className={'light-pink'}>
            <RegistrationSteps />
            <hr />
            <div className={'d-flex flex-row-reverse'}>
              {!onBoarding.isContactDetailsFilled && (
                <Link to={RouteList.GUEST.AUTHENTICATION.REGISTER_DATA_FILLING}>
                  <PrimaryButton>
                    {tHtml('profile.account_registration.button.complete_filling')}
                  </PrimaryButton>
                </Link>
              )}
              {onBoarding.isContactDetailsFilled && (
                <Link to={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}>
                  <PrimaryButton>
                    {tHtml('profile.account_registration.button.complete_filling')}
                  </PrimaryButton>
                </Link>
              )}
            </div>
          </Card>
        )}
        {!kycIsSet && (
          <InfoBlock
            color={'pink'}
            title={tHtml('profile.info_block.kyc.title')}
            description={tHtml('profile.info_block.kyc.description')}
          >
            <Link to={RouteList.USER.EDIT.KNOW_YOUR_CUSTOMER}>
              <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
            </Link>
          </InfoBlock>
        )}
        {!userProfileData?.user?.has_investor_quiz_filled && (
          <InfoBlock
            color={'pink'}
            title={tHtml('profile.info_block.investor_quiz.title')}
            description={tHtml('profile.info_block.investor_quiz.description')}
          >
            <Link to={RouteList.INVESTOR.QUIZ.VIEW}>
              <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
            </Link>
          </InfoBlock>
        )}
        {!onBoarding.isAccredited && onBoarding.isContactDetailsFilled && (
          <InfoBlock
            color={'pink'}
            title={tHtml('profile.info_block.id_verification.title')}
            description={tHtml('profile.info_block.id_verification.description')}
          >
            <Link to={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}>
              <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
            </Link>
          </InfoBlock>
        )}
        {onBoarding.isAccredited &&
          data?.is_document_expired !== null &&
          data?.is_document_expired !== undefined &&
          data.is_document_expired && (
            <InfoBlock
              color={'pink'}
              title={t('info_block.document_expired.title')}
              description={t('info_block.document_expired.description')}
            >
              <Link to={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}>
                <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
              </Link>
            </InfoBlock>
          )}
        {onBoarding.isAccredited && (
          <InfoBlock
            color={'light-blue'}
            title={tHtml('profile.info_block.add_company.title')}
            description={tHtml('profile.info_block.add_company.description')}
          >
            <Link
              to={{
                pathname: RouteList.USER.CREATE_COMPANY.COMPANY,
                state: { type: UserTypeEnum.PROJECT_DEVELOPER },
              }}
            >
              <HighlightButton>{tHtml('common.project_developer')}</HighlightButton>
            </Link>
            <Link
              to={{
                pathname: RouteList.USER.CREATE_COMPANY.COMPANY,
                state: { type: UserTypeEnum.INVESTOR },
              }}
            >
              <HighlightButton>{tHtml('common.investor')}</HighlightButton>
            </Link>
          </InfoBlock>
        )}
      </div>

      <div className={'profile-content'}>
        <section id={'contact-info'}>
          <ContactInfo />
        </section>
        {showIdVerificationSection && (
          <section id={'id-verification'}>
            <IdVerification />
          </section>
        )}
        <section id={'know-your-customer'}>
          <KnowYourCustomer />
        </section>
        {isInvestor() && (
          <>
            <section id={'investor-quiz'}>
              <ProfileInvestorQuiz />
            </section>
            <section id={'financial-risk-calculator'}>
              <FinancialRiskCalculator />
            </section>
          </>
        )}
        <section id={'security'}>
          <Security
            two_factor_auth_enabled={userProfileData?.user?.two_factor_auth_enabled ?? false}
          />
        </section>
        <section id={'marketing-settings'}>
          <MarketingSettings />
        </section>
      </div>
    </div>
  );
};

export default withRouter(Index);
