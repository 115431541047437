import { RouteList } from 'routes';
import { StepStatusEnum } from 'helpers/enums/StepStatusEnum';

export interface Step {
  name: string;
  route: string;
  status: StepStatusEnum;
  showFillLater?: boolean;
  tooltip?: string;
}

export const registrationSteps: Array<Step> = [
  {
    name: 'registration',
    route: RouteList.GUEST.AUTHENTICATION.REGISTER,
    status: StepStatusEnum.Incomplete,
  },
  {
    name: 'mail_verification',
    route: RouteList.GUEST.AUTHENTICATION.REGISTER_MAIL_VERIFICATION,
    status: StepStatusEnum.Incomplete,
  },
  {
    name: 'data_filling',
    route: RouteList.GUEST.AUTHENTICATION.REGISTER_DATA_FILLING,
    status: StepStatusEnum.Incomplete,
    showFillLater: true,
  },
  {
    name: 'id_verification',
    route: RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION,
    status: StepStatusEnum.Incomplete,
    showFillLater: true,
  },
  {
    name: 'success',
    route: RouteList.GUEST.AUTHENTICATION.REGISTER_SUCCESS,
    status: StepStatusEnum.Hidden,
  },
];

export const registrationStepsCompany: Array<Step> = [
  {
    name: 'registration',
    route: RouteList.USER.CREATE_COMPANY.COMPANY,
    status: StepStatusEnum.Incomplete,
  },
  {
    name: 'know_your_business',
    route: RouteList.USER.CREATE_COMPANY.KNOW_YOUR_BUSINESS,
    status: StepStatusEnum.Incomplete,
  },
  {
    name: 'verification',
    route: '',
    status: StepStatusEnum.Incomplete,
    tooltip: 'verification_tooltip',
  },
];
