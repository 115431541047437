import React from 'react';

interface Information {
  title: string;
  value: string | number | null;
}

interface Props {
  information: Array<Information>;
}

const HighlightedInformation: React.FC<Props> = ({ information }) => {
  if (information.every((block) => !block.value)) {
    return <></>;
  }

  return (
    <>
      <div className={'highlighted-information'}>
        {information.map((block, key) => {
          if (block.value) {
            return (
              <div className={'highlighted-information-block w-100'} key={key}>
                <div className={'title w-50'}>{block.title}</div>
                <div className={'value w-50'}>{block.value}</div>
              </div>
            );
          }

          return <></>;
        })}
      </div>
    </>
  );
};

export default HighlightedInformation;
