import Client from '../client';
import Http from '../../http';
import {
  ConfirmEmailRequestDto,
  ConfirmPasswordResetRequestDto,
  ImpersonateUserRequestDto,
  ImpersonateUserResponseDto,
  LoginUserRequestDto,
  LoginUserResponseDto,
  LoginUserTwoFactorRequestDto,
  PasswordResetRequestDto,
  RegisterUserRequestDto,
  RegisterUserResponseDto,
} from '../../types/guest/authenticate';

class GuestAuth extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      IMPERSONATE: this.defaultUrl(`${this.http.baseUrl}/guest/auth/impersonate`),
      QUIET_IMPERSONATE: this.defaultUrl(`${this.http.baseUrl}/guest/auth/quiet-impersonate`),
      REGISTER: this.defaultUrl(`${this.http.baseUrl}/guest/auth/register`),
      LOGIN: this.defaultUrl(`${this.http.baseUrl}/guest/auth/login`),
      LOGIN_2FA: this.defaultUrl(`${this.http.baseUrl}/guest/auth/login/2fa`),
      CONFIRM_EMAIL: this.defaultUrl(`${this.http.baseUrl}/guest/auth/confirm-email`),
      CONFIRM_EMAIL_CHANGE: this.defaultUrl(`${this.http.baseUrl}/guest/auth/confirm-email-change`),
      PASSWORD_RESET: this.defaultUrl(`${this.http.baseUrl}/guest/auth/password-reset`),
      PASSWORD_RESET_CONFIRM: this.defaultUrl(
        `${this.http.baseUrl}/guest/auth/confirm-password-reset`,
      ),
      VALIDATE_AFFILIATE_CODE: this.defaultUrl(
        `${this.http.baseUrl}/guest/auth/validate-affiliate-code`,
      ),
    };
  }

  login = (data: LoginUserRequestDto): Promise<LoginUserResponseDto> => {
    return this.http.post(this.api.LOGIN, data);
  };

  loginTwoFactor = (data: LoginUserTwoFactorRequestDto): Promise<LoginUserResponseDto> => {
    return this.http.post(this.api.LOGIN_2FA, data);
  };

  impersonate = (data: ImpersonateUserRequestDto): Promise<ImpersonateUserResponseDto> => {
    return this.http.post(this.api.IMPERSONATE, data);
  };

  quietImpersonate = (data: ImpersonateUserRequestDto): Promise<ImpersonateUserResponseDto> => {
    return this.http.post(this.api.QUIET_IMPERSONATE, data);
  };

  confirmEmail = (data: ConfirmEmailRequestDto): Promise<any> => {
    return this.http.post(this.api.CONFIRM_EMAIL, data);
  };

  confirmEmailChange = (data: ConfirmEmailRequestDto): Promise<any> => {
    return this.http.post(this.api.CONFIRM_EMAIL_CHANGE, data);
  };

  initPasswordReset = (data: PasswordResetRequestDto): Promise<any> => {
    return this.http.post(this.api.PASSWORD_RESET, data);
  };

  confirmPasswordReset = (data: ConfirmPasswordResetRequestDto): Promise<any> => {
    return this.http.post(this.api.PASSWORD_RESET_CONFIRM, data);
  };

  passwordReset = (data: PasswordResetRequestDto): Promise<any> => {
    return this.http.post(this.api.PASSWORD_RESET, data);
  };

  register = (data: RegisterUserRequestDto): Promise<RegisterUserResponseDto> => {
    return this.http.post(this.api.REGISTER, data);
  };

  validateAffiliateCode = (code: string): Promise<RegisterUserResponseDto> => {
    return this.http.post(
      this.api.VALIDATE_AFFILIATE_CODE,
      {
        affiliate_code: code,
      },
      { ignoreException: true },
    );
  };
}

export default GuestAuth;
