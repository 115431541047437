import React from 'react';

import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import PageLayout from 'scopes/investor/containers/PageLayout';
import * as Roles from 'helpers/auth/roles';
import Transactions from './Transactions';
import TransactionsAll from './components/TransactionsAll';
import TransactionsInvestmentRepayment from './components/TransactionsInvestmentRepayment';
import TransactionsInvestments from './components/TransactionsInvestments';

const TransactionRoutes: any = () => [
  <AuthRoute
    path={RouteList.INVESTOR.TRANSACTIONS.LIST}
    component={Transactions}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.INVESTOR.TRANSACTIONS.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.TRANSACTIONS.ALL}
    component={TransactionsAll}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.INVESTOR.TRANSACTIONS.ALL}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.TRANSACTIONS.INVESTMENT_REPAYMENT}
    component={TransactionsInvestmentRepayment}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.INVESTOR.TRANSACTIONS.INVESTMENT_REPAYMENT}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.TRANSACTIONS.INVESTMENT}
    component={TransactionsInvestments}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.INVESTOR.TRANSACTIONS.INVESTMENT}
    exact
  />,
];

export default TransactionRoutes;
