import React from 'react';
import useTranslation from 'helpers/useTranslation';
import TextAreaInput from 'components/Formik/TextAreaInput';

export const WorkingCapitalFormSection = () => {
  const { t, tHtml } = useTranslation();

  return (
    <>
      <h2 className="mt-5 mb-4">{tHtml('prospect_application.working_capital_details')}</h2>
      <div className="mb-3">
        <TextAreaInput
          name={'working_capital.loan_details_working_capitals'}
          placeholder={t(
            'placeholder.prospect_application.working_capital.loan_details_working_capitals',
          )}
        />
      </div>
    </>
  );
};
