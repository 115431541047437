import React from 'react';
import { Col, Row } from 'reactstrap';

import ProjectTopTitle from './ProjectTopTitle';
import ProjectTopData from './ProjectTopData';
import { ProjectInvestmentContextState, withProjectInvestment } from '../ProjectInvestmentProvider';
import ProjectTopCalculator from './ProjectTopCalculator';
import ProjectGallery from 'components/Project/ProjectGallery';

type Props = ProjectInvestmentContextState;

const ProjectTop: React.FC<Props> = ({ projectInvestment }) => {
  if (!projectInvestment) return <></>;

  return (
    <>
      <div className={'project-top'}>
        <Row className={'mb-4'}>
          <Col lg={6} md={6}>
            <ProjectGallery pictures={projectInvestment.pictures} />
          </Col>
          <Col lg={6} md={6}>
            <ProjectTopTitle />
            <ProjectTopData />
          </Col>
        </Row>
        <ProjectTopCalculator />
      </div>
    </>
  );
};

export default withProjectInvestment(ProjectTop);
