import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import Api from 'api';
import { ApplicationDocumentResponse, DocumentResponse } from 'api/types/company/document';
import { DocumentsBlock } from './components';

const Documents: React.FC = () => {
  const [documents, setDocuments] = useState<DocumentResponse[] | null>(null);
  const [applications, setApplications] = useState<ApplicationDocumentResponse[] | null>(null);
  const { tHtml, currentLanguage } = useTranslation();

  useEffect(() => {
    Api.projectDeveloper.documents.fetchProjectDeveloperDocuments().then((data) => {
      setDocuments(data.documents);
      setApplications(data.applications);
    });
  }, [currentLanguage]);

  return (
    <>
      {documents && (
        <DocumentsBlock
          title={tHtml('placeholder.my_documents')}
          documents={documents}
          isBlockOpen={documents.length > 0}
        />
      )}
      {applications &&
        applications.map((application: ApplicationDocumentResponse, idx: number) => {
          if (application.documents)
            return (
              <DocumentsBlock
                title={[application.pid, application.project_name].filter((v) => v).join(' - ')}
                isProject={application.is_project}
                projectId={application.id}
                documents={application.documents}
                key={idx}
              />
            );
        })}
    </>
  );
};

export default Documents;
