import React from 'react';
import { ApplicationState } from '../module/ApplicationReducer';
import SecurityDepositsReview from './Shared/SecurityDepositsReview';
import DocumentsReview from './Shared/DocumentsReview';
import useTranslation from 'helpers/useTranslation';

interface WorkingCapitalReviewProps {
  state: ApplicationState;
}

const WorkingCapitalReview = ({ state }: WorkingCapitalReviewProps) => {
  const { tHtml } = useTranslation();

  return (
    <div className="content">
      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.credit_purpose')}
          </div>
          <div className="text review-data-single">
            {tHtml(`application.type.${state.application?.credit_purpose}`)}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.required_amount')}
          </div>
          <div className="text review-data-single">{state.application?.required_amount}</div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.minimal_credit_amount')}
          </div>
          <div className="text review-data-single">{state.application?.minimal_credit_amount}</div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.credit_return')}
          </div>
          <div className="text review-data-single">
            {tHtml(`placeholder.credit_return.${state.application?.credit_return}`)}
          </div>
        </div>
      </div>

      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.interest_frequency')}
          </div>
          <div className="text review-data-single">
            {tHtml(`application.interest_frequency.${state.application?.interest_frequency}`)}
          </div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.source_of_funds')}
          </div>
          <div className="text review-data-single">{state.application?.source_of_funds}</div>
        </div>
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.credit_duration')}
          </div>
          <div className="text review-data-single">
            {tHtml('placeholder.credit_duration.custom', {
              month: state.application?.credit_duration,
            })}
          </div>
        </div>
        <div className="data-block review-data" />
      </div>

      <hr />

      <div className="data-block review-data">
        <div className="title review-data-single">
          {tHtml('placeholder.application.building_permit')}
        </div>
        <div className="text review-data-single">
          {state.application?.estimate?.building_permit
            ? tHtml('common.present')
            : tHtml('common.absent')}
        </div>
      </div>

      <hr />

      <h2>{tHtml('application.security_deposits')}</h2>

      <SecurityDepositsReview state={state} />

      <hr />
      <br />
      <h2>{tHtml('application.documents')}</h2>

      <DocumentsReview state={state} />
    </div>
  );
};

export default WorkingCapitalReview;
