import styled from 'styled-components';

export const StyledMobileSidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSidebarParams = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.4rem;
  justify-content: center;
`;

export const StyledMobileSidebarArrow = styled.div``;

export const StyledMobileSidebarWrapper = styled(StyledSidebarParams)`
  padding: 0.6rem;
  padding-top: 1.3rem;

  span {
    vertical-align: middle;
  }

  .users {
    border: 3px solid var(--profitus-bg-color-3);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .current {
      padding: 0.5rem 0.2rem 0.5rem 0.8rem;
      background: var(--profitus-bg-color-3);
      border-radius: 6px;
      justify-content: flex-start;
      align-items: center;

      .avatar {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }

    .other {
      justify-content: flex-start;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 3px solid var(--profitus-bg-color-3);
      }

      .avatar {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1.5rem;
        margin-left: 0.5rem;
      }
    }
  }

  .logout {
    display: flex;
    gap: 0.5rem;
    color: var(--profitus-link-active-color);
    justify-content: flex-start;
    align-items: center;
    font-weight: 500;
    padding-left: 0.5rem;
    .icon {
      background-color: var(--profitus-link-active-color);
    }
  }

  a {
    &:not(.logout) {
      padding: 0.5rem 0.2rem 0.5rem 0.8rem;
      font-size: 14px;
      font-weight: unset;
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      color: var(--profitus-sidebar-link-color);

      .icon {
        display: inline-block;
        min-width: 1.5rem;
        padding-bottom: 0.125em;
        font-size: 1.05rem;
        line-height: 1.40625rem;
        vertical-align: middle;
        background-color: var(--profitus-sidebar-link-color);
      }

      &.active {
        color: var(--profitus-sidebar-mobile-link-color);

        .icon {
          background-color: var(--profitus-sidebar-mobile-link-color);
        }
      }

      &:hover {
        color: var(--profitus-link-active-color);
        .icon {
          background-color: var(--profitus-link-active-color);
        }
      }
    }
  }
`;

export const StyledMobileSidebarBlock = styled.div`
  display: flex;
  place-items: center;
`;

export const StyledMobileBurgerButton = styled.div`
  display: flex;
  align-items: center;

  .sidebar-toggler {
    padding: 0;
    border: none;
    font-size: 0.875rem;
  }
`;
