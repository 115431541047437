import React, { Suspense, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import './services/i18n';

import './assets/scss/app.scss';
import { setGlobalLoading } from './modules/app/actions';
import ThemeSetter from './components/ThemeSetter';
import GlobalLoader from './components/GlobalLoader';
import { GlobalModal } from './components/Modal/GlobalModal';
import ImpersonatedNotification from './components/ImpersonatedNotification';
import useScope from './helpers/useScope';
import useUserType from './helpers/useUserType';

import GuestRoutes from './scopes/guest/GuestRoutes';
import DeveloperRoutes from './scopes/developer/DeveloperRoutes';
import GlobalRoutes from './scopes/global/GlobalRoutes';
import InvestorRoutes from './scopes/investor/InvestorRoutes';
import CompanyRoutes from './scopes/company/CompanyRoutes';
import UserRoutes from './scopes/user/UserRoutes';
import SharedRoutes from './scopes/shared/SharedRoutes';
import { RouteList } from './routes';
import LogoutTimer from './scopes/shared/components/LogoutTimer';
import AccountSwapFromUrlManager from './scopes/shared/components/AccountSwapFromUrlManager';
import { useAuth } from 'services/useAuth';
import useAppState from './modules/appState';
import Chat from './Chat';
import { GoogleTagManager } from './GoogleTagManager';
import { default as env } from 'env.json';

const App: React.FC = () => {
  const { globalLoading, isImpersonated, isAppStarted, isSwitchingAccount, setInitialRoute } =
    useAppState();
  const scope = useScope();
  const userType = useUserType();
  const location = useLocation();

  const { refresh, mount, dismount } = useAuth();

  useEffect(() => {
    setInitialRoute(location);

    const head = document.querySelector('head');
    const script = document.createElement('script');

    if (env.NODE_ENV === 'production') {
      script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-6Q5BF7EMN7');
      script.async = true;
      head?.appendChild(script);
    }

    GoogleTagManager.init();
    GoogleTagManager.push({
      event: 'consentUpdate',
      consent: 'update',
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
    });

    refresh().finally(() => {
      setGlobalLoading(false);
    });

    mount();
    return () => {
      head?.removeChild(script);
      dismount();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <GlobalModal>
        {!userType.isGuest() && <LogoutTimer />}
        <Suspense fallback={<GlobalLoader />}>
          {(globalLoading || !isAppStarted || isSwitchingAccount) && <GlobalLoader />}
          {isImpersonated && <ImpersonatedNotification />}
          {isAppStarted && !userType.isGuest() && <AccountSwapFromUrlManager />}
          <Switch>
            {isAppStarted && GlobalRoutes()}
            {isAppStarted && scope.isCompany() && CompanyRoutes()}
            {isAppStarted && scope.isUser() && UserRoutes()}
            {isAppStarted && (scope.isUser() || scope.isCompany()) && SharedRoutes()}
            {isAppStarted && userType.isInvestor() && InvestorRoutes()}
            {isAppStarted && userType.isDeveloper() && DeveloperRoutes()}
            {isAppStarted && userType.isGuest() && GuestRoutes()}
            {isAppStarted && userType.isGuest() && (
              <Redirect
                to={RouteList.GUEST.AUTHENTICATION.LOGIN}
                key={RouteList.GUEST.AUTHENTICATION.LOGIN}
              />
            )}
            {isAppStarted && !userType.isGuest() && (
              <Redirect from={RouteList.GUEST.AUTHENTICATION.REGISTER} to={RouteList.DASHBOARD} />
            )}
            {isAppStarted && !userType.isGuest() && (
              <Redirect from={RouteList.GUEST.AUTHENTICATION.LOGIN} to={RouteList.DASHBOARD} />
            )}
            {isAppStarted && !userType.isGuest() && (
              <Redirect to={RouteList.NOT_FOUND} key={RouteList.NOT_FOUND} />
            )}
          </Switch>
          <ThemeSetter />
          <ToastContainer position={'top-center'} />
          {env.NODE_ENV === 'production' && <Chat />}
        </Suspense>
      </GlobalModal>
    </React.Fragment>
  );
};

export default App;
