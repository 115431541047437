import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Column as TableColumn,
  RowPropGetter,
  Row as TableRow,
  useExpanded,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  Filters,
} from 'react-table';
import { Col, Row, Table as BoostrapTable } from 'reactstrap';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';

import { PaginationData, PaginationDataFilter, PaginationMeta } from 'api/types';
import { TableInitialSortBy } from 'api/types/common';
import SortOrder from './SortOrder';
import NumberedPagination from './components/Paginations/NumberedPagination';
import PageSizes from './PageSizes';
import SearchInput from './SearchInput';
import DateFilter from './AdvanceFilters/DateFilter';
import AmountFilter from './AdvanceFilters/AmountFilter';
import ExportTableButton from './components/Buttons/ExportTableButton';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import DataNotFoundBlock from '../DataNotFoundBlock';
import { IconArrowDown, IconArrowUp } from '../Icons';
import SubRowTable from './SubRowTable';
import { SwipeModalComponent } from 'components/Modal/SwipeModalComponent';
import useDisplay from 'helpers/useDisplay';

export interface ExportTableLinkProp {
  onClick: (request: PaginationDataFilter | undefined) => () => Promise<any>;
  request: PaginationDataFilter | undefined;
  type: CommonDocumentTypeEnum;
}

export interface FilterProps {
  start?: any;
  end?: any;
  ignoreLimits?: boolean;
  columnHeaderFilter?: boolean;
  request?: (filter: any) => Promise<any>;
}

export type Column<D extends object> = TableColumn<D> & {
  filterProps?: FilterProps;
};

export interface TableProps {
  columns: Column<any>[];
  data: PaginationData<any> | undefined;
  onFetchData: (request: PaginationDataFilter) => Promise<any>;
  searchable?: boolean;
  sortable?: boolean;
  showPagination?: boolean;
  showPageSizes?: boolean;
  defaultLimit?: number;
  enableQueryFilter?: boolean;
  renderRowSubComponent?: (row: any) => any;
  initialSortBy?: TableInitialSortBy[];
  tableRowProps?: (
    row: TableRow<Record<string, unknown>>,
  ) => RowPropGetter<Record<string, unknown>>;
  exportLinks?: ExportTableLinkProp[];
  externalFilters?: Filters<any>;
  noDataLabel?: string | ReactElement;
  mobile?: boolean;
  hideSubRowColumns?: string[];
  hideSortFields?: string[];
  hideColumnsOnDesktop?: string[];
  hideColumnsOnMobile?: string[];
  overrideTotalCount?: number;
  minimizeCard?: boolean;
}

const DEFAULT_PAGE = 1;

export const ITEMS_PER_PAGE = 5;

export const DEFAULT_META: PaginationMeta = {
  current_page: 1,
  from: 1,
  last_page: 1,
  to: 1,
  total: 1,
};

const Table: React.FC<TableProps> = ({
  columns,
  data,
  onFetchData,
  searchable,
  sortable = true,
  showPagination = true,
  showPageSizes,
  defaultLimit = 10,
  renderRowSubComponent,
  enableQueryFilter,
  initialSortBy,
  tableRowProps,
  exportLinks,
  externalFilters,
  noDataLabel,
  mobile = false,
  hideSubRowColumns = [],
  hideSortFields = [],
  hideColumnsOnDesktop = [],
  hideColumnsOnMobile = [],
  overrideTotalCount = undefined,
  minimizeCard = false,
}) => {
  let { isMobile } = useDisplay();
  const { isApp } = useDisplay();
  isMobile = mobile && isMobile;
  defaultLimit = isMobile ? ITEMS_PER_PAGE : defaultLimit;

  const [innerRefresh, setInnerRefresh] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isSortOpen, setSortOpen] = useState<boolean>(false);
  const [exportLinksOpen, setExportLinksOpen] = useState<boolean>(false);

  const [queryPage, setQueryPage] = useQueryParam('page', NumberParam);
  const [queryLimit, setQueryLimit] = useQueryParam('limit', NumberParam);
  const [querySort, setQuerySort] = useQueryParam('sort', StringParam);
  const [querySearch, setQuerySearch] = useQueryParam('search', StringParam);
  const [queryFilters, setQueryFilters] = useQueryParam('filters', StringParam);
  const [pageSizes, setPageSizes] = useState<number[]>([]);

  const {
    allColumns,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    gotoPage,
    setGlobalFilter,
    visibleColumns,
    setPageSize,
    setSortBy,
    setAllFilters,
    state: { pageSize, pageIndex, sortBy, globalFilter, filters },
  } = useTable(
    {
      columns,
      data: data?.data ?? [],
      // defaultColumn,
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      manualGlobalFilter: true,
      defaultCanSort: false,
      autoResetPage: false,
      autoResetResize: false,
      autoResetExpanded: false,
      pageCount: data?.meta?.last_page ? data.meta.last_page + 1 : 1,
      initialState: {
        pageIndex: queryPage ?? DEFAULT_PAGE,
        pageSize: queryLimit ?? defaultLimit,
        sortBy: querySort ? JSON.parse(querySort) : initialSortBy ? initialSortBy : [],
        globalFilter: querySearch ?? undefined,
        filters: queryFilters ? JSON.parse(queryFilters) : [],
      },
      stateReducer: (newState, action, previousState) => {
        // triggers data refreshing
        if (action.type == 'refreshData') {
          setInnerRefresh({});
        }

        // API starts counting pages from 1, react-table counts it from 0, setting default page to 1
        if (
          newState.pageIndex < DEFAULT_PAGE ||
          (previousState.pageSize && newState.pageSize !== previousState.pageSize) ||
          newState.globalFilter !== previousState.globalFilter
        ) {
          return { ...newState, pageIndex: DEFAULT_PAGE };
        }

        return newState;
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const fetchData = useCallback(
    (request) => {
      setLoading(true);

      return onFetchData(request).finally(() => {
        setLoading(false);
      });
    },
    [onFetchData],
  );

  useEffect(() => {
    if (enableQueryFilter) {
      setQueryPage(pageIndex === DEFAULT_PAGE ? undefined : pageIndex);
      setQueryLimit(pageSize === defaultLimit ? undefined : pageSize);
      setQuerySort(sortBy && sortBy.length == 0 ? undefined : JSON.stringify(sortBy));
      setQuerySearch(globalFilter);
      setQueryFilters(JSON.stringify(filters));
    }

    fetchData({
      page: pageIndex,
      limit: pageSize,
      sort: sortBy,
      search: globalFilter,
      filters: filters,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, globalFilter, pageIndex, pageSize, sortBy, innerRefresh, filters]);

  useEffect(() => {
    if (defaultLimit) {
      setPageSize(defaultLimit);
    }
  }, [defaultLimit, setPageSize]);

  useEffect(() => {
    if (externalFilters) {
      setAllFilters(externalFilters);
    }
  }, [externalFilters, setAllFilters]);

  // On filtering changes, reset the page:
  useEffect(() => {
    gotoPage(DEFAULT_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  //If table filters resets somehow (by clicking a external link with no parameters), resetting table to default
  useEffect(() => {
    if (querySearch === undefined) {
      setGlobalFilter(undefined);
    }

    if (queryPage === undefined) {
      gotoPage(DEFAULT_PAGE);
    }

    if (querySort == undefined && sortBy.length != 0) {
      setSortBy(initialSortBy ? initialSortBy : []);
    }

    if (queryLimit == undefined) {
      setPageSize(defaultLimit);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySearch, querySort, queryPage, queryLimit]);

  useEffect(() => {
    if (data) {
      const total = data.meta.total;

      if (total == 0) {
        setPageSizes([]);
      } else if (total >= 1 && total <= 10) {
        setPageSizes([10]);
      } else if (total >= 11 && total <= 20) {
        setPageSizes([10, 20]);
      } else if (total >= 21 && total <= 30) {
        setPageSizes([10, 20, 30]);
      } else if (total >= 31 && total <= 40) {
        setPageSizes([10, 20, 30, 40]);
      } else if (total >= 41) {
        setPageSizes([10, 20, 30, 40, 50]);
      }
    }
  }, [data]);

  return (
    <>
      <div className={`custom-table ${isMobile ? 'mobile-table-view' : ''}`}>
        {(searchable || isMobile) && (
          <Row className={'mb-2'}>
            {searchable && (
              <Col xs={12} md={4} lg={3} className={'me-auto ml-1 mr-1'}>
                <SearchInput
                  isLoading={isLoading}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  data={data}
                  overrideTotalCount={overrideTotalCount}
                />
              </Col>
            )}
            <Col>
              <Row>
                <Col className={'advance-filters mobile-view'}>
                  {allColumns
                    .filter((column) => column.Filter != undefined)
                    .map((column, index) => (
                      <div key={index}>
                        {column.Filter && !(column as Column<any>).filterProps?.columnHeaderFilter
                          ? column.render('Filter', (column as Column<any>).filterProps)
                          : null}
                      </div>
                    ))}
                  {exportLinks &&
                    !isMobile &&
                    !isApp &&
                    exportLinks.map((link, index) => (
                      <div key={index} className={'download-button mobile-view'}>
                        <ExportTableButton
                          type={link.type}
                          onClick={link.onClick}
                          request={link.request}
                        />
                      </div>
                    ))}
                  {isMobile && sortable && (
                    <>
                      <div className="action">
                        <button className={`btn action-btn`} onClick={() => setSortOpen(true)}>
                          <i className={`icon icon-up-down`} />
                        </button>
                      </div>
                      {isSortOpen && (
                        <SwipeModalComponent onClose={() => setSortOpen(false)}>
                          <div className={'action-options w-100'}>
                            {headerGroups.map((headerGroup, i) => {
                              return (
                                <div key={i} className="mobile-view-sort">
                                  {headerGroup.headers
                                    .filter((column) => !column.disableSortBy)
                                    .map((column, i) => {
                                      if (hideSortFields?.includes(column.id)) {
                                        return <React.Fragment key={i} />;
                                      }

                                      return (
                                        <span
                                          className={`sort-by ${column.isSorted ? 'active' : ''}`}
                                          {...column.getHeaderProps(column.getSortByToggleProps())}
                                          key={i}
                                        >
                                          {column.render('Header')}
                                        </span>
                                      );
                                    })}
                                </div>
                              );
                            })}
                          </div>
                        </SwipeModalComponent>
                      )}
                    </>
                  )}
                  {exportLinks && isMobile && !isApp && (
                    <>
                      <div className="action">
                        <button
                          className={`btn action-btn`}
                          onClick={() => setExportLinksOpen(true)}
                        >
                          <i className={`icon icon-vertical-dots`} />
                        </button>
                      </div>
                      {exportLinksOpen && (
                        <SwipeModalComponent onClose={() => setExportLinksOpen(false)}>
                          <div className={'actions'}>
                            {exportLinks.map((link, index) => (
                              <div key={index} className={'download-button mobile-view'}>
                                <ExportTableButton
                                  type={link.type}
                                  onClick={link.onClick}
                                  request={link.request}
                                />
                              </div>
                            ))}
                          </div>
                        </SwipeModalComponent>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {isMobile ? (
          <div>
            {!isLoading && rows.length === 0 && (
              <div className="mobile-table-card">
                <DataNotFoundBlock label={noDataLabel} />
              </div>
            )}
            {isLoading &&
              [...Array(pageSize).keys()].map((i) => (
                <div key={i} className="mobile-table-card">
                  {visibleColumns.map((col, key) => {
                    return (
                      <div className={'text-center'} key={key}>
                        <div className={'placeholder-glow'}>
                          <span className={'placeholder w-100'} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            {!isLoading &&
              rows.map((row, i) => {
                prepareRow(row);
                const props = tableRowProps ? tableRowProps(row as any) : {};

                // Don't render sub rows by default, because we do that manually by extending mobile table card
                if (row.depth > 0) {
                  return <React.Fragment key={i} />;
                }
                return (
                  <div
                    {...row.getRowProps({ ...props })}
                    key={i}
                    className={`mobile-table-card ${minimizeCard ? 'minimize' : ''}`}
                  >
                    {row.cells.map((cell, j) => {
                      if (hideColumnsOnMobile.includes(cell.column.id)) {
                        return <React.Fragment key={`${i}.${j}`} />;
                      }
                      return minimizeCard ? (
                        <div key={`${i}.${j}`} className="list">
                          {cell.render('Cell')}
                        </div>
                      ) : (
                        <Row className="mobile-table-card-value" key={`${i}.${j}`}>
                          <Col xs={5} className="header-value">
                            <span className="span-align-top">{cell.render('Header')}</span>
                          </Col>
                          <Col
                            xs={
                              typeof cell.render('Header') !== 'string' || !cell.render('Header')
                                ? 12
                                : 7
                            }
                            {...cell.getCellProps()}
                            className={
                              typeof cell.render('Header') !== 'string' || !cell.render('Header')
                                ? 'center-cell'
                                : 'value-cell'
                            }
                          >
                            {cell.render('Cell')}
                          </Col>
                        </Row>
                      );
                    })}
                    {renderRowSubComponent && (
                      <Row className="mobile-table-card-value">
                        <Col xs={12} className={'center-cell'}>
                          <div
                            {...row.getToggleRowExpandedProps()}
                            onClick={() => {
                              row.toggleRowExpanded(!row.isExpanded);
                            }}
                            key={`${i}.${row.cells.length}`}
                            className="toggle-sub-row"
                          >
                            {row.isExpanded ? (
                              <IconArrowUp classes={'icon-dropdown'} />
                            ) : (
                              <IconArrowDown classes={'icon-dropdown'} />
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}

                    {row.isExpanded &&
                      row.subRows.length > 0 &&
                      row.subRows.map((subRow, i) => {
                        prepareRow(subRow);
                        return (
                          <React.Fragment key={i}>
                            <hr />
                            {subRow.cells.map((cell, j) => {
                              if (hideSubRowColumns.includes(cell.column.id)) {
                                return <React.Fragment key={`${i}.${j}`} />;
                              }
                              return <SubRowTable key={`${i}.${j}`} cell={cell} />;
                            })}
                          </React.Fragment>
                        );
                      })}
                    {renderRowSubComponent && row.isExpanded && (
                      <div>{renderRowSubComponent({ row })}</div>
                    )}
                  </div>
                );
              })}
          </div>
        ) : (
          <BoostrapTable responsive borderless hover {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, i) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                    {headerGroup.headers.map((column, i) => {
                      if (hideColumnsOnDesktop.includes(column.id)) {
                        return <React.Fragment key={i} />;
                      }

                      return (
                        <th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          className={'th-text-top'}
                          key={i}
                        >
                          {(column as Column<any>).filterProps?.columnHeaderFilter ? (
                            <div className={`cell-filter ${column.isSorted ? 'active' : ''}`}>
                              <span className={'me-auto'}>{column.render('Header')}</span>
                              <span className={'mt-1'}>
                                {column.isSorted && (
                                  <SortOrder type={column.sortType} isDesc={column.isSortedDesc} />
                                )}
                              </span>
                              <div className={'mt-1'}>
                                {column.Filter
                                  ? column.render('Filter', (column as Column<any>).filterProps)
                                  : null}
                              </div>
                            </div>
                          ) : (
                            <div className={`cell ${column.isSorted ? 'active' : ''}`}>
                              <span className={'me-auto'}>{column.render('Header')}</span>
                              <span>
                                {column.isSorted && (
                                  <SortOrder type={column.sortType} isDesc={column.isSortedDesc} />
                                )}
                              </span>
                            </div>
                          )}
                        </th>
                      );
                    })}
                    {renderRowSubComponent && (
                      <th key={headerGroup.headers.length}>
                        <div className={`cell`}>
                          <span className={'me-auto'} />
                        </div>
                      </th>
                    )}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {!isLoading && rows.length === 0 && (
                <>
                  <tr>
                    <td className={'text-center p-4'} colSpan={visibleColumns.length}>
                      <DataNotFoundBlock label={noDataLabel} />
                    </td>
                  </tr>
                </>
              )}
              {isLoading &&
                [...Array(pageSize).keys()].map((i) => (
                  <tr key={i}>
                    {visibleColumns.map((col, key) => {
                      return (
                        <td className={'text-center'} key={key}>
                          <div className={'placeholder-glow'}>
                            <span className={'placeholder w-100'} />
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              {!isLoading &&
                rows.map((row, i) => {
                  prepareRow(row);
                  const props = tableRowProps ? tableRowProps(row as any) : {};

                  return (
                    <React.Fragment key={i}>
                      <tr {...row.getRowProps({ ...props })} key={i}>
                        {row.cells.map((cell, j) => {
                          if (hideColumnsOnDesktop.includes(cell.column.id)) {
                            return <React.Fragment key={`${i}.${j}`} />;
                          }
                          return (
                            <td {...cell.getCellProps()} key={`${i}.${j}`}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                        {renderRowSubComponent && (
                          <td key={`${i}.${row.cells.length}`}>
                            <div
                              {...row.getToggleRowExpandedProps()}
                              onClick={() => {
                                row.toggleRowExpanded(!row.isExpanded);
                              }}
                            >
                              {row.isExpanded ? (
                                <IconArrowUp classes={'icon-dropdown'} />
                              ) : (
                                <IconArrowDown classes={'icon-dropdown'} />
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                      {/*
                    If the row is in an expanded state, render a row with a
                    column that fills the entire length of the table.
                  */}
                      {renderRowSubComponent && row.isExpanded ? (
                        <tr key={`${i}.sub`}>
                          <td colSpan={visibleColumns.length + 1}>
                            {/*
                          Inside it, call our renderRowSubComponent function. In reality,
                          you could pass whatever you want as props to
                          a component like this, including the entire
                          table instance. But for this example, we'll just
                          pass the row
                        */}
                            {renderRowSubComponent({ row })}
                          </td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </BoostrapTable>
        )}

        {(showPagination || isMobile) && (
          <div className="pagination justify-content-center">
            {data?.meta.last_page && data?.meta.last_page > 1 && (
              <NumberedPagination
                pageIndex={pageIndex}
                lastPage={data.meta.last_page}
                gotoPage={gotoPage}
              />
            )}
          </div>
        )}
      </div>

      {showPageSizes && !isMobile && pageSizes.length > 0 && (
        <>
          <PageSizes pageSizes={pageSizes} currentPageSize={pageSize} setPageSize={setPageSize} />
        </>
      )}
    </>
  );
};

export default Table;
export { DateFilter, AmountFilter };
