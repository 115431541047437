import React, { ReactElement } from 'react';

interface Props {
  icon: string;
  title: string | ReactElement;
  data: string | ReactElement;
  infoLink?: string;
}

const InvestmentDataBlock: React.FC<Props> = ({ icon, title, data, infoLink }) => {
  return (
    <>
      <div className={'investment-data-block'}>
        <div className={'investment-icon'}>
          <i className={`icon ${icon}`} />
        </div>
        <div className={'data-block'}>
          <div className={'title'}>
            {title}
            {infoLink && (
              <a href={infoLink} target={'_blank'} rel={'noreferrer'}>
                <i className={`icon icon-info-sm`} />
              </a>
            )}
          </div>
          <div className={'text'}>{data}</div>
        </div>
      </div>
    </>
  );
};

export default InvestmentDataBlock;
