import styled from 'styled-components';
import { StyledCard } from 'components/Card/Card.style';

export const StyledPartialReturnForm = styled.div`
  margin-bottom: 2rem;
`;

export const StyledPartialReturnFormCard = styled(StyledCard)`
  margin-bottom: 2rem;
`;

export const StyledPartialReturnFormSection = styled.div`
  margin-bottom: 1.5rem;
  padding-top: 0.25rem;
`;

export const StyledPartialReturnFormSubmitSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

export const StyledPartialReturnFormSubmitSectionTotal = styled.div`
  flex-grow: 3;
  min-width: 15rem;
`;

export const StyledPartialReturnFormSubmitSectionButton = styled.div`
  flex-grow: 1;
  min-width: 7rem;
`;
