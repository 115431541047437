import React from 'react';
import { Container, Navbar, NavbarToggler } from 'reactstrap';

import ResponsiveLogo from 'components/Logo/ResponsiveLogo';
import NotificationDropdown from 'components/NotificationDropDown/NotificationDropdown';
import WalletButton from 'scopes/shared/WalletButton';
import {
  StyledMobileBody,
  StyledMobileHeader,
  StyledMobileHeaderEnd,
  StyledMobileHeaderStart,
} from './HeaderMobile.style';
import useAppState from 'modules/appState';
import useDisplay from 'helpers/useDisplay';
import { useLocation } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

interface Props {
  toggleMenuCallback: () => unknown;
}

const HeaderMobile: React.FC<Props> = (props) => {
  const { isImpersonated } = useAppState();
  const { isApp } = useDisplay();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <StyledMobileHeader top={isImpersonated ? '30px' : '0'}>
        <div className={'header-line'} />
        <Navbar expand={'xl'}>
          <Container>
            <StyledMobileBody>
              <StyledMobileHeaderStart>
                <ResponsiveLogo />
              </StyledMobileHeaderStart>
              {isApp && (
                <div className="w-100 text-center text-muted path">
                  {location.pathname.split('/')[1] === 'invest' ? (
                    <WalletButton />
                  ) : (
                    t(`routes.${location.pathname.split('/')[1] ?? 'home'}`)
                  )}
                </div>
              )}
              <StyledMobileHeaderEnd>
                {!isApp && (
                  <>
                    <WalletButton />
                    <NotificationDropdown />
                  </>
                )}
                <NavbarToggler onClick={props.toggleMenuCallback} className={'sidebar-toggler'} />
              </StyledMobileHeaderEnd>
            </StyledMobileBody>
          </Container>
        </Navbar>
      </StyledMobileHeader>
    </React.Fragment>
  );
};

export default HeaderMobile;
