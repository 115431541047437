import React from 'react';
import useOnboarding from 'helpers/useOnboarding';
import Api from 'api';
import { RouteList } from 'routes';
import Loader from 'components/Loader';
import { NavLink, Link } from 'react-router-dom';
import { useAccountQuery } from 'api/queries';
import { useAuth } from 'services/useAuth';
import SUITCASE_ICON from 'assets/images/icons/suitcase.svg';
import useAppState from 'modules/appState';
import { useStepsModalStateStore } from '../../investor/modals/RequiredSteps/store';
import {
  AuthenticatedAccountBriefResponseDto,
  RefreshAuthenticatedAccountResponseDto,
} from 'api/types/shared/authenticatedAccount';

const AuthenticatedAccountMobile: React.FC = () => {
  const onBoarding = useOnboarding();
  const { data, isLoading, isFetching } = useAccountQuery();
  const { login } = useAuth();
  const { setIsSwitchingAccount } = useAppState();
  const modalState = useStepsModalStateStore();

  if (!data || isLoading || isFetching) {
    return (
      <div className="users mb-3">
        <div className="current">
          <Loader size={'sm'} />
        </div>
      </div>
    );
  }

  const switchAccount = async (
    account: AuthenticatedAccountBriefResponseDto,
    onSwitch: (id: string, type: string) => Promise<RefreshAuthenticatedAccountResponseDto>,
  ) => {
    modalState.setShouldBeShown({ state: true });
    if (!account) {
      return;
    }

    try {
      setIsSwitchingAccount(true);
      const response = await onSwitch(account.id, account.type);

      await login(response.access_token, response.permissions);
    } finally {
      setIsSwitchingAccount(false);
    }
  };

  return (
    <div className="users mb-3">
      <NavLink to={RouteList.COMPANY.PROFILE.HOME} className="current">
        {!onBoarding.isCompleted && <i className={'icon-alert p-0'} />}
        <span title={data.name}>
          {!data.user ? (
            <img src={data.main_user_avatar_url} alt="Avatar" className={'avatar'} />
          ) : (
            <img src={SUITCASE_ICON} alt="Avatar" className={'company avatar'} />
          )}
          <span className={'label text-break'}>{data.name}</span>
        </span>
      </NavLink>
      {data.user && (
        <Link
          to={RouteList.HOME}
          className="other"
          onClick={async () => {
            data.user && (await switchAccount(data.user, Api.shared.loginAs.userLogin));
          }}
        >
          <img src={data.main_user_avatar_url} alt="Avatar" className={'avatar'} />
          <span className={'label text-break'}> {data.user.name}</span>
        </Link>
      )}
      {data.companies &&
        data.companies.map((company, index) => {
          return (
            <Link
              key={index}
              to={RouteList.HOME}
              className="other"
              onClick={async () => {
                await switchAccount(company, (id: string) => Api.shared.loginAs.companyLogin(id));
              }}
            >
              <img src={SUITCASE_ICON} alt="Avatar" className={'company avatar'} />
              <span className={'label text-break'}>{company.name}</span>
            </Link>
          );
        })}
    </div>
  );
};

export default AuthenticatedAccountMobile;
