import { ApplicationSingleSecurityDepositDto } from 'api/types/project-developer/application';
import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { ApplicationState } from '../../module/ApplicationReducer';

interface SecurityDepositsReviewProps {
  state: ApplicationState;
}

const SecurityDepositsReview = ({ state }: SecurityDepositsReviewProps) => {
  const { tHtml } = useTranslation();

  return (
    <>
      <div className="review-content">
        <div className="data-block review-data">
          <div className="title review-data-single">
            {tHtml('placeholder.application.surety_exists')}
          </div>
          <div className="text review-data-single">
            {state.application?.security_deposits?.surety?.surety_exists
              ? tHtml('common.yes')
              : tHtml('common.no')}
          </div>
        </div>
        {state.application?.security_deposits?.surety?.surety_exists && (
          <>
            <div className="data-block review-data">
              <div className="title review-data-single">
                {tHtml('placeholder.application.surety_name')}
              </div>
              <div className="text review-data-single">
                {state.application?.security_deposits.surety.surety_name}
              </div>
            </div>
            <div className="data-block review-data">
              <div className="title review-data-single">
                {tHtml('placeholder.application.surety_code')}
              </div>
              <div className="text review-data-single">
                {state.application?.security_deposits.surety.surety_code}
              </div>
            </div>
            <div className="data-block review-data" />
          </>
        )}
      </div>

      {state.application?.security_deposits?.security_deposits?.map(
        (security_deposit: ApplicationSingleSecurityDepositDto, i: number) => (
          <React.Fragment key={i}>
            <hr />
            <div className="review-content">
              <div className="data-block review-data">
                <div className="title review-data-single">
                  {tHtml('placeholder.application.real_estate_type')}
                </div>
                <div className="text review-data-single">
                  {tHtml(
                    `placeholder.application.real_estate_unique.${security_deposit.real_estate_type}`,
                  )}
                </div>
              </div>
              <div className="data-block review-data">
                <div className="title review-data-single">
                  {tHtml('placeholder.application.real_estate_unique')}
                </div>
                <div className="text review-data-single">{security_deposit.real_estate_unique}</div>
              </div>
              <div className="data-block review-data">
                <div className="title review-data-single">
                  {tHtml('placeholder.application.real_estate_plot')}
                </div>
                <div className="text review-data-single">{security_deposit.real_estate_plot}</div>
              </div>
              <div className="data-block review-data">
                <div className="title review-data-single">
                  {tHtml('placeholder.application.real_estate_address')}
                </div>
                <div className="text review-data-single">
                  {security_deposit.real_estate_address}
                </div>
              </div>
            </div>
            <div className="review-content">
              <div className="data-block review-data">
                <div className="title review-data-single">
                  {tHtml('placeholder.application.real_estate_value')}
                </div>
                <div className="text review-data-single">{security_deposit.real_estate_value}</div>
              </div>
              <div className="data-block review-data">
                <div className="title review-data-single">
                  {tHtml('placeholder.application.real_estate_appraiser')}
                </div>
                <div className="text review-data-single">
                  {security_deposit.real_estate_appraiser}
                </div>
              </div>
              <div className="data-block review-data">
                <div className="title review-data-single">
                  {tHtml('placeholder.application.real_estate_appraised_at')}
                </div>
                <div className="text review-data-single">
                  {security_deposit.real_estate_appraised_at}
                </div>
              </div>
              <div className="data-block review-data" />
            </div>
          </React.Fragment>
        ),
      )}
    </>
  );
};

export default SecurityDepositsReview;
