import { MediaInfoResponseDto, SingUploadSingleFileResponseDto } from 'api/types/app';
import { handleActions } from 'redux-actions';

export const FILES_ADDED = 'FILE_UPLOAD.FILES_ADDED';
export const REMOVE_FILES = 'FILE_UPLOAD.REMOVE_FILES';
export const FILE_UPLOADING = 'FILE_UPLOAD.FILE_UPLOADING';
export const FILE_UPLOADED = 'FILE_UPLOAD.FILE_UPLOADED';
export const FILE_REMOVED = 'FILE_UPLOAD.FILE_REMOVED';
export const EXISTING_FILE_ADD = 'FILE_UPLOAD.EXISTING_FILE_ADD';

export enum FileStateEnum {
  Add,
  Uploading,
  Uploaded,
}

export interface IFile {
  name: string;
  state: FileStateEnum;
  file: File | null;
  meta: MediaInfoResponseDto | null;
}

export interface FileState {
  files: { [key: string]: IFile };
  isUploading: boolean;
}

export const initialState: FileState = {
  files: {},
  isUploading: false,
};

export const FileUploadReducer = handleActions<FileState, any>(
  {
    [FILES_ADDED]: (state: FileState, action: { payload: File[] }) => {
      const files: { [key: string]: IFile } = { ...state.files };

      action.payload.forEach((file) => {
        let isNew = true;
        Object.keys(files).forEach((key) => {
          if (files[key].name === file.name) {
            isNew = false;
            return;
          }
        });

        if (isNew) {
          files[Math.random()] = {
            name: file.name,
            state: FileStateEnum.Add,
            file: file,
            meta: null,
          };
        }
      });

      return {
        ...state,
        files: files,
      };
    },
    [REMOVE_FILES]: () => {
      return {
        ...initialState,
      };
    },
    [FILE_UPLOADING]: (state: FileState, action: { payload: string }) => {
      const files = { ...state.files };
      const file = files[action.payload];
      file.state = FileStateEnum.Uploading;

      return {
        ...state,
        files: files,
        isUploading: true,
      };
    },
    [EXISTING_FILE_ADD]: (state: FileState, action: { payload: MediaInfoResponseDto[] }) => {
      const newFiles: { [key: string]: IFile } = {};

      action.payload.forEach((media) => {
        if (!Object.keys(state.files).includes(media.id)) {
          newFiles[media.id] = {
            file: null,
            state: FileStateEnum.Uploaded,
            meta: media,
            name: media.name,
          };
        }
      });

      if (Object.keys(newFiles).length > 0) {
        return {
          ...state,
          files: { ...state.files, ...newFiles },
        };
      }

      return state;
    },
    [FILE_UPLOADED]: (state: FileState, action: { payload: SingUploadSingleFileResponseDto }) => {
      const payload = action.payload;
      const hash = payload.hash;
      let isUploading = false;

      const files = { ...state.files };

      if (Object.keys(files).includes(hash)) {
        delete files[hash];
      }

      if (!Object.keys(files).includes(payload.media_id)) {
        files[payload.media_id] = {
          file: null,
          name: payload.name,
          state: FileStateEnum.Uploaded,
          meta: {
            id: payload.media_id,
            name: payload.name,
          },
        };

        Object.keys(files).forEach((key) => {
          if (files[key].state !== FileStateEnum.Uploaded) {
            isUploading = true;
          }
        });

        return {
          ...state,
          files: files,
          isUploading,
        };
      }

      return state;
    },
    [FILE_REMOVED]: (state: FileState, action: { payload: string }) => {
      const files = { ...state.files };
      delete files[action.payload];

      return {
        ...state,
        files: files,
      };
    },
  },
  initialState,
);
