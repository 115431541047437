import { RefObject, useEffect, useState } from 'react';

const useOnScreen = (ref: RefObject<any>, initialValue = true): boolean => {
  const [isIntersecting, setIntersecting] = useState<boolean>(initialValue);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line
  }, [ref]);

  return isIntersecting;
};

export default useOnScreen;
