import React from 'react';
import { ModalComponent } from 'components/Modal/ModalComponent';
import { ModalBody, ModalHeader } from 'reactstrap';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { HighlightButton } from 'components/Button';
import { PrimaryButton } from 'components/Formik';
import useTranslation from 'helpers/useTranslation';
import Api from 'api';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/Formik';
import { success, warning } from 'services/toastr';
import { LoginUserTwoFactorRequestDto } from 'api/types/guest/authenticate';
import { GoogleTagManager } from 'GoogleTagManager';
import { RouteList } from 'routes';
import { useAuth } from 'services/useAuth';
import * as H from 'history';

interface Props {
  history: H.History<unknown>;
  token: string;
}

const LoginTwoFactorStepModal = ({ history, token }: Props) => {
  const { t, tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const { login } = useAuth();

  const handleConfirmTwoFactorAuth = async (
    values: LoginUserTwoFactorRequestDto,
    helpers: FormikHelpers<LoginUserTwoFactorRequestDto>,
  ) => {
    try {
      const response = await Api.guest.auth.loginTwoFactor(values);

      if (response.access_token) {
        await login(response.access_token, response.permissions);
        success(tHtml('authentication.login_success'));

        GoogleTagManager.push({
          event: 'user_logged_id',
        });
      } else {
        warning(tHtml('authentication.user_not_registered'));
        history.push(RouteList.GUEST.AUTHENTICATION.REGISTER);
      }

      hideModal();
    } catch (error) {
      helpers.setErrors({ code: t('placeholder.2fa.2fa_confirm_failed') });
    }
  };

  const TwoFactorAuthConfirm = Yup.object().shape({
    token: Yup.string().required(),
    code: Yup.string().required(),
  });

  return (
    <>
      <ModalComponent size={'md'} contentClassName={'two-factor-auth-modal'}>
        <div className={'p-3'}>
          <ModalHeader
            toggle={hideModal}
            close={<ModalCloseButton />}
            tag={'h2'}
            className={'mt-2'}
          >
            {tHtml('placeholder.2fa')}
          </ModalHeader>
          <ModalBody>
            <div className={'row mb-4'}>
              <span>{tHtml('placeholder.2fa.on_login_description')}</span>
            </div>
            <Formik
              initialValues={{
                token: token,
                code: '',
              }}
              validationSchema={TwoFactorAuthConfirm}
              onSubmit={handleConfirmTwoFactorAuth}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={'form-input'}>
                    <TextInput
                      name="code"
                      placeholder={t('placeholder.2fa.2fa_code')}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSubmit();
                        }
                      }}
                    />
                  </div>
                  <div className={'d-flex justify-content-around mt-3'}>
                    <HighlightButton
                      className={'btn btn-highlight w-50 fw-normal me-3'}
                      onClick={hideModal}
                    >
                      {tHtml('common.cancel')}
                    </HighlightButton>
                    <PrimaryButton
                      disabled={isSubmitting}
                      className={'btn btn-primary w-50 fw-normal'}
                      type={'submit'}
                    >
                      {tHtml('common.login')}
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </ModalComponent>
    </>
  );
};

export default LoginTwoFactorStepModal;
