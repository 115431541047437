import useTranslation from 'helpers/useTranslation';
import React from 'react';
import { Collapse } from 'reactstrap';
import { IconArrowDown, IconArrowUp } from 'components/Icons';

export enum FinancialRiskCalculatorTypeEnum {
  ANNUAL_INCOME = 'annual_income',
  LIQUID_NET_WORTH = 'liquid_net_worth',
  ANNUAL_OBLIGATIONS = 'annual_obligations',
}

interface Props {
  isOpen: boolean;
  handleOnClick: () => void;
  type: string;
  children: React.ReactNode;
}

export const FinancialRiskCalculatorTooltip = ({
  isOpen,
  handleOnClick,
  type,
  children,
}: Props) => {
  const { tHtml } = useTranslation();

  return (
    <div className={'form-input mb-4'}>
      <div className="d-inline-flex flex-row w-100 gap-3">
        <div className="w-100">{children}</div>
        <div onClick={handleOnClick} className="d-flex pointer mt-3">
          {isOpen ? (
            <IconArrowUp classes={'icon-dropdown'} />
          ) : (
            <IconArrowDown classes={'icon-dropdown'} />
          )}
        </div>
      </div>

      <Collapse isOpen={isOpen}>
        <span className="text-muted small">{tHtml(`placeholder.${type}_tooltip_description`)}</span>
      </Collapse>
    </div>
  );
};
