import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Col, Row } from 'reactstrap';

import { ProjectDeveloperPartialReturnProjectResponseDto } from 'api/types/project-developer/payment';
import {
  StyledProjectInformation,
  StyledProjectInformationCard,
  StyledProjectInformationDescriptionSection,
  StyledProjectInformationDetails,
} from './ProjectInformation.style';
import { StyledPartialReturnLabel } from '../../PartialReturn.style';
import DataBlockBetween from 'components/DataBlock/DataBlockBetween/DataBlockBetween';
import ProjectGallery from 'components/Project/ProjectGallery';
import SanitizedHtmlBlock from 'components/SanitizedHtmlBlock';
import ProjectInformationHeader from './ProjectInformationHeader/ProjectInformationHeader';
import { ReadMore } from 'components/ReadMore';

type Props = {
  project: ProjectDeveloperPartialReturnProjectResponseDto;
};

const ProjectInformation: React.FC<Props> = ({ project }) => {
  const { tHtml } = useTranslation();

  return (
    <StyledProjectInformation>
      <StyledProjectInformationCard>
        <ProjectInformationHeader
          projectId={project.pid}
          projectName={project.project_name ?? '-'}
          className={'d-flex d-xl-none'}
        />
        <Row>
          <Col xl={6}>
            <ProjectGallery pictures={project.pictures} />
          </Col>
          <Col xl={6}>
            <ProjectInformationHeader
              projectId={project.pid}
              projectName={project.project_name ?? '-'}
              className={'d-none d-xl-flex'}
            />
            <StyledProjectInformationDetails>
              <DataBlockBetween
                title={tHtml('payments.partial_return.project_information.project_address')}
                content={project.project_address}
              />
              <DataBlockBetween
                title={tHtml('payments.partial_return.project_information.project_country')}
                content={
                  project.project_country ? tHtml('countries.' + project.project_country) : null
                }
              />
              <DataBlockBetween
                title={tHtml('payments.partial_return.project_information.number_of_re')}
                content={project.number_of_re ? String(project.number_of_re) : null}
              />
              <DataBlockBetween
                title={tHtml('payments.partial_return.project_information.area_of_object')}
                content={project.area_of_object}
              />
              <DataBlockBetween
                title={tHtml('payments.partial_return.project_information.object_energy_class')}
                content={project.object_energy_class}
              />
              <DataBlockBetween
                title={tHtml('payments.partial_return.project_information.start_construction_at')}
                content={project.start_construction_at}
              />
              <DataBlockBetween
                title={tHtml('payments.partial_return.project_information.end_construction_at')}
                content={project.end_construction_at}
              />
            </StyledProjectInformationDetails>
          </Col>
        </Row>
        {project.project_description && (
          <StyledProjectInformationDescriptionSection>
            <StyledPartialReturnLabel>
              {tHtml('payments.partial_return.project_information.project_description')}
            </StyledPartialReturnLabel>
            <ReadMore>
              <SanitizedHtmlBlock content={project.project_description} />
            </ReadMore>
          </StyledProjectInformationDescriptionSection>
        )}
      </StyledProjectInformationCard>
    </StyledProjectInformation>
  );
};

export default ProjectInformation;
