import { UserMarketingSettingTypeEnum } from '../enums/UserMarketingSettingTypeEnum';

export const EMAIL_MARKETING_SETTINGS = [
  UserMarketingSettingTypeEnum.EDUCATIONAL,
  UserMarketingSettingTypeEnum.INVESTED_PROJECT_NEWS,
  UserMarketingSettingTypeEnum.PROMOTIONAL,
  UserMarketingSettingTypeEnum.SURVEYS,
  UserMarketingSettingTypeEnum.NEWSLETTER,
];

export const CALL_MARKETING_SETTINGS = [
  UserMarketingSettingTypeEnum.SURVEYS,
  UserMarketingSettingTypeEnum.PROMOTIONAL,
];

export const SMS_MARKETING_SETTINGS = [UserMarketingSettingTypeEnum.DIRECT];
