import React, { useState } from 'react';
import { Item as LightboxItem } from 'react-photoswipe-gallery';
import { TranslatableValueDto } from 'api/types/common';
import { resolveTranslatableValueDto } from 'helpers/utils';

interface Props {
  imageUrl: string;
  thumbnailUrl: string;
  label: TranslatableValueDto<string> | null;
}

const Image: React.FC<Props> = ({ imageUrl, thumbnailUrl, label }) => {
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>({
    width: 100,
    height: 100,
  });

  return (
    <div className={'image swiper-zoom-container'}>
      <LightboxItem
        original={imageUrl}
        thumbnail={thumbnailUrl}
        width={imageSize.width}
        height={imageSize.height}
      >
        {({ ref, open }) => (
          <div className="position-relative">
            <img
              src={imageUrl}
              onClick={open}
              ref={ref as React.MutableRefObject<HTMLImageElement>}
              alt={'Image'}
              onLoad={() => {
                const image = ref.current as HTMLImageElement;
                setImageSize({ width: image.naturalWidth, height: image.naturalHeight });
              }}
            />
            {label && resolveTranslatableValueDto(label) ? (
              <div className="image-label">{resolveTranslatableValueDto(label)}</div>
            ) : null}
          </div>
        )}
      </LightboxItem>
    </div>
  );
};
export default Image;
