import React from 'react';
import { useWalletQuery } from 'api/queries/useWalletQuery';
import Loader from 'components/Loader';
import useTranslation from 'helpers/useTranslation';
import { Link } from 'react-router-dom';
import { RouteList } from 'routes';

export const ReservedAmountWalletButton: React.FC = () => {
  const { tHtml } = useTranslation();
  const { data: wallet, isLoading } = useWalletQuery();

  if (!wallet || wallet?.reserved_amount === null || wallet?.reserved_amount <= 0) return <></>;

  return isLoading ? (
    <div>
      <Loader size={'sm'} />
    </div>
  ) : (
    <Link
      to={RouteList.SHARED.WALLET.OVERVIEW}
      className={'balance-amount  btn btn-functional-muted me-2'}
    >
      {wallet && wallet.reserved_amount !== null && wallet.reserved_amount > 0 ? (
        <>{tHtml('common.money', { value: wallet.reserved_amount })}</>
      ) : (
        <Loader size={'sm'} />
      )}
    </Link>
  );
};

export default ReservedAmountWalletButton;
