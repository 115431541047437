import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Api from 'api';
import { NotificationResponseDto } from 'api/types/app';
import useTranslation from 'helpers/useTranslation';
import { useNotificationsQuery } from '../../api/queries/useNotificationsQuery/useNotificationsQuery';

export interface NotificationsContextState {
  selectedNotification: NotificationResponseDto | undefined;
  setSelectedNotification?: (value: NotificationResponseDto) => void;
  shouldReload: boolean;
  setShouldReload?: (value: boolean) => void;
  markAllRead?: () => any;
  isLoading: boolean;
}

const NotificationsContext = createContext<NotificationsContextState>({
  selectedNotification: undefined,
  isLoading: true,
  shouldReload: false,
});

export const NotificationsProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shouldReload, setShouldReload] = useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] = useState<
    NotificationResponseDto | undefined
  >(undefined);
  const { currentLanguage } = useTranslation();

  const { invalidate } = useNotificationsQuery();

  const location = useLocation();

  const markAllRead = async () => {
    await Api.notifications.markAllAsRead().then(() => {
      invalidate();
      setShouldReload(!shouldReload);
    });
  };

  useEffect(() => {
    if (location.hash) {
      setIsLoading(true);

      const id = location.hash.replace('#', '');
      Api.notifications
        .fetchNotification(id)
        .then(setSelectedNotification)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [location, currentLanguage]);

  return (
    <NotificationsContext.Provider
      value={{
        selectedNotification,
        setSelectedNotification,
        isLoading,
        markAllRead,
        shouldReload,
        setShouldReload,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function withNotifications<P extends NotificationsContextState = NotificationsContextState>(
  Component: React.ComponentType<P>,
) {
  const WithNotifications = ({ ...props }: Optionalize<P, NotificationsContextState>) => {
    const c = useContext(NotificationsContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithNotifications.displayName = `withNotifications${Component.displayName}`;

  return withNotifications;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNotifications = () => {
  return useContext(NotificationsContext);
};

export default NotificationsProvider;
