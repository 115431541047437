import React from 'react';
import AuthRoute from 'routes/AuthRoute';
import * as Roles from 'helpers/auth/roles';
import { RouteList } from 'routes';
import { StepFormLayout } from 'containers';
import InvestorQuiz from './InvestorQuiz';

const QuizRoutes: any = () => [
  <AuthRoute
    path={RouteList.INVESTOR.QUIZ.VIEW}
    component={InvestorQuiz}
    layout={StepFormLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.INVESTOR.QUIZ.VIEW}
    exact
  />,
];

export default QuizRoutes;
