import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import { DocumentDownloadLink } from './shared/DocumentDownloadLink';
import {
  InvestmentDocumentResponseDto,
  InvestorOtherInvestmentListResponseDto,
} from 'api/types/investor/investment';
import { Cell, UseTableCellProps } from 'react-table';
import DocumentsTable from './DocumentsTable';
import { resolveTranslatableValueDto } from 'helpers/utils';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';
import Expander from 'components/Table/Expander';

const OPTION_ALL = 'all';
const OPTION_ACTIVE = 'active';
const OPTION_INACTIVE = 'inactive';
const OPTION_LATE = 'late';
const OPTION_SOLD = 'sold';
const OPTION_RECOVERING = 'recovering';
const OPTION_OTHER = 'other';

const TABLE_OPTIONS = [
  OPTION_ACTIVE,
  OPTION_INACTIVE,
  OPTION_LATE,
  OPTION_SOLD,
  OPTION_RECOVERING,
  OPTION_OTHER,
  OPTION_ALL,
];

const InvestmentDocumentsTable: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const [selectedTableOption, setSelectedTableOption] = useState<string>(OPTION_ACTIVE);

  const baseColumns = React.useMemo(
    () => [
      {
        id: 'expander',
        Cell: ({ row }: UseTableCellProps<InvestmentDocumentResponseDto>) => <Expander row={row} />,
      },
      {
        Header: t('investments.investment_table.project_name'),
        accessor: 'project_name',
        Cell: ({ row }: UseTableCellProps<InvestmentDocumentResponseDto>) => (
          <ProjectTitleLink
            title={row.original.project_name ?? ''}
            projectId={row.original.project_id}
            privateId={row.original.project_pid}
          />
        ),
      },
      {
        Header: t('investments.investment_table.investment_date'),
        accessor: 'invested_at',
      },
      {
        Header: t('investments.investment_table.investment_conditions'),
        accessor: 'investment_conditions',
        Cell: ({ row }: UseTableCellProps<InvestmentDocumentResponseDto>) => (
          <div className="d-flex justify-content-center">
            {row.original.investment_conditions && (
              <DocumentDownloadLink file={row.original.investment_conditions} />
            )}
          </div>
        ),
      },
      {
        Header: t('investments.investment_table.loan_agreement'),
        accessor: 'loan_agreement',
        Cell: ({ row }: UseTableCellProps<InvestmentDocumentResponseDto>) => (
          <div className="d-flex justify-content-center">
            {row.original.loan_agreement && (
              <DocumentDownloadLink file={row.original.loan_agreement} />
            )}
          </div>
        ),
      },
      {
        Header: t('investments.investment_table.payment_schedule'),
        accessor: 'payment_schedule',
        Cell: ({ row }: UseTableCellProps<InvestmentDocumentResponseDto>) => (
          <div className="d-flex justify-content-center">
            {row.original.payment_schedule && (
              <DocumentDownloadLink file={row.original.payment_schedule} />
            )}
          </div>
        ),
      },
      {
        Header: t('investments.investment_table.other_documents'),
        accessor: 'other_documents',
        Cell: ({ row }: UseTableCellProps<InvestmentDocumentResponseDto>) => (
          <div>
            {row.original.other_documents &&
              row.original.other_documents.length > 0 &&
              row.original.other_documents.map((document, index) => (
                <div className={'d-flex flex-column pb-2'} key={index}>
                  <DocumentDownloadLink file={document} />
                </div>
              ))}
          </div>
        ),
      },
    ],
    [t],
  );

  const expander = React.useMemo(
    () => [
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestmentDocumentResponseDto>) => <Expander row={row} />,
      },
    ],
    [],
  );

  const columnsOther = React.useMemo(
    () => [
      ...baseColumns,
      {
        Header: t('table.comment'),
        accessor: 'comment',
        sortType: 'string',
        Cell: (cell: Cell<InvestorOtherInvestmentListResponseDto>) => {
          return <span>{resolveTranslatableValueDto(cell.value)}</span>;
        },
      },
      ...expander,
    ],
    [baseColumns, expander, t],
  );

  const columns = React.useMemo(() => [...baseColumns, ...expander], [baseColumns, expander]);

  return (
    <>
      <div className={'mb-3'}>
        <div className={'main-filter mb-3'}>
          <div className={'filterable-btns'}>
            {TABLE_OPTIONS.map((option: string, idx) => (
              <button
                className={`${option === selectedTableOption && 'active'}`}
                key={idx}
                onClick={() => setSelectedTableOption(option)}
              >
                {tHtml(`document.filters.${option}`)}
              </button>
            ))}
          </div>
        </div>
      </div>
      {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_ACTIVE) && (
        <>
          <h2 className={'main-filter__header'}>{tHtml('document.active')}</h2>
          <DocumentsTable columns={columns} selectedTableOption={OPTION_ACTIVE} />
        </>
      )}

      {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_INACTIVE) && (
        <>
          <h2 className={'main-filter__header'}>{tHtml('document.inactive')}</h2>
          <DocumentsTable columns={columns} selectedTableOption={OPTION_INACTIVE} />
        </>
      )}

      {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_LATE) && (
        <>
          <h2 className={'main-filter__header'}>{tHtml('document.late')}</h2>
          <DocumentsTable columns={columns} selectedTableOption={OPTION_LATE} />
        </>
      )}

      {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_SOLD) && (
        <>
          <h2 className={'main-filter__header'}>{t('document.sold')}</h2>
          <DocumentsTable columns={columns} selectedTableOption={OPTION_SOLD} />
        </>
      )}

      {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_RECOVERING) && (
        <>
          <h2 className={'main-filter__header'}>{t('document.recovering')}</h2>
          <DocumentsTable columns={columnsOther} selectedTableOption={OPTION_RECOVERING} />
        </>
      )}

      {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_OTHER) && (
        <>
          <h2 className={'main-filter__header'}>{t('document.other')}</h2>
          <DocumentsTable columns={columnsOther} selectedTableOption={OPTION_OTHER} />
        </>
      )}
    </>
  );
};

export default InvestmentDocumentsTable;
