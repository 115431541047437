import React from 'react';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';

type Props = SecondaryMarketInvestmentContextState;

const TabNews: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  if (!secondaryMarketInvestment) return null;

  // TODO: translations
  // TODO: display project news information
  return (
    <>
      <section>
        <h2 className={'mb-3'}>{'Projekto eiga'}</h2>
        <table className={'table table-compact mb-4'}>
          <thead>
            <th scope={'col'}>{'Data'}</th>
            <th scope={'col'}>{'Įvykis'}</th>
          </thead>
          <tbody>
            <tr>
              <td>{'2022-02-18'}</td>
              <td>{'Pasirašyta hipotekos sutartis bei pervesti pinigai paskolos gavėjui.'}</td>
            </tr>
          </tbody>
        </table>
        <h2 className={'mb-3'}>{'Paskolos mokėjimų grafikas'}</h2>
        <table className={'table table-compact mb-4'}>
          <thead>
            <th scope={'col'}>{'Data'}</th>
            <th scope={'col'}>{'Įvykis'}</th>
          </thead>
          <tbody>
            <tr>
              <td>{'2022-05-31'}</td>
              <td>{'Palūkanų išmokėjimas'}</td>
            </tr>
            <tr>
              <td>{'2022-08-09'}</td>
              <td>{'Palūkanų išmokėjimas ir investicijų grąžinimas'}</td>
            </tr>
          </tbody>
        </table>
        <p>
          {'Jei mokėjimo data yra nedarbo diena, mokėjimas atliekamas artimiausią darbo dieną.'}
        </p>
        <p>
          {
            'Svarbu atkreipti dėmesį, jog Profitus iš Paskolos gavėjo gautas su Paskolos grąžinimu susijusias įmokas per 3 (tris) darbo dienas paskirsto ir perveda investuotojams.'
          }
        </p>
      </section>
    </>
  );
};

export default withSecondaryMarketInvestment(TabNews);
