import { useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useAuth } from 'services/useAuth';
import useAppState from 'modules/appState';
import Api from 'api';

const AccountSwapFromUrlManager = () => {
  const { initialRoute, setIsSwitchingAccount } = useAppState();
  const { login } = useAuth();
  const [queryLoginAs, setQueryLoginAs] = useQueryParam('login_as', StringParam);

  useEffect(() => {
    if (queryLoginAs) {
      setIsSwitchingAccount(true);

      Api.shared.loginAs
        .attemptLoginAs(queryLoginAs)
        .then(async (response) => {
          if (response.access_token) {
            await login(
              response.access_token,
              response.permissions,
              initialRoute
                ? {
                    ...initialRoute,
                    search: '',
                  }
                : null,
            );
          }
        })
        .finally(() => {
          setIsSwitchingAccount(false);
        });
    }

    setQueryLoginAs(undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default AccountSwapFromUrlManager;
