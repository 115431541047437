import React, { useContext, useEffect, useImperativeHandle } from 'react';
import { useField } from 'formik';
import { action } from 'typesafe-actions';

import Api from 'api';
import { MediaInfoWithTitleRequestDto, SignUploadSingleFileRequestDto } from 'api/types/app';
import FileUploadContext from './module/FileUploadContext';
import * as FileUploadReducer from './module/FileUploadReducer';
import { FileStateEnum, IFile } from './module/FileUploadReducer';
import { FileUploadHandle, FileUploadProps } from './FileUpload';
import FileUploadZone from './FileUploadZone';
import FileUploadList from './FileUploadList';

const FileUploadComponent: React.ForwardRefRenderFunction<FileUploadHandle, FileUploadProps> = (
  props,
  fileUploadRef,
) => {
  const [field, , helper] = useField(props.name);
  const { state, dispatch } = useContext(FileUploadContext);

  useImperativeHandle(
    fileUploadRef,
    () => ({
      isFilesUploading: () => {
        return state.isUploading;
      },
      reset: () => {
        dispatch(action(FileUploadReducer.REMOVE_FILES));
      },
    }),
    [dispatch, state.isUploading],
  );

  useEffect(() => {
    dispatch(action(FileUploadReducer.EXISTING_FILE_ADD, field.value));
  }, [dispatch, field.value]);

  useEffect(() => {
    const files: MediaInfoWithTitleRequestDto[] = [];
    Object.keys(state.files).map((key) => {
      const curFile = state.files[key];
      if (curFile.state === FileStateEnum.Uploaded && curFile.meta) {
        files.push({ id: curFile.meta?.id, name: curFile.meta?.name });
      }
    });

    helper.setValue(files, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.files]);

  useEffect(() => {
    const filteredFiles: { [key: string]: IFile } = {};

    Object.keys(state.files).forEach((key) => {
      if (state.files[key].state == FileStateEnum.Add) {
        filteredFiles[key] = state.files[key];
      }
    });

    const convertedFiles: SignUploadSingleFileRequestDto[] = Object.keys(filteredFiles).map(
      (key) => {
        return {
          name: filteredFiles[key].name,
          type: filteredFiles[key].file?.type ?? '-',
          hash: key,
        };
      },
    );

    if (convertedFiles.length === 0) return;

    convertedFiles.forEach((file) => {
      dispatch(action(FileUploadReducer.FILE_UPLOADING, file.hash));
    });

    props.onPresign({ files: convertedFiles }).then(async (response) => {
      if (response.files.length === 0) {
        return false;
      }

      const promises: Promise<any>[] = response.files.map((file) => {
        return new Promise((resolve) => {
          const ff = filteredFiles[file.hash].file;
          if (!ff) return;

          Api.s3
            .uploadFile(file.url, ff)
            .then(() => {
              dispatch(action(FileUploadReducer.FILE_UPLOADED, file));
              resolve(true);
            })
            .catch(() => resolve(true));

          dispatch(action(FileUploadReducer.FILE_UPLOADING, file.hash));
        });
      });

      await Promise.all(promises);

      return true;
    });
  }, [dispatch, props, state.files]);

  return (
    <div className={'file-upload'}>
      <FileUploadZone {...props} />

      <FileUploadList {...props} />
    </div>
  );
};

export default React.forwardRef(FileUploadComponent);
