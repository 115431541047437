import styled from 'styled-components';

export const QrCodeModalWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1.5rem;
`;

export const StyledP = styled.p`
  font-size: 1rem !important;
`;
