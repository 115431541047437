import React, { useState } from 'react';

import SelectBox, { SelectBoxProps } from 'components/Formik/SelectBox';
import { SelectOption } from 'components/Formik/SelectInput';
import { AvailableCountriesEnum } from 'helpers/enums/AvailableCountriesEnum';
import useTranslation from 'helpers/useTranslation';

const MultiSelectAvailableProjectCountries: React.FC<Omit<SelectBoxProps, 'selectOptions'>> = (
  props,
) => {
  const { t } = useTranslation();

  const [optionsRatings] = useState<SelectOption[]>(() =>
    Object.values(AvailableCountriesEnum).map((value) => {
      return {
        label: t('countries.' + value),
        value: value,
      };
    }),
  );

  return (
    <>
      <SelectBox selectOptions={optionsRatings} closeMenuOnSelect={false} {...props} />
    </>
  );
};

export default MultiSelectAvailableProjectCountries;
