import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { DocumentsSkeleton, InvestmentDocumentsTable, PersonalDocumentsTable } from './components';
import SkeletonLoader from 'components/SkeletonLoader';

const Documents: React.FC = () => {
  return (
    <Container>
      <SkeletonLoader loading={false} customPlaceholder={<DocumentsSkeleton />}>
        <PersonalDocumentsTable />
        <InvestmentDocumentsTable />
      </SkeletonLoader>
    </Container>
  );
};

export default withRouter(Documents);
