import React from 'react';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';
import useTranslation from 'helpers/useTranslation';

type Props = SecondaryMarketInvestmentContextState;

// TODO: mokėjimų grafikas
const TabPayouts: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  const { tHtml } = useTranslation();

  if (!secondaryMarketInvestment) return null;

  return (
    <>
      <section>
        <h2 className={'mb-3'}>{tHtml('secondary_market.project.tab.payouts')}</h2>
      </section>
    </>
  );
};

export default withSecondaryMarketInvestment(TabPayouts);
