import React from 'react';
import { ButtonProps } from 'reactstrap';
import { PrimaryButton as Button } from 'components/Button';

const SocialButton: React.FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
  children,
  icon,
  ...props
}) => {
  const propsInput: any = {};

  Object.keys(props).forEach(function (key) {
    if (key === 'submitting') return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    propsInput[key] = props[key];
  });

  return (
    <Button
      type="submit"
      disabled={props.submitting}
      className={'btn btn-primary btn-icon w-100'}
      {...propsInput}
    >
      {props.submitting ? (
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <div className={'d-flex justify-content-center align-items-center'}>
          {icon && (
            <>
              <i className={`me-2 align-middle ${icon}`} />{' '}
            </>
          )}
          {children}
        </div>
      )}
    </Button>
  );
};

export default SocialButton;
