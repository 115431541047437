import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import useTranslation from 'helpers/useTranslation';
import * as Yup from 'yup';

import Api from 'api';
import { RouteList } from 'routes';
import PrimaryButton from 'components/Formik/PrimaryButton';
import { CommonEnum } from 'helpers/enums/CommonEnum';
import { success } from 'services/toastr';
import usePermission from 'helpers/usePermissions';
import { Permission } from 'helpers/auth/permissions';
import { Form, FormGroup } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import { ConfirmAccountRequestDto } from 'api/types/guest/authenticate';
import CodeInput from 'components/Formik/Inputs/CodeInput';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import { useAuth } from '../../../../services/useAuth';

const INITIAL_REQUEST: ConfirmAccountRequestDto = {
  confirmation_code: '',
};

const MailVerification: React.FC<RouteComponentProps> = ({ history }) => {
  const { t, tHtml } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
  const [sleepSeconds, setSleepSeconds] = useState(0);
  const { refresh } = useAuth();

  const { data: userProfileData } = useUserProfileQuery();

  const { hasAll } = usePermission();

  useEffect(() => {
    if (hasAll(Permission.EMAIL_VERIFIED)) {
      history.push(RouteList.GUEST.AUTHENTICATION.REGISTER_DATA_FILLING);
    }
  }, [hasAll, history]);

  useEffect(() => {
    let interval: any = undefined;

    if (sleepSeconds > 0) {
      setIsTimerRunning(true);
      interval = setInterval(() => {
        setSleepSeconds((prevValue) => prevValue - 1);
      }, 1000);
    } else if (sleepSeconds <= 0) {
      setIsTimerRunning(false);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [sleepSeconds]);

  const ConfirmationSchema = Yup.object().shape({
    confirmation_code: Yup.string().confirmationCode().required(),
  });

  const onSubmit = useCallback(
    async (request: ConfirmAccountRequestDto, helpers: FormikHelpers<ConfirmAccountRequestDto>) => {
      try {
        await Api.shared.user.signUpSteps.confirmAccount(request);
        success(tHtml('authentication.email_confirmed'));
        await refresh();
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [refresh, tHtml],
  );

  const onMailResend = useCallback(async () => {
    if (isLoading) return;
    setSleepSeconds(60);
    setIsLoading(true);

    try {
      const response = await Api.shared.user.signUpSteps.resendConfirmationMail();
      if (response) {
        success(tHtml('authentication.email_resent'));
      }
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, tHtml]);

  return (
    <React.Fragment>
      <div className={'top-title d-flex flex-row mb-4'}>
        <h4 className={'me-auto mb-0 align-self-center'}>
          {tHtml('authentication.step_mail_verification')}
        </h4>
      </div>
      <p className={'mb-4'}>
        <Trans
          components={[
            <a key={0} href="types/investor/shared/pages/Authentication/MailVerification#" />,
          ]}
        >
          {t('authentication.email_confirmation_text', {
            email: userProfileData?.user?.email ?? '-',
          })}
        </Trans>
      </p>
      <div>
        <Formik
          initialValues={INITIAL_REQUEST}
          enableReinitialize={true}
          validationSchema={ConfirmationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <div className="d-flex justify-content-center mb-3">
                  <CodeInput
                    name={'confirmation_code'}
                    inputMode={'numeric'}
                    fields={6}
                    placeholder={t('authentication.confirmation_code')}
                  />
                </div>
                <div className={'mt-4 mb-5'}>
                  <PrimaryButton type={'submit'} submitting={isSubmitting}>
                    {tHtml('common.submit')}
                  </PrimaryButton>
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
      <hr />
      <div className={'mb-4 fw-bold'}>{tHtml('authentication.email_not_received')}</div>
      <div className={'mb-5'}>
        <PrimaryButton submitting={isLoading} onClick={onMailResend} disabled={isTimerRunning}>
          {isTimerRunning ? sleepSeconds.toString() : tHtml('authentication.email_resend')}
        </PrimaryButton>
      </div>
      <div>
        <small>
          <Trans components={[<a key={0} href={'mailto:' + CommonEnum.InfoEmail} />]}>
            {t('authentication.email_contact_us_if_not_received', {
              email: CommonEnum.InfoEmail,
            })}
          </Trans>
        </small>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MailVerification);
