import React, { useEffect, useState } from 'react';
import { ArrayHelpers } from 'formik';
import { Card } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import { ApplicationSingleSecurityDepositDto } from 'api/types/project-developer/application';
import { DataBlock } from 'components/DataBlock';
import { FunctionalButton } from 'components/Button';
import SelectInput, { SelectOption } from 'components/Formik/SelectInput';
import { TextInput } from 'components/Formik';
import { DatepickerInput } from 'components/Formik/Inputs';
import { ApplicationRealEstateType } from 'scopes/developer/helpers/enums/ApplicationRealEstateType';

const SingleSecurityDeposit: React.FC<{
  deposit: ApplicationSingleSecurityDepositDto;
  index: number;
  formikHelpers: any;
  formikArrayHelpers: ArrayHelpers;
}> = ({ deposit, index, formikHelpers, formikArrayHelpers }) => {
  const { t, tHtml } = useTranslation();
  const [show, setShow] = useState<boolean>(true);

  const [applicationRealEstateTypes] = useState<SelectOption[]>(() =>
    Object.values(ApplicationRealEstateType).map((value) => {
      return {
        label: t(`placeholder.application.real_estate_unique.${value}`),
        value: value,
      };
    }),
  );

  const realEstateType = `security_deposits[${index}].real_estate_type`;
  const realEstateUnique = `security_deposits[${index}].real_estate_unique`;
  const realEstatePlot = `security_deposits[${index}].real_estate_plot`;
  const realEstateAddress = `security_deposits[${index}].real_estate_address`;
  const realEstateValue = `security_deposits[${index}].real_estate_value`;
  const realEstateAppraiser = `security_deposits[${index}].real_estate_appraiser`;
  const realEstateAppraisedAt = `security_deposits[${index}].real_estate_appraised_at`;

  const setVisibility = async () => {
    const errors = await formikHelpers.validateForm();

    let show = false;
    if (
      errors != undefined &&
      errors.security_deposits != undefined &&
      errors.security_deposits[index] != undefined
    ) {
      show = true;
    }

    setShow(show);
  };

  useEffect(() => {
    setVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    index,
    formikHelpers.validateForm,
    formikHelpers.submitCount,
    formikHelpers.values.security_deposits.length,
  ]);

  if (!show) {
    return (
      <div className={'step-form-cards'}>
        <Card body className={'light-pink'}>
          <div className={'d-flex justify-content-between align-items-center'}>
            {/* TODO: add translations */}
            <DataBlock title={'Turtas'} data={deposit.real_estate_address} />
            <DataBlock title={'Vertė'} data={`€ ${deposit.real_estate_value}`} />
            <div className="buttons">
              <FunctionalButton onClick={() => setShow(true)}>
                {tHtml('common.edit')}
              </FunctionalButton>
              <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
                {tHtml('common.remove')}
              </FunctionalButton>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="sub-form">
      <div className="mb-3">
        <SelectInput
          name={realEstateType}
          options={applicationRealEstateTypes}
          placeholder={tHtml('placeholder.application.real_estate_type')}
        />
      </div>
      <div className="mb-3">
        <TextInput
          name={realEstateUnique}
          placeholder={t('placeholder.application.real_estate_unique')}
        />
      </div>
      <div className="mb-3">
        <TextInput
          name={realEstatePlot}
          placeholder={t('placeholder.application.real_estate_plot')}
        />
      </div>
      <div className="mb-3">
        <TextInput
          name={realEstateAddress}
          placeholder={t('placeholder.application.real_estate_address')}
        />
      </div>
      <div className="mb-3">
        <TextInput
          type={'number'}
          name={realEstateValue}
          placeholder={t('placeholder.application.real_estate_value')}
        />
      </div>
      <div className="mb-3">
        <TextInput
          name={realEstateAppraiser}
          placeholder={t('placeholder.application.real_estate_appraiser')}
        />
      </div>
      <div className="mb-3">
        <DatepickerInput
          hasFutureRange={true}
          name={realEstateAppraisedAt}
          label={t('placeholder.application.real_estate_appraised_at')}
        />
      </div>
      <div className="mb-3">
        <div className="buttons">
          <FunctionalButton onClick={() => setVisibility()}>
            {tHtml('common.save')}
          </FunctionalButton>
          <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
            {tHtml('common.remove')}
          </FunctionalButton>
        </div>
      </div>
    </div>
  );
};

export default SingleSecurityDeposit;
