import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import usePermission from 'helpers/usePermissions';
import { StepStatusEnum } from 'helpers/enums/StepStatusEnum';
import { registrationSteps, Step } from 'scopes/investor/containers/RegistrationStepLayout/steps';
import useOnboarding from '../../helpers/useOnboarding';

const RegistrationSteps: React.FC = () => {
  const { tHtml } = useTranslation();
  const [steps, setSteps] = useState<Array<Step>>([]);
  const { hasAll } = usePermission();

  const { currentStep } = useOnboarding();

  useEffect(() => {
    const tempSteps = [...registrationSteps];

    for (let i = 0; i < tempSteps.length; i++) {
      if (tempSteps[i].status === StepStatusEnum.Hidden) continue;

      const tempIndex = currentStep ?? 1;

      if (i < tempIndex) {
        tempSteps[i].status = StepStatusEnum.Complete;
      } else if (i == tempIndex) {
        tempSteps[i].status = StepStatusEnum.Active;
      } else {
        tempSteps[i].status = StepStatusEnum.Incomplete;
      }
    }

    setSteps(tempSteps);
  }, [hasAll, currentStep]);

  const getClassName = (status: StepStatusEnum) => {
    switch (status) {
      case StepStatusEnum.Complete:
        return 'completed';
      case StepStatusEnum.Active:
        return 'active';
      default:
        return '';
    }
  };

  return (
    <>
      <div className={'info'}>
        {tHtml('profile.account_registration.message.account_registration')}
        <strong>
          {(currentStep ?? 1) * 25}% {tHtml('profile.account_registration.message.completed')}
        </strong>
      </div>
      <div className="steps">
        {steps.map((step, idx) => {
          if (step.status == StepStatusEnum.Hidden) return '';

          return (
            <div key={step.name} className={`step ${getClassName(step.status)}`}>
              <div className="number">{idx + 1}</div>
              <div className="text">{tHtml('authentication.step_' + step.name)}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RegistrationSteps;
