import { ModalComponent } from 'components/Modal/ModalComponent';
import { ModalBody, ModalHeader } from 'reactstrap';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { HighlightButton } from 'components/Button';
import { PrimaryButton, TextInput } from 'components/Formik';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import Api from 'api';
import { DeleteTwoFactorAuthRequestDto } from 'api/types/shared/user/profile';
import { success } from 'services/toastr';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';

const DisableTwoFactorAuthModal = () => {
  const { t, tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const { refetch: userProfileDataRefetch } = useUserProfileQuery();

  const TwoFactorAuthDelete = Yup.object().shape({
    code: Yup.string().required(),
  });

  const handleRemoveTwoFactorAuth = async (
    values: DeleteTwoFactorAuthRequestDto,
    helpers: FormikHelpers<DeleteTwoFactorAuthRequestDto>,
  ) => {
    try {
      await Api.shared.user.profile.deleteTwoFactorAuth(values);
      success(tHtml('placeholder.2fa.remove_success'));
      userProfileDataRefetch();
      hideModal();
    } catch (e) {
      helpers.setErrors({ code: t('placeholder.2fa.2fa_confirm_failed') });
    }
  };

  return (
    <ModalComponent size={'md'} contentClassName={'two-factor-auth-modal'}>
      <div className={'p-3'}>
        <ModalHeader toggle={hideModal} close={<ModalCloseButton />} tag={'h2'} className={'mt-2'}>
          {tHtml('placeholder.2fa')}
        </ModalHeader>
        <ModalBody>
          <span>{tHtml('placeholder.2fa.remove_description')}</span>
          <div className={'mt-4'}>
            <Formik
              initialValues={{
                code: '',
              }}
              validationSchema={TwoFactorAuthDelete}
              onSubmit={handleRemoveTwoFactorAuth}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={'form-input'}>
                    <TextInput
                      name="code"
                      placeholder={t('placeholder.2fa.2fa_code')}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSubmit();
                        }
                      }}
                    />
                  </div>
                  <div className={'d-flex justify-content-around mt-3'}>
                    <HighlightButton
                      className={'btn btn-highlight w-50 fw-normal me-3'}
                      onClick={hideModal}
                    >
                      {tHtml('common.cancel')}
                    </HighlightButton>
                    <PrimaryButton
                      disabled={isSubmitting}
                      className={'btn btn-primary w-50 fw-normal'}
                      type={'submit'}
                    >
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </div>
    </ModalComponent>
  );
};

export default DisableTwoFactorAuthModal;
