import React from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { ModalComponent } from 'components/Modal/ModalComponent';
import { PrimaryButton } from 'components/Button';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';
import { RouteList } from 'routes';
import { navigate } from 'helpers';
import { useAccountQuery } from 'api/queries';
import Api from 'api';
import { useAuth } from 'services/useAuth';
import {
  ModalWrapper,
  StepDiv,
  StepsWrapper,
  StepText,
} from '../../investor/modals/RequiredSteps/RequiredStepsModal.style';
import StepsModalCloseButton from '../../investor/modals/RequiredSteps/components/StepsModalCloseButton';

const DeveloperRequiredStepsModal: React.FC = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const { login } = useAuth();
  const { hideModal } = useGlobalModalContext();
  const { data } = useUserCompanyProfileQuery();
  const { data: userAccount } = useAccountQuery();

  const handleAction = async () => {
    hideModal();
    const response = await Api.shared.loginAs.userLogin();
    await login(response.access_token, response.permissions);
    navigate(RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION);
  };

  const steps = () => {
    return (
      userAccount &&
      data && (
        <ModalWrapper>
          <StepsWrapper>
            <StepDiv>
              <div className="steps">
                <div
                  className={`step ${
                    userAccount.main_user_verified === true &&
                    userAccount.is_document_expired != true
                      ? 'completed'
                      : 'active'
                  }`}
                >
                  <div className="number">{1}</div>
                </div>
              </div>
              <StepText>{tHtml('modal.steps.owner')}</StepText>
              {userAccount.main_user_verified === true &&
              userAccount.is_document_expired != true ? null : (
                <PrimaryButton onClick={handleAction}>
                  {tHtml('modal.steps.complete')}
                </PrimaryButton>
              )}
            </StepDiv>
            <StepDiv lastElement={true}>
              <div className="steps">
                <div
                  className={`step ${
                    data.know_your_business != null && userAccount.is_investor_kyb_expired != true
                      ? 'completed'
                      : 'active'
                  }`}
                >
                  <div className="number">{2}</div>
                </div>
              </div>
              <StepText>{tHtml('modal.steps.kyb')}</StepText>
              {data.know_your_business != null &&
              userAccount.is_investor_kyb_expired != true ? null : (
                <PrimaryButton
                  onClick={() => {
                    navigate(RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS);
                    hideModal();
                  }}
                >
                  {tHtml('modal.steps.complete')}
                </PrimaryButton>
              )}
            </StepDiv>
          </StepsWrapper>
        </ModalWrapper>
      )
    );
  };

  return (
    <React.Fragment>
      <ModalComponent
        size={'md'}
        {...props}
        contentClassName={'modal-investment'}
        backdrop="static"
      >
        <div className={'p-3'}>
          <ModalHeader toggle={hideModal} close={<StepsModalCloseButton />} tag={'h1'}>
            {tHtml('modal.steps.developer_title')}
          </ModalHeader>
          <ModalBody>{steps()}</ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default DeveloperRequiredStepsModal;
