import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  AutoStrategyChatData,
  InvestorAutoInvestmentConfigPackEnum,
  InvestorAutoInvestmentFullConfig,
  InvestorAutoInvestmentResponseDto,
  InvestorAutoStrategy,
} from 'api/types/investor/investment';
import Api from 'api';

export type StrategyStatisticsState = {
  activeAverageAnnualInterest: number;
  averageWeightedRating: number;
  chartData: AutoStrategyChatData;
};

export interface AutoInvestmentContextState {
  statistics: {
    [key: string]: StrategyStatisticsState;
  } | null;
  isGlobalActive: boolean;
  isLoading: boolean;
  timesLoaded: number;
  maxShareLongTerm: number | null;
  maxSharePerBorrower: number | null;
  newStrategy: InvestorAutoInvestmentConfigPackEnum | undefined;
  autoInvestmentConfig: InvestorAutoInvestmentFullConfig | undefined;
  autoInvestmentData: InvestorAutoStrategy[] | undefined;
  updateAutoInvestmentData: (response: InvestorAutoInvestmentResponseDto) => void;
  setNewStrategy: (value: InvestorAutoInvestmentConfigPackEnum | undefined) => void;
}

const AutoInvestmentContext = createContext<AutoInvestmentContextState>({
  isGlobalActive: false,
  statistics: null,
  maxShareLongTerm: null,
  maxSharePerBorrower: null,
  isLoading: true,
  timesLoaded: 0,
  newStrategy: undefined,
  setNewStrategy: () => undefined,
  autoInvestmentData: undefined,
  autoInvestmentConfig: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateAutoInvestmentData: () => {},
});

export const AutoInvestmentProvider: React.FC = ({ children }) => {
  const [autoInvestmentData, setAutoInvestmentData] = useState<InvestorAutoStrategy[]>();
  const [newStrategy, setNewStrategy] = useState<InvestorAutoInvestmentConfigPackEnum | undefined>(
    undefined,
  );
  const [autoInvestmentConfig, setAutoInvestmentConfig] =
    useState<InvestorAutoInvestmentFullConfig>();
  const [isGlobalActive, setGlobalActive] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [timesLoaded, setTimesLoaded] = useState<number>(0);
  const [statistics, setStatistics] = useState<any>(null);
  const [maxShareLongTerm, setMaxShareLongTerm] = useState<number | null>(null);
  const [maxSharePerBorrower, setMaxSharePerBorrower] = useState<number | null>(null);

  const updateAutoInvestmentData = (response: InvestorAutoInvestmentResponseDto) => {
    setAutoInvestmentData(response.strategies);
    setMaxShareLongTerm(response.max_share_long_term);
    setMaxSharePerBorrower(response.max_share_per_borrower);
    setGlobalActive(!response.disabled_at);
    setTimesLoaded((prev) => prev + 1);
  };

  const updateStatistics = React.useCallback(async (strategies) => {
    const statsPromises = strategies.map(async (strategy: InvestorAutoStrategy) => {
      const [interestResult, ratingResult, chartDataResult] = await Promise.all([
        Api.investor.investments.fetchAutoInvestmentStatisticAvgAnnualInterest(strategy.id),
        Api.investor.investments.fetchAutoInvestmentStatisticAvgWeightedRating(strategy.id),
        Api.investor.investments.fetchAutoInvestmentStatisticChartData(strategy.id),
      ]);

      return {
        [strategy.id]: {
          activeAverageAnnualInterest: interestResult.active_average_annual_interest,
          averageWeightedRating: ratingResult.average_weighted_rating,
          chartData: chartDataResult,
        },
      };
    });

    const stats = await Promise.all(statsPromises);
    setStatistics(Object.assign({}, ...stats));
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [dataResult, configResult] = await Promise.allSettled([
          Api.investor.investments.fetchAutoInvestment(),
          Api.investor.investments.fetchAutoInvestmentConfigs(),
        ]);

        if (dataResult.status === 'fulfilled') {
          updateAutoInvestmentData(dataResult.value);
          updateStatistics(dataResult.value.strategies);
        } else {
          console.error(dataResult.reason);
        }

        if (configResult.status === 'fulfilled') {
          setAutoInvestmentConfig(configResult.value);
        } else {
          console.error(configResult.reason);
        }
      } catch (error) {
        console.error('Unexpected error:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AutoInvestmentContext.Provider
      value={{
        isLoading,
        timesLoaded,
        newStrategy,
        setNewStrategy,
        autoInvestmentData,
        autoInvestmentConfig,
        isGlobalActive,
        updateAutoInvestmentData,
        statistics,
        maxShareLongTerm,
        maxSharePerBorrower,
      }}
    >
      {children}
    </AutoInvestmentContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function withAutoInvestmentData<
  P extends AutoInvestmentContextState = AutoInvestmentContextState,
>(Component: React.ComponentType<P>) {
  const WithAutoInvestmentData = ({ ...props }: Optionalize<P, AutoInvestmentContextState>) => {
    const context = useContext(AutoInvestmentContext);
    return <Component {...context} {...(props as P)} />;
  };

  WithAutoInvestmentData.displayName = `withAutoInvestmentData${Component.displayName}`;

  return WithAutoInvestmentData;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAutoInvestmentData = () => {
  return useContext(AutoInvestmentContext);
};

export default AutoInvestmentProvider;
