import React, { useEffect, useState } from 'react';
import { ModalComponent } from 'components/Modal/ModalComponent';
import { ModalBody, ModalHeader } from 'reactstrap';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { PrimaryButton } from 'components/Formik';
import useTranslation from 'helpers/useTranslation';
import { success } from 'services/toastr';
import { InvestorDocumentResponseDto } from 'api/types/investor/document';
import Api from 'api';
import Loader from 'components/Loader';
import { openMedia } from 'services/media';
import { useAccountQuery } from 'api/queries';

const AcceptTermsAndConditionsModal = () => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const { refetch: accountQueryRefetch } = useAccountQuery();

  const [viewedTermsAndConditions, setViewedTermsAndConditions] = useState<boolean>(false);
  const [termsAndConditions, setTermsAndConditions] = useState<InvestorDocumentResponseDto | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleViewTermsAndConditions = () => {
    setIsLoading(true);
    if (termsAndConditions && termsAndConditions.url) {
      openMedia(termsAndConditions.url).then(() => {
        setViewedTermsAndConditions(true);
        setIsLoading(false);
      });
    }
  };

  const handleAcceptTermsAndConditions = () => {
    setIsLoading(true);
    Api.investor.documents.acceptTermsAndConditions().then(() => {
      success(tHtml('placeholder.terms_and_conditions.accepted'));
      hideModal();
      accountQueryRefetch();
      setIsLoading(false);
    });
  };

  useEffect(() => {
    Api.investor.documents.fetchTermsAndConditions().then((response) => {
      setTermsAndConditions(response);
    });
  }, []);

  return (
    <ModalComponent size={'md'} contentClassName={'terms-and-conditions-accept-modal'}>
      <div className={'p-3'}>
        <ModalHeader toggle={hideModal} close={<ModalCloseButton />} tag={'h2'} className={'mt-2'}>
          {tHtml('placeholder.terms_and_conditions.title')}
        </ModalHeader>
        <ModalBody>
          <span>{tHtml('placeholder.terms_and_conditions.description')}</span>
          {termsAndConditions ? (
            <div className={'d-flex justify-content-around mt-3'}>
              {viewedTermsAndConditions ? (
                <PrimaryButton
                  className={'btn btn-primary w-50 fw-normal'}
                  onClick={handleAcceptTermsAndConditions}
                  submitting={isLoading}
                >
                  {tHtml('common.accept')}
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  className={'btn btn-primary w-50 fw-normal'}
                  onClick={handleViewTermsAndConditions}
                  submitting={isLoading}
                >
                  {tHtml('common.view')}
                </PrimaryButton>
              )}
            </div>
          ) : (
            <Loader />
          )}
        </ModalBody>
      </div>
    </ModalComponent>
  );
};

export default AcceptTermsAndConditionsModal;
