import React from 'react';

import * as Registration from '.';
import * as Roles from 'helpers/auth/roles';
import { RegistrationStepLayout } from 'scopes/investor/containers';
import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';

const AuthenticationRoutes: any = () => [
  <AuthRoute
    path={RouteList.GUEST.AUTHENTICATION.REGISTER_MAIL_VERIFICATION}
    component={Registration.MailVerification}
    layout={RegistrationStepLayout}
    permissions={Roles.UnverifiedUser.permissions}
    redirect={Roles.UnverifiedUser.redirect}
    key={RouteList.GUEST.AUTHENTICATION.REGISTER_MAIL_VERIFICATION}
    exact
  />,
  <AuthRoute
    path={RouteList.GUEST.AUTHENTICATION.REGISTER_DATA_FILLING}
    component={Registration.DataFilling}
    layout={RegistrationStepLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.GUEST.AUTHENTICATION.REGISTER_DATA_FILLING}
  />,
  <AuthRoute
    path={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}
    component={Registration.IDVerification}
    layout={RegistrationStepLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}
    exact
  />,
  <AuthRoute
    path={RouteList.GUEST.AUTHENTICATION.REGISTER_SUCCESS}
    component={Registration.RegistrationSuccess}
    layout={RegistrationStepLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.GUEST.AUTHENTICATION.REGISTER_SUCCESS}
    exact
  />,
];

export default AuthenticationRoutes;
