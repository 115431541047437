import React, { ReactElement, useEffect } from 'react';
import useTranslation from 'helpers/useTranslation';
import Select from 'react-select';
import { useField } from 'formik';
import { ReactSelectOption } from './ReactSelectInput';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import Label from '../Label';

export interface ReactGroupSelectProps extends StateManagerProps {
  name: string;
  groupedOptions: GroupedOption[] | undefined;
  hideLabel?: boolean;
  hasTooltip?: boolean;
  tooltipContent?: string | ReactElement;
}

export interface GroupedOption {
  label: string;
  options: ReactSelectOption[];
}

const ReactSelectGroupInput: React.FC<ReactGroupSelectProps> = ({
  name,
  groupedOptions,
  ...props
}) => {
  const { t, currentLanguage } = useTranslation();
  const [field, meta, helper] = useField(name);

  if (!props.placeholder) {
    props.placeholder = t('label.' + name);
  }

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const selectValue = () => {
    if (!groupedOptions) return '';

    for (const options of groupedOptions) {
      for (const option of options.options) {
        if (option.value === field.value) return option;
      }
    }
    return '';
  };

  const isError = meta?.touched && !!meta?.error;

  const defaultStyles = {
    valueContainer: (provided: any) => ({
      ...provided,
      height: '2.5rem',
    }),
    menu: (provided: any) => ({
      ...provided,
      color: 'black',
    }),
    ...props.styles,
  };

  return (
    <>
      <div className={isError ? 'is-invalid' : ''}>
        {!props.hideLabel && (
          <div className={'site-input'}>
            <Label
              title={props.placeholder}
              htmlFor={field.name}
              labelStyle={{ fontSize: '0.7rem', paddingLeft: '1rem' }}
              labelClassName={'form-label py-1'}
              hasTooltip={props.hasTooltip}
              tooltipContent={props.tooltipContent}
              smallTooltip={true}
            />
          </div>
        )}
        {/*TODO drop this and use SelectInput instead*/}
        <Select
          className={'fancy-select'}
          classNamePrefix={'fancy-select'}
          loadingMessage={() => t('common.loading')}
          noOptionsMessage={() => t('common.no_options')}
          placeholder={() => t('common.select')}
          options={groupedOptions}
          name={name}
          onBlur={field.onBlur}
          value={selectValue()}
          isLoading={groupedOptions === undefined}
          {...props}
          onChange={(option: any, meta: any) => {
            if (Array.isArray(option)) {
              helper.setValue((option as ReactSelectOption[]).map((option) => option.value));
            } else {
              helper.setValue(option?.value);
            }
            props.onChange && props.onChange(option, meta);
          }}
          styles={defaultStyles}
        />
      </div>
      {isError && <div className={isError ? 'invalid-feedback' : ''}>{meta.error}</div>}
    </>
  );
};

ReactSelectGroupInput.defaultProps = {
  hideLabel: false,
};

export default ReactSelectGroupInput;
