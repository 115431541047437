import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { default as env } from 'env.json';
import { buildYupValidations } from './yup/validations';
import { buildYupLocale } from './yup/locale';
import { format, parseISO } from 'date-fns';
import { CommonEnum } from 'helpers/enums/CommonEnum';

function getLanguage() {
  const currentDomain = window.location.hostname;
  const localStorageLanguage = localStorage.getItem(CommonEnum.Language);
  if (localStorageLanguage) {
    return JSON.parse(localStorageLanguage);
  }
  return (env.DOMAIN_MAP as { [key: string]: string })[currentDomain] || 'en';
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      debug: false,
      react: {
        useSuspense: true,
      },
      backend: {
        loadPath: env.BASE_URL + '/locale/{{lng}}',
        allowMultiLoading: false,
      },
      lng: getLanguage(),
      supportedLngs: ['lt', 'en', 'de', 'ee'],
      fallbackLng: 'lt',
      missingKeyHandler: (lngs, ns, key) => {
        console.info('Missing key translation key', key, lngs);
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format: function (value, formatting, lng, options) {
          if (value === undefined || value === null) return value;

          switch (formatting) {
            case 'bytes':
              const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

              let l = 0,
                n = parseInt(value, 10) || 0;

              while (n >= 1024 && ++l) {
                n = n / 1024;
              }

              return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
            case 'money':
              if (isNaN(value)) {
                return '-';
              }

              let formatter;
              if (lng === 'ee') {
                formatter = new Intl.NumberFormat(lng, {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  useGrouping: true,
                });
              } else {
                formatter = new Intl.NumberFormat(lng, {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                });
              }

              return formatter.format(value);
            case 'money_no_cents':
              if (isNaN(value)) {
                return '-';
              }

              const centsFormatter = new Intl.NumberFormat(lng, {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 0,
              });

              return centsFormatter.format(value);
            case 'money_signed':
              let numberFormatter;
              if (lng === 'ee') {
                numberFormatter = new Intl.NumberFormat(lng, {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  useGrouping: true,
                });
              } else {
                numberFormatter = new Intl.NumberFormat(lng, {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 2,
                  signDisplay: 'exceptZero',
                });
              }

              return numberFormatter.format(value);
            case 'percents':
              let val = value.toFixed(2);

              if (val.endsWith('00')) {
                val = value.toFixed(0);
              } else if (val.endsWith('0')) {
                val = val.slice(0, -1);
              }

              if (lng === 'en') {
                return val + '%';
              } else {
                return val.replace('.', ',') + '%';
              }
          }

          if (value instanceof Date && formatting) return format(value, formatting);

          if (formatting && options && 'date' in options && options['date']) {
            const date = options['date'];
            return format(parseISO(date), formatting);
          }

          return value;
        },
      },
      // TODO fix this initial load
      preload: ['lt'],
      load: 'languageOnly',
      initImmediate: false,
    },
    () => {
      buildYupLocale();
      buildYupValidations();
    },
  );

export default i18n;
