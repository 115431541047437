import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik, FormikHelpers } from 'formik';

import { SwitchInput } from 'components/Inputs';
import { InvestorAutoInvestmentUpdateStatusRequestDto } from 'api/types/investor/investment';
import { useAutoInvestmentData, withAutoInvestmentData } from '../AutoInvestmentProvider';
import Api from 'api';
import { success } from 'services/toastr';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import ConfirmationModal from 'components/Modal/Modals/ConfirmationModal';

const SectionAutoInvestorInformation: React.FC = () => {
  const { t, tHtml } = useTranslation();

  const { isGlobalActive, isLoading, updateAutoInvestmentData } = useAutoInvestmentData();
  const { showModal } = useGlobalModalContext();

  const onSubmit = useCallback(
    async (
      request: InvestorAutoInvestmentUpdateStatusRequestDto,
      helpers: FormikHelpers<InvestorAutoInvestmentUpdateStatusRequestDto>,
    ) => {
      try {
        showModal(
          <ConfirmationModal
            title={
              request.is_active
                ? tHtml('investments.auto_investment.activate_global_confirm')
                : tHtml('investments.auto_investment.deactivate_global_confirm')
            }
            onClose={() => helpers.setValues({ is_active: isGlobalActive })}
            onSubmit={async () => {
              await Api.investor.investments
                .updateStatusAutoInvestment(request)
                .then((response) => {
                  success(tHtml('common.updated_successfully'));
                  updateAutoInvestmentData(response);
                });
            }}
            backdrop={'static'}
          />,
        );
      } catch (e) {
        helpers.setValues({ is_active: isGlobalActive });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isGlobalActive, tHtml],
  );

  if (isLoading) return null;

  return (
    <div className={'section'} id="auto-invest-strategy-info">
      <h2 className={'section__title'}>
        {tHtml('investments.auto_investment.strategy_information_and_activate')}
      </h2>
      <div className={'panel section__info'}>
        <Row className={'d-flex justify-content-between w-100 align-items-center'}>
          <Col xs={12} sm={6} md={7} className={'section__info-desc'}>
            <p className={'mb-0 text-muted'}>
              <strong>{tHtml('investments.auto_investment.description')}</strong>
            </p>
          </Col>
          <Col xs={12} sm={6} md={5} className={'section__info-input'}>
            <div className={'d-flex justify-content-lg-end justify-content-md-center'}>
              <Formik
                enableReinitialize={true}
                initialValues={{ is_active: isGlobalActive }}
                onSubmit={onSubmit}
              >
                {({ values, isSubmitting, submitForm, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <SwitchInput
                      label={
                        values.is_active
                          ? t('placeholder.auto_investment_enabled')
                          : t('placeholder.auto_investment_disabled')
                      }
                      name={'is_active'}
                      isDisabled={isSubmitting}
                      onChange={submitForm}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withAutoInvestmentData(SectionAutoInvestorInformation);
