import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import { action } from 'typesafe-actions';
import * as Yup from 'yup';
import Api from 'api';
import { PrimaryButton } from 'components/Formik';
import transformErrors from 'helpers/formik';
import { redirect } from 'scopes/guest/helpers/utils';
import Loader from 'components/Loader';
import { ApplicationPageProps } from '../steps';
import { ApplicationContext } from '../module/ApplicationContext';
import { UPDATE_APPLICATION } from '../module/ApplicationReducer';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import useDisplay from 'helpers/useDisplay';
import { ExecutiveTypeEnum } from 'scopes/guest/helpers/enums/ExecutiveTypeEnum';
import SignaturesFormBlock from './SignaturesFormBlock';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import YesNoSelector from 'components/Formik/YesNoSelector';
import { DokobitLanguageEnum } from 'scopes/guest/helpers/enums/DokobitLanguageEnum';

export interface SingleSignatureIForm {
  first_name: string | null;
  last_name: string | null;
  email: string;
  responsibilities: string;
  language: string;
  disable: boolean;
}

interface IForm {
  signatures: SingleSignatureIForm[];
  sign_by_hand: boolean;
}

const ApplicationSignatures: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { isMobile } = useDisplay();
  const { applicationId } = useParams<{ applicationId: string }>();
  const { state, dispatch } = useContext(ApplicationContext);
  const applicationDocument =
    state.application?.prospect_application.generated_prospect_application_document;
  const [initialFormValues, setInitialFormValues] = useState<IForm | null>(null);

  const getLanguage = (company: any) => {
    return company?.country && Object.values(DokobitLanguageEnum).includes(company.country)
      ? company.country
      : DokobitLanguageEnum.EN;
  };

  useEffect(() => {
    if (state.application?.prospect_application.id !== applicationId) return;
    const company = state.application?.prospect_application.company;
    const isExecutive =
      company?.executive_type === t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`);
    setInitialFormValues({
      sign_by_hand: false,
      signatures: isExecutive
        ? [
            {
              first_name: null,
              last_name: null,
              email: company?.executive_email ?? '',
              responsibilities: t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`),
              language: getLanguage(company),
              disable: true,
            },
          ]
        : [
            {
              first_name: null,
              last_name: null,
              email: company?.executive_email ?? '',
              responsibilities: t(`prospect_application.executive.${ExecutiveTypeEnum.DIRECTOR}`),
              language: getLanguage(company),
              disable: true,
            },
            {
              first_name: null,
              last_name: null,
              email: company?.representative_email ?? '',
              responsibilities: company?.executive_type ?? '',
              language: getLanguage(company),
              disable: true,
            },
          ],
    });
  }, [t, applicationId, state]);

  const onSubmit = useCallback(
    async (request: IForm, helpers: FormikHelpers<IForm>) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const signatures = request.signatures.map(({ disable, ...item }) => item);
        await Api.guest.prospect_application
          .storeApplicationCompanySignatures(applicationId, {
            signatures,
            sign_by_hand: request.sign_by_hand,
          })
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  const schema = Yup.object().shape({
    signatures: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          first_name: Yup.string().firstName().nullable(),
          last_name: Yup.string().lastName().nullable(),
          email: Yup.string().email().required(),
          responsibilities: Yup.string().max(255).required(),
        }),
      ),
  });
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <>
      <Row>
        {initialFormValues ? (
          <>
            <h2 className={'mb-5'}>{tHtml('prospect_application.signatures_preview')}</h2>
            <div
              style={{
                maxHeight: isMobile ? 600 : 1000,
              }}
            >
              {applicationDocument && (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl={applicationDocument} plugins={[defaultLayoutPluginInstance]} />
                </Worker>
              )}
            </div>
            <div className={'col-12 col-md-10 col-lg-7.njbl mx-auto'}>
              <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={initialFormValues}
                validationSchema={schema}
                onSubmit={onSubmit}
              >
                {(helpers) => (
                  <Form onSubmit={helpers.handleSubmit}>
                    <FormGroup>
                      <hr />
                      <h5 className="mb-4 mt-4">{tHtml('prospect_application.signatures')}</h5>
                      <SignaturesFormBlock
                        fieldName={'signatures'}
                        values={helpers.values.signatures}
                        formikHelpers={helpers}
                      />
                      <YesNoSelector
                        name={`sign_by_hand`}
                        placeholder={tHtml('prospect_application.sign_by_hand')}
                      />
                    </FormGroup>
                    <hr />
                    <p className="text-muted">{tHtml('prospect_application.sign_warning')}</p>
                    <div className={'d-flex justify-content-between align-items-center'}>
                      <PrimaryButton
                        className={'btn btn-grey w-25'}
                        type={'button'}
                        onClick={() => {
                          redirect(history, previousPage, applicationId);
                        }}
                      >
                        {tHtml('common.back')}
                      </PrimaryButton>
                      <PrimaryButton
                        className={'btn btn-primary w-25'}
                        submitting={helpers.isSubmitting}
                      >
                        {tHtml('common.submit')}
                      </PrimaryButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader />
          </div>
        )}
      </Row>
    </>
  );
};

export default withRouter(ApplicationSignatures);
