import React, { ReactElement } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import { FunctionalButton } from 'components/Button';
import { IconArrowDown, IconArrowUp } from 'components/Icons';
import { CompanyMenuEnum } from 'helpers/enums/CompanyMenuEnum';
import useProfileMenuState from '../pages/Profile/ProfileMenuState';

interface Props {
  name: CompanyMenuEnum;
  title: string | ReactElement;
  buttonLink?: string;
}

const CompanyProfileSection: React.FC<Props> = ({ children, name, title, buttonLink }) => {
  const { tHtml } = useTranslation();

  const { toggleProfileMenu, isOpen } = useProfileMenuState();

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div
          className={'d-flex w-100 justify-content-between align-items-center'}
          onClick={() => toggleProfileMenu(name)}
        >
          <h2 className={'pointer me-2'}>{title}</h2>
          {isOpen(name) ? (
            <IconArrowUp classes={'icon-dropdown'} />
          ) : (
            <IconArrowDown classes={'icon-dropdown'} />
          )}
        </div>
      </div>
      <Collapse isOpen={isOpen(name)}>
        <div className={'mt-3 mt-lg-4'}>
          <div className={'profile-section flex-column-reverse flex-md-row'}>
            <div>{children}</div>
            {buttonLink && (
              <div>
                <Link to={buttonLink}>
                  <FunctionalButton
                    className={'site btn btn-functional edit-button'}
                    icon={'icon-edit'}
                  >
                    {tHtml('common.edit')}
                  </FunctionalButton>
                </Link>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default CompanyProfileSection;
