import { history } from '../index';
import * as H from 'history';
import i18n from 'i18next';
export { default as UUID } from 'helpers/uuid';
export { default as useLocalStorage } from 'helpers/localStorage';

export const passwordRegExp = RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})',
);

export const hasClass = (ele: HTMLElement, cls: string): boolean => {
  const arr = ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
  if (arr && arr.length > 0) return true;
  return false;
};

export const navigate = (route: string | H.Location): void => {
  history.push(route);
};

export const formatMultipleSelectValues = (
  values: string[] | Array<string> | null,
  otherValue: string | null,
): string => {
  return (
    values
      ?.map((item) =>
        item === 'other'
          ? i18n.t(`placeholder.${item}`) + ' (' + otherValue + ')'
          : i18n.t(`placeholder.${item}`),
      )
      .join('; ') ?? ''
  );
};
