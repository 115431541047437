import { ErrorMessage, useField } from 'formik';
import React, { useEffect } from 'react';
import useTranslation from 'helpers/useTranslation';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
}

const ReferralEmailInput: React.FC<Props> = ({ ...props }) => {
  const { t, currentLanguage } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  if (!props.placeholder) {
    props.placeholder = t('placeholder.' + props.name);
  }

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const isError = meta?.touched && !!meta?.error;

  return (
    <div className={'position-relative'}>
      <input
        onChange={field.onChange}
        onBlur={field.onBlur}
        onInput={() => helper.setTouched(true, true)}
        value={field.value}
        id={props.name}
        placeholder={props.placeholder}
        className={`border-0 input-field w-100`}
        style={{
          backgroundColor: 'transparent',
          color: 'var(--profitus-input-color)',
        }}
        {...props}
      />
      {isError && (
        <div className={'error-message'}>
          {props.name && (
            <ErrorMessage
              component={'div'}
              name={props.name}
              render={(msg: string | string[]) => {
                if (typeof msg == 'string') {
                  return msg;
                }

                return msg.join('\n');
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default ReferralEmailInput;
