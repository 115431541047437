import React from 'react';
import { Col, ColProps, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from './SecondaryMarketInvestmentProvider';
import DataBlockStacked from 'scopes/investor/pages/SecondaryMarket/components/DataBlockStacked';
import { ProjectHelper } from 'helpers/project';

type Props = SecondaryMarketInvestmentContextState;

const ProjectInformation: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  const { tHtml } = useTranslation();

  if (!secondaryMarketInvestment) return null;

  const colProps: ColProps = {
    lg: 3,
    md: 6,
    sm: 6,
    xs: 12,
  };

  return (
    <>
      <div className={'mb-4'}>
        <h2>{tHtml('secondary_market.project.more_project_information')}</h2>
      </div>
      <section>
        <Row>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.required_amount')}
              content={tHtml('common.money', {
                value: secondaryMarketInvestment.more_information?.required_amount,
              })}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.interest_rate')}
              content={
                secondaryMarketInvestment.more_information?.basic_interest
                  ? ProjectHelper.formatInterestRate(
                      secondaryMarketInvestment.more_information?.basic_interest,
                      secondaryMarketInvestment.more_information?.increased_interest,
                    )
                  : '-'
              }
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.initial_rating')}
              content={secondaryMarketInvestment.more_information?.initial_rating}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={secondaryMarketInvestment.loan_ratio}
              content={
                secondaryMarketInvestment.more_information?.loan_ratio_external
                  ? ProjectHelper.formatLoanRatio(
                      secondaryMarketInvestment.more_information?.loan_ratio_external,
                      secondaryMarketInvestment.more_information?.loan_ratio_max,
                    )
                  : '-'
              }
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.credit_duration')}
              content={tHtml(`common.month`, {
                value: secondaryMarketInvestment?.more_information?.credit_duration,
              })}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.interest_frequency')}
              content={tHtml(
                `placeholder.interest_frequency.${secondaryMarketInvestment.more_information?.interest_frequency}`,
              )}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.security_measures')}
              content={
                secondaryMarketInvestment.more_information?.security_measures
                  ? tHtml(
                      `placeholder.security_measures.${secondaryMarketInvestment.more_information?.security_measures}`,
                    )
                  : '-'
              }
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.investment_purpose')}
              content={
                secondaryMarketInvestment.more_information?.investment_purpose
                  ? tHtml(
                      'projects.investment_purposes.' +
                        secondaryMarketInvestment.more_information?.investment_purpose,
                    )
                  : '-'
              }
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default withSecondaryMarketInvestment(ProjectInformation);
