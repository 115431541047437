import React, { useEffect, useState } from 'react';
import {
  Cell as RechartsCell,
  Pie as RechartsPie,
  PieChart as RechartsPieChart,
  ResponsiveContainer as RechartsResponsiveContainer,
  Tooltip as RechartsTooltip,
} from 'recharts';

export interface PieChartDataEntry {
  name: string;
  value: number;
}

export interface PieChartProps {
  data: PieChartDataEntry[];
  onChange?: (index: number) => void;
  indexState?: {
    index: number;
    setIndex: React.Dispatch<React.SetStateAction<number>>;
  };
  height?: number;
}

const PieChart: React.FC<PieChartProps> = ({ data, onChange, indexState, height = 275 }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(
    Number.isFinite(indexState?.index) ? (indexState?.index as number) : -1,
  );

  useEffect(() => {
    if (onChange) onChange(currentIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  useEffect(() => {
    if (Number.isFinite(indexState?.index)) {
      setCurrentIndex(indexState?.index as number);
    }
  }, [indexState?.index]);

  const handleOnClick = (data: any, index: number) => {
    if (index != currentIndex) {
      setCurrentIndex(index);
      indexState?.setIndex(index);
    } else {
      setCurrentIndex(-1);
      indexState?.setIndex(-1);
    }
  };

  const Label = (props: any) => {
    const radius = props.innerRadius + (props.outerRadius - props.innerRadius) * 0.5;

    const radian = Math.PI / 180;
    const x = props.cx + radius * Math.cos(-props.midAngle * radian);
    const y = props.cy + radius * Math.sin(-props.midAngle * radian);

    const isActive = currentIndex == props.index;
    const value = parseFloat((props.percent * 100).toFixed(2));

    const getText = () => {
      return `${value}%`;
    };

    if (value < 10) return null;

    return (
      <text x={x} y={y} className={isActive ? 'label active' : 'label'} dominantBaseline="central">
        {getText()}
      </text>
    );
  };

  const Cell = (index: number) => {
    const isActive = index == currentIndex;

    return <RechartsCell key={`cell-${index}`} className={isActive ? 'cell active' : 'cell'} />;
  };

  const Tooltip = (props: any) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <div className="custom-tooltip">
          <div>
            {props.payload[0].name}: <span className="value">{props.payload[0].value}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div style={{ width: '100%', height: height }}>
        <RechartsResponsiveContainer className={'pie-chart'}>
          <RechartsPieChart>
            <RechartsPie
              data={data}
              innerRadius={70}
              labelLine={false}
              paddingAngle={1}
              label={Label}
              isAnimationActive={false}
              dataKey="value"
              onMouseEnter={handleOnClick}
            >
              {data.map((entry, index) => Cell(index))}
            </RechartsPie>
            <RechartsTooltip content={Tooltip} />
          </RechartsPieChart>
        </RechartsResponsiveContainer>
      </div>
    </>
  );
};

export default PieChart;
