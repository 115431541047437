import Client from '../client';
import Http from '../../http';
import {
  BasicCompanyInfoDto,
  CompanyDetailsDto,
  CompanyExecutiveDto,
  InquireApplicationsRequestDto,
  ProspectApplicationDetailsDto,
  ProspectApplicationDto,
  ProspectApplicationSecurityDepositsDto,
  ProspectApplicationSignaturesDto,
  ProspectUserCompanyDocumentsDto,
  ProspectUserCompanyExperienceResponseDto,
  StoreProspectApplicationRequestDto,
} from '../../types/guest/prospectApplication';
import { SignUploadRequestDto, SignUploadWithCollectionRequestDto } from 'api/types/app';
import { UserBriefResponseDto } from 'scopes/guest/pages/ProspectApplication/CreateApplication';

class ProspectApplication extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      INQUIRE_APPLICATIONS: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/send-prospect-application-list`,
      ),
      FETCH_APPLICATION: this.protectedUrl(`${this.http.baseUrl}/prospect-application/{uuid}`),
      FETCH_SALES_MANAGERS: this.protectedUrl(`${this.http.baseUrl}/prospect-application/managers`),
      STORE_APPLICATION: this.protectedUrl(`${this.http.baseUrl}/prospect-application`),
      UPDATE_APPLICATION: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/update`,
      ),
      STORE_APPLICATION_DETAILS: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/application-details`,
      ),
      UPLOAD_ASSESSMENT_DOCUMENT: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/prospect-application-documents`,
      ),
      UPLOAD_COMPANY_DOCUMENT: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/prospect-company-documents`,
      ),
      UPLOAD_PROSPECT_APPLICATION_DOCUMENT: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/documents`,
      ),
      STORE_APPLICATION_SECURITY_DEPOSITS: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/security-deposit`,
      ),
      STORE_APPLICATION_BASIC_COMPANY_INFO: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/company`,
      ),
      STORE_APPLICATION_COMPANY_DETAILS: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/company-details`,
      ),
      STORE_APPLICATION_COMPANY_BENEFICIARIES: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/beneficiaries`,
      ),
      STORE_APPLICATION_COMPANY_EXECUTIVE: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/executive`,
      ),
      STORE_APPLICATION_COMPANY_DOCUMENTS: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/company-documents`,
      ),
      STORE_APPLICATION_COMPANY_EXPERIENCE: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/experience`,
      ),
      STORE_APPLICATION_SIGNATURES: this.protectedUrl(
        `${this.http.baseUrl}/prospect-application/{applicationId}/signatures`,
      ),
      FETCH_PROJECT_RISKS: this.protectedUrl(`${this.http.baseUrl}/prospect-application/risks`),
    };
  }

  fetchProjectRisks = (): Promise<string[]> => {
    return this.http.get(this.api.FETCH_PROJECT_RISKS);
  };

  inquireApplications = (data: InquireApplicationsRequestDto): Promise<any> => {
    return this.http.post(this.api.INQUIRE_APPLICATIONS, data);
  };

  fetchApplication = (uuid: string): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.FETCH_APPLICATION, { uuid });
    return this.http.get(url);
  };

  storeApplication = (
    data: StoreProspectApplicationRequestDto,
  ): Promise<ProspectApplicationDto> => {
    return this.http.post(this.api.STORE_APPLICATION, data);
  };

  fetchSalesManagers = (): Promise<Array<UserBriefResponseDto>> => {
    return this.http.get(this.api.FETCH_SALES_MANAGERS);
  };

  updateApplication = (
    applicationId: number | string,
    data: StoreProspectApplicationRequestDto,
  ): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.UPDATE_APPLICATION, { applicationId });
    return this.http.put(url, data);
  };

  storeApplicationCreditDetails = (
    applicationId: number | string,
    data: ProspectApplicationDetailsDto,
  ): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_DETAILS, { applicationId });
    return this.http.put(url, data);
  };

  uploadAssessmentDocuments = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.post(this.api.UPLOAD_ASSESSMENT_DOCUMENT, request);
  };

  uploadCompanyDocuments = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.post(this.api.UPLOAD_COMPANY_DOCUMENT, request);
  };

  uploadProspectApplicationDocuments = (
    request: SignUploadWithCollectionRequestDto,
  ): Promise<any> => {
    return this.http.post(this.api.UPLOAD_PROSPECT_APPLICATION_DOCUMENT, request);
  };

  storeApplicationSecurityDeposits = (
    applicationId: number | string,
    data: ProspectApplicationSecurityDepositsDto,
  ): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_SECURITY_DEPOSITS, { applicationId });
    return this.http.post(url, data);
  };

  storeApplicationBasicCompanyInfo = (
    applicationId: number | string,
    data: BasicCompanyInfoDto,
  ): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_BASIC_COMPANY_INFO, { applicationId });
    return this.http.post(url, data);
  };

  storeApplicationCompanyDetails = (
    applicationId: number | string,
    data: CompanyDetailsDto,
  ): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_COMPANY_DETAILS, { applicationId });
    return this.http.post(url, data);
  };

  storeApplicationCompanyBeneficiariesDetails = (
    applicationId: number | string,
    data: CompanyDetailsDto,
  ): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_COMPANY_BENEFICIARIES, { applicationId });
    return this.http.post(url, data);
  };

  storeApplicationCompanyExecutive = (
    applicationId: number | string,
    data: CompanyExecutiveDto,
  ): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_COMPANY_EXECUTIVE, { applicationId });
    return this.http.post(url, data);
  };

  storeApplicationCompanyDocuments = (
    applicationId: number | string,
    data: ProspectUserCompanyDocumentsDto,
  ): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_COMPANY_DOCUMENTS, { applicationId });
    return this.http.post(url, data);
  };

  storeApplicationCompanyExperience = (
    applicationId: number | string,
    data: ProspectUserCompanyExperienceResponseDto,
  ): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_COMPANY_EXPERIENCE, { applicationId });
    return this.http.post(url, data);
  };

  storeApplicationCompanySignatures = (
    applicationId: number | string,
    data: { signatures: ProspectApplicationSignaturesDto[]; sign_by_hand: boolean },
  ): Promise<ProspectApplicationDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_SIGNATURES, { applicationId });
    return this.http.post(url, data);
  };
}

export default ProspectApplication;
