import React, { useEffect } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import Api from 'api';
import { setGlobalLoading } from 'modules/app/actions';
import { success } from 'services/toastr';
import { useAuth } from 'services/useAuth';

const Impersonate: React.FC<RouteComponentProps> = () => {
  const { t, tHtml } = useTranslation();
  const { hash } = useParams<{ hash: string }>();
  const { login } = useAuth();

  useEffect(() => {
    setGlobalLoading(true);

    Api.guest.auth
      .impersonate({ hash: hash })
      .then(async (response) => {
        await login(response.access_token, response.permissions);
        success(tHtml('authentication.impersonated_successfully'));
      })
      .finally(() => {
        setGlobalLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, t, tHtml]);

  return <React.Fragment />;
};

export default withRouter(Impersonate);
