import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import { openMedia } from 'services/media';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  url: string;
}

const DownloadButtonAuthorized: React.FC<Props> = ({
  url,
  className,
  type,
  children,
  ...props
}) => {
  const { tHtml } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [generatedUrl, setGeneratedUrl] = useState<string>();

  useEffect(() => {
    setGeneratedUrl(undefined);
  }, [url]);

  return (
    <>
      {generatedUrl && (
        <a
          target={'_blank'}
          download
          className={className ?? 'btn btn-borderless p-0'}
          href={generatedUrl}
          rel="noreferrer"
        >
          {children ?? tHtml('common.download')}
        </a>
      )}
      {!generatedUrl && (
        <button
          className={className ?? 'btn btn-borderless p-0'}
          type={type ?? 'button'}
          disabled={disabled}
          onClick={() => {
            setDisabled(true);

            openMedia(url)
              .then((r) => setGeneratedUrl(r.url))
              .finally(() => {
                setDisabled(false);
              });
          }}
          {...props}
        >
          {children ?? tHtml('common.download')}
        </button>
      )}
    </>
  );
};

export default DownloadButtonAuthorized;
