import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import { GuestPageLayout } from 'scopes/guest/containers';
import * as Roles from 'helpers/auth/roles';
import Home from '.';

const HomeRoutes: any = () => [
  <Route exact path={RouteList.DASHBOARD} key={RouteList.DASHBOARD}>
    <Redirect to={RouteList.HOME} />
  </Route>,
  <AuthRoute
    path={RouteList.HOME}
    component={Home}
    layout={GuestPageLayout}
    permissions={Roles.All.permissions}
    key={RouteList.HOME}
    exact
  />,
];

export default HomeRoutes;
