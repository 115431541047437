export const EVALUATORS: { [key: string]: string[] } = {
  lt: [
    'AS&Partners, UAB',
    'Butas tau, UAB',
    'Centro kubas-nekilnojamasis turtas, UAB',
    'UAB Colliers International Advisors',
    'Domus-Optima, UAB',
    'Inreal, UAB',
    'Jungtinis verslas, UAB',
    'Korporacija matininkai, UAB',
    'Latmas, UAB',
    'Lituka ir Ko, UAB',
    'MARLEKSA, UAB',
    'Newsec valuations, UAB',
    'UAB „OBER-HAUS“ nekilnojamas turtas',
    'Turto spektras, UAB',
  ],
  lv: [
    '1 Partner',
    'Arco Real Estate',
    'CBRE',
    'Colliers International Advisor',
    'Eiroeksperts',
    'InterBaltija',
    'Kivi real estate',
    'Latio',
    'Nira Fonds',
    'NEXTRE Global',
    'Newsec Valuations LV',
    'Ober Haus Valuations LV',
    'RE eksperts',
    'VCG Eksper',
  ],
  ee: [
    '1Partner Kinnisvara',
    'Arco Vara',
    'Colliers International',
    'Domus Kinnisvara',
    'Eri Kinnisvara OÜ',
    'Kaanon Kinnisvarabüroo OÜ',
    'Kinnisvaraekspert',
    'Lahe Kinnisvara',
    'LVM Kinnisvara',
    'Newsec Valuations EE',
    'Ober-Haus',
    'Pindi Kinnisvara',
    'RE Kinnisvara AS',
    'Uus Maa Kinnisvarabüroo',
  ],
};
