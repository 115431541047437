import React, { ReactElement, useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { UseNumbers } from 'helpers/useNumbers';
import { handleDecimalsTensOnValue } from 'helpers/utils';

interface Props {
  label: string | ReactElement;
  name: string;
  value: string | number;
  onChange: (date: number) => void;
  isHighlight?: boolean;
  isError?: boolean;
  max?: number;
}

const defaultProps: Props = {
  label: '',
  name: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
  value: 0,
  isHighlight: false,
  isError: false,
  max: undefined,
};

const NumberInput: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string | number>(props.value);

  useEffect(() => {
    const newDate = props.value;
    if (newDate === value) return;

    setValue(newDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  useEffect(() => {
    props.onChange(Number(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSetValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;

    if (props?.max === undefined || Number(newValue) <= props.max) {
      setValue(handleDecimalsTensOnValue(newValue));
    }
  };

  const getClassName = (isHighlight: boolean, isError: boolean): string => {
    let baseStyle = '';

    baseStyle = isHighlight
      ? baseStyle.concat(' site-input highlight')
      : baseStyle.concat(' site-input');
    baseStyle = isError ? baseStyle.concat(' is-invalid') : baseStyle;

    return baseStyle;
  };

  return (
    <div className={getClassName(!!props.isHighlight, !!props.isError)}>
      <Label className={'label'} for={props.name}>
        {props.label}
      </Label>
      <input
        name={props.name}
        step={0.01}
        id={props.name}
        onClick={(e) => e.preventDefault()}
        value={value.toString()}
        onChange={onSetValue}
        className={`form-control ${props.isError ? 'is-invalid' : ''}`}
        onKeyPress={UseNumbers.preventNonNumericalInput}
        onWheel={(e) => e.currentTarget.blur()} /* Disabling number change on scroll */
      />
    </div>
  );
};

NumberInput.defaultProps = defaultProps;
export default NumberInput;
