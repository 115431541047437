import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteList } from 'routes';
import ProfileRoutes from './pages/Profile/ProfileRoutes';
import EditProfileRoutes from './pages/EditProfile/EditProfileRoutes';

const CompanyRoutes: any = () => [
  <Route exact path={RouteList.HOME} key={RouteList.HOME}>
    <Redirect to={RouteList.DASHBOARD} />
  </Route>,
  ...ProfileRoutes(),
  ...EditProfileRoutes(),
];

export default CompanyRoutes;
