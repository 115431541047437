import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import { DataBlock } from 'components/DataBlock';
import useOnboarding from 'helpers/useOnboarding';
import CountryLabel from 'components/Labels/CountryLabel';
import NotFilledProfileSection from 'components/Profile/NotFilledProfileSection';
import UserProfileSection from 'scopes/user/components/UserProfileSection';
import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';

const IdVerification: React.FC = () => {
  const { tHtml } = useTranslation();

  const { data: userProfileData } = useUserProfileQuery();

  const { isAccredited } = useOnboarding();

  if (!isAccredited) {
    return (
      <NotFilledProfileSection
        title={tHtml('menu.id_verification')}
        buttonLink={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}
        description={tHtml('profile.id_verification.please_verify_id')}
        buttonText={tHtml('profile.id_verification.id_verification')}
      />
    );
  }

  if (userProfileData?.id_verification) {
    return (
      <UserProfileSection menu={UserMenuEnum.IdVerification} title={tHtml('menu.id_verification')}>
        <div className={'content'}>
          <DataBlock
            title={tHtml('placeholder.first_name')}
            data={userProfileData.id_verification.first_name ?? ''}
          />
          {userProfileData.id_verification.middle_name && (
            <DataBlock
              title={tHtml('placeholder.middle_name')}
              data={userProfileData.id_verification.middle_name}
            />
          )}
          <DataBlock
            title={tHtml('placeholder.last_name')}
            data={userProfileData.id_verification.last_name ?? ''}
          />
          <DataBlock
            title={tHtml('placeholder.gov_code')}
            data={userProfileData.id_verification.gov_code ?? ''}
          />
          <DataBlock
            title={tHtml('placeholder.date_of_birth')}
            data={
              userProfileData.id_verification.dob
                ? tHtml('common.date', { date: userProfileData.id_verification.dob })
                : ''
            }
          />
          <DataBlock
            title={tHtml('placeholder.gender')}
            data={
              userProfileData.id_verification.gender
                ? tHtml(`common.${userProfileData.id_verification.gender}`)
                : ''
            }
          />
          <DataBlock
            title={tHtml('placeholder.nationality')}
            data={
              userProfileData.id_verification.nationality ? (
                <CountryLabel code={userProfileData.id_verification.nationality} />
              ) : (
                ''
              )
            }
          />
          <DataBlock
            title={tHtml('placeholder.residence')}
            data={
              userProfileData.id_verification.residence ? (
                <CountryLabel code={userProfileData.id_verification.residence} />
              ) : (
                ''
              )
            }
          />
          <DataBlock
            title={tHtml('placeholder.document_number')}
            data={userProfileData.id_verification.document_number ?? ''}
          />
          <DataBlock
            title={tHtml('placeholder.document_type')}
            data={
              userProfileData.id_verification.document_type
                ? tHtml(`placeholder.gov.document.${userProfileData.id_verification.document_type}`)
                : ''
            }
          />
          <DataBlock
            title={tHtml('placeholder.document_doi')}
            data={
              userProfileData.id_verification.document_doi
                ? tHtml('common.date', { date: userProfileData.id_verification.document_doi })
                : ''
            }
          />
          <DataBlock
            title={tHtml('placeholder.document_doe')}
            data={
              userProfileData.id_verification.document_doe
                ? tHtml('common.date', { date: userProfileData.id_verification.document_doe })
                : ''
            }
          />
        </div>
      </UserProfileSection>
    );
  }

  return <></>;
};

export default withRouter(IdVerification);
