import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import OneSignal from 'react-onesignal';

import Api from 'api';
import DashboardSkeleton from './DashboardSkeleton';
import SkeletonLoader from 'components/SkeletonLoader';
import TopUpWithdraw from './components/TopUpWithdraw';
import InterestReceived from './components/InterestReceived';
import ActiveInvestments from './components/ActiveInvestments';
import TopInvestor from './components/TopInvestor';
import PaymentsOverview from './components/Payments/PaymentsOverview';
import AvgAnnualInterest from './components/AvgAnnualInterest';
import PortfolioStatistics from './components/PortfolioStatistics';
import { useStatisticQuery } from 'api/queries/dashboard';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';
import UserRequiredStepsModal from '../../modals/RequiredSteps/UserRequiredStepsModal';
import InvestorRequiredStepsModal from '../../modals/RequiredSteps/InvestorRequiredStepsModal';
import { useStepsModalStateStore } from '../../modals/RequiredSteps/store';
import { useAccountQuery } from 'api/queries';
import { default as env } from 'env.json';
import useDisplay from 'helpers/useDisplay';
import InvestorHasTransferableInvestmentsModal from '../../modals/transferableInvestments/InvestorHasTransferableInvestmentsModal';
import { useLocalStorage } from 'helpers';
import { InvestorProjectsWithTransferableInvestmentsResponse } from 'api/types/investor/project';

const Dashboard: React.FC = () => {
  const { showModal } = useGlobalModalContext();
  const { data: userProfileData } = useUserProfileQuery();
  const { data: userCompanyProfileData } = useUserCompanyProfileQuery();
  const { data: userAccount } = useAccountQuery();
  const { data: topInvestor } = useStatisticQuery(
    ['topInvestor'],
    Api.investor.dashboard.fetchTopInvestor,
  );
  const { isApp } = useDisplay();
  const modalState = useStepsModalStateStore();
  const [
    localStorageProjectsWithTransferableInvestments,
    setLocalStorageProjectsWithTransferableInvestments,
  ] = useLocalStorage<InvestorProjectsWithTransferableInvestmentsResponse[]>(
    'projects_with_transferable_investments',
    [],
  );

  async function runOneSignal(id: string) {
    await OneSignal.init({
      appId: env.ONESIGNAL_APP_ID,
      allowLocalhostAsSecureOrigin: true,
    });

    await OneSignal.login(id);
    await OneSignal.Slidedown.promptPush();
  }

  useEffect(() => {
    if (
      env.ONESIGNAL_ENABLED === 'true' &&
      userProfileData?.user?.id &&
      userProfileData?.user?.id === '217805518518009438' &&
      isApp
    ) {
      runOneSignal(userProfileData?.user?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData]);

  useEffect(() => {
    if (
      modalState.shouldBeShown &&
      userAccount &&
      userProfileData &&
      userProfileData.user &&
      (!userProfileData.user.verified ||
        !userProfileData.user.has_investor_quiz_filled ||
        userAccount.is_document_expired == true ||
        userAccount.is_investor_quiz_expired == true ||
        userAccount.is_contact_info_expired == true ||
        userAccount.is_investor_kyc_expired == true ||
        userProfileData.know_your_customer == null)
    ) {
      const delay = 2000;
      const timeoutId = setTimeout(() => {
        showModal(<UserRequiredStepsModal />);
      }, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData]);

  const showInvestorHasTransferableInvestmentsModal = useCallback(
    (data: InvestorProjectsWithTransferableInvestmentsResponse) => {
      if (data.refinancing_project_id === null) return;
      const delay = 2000;
      const timeoutId = setTimeout(() => {
        showModal(
          <InvestorHasTransferableInvestmentsModal
            project_id={data.project_id}
            project_pid={data.project_pid}
            project_name={data.project_name}
            refinancing_project_pid={data.refinancing_project_pid}
            refinancing_project_id={data.refinancing_project_id}
            refinancing_project_name={data.refinancing_project_name}
            investments={data.investments}
          />,
        );
      }, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    },
    [showModal],
  );

  const fetchTransferableProjects = useCallback(
    (projects) => {
      if (!projects.length) return;

      if (
        localStorageProjectsWithTransferableInvestments &&
        localStorageProjectsWithTransferableInvestments.length > 0
      ) {
        const project = projects.find(
          (project: InvestorProjectsWithTransferableInvestmentsResponse) =>
            !localStorageProjectsWithTransferableInvestments.some(
              (x) => x.project_id === project.project_id,
            ),
        );

        if (project) {
          setLocalStorageProjectsWithTransferableInvestments([
            ...localStorageProjectsWithTransferableInvestments,
            project,
          ]);
          showInvestorHasTransferableInvestmentsModal(project);
        }
      } else {
        setLocalStorageProjectsWithTransferableInvestments([projects[0]]);
        showInvestorHasTransferableInvestmentsModal(projects[0]);
      }
    },
    [
      localStorageProjectsWithTransferableInvestments,
      setLocalStorageProjectsWithTransferableInvestments,
      showInvestorHasTransferableInvestmentsModal,
    ],
  );

  useEffect(() => {
    if (userAccount?.has_transferable_investments && userAccount?.transferable_projects) {
      fetchTransferableProjects(userAccount?.transferable_projects);
    }
  }, [fetchTransferableProjects, userAccount]);

  useEffect(() => {
    if (
      modalState.shouldBeShown &&
      userAccount &&
      userCompanyProfileData &&
      userCompanyProfileData.company &&
      (userAccount.main_user_verified != true ||
        !userCompanyProfileData.company.has_investor_quiz_filled ||
        userAccount.is_document_expired == true ||
        userAccount.is_investor_quiz_expired == true ||
        userAccount.is_investor_kyb_expired == true ||
        userCompanyProfileData.know_your_business == null)
    ) {
      const delay = 2000;
      const timeoutId = setTimeout(() => {
        showModal(<InvestorRequiredStepsModal />);
      }, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCompanyProfileData]);
  return (
    <div className={'investor-dashboard'}>
      <SkeletonLoader loading={false} customPlaceholder={<DashboardSkeleton />}>
        <div className={'page-layout wallet'}>
          <div>
            <Row className={'justify-content-center mb-4'}>
              <Col xs={5} xl={4}>
                <AvgAnnualInterest />
              </Col>
              <Col xs={7} xl={8}>
                <TopUpWithdraw />
              </Col>
            </Row>
            <Row className={'justify-content-center'}>
              <Col xs={12} md={5} xl={4} className={'mb-4'}>
                <InterestReceived />
              </Col>
              <Col xs={12} md={7} xl={8} className={'mb-4'}>
                <ActiveInvestments />
              </Col>
            </Row>
          </div>
        </div>
        {topInvestor && topInvestor.place > 0 && (
          <div className={'mb-4'}>
            <TopInvestor place={topInvestor.place} investors={topInvestor.investors} />
          </div>
        )}
        <div className={'mb-6'}>
          <PaymentsOverview />
        </div>
        <PortfolioStatistics />
      </SkeletonLoader>
    </div>
  );
};

export default Dashboard;
