import React from 'react';
import { withRouter } from 'react-router-dom';

import UpcomingPayments from './components/UpcomingPayments/UpcomingPayments';
import { StyledPayments } from './Payments.style';
import Invoices from './components/Invoice/Invoices';
import PartialReturnSection from './components/PartialReturnSection/PartialReturnSection';

const Payments: React.FC = () => {
  return (
    <StyledPayments>
      <UpcomingPayments />
      <Invoices />
      <PartialReturnSection />
    </StyledPayments>
  );
};

export default withRouter(Payments);
