import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { RouteList } from 'routes';
import PageLayout from 'scopes/investor/containers/PageLayout';
import { HighlightButton } from 'components/Button';
import Sidebar from './Sidebar';
import EditProfileLayoutSkeleton from './EditProfileLayoutSkeleton';
import useTranslation from 'helpers/useTranslation';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';

const EditProfileLayout: React.FC = ({ children }) => {
  const { isFetching } = useUserProfileQuery();
  const { tHtml } = useTranslation();

  return (
    <React.Fragment>
      <PageLayout isProfileLayout={true}>
        <div className={'edit-profile-layout'}>
          <Container className={'g-0'}>
            {isFetching ? (
              <EditProfileLayoutSkeleton />
            ) : (
              <Row>
                <Col className={'sidebar d-none d-lg-flex'} xs={0} lg={4}>
                  <Sidebar />
                </Col>
                <Col className={'content d-flex justify-content-center'} xs={12} lg={8}>
                  <div className={'close'}>
                    <Link to={RouteList.USER.PROFILE.HOME}>
                      <HighlightButton>{tHtml('common.back')}</HighlightButton>
                    </Link>
                  </div>
                  <div className={'data'}>{children}</div>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </PageLayout>
    </React.Fragment>
  );
};

export default withRouter(EditProfileLayout);
