import Client from '../client';
import Http from '../../http';
import { SignUploadRequestDto } from '../../types/app';
import {
  ApplicationListResponseDto,
  ApplicationPurchaseResponseDto,
  ApplicationRefinancingResponseDto,
  ApplicationResponseDto,
  ProjectDeveloperApplicationProjectProposal,
  StoreApplicationDevelopmentRequestDto,
  StoreApplicationDocumentsRequestDto,
  StoreApplicationEstimateRequestDto,
  StoreApplicationPurchaseRequestDto,
  StoreApplicationRefinancingRequestDto,
  StoreApplicationRequestDto,
  StoreApplicationSecurityDepositsRequestDto,
} from '../../types/project-developer/application';
import { ProjectDeveloperOpenProjectsResponse } from '../../types/project-developer/project';
import { PaginationData, PaginationDataFilter } from '../../types';

class ProjectDeveloperApplication extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FILTER_APPLICATION_LIST: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/filter`,
      ),
      FETCH_APPLICATION: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}`,
      ),
      STORE_APPLICATION: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application`,
      ),
      UPDATE_APPLICATION: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}`,
      ),
      STORE_APPLICATION_REFINANCING: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/refinancing`,
      ),
      STORE_APPLICATION_PURCHASE: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/purchase`,
      ),
      STORE_APPLICATION_DEVELOPMENT: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/development`,
      ),
      STORE_APPLICATION_ESTIMATE: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/estimate`,
      ),
      STORE_APPLICATION_SECURITY_DEPOSITS: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/security-deposits`,
      ),
      STORE_APPLICATION_DOCUMENTS: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/documents`,
      ),

      FETCH_APPLICATION_PROJECT_PROPOSAL: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/proposal`,
      ),
      REJECT_APPLICATION_PROJECT_PROPOSAL: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/proposal/reject`,
      ),
      ACCEPT_APPLICATION_PROJECT_PROPOSAL_OFFER: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/proposal/accept`,
      ),

      UPLOAD_APPLICATION_DOCUMENTS: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/upload/documents`,
      ),
      UPLOAD_APPLICATION_PICTURES: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/upload/pictures`,
      ),
      MARK_APPLICATION_AS_SUBMITTED: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/applications/application/{applicationId}/submit`,
      ),
    };
  }

  filterApplicationList = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ApplicationListResponseDto>> => {
    return this.http.get(this.api.FILTER_APPLICATION_LIST, { params: request });
  };

  fetchApplicationProjectProposal = (
    applicationId: string | number,
  ): Promise<ProjectDeveloperApplicationProjectProposal> => {
    const url = this.buildUrl(this.api.FETCH_APPLICATION_PROJECT_PROPOSAL, { applicationId });
    return this.http.get(url);
  };

  rejectApplicationProjectProposal = (applicationId: string | number): Promise<any> => {
    const url = this.buildUrl(this.api.REJECT_APPLICATION_PROJECT_PROPOSAL, { applicationId });
    return this.http.post(url);
  };

  acceptApplicationProjectProposal = (
    applicationId: string | number,
  ): Promise<ProjectDeveloperOpenProjectsResponse> => {
    const url = this.buildUrl(this.api.ACCEPT_APPLICATION_PROJECT_PROPOSAL_OFFER, {
      applicationId,
    });
    return this.http.post(url);
  };

  fetchCurrentApplication = (applicationId: number | string): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_APPLICATION, { applicationId });
    return this.http.get(url);
  };

  storeApplication = (data: StoreApplicationRequestDto): Promise<ApplicationResponseDto> => {
    return this.http.post(this.api.STORE_APPLICATION, data);
  };

  updateApplication = (
    applicationId: number | string,
    data: StoreApplicationRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_APPLICATION, { applicationId });
    return this.http.put(url, data);
  };

  markApplicationAsSubmitted = (
    applicationId: number | string,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.MARK_APPLICATION_AS_SUBMITTED, { applicationId });
    return this.http.put(url);
  };

  storeApplicationRefinancing = (
    applicationId: number | string,
    data: StoreApplicationRefinancingRequestDto,
  ): Promise<ApplicationRefinancingResponseDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_REFINANCING, { applicationId });
    return this.http.put(url, data);
  };

  storeApplicationPurchase = (
    applicationId: number | string,
    data: StoreApplicationPurchaseRequestDto,
  ): Promise<ApplicationPurchaseResponseDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_PURCHASE, { applicationId });
    return this.http.put(url, data);
  };

  updateApplicationDevelopment = (
    applicationId: number | string,
    data: StoreApplicationDevelopmentRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_APPLICATION, { applicationId });
    return this.http.put(url, data);
  };

  storeApplicationDevelopment = (
    applicationId: number | string,
    data: StoreApplicationDevelopmentRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_DEVELOPMENT, { applicationId });
    return this.http.put(url, data);
  };

  storeApplicationDocuments = (
    applicationId: number | string,
    data: StoreApplicationDocumentsRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_DOCUMENTS, { applicationId });
    return this.http.put(url, data);
  };

  storeApplicationEstimate = (
    applicationId: number | string,
    data: StoreApplicationEstimateRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_ESTIMATE, { applicationId });
    return this.http.put(url, data);
  };

  storeApplicationSecurityDeposits = (
    applicationId: number | string,
    data: StoreApplicationSecurityDepositsRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_SECURITY_DEPOSITS, { applicationId });
    return this.http.put(url, data);
  };

  uploadDocuments = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.post(this.api.UPLOAD_APPLICATION_DOCUMENTS, request);
  };

  uploadPictures = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.post(this.api.UPLOAD_APPLICATION_PICTURES, request);
  };
}

export default ProjectDeveloperApplication;
