import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import { NumberInput } from 'components/Inputs';
import CancelButton from './Buttons/CancelButton';
import Button from './Buttons/Button';
import AdvanceFilter, { AdvanceFilterProps } from './AdvanceFilter';
import SliderWithRange from '../../SliderWithRange';
import { warning } from 'services/toastr';

const AmountFilter: React.FC<AdvanceFilterProps> = (props) => {
  const { t, tHtml } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');

  const [startRange] = useState<number>(props.start!);
  const [endRange] = useState<number>(props.end!);

  const [selectedValues, setSelectedValues] = useState<[number, number]>([startRange!, endRange!]);

  useEffect(() => {
    if (title != '') {
      setTitle(getAmountRange());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  const getAmountRange = (): string => {
    if (isNumber(selectedValues[0]) && isNumber(selectedValues[1]) && endRange) {
      return `${t('common.money', { value: selectedValues[0] })} - ${t('common.money', {
        value: selectedValues[1],
      })}`;
    }

    return '';
  };

  const isNumber = (value: any) => {
    return typeof value === 'number' && !isNaN(value);
  };

  const clearValues = () => {
    setSelectedValues([startRange, endRange]);
    setTitle('');
    props.column.setFilter({ min: null, max: null });
  };

  const onCancelButtonClicked = () => {
    clearValues();
  };

  const areValuesValid = (): boolean => {
    if (props.ignoreLimits) {
      return isCorrectMinAndMaxValues(selectedValues);
    }

    return (
      isBetweenMinAndMax(selectedValues[0]) &&
      isBetweenMinAndMax(selectedValues[1]) &&
      isCorrectMinAndMaxValues(selectedValues)
    );
  };

  const onSaveButtonClicked = () => {
    setIsOpen(false);

    if (areValuesValid()) {
      setTitle(getAmountRange());
      props.column.setFilter({ min: selectedValues[0], max: selectedValues[1] });
    } else {
      warning(tHtml('validation.between_amount'));
      clearValues();
    }
  };

  const isBetweenMinAndMax = (value: number): boolean => {
    return startRange <= value && value <= endRange;
  };

  const isCorrectMinAndMaxValues = (values: [number, number]): boolean => {
    return values[0] <= values[1];
  };

  return (
    <AdvanceFilter icon={'icon-euro'} title={title} isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={'title'}>{tHtml('table.filters.amount')}</div>

      <SliderWithRange
        min={startRange}
        max={endRange}
        values={selectedValues}
        onChange={(values) => {
          setSelectedValues(values);
        }}
      />
      <div className={'action-options-inputs'}>
        <NumberInput
          label={t('common.min')}
          name={'min'}
          value={selectedValues[0]}
          onChange={(newValue) => setSelectedValues([newValue, selectedValues[1]])}
        />
        <NumberInput
          label={t('common.max')}
          name={'max'}
          value={selectedValues[1]}
          onChange={(newValue) => setSelectedValues([selectedValues[0], newValue])}
        />
      </div>
      <div className={'action-options-btns'}>
        <CancelButton onClick={() => onCancelButtonClicked()}>{tHtml('common.clear')}</CancelButton>
        <Button onClick={() => onSaveButtonClicked()}>{tHtml('common.save')}</Button>
      </div>
    </AdvanceFilter>
  );
};

AmountFilter.defaultProps = {
  start: 100,
  end: 10000,
};

export default AmountFilter;
