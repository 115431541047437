import { UseQueryOptionsObject } from './queries.utils';
import { useQuery, useQueryClient } from 'react-query';

const useCacheQuery = <Data, SetData = Partial<Data>>(
  queryKey: any[],
  promise: () => Promise<Data>,
  options?: UseQueryOptionsObject<Data>,
) => {
  const client = useQueryClient();
  const query = useQuery<Data>(queryKey, promise, options);
  return {
    ...query,
    retry: false,
    invalidate: () => client.invalidateQueries(queryKey),
    setQueryData: (data: SetData | ((prev: Data) => Data)) =>
      client.setQueryData<Data>(queryKey, (prev) => {
        if (data instanceof Function) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return data(prev!);
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return { ...prev!, ...data };
      }),
  };
};

export { useCacheQuery };
