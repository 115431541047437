import React from 'react';
import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import { IntroView } from './IntroView';
import { prospectApplicationSteps } from './steps';
import * as Roles from 'helpers/auth/roles';
import { BaseLayout } from 'containers';

const ProspectApplicationRoutes: any = () => [
  <AuthRoute
    path={RouteList.GUEST.PROSPECT_APPLICATION.VIEW}
    component={IntroView}
    layout={BaseLayout}
    permissions={Roles.Guest.permissions}
    redirect={Roles.Guest.redirect}
    key={RouteList.GUEST.PROSPECT_APPLICATION.VIEW}
    exact
  />,
  prospectApplicationSteps.map((step) => (
    <AuthRoute
      path={step.route}
      component={step.component}
      layout={BaseLayout}
      permissions={Roles.Guest.permissions}
      redirect={Roles.Guest.redirect}
      key={step.route}
      exact
    />
  )),
];

export default ProspectApplicationRoutes;
