import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { PageHeader } from 'components/Header';
import SecondaryMarketFAQ from '../components/SecondaryMarketFAQ';
import SecondaryMarketPromotionBlock from '../components/SecondaryMarketPromotionBlock';
import MyActiveInvestmentsList from './components/MyActiveInvestmentsList';
import MyForSaleInvestmentsList from './components/MyForSaleInvestmentsList';
import { RenderProvider } from './components/useRender';

const SecondaryMarketSeller: React.FC = () => {
  const { tHtml } = useTranslation();

  return (
    <div className={'page-layout secondary-market-page'}>
      <PageHeader title={tHtml('secondary_market.title')} />
      <SecondaryMarketPromotionBlock />
      <RenderProvider>
        <MyForSaleInvestmentsList />
        <MyActiveInvestmentsList />
      </RenderProvider>
      <SecondaryMarketFAQ />
    </div>
  );
};

export default withRouter(SecondaryMarketSeller);
