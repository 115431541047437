import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';

export const OuterSidebar = styled.div`
  display: none;
  top: 0px;
  width: 100%;
  right: 0px;
  position: fixed;
  background-color: transparent;
  height: 100%;
  z-index: 100;

  ${breakpointDown.LG} {
    display: block;
  }
`;

export const StyledSidebar = styled.div<{ top: string }>`
  width: 18rem;
  z-index: 950;
  bottom: 0;
  margin-top: 3rem;
  position: -webkit-sticky; /* for Safari users */
  position: sticky;
  float: left;
  top: 8rem;
  margin-bottom: 5rem;

  ${breakpointDown.LG} {
    top: ${(props) => props.top || '0'};
    width: 45%;
    margin-top: 0;
    margin-bottom: 0;
    transition: 0.5s;
    right: 0;
    position: fixed;
    background-color: var(--profitus-bg-color-1);
    box-shadow: 0 0 1rem 0.5rem rgb(0 0 0 / 12%);
    padding-left: 0;
    height: auto;
    outline: none;
    z-index: 100;

    &.hide-sidebar {
      transition: 0.5s;
      width: 0;
    }

    .only-icon-phone {
      display: none;
    }
  }

  ${breakpointDown.MD} {
    width: 45%;
  }

  ${breakpointDown.SM} {
    width: 67%;
  }

  ${breakpointDown.XSM} {
    width: 85%;
  }
`;
