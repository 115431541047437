import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { RouteList } from 'routes';
import YesNoBlock from 'components/YesNoBlock';
import { DataBlock } from 'components/DataBlock';
import NotFilledProfileSection from 'components/Profile/NotFilledProfileSection';
import UserProfileSection from 'scopes/user/components/UserProfileSection';
import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';

const KnowYourCustomer: React.FC = () => {
  const { t, tHtml } = useTranslation();

  const { data: userProfileData } = useUserProfileQuery();

  const translatedWork = userProfileData?.know_your_customer?.work.map((item) => {
    if (item === 'other') {
      return (
        t(`placeholder.${item}`) + ' (' + userProfileData?.know_your_customer?.other_work_text + ')'
      );
    } else if (item === 'worker') {
      return (
        t(`placeholder.${item}`) +
        ' (' +
        userProfileData?.know_your_customer?.worker_work_text +
        ')'
      );
    }

    return t(`placeholder.${item}`);
  });

  const translatedPurpose = userProfileData?.know_your_customer?.purpose
    ? userProfileData.know_your_customer.purpose.map((item) => {
        if (item === 'other') {
          return (
            t(`placeholder.${item}`) +
            ' (' +
            userProfileData?.know_your_customer?.other_purpose_text +
            ')'
          );
        }
        return t(`placeholder.${item}`);
      })
    : [];

  const translatedSelfEmployedActivity =
    userProfileData?.know_your_customer?.self_employed_activity?.map((item) => {
      if (item === 'other') {
        return userProfileData?.know_your_customer?.other_self_employed_activity_text;
      }
      return t(`placeholder.${item}`);
    });

  const translatedIncomeSource = userProfileData?.know_your_customer?.main_income_source.map(
    (item) => {
      if (item === 'other') {
        return userProfileData?.know_your_customer?.other_main_income_source_text;
      }
      return t(`placeholder.${item}`);
    },
  );

  if (!userProfileData?.know_your_customer) {
    return (
      <NotFilledProfileSection
        title={tHtml('placeholder.kyc_title')}
        buttonLink={RouteList.USER.EDIT.KNOW_YOUR_CUSTOMER}
      />
    );
  }

  return (
    <UserProfileSection
      menu={UserMenuEnum.KnowYourCustomer}
      title={tHtml('menu.kyc')}
      buttonLink={RouteList.USER.EDIT.KNOW_YOUR_CUSTOMER}
    >
      <div className={'content'}>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.purpose')}
            data={translatedPurpose?.join('; ') ?? ''}
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock title={tHtml('placeholder.work')} data={translatedWork?.join('; ') ?? ''} />
        </div>
        {translatedSelfEmployedActivity && (
          <div className={'mb-4'}>
            <DataBlock
              title={tHtml('placeholder.self_employed_activity')}
              data={translatedSelfEmployedActivity.join('; ')}
            />
          </div>
        )}

        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.average_income')}
            data={
              userProfileData?.know_your_customer?.average_income
                ? t('common.money', { value: userProfileData?.know_your_customer?.average_income })
                : ''
            }
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.main_income_source')}
            data={translatedIncomeSource?.join('; ') ?? ''}
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.planned_investment_amount')}
            data={
              userProfileData?.know_your_customer?.planned_investment_amount
                ? tHtml(
                    `placeholder.${userProfileData?.know_your_customer?.planned_investment_amount}`,
                  )
                : ''
            }
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.origin_of_invested_funds')}
            data={
              userProfileData?.know_your_customer?.origin_of_invested_funds
                ? userProfileData?.know_your_customer?.origin_of_invested_funds
                : ''
            }
          />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml('placeholder.public_office_position')}
            checked={!!userProfileData?.know_your_customer?.public_office_confirm}
          />
          {userProfileData?.know_your_customer?.public_office_confirm && (
            <DataBlock
              title={tHtml('placeholder.public_office_confirm')}
              data={userProfileData?.know_your_customer?.public_office_confirm}
            />
          )}
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml('placeholder.family_public_office_position')}
            checked={!!userProfileData?.know_your_customer?.public_office_family_confirm}
          />
          {userProfileData?.know_your_customer?.public_office_family_confirm && (
            <DataBlock
              title={tHtml('placeholder.public_office_family_confirm')}
              data={userProfileData?.know_your_customer?.public_office_family_confirm}
            />
          )}
        </div>
        <YesNoBlock
          label={tHtml('placeholder.bankruptcy_procedure')}
          checked={userProfileData?.know_your_customer?.bankruptcy_procedure}
        />
      </div>
    </UserProfileSection>
  );
};

export default withRouter(KnowYourCustomer);
