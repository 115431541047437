import React from 'react';

import { RouteList } from 'routes';
import * as Roles from 'helpers/auth/roles';
import AuthRoute from 'routes/AuthRoute';
import { StepFormLayout } from 'containers';
import { ProjectProposal } from '../ProjectProposal';

const ProjectProposalRoutes: any = () => [
  <AuthRoute
    path={RouteList.PROJECT_DEVELOPER.APPLICATIONS.PROPOSAL}
    component={ProjectProposal}
    layout={StepFormLayout}
    permissions={Roles.User.permissions} // TODO only project-developer can access this route
    redirect={Roles.User.redirect}
    key={RouteList.PROJECT_DEVELOPER.APPLICATIONS.PROPOSAL}
    exact
  />,
];

export default ProjectProposalRoutes;
