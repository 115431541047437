import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container } from 'reactstrap';

const StepFormLayoutSkeleton: React.FC = () => (
  <Container>
    <Skeleton height={80} className={'mb-5'} />
    <Skeleton height={160} className={'mb-5'} />
    <Skeleton height={1000} className={'rounded'} />
  </Container>
);

export default StepFormLayoutSkeleton;
