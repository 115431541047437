import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Cell, Column } from 'react-table';

import { PaginationData, PaginationDataFilter } from 'api/types';
import { OperationsResponseDto } from 'api/types/shared/wallet';
import { TableInitialSortBy } from 'api/types/common';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import Table, { AmountFilter, DateFilter } from 'components/Table/Table';
import WalletOperationTypeBadge from 'components/Badge/WalletOperationTypeBadge';
import WalletOperationStatusBadge from 'components/Badge/WalletOperationStatusBadge';
import WalletOperationAmountBadge from 'components/Badge/WalletOperationAmountBadge';

interface Props {
  onFetch: (
    request: PaginationDataFilter | undefined,
  ) => Promise<PaginationData<OperationsResponseDto>>;
  exportCsv: (request: PaginationDataFilter | undefined) => () => Promise<void>;
  exportXlsx: (request: PaginationDataFilter | undefined) => () => Promise<void>;
  exportXml: (request: PaginationDataFilter | undefined) => () => Promise<void>;
  title: string;
}

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'executed_at',
    desc: true,
  },
];

const TransactionsTable: React.FC<Props> = ({
  onFetch,
  exportCsv,
  exportXlsx,
  exportXml,
  title,
}) => {
  const { t, tHtml } = useTranslation();
  const [data, setData] = useState<PaginationData<OperationsResponseDto>>();
  const [paginationRequest, setPaginationRequest] = useState<PaginationDataFilter | undefined>();

  const columns = useMemo<Column<OperationsResponseDto>[] | any>(
    () => [
      {
        Header: t('table.developer_transaction_type'),
        accessor: 'type',
        sortType: 'string',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return (
            <WalletOperationTypeBadge
              type={row.type}
              private_data={row.private_data}
              amount={row.amount}
            />
          );
        },
      },
      {
        Header: t('table.executed_at'),
        accessor: 'executed_at',
        sortType: '',
        Filter: DateFilter,
        Cell: (cell: Cell<OperationsResponseDto>) => (
          <span>{tHtml('common.date_full', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return <WalletOperationStatusBadge status={row.status} />;
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return <WalletOperationAmountBadge amount={row.amount} is_receiver={row.is_receiver} />;
        },
        Filter: AmountFilter,
        filterProps: {
          start: -1000,
          end: 50000,
          ignoreLimits: true,
        },
      },
    ],
    [t, tHtml],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setPaginationRequest(request);
      return onFetch(request).then((response) => setData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onFetch, t],
  );

  return (
    <div className={'mb-6'}>
      <h2 className={'mb-2'}>{title}</h2>
      <div>
        <Table
          columns={columns}
          data={data}
          onFetchData={fetchData}
          showPageSizes={true}
          searchable={true}
          enableQueryFilter={true}
          initialSortBy={INITIAL_SORT_ORDER}
          defaultLimit={20}
          exportLinks={[
            {
              onClick: exportCsv,
              request: paginationRequest,
              type: CommonDocumentTypeEnum.CSV,
            },
            {
              onClick: exportXlsx,
              request: paginationRequest,
              type: CommonDocumentTypeEnum.XLSX,
            },
            {
              onClick: exportXml,
              request: paginationRequest,
              type: CommonDocumentTypeEnum.XML,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default TransactionsTable;
