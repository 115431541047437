import React, { useEffect, useState } from 'react';
import { FilterValue } from 'react-table';
import useTranslation from 'helpers/useTranslation';

export type FilterSelectValuesResponseDto = {
  key: string;
  value: string;
};

export type AvailableGenericType = {
  key: string;
};

export interface ColumnFilterProps {
  column: {
    filterValue: FilterValue;
    setFilter: (updater: ((filterValue: FilterValue) => FilterValue) | FilterValue) => void;
  };
  request?: Promise<FilterSelectValuesResponseDto[]>;
  options?: FilterSelectValuesResponseDto[];
  optionsRequest?: Promise<FilterSelectValuesResponseDto[]>;
  optionsMapResponse?: (response: AvailableGenericType[]) => FilterSelectValuesResponseDto[];
}

const ColumnFilter: React.FC<ColumnFilterProps> = ({
  column,
  request,
  optionsRequest,
  options,
  optionsMapResponse,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<FilterSelectValuesResponseDto[]>([]);

  useEffect(() => {
    if (optionsRequest && optionsMapResponse) {
      optionsRequest.then((response) => {
        setData(optionsMapResponse(response));
      });
    }
  }, [options, optionsMapResponse, optionsRequest, request, t]);

  if (!request && !options) return null;

  return (
    <select
      value={column.filterValue}
      onChange={(e) => {
        column.setFilter(e.target.value || undefined);
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={'form-control'}
    >
      <option value="" className={'option-font-size'}>
        {t('common.all')}
      </option>
      {data.map((option, i) => (
        <option key={i} value={option.key} className={'option-font-size'}>
          {option.value}
        </option>
      ))}
    </select>
  );
};

export default ColumnFilter;
