import React, { ReactElement } from 'react';
import { Input, Label } from 'reactstrap';
import { UseNumbers } from 'helpers/useNumbers';
import { ErrorMessage, useField } from 'formik';

interface Props {
  label: string | ReactElement;
  name: string;
  isHighlight?: boolean;
  isError?: boolean;
  max?: number;
}

const defaultProps: Props = {
  label: '',
  name: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  isHighlight: false,
  isError: false,
  max: undefined,
};

const NumberInput: React.FC<Props> = (props) => {
  const [field, meta] = useField(props.name);

  const getClassName = (isHighlight: boolean, isError: boolean): string => {
    let baseStyle = ' site-input highlight';

    baseStyle = isError ? baseStyle.concat(' is-invalid') : baseStyle;

    return baseStyle;
  };

  const isError = meta?.touched && !!meta?.error;

  return (
    <>
      <div className={getClassName(!!props.isHighlight, isError)}>
        <Label className={'label'} for={props.name}>
          {props.label}
        </Label>
        <Input
          type={'number'}
          name={props.name}
          id={props.name}
          value={field.value}
          onClick={(e) => e.preventDefault()}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            const newValue = event.target.value;

            if (props?.max === undefined || Number(newValue) <= props.max) {
              return field.onChange(event);
            } else {
              event.preventDefault();
            }
          }}
          max={props.max}
          className={`form-control ${isError ? 'is-invalid' : ''}`}
          onKeyPress={UseNumbers.preventNonNumericalInput}
          onWheel={(e) => e.currentTarget.blur()} /* Disabling number change on scroll */
        />
      </div>
      {isError && (
        <div className={'invalid-feedback text-pre'}>
          {props.name && (
            <ErrorMessage
              component={'div'}
              name={props.name}
              render={(msg: string | string[]) => {
                if (typeof msg === 'string') {
                  return msg;
                }

                return msg.join('\n');
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

NumberInput.defaultProps = defaultProps;
export default NumberInput;
