import React, { useCallback, useContext, useEffect } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Form, FormGroup, Row } from 'reactstrap';

import { setGlobalLoading } from 'modules/app/actions';
import { ApplicationContext } from './module/ApplicationContext';
import { ApplicationPageProps } from './steps';
import { PrimaryButton } from 'components/Formik';
import { redirect } from './CreateApplication';
import { Formik } from 'formik';
import { success } from 'services/toastr';
import RealEstateDevelopmentReview from './ReviewTypes/RealEstateDevelopmentReview';
import RefinancingReview from './ReviewTypes/RefinancingReview';
import WorkingCapitalReview from './ReviewTypes/WorkingCapitalReview';
import RealEstateAcquisitionReview from './ReviewTypes/RealEstateAcquisitionReview';
import OtherReview from './ReviewTypes/OtherReview';
import { ApplicationTypeEnum } from '../../helpers/enums/ApplicationTypeEnum';
import Api from 'api';

const Review: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
  isLastStep,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { state, setApplicationId } = useContext(ApplicationContext);

  useEffect(() => {
    if (applicationId !== undefined) {
      setApplicationId(applicationId);
    }

    setGlobalLoading(false);
  }, [t, applicationId, setApplicationId]);

  const onSubmit = useCallback(async () => {
    await Api.projectDeveloper.applications.markApplicationAsSubmitted(applicationId).then(() => {
      if (isLastStep) {
        success(tHtml('application.created_successfully'));
      }
      redirect(history, nextPage, applicationId);
    });
  }, [applicationId, history, isLastStep, nextPage, tHtml]);

  let reviewContent;

  switch (state.application?.type) {
    case ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT:
      reviewContent = <RealEstateDevelopmentReview state={state} />;
      break;
    case ApplicationTypeEnum.REFINANCING:
      reviewContent = <RefinancingReview state={state} />;
      break;
    case ApplicationTypeEnum.WORKING_CAPITAL:
      reviewContent = <WorkingCapitalReview state={state} />;
      break;
    case ApplicationTypeEnum.REAL_ESTATE_ACQUISITION:
      reviewContent = <RealEstateAcquisitionReview state={state} />;
      break;
    case ApplicationTypeEnum.OTHER:
      reviewContent = <OtherReview state={state} />;
      break;
    default:
      reviewContent = undefined;
  }

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          <h1 className="mb-4 mb-md-6">{tHtml('application.review')}</h1>
          {reviewContent}
          <Formik enableReinitialize={true} initialValues={{}} onSubmit={onSubmit}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <PrimaryButton
                      className={'btn btn-grey w-25'}
                      type={'button'}
                      onClick={() => {
                        redirect(history, previousPage, applicationId);
                      }}
                    >
                      {tHtml('common.back')}
                    </PrimaryButton>
                    <PrimaryButton className={'btn btn-primary w-25'} submitting={isSubmitting}>
                      {tHtml('common.submit')}
                    </PrimaryButton>
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </div>
      </Row>
    </>
  );
};

export default withRouter(Review);
