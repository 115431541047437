import { useField, Field, ErrorMessage } from 'formik';
import React, { ReactElement, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { TextInput } from 'components/Formik';
import ScrollToOnError from '../ScrollToOnError';
import YesNoSelector from '../YesNoSelector';

export interface Props
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'placeholder'
  > {
  name: string;
  isMulti: boolean;
  textName: string;
  textPlaceholder?: string | ReactElement;
  value?: string;
  showPlaceholder: boolean;
  placeholder?: string | ReactElement;
  placeholderPrefix?: string;
  withButtons?: boolean;
}

const CheckboxTextInput: React.FC<Props> = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const [field, meta, helper] = useField(props.textName);
  const [, checkMeta] = useField(props.name);
  const [checked, setChecked] = useState<boolean>(
    props.isMulti ? checkMeta.value?.includes(props.value) : checkMeta.value,
  );

  if (!props.placeholder && props.showPlaceholder) {
    props.placeholder = tHtml((props.placeholderPrefix ?? 'placeholder.') + props.value);
  }

  const checkValue: any = props.isMulti
    ? {
        value: props.value,
      }
    : {};

  const textProps: any = props.isMulti
    ? {
        placeholder: '',
      }
    : {};

  const isError = meta?.touched && !!meta?.error;

  const handleChange = (e: React.FormEvent) => {
    field.onChange(e);
    setChecked(!checked);
  };

  return (
    <div className={!props.withButtons ? `form-check row` : undefined}>
      {props.withButtons ? (
        <div className={'col-sm-auto'}>
          <YesNoSelector
            name={props.name}
            placeholder={props.placeholder}
            handleClick={(value: boolean) => setChecked(value)}
          />
        </div>
      ) : (
        <div className={'col-sm-auto'}>
          <ScrollToOnError name={props.name} isError={isError}>
            <label className={`form-check-label form-label ${isError ? ' is-invalid' : ''}`}>
              <Field
                onChange={handleChange}
                onBlur={field.onBlur}
                type="checkbox"
                className={`form-check-input ${isError ? ' is-invalid' : ''}`}
                name={props.name}
                {...checkValue}
              />
              <span className={'ms-2'}>{props.placeholder}</span>
            </label>
          </ScrollToOnError>
        </div>
      )}
      <div className={`col-sm ${isError ? ' is-invalid' : ''}`}>
        {checked && props.isMulti && (
          <div>
            <ScrollToOnError name={props.textName} isError={isError}>
              <Field
                className={`form-control ${isError ? ' is-invalid' : ''}`}
                type="text"
                name={props.textName}
                id={props.textName}
                onInput={() => helper.setTouched(true, true)}
                placeholder={props.textPlaceholder}
              />
            </ScrollToOnError>
            {isError && (
              <div className={'invalid-feedback ms-2'}>
                {props.textName && <ErrorMessage name={props.textName} />}
              </div>
            )}
          </div>
        )}
        {checked && !props.isMulti && <TextInput name={props.textName} {...textProps} />}
      </div>
    </div>
  );
};

CheckboxTextInput.defaultProps = {
  value: '',
  textPlaceholder: '',
};

export default CheckboxTextInput;
