import { ErrorMessage, useField, Field } from 'formik';
import React, { ReactElement, useEffect } from 'react';
import useTranslation from 'helpers/useTranslation';

import ScrollToOnError from './ScrollToOnError';

export interface Props
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'placeholder'
  > {
  name: string;
  showPlaceholder?: boolean;
  translationPrefix?: string;
  customLabel?: React.ReactNode;
  disabled?: boolean;
  placeholder?: string | ReactElement;
}

const CheckboxInput: React.FC<Props> = ({ disabled = false, ...props }) => {
  const { tHtml } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  useEffect(() => {
    helper.setTouched(true, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  if (!props.placeholder && props.showPlaceholder) {
    props.placeholder = tHtml(props.translationPrefix + props.name);
  }

  const isError = meta?.touched && !!meta?.error;

  const handleChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e);
    }
    field.onChange(e);
  };

  return (
    <div className={'form-check'}>
      <ScrollToOnError name={props.name} isError={isError}>
        <label className={`form-check-label form-label ${isError ? ' is-invalid' : ''}`}>
          <Field
            disabled={disabled}
            onChange={handleChange}
            onBlur={field.onBlur}
            name={props.name}
            type={'checkbox'}
            className={`form-check-input${isError ? ' is-invalid' : ''}`}
          />
          {props.customLabel ? (
            props.customLabel
          ) : (
            <div className={'ms-2'}>{props.placeholder}</div>
          )}
        </label>
      </ScrollToOnError>
      {isError && (
        <div className={'invalid-feedback ms-2'}>
          {props.name && <ErrorMessage name={props.name} />}
        </div>
      )}
    </div>
  );
};

CheckboxInput.defaultProps = {
  translationPrefix: 'placeholder.',
  showPlaceholder: true,
};

export default CheckboxInput;
