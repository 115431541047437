import { defaultOptions, UseQueryOptionsObject } from 'api/queries/queries.utils';
import { useMakeQuery } from 'api/queries/useMakeQuery';
import { queries } from '../index';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStatisticQuery = <Data, SetData = Partial<Data>>(
  queryKey: any[],
  promise: () => Promise<Data>,
  options?: UseQueryOptionsObject<Data>,
) => {
  return useMakeQuery<Data>([queries.statistics, ...queryKey], promise, {
    staleTime: 120 * 1000,
    retry: false,
    ...defaultOptions,
    ...(options ?? {}),
  });
};

export { useStatisticQuery };
