import React, { useEffect } from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import { ModalComponent } from 'components/Modal/ModalComponent';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import QRCode from 'qrcode.react';
import LaravelPusher from 'services/LaravelPusher';
import { LaravelPusherEventEnum } from 'helpers/enums/pusher/LaravelPusherEventEnum';
import { StyledP, QrCodeModalWrapper } from './QrCodeModal.style';
import { useAccountQuery } from 'api/queries';
import { navigate } from 'helpers';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'routes';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import { useAuth } from 'services/useAuth';

interface Props {
  message: string;
  qrCode: string;
  title?: string | JSX.Element;
}

interface OndatoResponse {
  success: boolean;
  error: string | null;
}

const QrCodeModal: React.FC<Props> = ({ ...props }) => {
  const { hideModal } = useGlobalModalContext();
  const { data: userAccount } = useAccountQuery();
  const { invalidate: invalidateUserProfile } = useUserProfileQuery();
  const { refresh: refreshPermissions } = useAuth();

  useEffect(() => {
    if (userAccount) {
      const laravelPusher = new LaravelPusher(
        'accreditation-successful-' + userAccount.id,
        LaravelPusherEventEnum.AccreditationCompletedEvent,
        async (data: OndatoResponse) => {
          if (data.success) {
            await refreshPermissions();
            invalidateUserProfile();
            hideModal();
            navigate(generatePath(RouteList.DASHBOARD));
          }
        },
      );

      return () => {
        laravelPusher.stopListening(LaravelPusherEventEnum.AccreditationCompletedEvent);
      };
    }
  }, [hideModal, userAccount, invalidateUserProfile, refreshPermissions]);

  return (
    <React.Fragment>
      <ModalComponent size={'sm'} {...props} className={'modal position-relative'}>
        <i className={'icon icon-close mb-4 cursor pointer'} onClick={hideModal} />
        <div className={'p-2 p-lg-4'}>
          <ModalHeader className={'d-flex justify-content-between align-items-center'}>
            <span>{props.title}</span>
          </ModalHeader>
          <ModalBody className={'modal-body'}>
            <QrCodeModalWrapper>
              <QRCode size={220} value={props.qrCode} />
              <StyledP>{props.message}</StyledP>
            </QrCodeModalWrapper>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default QrCodeModal;
