import React, { createContext, useEffect, useReducer } from 'react';
import { action } from 'typesafe-actions';
import { useLocalStorage } from 'helpers';

import {
  ApplicationState,
  ApplicationReducer,
  initialState,
  UPDATE_APPLICATION,
} from './ApplicationReducer';
import Api from 'api';
import { generatePath, useHistory } from 'react-router-dom';
import { RouteList } from 'routes';
import { ProspectApplicationStatusEnum } from 'scopes/guest/helpers/enums/ProspectApplicationStatusEnum';
interface IApplicationContext {
  state: ApplicationState;
  dispatch: React.Dispatch<any>;
  setUuid: (value: string | null) => void;
}

export const ApplicationContext = createContext<IApplicationContext>({
  state: initialState,
  dispatch: () => {
    return;
  },
  setUuid: (): void => {
    return;
  },
});

export const ApplicationContextProvider: React.FC = ({ children }): JSX.Element => {
  const [state, dispatch] = useReducer(ApplicationReducer, initialState);
  const [uuid, setUuid] = useLocalStorage<string | null>('uuid', null);
  const history = useHistory();

  const fetchApplication = async (id: string) => {
    if (id == state.id) {
      return;
    }

    try {
      await Api.guest.prospect_application.fetchApplication(id).then((response) => {
        if (
          response.prospect_application.status &&
          response.prospect_application.status !== ProspectApplicationStatusEnum.IN_PROGRESS &&
          response.prospect_application.status !== ProspectApplicationStatusEnum.REJECTED
        ) {
          const path = generatePath(RouteList.GUEST.PROSPECT_APPLICATION.SUCCESS, {
            applicationId: response.prospect_application.id,
          });
          localStorage.removeItem('uuid');
          history.push(path);
        }
        dispatch(action(UPDATE_APPLICATION, response));
      });
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    if (!uuid) return;
    fetchApplication(uuid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  return (
    <ApplicationContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
        setUuid,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
