import React, { useCallback, useEffect, useState } from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Trans } from 'react-i18next';

import Api from 'api';
import { LANDING_ROUTES, RouteList } from 'routes';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { CheckboxInput, TextInput } from 'components/Formik';
import { InvestInProjectRequest, InvestorProjectResponse } from 'api/types/investor/project';
import { success } from 'services/toastr';
import InvestorProjectGuard from 'helpers/guards/investor';
import useInvestmentModalBonusStore from './InvestmentModalBonusStore';
import ApplyBonus from './ApplyBonus';
import Tooltip from 'components/Tooltip';
import { UseNumbers } from 'helpers/useNumbers';
import { useWalletQuery } from 'api/queries/useWalletQuery';
import { GoogleTagManager } from 'GoogleTagManager';
import { canInvestOnlyRemainingAmount } from 'helpers/investmentsHelper';
import DownloadButtonAuthorized from 'components/DownloadButtonAuthorized';
import { responseToPdf } from 'helpers/utils';
import { handleDecimalsOnValue } from 'helpers/utils';
import { InvestButton } from './InvestmentModal.styles';
import useInvestor from 'helpers/useInvestor';
import { Link } from 'react-router-dom';
import InvestedWithoutQuizModal from '../InvestedWithoudQuizModal';
import { useAccountQuery } from 'api/queries';
import { InvestorExperienceLevelTypeEnum } from 'helpers/enums/InvestorExperienceLevelTypeEnum';

interface Props extends ModalComponentProps {
  amount: number;
  setProject: ((project: InvestorProjectResponse) => void) | undefined;
}

interface IFormValues {
  investment_amount: number | string;
  bonus_codes: Array<string>;
  agreement_all: boolean;
  agreement_market: boolean;
  agreement_risk: boolean;
  agreement_rules: boolean;
}

const InvestmentModal: React.FC<Props> = ({ project, setProject, amount, ...props }) => {
  const { t, tHtml, i18n } = useTranslation();
  const { hideModal, showModal } = useGlobalModalContext();
  const [isBonusLoading, setBonusLoading] = useState<boolean>(false);
  const bonusStore = useInvestmentModalBonusStore();

  const { invalidate: invalidateWallet } = useWalletQuery();
  const { data: accountData } = useAccountQuery();
  const [investment, setInvestment] = useState<string | number>(amount);
  const { hasFilledQuiz } = useInvestor();

  const p: InvestorProjectResponse = project as InvestorProjectResponse;

  const [formRequest, setFormRequest] = useState<IFormValues>({
    investment_amount: amount,
    bonus_codes: [],
    agreement_all: false,
    agreement_market: false,
    agreement_risk: false,
    agreement_rules: false,
  });

  const schema = Yup.object().shape({
    investment_amount: Yup.number().min(1).required(),
    bonus_codes: Yup.array().of(Yup.string().min(1).max(255).required()),
    agreement_all: Yup.boolean(),
    agreement_market: Yup.boolean().required().isTrue(),
    agreement_risk: Yup.boolean().required().isTrue(),
    agreement_rules: Yup.boolean().required().isTrue(),
  });

  const onSubmit = useCallback(
    async (request: IFormValues, helpers: FormikHelpers<IFormValues>) => {
      if (!p) return;

      const req: InvestInProjectRequest = {
        investment_amount: parseFloat(investment.toString()),
        bonus_codes: bonusStore.codes,
        agreement_market: request.agreement_market,
        agreement_risk: request.agreement_risk,
        agreement_rules: request.agreement_rules,
      };

      try {
        await Api.investor.projects.investInProject(p.id, req).then((response) => {
          invalidateWallet();
          const successMessage =
            accountData?.investor_level === InvestorExperienceLevelTypeEnum.EXPERIENCED
              ? tHtml('projects.investment_modal.experienced_investor_investment_success')
              : tHtml('projects.investment_modal.investment_success');

          success(successMessage);

          hideModal();
          !hasFilledQuiz && showModal(<InvestedWithoutQuizModal />);

          if (setProject) {
            setProject(response.project);
          }

          GoogleTagManager.push({
            event: 'purchase',
            ecommerce: {
              currency: 'EUR',
              value: req.investment_amount,
              transaction_id: response.transaction_id || 0,
              items: [
                {
                  item_name: p.project_name,
                  item_id: p.id,
                  price: req.investment_amount,
                  quantity: 1,
                },
              ],
            },
          });
        });
      } catch (e) {
        helpers.setErrors(e.response?.errors ?? {});
      }
    },
    [
      p,
      bonusStore.codes,
      hasFilledQuiz,
      invalidateWallet,
      showModal,
      tHtml,
      hideModal,
      setProject,
      investment,
      accountData?.investor_level,
    ],
  );

  useEffect(() => {
    if (p && canInvestOnlyRemainingAmount(p)) {
      setFormRequest({
        ...formRequest,
        investment_amount: (p.required_amount - p.invested_amount).toFixed(2),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [p]);

  const handleIIPSClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    event.preventDefault();

    await fetchIIPS();
  };

  const fetchIIPS = async () => {
    const response = await Api.investor.projects.previewIIPS(p.id);
    responseToPdf(response, 'TermsAndConditionsOfInvestment.pdf');
  };

  if (!p) {
    return <></>;
  }

  function checkValue(value: string) {
    setInvestment(handleDecimalsOnValue(value));
  }

  return (
    <React.Fragment>
      <ModalComponent size={'lg'} {...props} contentClassName={'modal-investment'}>
        <div className={'p-3'}>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />} tag={'h1'}>
            {tHtml('projects.investment_modal.investment_confirmation')}
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={formRequest}
              validationSchema={schema}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validateOnBlur={true}
              validateOnChange={true}
            >
              {({ handleSubmit, isSubmitting, values, setValues }) => (
                <div>
                  <div>
                    <div className={'mb-3'}>
                      <div className={'py-2'}>
                        <div>
                          <h2 className={'mb-3 fw-bold'}>
                            {tHtml('projects.investment_modal.investment_amount')}
                          </h2>
                        </div>
                        {canInvestOnlyRemainingAmount(p) && (
                          <div className="alert alert-warning">
                            {tHtml('projects.investment_modal.warning_can_only_invest_less', {
                              value: p.minimal_investment,
                            })}
                          </div>
                        )}
                        <div>
                          <TextInput
                            name={'investment_amount'}
                            type={'number'}
                            value={investment}
                            hideLabel={true}
                            disabled={canInvestOnlyRemainingAmount(p) || isBonusLoading}
                            onKeyPress={UseNumbers.preventNonNumericalInput}
                            onWheel={(e) => e.currentTarget.blur()}
                            onChange={(e) => {
                              checkValue(e.target.value);
                              bonusStore.reset();
                            }}
                          />
                        </div>
                      </div>
                      {bonusStore.bonus_amount && bonusStore.bonus_amount > 0 ? (
                        <div className="align-items-center d-flex col-12">
                          <div className={'balance-description m-1 pe-1'}>
                            {tHtml('projects.investment_modal.investment_bonus_amount')}
                          </div>
                          <div className={'text m-1'}>
                            +{t('common.money', { value: bonusStore.bonus_amount })}
                          </div>
                        </div>
                      ) : null}
                      {bonusStore.bonus_interest && bonusStore.bonus_interest > 0 ? (
                        <div className="align-items-center d-flex col-12">
                          <div className={'balance-description m-1 pe-1'}>
                            {tHtml('projects.investment_modal.investment_bonus_interest')}
                          </div>
                          <div className={'text m-1'}>
                            +{t('common.percents', { value: bonusStore.bonus_interest })}
                          </div>
                        </div>
                      ) : null}
                      {bonusStore.bonus_amount &&
                      bonusStore.bonus_amount > 0 &&
                      bonusStore.total_amount &&
                      bonusStore.total_amount > 0 ? (
                        <div className="align-items-center d-flex col-12">
                          <div className={'balance-description m-1 pe-1'}>
                            {tHtml('projects.investment_modal.investment_amount_after_bonus')}
                          </div>
                          <div className={'text m-1'}>
                            {tHtml('common.money', { value: bonusStore.total_amount })}
                          </div>
                        </div>
                      ) : null}
                      {bonusStore.bonus_interest &&
                      bonusStore.bonus_interest > 0 &&
                      bonusStore.total_interest &&
                      bonusStore.total_interest > 0 ? (
                        <div className="align-items-center d-flex col-12">
                          <div className={'balance-description m-1 pe-1'}>
                            {tHtml('projects.investment_modal.investment_interest_after_bonus')}
                          </div>
                          <div className={'text m-1'}>
                            {tHtml('common.percents', { value: bonusStore.total_interest })}
                          </div>
                        </div>
                      ) : null}
                      {!bonusStore.is_sellable && (
                        <div className="align-items-center d-flex col-12">
                          <div className={'balance-description m-1 pe-1'}>
                            {tHtml('projects.investment_modal.sellable_disclaimer.title')}
                          </div>
                          <i className={`icon icon-info`} id={`tooltip-icon-sellable_disclaimer`} />
                          <Tooltip
                            id={`tooltip-icon-sellable_disclaimer`}
                            text={tHtml(
                              'projects.investment_modal.sellable_disclaimer.description',
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <ApplyBonus
                    project={project}
                    isLoading={isBonusLoading}
                    setLoading={(value: boolean) => setBonusLoading(value)}
                    amount={parseFloat(values.investment_amount.toString())}
                  />

                  <div className={'w-100'}>
                    <Form onSubmit={handleSubmit}>
                      {/* TODO: links to rules */}
                      <div className={'form-input mb-3'}>
                        <CheckboxInput
                          name={'agreement_all'}
                          onChange={(event) =>
                            setValues((p) => ({
                              ...p,
                              agreement_market: event.target.checked,
                              agreement_risk: event.target.checked,
                              agreement_rules: event.target.checked,
                            }))
                          }
                        />
                      </div>
                      <div className={'form-input mb-3'}>
                        <CheckboxInput name={'agreement_market'} />
                      </div>
                      <div className={'form-input mb-3'}>
                        <CheckboxInput name={'agreement_risk'} />
                      </div>
                      <div className={'form-input mb-3'}>
                        <CheckboxInput
                          name={'agreement_rules'}
                          customLabel={
                            <div className={'ms-2'}>
                              <Trans
                                i18nKey={'placeholder.agreement_rules'}
                                components={[
                                  <a
                                    key={0}
                                    href={LANDING_ROUTES[i18n.resolvedLanguage].INVESTMENT_RISKS}
                                    className={'link-secondary fw-bold'}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                  />,
                                  <DownloadButtonAuthorized
                                    key={1}
                                    url={p.authorized_details?.project_risks?.url ?? '#'}
                                    className={
                                      'btn btn-borderless link-secondary fw-bold form-label btn-inline'
                                    }
                                  />,
                                  <a
                                    key={2}
                                    download
                                    className={'link-secondary fw-bold'}
                                    onClick={handleIIPSClick}
                                  />,
                                  <a
                                    key={3}
                                    href={LANDING_ROUTES[i18n.resolvedLanguage].FEES}
                                    className={'link-secondary fw-bold'}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                  />,
                                  <a
                                    key={4}
                                    href={LANDING_ROUTES[i18n.resolvedLanguage].DOCUMENTS}
                                    className={'link-secondary fw-bold'}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                  />,
                                  <a
                                    key={5}
                                    href={
                                      LANDING_ROUTES[i18n.resolvedLanguage].REFERRAL_PROGRAM_RULES
                                    }
                                    className={'link-secondary fw-bold'}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                  />,
                                ]}
                              />
                            </div>
                          }
                        />
                      </div>

                      {project.will_have_right_to_refund && (
                        <div className={'form-input mb-3'}>
                          <span className="text-muted small">
                            {tHtml('projects.investment_modal.reflection_period')}
                          </span>
                        </div>
                      )}

                      {InvestorProjectGuard.canInvest(p) &&
                        (hasFilledQuiz ? (
                          <InvestButton className={'btn btn-primary mt-3'} disabled={isSubmitting}>
                            {tHtml('common.invest')}
                          </InvestButton>
                        ) : (
                          <div className="d-flex gap-3 flex-wrap">
                            <Link to={RouteList.INVESTOR.QUIZ.VIEW}>
                              <button
                                className="btn btn-primary mt-3 px-2"
                                onClick={() => hideModal()}
                              >
                                {t('projects.investment_modal.fill_quiz')}
                              </button>
                            </Link>
                            <button
                              type="submit"
                              className={'btn btn-highlight mt-3 px-2'}
                              disabled={isSubmitting}
                            >
                              {t('projects.investment_modal.invest_without_quiz')}
                            </button>
                          </div>
                        ))}
                    </Form>
                  </div>
                </div>
              )}
            </Formik>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default InvestmentModal;
