import React from 'react';
import { Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import { StyledInfoBlockContainer } from './InfoBlockContainer.style';
import InfoBlock from 'components/InfoBlock/InfoBlock';
import { PrimaryButton } from 'components/Button';
import useScope from 'helpers/useScope';
import useOnboarding from 'helpers/useOnboarding';
import { useAccountQuery } from 'api/queries';
import { isWalletProviderObsolete } from 'helpers/enums/wallet/WalletProviderEnum';
import { useWalletQuery } from 'api/queries/useWalletQuery';
import RedirectToMainUserIdVerificationButton from '../../../global/pages/Authentication/Button/RedirectToMainUserIdVerificationButton';
import useUserType from 'helpers/useUserType';
import AcceptTermsAndConditionsModal from 'scopes/user/pages/Profile/Modals/TermsAndConditions/AcceptTermsAndConditionsModal';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';

const InfoBlockContainer: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const { isUser, isCompany } = useScope();
  const { isInvestor } = useUserType();
  const onBoarding = useOnboarding();
  const { data } = useAccountQuery();
  const { showModal } = useGlobalModalContext();

  const { data: wallet } = useWalletQuery();

  const infoBlocks = [
    {
      condition: isInvestor() && data && !data.terms_and_conditions_accepted,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.must_accept_terms_and_service.title')}
          description={t('info_block.must_accept_terms_and_service.description')}
        >
          <PrimaryButton onClick={() => showModal(<AcceptTermsAndConditionsModal />)}>
            {tHtml('profile.terms_and_conditions.button_view_terms_and_conditions')}
          </PrimaryButton>
        </InfoBlock>
      ),
    },
    {
      condition:
        data?.is_document_expired !== null &&
        data?.is_document_expired !== undefined &&
        data.is_document_expired,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.document_expired.title')}
          description={t('info_block.document_expired.description')}
        >
          {isUser() && (
            <Link to={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}>
              <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
            </Link>
          )}
        </InfoBlock>
      ),
    },
    {
      condition: data?.is_document_expiring_soon,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.document_expiring.title')}
          description={t('info_block.document_expiring.description')}
        >
          <Link to={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}>
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition: isCompany() && data?.main_user_verified === false,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.main_user_not_verified.title')}
          description={t('info_block.main_user_not_verified.description')}
        >
          <RedirectToMainUserIdVerificationButton />
        </InfoBlock>
      ),
    },
    {
      condition: isUser() && !onBoarding.isContactDetailsFilled,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.contact_info.title')}
          description={t('info_block.contact_info.description')}
        >
          <Link to={RouteList.USER.EDIT.CONTACT_INFO}>
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition: isUser() && !onBoarding.isAccredited,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.id_verification.title')}
          description={t('info_block.id_verification.description')}
        >
          <Link to={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}>
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition: isUser() && !onBoarding.isKnowYourCustomerFilled,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.kyc.title')}
          description={t('info_block.kyc.description')}
        >
          <Link to={RouteList.USER.EDIT.KNOW_YOUR_CUSTOMER}>
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition: isCompany() && !onBoarding.isKnowYourBusinessFilled,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.kyb.title')}
          description={t('info_block.kyb.description')}
        >
          <Link to={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}>
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition: isCompany() && !onBoarding.isAccredited,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.id_verification_company.title')}
          description={t('info_block.id_verification_company.description')}
        />
      ),
    },
    {
      condition: !onBoarding.isInvestorQuizFilled,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.investor_quiz.title')}
          description={t('info_block.investor_quiz.description')}
        >
          <Link to={RouteList.INVESTOR.QUIZ.VIEW}>
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition:
        data?.is_contact_info_expired !== null &&
        data?.is_contact_info_expired !== undefined &&
        data.is_contact_info_expired,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.contact_info_expired.title')}
          description={t('info_block.contact_info_expired.description')}
        >
          <Link
            to={isUser() ? RouteList.USER.EDIT.CONTACT_INFO : RouteList.COMPANY.EDIT.CONTACT_INFO}
          >
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition:
        data?.is_investor_quiz_expired !== null &&
        data?.is_investor_quiz_expired !== undefined &&
        data.is_investor_quiz_expired,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.investor_quiz_expired.title')}
          description={t('info_block.investor_quiz_expired.description')}
        >
          <Link to={RouteList.INVESTOR.QUIZ.VIEW}>
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition:
        data?.is_investor_quiz_expired !== null &&
        data?.is_investor_quiz_expired !== undefined &&
        data.is_investor_quiz_expired,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.contact_info_expired.title')}
          description={t('info_block.contact_info_expired.description')}
        >
          <Link
            to={isUser() ? RouteList.USER.EDIT.CONTACT_INFO : RouteList.COMPANY.EDIT.CONTACT_INFO}
          >
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition: isWalletProviderObsolete(wallet?.provider),
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.wallet_provider_not_supported.title')}
          description={t('info_block.wallet_provider_not_supported.description')}
        >
          {isUser() && (
            <Link to={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}>
              <PrimaryButton>{tHtml('profile.id_verification.id_verification')}</PrimaryButton>
            </Link>
          )}
        </InfoBlock>
      ),
    },
    {
      condition:
        isInvestor() &&
        data &&
        !data.has_financial_risk_calculator_filled &&
        !data.financial_risk_calculator_visited,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.financial_risk_calculator.title')}
          description={t('info_block.financial_risk_calculator.description')}
        >
          <Link to={RouteList.INVESTOR.FINANCIAL_RISK_CALCULATOR.VIEW}>
            <PrimaryButton>
              {tHtml('profile.financial_risk_calculator.financial_risk_calculator')}
            </PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
  ];

  const getCurrentInfoBlock = () => {
    for (let i = 0; i < infoBlocks.length; i++) {
      if (infoBlocks[i].condition) {
        return infoBlocks[i].component;
      }
    }

    return null;
  };

  return <StyledInfoBlockContainer>{getCurrentInfoBlock()}</StyledInfoBlockContainer>;
};

export default InfoBlockContainer;
