import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';

export const MultipleButtonDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  ${breakpointDown.MD} {
    flex-direction: column;
    align-items: flex-end;
  }
`;
