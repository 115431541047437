import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Col, ColProps, Row } from 'reactstrap';

import { ProjectInvestmentContextState, withProjectInvestment } from '../ProjectInvestmentProvider';
import { PrimaryButton } from 'components/Button';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import SellInvestmentModal from '../../SellInvestmentModal';
import { UseNumbers } from 'helpers/useNumbers';

interface Props extends ProjectInvestmentContextState {
  defaultAmount?: number;
  taxSeller?: number;
  taxBuyer?: number;
}

// TODO: fetch tax values from db
const ProjectTopCalculator: React.FC<Props> = ({
  projectInvestment,
  defaultAmount = 0,
  taxSeller = 0.02,
  taxBuyer = 0,
}) => {
  const { tHtml } = useTranslation();
  const [salePrice, setSalePrice] = useState<string>(String(defaultAmount));
  const { showModal } = useGlobalModalContext();

  if (!projectInvestment) return <></>;

  const colProps: ColProps = {
    lg: 6,
    md: 6,
    sm: 12,
    xs: 12,
  };

  const calculateAmountAfterTax = (amount: string): string => {
    return String(Number(amount) * (1 - (taxSeller! + taxBuyer!)));
  };

  return (
    <>
      <hr />
      <div className={'sell-investment-calculator mt-4'}>
        <Row className={'mb-2'}>
          <Col {...colProps}>
            <div className={'py-2'}>
              <h2 className={'mb-3'}>{tHtml('secondary_market.project.calculator.sale_price')}</h2>
              <div>
                <input
                  type="number"
                  className="form-control"
                  value={salePrice}
                  onChange={(e) => setSalePrice(String(e.target.value))}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                />
              </div>
            </div>
          </Col>
          <Col {...colProps}>
            <div className={'py-2'}>
              <h2 className={'mb-3'}>
                {tHtml('secondary_market.project.calculator.amount_after_tax')}
              </h2>
              <div>
                <h1>{tHtml('common.money', { value: calculateAmountAfterTax(salePrice) })}</h1>
              </div>
            </div>
          </Col>
        </Row>
        <div>
          <p>{tHtml('secondary_market.project.calculator.disclaimer')}</p>
        </div>
        <div className={'secondary-market-sell-button-wrapper'}>
          <PrimaryButton
            className={'btn btn-primary secondary-market-sell-button mt-1 mb-2'}
            onClick={() =>
              showModal(
                <SellInvestmentModal
                  investmentId={projectInvestment.id}
                  amount={Number(salePrice)}
                  onAmountChange={setSalePrice}
                  taxSeller={taxSeller}
                  taxBuyer={taxBuyer}
                />,
              )
            }
          >
            {tHtml('common.sell')}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};

export default withProjectInvestment(ProjectTopCalculator);
