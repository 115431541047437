import React from 'react';
import { useStepsModalStateStore } from '../store';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';

const ModalCloseButton: React.FC = () => {
  const modalState = useStepsModalStateStore();
  const { hideModal } = useGlobalModalContext();
  return (
    <div
      onClick={() => {
        modalState.setShouldBeShown({ state: false });
        hideModal();
      }}
      className="close pointer"
      aria-label="Close"
    >
      &times;
    </div>
  );
};

export default ModalCloseButton;
