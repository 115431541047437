import React from 'react';
import { Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import { StyledInfoBlockContainer } from './InfoBlockContainer.style';
import InfoBlock from 'components/InfoBlock/InfoBlock';
import { PrimaryButton } from 'components/Button';
import useOnboarding from 'helpers/useOnboarding';
import { useAccountQuery } from 'api/queries';
import { isWalletProviderObsolete } from 'helpers/enums/wallet/WalletProviderEnum';
import { useWalletQuery } from 'api/queries/useWalletQuery';
import useScope from 'helpers/useScope';
import RedirectToMainUserIdVerificationButton from '../../../global/pages/Authentication/Button/RedirectToMainUserIdVerificationButton';

const InfoBlockContainer: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const onBoarding = useOnboarding();
  const { data } = useAccountQuery();
  const { isCompany } = useScope();

  const { data: wallet } = useWalletQuery();

  const infoBlocks = [
    {
      condition:
        data?.is_document_expired !== null &&
        data?.is_document_expired !== undefined &&
        data.is_document_expired,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.document_expired.title')}
          description={t('info_block.document_expired.description')}
        />
      ),
    },
    {
      condition: data?.is_document_expiring_soon,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.document_expiring.title')}
          description={t('info_block.document_expiring.description')}
        >
          <Link to={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}>
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition: isCompany() && data?.main_user_verified === false,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.main_user_not_verified.title')}
          description={t('info_block.main_user_not_verified.description')}
        >
          <RedirectToMainUserIdVerificationButton />
        </InfoBlock>
      ),
    },
    {
      condition: !onBoarding.isAccredited,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.id_verification_company.title')}
          description={t('info_block.id_verification_company.description')}
        />
      ),
    },
    {
      condition: !onBoarding.isKnowYourBusinessFilled,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.kyb.title')}
          description={t('info_block.kyb.description')}
        >
          <Link to={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}>
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition:
        data?.is_contact_info_expired !== null &&
        data?.is_contact_info_expired !== undefined &&
        data.is_contact_info_expired,
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.contact_info_expired.title')}
          description={t('info_block.contact_info_expired.description')}
        >
          <Link to={RouteList.COMPANY.EDIT.CONTACT_INFO}>
            <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
          </Link>
        </InfoBlock>
      ),
    },
    {
      condition: isWalletProviderObsolete(wallet?.provider),
      component: (
        <InfoBlock
          color={'pink'}
          title={t('info_block.wallet_provider_not_supported.title')}
          description={t('info_block.wallet_provider_not_supported.description')}
        />
      ),
    },
  ];

  const getCurrentInfoBlock = () => {
    for (let i = 0; i < infoBlocks.length; i++) {
      if (infoBlocks[i].condition) {
        return infoBlocks[i].component;
      }
    }

    return null;
  };

  return <StyledInfoBlockContainer>{getCurrentInfoBlock()}</StyledInfoBlockContainer>;
};

export default InfoBlockContainer;
