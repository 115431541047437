import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import DataNotFoundBlock from 'components/DataNotFoundBlock';
import Loader from 'components/Loader';
import useTranslation from 'helpers/useTranslation';
import { emptyObject } from 'helpers/utils';
import useDisplay from 'helpers/useDisplay';
import { useAutoInvestmentData, StrategyStatisticsState } from '../AutoInvestmentProvider';
import InvestorPortfolioStackedBarChart from 'scopes/investor/pages/Dashboard/components/InvestorPortfolio/InvestorPortfolioStackedBarChart';
import InvestorPortfolioByCountry from 'scopes/investor/pages/Dashboard/components/InvestorPortfolio/InvestorPortfolioByCountry';
import InvestorPortfolioByLoanStatus from 'scopes/investor/pages/Dashboard/components/InvestorPortfolio/InvestorPortfolioByLoanStatus';

interface StrategyStatisticsProps {
  strategyId: string;
}

export const StrategyStatistics = React.memo(function StrategyStatistics({
  strategyId,
}: StrategyStatisticsProps) {
  const { t, tHtml } = useTranslation();
  const { isMobile } = useDisplay();
  const { statistics } = useAutoInvestmentData();
  const [data, setData] = React.useState<StrategyStatisticsState | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  React.useEffect(() => {
    if (statistics && statistics[strategyId]) setData(statistics[strategyId]);

    setIsLoading(false);
  }, [strategyId, statistics]);

  if (!statistics || isLoading)
    return (
      <div className="d-flex justify-content-center">
        <Loader />
      </div>
    );

  return data &&
    (data.activeAverageAnnualInterest ||
      data.averageWeightedRating ||
      !emptyObject(data.chartData)) ? (
    <div className={'strategy-statistics mt-3'}>
      <Row className={'g-3'}>
        {data.activeAverageAnnualInterest && data.averageWeightedRating && (
          <Col xs={12} lg={6}>
            <div className={'h-100'}>
              <Row className={'h-100'}>
                <Col xs={12}>
                  <div className={'card light-pink h-100 d-flex flex-row align-items-center'}>
                    <div>
                      <div className={'statistics-amount'}>
                        {t('common.percents', { value: data.activeAverageAnnualInterest })}
                      </div>
                      <div className={'statistics-amount-description'}>
                        {t('investments.auto_investment.statistics.active_average_annual_interest')}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} className="mt-0 mt-3">
                  <div className={'card light-pink h-100 d-flex flex-row align-items-center'}>
                    <div>
                      <div className={'statistics-amount'}>
                        {Math.round(data.averageWeightedRating * 100) / 100}
                      </div>
                      <div className={'statistics-amount-description'}>
                        {t('investments.auto_investment.statistics.average_weighted_rating')}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        )}
        {!emptyObject(data.chartData.by_country) && (
          <Col xs={12} lg={6}>
            <div className={'card light-pink'}>
              <div className={'h-100'}>
                <h3 className={'title'}>
                  {tHtml('investments.auto_investment.statistics.country')}
                </h3>
                <InvestorPortfolioByCountry
                  countryData={data.chartData.by_country}
                  amountColumn={'investment_total_amount'}
                />
              </div>
            </div>
          </Col>
        )}
        {!emptyObject(data.chartData.by_rating) && (
          <Col xs={12} lg={6}>
            <div className={'card light-pink h-100'}>
              <h3 className={'title'}>
                {tHtml('investments.auto_investment.statistics.initial_rating')}
              </h3>
              <InvestorPortfolioStackedBarChart
                ratingsData={data.chartData.by_rating}
                height={isMobile ? undefined : '100%'}
              />
            </div>
          </Col>
        )}
        {!emptyObject(data.chartData.by_loan_status) && (
          <Col xs={12} lg={6}>
            <div className={'card light-pink'}>
              <h3 className={'title'}>
                {tHtml('investments.auto_investment.statistics.loan_status')}
              </h3>
              <InvestorPortfolioByLoanStatus
                investmentTypesData={data.chartData.by_loan_status}
                amountColumn={'investment_total_amount'}
              />
            </div>
          </Col>
        )}
      </Row>
    </div>
  ) : (
    <DataNotFoundBlock label={tHtml('investments.auto_investment.statistics.not_found')} />
  );
});
