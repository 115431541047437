import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { Cell, Column } from 'react-table';
import { AmountFilter, DateFilter } from 'components/Table/Table';
import TableOverview from 'components/Table/TableOverview';

import { PaginationData, PaginationDataFilter } from 'api/types';
import { RouteList } from 'routes';
import Api from 'api';
import SectionFaq from './components/SectionFaq';
import { success, warning } from 'services/toastr';
import WalletAmount from 'components/Wallet/WalletAmount';
import WithdrawButton from 'components/Wallet/WithdrawButton';
import TopUpButton from 'components/Wallet/TopUpButton';
import WalletOperationStatusBadge from 'components/Badge/WalletOperationStatusBadge';
import { OperationsResponseDto } from 'api/types/shared/wallet';
import { TableInitialSortBy } from 'api/types/common';
import WalletOperationAmountBadge from 'components/Badge/WalletOperationAmountBadge';
import WalletOperationTypeBadge from 'components/Badge/WalletOperationTypeBadge';
import useUserType from 'helpers/useUserType';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import { downloadXMLFile, downloadCSVFile, downloadXLSXFile } from 'services/media';
import { useWalletQuery } from 'api/queries/useWalletQuery';

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'executed_at',
    desc: true,
  },
];

const Overview: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const [data, setData] = useState<PaginationData<OperationsResponseDto>>();
  const [paginationRequest, setPaginationRequest] = useState<PaginationDataFilter | undefined>();
  const { isInvestor, isDeveloper } = useUserType();
  const { data: wallet } = useWalletQuery();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const status = urlParams.get('status');

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t(
          isInvestor()
            ? 'table.investor_wallet_transaction_type'
            : 'table.developer_wallet_transaction_type',
        ),
        accessor: 'type',
        sortType: 'string',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return (
            <WalletOperationTypeBadge
              type={row.type}
              private_data={row.private_data}
              amount={row.amount}
            />
          );
        },
      },
      {
        Header: t('table.executed_at'),
        accessor: 'executed_at',
        sortType: '',
        Filter: DateFilter,
        Cell: (cell: Cell<OperationsResponseDto>) => (
          <span>{tHtml('common.date_full', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return <WalletOperationStatusBadge status={row.status} />;
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return <WalletOperationAmountBadge amount={row.amount} is_receiver={row.is_receiver} />;
        },
        Filter: AmountFilter,
        filterProps: {
          start: -1000,
          end: 50000,
          ignoreLimits: true,
        },
      },
    ],
    [isInvestor, t, tHtml],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setPaginationRequest(request);
      return request && Api.wallet.operations(request).then((response) => setData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const exportCsv = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.wallet.exportOperations(CommonDocumentTypeEnum.CSV, request);
    await downloadCSVFile(response.url, 'operations');
  };

  const exportExcel = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.wallet.exportOperations(CommonDocumentTypeEnum.XLSX, request);
    await downloadXLSXFile(response.url, 'operations');
  };

  const exportXml = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.wallet.exportOperations(CommonDocumentTypeEnum.XML, request);
    await downloadXMLFile(response.url);
  };

  useEffect(() => {
    if (status == 'cancel') {
      warning(tHtml('wallet.top_up_cancel'));
    }

    if (status == 'error') {
      warning(tHtml('wallet.top_up_failed'));
    }

    if (status == 'success') {
      success(tHtml('wallet.top_up_success'));
    }
  }, [status, t, tHtml]);

  return (
    <div className={'page-layout wallet'}>
      <div className={'panel mb-6'}>
        <Card body className={'light-pink balance'}>
          <Row className={'balance-items'}>
            <Col lg={8} md={6} className={'balance-information wallet-overview'}>
              <WalletAmount />
              <div className={'balance-description'}>{tHtml('wallet.account_balance')}</div>
              {isDeveloper() && wallet && wallet.reserved_amount > 0 && (
                <div className={'text-muted'}>
                  {tHtml('wallet.reserved_amount')}{' '}
                  {tHtml('common.money', { value: wallet.reserved_amount })}
                </div>
              )}
            </Col>
            <Col lg={4} md={6} className={'balance-buttons'}>
              <WithdrawButton />
              <TopUpButton />
            </Col>
            {isDeveloper() && (
              <div className="text-muted text-muted-important small text-right mt-3">
                {tHtml('wallet.developer_top_up_withdraw_note')}
              </div>
            )}
            {isInvestor() && (
              <div className="text-muted text-muted-important small text-right mt-3">
                {tHtml('wallet.investor_top_up_withdraw_note')}
              </div>
            )}
          </Row>
        </Card>
      </div>
      <TableOverview
        title={tHtml('wallet.operations')}
        columns={columns}
        link={RouteList.SHARED.WALLET.TRANSACTIONS}
        data={data}
        fetchData={fetchData}
        minimizeCard={true}
        initialSortBy={INITIAL_SORT_ORDER}
        exportLinks={[
          {
            onClick: exportCsv,
            request: paginationRequest,
            type: CommonDocumentTypeEnum.CSV,
          },
          {
            onClick: exportExcel,
            request: paginationRequest,
            type: CommonDocumentTypeEnum.XLSX,
          },
          {
            onClick: exportXml,
            request: paginationRequest,
            type: CommonDocumentTypeEnum.XML,
          },
        ]}
      />
      <div className={'wallet-faq-wrapper'}>
        <SectionFaq />
      </div>
    </div>
  );
};

export default withRouter(Overview);
