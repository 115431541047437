import Client from '../../client';
import Http from '../../../http';
import {
  CompanyProfileResponseDto,
  InvestorKybDataV2RequestDto,
  UpdateCompanyProfileContactInfoRequestDto,
  UpdateCompanyProfileMarketingSettingsRequestDto,
  ProjectDeveloperKybDataV2RequestDto,
} from '../../../types/shared/company/profile';
import { SignUploadRequestDto } from '../../../types/app';
import {
  FinancialRiskCalculatorRequestDto,
  FinancialRiskCalculatorResponseDto,
} from '../../../types/shared/user/profile';

class SharedCompanyProfile extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      PROFILE: this.protectedUrl(`${this.http.baseUrl}/company/profile`),
      STORE_PROFILE_MARKETING_SETTINGS: this.protectedUrl(
        `${this.http.baseUrl}/company/profile/marketing-settings`,
      ),
      STORE_PROFILE_CONTACT_INFO: this.protectedUrl(
        `${this.http.baseUrl}/company/profile/contact-info`,
      ),
      STORE_COMPANY_KYB_PROJECT_DEVELOPER: this.protectedUrl(
        `${this.http.baseUrl}/company/profile/kyb/project-developer`,
      ),
      STORE_COMPANY_KYB_INVESTOR: this.protectedUrl(
        `${this.http.baseUrl}/company/profile/kyb/investor`,
      ),
      UPLOAD_COMPANY_DOCUMENTS: this.protectedUrl(`${this.http.baseUrl}/company/profile/documents`),
      PROFILE_FINANCIAL_RISK_CALCULATOR: this.protectedUrl(
        `${this.http.baseUrl}/company/profile/financial-risk-calculator`,
      ),
      PROFILE_FINANCIAL_RISK_CALCULATOR_CALCULATE: this.protectedUrl(
        `${this.http.baseUrl}/company/profile/financial-risk-calculator/calculate`,
      ),
    };
  }

  fetchCompanyProfile = (): Promise<CompanyProfileResponseDto> => {
    return this.http.get(this.api.PROFILE);
  };

  storeUserProfileMarketingSettings = (
    data: UpdateCompanyProfileMarketingSettingsRequestDto,
  ): Promise<CompanyProfileResponseDto> => {
    return this.http.put(this.api.STORE_PROFILE_MARKETING_SETTINGS, data);
  };

  storeUserProfileContactInfo = (
    data: UpdateCompanyProfileContactInfoRequestDto,
  ): Promise<CompanyProfileResponseDto> => {
    return this.http.put(this.api.STORE_PROFILE_CONTACT_INFO, data);
  };

  storeCompanyProjectDeveloperKYB = (
    companyId: string,
    payload: ProjectDeveloperKybDataV2RequestDto,
  ): Promise<string | never> => {
    const url = this.buildUrl(this.api.STORE_COMPANY_KYB_PROJECT_DEVELOPER, { companyId });

    return this.http.post(url, payload);
  };

  storeCompanyInvestorKYB = (
    companyId: string,
    payload: InvestorKybDataV2RequestDto,
  ): Promise<string | never> => {
    const url = this.buildUrl(this.api.STORE_COMPANY_KYB_INVESTOR, { companyId });

    return this.http.post(url, payload);
  };

  uploadCompanyDocuments = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.post(this.api.UPLOAD_COMPANY_DOCUMENTS, request);
  };

  getFinancialRiskCalculatorSubmit = (): Promise<FinancialRiskCalculatorResponseDto> => {
    return this.http.get(this.api.PROFILE_FINANCIAL_RISK_CALCULATOR, {
      ignoreException: true,
    });
  };

  calculateFinancialRisk = (
    data: FinancialRiskCalculatorRequestDto,
  ): Promise<FinancialRiskCalculatorResponseDto> => {
    return this.http.post(this.api.PROFILE_FINANCIAL_RISK_CALCULATOR_CALCULATE, data);
  };

  calculateAndStoreFinancialRisk = (
    data: FinancialRiskCalculatorRequestDto,
  ): Promise<FinancialRiskCalculatorResponseDto> => {
    return this.http.post(this.api.PROFILE_FINANCIAL_RISK_CALCULATOR, data);
  };
}

export default SharedCompanyProfile;
