import React, { useRef } from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import { FunctionalButton } from 'components/Button';
import useOnScreen from 'helpers/useOnScreen';
import ProjectMinimized from './ProjectMinimized';
import ProjectTop from './ProjectTop';
import { ProjectProvider } from 'components/Project';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';
import ProjectInformation from 'components/Project/Authenticated/ProjectInformation';
import ProjectBody from 'components/Project/Authenticated/ProjectBody';

const ProjectWrapper: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();

  return (
    <ProjectProvider projectId={projectId} type={UserTypeEnum.PROJECT_DEVELOPER}>
      <Project />
    </ProjectProvider>
  );
};

const Project: React.FC = () => {
  const { tHtml } = useTranslation();
  const projectTopRef = useRef<HTMLDivElement>(null);
  const isProjectTopVisible: boolean = useOnScreen(projectTopRef);

  return (
    <div className={'project'}>
      <div className={'mb-3'}>
        <Link to={RouteList.PROJECT_DEVELOPER.PROJECTS.LIST}>
          <FunctionalButton>{tHtml('common.back')}</FunctionalButton>
        </Link>
      </div>
      <div ref={projectTopRef}>
        <ProjectTop />
      </div>
      <ProjectMinimized show={!isProjectTopVisible} />
      <ProjectInformation />
      <ProjectBody />
    </div>
  );
};

export default withRouter(ProjectWrapper);
