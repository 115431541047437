import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Column, Filters } from 'react-table';
import { Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import { generatePath, Link } from 'react-router-dom';

import { PaginationData, PaginationDataFilter } from 'api/types';
import Table from 'components/Table/Table';
import {
  SecondaryMarketProjectListResponseDto,
  SecondaryMarketTableFilter,
} from 'api/types/investor/secondary_market';
import { Label, PrimaryButton, TextInput } from 'components/Formik';
import { RouteList } from 'routes';
import { HighlightButton } from 'components/Button';
import MultiSelectProjectRatingInput from 'components/Formik/Selects/Investor/MultiSelectProjectRatingInput';
import InterestSelectInput from './InterestSelectInput';
import ProjectLoanActivityStatusBadge from '../../components/ProjectLoanActivityStatusBadge';
import ProjectThumbnail from './ProjectThumbnail';
import Api from 'api';
import CancelForSaleInvestmentModal from '../../components/CancelForSaleInvestmentModal';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';

const DEFAULT_FILTER: SecondaryMarketTableFilter = {
  initial_rating: [],
  yearly_interest: 0,
  project_id: '',
  project_name: '',
};

const SecondaryMarketInvestmentList: React.FC = () => {
  const { t, currentLanguage, tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const [projects, setProjects] = useState<PaginationData<SecondaryMarketProjectListResponseDto>>();
  const [filterRequest] = useState<SecondaryMarketTableFilter>(DEFAULT_FILTER);
  const [tableFilter, setTableFilter] = useState<Filters<any>>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        accessor: 'image_url',
        disableSortBy: true,
        Cell: (cell: any) => {
          return <ProjectThumbnail thumbnailUrl={cell.value} />;
        },
      },
      {
        Header: t('table.project_name'),
        accessor: 'project_id',
        sortType: 'number',
        canFilter: true,
        Cell: (cell: any) => {
          const row = cell.row.original;

          return (
            <div className={'text-start text-md-center'}>
              <span>{cell.value}</span>
              <br />
              <Link
                to={{
                  pathname: generatePath(
                    RouteList.INVESTOR.SECONDARY_MARKET.BUYER.SECONDARY_MARKET_INVESTMENT,
                    {
                      secondaryMarketInvestmentId: row.id,
                    },
                  ),
                }}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={'secondary-market-table-button'}
              >
                <span className={'text-highlight'}>{row.project_name}</span>
              </Link>
            </div>
          );
        },
      },
      {
        Header: t('table.initial_rating'),
        accessor: 'initial_rating',
        canFilter: true,
        sortType: 'string',
        Cell: (cell: any) => {
          return cell.value;
        },
      },
      {
        Header: t('table.investment_term_left'),
        accessor: 'term',
        sortType: '',
        Cell: (cell: any) => {
          const { total, passed } = cell.value;

          return t('common.days_interval', { passed: passed, total: total });
        },
      },
      {
        Header: t('table.amount_left'),
        accessor: 'loan_left_amount',
        sortType: 'number',
        Cell: (cell: any) => t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.interest'),
        accessor: 'yearly_interest',
        sortType: 'number',
        canFilter: true,
        Cell: (cell: any) => t('common.percents', { value: cell.value }),
      },
      {
        Header: t('table.receivable_amount_left'),
        accessor: 'receivable_amount_left',
        sortType: 'number',
        Cell: (cell: any) => t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.sale_price'),
        accessor: 'sale_price',
        sortType: 'number',
        Cell: (cell: any) => t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        Cell: (cell: any) => <ProjectLoanActivityStatusBadge type={cell.value} />,
      },
      {
        accessor: 'project_name', // Workaround, because project_id and project_name is on the same column
        width: 100,
        disableSortBy: true,
        Cell: (cell: any) => {
          const row = cell.row.original;
          if (row.is_personal) {
            return (
              <div className={'secondary-market-table-button'}>
                <HighlightButton
                  onClick={() =>
                    showModal(
                      <CancelForSaleInvestmentModal
                        secondaryMarketInvestmentId={row.id}
                        onCancel={() => {
                          cell.dispatch({ type: 'refreshData' });
                        }}
                      />,
                    )
                  }
                >
                  {tHtml('common.cancel')}
                </HighlightButton>
              </div>
            );
          }

          return (
            <Link
              to={{
                pathname: generatePath(
                  RouteList.INVESTOR.SECONDARY_MARKET.BUYER.SECONDARY_MARKET_INVESTMENT,
                  {
                    secondaryMarketInvestmentId: row.id,
                  },
                ),
              }}
              className={'secondary-market-table-button'}
            >
              <PrimaryButton className={'btn btn-primary'}>{tHtml('common.buy')}</PrimaryButton>
            </Link>
          );
        },
      },
    ],
    [showModal, t, tHtml],
  );

  useEffect(() => {
    setTableFilter(formatFilters(filterRequest));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatFilters = (request: SecondaryMarketTableFilter): Filters<any> => {
    return (Object.keys(request) as (keyof typeof request)[])
      .filter(
        (key) =>
          !Array.isArray(request[key]) ||
          (Array.isArray(request[key]) && (request[key] as Array<any>).length > 0),
      )
      .map((key) => ({
        id: key,
        value: request[key],
      }));
  };

  const onFilter = useCallback(async (request: SecondaryMarketTableFilter) => {
    setTableFilter(formatFilters(request));
  }, []);

  const fetchTableData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      await Api.investor.secondary_market
        .fetchAllForSaleInvestmentList(request)
        .then((response) => {
          setProjects(response);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage],
  );

  return (
    <div className={'mb-5'}>
      <div className={'d-flex justify-content-between align-items-center mb-3'}>
        <h2>{tHtml('secondary_market.title')}</h2>
        <Link to={RouteList.INVESTOR.SECONDARY_MARKET.SELLER.OVERVIEW}>
          <HighlightButton className={'btn btn-highlight secondary-market-link'}>
            {tHtml('secondary_market.sell_investments')}
          </HighlightButton>
        </Link>
      </div>
      <section>
        <Formik initialValues={filterRequest} onSubmit={onFilter}>
          {({ handleSubmit, setFieldValue, resetForm }) => (
            <Form onSubmit={handleSubmit}>
              <div className={'form-input mb-4'}>
                <Label
                  htmlFor={'initial_rating'}
                  title={tHtml('secondary_market.filter.initial_rating')}
                  isBold={true}
                />
                <MultiSelectProjectRatingInput
                  name={'initial_rating'}
                  setFieldValue={setFieldValue}
                />
              </div>
              <Row>
                <div className={'col-12 col-sm-12 col-md-6 form-input mb-4'}>
                  <Label
                    htmlFor={'yearly_interest'}
                    title={tHtml('secondary_market.filter.interest')}
                    isBold={true}
                  />
                  <InterestSelectInput name={'yearly_interest'} hideLabel={true} />
                </div>
                <div className={'col-12 col-sm-12 col-md-6 form-input mb-4'}>
                  <Label
                    htmlFor={'project_id'}
                    title={tHtml('secondary_market.filter.id')}
                    isBold={true}
                  />
                  <TextInput name={'project_id'} hideLabel={true} />
                </div>
              </Row>
              <Row>
                <div className={'form-input mb-4'}>
                  <Label
                    htmlFor={'project_name'}
                    title={tHtml('secondary_market.filter.project_name')}
                    isBold={true}
                  />
                  <TextInput name={'project_name'} hideLabel={true} />
                </div>
              </Row>

              <div className={'d-flex justify-content-end align-items-center'}>
                <PrimaryButton className={'btn btn-primary secondary-market-filter-button me-4'}>
                  {tHtml('secondary_market.filter.submit')}
                </PrimaryButton>
                <PrimaryButton className={'btn btn-borderless'} onClick={() => resetForm()}>
                  {tHtml('secondary_market.filter.reset')}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </section>
      <h2 className={'mb-4'}>{tHtml('secondary_market.projects')}</h2>
      <Table
        columns={columns}
        data={projects}
        onFetchData={fetchTableData}
        enableQueryFilter={true}
        externalFilters={tableFilter}
        mobile={true}
      />
    </div>
  );
};

export default SecondaryMarketInvestmentList;
