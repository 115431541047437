import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Cell, Column } from 'react-table';

import Api from 'api';
import { PaginationData, PaginationDataFilter } from 'api/types';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';
import { ProjectDeveloperAllProjectsResponse } from 'api/types/project-developer/project';
import TableExpandable from 'components/Table/TableExpandable';
import { TableInitialSortBy } from 'api/types/common';

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'pid',
    desc: true,
  },
];

const ProjectList: React.FC = () => {
  const { t, tHtml, currentLanguage } = useTranslation();
  const [data, setData] = useState<PaginationData<ProjectDeveloperAllProjectsResponse>>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.developer.project_list.pid'),
        accessor: 'pid',
        sortType: 'string',
      },
      {
        Header: t('table.title'),
        accessor: 'project_name',
        sortType: 'string',
        Cell: (cell: Cell<ProjectDeveloperAllProjectsResponse>) => {
          return <ProjectTitleLink title={cell.value} projectId={cell.row.original.id} />;
        },
      },
      {
        Header: t('table.developer_project_type'),
        accessor: 'type',
        sortType: 'string',
        Cell: (cell: Cell<ProjectDeveloperAllProjectsResponse>) => {
          return <span>{tHtml('application.type.' + cell.value)}</span>;
        },
      },
      {
        Header: t('table.status'),
        accessor: 'calculated_status',
        sortType: 'string',
        Cell: (cell: Cell<ProjectDeveloperAllProjectsResponse>) => {
          return <span>{tHtml('projects.status.' + cell.value)}</span>;
        },
      },
      {
        Header: t('table.required_amount'),
        accessor: 'required_amount',
        sortType: 'number',
        Cell: (cell: Cell<ProjectDeveloperAllProjectsResponse>) => {
          return <span>{tHtml('common.money', { value: cell.value })}</span>;
        },
      },
      {
        Header: t('table.total_interest'),
        accessor: 'basic_interest',
        sortType: 'string',
        Cell: (cell: Cell<ProjectDeveloperAllProjectsResponse>) => {
          return (
            <span className={'fw-600'}>{tHtml('common.percents', { value: cell.value })}</span>
          );
        },
      },
    ],
    [t, tHtml],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.projectDeveloper.projects
        .filterDeveloperProjectsList(request)
        .then((response) => setData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage],
  );

  return (
    <div>
      <div className={'mt-6'}>
        <div>
          <TableExpandable
            title={tHtml('projects.developer.project_list.title')}
            columns={columns}
            data={data}
            onFetchData={fetchData}
            showPageSizes={false}
            initialSortBy={INITIAL_SORT_ORDER}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectList;
