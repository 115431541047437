import React, { ReactElement } from 'react';
import { Collapse } from 'reactstrap';

import { IconArrowDown, IconArrowUp } from 'components/Icons';
import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';
import useProfileMenuState from '../pages/Profile/ProfileMenuState';
import IdReverificationButton from '../pages/Profile/IdReverificationButton';
import EditLinkAction from './EditLinkAction';

interface Props {
  menu: UserMenuEnum;
  title: string | ReactElement;
  buttonLink?: string;
}

const UserProfileSection: React.FC<Props> = ({ children, menu, title, buttonLink }) => {
  const { toggleProfileMenu, isOpen } = useProfileMenuState();

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div
          className={'d-flex w-100 justify-content-between align-items-center'}
          onClick={() => toggleProfileMenu(menu)}
        >
          <h2 className={'pointer me-2'}>{title}</h2>
          <div>
            {menu === UserMenuEnum.IdVerification && <IdReverificationButton />}
            <div className="inline">
              {isOpen(menu) ? (
                <IconArrowUp classes={'icon-dropdown'} />
              ) : (
                <IconArrowDown classes={'icon-dropdown'} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Collapse isOpen={isOpen(menu)}>
        <div className={'mt-3 mt-lg-4'}>
          <div className={'profile-section flex-column-reverse flex-md-row'}>
            <div>{children}</div>
            {buttonLink && <EditLinkAction buttonLink={buttonLink} />}
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default UserProfileSection;
