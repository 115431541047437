import { create } from 'zustand';
import { UUID } from 'helpers';
import { CodeValidation } from 'api/types/investor/project';

interface Actions {
  reset: () => void;

  hardReset: () => void;

  getId: () => string;

  setBonusAmount: (amount: number | undefined | null) => void;

  setBonusPercentage: (percentage: number | undefined | null) => void;

  setTotalAmount: (total: number | undefined | null) => void;

  setTotalInterest: (total: number | undefined | null) => void;

  setCodes: (codes: Array<string> | undefined) => void;

  setIsSellable: (is_sellable: boolean | undefined | null) => void;

  setCodeValidations: (validations: CodeValidation[]) => void;
}

interface State {
  bonus_amount: number | undefined;
  bonus_interest: number | undefined;
  total_amount: number | undefined;
  total_interest: number | undefined;
  codes: Array<string>;
  is_sellable: boolean;
  id: string;
  code_validations: CodeValidation[];
}

const initialState: State = {
  bonus_amount: undefined,
  bonus_interest: undefined,
  codes: [],
  total_amount: undefined,
  total_interest: undefined,
  is_sellable: true,
  id: UUID(),
  code_validations: [],
};

const useInvestmentModalBonusStore = create<State & Actions>((set, get) => ({
  ...initialState,

  reset: () => {
    set(initialState);
  },

  hardReset: () => {
    set(() => ({
      ...initialState,
      id: UUID(),
    }));
  },

  setBonusAmount: (amount) =>
    set((s) => ({
      ...s,
      bonus_amount: amount ?? undefined,
    })),

  getId: () => {
    return get().id;
  },

  setBonusPercentage: (percentage) =>
    set((s) => ({
      ...s,
      bonus_interest: percentage ?? undefined,
    })),

  setCodeValidations: (validations) =>
    set((s) => ({
      ...s,
      code_validations: validations,
    })),

  setTotalAmount: (amount) =>
    set((s) => ({
      ...s,
      total_amount: amount ?? undefined,
    })),

  setTotalInterest: (interest) =>
    set((s) => ({
      ...s,
      total_interest: interest ?? undefined,
    })),

  setCodes: (codes) =>
    set((s) => ({
      ...s,
      codes: codes ?? [],
    })),

  setIsSellable: (is_sellable) =>
    set((s) => ({
      ...s,
      is_sellable: is_sellable ?? undefined,
    })),
}));

export default useInvestmentModalBonusStore;
