import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import CheckboxBlock from 'components/CheckboxBlock';
import Flag from 'components/Flag';
import UserProfileSection from 'scopes/user/components/UserProfileSection';
import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import { UserMarketingSettingTypeEnum } from 'helpers/enums/UserMarketingSettingTypeEnum';
import {
  CALL_MARKETING_SETTINGS,
  EMAIL_MARKETING_SETTINGS,
  SMS_MARKETING_SETTINGS,
} from 'helpers/constants/UserMarketingSettingTypes';

const MarketingSettings: React.FC = () => {
  const { tHtml, currentLanguage } = useTranslation();

  const { data: userProfileData } = useUserProfileQuery();

  return (
    <UserProfileSection
      menu={UserMenuEnum.MarketingSettings}
      title={tHtml('menu.marketing_settings')}
      buttonLink={RouteList.USER.EDIT.MARKETING_SETTINGS}
    >
      {userProfileData?.marketing_settings && (
        <>
          {userProfileData?.marketing_settings?.filter((setting) =>
            EMAIL_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
          ).length > 0 && (
            <div className="marketing-settings mb-4">
              <div className={'profile-marketing-settings--header'}>
                {tHtml('profile.notifications.email')}
              </div>
              <div className={'profile-marketing-settings--body'}>
                {userProfileData?.marketing_settings
                  ?.filter((setting) =>
                    EMAIL_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
                  )
                  .map((setting, index) => {
                    return (
                      <div key={`email-marketing-settings-${index}`}>
                        <CheckboxBlock
                          label={tHtml(setting.name ?? '--')}
                          checked={setting.via_email}
                          readonly={true}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {userProfileData?.marketing_settings?.filter((setting) =>
            CALL_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
          ).length > 0 && (
            <div className="marketing-settings mb-4">
              <div className={'profile-marketing-settings--header'}>
                {tHtml('profile.notifications.call')}
              </div>
              <div className={'profile-marketing-settings--body'}>
                {userProfileData?.marketing_settings
                  ?.filter((setting) =>
                    CALL_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
                  )
                  .map((setting, index) => {
                    return (
                      <div key={`call-marketing-settings-${index}`}>
                        <CheckboxBlock
                          label={tHtml(setting.name ?? '--')}
                          checked={setting.via_phone}
                          readonly={true}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {userProfileData?.marketing_settings?.filter((setting) =>
            SMS_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
          ).length > 0 && (
            <div className="marketing-settings mb-4">
              <div className={'profile-marketing-settings--header'}>
                {tHtml('profile.notifications.sms')}
              </div>
              <div className={'profile-marketing-settings--body'}>
                {userProfileData?.marketing_settings
                  ?.filter((setting) =>
                    SMS_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
                  )
                  .map((setting, index) => {
                    return (
                      <div key={`sms-marketing-settings-${index}`}>
                        <CheckboxBlock
                          label={tHtml(setting.name ?? '--')}
                          checked={setting.via_sms}
                          readonly={true}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </>
      )}
      <hr />
      <div className={'mb-4'}>
        <span className={'text me-2'}>
          <strong>{tHtml('profile.notifications.receive_information_language')}</strong>
        </span>
        <Flag
          countryCode={userProfileData?.user?.channel_notification_language ?? currentLanguage}
        />
      </div>
    </UserProfileSection>
  );
};

export default withRouter(MarketingSettings);
