import Client from '../client';
import Http from '../../http';
import { PaginationData, PaginationDataFilter, PaginationDataFilterWithTypes } from '../../types';
import {
  InvestorDocumentResponseDto,
  InvestorExpandableDocumentResponseDto,
} from '../../types/investor/document';

class InvestorDocument extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FILTER_PERSONAL_DOCUMENTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/documents/personal/filter`,
      ),
      FILTER_PROJECT_DOCUMENTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/documents/projects/filter`,
      ),
      FETCH_TERMS_AND_CONDITIONS: this.protectedUrl(
        `${this.http.baseUrl}/investor/documents/terms-and-conditions`,
      ),
      ACCEPT_TERMS_AND_CONDITIONS: this.protectedUrl(
        `${this.http.baseUrl}/investor/documents/terms-and-conditions/accept`,
      ),
    };
  }

  filterPersonalDocuments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestorDocumentResponseDto>> => {
    return this.http.get(this.api.FILTER_PERSONAL_DOCUMENTS, { params: request });
  };

  filterProjectDocuments = (
    request: PaginationDataFilter | undefined = undefined,
    selectedTableOption: string,
  ): Promise<PaginationData<InvestorExpandableDocumentResponseDto>> => {
    const modifiedRequest: PaginationDataFilterWithTypes = {
      ...request,
      type: selectedTableOption,
    };

    return this.http.get(this.api.FILTER_PROJECT_DOCUMENTS, { params: modifiedRequest });
  };

  fetchTermsAndConditions = (): Promise<InvestorDocumentResponseDto> => {
    return this.http.get(this.api.FETCH_TERMS_AND_CONDITIONS);
  };

  acceptTermsAndConditions = (): Promise<any> => {
    return this.http.post(this.api.ACCEPT_TERMS_AND_CONDITIONS);
  };
}

export default InvestorDocument;
