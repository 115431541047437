import { default as env } from 'env.json';

export const RouteList = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  NOT_FOUND: '/404',
  NOTIFICATIONS: {
    LIST: '/notifications',
  },

  SHARED: {
    PROFILE: {
      HOME: '/profile',
    },
    WALLET: {
      OVERVIEW: '/my-wallet',
      WITHDRAW: '/withdraw',
      TOP_UP: '/top-up',
      TRANSACTIONS: '/my-wallet/transactions',
      RECENT_TRANSACTIONS: '/wallet/recent-transactions',
      PAYMENTS: {
        SUCCESS: '/wallet/payments/success',
        CANCEL: '/wallet/payments/cancel',
        ERROR: '/wallet/payments/error',
      },
    },
  },

  INVESTOR: {
    PROJECTS: {
      LIST: '/invest/primary-market',
      VIEW: '/invest/primary-market/:projectId',
    },
    SECONDARY_MARKET: {
      BUYER: {
        OVERVIEW: '/invest/secondary-market',
        SECONDARY_MARKET_INVESTMENT: '/invest/secondary-market/:secondaryMarketInvestmentId',
      },
      SELLER: {
        OVERVIEW: '/sale/secondary-market',
        PROJECT_INVESTMENT: '/sale/secondary-market/:projectInvestmentId',
      },
    },
    DASHBOARD: {
      LIST: '/meta',
    },
    MY_INVESTMENTS: {
      LIST: '/my-investments',
      ACTIVE: '/my-investments/active',
      INACTIVE: '/my-investments/inactive',
      LATE: '/my-investments/late',
      SOLD: '/my-investments/sold',
      RECOVERING: '/my-investments/recovering',
      OTHER: '/my-investments/other',
      TRANSFERABLE: '/my-investments/transferable',
    },
    PAYOUTS: {
      UPCOMING_PAYMENTS: '/upcoming-payments',
      LATE_PAYMENTS: '/late-payments',
    },
    DOCUMENTS: {
      LIST: '/documents',
    },
    TRANSACTIONS: {
      LIST: '/transactions',
      ALL: '/transactions/all',
      INVESTMENT_REPAYMENT: '/transactions/investment-repayment',
      INVESTMENT: '/transactions/investment',
    },
    QUIZ: {
      VIEW: '/quiz',
    },
    FINANCIAL_RISK_CALCULATOR: {
      VIEW: '/financial-risk-calculator',
    },
    AFFILIATE: {
      VIEW: '/affiliate',
    },
    AUTO_INVESTMENT: {
      VIEW: '/auto-invest',
    },
    BONUS_SYSTEM: {
      VIEW: '/bonuses',
    },
  },

  PROJECT_DEVELOPER: {
    APPLICATIONS: {
      CREATE_APPLICATION: '/application/:type/:applicationId?',
      REFINANCING: '/application/:type/:applicationId/refinancing',
      PURCHASE: '/application/:type/:applicationId/purchase',
      DEVELOPMENT: '/application/:type/:applicationId/development',
      ESTIMATE: '/application/:type/:applicationId/estimate',
      SECURITY_DEPOSITS: '/application/:type/:applicationId/security-deposits',
      DOCUMENTS: '/application/:type/:applicationId/documents',
      REVIEW: '/application/:type/:applicationId/review',
      SUCCESS: '/application/:type/:applicationId/success',
      PROPOSAL: '/application/:applicationId/proposal',
    },
    PROJECTS: {
      LIST: '/invest/primary-market',
      VIEW: '/invest/primary-market/:projectId',
    },
    DOCUMENTS: {
      LIST: '/documents',
    },
    TRANSACTIONS: {
      LIST: '/transactions',
      ALL: '/transactions/all',
      LOAN_REPAYMENT: '/transactions/loan-repayment',
      LOAN_RECEIVE: '/transactions/loan-receive',
    },
    PAYMENTS: {
      VIEW: '/payments',
      PARTIAL_RETURN: '/payments/partial-return/:projectId',
    },
  },

  GUEST: {
    PROJECTS: {
      VIEW: '/invest/primary-market/:projectId',
    },
    AUTHENTICATION: {
      LOGIN: '/login',
      REGISTER: '/register',
      RESET_PASSWORD: '/reset-password',
      CONFIRM_RESET_PASSWORD: '/reset-password/:token',
      REGISTER_MAIL_VERIFICATION: '/register/mail-verification',
      REGISTER_DATA_FILLING: '/register/data-filling',
      REGISTER_ID_VERIFICATION: '/register/id-verification',
      REGISTER_SUCCESS: '/register/success',
    },
    PROSPECT_APPLICATION: {
      VIEW: '/prospect-application',
      CREATE_APPLICATION: '/prospect-application/create/:uuid?',
      CREDIT_DETAILS: '/prospect-application/:applicationId/credit-details',
      SECURITY_DEPOSITS: '/prospect-application/:applicationId/security-deposits',
      COMPANY_INFO: '/prospect-application/:applicationId/company-info',
      COMPANY_DETAILS: '/prospect-application/:applicationId/company-details',
      COMPANY_EXECUTIVE: '/prospect-application/:applicationId/company-executive',
      COMPANY_SHAREHOLDERS: '/prospect-application/:applicationId/company-shareholders',
      COMPANY_EXPERIENCE: '/prospect-application/:applicationId/company-experience',
      DOCUMENTS: '/prospect-application/:applicationId/documents',
      SIGNATURES: '/prospect-application/:applicationId/signatures',
      REVIEW: '/prospect-application/:applicationId/review',
      SUCCESS: '/prospect-application/:applicationId/success',
    },
  },

  GLOBAL: {
    AUTHENTICATION: {
      REGISTER_CONFIRM_EMAIL: '/register/mail-verification/:token',
      CONFIRM_EMAIL_CHANGE: '/mail/mail-verification/:token',
      IMPERSONATE: '/impersonate/:hash',
    },
    PROJECTS: {
      PREVIEW: '/invest/primary-market/:projectId/preview/:previewId',
    },
  },

  USER: {
    PROFILE: {
      HOME: '/profile',
      CONTACT_INFO: '/profile/#contact-info',
      ID_VERIFICATION: '/profile/#id-verification',
      KNOW_YOUR_CUSTOMER: '/profile/#know-your-customer',
      PASSWORD: '/profile/#password',
      MARKETING_SETTINGS: '/profile/#marketing-settings',
    },
    EDIT: {
      CONTACT_INFO: '/edit/account-information',
      ID_VERIFICATION: '/edit/id-verification',
      KNOW_YOUR_CUSTOMER: '/edit/kyc',
      PASSWORD: '/edit/password',
      MARKETING_SETTINGS: '/edit/account-settings',
    },
    CREATE_COMPANY: {
      COMPANY: '/add-company',
      KNOW_YOUR_BUSINESS: '/add-company/know-your-business',
      SUCCESS: '/add-company/success',
    },
  },

  COMPANY: {
    PROFILE: {
      HOME: '/profile',
      CONTACT_INFO: '/profile/#contact-info',
      KNOW_YOUR_BUSINESS: '/profile/#know-your-business',
      MARKETING_SETTINGS: '/profile/#marketing-settings',
    },
    EDIT: {
      CONTACT_INFO: '/profile/edit/account-information',
      KNOW_YOUR_BUSINESS: '/profile/edit/kyb',
      MARKETING_SETTINGS: '/profile/edit/account-settings',
    },
  },
};

export const LANDING_ROUTES: { [key: string]: { [key: string]: string } } = {
  lt: {
    HOME: env.LANDING_PAGE_URL_LT + '/',
    ABOUT_US: env.LANDING_PAGE_URL_LT + '/apie',
    AFFILIATE_PROGRAM: env.LANDING_PAGE_URL_LT + '/partnerystes-programa',
    RECOMMENDATION_PROGRAM: env.LANDING_PAGE_URL_LT + '/rekomendavimo-programa',
    INVEST: env.LANDING_PAGE_URL_LT + '/investavimas',
    HOW_TO_INVEST: env.LANDING_PAGE_URL_LT + '/kaip-investuoti',
    INVESTMENT_CALCULATOR: env.LANDING_PAGE_URL_LT + '/investavimo-skaiciuokle',
    BORROW: env.LANDING_PAGE_URL_LT + '/paskolos',
    HOW_TO_BORROW: env.LANDING_PAGE_URL_LT + '/kaip-skolintis',
    BUSINESS_CALCULATOR: env.LANDING_PAGE_URL_LT + '/verslo-paskolos-skaiciuokle',
    DOCUMENTS: env.LANDING_PAGE_URL_LT + '/dokumentai',
    RISKS: env.LANDING_PAGE_URL_LT + '/investavimo-rizikos',
    FEES: env.LANDING_PAGE_URL_LT + '/ikainiai',
    CONTACTS: env.LANDING_PAGE_URL_LT + '/kontaktai',
    PRIVACY_POLICY: env.LANDING_PAGE_URL_LT + '/privatumo-politika',
    FAQ: env.LANDING_PAGE_URL_LT + '/dazniausiai-uzduodami-klausimai',
    STATISTIC: env.LANDING_PAGE_URL_LT + '/statistika',
    INVESTMENT_RISKS: env.LANDING_PAGE_URL_LT + '/investavimo-rizikos',
    REFERRAL_PROGRAM_RULES:
      env.LANDING_PAGE_URL_LT + '/dokumentai/Rekomendavimo_programos_taisykles',
  },
  en: {
    HOME: env.LANDING_PAGE_URL_EN + '/',
    ABOUT_US: env.LANDING_PAGE_URL_EN + '/um',
    AFFILIATE_PROGRAM: env.LANDING_PAGE_URL_EN + '/affiliates',
    RECOMMENDATION_PROGRAM: env.LANDING_PAGE_URL_EN + '/referral',
    INVEST: env.LANDING_PAGE_URL_EN + '/invest',
    HOW_TO_INVEST: env.LANDING_PAGE_URL_EN + '/how-to-invest',
    INVESTMENT_CALCULATOR: env.LANDING_PAGE_URL_EN + '/investment-calculator',
    BORROW: env.LANDING_PAGE_URL_EN + '/loans',
    HOW_TO_BORROW: env.LANDING_PAGE_URL_EN + '/how-to-get-a-loan',
    BUSINESS_CALCULATOR: env.LANDING_PAGE_URL_EN + '/business-loan-calculator',
    DOCUMENTS: env.LANDING_PAGE_URL_EN + '/documents',
    RISKS: env.LANDING_PAGE_URL_EN + '/investment-risks',
    FEES: env.LANDING_PAGE_URL_EN + '/fees',
    CONTACTS: env.LANDING_PAGE_URL_EN + '/contacts',
    PRIVACY_POLICY: env.LANDING_PAGE_URL_EN + '/privacy-policy',
    FAQ: env.LANDING_PAGE_URL_EN + '/frequently-asked-questions',
    STATISTIC: env.LANDING_PAGE_URL_EN + '/statistics',
    INVESTMENT_RISKS: env.LANDING_PAGE_URL_EN + '/investment-risks',
    REFERRAL_PROGRAM_RULES: env.LANDING_PAGE_URL_EN + '/documents/Referral_program_rules',
  },
  de: {
    HOME: env.LANDING_PAGE_URL_DE + '/',
    ABOUT_US: env.LANDING_PAGE_URL_DE + '/um',
    AFFILIATE_PROGRAM: env.LANDING_PAGE_URL_DE + '/mitgliedsorganisationen',
    RECOMMENDATION_PROGRAM: env.LANDING_PAGE_URL_DE + '/verweisung',
    INVEST: env.LANDING_PAGE_URL_DE + '/investieren',
    HOW_TO_INVEST: env.LANDING_PAGE_URL_DE + '/wie-man-investiert',
    INVESTMENT_CALCULATOR: env.LANDING_PAGE_URL_DE + '/investitionsrechner',
    BORROW: env.LANDING_PAGE_URL_DE + '/leihen',
    HOW_TO_BORROW: env.LANDING_PAGE_URL_DE + '/wie-bekommt-man-einen-kredit',
    BUSINESS_CALCULATOR: env.LANDING_PAGE_URL_DE + '/firmenkreditrechner',
    DOCUMENTS: env.LANDING_PAGE_URL_DE + '/miete',
    RISKS: env.LANDING_PAGE_URL_DE + '/investitionsrisiken',
    FEES: env.LANDING_PAGE_URL_DE + '/gebuhr',
    CONTACTS: env.LANDING_PAGE_URL_DE + '/kontakte',
    PRIVACY_POLICY: env.LANDING_PAGE_URL_DE + '/datenschutz-bestimmungen',
    FAQ: env.LANDING_PAGE_URL_DE + '/haufig-gestellte-fragen',
    STATISTIC: env.LANDING_PAGE_URL_DE + '/statistiken',
    INVESTMENT_RISKS: env.LANDING_PAGE_URL_DE + '/investitionsrisiken',
    REFERRAL_PROGRAM_RULES: env.LANDING_PAGE_URL_DE + '/miete/Referral_program_rules',
  },
  ee: {
    HOME: env.LANDING_PAGE_URL_EE + '/',
    ABOUT_US: env.LANDING_PAGE_URL_EE + '/kohta',
    AFFILIATE_PROGRAM: env.LANDING_PAGE_URL_EE + '/partnerid',
    RECOMMENDATION_PROGRAM: env.LANDING_PAGE_URL_EE + '/viide',
    INVEST: env.LANDING_PAGE_URL_EE + '/investeerida',
    HOW_TO_INVEST: env.LANDING_PAGE_URL_EE + '/kuidas-investeerida',
    INVESTMENT_CALCULATOR: env.LANDING_PAGE_URL_EE + '/investeerimis-kalkulaator',
    BORROW: env.LANDING_PAGE_URL_EE + '/laenud',
    HOW_TO_BORROW: env.LANDING_PAGE_URL_EE + '/kuidas-saada-laenu',
    BUSINESS_CALCULATOR: env.LANDING_PAGE_URL_EE + '/arilaenu-kalkulaator',
    DOCUMENTS: env.LANDING_PAGE_URL_EE + '/dokumendid',
    RISKS: env.LANDING_PAGE_URL_EE + '/investeerimisriskid',
    FEES: env.LANDING_PAGE_URL_EE + '/tasud',
    CONTACTS: env.LANDING_PAGE_URL_EE + '/kontaktid',
    PRIVACY_POLICY: env.LANDING_PAGE_URL_EE + '/privaatsuspoliitika',
    FAQ: env.LANDING_PAGE_URL_EE + '/korduma-kippuvad-kusimused',
    STATISTIC: env.LANDING_PAGE_URL_EE + '/statistika',
    INVESTMENT_RISKS: env.LANDING_PAGE_URL_EE + '/investeerimisriskid',
    REFERRAL_PROGRAM_RULES: env.LANDING_PAGE_URL_EE + '/dokumendid/referral-program-rules',
  },
};
