import React from 'react';

import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import PageLayout from 'scopes/investor/containers/PageLayout';
import { AutoInvestment } from './index';
import { Permission } from 'helpers/auth/permissions';

const AutoInvestmentRoutes: any = () => [
  <AuthRoute
    path={RouteList.INVESTOR.AUTO_INVESTMENT.VIEW}
    component={AutoInvestment}
    layout={PageLayout}
    permissions={[Permission.ENABLED_AUTO_INVESTMENTS]}
    key={RouteList.INVESTOR.AUTO_INVESTMENT.VIEW}
    exact
  />,
];

export default AutoInvestmentRoutes;
