import { ProspectApplicationDto } from 'api/types/guest/prospectApplication';
import { handleActions } from 'redux-actions';

export const UPDATE_APPLICATION = 'PROSPECT_APPLICATION.UPDATE_APPLICATION';
export const INITIALIZE_APPLICATION = 'PROSPECT_APPLICATION.INITIALIZE_APPLICATION';

export interface ApplicationState {
  id?: number | string | null;
  application?: ProspectApplicationDto;
}

export const initialState: ApplicationState = {
  id: undefined,
  application: undefined,
};

export const ApplicationReducer = handleActions<ApplicationState, ProspectApplicationDto>(
  {
    [UPDATE_APPLICATION]: (
      state: ApplicationState,
      action: { payload: ProspectApplicationDto },
    ) => {
      const application = action.payload;
      const id = action.payload.prospect_application?.uuid;
      return {
        ...state,
        id,
        application,
      };
    },
    [INITIALIZE_APPLICATION]: (state: ApplicationState) => {
      const application: any = {
        prospect_user: null,
        prospect_application: null,
      };

      return {
        ...state,
        application: application,
      };
    },
  },
  initialState,
);
