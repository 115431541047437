import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { ModalBody, ModalHeader } from 'reactstrap';

import { ModalComponent, ModalComponentProps } from '../ModalComponent';
import { useGlobalModalContext } from '../GlobalModal';
import ModalCloseButton from '../ModalCloseButton';
import { HighlightButton } from '../../Button';
import { PrimaryButton } from '../../Formik';

interface Props extends ModalComponentProps {
  title: string | JSX.Element;
  onSubmit: () => Promise<void>;
  onClose?: () => void;
}

const ConfirmationModal: React.FC<Props> = ({ title, onSubmit, onClose = undefined, ...props }) => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <React.Fragment>
      <ModalComponent size={'md'} {...props} contentClassName={'modal-investment'}>
        <div className={'p-3'}>
          <ModalHeader
            toggle={() => {
              if (onClose) onClose();
              hideModal();
            }}
            close={<ModalCloseButton onClose={onClose} />}
            tag={'h2'}
            className={'mt-2'}
          >
            {title}
          </ModalHeader>
          <ModalBody>
            <div className={'w-100'}>
              <div className={'d-flex justify-content-around mt-3'}>
                <HighlightButton
                  className={'btn btn-highlight w-25 fw-normal me-3'}
                  onClick={() => {
                    if (onClose) onClose();
                    hideModal();
                  }}
                >
                  {tHtml('common.no')}
                </HighlightButton>
                <PrimaryButton
                  className={'btn btn-primary w-25 fw-normal'}
                  submitting={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    onSubmit().finally(() => {
                      setIsLoading(false);
                      hideModal();
                    });
                  }}
                >
                  {tHtml('common.yes')}
                </PrimaryButton>
              </div>
            </div>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ConfirmationModal;
