import React, { useEffect, useState } from 'react';
import { ArrayHelpers } from 'formik';
import { Card } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { DataBlock } from 'components/DataBlock';
import { FunctionalButton } from 'components/Button';
import { TextInput } from 'components/Formik';
import { StoreProspectUserCompanyPepKYBRequestDto } from 'api/types/shared/user/company';

const SinglePepForm: React.FC<{
  pep: StoreProspectUserCompanyPepKYBRequestDto;
  index: number;
  fieldName: string;
  formikHelpers: any;
  formikArrayHelpers: ArrayHelpers;
}> = ({ pep, index, fieldName, formikHelpers, formikArrayHelpers }) => {
  const { t, tHtml } = useTranslation();
  const [show, setShow] = useState<boolean>(true);

  const fullName = `${fieldName}[${index}].name`;
  const govCode = `${fieldName}[${index}].gov_code`;
  const responsibilities = `${fieldName}[${index}].responsibilities`;
  const relationWithManagerOrRepresentative = `${fieldName}[${index}].relation_with_manager_or_representative`;

  const setVisibility = async () => {
    const errors = await formikHelpers.validateForm();

    let show = false;
    if (errors != undefined && errors.peps != undefined && errors.peps[index] != undefined) {
      show = true;
    }

    setShow(show);
  };

  useEffect(() => {
    setVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    index,
    formikHelpers.validateForm,
    formikHelpers.submitCount,
    formikHelpers.values.peps.length,
  ]);

  if (!show) {
    return (
      <div className={'form-cards'}>
        <Card body className={'light-pink'}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <DataBlock title={tHtml('placeholder.company.pep.name')} data={pep.name} />
            <DataBlock title={tHtml('placeholder.company.pep.gov_code')} data={pep.gov_code} />
            <DataBlock
              title={tHtml('placeholder.company.pep.responsibilities')}
              data={pep.responsibilities}
            />
            <div className="buttons">
              <FunctionalButton onClick={() => setShow(true)}>
                {tHtml('common.edit')}
              </FunctionalButton>
              <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
                {tHtml('common.remove')}
              </FunctionalButton>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="sub-form">
      <div className={'mb-4'}>
        <TextInput placeholder={t('placeholder.company.pep.name')} name={fullName} />
      </div>
      <div className={'mb-4'}>
        <TextInput placeholder={t('placeholder.company.pep.gov_code')} name={govCode} />
      </div>
      <div className={'mb-4'}>
        <TextInput
          placeholder={t('placeholder.company.pep.responsibilities')}
          name={responsibilities}
        />
      </div>
      <div className={'mb-3'}>
        <TextInput
          placeholder={t('placeholder.company.pep.relation_with_manager_or_representative')}
          name={relationWithManagerOrRepresentative}
        />
      </div>
      <div className="mb-3">
        <div className="buttons">
          <FunctionalButton onClick={() => setVisibility()}>
            {tHtml('common.save')}
          </FunctionalButton>
          <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
            {tHtml('common.remove')}
          </FunctionalButton>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default SinglePepForm;
