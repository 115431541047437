import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';
import { AuthUtils } from '../services/useAuth/Auth.utils';
import { useCallback } from 'react';

const useUserType = () => {
  const isInvestor = useCallback((): boolean => {
    try {
      return !!AuthUtils.getPayload() && AuthUtils.getPayload()?.type == UserTypeEnum.INVESTOR;
    } catch (e) {
      AuthUtils.removeJwt();
      console.error('Failed to check if is investor');
    }

    return false;
  }, []);

  const isDeveloper = (): boolean => {
    try {
      return (
        !!AuthUtils.getPayload() && AuthUtils.getPayload()?.type == UserTypeEnum.PROJECT_DEVELOPER
      );
    } catch (e) {
      AuthUtils.removeJwt();
      console.error('Failed to check if is investor');
    }

    return false;
  };

  const isGuest = (): boolean => {
    try {
      return !AuthUtils.getPayload() || AuthUtils.getPayload()?.type == UserTypeEnum.GUEST;
    } catch (e) {
      AuthUtils.removeJwt();
      console.error('Failed to check if is investor');
    }

    return false;
  };

  return { isDeveloper, isInvestor, isGuest };
};

export default useUserType;
