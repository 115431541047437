import React from 'react';
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext,
} from 'formik';
import useTranslation from 'helpers/useTranslation';

import { FunctionalButton } from 'components/Button';
import { StoreProspectUserCompanyShareholderKYBRequestDto } from 'api/types/shared/user/company';
import SingleShareholderForm from './SingleShareholderForm';

interface Props {
  values: any;
  fieldName: string;
  formikHelpers: any;
}

const ShareholdersFormBlock: React.FC<Props> = ({ values, fieldName, formikHelpers }) => {
  const { tHtml } = useTranslation();
  const [, meta] = useField(fieldName);
  const formik = useFormikContext();
  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;

  const emptyShareholder: StoreProspectUserCompanyShareholderKYBRequestDto = {
    name: '',
    shares: 0,
    legal_code: '',
    traded_securities_on_eu_exchanges: false,
    country: '',
  };

  return (
    <div className={meta.error ? 'is-invalid' : ''}>
      {isError && (
        <div className={'invalid-feedback-visible ms-2'}>
          {typeof meta.error === 'string' && <ErrorMessage name={fieldName} />}
        </div>
      )}
      <FieldArray
        name={fieldName}
        render={(arrayHelpers: FieldArrayRenderProps) => {
          const addShareholder = () => {
            arrayHelpers.push({ ...emptyShareholder });
          };

          return (
            <>
              <>
                {values.map(
                  (
                    shareholder: StoreProspectUserCompanyShareholderKYBRequestDto,
                    index: number,
                  ) => (
                    <SingleShareholderForm
                      shareholder={shareholder}
                      key={index}
                      index={index}
                      fieldName={fieldName}
                      formikHelpers={formikHelpers}
                      formikArrayHelpers={arrayHelpers}
                    />
                  ),
                )}
              </>
              <div className={'mt-4 mb-4'}>
                <FunctionalButton
                  type={'button'}
                  onClick={async () => {
                    addShareholder();
                  }}
                >
                  {tHtml('common.add_with_sign_short')}
                </FunctionalButton>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default ShareholdersFormBlock;
