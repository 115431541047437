import React, { ReactElement } from 'react';
import { Row, Col, ColProps } from 'reactstrap';

import Tooltip from 'components/Tooltip';
import useTranslation from 'helpers/useTranslation';

interface BlockDataInterface {
  iconClass: string;
  title: string | ReactElement;
  body: string | ReactElement;
}

const BlockData: React.FC<BlockDataInterface> = ({ iconClass, title, body }) => (
  <div className={'block'}>
    <div className={'icon-block'}>
      <i className={`icon ${iconClass}`} />
    </div>
    <h3>{title}</h3>
    <p>{body}</p>
  </div>
);

const AffiliatePromotionBlock: React.FC = () => {
  const { tHtml } = useTranslation();

  const colProps: ColProps = {
    sm: 12,
    md: 12,
    lg: 4,
  };

  return (
    <section className={'promotion-block'}>
      <h2>
        {tHtml('affiliate.headlines.promotion_tiers')}
        <i className={'icon icon-info'} id={'invite-tooltip-icon'} />
        <Tooltip id={'invite-tooltip-icon'} text={tHtml('affiliate.tooltips.invite_tier')} />
      </h2>
      <p className={'promotion-block-body-text'}>
        {tHtml('affiliate.descriptions.promotion_tiers')}
        <a href="#affiliate-tier-program" className="mx-2">
          {tHtml('affiliate.descriptions.promotion_tiers_last_word')}
        </a>
      </p>
      <Row className={'promotion-block-icons'}>
        <Col {...colProps}>
          <BlockData
            iconClass={'icon-chat'}
            title={tHtml('affiliate.headlines.send_invite')}
            body={tHtml('affiliate.descriptions.send_invite')}
          />
        </Col>
        <Col {...colProps}>
          <BlockData
            iconClass={'icon-user-add'}
            title={tHtml('affiliate.headlines.wait_for_registration')}
            body={tHtml('affiliate.descriptions.wait_for_registration')}
          />
        </Col>
        <Col {...colProps}>
          <BlockData
            iconClass={'icon-euro'}
            title={tHtml('affiliate.headlines.profit')}
            body={tHtml('affiliate.descriptions.profit')}
          />
        </Col>
      </Row>
    </section>
  );
};

export default AffiliatePromotionBlock;
