import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';

export const CellWrapper = styled.div`
  max-height: 2.5rem;
  padding-bottom: 3.5rem;

  ${breakpointDown.XXL} {
    padding-bottom: 4.5rem;

    .invalid-feedback {
      font-size: 0.625rem;
    }
  }
`;
