import React from 'react';
import useTranslation from 'helpers/useTranslation';

import { ProjectInvestmentContextState, withProjectInvestment } from '../ProjectInvestmentProvider';
import DataBlockBetween from 'components/DataBlock/DataBlockBetween/DataBlockBetween';

type Props = ProjectInvestmentContextState;

const ProjectTopData: React.FC<Props> = ({ projectInvestment }) => {
  const { tHtml } = useTranslation();

  if (!projectInvestment) return <></>;

  return (
    <>
      <div className={'mb-4'}>
        <DataBlockBetween
          title={tHtml('secondary_market.project.sell_investment.receivable_amount_left')}
          content={tHtml('common.money', { value: projectInvestment.receivable_amount_left })}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.sell_investment.term')}
          content={tHtml('common.days_interval', {
            passed: projectInvestment.term.passed,
            total: projectInvestment.term.total,
          })}
          tooltipId={'term'}
          tooltipText={tHtml('secondary_market.tooltip.term')}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.sell_investment.interest_amount_received')}
          content={tHtml('common.money', { value: projectInvestment.interest_amount_received })}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.sell_investment.yearly_interest')}
          content={tHtml('common.percents', { value: projectInvestment.yearly_interest })}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.sell_investment.loan_returned')}
          content={tHtml('common.money', { value: projectInvestment.loan_returned })}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.sell_investment.status')}
          content={tHtml(`secondary_market.status.${projectInvestment.status}`)}
        />
        <DataBlockBetween
          title={tHtml('secondary_market.project.sell_investment.loan_left_amount')}
          content={tHtml('common.money', { value: projectInvestment.loan_left_amount })}
        />
      </div>
    </>
  );
};

export default withProjectInvestment(ProjectTopData);
