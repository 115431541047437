import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Cell, Column, UseTableCellProps } from 'react-table';
import useTranslation from 'helpers/useTranslation';
import { Link } from 'react-router-dom';

import { RouteList } from 'routes';
import { PaginationData, PaginationDataFilter } from 'api/types';
import {
  InvestmentDocumentResponseDto,
  InvestorInvestmentListResponseDto,
  InvestorLateInvestmentListResponseDto,
  InvestorOtherInvestmentListResponseDto,
} from 'api/types/investor/investment';
import TableOverview from 'components/Table/TableOverview';
import { AmountFilter, DateFilter } from 'components/Table/Table';
import { PageHeader } from 'components/Header';
import { PrimaryButton } from 'components/Button';
import Api from 'api';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';
import { BonusAmountTooltip } from './Tooltips/BonusAmountTooltip';
import { BonusPercentageTooltip } from './Tooltips/BonusPercentageTooltip';
import { AutomaticInvestmentTooltip } from './Tooltips/AutomaticInvestmentTooltip';
import { resolveTranslatableValueDto, useDifferenceBetweenTwoDatesToString } from 'helpers/utils';
import { TableInitialSortBy } from 'api/types/common';
import { NotSellableInvestmentTooltip } from './Tooltips/NotSellableInvestmentTooltip';
import { useCalculateCreditDuration } from './helpers/calculateCreditDuration';
import usePermissions from 'helpers/usePermissions';
import { Permission } from 'helpers/auth/permissions';
import Expander from 'components/Table/Expander';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  ACTIVE as OPTION_ACTIVE,
  INACTIVE as OPTION_INACTIVE,
  LATE as OPTION_LATE,
  SOLD as OPTION_SOLD,
  RECOVERING as OPTION_RECOVERING,
  OTHER as OPTION_OTHER,
  TRANSFERABLE,
} from 'helpers/constants/InvestmentTypes';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import { ProjectInvestmentStatusEnum } from 'helpers/enums/ProjectInvestmentTypeEnum';
import { error } from 'services/toastr';
import { downloadXMLFile, downloadXLSXFile, downloadCSVFile } from 'services/media';
import RefundInvestment from './helpers/RefundInvestment';
import TransferInvestment from './helpers/TransferInvestment';
import { TransferTypeInvestmentTooltip } from './Tooltips/TransferTypeInvestmentTooltip';

const OPTION_ALL = ProjectInvestmentStatusEnum.ALL;

const TABLE_OPTIONS = [
  ProjectInvestmentStatusEnum.ACTIVE,
  ProjectInvestmentStatusEnum.INACTIVE,
  ProjectInvestmentStatusEnum.LATE,
  ProjectInvestmentStatusEnum.SOLD,
  ProjectInvestmentStatusEnum.RECOVERING,
  ProjectInvestmentStatusEnum.OTHER,
  ProjectInvestmentStatusEnum.TRANSFERABLE,
  ProjectInvestmentStatusEnum.ALL,
];

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'invested_at',
    desc: true,
  },
];

const MyInvestments: React.FC = () => {
  const { t, currentLanguage, tHtml } = useTranslation();
  const calculateCreditDuration = useCalculateCreditDuration();
  const differenceBetweenTwoDatesToString = useDifferenceBetweenTwoDatesToString();

  const p = usePermissions();

  const [selectedTableOption, setSelectedTableOption] = useState<string>(OPTION_ACTIVE);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [activeInvestments, setActiveInvestments] =
    useState<PaginationData<InvestorInvestmentListResponseDto>>();
  const [activeInvestmentsPaginationRequest, setActiveInvestmentsPaginationRequest] = useState<
    PaginationDataFilter | undefined
  >();

  const [inactiveInvestments, setInactiveInvestments] =
    useState<PaginationData<InvestorInvestmentListResponseDto>>();
  const [inactiveInvestmentsPaginationRequest, setInactiveInvestmentsPaginationRequest] = useState<
    PaginationDataFilter | undefined
  >();

  const [lateInvestments, setLateInvestments] =
    useState<PaginationData<InvestorInvestmentListResponseDto>>();
  const [lateInvestmentsPaginationRequest, setLateInvestmentsPaginationRequest] = useState<
    PaginationDataFilter | undefined
  >();

  const [soldInvestments, setSoldInvestments] =
    useState<PaginationData<InvestorInvestmentListResponseDto>>();
  const [soldInvestmentsPaginationRequest, setSoldInvestmentsPaginationRequest] = useState<
    PaginationDataFilter | undefined
  >();

  const [recoveringInvestments, setRecoveringInvestments] =
    useState<PaginationData<InvestorInvestmentListResponseDto>>();
  const [recoveringInvestmentsPaginationRequest, setRecoveringInvestmentsPaginationRequest] =
    useState<PaginationDataFilter | undefined>();

  const [otherInvestments, setOtherInvestments] =
    useState<PaginationData<InvestorInvestmentListResponseDto>>();
  const [otherInvestmentsPaginationRequest, setOtherInvestmentsPaginationRequest] = useState<
    PaginationDataFilter | undefined
  >();

  const [transferableInvestments, setTransferableInvestments] =
    useState<PaginationData<InvestorInvestmentListResponseDto>>();
  const [transferableInvestmentsPaginationRequest, setTransferableInvestmentsPaginationRequest] =
    useState<PaginationDataFilter | undefined>();

  const [, setQueryFilters] = useQueryParam('filters', StringParam);

  const genericColumns: Column[] | any = useMemo(
    () => [
      {
        id: 'expander',
        Cell: ({ row }: UseTableCellProps<InvestmentDocumentResponseDto>) => <Expander row={row} />,
      },
      {
        Header: t('table.pid'),
        accessor: 'pid',
        sortType: 'string',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => {
          if (cell.row.depth === 0) {
            return (
              <ProjectTitleLink
                title={cell.row.original.project_name ?? ''}
                projectId={cell.row.original.project_id}
                privateId={cell.row.original.project_pid}
              />
            );
          }

          return '';
        },
      },
      {
        Header: t('table.investment'),
        accessor: 'investment_amount',
        sortType: 'number',
        Filter: AmountFilter,
        filterProps: {
          start: 0,
          end: 10000,
        },
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => {
          return (
            <span className={'d-flex align-items-center'}>
              <span>{tHtml('common.money', { value: cell.value })}</span>
              <BonusAmountTooltip cell={cell} />
            </span>
          );
        },
      },
      {
        Header: t('table.investment_date'),
        accessor: 'invested_at',
        sortType: '',
        Filter: DateFilter,
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => {
          return (
            <span className={'d-flex align-items-center'}>
              <span>{tHtml('common.date', { date: cell.value })}</span>
              <AutomaticInvestmentTooltip cell={cell} />
              <TransferTypeInvestmentTooltip cell={cell} />
              <NotSellableInvestmentTooltip cell={cell} />
            </span>
          );
        },
      },
      {
        Header: t('table.investment_term'),
        accessor: 'credit_duration',
        sortType: '',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => {
          return calculateCreditDuration(cell.row.original);
        },
      },
      {
        Header: t('table.interest'),
        accessor: 'total_interest',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => {
          return (
            <span className={'d-flex align-items-center'}>
              <span>{tHtml('common.percents', { value: cell.value })}</span>
              <BonusPercentageTooltip cell={cell} />
            </span>
          );
        },
      },
      {
        Header: t('table.received_interest'),
        accessor: 'interest_received',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) =>
          t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.returned_loan_amount'),
        accessor: 'interest_returned',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) =>
          t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.loan_fine'),
        accessor: 'fine',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) =>
          t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.received_total'),
        accessor: 'total',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) =>
          t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.loan_left_amount'),
        accessor: 'investment_balance',
        sortType: 'number',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) =>
          t('common.money', { value: cell.value }),
      },
      {
        Header: '',
        disableSortBy: true,
        accessor: 'is_refundable',
        Cell: (cell: any) => (
          <>
            {cell.value ? (
              <RefundInvestment
                onSuccess={() => cell.dispatch({ type: 'refreshData' })}
                investmentId={cell.row.original.id}
                is_project_funded={cell.row.original.is_project_funded}
              />
            ) : null}
          </>
        ),
      },
      {
        Header: '',
        disableSortBy: true,
        accessor: 'allow_project_investment_transfers',
        Cell: (cell: any) => (
          <>
            {cell.value &&
            !cell.row.original.refunded_at &&
            cell.row.original.refinancing_project_id !== null ? (
              <TransferInvestment
                data={{
                  project_name: cell.row.original.project_name,
                  project_id: cell.row.original.project_id,
                  project_pid: cell.row.original.project_pid,
                  refinancing_project_name: cell.row.original.refinancing_project_name,
                  investment: [
                    { amount: cell.row.original.investment_amount, id: cell.row.original.id },
                  ],
                  refinancing_project_id: cell.row.original.refinancing_project_id,
                  refinancing_project_pid: cell.row.original.refinancing_project_pid,
                  onTransfer: () => setRefresh((val) => !val),
                }}
              />
            ) : null}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, tHtml],
  );

  const columns: Column[] | any = useMemo(
    () => [
      ...genericColumns,
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [genericColumns, t],
  );

  const inactiveInvestmentColumns: Column[] | any = useMemo(
    () => [
      ...genericColumns,
      {
        Header: t('table.finished_at'),
        accessor: 'finished_at',
        sortType: '',
        Cell: (cell: Cell<InvestorInvestmentListResponseDto>) => (
          <span>{tHtml('common.date', { date: cell.value })}</span>
        ),
      },
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [genericColumns, t],
  );

  const lateInvestmentColumns: Column[] | any = useMemo(
    () => [
      ...genericColumns,
      {
        Header: t('table.late_at'),
        accessor: 'late_at',
        sortType: '',
        Cell: (cell: Cell<InvestorLateInvestmentListResponseDto>) => {
          return (
            <span className={'d-flex align-items-center'}>
              <span>{cell.value ? differenceBetweenTwoDatesToString(cell.value) : '-'}</span>
            </span>
          );
        },
      },
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [genericColumns, t],
  );

  const recoveringInvestmentColumns: Column[] | any = useMemo(
    () => [
      ...genericColumns,
      {
        Header: t('table.comment'),
        accessor: 'comment',
        sortType: 'string',
        Cell: (cell: Cell<InvestorOtherInvestmentListResponseDto>) => {
          return <span>{resolveTranslatableValueDto(cell.value)}</span>;
        },
      },
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
    ],
    [genericColumns, t],
  );

  const otherInvestmentColumns: Column[] | any = useMemo(
    () => [
      ...genericColumns,
      {
        Header: t('table.comment'),
        accessor: 'comment',
        sortType: 'string',
        Cell: (cell: Cell<InvestorOtherInvestmentListResponseDto>) => {
          return <span>{resolveTranslatableValueDto(cell.value)}</span>;
        },
      },
      {
        id: 'expander-mobile',
        hidden: true,
        Cell: ({ row }: UseTableCellProps<InvestorInvestmentListResponseDto>) => (
          <Expander row={row} />
        ),
      },
    ],
    [genericColumns, t],
  );

  const calculateTitle = (): ReactElement => {
    if (selectedTableOption === OPTION_ALL) return tHtml('investments.active');
    return tHtml('investments.' + selectedTableOption);
  };

  const resolveExportTableType = (): ProjectInvestmentStatusEnum | null => {
    switch (selectedTableOption) {
      case OPTION_ACTIVE:
        return ProjectInvestmentStatusEnum.ACTIVE;
      case OPTION_INACTIVE:
        return ProjectInvestmentStatusEnum.INACTIVE;
      case OPTION_LATE:
        return ProjectInvestmentStatusEnum.LATE;
      case OPTION_SOLD:
        return ProjectInvestmentStatusEnum.SOLD;
      case OPTION_RECOVERING:
        return ProjectInvestmentStatusEnum.RECOVERING;
      case OPTION_OTHER:
        return ProjectInvestmentStatusEnum.OTHER;
      case TRANSFERABLE:
        return ProjectInvestmentStatusEnum.TRANSFERABLE;
      default:
        return null;
    }
  };

  const exportCsv = (request: PaginationDataFilter | undefined) => async () => {
    const type: ProjectInvestmentStatusEnum | null = resolveExportTableType();

    if (!type) {
      error(t('common.error'));
      return;
    }

    const response = await Api.investor.investments.exportInvestments(
      type,
      CommonDocumentTypeEnum.CSV,
      request,
    );

    await downloadCSVFile(response.url, 'investments');
  };

  const exportXlsx = (request: PaginationDataFilter | undefined) => async () => {
    const type: ProjectInvestmentStatusEnum | null = resolveExportTableType();

    if (!type) {
      error(t('common.error'));
      return;
    }

    const response = await Api.investor.investments.exportInvestments(
      type,
      CommonDocumentTypeEnum.XLSX,
      request,
    );

    await downloadXLSXFile(response.url, 'investments');
  };

  const exportXml = (request: PaginationDataFilter | undefined) => async () => {
    const type: ProjectInvestmentStatusEnum | null = resolveExportTableType();

    if (!type) {
      error(t('common.error'));
      return;
    }

    const response = await Api.investor.investments.exportInvestments(
      type,
      CommonDocumentTypeEnum.XML,
      request,
    );
    await downloadXMLFile(response.url);
  };

  const fetchActiveInvestments = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setActiveInvestmentsPaginationRequest(request);
      return Api.investor.investments
        .fetchActiveInvestments(request)
        .then((response) => setActiveInvestments(reformatResponse(response)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage, refresh],
  );

  const fetchInactiveInvestments = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setInactiveInvestmentsPaginationRequest(request);
      return Api.investor.investments
        .fetchInactiveInvestments(request)
        .then((response) => setInactiveInvestments(reformatResponse(response)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage, refresh],
  );

  const fetchLateInvestments = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setLateInvestmentsPaginationRequest(request);
      return Api.investor.investments
        .fetchLateInvestments(request)
        .then((response) => setLateInvestments(reformatResponse(response)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage, refresh],
  );

  const fetchSoldInvestments = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setSoldInvestmentsPaginationRequest(request);
      return Api.investor.investments
        .fetchSoldInvestments(request)
        .then((response) => setSoldInvestments(reformatResponse(response)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage, refresh],
  );

  const fetchRecoveringInvestments = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setRecoveringInvestmentsPaginationRequest(request);
      return Api.investor.investments
        .fetchRecoveringInvestments(request)
        .then((response) => setRecoveringInvestments(reformatResponse(response)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage, refresh],
  );

  const fetchOtherInvestments = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setOtherInvestmentsPaginationRequest(request);
      return Api.investor.investments
        .fetchOtherInvestments(request)
        .then((response) => setOtherInvestments(reformatResponse(response)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage, refresh],
  );

  const fetchTransferableInvestments = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setTransferableInvestmentsPaginationRequest(request);
      return Api.investor.investments
        .fetchTransferableInvestments(request)
        .then((response) => setTransferableInvestments(reformatResponse(response)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage, refresh],
  );

  const handleSelectTabOption = (option: string) => {
    setQueryFilters('[]');
    setSelectedTableOption(option);
  };

  return (
    <div className={'investor-my-investments'}>
      <PageHeader title={tHtml('investments.my')}>
        <div>
          {p.can(Permission.ENABLED_SECONDARY_MARKET) && (
            <Link to={RouteList.INVESTOR.SECONDARY_MARKET.SELLER.OVERVIEW}>
              <PrimaryButton className={'site btn btn-highlight me-3'}>
                {tHtml('investments.sell_investments')}
              </PrimaryButton>
            </Link>
          )}
          <Link to={RouteList.INVESTOR.PROJECTS.LIST}>
            <PrimaryButton>{tHtml('common.invest')}</PrimaryButton>
          </Link>
        </div>
      </PageHeader>

      <div className={'main-filter'}>
        <h2 className={'main-filter__header'}>{calculateTitle()}</h2>
        <div className={'filterable-btns'}>
          {TABLE_OPTIONS.map((option: string, idx) => (
            <button
              className={`${option === selectedTableOption && 'active'}`}
              key={idx}
              onClick={() => handleSelectTabOption(option)}
            >
              {tHtml(`investments.filters.${option}`)}
            </button>
          ))}
        </div>
      </div>

      <div
        className={activeInvestments?.meta?.total && activeInvestments.meta.total > 0 ? '' : 'mb-4'}
      >
        {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_ACTIVE) && (
          <TableOverview
            columns={columns}
            link={RouteList.INVESTOR.MY_INVESTMENTS.ACTIVE}
            data={activeInvestments}
            fetchData={fetchActiveInvestments}
            noDataLabel={tHtml('investments.no_active_investments')}
            initialSortBy={INITIAL_SORT_ORDER}
            hideSubRowColumns={['project_name']}
            hideColumnsOnDesktop={['expander-mobile']}
            hideColumnsOnMobile={['expander']}
            hideSortFields={['expander', 'expander-mobile']}
            exportLinks={[
              {
                onClick: exportCsv,
                request: activeInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.CSV,
              },
              {
                onClick: exportXlsx,
                request: activeInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XLSX,
              },
              {
                onClick: exportXml,
                request: activeInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XML,
              },
            ]}
          />
        )}
      </div>
      <div
        className={
          inactiveInvestments?.meta?.total && inactiveInvestments.meta.total > 0 ? '' : 'mb-4'
        }
      >
        {selectedTableOption === OPTION_ALL && (
          <h2 className={'mb-3'}>{tHtml('investments.inactive')}</h2>
        )}
        {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_INACTIVE) && (
          <TableOverview
            columns={inactiveInvestmentColumns}
            link={RouteList.INVESTOR.MY_INVESTMENTS.INACTIVE}
            data={inactiveInvestments}
            fetchData={fetchInactiveInvestments}
            initialSortBy={INITIAL_SORT_ORDER}
            hideSubRowColumns={['project_name']}
            hideColumnsOnDesktop={['expander-mobile']}
            hideColumnsOnMobile={['expander']}
            hideSortFields={['expander', 'expander-mobile']}
            exportLinks={[
              {
                onClick: exportCsv,
                request: inactiveInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.CSV,
              },
              {
                onClick: exportXlsx,
                request: inactiveInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XLSX,
              },
              {
                onClick: exportXml,
                request: inactiveInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XML,
              },
            ]}
          />
        )}
      </div>
      <div className={lateInvestments?.meta?.total && lateInvestments.meta.total > 0 ? '' : 'mb-4'}>
        {selectedTableOption === OPTION_ALL && (
          <h2 className={'mb-3'}>{tHtml('investments.late')}</h2>
        )}
        {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_LATE) && (
          <TableOverview
            columns={lateInvestmentColumns}
            link={RouteList.INVESTOR.MY_INVESTMENTS.LATE}
            data={lateInvestments}
            fetchData={fetchLateInvestments}
            initialSortBy={INITIAL_SORT_ORDER}
            hideSubRowColumns={['project_name']}
            hideColumnsOnDesktop={['expander-mobile']}
            hideColumnsOnMobile={['expander']}
            hideSortFields={['expander', 'expander-mobile']}
            exportLinks={[
              {
                onClick: exportCsv,
                request: lateInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.CSV,
              },
              {
                onClick: exportXlsx,
                request: lateInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XLSX,
              },
              {
                onClick: exportXml,
                request: lateInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XML,
              },
            ]}
          />
        )}
      </div>
      <div className={soldInvestments?.meta?.total && soldInvestments.meta.total > 0 ? '' : 'mb-4'}>
        {selectedTableOption === OPTION_ALL && (
          <h2 className={'mb-3'}>{tHtml('investments.sold')}</h2>
        )}
        {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_SOLD) && (
          <TableOverview
            columns={columns}
            link={RouteList.INVESTOR.MY_INVESTMENTS.SOLD}
            data={soldInvestments}
            fetchData={fetchSoldInvestments}
            initialSortBy={INITIAL_SORT_ORDER}
            hideSubRowColumns={['project_name']}
            hideColumnsOnDesktop={['expander-mobile']}
            hideColumnsOnMobile={['expander']}
            hideSortFields={['expander', 'expander-mobile']}
            exportLinks={[
              {
                onClick: exportCsv,
                request: soldInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.CSV,
              },
              {
                onClick: exportXlsx,
                request: soldInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XLSX,
              },
              {
                onClick: exportXml,
                request: soldInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XML,
              },
            ]}
          />
        )}
      </div>
      <div
        className={
          recoveringInvestments?.meta?.total && recoveringInvestments.meta.total > 0 ? '' : 'mb-4'
        }
      >
        {selectedTableOption === OPTION_ALL && (
          <h2 className={'mb-3'}>{t('investments.recovering')}</h2>
        )}
        {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_RECOVERING) && (
          <TableOverview
            columns={recoveringInvestmentColumns}
            link={RouteList.INVESTOR.MY_INVESTMENTS.RECOVERING}
            data={recoveringInvestments}
            fetchData={fetchRecoveringInvestments}
            hideSubRowColumns={['project_name']}
            hideColumnsOnDesktop={['expander-mobile']}
            hideColumnsOnMobile={['expander']}
            hideSortFields={['expander', 'expander-mobile']}
            exportLinks={[
              {
                onClick: exportCsv,
                request: recoveringInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.CSV,
              },
              {
                onClick: exportXlsx,
                request: recoveringInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XLSX,
              },
              {
                onClick: exportXml,
                request: recoveringInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XML,
              },
            ]}
          />
        )}
      </div>
      <div
        className={
          transferableInvestments?.meta?.total && transferableInvestments.meta.total > 0
            ? ''
            : 'mb-4'
        }
      >
        {(selectedTableOption === OPTION_ALL || selectedTableOption === TRANSFERABLE) && (
          <TableOverview
            columns={columns}
            link={RouteList.INVESTOR.MY_INVESTMENTS.TRANSFERABLE}
            data={transferableInvestments}
            fetchData={fetchTransferableInvestments}
            noDataLabel={tHtml('investments.no_transferable_investments')}
            initialSortBy={INITIAL_SORT_ORDER}
            hideSubRowColumns={['project_name']}
            hideColumnsOnDesktop={['expander-mobile']}
            hideColumnsOnMobile={['expander']}
            hideSortFields={['expander', 'expander-mobile']}
            exportLinks={[
              {
                onClick: exportCsv,
                request: transferableInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.CSV,
              },
              {
                onClick: exportXlsx,
                request: transferableInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XLSX,
              },
              {
                onClick: exportXml,
                request: transferableInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XML,
              },
            ]}
          />
        )}
      </div>
      <div
        className={otherInvestments?.meta?.total && otherInvestments.meta.total > 0 ? '' : 'mb-4'}
      >
        {selectedTableOption === OPTION_ALL && <h2 className={'mb-3'}>{t('investments.other')}</h2>}
        {(selectedTableOption === OPTION_ALL || selectedTableOption === OPTION_OTHER) && (
          <TableOverview
            columns={otherInvestmentColumns}
            link={RouteList.INVESTOR.MY_INVESTMENTS.OTHER}
            data={otherInvestments}
            fetchData={fetchOtherInvestments}
            hideSubRowColumns={['project_name']}
            hideColumnsOnDesktop={['expander-mobile']}
            hideColumnsOnMobile={['expander']}
            hideSortFields={['expander', 'expander-mobile']}
            exportLinks={[
              {
                onClick: exportCsv,
                request: otherInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.CSV,
              },
              {
                onClick: exportXlsx,
                request: otherInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XLSX,
              },
              {
                onClick: exportXml,
                request: otherInvestmentsPaginationRequest,
                type: CommonDocumentTypeEnum.XML,
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export const reformatResponse = (response: PaginationData<any>) => {
  const reformattedResponse: PaginationData<any> = {
    data: [],
    meta: { ...response.meta },
  };

  for (const item of response.data) {
    reformattedResponse.data.push({
      ...item,
      subRows: item.sub_rows,
    });
  }

  return reformattedResponse;
};

export default MyInvestments;
