import * as Yup from 'yup';

export default (): any => {
  return Yup.object().shape({
    purpose: Yup.array().min(1),
    other_purpose_text: Yup.string()
      .max(255)
      .when('purpose', {
        is: (purpose: string[]) => purpose.includes('other'),
        then: Yup.string().maxLength().required(),
        otherwise: Yup.string().maxLength().nullable(),
      }),
    activities: Yup.array().min(1).required(),
    other_activities_text: Yup.string().when('activities', {
      is: (activities: string[]): boolean => activities.includes('other'),
      then: Yup.string().maxLength().required(),
      otherwise: Yup.string().maxLength().nullable(),
    }),
    average_income: Yup.number().min(1).required(),
    cash_usage: Yup.string().maxLength().required(),
    activity_regions: Yup.array().min(1).required(),
    other_activity_regions_text: Yup.string().when('activity_regions', {
      is: (activity_regions: string[]): boolean => activity_regions.includes('other'),
      then: Yup.string().maxLength().required(),
      otherwise: Yup.string().maxLength().nullable(),
    }),
    is_not_operating_in_russia_belarus_ukraine_occupied_territories: Yup.boolean().required(),
    business_partners: Yup.string().maxLengthLongText().required(),
    has_bankruptcy_procedure: Yup.boolean().required(),
    convicted: Yup.boolean().required(),

    planned_investment_amount: Yup.string().required(),
    origin_of_invested_funds: Yup.array().min(1).required(),
    other_origin_of_invested_funds_text: Yup.string().when('origin_of_invested_funds', {
      is: (origin_of_invested_funds: string[]): boolean =>
        origin_of_invested_funds.includes('other'),
      then: Yup.string().maxLength().required(),
      otherwise: Yup.string().maxLength().nullable(),
    }),

    has_shareholders_with_more_than_25_percent: Yup.boolean().required(),
    shareholders: Yup.array().shareholderArray(),

    beneficiaries: Yup.array().beneficiaryArray(),

    public_positions_held_by_related_individuals: Yup.boolean().required(),
    peps: Yup.array().pepArray(),

    has_confirmed_data_integrity: Yup.boolean().required().isTrue(),
    has_confirmed_legitimacy: Yup.boolean().required().isTrue(),
  });
};
