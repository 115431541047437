import React from 'react';
import { ButtonProps, ModalBody, ModalHeader } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { generatePath, Link } from 'react-router-dom';

import { RouteList } from 'routes';
import { ApplicationTypeEnum } from 'scopes/developer/helpers/enums/ApplicationTypeEnum';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import ModalCloseButton from 'components/Modal/ModalCloseButton';

const ApplicationModal: React.FC<ModalComponentProps> = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  interface ApplicationButtonProps {
    applicationType: ApplicationTypeEnum;
  }

  const ApplicationButton: React.FC<
    ApplicationButtonProps & ButtonProps & React.HTMLProps<HTMLButtonElement>
  > = ({ applicationType, children, icon, ...props }) => {
    return (
      <div className={'my-1'}>
        <Link
          to={{
            pathname: generatePath(RouteList.PROJECT_DEVELOPER.APPLICATIONS.CREATE_APPLICATION, {
              type: applicationType,
            }),
            state: { type: applicationType },
          }}
        >
          <button
            className={'w-100 site btn btn-highlight-secondary'}
            onClick={hideModal}
            {...props}
          >
            {icon && (
              <>
                <i className={`align-middle me-2 ${icon}`} />{' '}
              </>
            )}
            {children}
          </button>
        </Link>
      </div>
    );
  };

  return (
    <React.Fragment>
      <ModalComponent size={'mf'} {...props} contentClassName={'modal-application'}>
        <div className={'p-2 p-lg-6 py-lg-4'}>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />} tag={'h1'}>
            {tHtml('application.modal.title')}
          </ModalHeader>
          <ModalBody className={'d-flex justify-content-center'}>
            <div className={'w-100'}>
              <ApplicationButton applicationType={ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT}>
                {tHtml('application.type.real_estate_development')}
              </ApplicationButton>
              <ApplicationButton applicationType={ApplicationTypeEnum.REFINANCING}>
                {tHtml('application.type.refinancing')}
              </ApplicationButton>
              <ApplicationButton applicationType={ApplicationTypeEnum.WORKING_CAPITAL}>
                {tHtml('application.type.working_capital')}
              </ApplicationButton>
              <ApplicationButton applicationType={ApplicationTypeEnum.REAL_ESTATE_ACQUISITION}>
                {tHtml('application.type.real_estate_acquisition')}
              </ApplicationButton>
              <ApplicationButton applicationType={ApplicationTypeEnum.OTHER}>
                {tHtml('application.type.other')}
              </ApplicationButton>
            </div>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ApplicationModal;
