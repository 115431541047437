export enum WalletOperationTypeEnum {
  MONEY_IN = 'money_in',
  MONEY_OUT = 'money_out',
  PROJECT_INVESTMENT = 'project_investment',
  PROJECT_INVESTMENT_BONUS = 'project_investment_bonus',
  PROJECT_INVESTMENT_REFUND = 'project_investment_refund',
  PROJECT_INVESTMENT_OVERPAYMENT_REFUND = 'project_investment_overpayment_refund',
  PROJECT_INVESTMENT_REPAYMENT = 'project_investment_repayment',
  PROJECT_INVESTMENT_REPAYMENT_BONUS = 'project_investment_repayment_bonus',
  SECONDARY_MARKET = 'secondary_market',
  SECONDARY_MARKET_SELLER_TAX = 'secondary_market_seller_tax',
  SECONDARY_MARKET_PURCHASE = 'secondary_market_purchase',
  SECONDARY_MARKET_SALE = 'secondary_market_sale',
  LOAN_RECEIVE = 'loan_receive',
  LOAN_REPAYMENT = 'loan_repayment',
  INVOICE_PAYMENT = 'invoice_payment',
  BONUS_PREMIUM = 'bonus_premium',
}
