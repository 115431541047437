import React, { useRef } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Link, useParams, withRouter } from 'react-router-dom';

import { RouteList } from 'routes';
import { FunctionalButton } from 'components/Button';
import useOnScreen from 'helpers/useOnScreen';
import ProjectMinimized from './ProjectMinimized';
import ProjectTop from './ProjectTop';
import { ProjectProvider } from 'components/Project';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';
import ProjectBody from 'components/Project/Authenticated/ProjectBody';
import ProjectInformation from 'components/Project/Authenticated/ProjectInformation';
import useDisplay from 'helpers/useDisplay';
import ProjectTopApp from './ProjectTopApp';

const ProjectWrapper: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();

  return (
    <ProjectProvider type={UserTypeEnum.INVESTOR} projectId={projectId}>
      <Project />
    </ProjectProvider>
  );
};

export const Project: React.FC = () => {
  const { isApp, isMobile } = useDisplay();
  const { tHtml } = useTranslation();
  const projectTopRef = useRef<HTMLDivElement>(null);
  const isProjectTopVisible: boolean = useOnScreen(projectTopRef);

  return (
    <div className={'project'}>
      <div className={'mb-3'}>
        <Link to={RouteList.INVESTOR.PROJECTS.LIST}>
          <FunctionalButton>{tHtml('common.back')}</FunctionalButton>
        </Link>
      </div>
      <div ref={projectTopRef}>{isApp && isMobile ? <ProjectTopApp /> : <ProjectTop />}</div>
      <ProjectMinimized show={!isProjectTopVisible} />
      <ProjectInformation />
      <ProjectBody />
    </div>
  );
};

export default withRouter(ProjectWrapper);
