import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Nav, NavbarToggler } from 'reactstrap';
import { RouteList } from 'routes';
import LocaleSwitcher from 'components/LocaleSwitcher';

import {
  StyledMobileBurgerButton,
  StyledMobileSidebarArrow,
  StyledMobileSidebarBlock,
  StyledMobileSidebarHeader,
  StyledMobileSidebarWrapper,
} from './SidebarMobile.style';
import ThemeSwitcher from 'components/ThemeSwitcher/ThemeSwitcher';
import usePermissions from 'helpers/usePermissions';
import { useAuth } from 'services/useAuth';
import AuthenticatedAccountMobile from 'scopes/shared/components/AuthenticatedAccountMobile';
import useDisplay from 'helpers/useDisplay';
import { Permission } from 'helpers/auth/permissions';

export interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarMobile: React.FC<Props> = (props) => {
  const { t, tHtml } = useTranslation();
  const { can } = usePermissions();
  const { isApp } = useDisplay();

  const { logout } = useAuth();

  interface MenuItem {
    name: string;
    path?: string;
    permission?: string;
    isHidden?: boolean;
  }

  const menuItems: MenuItem[] = [
    {
      name: t('menu.account_overview'),
      path: RouteList.DASHBOARD,
      isHidden: isApp,
    },
    {
      name: t('menu.invest'),
      path: RouteList.INVESTOR.PROJECTS.LIST,
      isHidden: isApp,
    },
    {
      name: t('menu.auto_investment'),
      path: RouteList.INVESTOR.AUTO_INVESTMENT.VIEW,
      permission: Permission.ENABLED_AUTO_INVESTMENTS,
    },
    {
      name: t('menu.my_investments'),
      path: RouteList.INVESTOR.MY_INVESTMENTS.LIST,
    },
    {
      name: t('menu.transactions'),
      path: RouteList.INVESTOR.TRANSACTIONS.LIST,
    },
    {
      name: t('menu.documents'),
      path: RouteList.INVESTOR.DOCUMENTS.LIST,
    },
    {
      name: t('menu.affiliate'),
      path: RouteList.INVESTOR.AFFILIATE.VIEW,
    },
  ];

  const renderItem = (menuItem: MenuItem, index: number) => {
    if (menuItem.permission && !can(menuItem.permission)) {
      return <React.Fragment key={index} />;
    }

    return (
      <Link to={menuItem.path ?? '#'} style={{ whiteSpace: 'nowrap' }} key={menuItem.path}>
        <span>{menuItem.name}</span>
        <StyledMobileSidebarArrow>
          <i className={'icon icon-arrow-sidebar'} />
        </StyledMobileSidebarArrow>
      </Link>
    );
  };

  const toggle = () => props.setIsOpen((value) => !value);

  const renderMenu = (menu: MenuItem[]) => {
    return menu.map((menuItem, index) => !menuItem.isHidden && renderItem(menuItem, index));
  };

  return (
    <React.Fragment>
      <Nav vertical={true} className="h-100">
        <StyledMobileSidebarWrapper className="h-100 justify-content-between">
          <div>
            <StyledMobileSidebarHeader>
              <LocaleSwitcher />
              <StyledMobileSidebarBlock>
                {!isApp && <ThemeSwitcher />}
                <StyledMobileBurgerButton>
                  <NavbarToggler onClick={toggle} className={'sidebar-toggler'} />
                </StyledMobileBurgerButton>
              </StyledMobileSidebarBlock>
            </StyledMobileSidebarHeader>
            <AuthenticatedAccountMobile />
            {renderMenu(menuItems)}
          </div>
          <Link
            onClick={() => {
              logout();
            }}
            className="logout mb-4"
            to={RouteList.GUEST.AUTHENTICATION.LOGIN}
          >
            <i className={'icon icon-logout'} />
            {tHtml('common.logout')}
          </Link>
        </StyledMobileSidebarWrapper>
      </Nav>
    </React.Fragment>
  );
};

export default SidebarMobile;
