import React from 'react';
import useTranslation from 'helpers/useTranslation';

import ProjectTopMobile from './ProjectTop/ProjectTopMobile';
import ProjectTop from './ProjectTop/ProjectTop';
import ProjectInvestmentProvider from './ProjectInvestmentProvider';
import useDisplay from 'helpers/useDisplay';

interface ProjectProps {
  projectInvestmentId: string;
}

const ProjectInvestmentForSale: React.FC<ProjectProps> = ({ projectInvestmentId }) => {
  const { tHtml } = useTranslation();
  const { isMobile } = useDisplay();

  return (
    <div>
      <div className={'mb-4'}>
        <h2>{tHtml('secondary_market.project.project_for_sale')}</h2>
      </div>
      <section>
        <ProjectInvestmentProvider projectInvestmentId={projectInvestmentId}>
          {isMobile ? <ProjectTopMobile /> : <ProjectTop />}
        </ProjectInvestmentProvider>
      </section>
    </div>
  );
};

export default ProjectInvestmentForSale;
