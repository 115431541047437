export type BonusSystemCodeResponseDto = {
  code: string;
  summable: boolean;
};

export interface BonusSystemBriefRequestDto {
  project_id: string | number | null;
}

export interface WithdrawalEventSettingsDto {
  min_amount: number;
  percent: number;
  expiry_date: string;
  bonus: {
    code: string;
    type: string;
    bonus_condition: string;
    percentage: number;
    summable: boolean;
    sellable: boolean;
    status: string;
    expire_at: string;
  } | null;
  single_bonus_validity_days: number;
}

export interface BonusSystemBriefResponseDto {
  code: string;
  amount: number | null;
  percentage: number | null;
  summable: boolean;
  sellable: boolean;
  bonus_condition: BonusConditionEnum;
}

export interface BonusSystemListResponseDto {
  type: BonusSystemType;
  code: string;
  bonus_condition: BonusConditionEnum;
  amount: number | null;
  percentage: number | null;
  summable: boolean;
  sellable: boolean;
  status: string;
  projects: BonusSystemProjectDto[];
  expiry_date: Date | null;
  referral_name: string | null;
  affiliate_name: string | null;
}

export interface BonusSystemProjectDto {
  private_id: string | null;
  project_name: string | null;
}

export enum BonusConditionEnum {
  AMOUNT = 'amount',
  PERCENTAGE = 'percentage',
}

export enum BonusSystemType {
  REFERRAL_REWARD = 'referral_reward',
  AFFILIATE_REWARD = 'affiliate_reward',
  AFFILIATE_REWARD_V2 = 'affiliate_reward_v2',

  BONUS_CODE = 'bonus_code',
  CLIENT_SUPPORT = 'client_support',
  CASHBACK = 'cashback',
}
