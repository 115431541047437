import styled from 'styled-components';

export const StyledDataBlockBetween = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export const StyledDataBlockBetweenTitle = styled.div`
  color: var(--profitus-copy-data-block-title-color);
  font-weight: 700;
  margin-bottom: 0;
  display: flex;
  align-items: center;
`;

export const StyledDataBlockBetweenTitleIcon = styled.i`
  height: 0.8rem;
  width: 0.8rem;
`;

export const StyledDataBlockBetweenContent = styled.h2`
  font-weight: 700;
  color: var(--profitus-copy-data-block-text-color);
  margin-bottom: 0;
`;
