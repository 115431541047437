import Client from '../client';
import Http from '../../http';
import {
  AffiliateResponseDto,
  SendReferralLinkByEmailRequest,
} from '../../types/investor/affiliate';

class InvestorAffiliate extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_AFFILIATE: this.defaultUrl(`${this.http.baseUrl}/investor/affiliate/meta`),
      SEND_REFERRAL_LINK: this.defaultUrl(`${this.http.baseUrl}/investor/affiliate/invite`),
    };
  }

  fetchAffiliate = (): Promise<AffiliateResponseDto> => {
    return this.http.get(this.api.FETCH_AFFILIATE);
  };

  sendReferralLinkByEmail = (request: SendReferralLinkByEmailRequest): Promise<any> => {
    return this.http.post(this.api.SEND_REFERRAL_LINK, request);
  };
}

export default InvestorAffiliate;
