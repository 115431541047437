import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Col, Row } from 'reactstrap';

const ProjectSkeleton: React.FC = () => (
  <div className={'project-skeleton'}>
    <section className={'project-top'}>
      <Row>
        <Col>
          <Skeleton />
        </Col>
        <Col>
          <Skeleton />
        </Col>
      </Row>
    </section>
    <section className={'project-information'}>
      <Skeleton />
    </section>
    <section className={'project-tabs'}>
      <Skeleton />
    </section>
  </div>
);

export default ProjectSkeleton;
