import React from 'react';
import { useGlobalModalContext } from './GlobalModal';

const ModalCloseButton: React.FC<{ onClose?: () => void }> = ({ onClose = undefined }) => {
  const { hideModal } = useGlobalModalContext();
  return (
    <div
      onClick={() => {
        hideModal();
        if (onClose) onClose();
      }}
      className="close pointer"
      aria-label="Close"
    >
      &times;
    </div>
  );
};

export default ModalCloseButton;
