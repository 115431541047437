import React from 'react';
import { DefaultSortTypes, SortByFn } from 'react-table';

interface Props {
  type: SortByFn<any> | DefaultSortTypes | string | undefined;
  isDesc: boolean | undefined;
}

const SortOrder: React.FC<Props> = ({ type, isDesc }) => {
  if (type === 'number') {
    if (isDesc) {
      return <i className="icon icon-sort-numeric-down" />;
    }

    return <i className="icon icon-sort-numeric-up" />;
  }

  if (type === 'alphanumeric' || type === 'string') {
    if (isDesc) {
      return <i className="icon icon-sort-alpha-down" />;
    }

    return <i className="icon icon-sort-alpha-up" />;
  }

  if (isDesc) {
    return <i className="icon icon-sort-down" />;
  }

  return <i className="icon icon-sort-up" />;
};

export default SortOrder;
