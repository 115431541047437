export const Permission = {
  GUEST: 'ss.guest',
  IMPERSONATED: 'ss.impersonated',
  REGISTERED: 'ss.registered',
  WALLET_VERIFIED: 'ss.actions.wallet_verified',
  EMAIL_VERIFIED: 'ss.actions.email_verified',
  PAY_BY_BANK: 'ss.actions.pay_by_bank',
  CONTACT_DATA_FILLED: 'ss.actions.contact_data_filled',
  ACCREDITATION_VERIFIED: 'ss.actions.accreditation_verified',
  COMPANY_KYB_FILLED: 'ss.actions.company_kyb_filled',
  USER_KYC_FILLED: 'ss.actions.user_kyc_filled',
  INVESTOR_QUIZ_FILLED: 'ss.actions.investor_quiz_filled',
  ENABLED_SECONDARY_MARKET: 'ss.enabled.secondary_market',
  ENABLED_AML: 'ss.enabled.aml',
  ENABLED_AUTO_INVESTMENTS: 'ss.enabled.auto_investments',
  INSTALL_PWA: 'ss.actions.install_pwa',
};
