import Client from '../client';
import Http from '../../http';
import { ExternalMediaResponseDto } from 'api/types/common';

class Media extends Client {
  constructor(http: Http) {
    super(http);
  }

  fetchExternal = (url: string): Promise<ExternalMediaResponseDto> => {
    return this.http.get(this.defaultUrl(url));
  };
}

export default Media;
