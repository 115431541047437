import React, { useState } from 'react';
import { NavLink, NavLinkProps, withRouter } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import SUITCASE_ICON from '../../../../assets/images/icons/suitcase.svg';

import { RouteList } from 'routes';
import { useAccountQuery } from 'api/queries';
import useUserType from 'helpers/useUserType';

const IconNavLink: React.FC<NavLinkProps> = (props) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <NavLink
      to={props.to}
      isActive={(match) => {
        setIsActive(!!match);
        return !!match;
      }}
    >
      {isActive && <i className={'input-icon icon-eye'} />} {props.children}
    </NavLink>
  );
};

const Sidebar: React.FC = () => {
  const { tHtml } = useTranslation();
  const { data } = useAccountQuery();
  const { isInvestor } = useUserType();

  return (
    <React.Fragment>
      <div className={'p-4 profile-sidebar w-100'}>
        <div className={'profile-info'}>
          <img src={SUITCASE_ICON} alt="Avatar" className={'avatar'} />
          {data?.name
            ? tHtml('profile.greeting_with_name', { value: data?.name })
            : tHtml('profile.greeting')}
        </div>
        <div className={'profile-menu mt-5'}>
          <Nav vertical>
            <NavItem>
              <IconNavLink to={RouteList.COMPANY.EDIT.CONTACT_INFO}>
                {tHtml('menu.contact_info')}
              </IconNavLink>
            </NavItem>
            <NavItem>
              <IconNavLink to={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}>
                {tHtml('menu.kyb')}
              </IconNavLink>
            </NavItem>
            {isInvestor() && (
              <NavItem>
                <IconNavLink to={RouteList.INVESTOR.FINANCIAL_RISK_CALCULATOR.VIEW}>
                  {tHtml('menu.financial_risk_calculator')}
                </IconNavLink>
              </NavItem>
            )}
            <NavItem>
              <IconNavLink to={RouteList.COMPANY.EDIT.MARKETING_SETTINGS}>
                {tHtml('menu.marketing_settings')}
              </IconNavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Sidebar);
