/**
 * Wrapper used for storing sensitive data in local storage.
 */
export class DataStorage {
  static get(key: string): string | null {
    return localStorage.getItem(key);
  }

  static set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  static setData<T>(key: string, value: T): void {
    localStorage.setItem(key, this.serialize(value));
  }

  static getData<T>(key: string): T | null {
    const data = localStorage.getItem(key);

    if (data === null) {
      return null;
    }
    const json = window.atob(data);

    try {
      return JSON.parse(json) as T;
    } catch (e) {
      return null;
    }
  }

  static serialize(value: any): string {
    return window.btoa(JSON.stringify(value));
  }

  static deserialize<T>(value: string): T | null {
    const json = window.atob(value);

    try {
      return JSON.parse(json) as T;
    } catch (e) {
      return null;
    }
  }

  static remove(key: string): void {
    try {
      if (localStorage.getItem(key) === null) {
        return;
      }

      localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }
}
