import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { action } from 'typesafe-actions';

import Api from 'api';
import { StoreApplicationRefinancingRequestDto } from 'api/types/project-developer/application';
import { setGlobalLoading } from 'modules/app/actions';
import { DatepickerInput } from 'components/Formik/Inputs';
import { PrimaryButton } from 'components/Formik';
import TextInput from 'components/Formik/TextInput';
import { redirect } from './CreateApplication';
import { ApplicationContext } from './module/ApplicationContext';
import { UPDATE_APPLICATION } from './module/ApplicationReducer';
import { ApplicationPageProps } from './steps';

interface IForm {
  debt_amount: string;
  debt_due_date: string;
  debt_purpose: string;
  debt_security_deposit: string;
  debt_security_deposit_value: string;
  debt_refinancing_reason: string;
}

const Refinancing: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { state, setApplicationId, dispatch } = useContext(ApplicationContext);

  useEffect(() => {
    if (applicationId !== undefined) {
      setApplicationId(applicationId);
    }

    setGlobalLoading(false);
  }, [t, applicationId, setApplicationId]);

  const [initialFormValues, setInitialFormValues] = useState<IForm>({
    debt_amount: '',
    debt_due_date: '',
    debt_purpose: '',
    debt_security_deposit: '',
    debt_security_deposit_value: '',
    debt_refinancing_reason: '',
  });

  useEffect(() => {
    setInitialFormValues({
      debt_amount: String(state.application?.refinancing?.debt_amount ?? ''),
      debt_due_date: String(state.application?.refinancing?.debt_due_date ?? ''),
      debt_purpose: String(state.application?.refinancing?.debt_purpose ?? ''),
      debt_security_deposit: String(state.application?.refinancing?.debt_security_deposit ?? ''),
      debt_security_deposit_value: String(
        state.application?.refinancing?.debt_security_deposit_value ?? '',
      ),
      debt_refinancing_reason: String(
        state.application?.refinancing?.debt_refinancing_reason ?? '',
      ),
    });
  }, [state]);

  const CreateApplicationSchema = Yup.object().shape({
    debt_amount: Yup.number().money().required(),
    debt_due_date: Yup.date().required(),
    debt_purpose: Yup.string().maxLengthLongText().required(),
    debt_security_deposit: Yup.string().maxLength(),
    debt_security_deposit_value: Yup.number().money(),
    debt_refinancing_reason: Yup.string().maxLengthLongText().required(),
  });

  const formatApplicationRefinancingStoreRequest = useCallback(
    (request: IForm): StoreApplicationRefinancingRequestDto => {
      return {
        debt_amount: Number(request.debt_amount),
        debt_due_date: request.debt_due_date,
        debt_purpose: request.debt_purpose,
        debt_security_deposit: request.debt_security_deposit,
        debt_security_deposit_value: request.debt_security_deposit_value
          ? Number(request.debt_security_deposit_value)
          : null,
        debt_refinancing_reason: request.debt_refinancing_reason,
      } as StoreApplicationRefinancingRequestDto;
    },
    [],
  );

  const onSubmit = useCallback(
    async (request: IForm, helpers: FormikHelpers<IForm>) => {
      try {
        const applicationRefinancingRequest: StoreApplicationRefinancingRequestDto =
          formatApplicationRefinancingStoreRequest(request);

        await Api.projectDeveloper.applications
          .storeApplicationRefinancing(applicationId, applicationRefinancingRequest)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [formatApplicationRefinancingStoreRequest, applicationId, dispatch, history, nextPage],
  );

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          <h1 className="mb-4 mb-md-6">{tHtml('application.refinancing')}</h1>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={CreateApplicationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'debt_amount'}
                      placeholder={t('placeholder.application.debt_amount')}
                    />
                  </div>
                  <div className="mb-3">
                    <DatepickerInput
                      hasFutureRange={true}
                      name={'debt_due_date'}
                      label={t('placeholder.application.debt_due_date')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'text'}
                      name={'debt_purpose'}
                      placeholder={t('placeholder.application.debt_purpose')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'text'}
                      name={'debt_security_deposit'}
                      placeholder={t('placeholder.application.debt_security_deposit')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'debt_security_deposit_value'}
                      placeholder={t('placeholder.application.debt_security_deposit_value')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'text'}
                      name={'debt_refinancing_reason'}
                      placeholder={t('placeholder.application.debt_refinancing_reason')}
                    />
                  </div>
                </FormGroup>
                <hr />
                <div className={'d-flex justify-content-between align-items-center'}>
                  <PrimaryButton
                    className={'btn btn-grey w-25'}
                    type={'button'}
                    onClick={() => {
                      redirect(history, previousPage, applicationId);
                    }}
                  >
                    {tHtml('common.back')}
                  </PrimaryButton>
                  <PrimaryButton className={'btn btn-primary w-25'} submitting={isSubmitting}>
                    {tHtml('common.continue')}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Row>
    </>
  );
};

export default withRouter(Refinancing);
