import styled from 'styled-components';
import { Badge } from 'reactstrap';

export const PromoText = styled.span`
  display: inline;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PromoCodeText = styled.span`
  overflow: hidden;
  max-width: 30ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
`;

export const PromoBadge = styled(Badge)`
  color: var(--profitus-button-color);
  background-color: var(--profitus-button-bg-color);
`;
