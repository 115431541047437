import Client from 'api/services/client';
import Http from 'api/http';
import { PaginationData, PaginationDataFilter } from '../../types';
import {
  BuyInvestmentRequestDto,
  ForSaleInvestmentProjectResponseDto,
  SecondaryMarketMyActiveInvestmentsResponseDto,
  SecondaryMarketMyForSaleInvestmentsResponseDto,
  SecondaryMarketProjectListResponseDto,
  SecondaryMarketSellerProjectResponseDto,
  SellInvestmentRequestDto,
} from '../../types/investor/secondary_market';

class InvestorSecondaryMarket extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_ALL_SECONDARY_MARKET_PROJECTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/secondary-market/buyer/for-sale-investments`,
      ),
      FETCH_SECONDARY_MARKET_PROJECT: this.protectedUrl(
        `${this.http.baseUrl}/investor/secondary-market/buyer/for-sale-investments/secondary-market-investment/investment/{secondaryMarketInvestmentId}`,
      ),
      BUY_INVESTMENT: this.protectedUrl(
        `${this.http.baseUrl}/investor/secondary-market/buyer/for-sale-investments/secondary-market-investment/investment/{secondaryMarketInvestmentId}/buy`,
      ),
      FETCH_PERSONAL_FOR_SALE_INVESTMENTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/secondary-market/seller/for-sale-investments`,
      ),
      CANCEL_INVESTMENT_SALE: this.protectedUrl(
        `${this.http.baseUrl}/investor/secondary-market/seller/for-sale-investments/secondary-market-investment/{secondaryMarketInvestmentId}/cancel`,
      ),
      FETCH_PERSONAL_ACTIVE_INVESTMENTS_LIST: this.protectedUrl(
        `${this.http.baseUrl}/investor/secondary-market/seller/investments`,
      ),
      FETCH_PERSONAL_ACTIVE_INVESTMENT: this.protectedUrl(
        `${this.http.baseUrl}/investor/secondary-market/seller/investments/investment/{investmentId}`,
      ),
      SELL_INVESTMENT: this.protectedUrl(
        `${this.http.baseUrl}/investor/secondary-market/seller/investments/investment/{investmentId}/sell`,
      ),
    };
  }

  fetchAllForSaleInvestmentList = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<SecondaryMarketProjectListResponseDto>> => {
    return this.http.get(this.api.FETCH_ALL_SECONDARY_MARKET_PROJECTS, { params: request });
  };

  fetchForSaleInvestment = (
    secondaryMarketInvestmentId: string | number,
  ): Promise<ForSaleInvestmentProjectResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_SECONDARY_MARKET_PROJECT, {
      secondaryMarketInvestmentId,
    });
    return this.http.get(url);
  };

  fetchPersonalForSaleInvestmentList = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<SecondaryMarketMyForSaleInvestmentsResponseDto>> => {
    return this.http.get(this.api.FETCH_PERSONAL_FOR_SALE_INVESTMENTS, { params: request });
  };

  fetchPersonalActiveInvestmentList = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<SecondaryMarketMyActiveInvestmentsResponseDto>> => {
    return this.http.get(this.api.FETCH_PERSONAL_ACTIVE_INVESTMENTS_LIST, { params: request });
  };

  fetchPersonalActiveInvestment = (
    investmentId: string | number,
  ): Promise<SecondaryMarketSellerProjectResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_PERSONAL_ACTIVE_INVESTMENT, { investmentId });
    return this.http.get(url);
  };

  buyInvestment = (
    request: BuyInvestmentRequestDto,
    secondaryMarketInvestmentId: string | number,
  ): Promise<SecondaryMarketSellerProjectResponseDto> => {
    const url = this.buildUrl(this.api.BUY_INVESTMENT, { secondaryMarketInvestmentId });
    return this.http.put(url, request);
  };

  sellInvestment = (
    request: SellInvestmentRequestDto,
    investmentId: string | number,
  ): Promise<SecondaryMarketSellerProjectResponseDto> => {
    const url = this.buildUrl(this.api.SELL_INVESTMENT, { investmentId });
    return this.http.post(url, request);
  };

  cancelInvestment = (
    secondaryMarketInvestmentId: string | number,
  ): Promise<SecondaryMarketSellerProjectResponseDto> => {
    const url = this.buildUrl(this.api.CANCEL_INVESTMENT_SALE, { secondaryMarketInvestmentId });
    return this.http.delete(url);
  };
}

export default InvestorSecondaryMarket;
