import React from 'react';
import { Card } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import useDisplay from 'helpers/useDisplay';

const ProjectCardSkeleton: React.FC = () => {
  const { isApp } = useDisplay();

  return (
    <>
      <Card className={'investment mb-4'}>
        <div className={'header'}>
          <Skeleton height={200} />
        </div>
        <div className={'body'}>
          <Skeleton height={220} />
          {!isApp && (
            <>
              <hr />
              <Skeleton height={96} />
              <hr />
              <Skeleton height={40} />
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default ProjectCardSkeleton;
