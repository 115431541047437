import React from 'react';
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext,
} from 'formik';
import useTranslation from 'helpers/useTranslation';

import { FunctionalButton } from 'components/Button';
import SingleSignatureForm from './SingleSignatureForm';
import { SingleSignatureIForm } from './ApplicationSignatures';

interface Props {
  values: SingleSignatureIForm[];
  fieldName: string;
  formikHelpers: any;
}

const SignaturesFormBlock: React.FC<Props> = ({ values, fieldName, formikHelpers }) => {
  const { tHtml } = useTranslation();
  const [, meta] = useField(fieldName);
  const formik = useFormikContext();
  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;

  const emptySignature: any = {
    first_name: null,
    last_name: null,
    email: '',
    responsibilities: '',
    disable: false,
  };

  return (
    <div className={meta.error ? 'is-invalid' : ''}>
      {isError && (
        <div className={'invalid-feedback-visible ms-2'}>
          {typeof meta.error === 'string' && <ErrorMessage name={fieldName} />}
        </div>
      )}
      <FieldArray
        name={fieldName}
        render={(arrayHelpers: FieldArrayRenderProps) => {
          const addShareholder = () => {
            arrayHelpers.push({ ...emptySignature });
          };

          return (
            <>
              <>
                {values.map((signature: any, index: number) => (
                  <SingleSignatureForm
                    signature={signature}
                    key={index}
                    index={index}
                    fieldName={fieldName}
                    formikHelpers={formikHelpers}
                    formikArrayHelpers={arrayHelpers}
                  />
                ))}
              </>
              <div className={'mt-4 mb-4'}>
                <FunctionalButton
                  type={'button'}
                  onClick={async () => {
                    addShareholder();
                  }}
                >
                  {tHtml('common.add_with_sign_short')}
                </FunctionalButton>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default SignaturesFormBlock;
