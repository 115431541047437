import React from 'react';
import useTranslation from 'helpers/useTranslation';

import { PayoutPaymentStatusEnum } from 'helpers/enums/PayoutPaymentStatusEnum';

type Props = {
  status: PayoutPaymentStatusEnum;
  isHiddenText?: boolean;
};

const PayoutPaymentStatusBadge: React.FC<Props> = ({ status, isHiddenText = false }) => {
  const { tHtml } = useTranslation();

  const mapIcon = (status: PayoutPaymentStatusEnum) => {
    switch (status) {
      case PayoutPaymentStatusEnum.PAID:
        return 'success';
      case PayoutPaymentStatusEnum.UNPAID:
        return 'warning';
      case PayoutPaymentStatusEnum.LATE:
        return 'danger';
    }
  };

  return (
    <div className={`payout-status-${status} d-flex flex-nowrap align-items-center`}>
      <i className={`icon icon-status-${mapIcon(status)} me-2`} />
      {!isHiddenText && tHtml(`payments.borrower_payouts.payment_status.${status}`)}
    </div>
  );
};

export default PayoutPaymentStatusBadge;
