import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { generatePath, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { SingleValue } from 'react-select/dist/declarations/src/types';

import Api from 'api';
import { RouteList } from 'routes';
import {
  StyledPartialReturnSectionCard,
  StyledPartialReturnSectionForm,
  StyledPartialReturnSectionFormSelect,
  StyledPartialReturnSectionFormSubmit,
  StyledPartialReturnSectionWrapper,
} from './PartialReturnSection.style';
import { StyledPaymentsLabel } from '../../Payments.style';
import PrimaryButton from 'components/Formik/PrimaryButton';
import { SelectOption } from 'components/Formik/SelectInput';

const PartialReturnSection: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<Array<SelectOption>>([]);
  const [selectedProject, setSelectedProject] = useState<SelectOption | null>(null);

  useEffect(() => {
    Api.projectDeveloper.payments.fetchProjects().then((response) => {
      setIsLoading(false);
      setProjects(
        response.map((project) => ({
          value: project.id,
          label: project.project_name
            ? project.private_id + ' ' + project.project_name
            : project.private_id,
        })),
      );
    });
  }, []);

  return (
    <StyledPartialReturnSectionWrapper>
      <StyledPaymentsLabel>{tHtml('payments.partial_return_section.title')}</StyledPaymentsLabel>
      <StyledPartialReturnSectionCard>
        <StyledPaymentsLabel>
          {tHtml('payments.partial_return_section.select_label')}
        </StyledPaymentsLabel>
        <StyledPartialReturnSectionForm className={'align-items-end'}>
          <StyledPartialReturnSectionFormSelect>
            <Select
              menuPlacement={'top'}
              options={projects}
              value={selectedProject}
              className={'fancy-select'}
              classNamePrefix={'fancy-select'}
              loadingMessage={() => t('common.loading')}
              noOptionsMessage={() => t('common.no_options')}
              placeholder={t('common.select')}
              isClearable={true}
              isSearchable={false}
              isLoading={isLoading}
              onChange={(option: SingleValue<SelectOption>) => {
                setSelectedProject(option);
              }}
            />
          </StyledPartialReturnSectionFormSelect>
          <StyledPartialReturnSectionFormSubmit>
            <PrimaryButton
              onClick={() => {
                selectedProject?.value &&
                  history.push(
                    generatePath(RouteList.PROJECT_DEVELOPER.PAYMENTS.PARTIAL_RETURN, {
                      projectId: selectedProject?.value,
                    }),
                  );
              }}
              disabled={!selectedProject?.value}
            >
              {tHtml('payments.partial_return_section.submit')}
            </PrimaryButton>
          </StyledPartialReturnSectionFormSubmit>
        </StyledPartialReturnSectionForm>
      </StyledPartialReturnSectionCard>
    </StyledPartialReturnSectionWrapper>
  );
};

export default PartialReturnSection;
