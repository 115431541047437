import React from 'react';
import { Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const PortfolioStatisticsSkeleton: React.FC = () => (
  <>
    <Row className={'py-2'}>
      <Col>
        <Skeleton height={300} />
      </Col>
    </Row>
    <Row className={'py-2'}>
      <Col>
        <Skeleton height={300} />
      </Col>
      <Col>
        <Skeleton height={300} />
      </Col>
    </Row>
    <Row className={'py-2'}>
      <Col>
        <Skeleton height={300} />
      </Col>
      <Col>
        <Skeleton height={300} />
      </Col>
    </Row>
  </>
);

export default PortfolioStatisticsSkeleton;
