import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Collapse, Container, Nav, Navbar, NavbarToggler, NavItem } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';

import { RouteList } from 'routes';
import ResponsiveLogo from 'components/Logo/ResponsiveLogo';
import ThemeSwitcher from 'components/ThemeSwitcher/ThemeSwitcher';
import LocaleSwitcher from 'components/LocaleSwitcher';
import { StyledDesktopHeader } from './Header.style';
import useDisplay from 'helpers/useDisplay';

interface Props {
  toggleMenuCallback: () => unknown;
}

const Header: React.FC<Props> = () => {
  const { tHtml } = useTranslation();
  const { isApp } = useDisplay();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <React.Fragment>
      <StyledDesktopHeader>
        <div className={'header-line'} />
        <Navbar expand={true}>
          <Container fluid>
            <ResponsiveLogo />
            <NavbarToggler onClick={toggle} />
            <Collapse navbar isOpen={isOpen}>
              <Nav className={'me-auto gap-3'} navbar>
                <NavItem>
                  <NavLink to={RouteList.HOME} exact>
                    {tHtml('menu.home')}
                  </NavLink>
                </NavItem>
              </Nav>
              <div className={'item mx-2'}>
                <React.Fragment>
                  <Link to={RouteList.GUEST.AUTHENTICATION.LOGIN}>{tHtml('common.login')}</Link>
                </React.Fragment>
              </div>
              <div className={'item mx-2'}>
                <LocaleSwitcher />
              </div>
              {!isApp && <ThemeSwitcher />}
            </Collapse>
          </Container>
        </Navbar>
      </StyledDesktopHeader>
    </React.Fragment>
  );
};

export default Header;
