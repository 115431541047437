import React from 'react';
import { Cell } from 'react-table';

import Tooltip from 'components/Tooltip';
import useTranslation from 'helpers/useTranslation';
import { InvestorInvestmentListResponseDto } from 'api/types/investor/investment';

interface Props {
  cell: Cell<InvestorInvestmentListResponseDto>;
}

export const NotSellableInvestmentTooltip = ({ cell }: Props) => {
  const { tHtml } = useTranslation();

  const randomValue = Math.floor(Math.random() * 100);
  const tooltipId = `investedat-${cell.row.index}-${randomValue}`;

  if (!cell.row.original.sellable) {
    return (
      <>
        <i className={'ms-1 icon icon-info-sm'} id={tooltipId} />
        <Tooltip
          id={tooltipId}
          text={tHtml('investments.tooltip.not_sellable')}
          placement={'right'}
        />
      </>
    );
  }

  return <></>;
};
