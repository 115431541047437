import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import { AffiliatePromotionBlock, AffiliateInformationBlock } from './index';
import { PageHeader } from 'components/Header';
import Api from 'api';
import { AffiliateResponseDto } from 'api/types/investor/affiliate';

const Affiliate: React.FC = () => {
  const { tHtml } = useTranslation();
  const [affiliateData, setAffiliate] = useState<AffiliateResponseDto | undefined>(undefined);

  useEffect(() => {
    Api.investor.affiliate.fetchAffiliate().then(setAffiliate);
  }, []);

  return (
    <div className={'affiliate-page'}>
      <PageHeader title={tHtml('affiliate.title')} />
      <AffiliatePromotionBlock />
      <AffiliateInformationBlock {...affiliateData} />
    </div>
  );
};

export default Affiliate;
