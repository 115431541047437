// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { default as env } from 'env.json';
import { ISocialNetwork } from './ISocialNetwork';
import { ExternalSocialSignUpResponse } from 'api/types';

export class FacebookSocialNetwork implements ISocialNetwork {
  setup(): Promise<void> {
    return new Promise((resolve) => {
      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          window.FB.logout();
          resolve();
        } else {
          window.FB.init({
            appId: env.FACEBOOK_APP_ID,
            cookie: true,
            status: true,
            xfbml: true,
            version: 'v8.0',
          });
          resolve();
        }
      }, true);
    });
  }

  signUp(): Promise<ExternalSocialSignUpResponse> {
    return new Promise((resolve, reject) => {
      window.FB.login(
        function ({ authResponse }) {
          if (authResponse) {
            const results: ExternalSocialSignUpResponse = {
              token: authResponse.accessToken,
            };
            resolve(results);
          } else {
            reject();
          }
        },
        { auth_type: 'reauthenticate', enable_profile_selector: true, scope: 'email' },
      );
    });
  }
}
