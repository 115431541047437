import React from 'react';
import useTranslation from 'helpers/useTranslation';
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext,
} from 'formik';

import { FunctionalButton } from 'components/Button';
import { ProspectFinancialObligationsDto } from 'api/types/guest/prospectApplication';
import SingleCompanyExperienceForm from './SingleCompanyExperienceForm';

interface Props {
  values: any;
  fieldName: string;
  formikHelpers: any;
}

const CompanyExperienceFormBlock: React.FC<Props> = ({ values, fieldName, formikHelpers }) => {
  const { tHtml } = useTranslation();
  const [, meta] = useField(fieldName);
  const formik = useFormikContext();
  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;
  const initialObligation: ProspectFinancialObligationsDto = {
    amount: 0,
    repayment_deadline: '',
    security_measure: '',
    purpose: '',
    creditor: '',
  };

  return (
    <div className={isError ? 'is-invalid' : ''}>
      {isError && (
        <div className={'invalid-feedback-visible ms-2'}>
          {typeof meta.error === 'string' && <ErrorMessage name={fieldName} />}
        </div>
      )}
      <FieldArray
        name={fieldName}
        render={(arrayHelpers: FieldArrayRenderProps) => {
          return (
            <>
              <>
                {values?.map(
                  (financialObligation: ProspectFinancialObligationsDto, index: number) => {
                    return (
                      <SingleCompanyExperienceForm
                        financialObligation={financialObligation}
                        index={index}
                        key={index}
                        formikHelpers={formikHelpers}
                        formikArrayHelpers={arrayHelpers}
                      />
                    );
                  },
                )}
              </>

              <div className={'mt-4 mb-4'}>
                <FunctionalButton
                  type={'button'}
                  onClick={async () => {
                    arrayHelpers.push({ ...initialObligation });
                  }}
                >
                  {tHtml('prospect_application.security_deposits.add_deposit')}
                </FunctionalButton>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default CompanyExperienceFormBlock;
