import React, { useEffect, useState } from 'react';

import { RouteList } from 'routes';
import LightLogo from 'assets/images/logo/logo-light.svg';
import DarkLogo from 'assets/images/logo/logo-dark.svg';
import AppLogo from 'assets/images/logo/app-logo.svg';
import { ThemeEnum } from 'helpers/enums/ThemeEnum';
import { CommonEnum } from 'helpers/enums/CommonEnum';
import { useLocalStorage } from 'helpers';
import { StyledLogo } from './Logo.style';

const Logo: React.FC<{ path?: string; target?: '_self' | '_blank'; isApp?: boolean }> = ({
  path = RouteList.HOME,
  target = '_self',
  isApp = false,
}) => {
  const [theme] = useLocalStorage<string>(CommonEnum.Theme, ThemeEnum.Light);
  const [currentLogo, setCurrentLogo] = useState<any>();

  useEffect(() => {
    switch (theme) {
      case ThemeEnum.Light:
        setCurrentLogo(LightLogo);
        break;
      case ThemeEnum.Dark:
        setCurrentLogo(DarkLogo);
        break;
    }
  }, [theme]);

  return (
    <StyledLogo>
      <a href={path} target={target}>
        <img src={isApp ? AppLogo : currentLogo} className={'logo'} alt={'logo'} />
      </a>
    </StyledLogo>
  );
};

export default Logo;
