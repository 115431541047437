import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { generatePath, Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { RouteList } from 'routes';
import { ProjectDeveloperProjectListItemResponseDto } from 'api/types/project-developer/project';
import { DataBlock } from 'components/DataBlock';
import ProjectCardLink from 'components/ProjectCardLink';

const MyProjectCard: React.FC<ProjectDeveloperProjectListItemResponseDto> = (props) => {
  const { t, tHtml } = useTranslation();

  return (
    <>
      <Card className={'investment developer mb-4'}>
        <div className={'header'}>
          <div className={'thumbnail'}>
            <ProjectCardLink {...props} />
          </div>
        </div>
        <div className={'body'}>
          <div className={'top mb-3'}>
            <div className={'title'}>{[props.pid, props.project_name].join(' ')}</div>
          </div>
          <div className={'bottom'}>
            <Row lg={4} md={2} sm={2} xs={2}>
              <Col>
                <DataBlock
                  title={t('projects.financial_information.required_amount')}
                  data={tHtml('common.money', { value: props.required_amount })}
                />
              </Col>
              <Col>
                <DataBlock
                  title={tHtml('projects.term')}
                  data={tHtml('common.month', { value: props.credit_duration })}
                />
              </Col>
              <Col>
                <DataBlock
                  title={tHtml('projects.interest')}
                  data={tHtml('common.percents', { value: props.basic_interest })}
                />
              </Col>
              <Col>
                {props.funded_duration !== null ? (
                  <DataBlock
                    title={tHtml('projects.funded_duration')}
                    data={
                      props.funded_duration === 0
                        ? (props.funded_duration + 1).toString()
                        : props.funded_duration.toString() ?? '?'
                    }
                  />
                ) : (
                  <DataBlock
                    title={tHtml('projects.left_to_invest')}
                    data={props.days_to_get_money?.toString() ?? '?'}
                  />
                )}
              </Col>
            </Row>
            <Row lg={4} md={2} sm={2} xs={2}>
              <Col>
                <DataBlock
                  title={tHtml('projects.invested_amount')}
                  data={tHtml('common.money', { value: props.invested_amount })}
                />
              </Col>
              <Col>
                <DataBlock
                  title={tHtml('projects.investors')}
                  data={props.investors?.toString() ?? '?'}
                />
              </Col>
              {props.open_for_investments_at && (
                <Col>
                  <DataBlock
                    title={tHtml('projects.project_open')}
                    data={t('common.date', { date: props.open_for_investments_at })}
                  />
                </Col>
              )}
              {props.last_payment && (
                <Col>
                  <DataBlock
                    title={tHtml('projects.last_payment_done')}
                    data={props.last_payment}
                  />
                </Col>
              )}
            </Row>
            <div className={'more'}>
              <Link
                to={{
                  pathname: generatePath(RouteList.PROJECT_DEVELOPER.PROJECTS.VIEW, {
                    projectId: props.id,
                  }),
                }}
              >
                {tHtml('projects.more')}
              </Link>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default MyProjectCard;
