import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { InvestorKybDataV2RequestDto } from 'api/types/shared/company/profile';
import { RouteList } from 'routes';
import YesNoBlock from 'components/YesNoBlock';
import { DataBlock } from 'components/DataBlock';
import NotFilledProfileSection from 'components/Profile/NotFilledProfileSection';
import CompanyProfileSection from 'scopes/company/components/CompanyProfileSection';
import { CompanyMenuEnum } from 'helpers/enums/CompanyMenuEnum';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';
import { formatMultipleSelectValues } from 'helpers';

const InvestorKybV2: React.FC = () => {
  const { tHtml } = useTranslation();
  const { data: companyProfileData } = useUserCompanyProfileQuery();

  const kyb = companyProfileData?.know_your_business?.kyb_data as
    | InvestorKybDataV2RequestDto
    | undefined;

  if (!kyb) {
    return (
      <NotFilledProfileSection
        title={tHtml('menu.kyb')}
        buttonLink={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}
      />
    );
  }

  return (
    <CompanyProfileSection
      name={CompanyMenuEnum.KnowYourBusiness}
      title={tHtml('menu.kyb')}
      buttonLink={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}
    >
      <div className={'content'}>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.purpose')}
            data={formatMultipleSelectValues(kyb?.purpose, kyb?.other_purpose_text)}
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.activities')}
            data={formatMultipleSelectValues(kyb?.activities, kyb?.other_activities_text)}
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.company.ytd_income')}
            data={kyb?.average_income?.toString() ?? ''}
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.company.planned_investment_amount')}
            data={
              kyb?.planned_investment_amount
                ? tHtml(`placeholder.${kyb?.planned_investment_amount}`)
                : ''
            }
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.company.cash_usage')}
            data={
              kyb?.cash_usage
                ? tHtml(`placeholder.prospect_application.cash_usage.${kyb?.cash_usage}`)
                : ''
            }
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.company.activity_regions')}
            data={formatMultipleSelectValues(
              kyb?.activity_regions,
              kyb?.other_activity_regions_text,
            )}
          />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml(
              'placeholder.company.is_not_operating_in_russia_belarus_ukraine_occupied_territories',
            )}
            checked={kyb?.is_not_operating_in_russia_belarus_ukraine_occupied_territories}
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.company.business_partners')}
            data={kyb?.business_partners}
          />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml('placeholder.company.has_bankruptcy_procedure')}
            checked={kyb?.has_bankruptcy_procedure}
          />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock label={tHtml('placeholder.company.convicted')} checked={kyb?.convicted} />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml(
              'placeholder.prospect_application.has_shareholders_with_more_than_25_percent',
            )}
            checked={kyb?.has_shareholders_with_more_than_25_percent}
          />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml(
              'placeholder.prospect_application.public_positions_held_by_related_individuals',
            )}
            checked={kyb?.public_positions_held_by_related_individuals}
          />
        </div>
      </div>
    </CompanyProfileSection>
  );
};

export default withRouter(InvestorKybV2);
