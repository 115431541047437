import React from 'react';
import { ButtonProps } from 'reactstrap';

type LocalButtonProps = {
  loading?: boolean;
};

export const PrimaryButton: React.FC<
  LocalButtonProps & ButtonProps & React.HTMLProps<HTMLButtonElement>
> = ({ children, icon, ...props }) => {
  return (
    <button className={'site btn btn-primary'} {...props}>
      {icon && (
        <>
          <i className={`align-middle ${icon}`} />{' '}
        </>
      )}
      {children}
    </button>
  );
};

export default PrimaryButton;
