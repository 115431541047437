import React from 'react';
import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import * as Roles from 'helpers/auth/roles';
import PageLayout from 'scopes/investor/containers/PageLayout';
import { Affiliate } from '.';

const AffiliateRoutes: any = () => [
  <AuthRoute
    path={RouteList.INVESTOR.AFFILIATE.VIEW}
    component={Affiliate}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    key={RouteList.INVESTOR.AFFILIATE.VIEW}
    exact
  />,
];

export default AffiliateRoutes;
