import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik } from 'formik';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import Api from 'api';
import { UserProfileMarketingSettingsRequestDto } from 'api/types/shared/user/profile';
import { RouteList } from 'routes';
import CheckboxInput from 'components/Formik/CheckboxInput';
import { setGlobalLoading } from 'modules/app/actions';
import { success } from 'services/toastr';
import { navigate } from 'helpers';
import Flag from 'components/Flag';
import { LanguageEnum } from 'helpers/enums/LanguageEnum';
import ThemeSwitcher from 'components/ThemeSwitcher/ThemeSwitcher';
import { PrimaryButton } from 'components/Formik';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import useDisplay from 'helpers/useDisplay';
import { UserMarketingSettingTypeEnum } from 'helpers/enums/UserMarketingSettingTypeEnum';
import {
  CALL_MARKETING_SETTINGS,
  EMAIL_MARKETING_SETTINGS,
  SMS_MARKETING_SETTINGS,
} from 'helpers/constants/UserMarketingSettingTypes';

const MarketingSettings: React.FC = () => {
  const { tHtml, currentLanguage } = useTranslation();
  const { isApp } = useDisplay();

  const { data: userProfileData, setQueryData: updateUserProfileData } = useUserProfileQuery();

  useEffect(() => {
    if (userProfileData) {
      setUpdateMarketingSettingsRequest({
        marketing_settings: userProfileData?.marketing_settings ?? [],
        channel_notification_language: userProfileData?.user?.channel_notification_language ?? '',
      });
      setGlobalLoading(false);
    } else {
      setGlobalLoading(true);
    }
  }, [userProfileData]);

  const [updateMarketingSettingsRequest, setUpdateMarketingSettingsRequest] =
    useState<UserProfileMarketingSettingsRequestDto>({
      marketing_settings: [],
      channel_notification_language: '',
    });

  const [isOpen, setOpen] = useState<boolean>(false);

  const onSubmit = useCallback(
    async (request: UserProfileMarketingSettingsRequestDto) => {
      const response = await Api.shared.user.profile.updateUserProfileMarketingSettings(request);
      updateUserProfileData(response);
      success(tHtml('common.updated_successfully'));
      navigate(RouteList.USER.PROFILE.HOME);
    },
    [tHtml, updateUserProfileData],
  );

  const onPhoneUpdatePermission = useCallback(
    (index: number) => {
      if (updateMarketingSettingsRequest) {
        updateMarketingSettingsRequest.marketing_settings[index].via_phone =
          !updateMarketingSettingsRequest.marketing_settings[index].via_phone;

        setUpdateMarketingSettingsRequest((prevState) => ({
          ...prevState,
          marketing_settings: updateMarketingSettingsRequest.marketing_settings,
        }));
      }
    },
    [updateMarketingSettingsRequest],
  );

  const onEmailUpdatePermission = useCallback(
    (index: number) => {
      if (updateMarketingSettingsRequest) {
        setUpdateMarketingSettingsRequest((prevState) => {
          const newState = { ...prevState };
          newState.marketing_settings[index].via_email =
            !newState.marketing_settings[index].via_email;
          return newState;
        });
      }
    },
    [updateMarketingSettingsRequest],
  );

  const onSmsUpdatePermission = useCallback(
    (index: number) => {
      if (updateMarketingSettingsRequest) {
        setUpdateMarketingSettingsRequest((prevState) => {
          const newState = { ...prevState };
          newState.marketing_settings[index].via_sms = !newState.marketing_settings[index].via_sms;
          return newState;
        });
      }
    },
    [updateMarketingSettingsRequest],
  );

  const onChangeLanguage = useCallback(
    (lang: string) => {
      if (updateMarketingSettingsRequest) {
        setUpdateMarketingSettingsRequest((prevState) => ({
          ...prevState,
          channel_notification_language: lang,
        }));
      }
    },
    [updateMarketingSettingsRequest],
  );

  return (
    <React.Fragment>
      <h1>{tHtml('menu.marketing_settings')}</h1>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={updateMarketingSettingsRequest}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <div className={'text mb-4'}>
                <span className={'text'}>{tHtml('profile.notifications.description')}</span>
              </div>
              {userProfileData?.marketing_settings && (
                <div className="marketing-settings mb-4">
                  <div className={'profile-marketing-settings--header'}>
                    {tHtml('profile.notifications.email')}
                  </div>
                  <div className={'profile-marketing-settings--body'}>
                    {userProfileData?.marketing_settings.map((setting, index) => {
                      if (
                        !EMAIL_MARKETING_SETTINGS.includes(
                          setting.name as UserMarketingSettingTypeEnum,
                        )
                      )
                        return '';
                      return (
                        <div key={`email-marketing-settings-${index}`}>
                          <CheckboxInput
                            name={`marketing_settings[${index}].via_email`}
                            showPlaceholder={true}
                            placeholder={tHtml(setting.name ?? '--')}
                            onChange={() => onEmailUpdatePermission(index)}
                            checked={setting.via_email}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {userProfileData?.marketing_settings && (
                <div className="marketing-settings mb-4">
                  <div className={'profile-marketing-settings--header'}>
                    {tHtml('profile.notifications.call')}
                  </div>
                  <div className={'profile-marketing-settings--body'}>
                    {userProfileData?.marketing_settings.map((setting, index) => {
                      if (
                        !CALL_MARKETING_SETTINGS.includes(
                          setting.name as UserMarketingSettingTypeEnum,
                        )
                      )
                        return '';

                      return (
                        <div key={`call-marketing-settings-${index}`}>
                          <CheckboxInput
                            name={`marketing_settings[${index}].via_phone`}
                            showPlaceholder={true}
                            placeholder={tHtml(setting.name ?? '--')}
                            onChange={() => onPhoneUpdatePermission(index)}
                            checked={setting.via_phone}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {userProfileData?.marketing_settings && (
                <div className="marketing-settings mb-4">
                  <div className={'profile-marketing-settings--header'}>
                    {tHtml('profile.notifications.sms')}
                  </div>
                  <div className={'profile-marketing-settings--body'}>
                    {userProfileData?.marketing_settings?.map((setting, index) => {
                      if (
                        !SMS_MARKETING_SETTINGS.includes(
                          setting.name as UserMarketingSettingTypeEnum,
                        )
                      )
                        return '';

                      return (
                        <div key={`sms-marketing-settings-${index}`}>
                          <CheckboxInput
                            name={`marketing_settings[${index}].via_sms`}
                            showPlaceholder={true}
                            placeholder={tHtml(setting.name ?? '--')}
                            onChange={() => onSmsUpdatePermission(index)}
                            checked={setting.via_sms}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <hr />
              <div className={'mb-4 d-flex'}>
                <span className={'text me-2'}>
                  <strong>{tHtml('profile.notifications.receive_information_language')}</strong>
                </span>
                <Dropdown
                  className={'custom-dropdown'}
                  isOpen={isOpen}
                  toggle={() => setOpen((prev) => !prev)}
                >
                  <DropdownToggle className={isOpen ? 'open' : ''} caret>
                    <Flag
                      countryCode={
                        updateMarketingSettingsRequest?.channel_notification_language ??
                        currentLanguage
                      }
                    />
                    {(
                      updateMarketingSettingsRequest?.channel_notification_language ??
                      currentLanguage
                    ).toUpperCase()}
                    <span className="caret" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {Object.entries(LanguageEnum)
                      .filter(
                        ([, value]) =>
                          value != updateMarketingSettingsRequest?.channel_notification_language ??
                          currentLanguage,
                      )
                      .map(([, value], idx) => {
                        return (
                          <DropdownItem
                            key={idx}
                            onClick={() => {
                              onChangeLanguage(value);
                            }}
                          >
                            <Flag countryCode={value} /> {value.toUpperCase()}
                          </DropdownItem>
                        );
                      })}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <hr />
              {!isApp && (
                <div className="mb-4">
                  <ThemeSwitcher />
                </div>
              )}
              <div className={'mt-5 d-flex flex-row-reverse'}>
                <PrimaryButton
                  type={'submit'}
                  submitting={isSubmitting}
                  className={'site btn btn-primary'}
                >
                  {tHtml('common.save')}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default withRouter(MarketingSettings);
