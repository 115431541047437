import React, { useCallback, useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { useField } from 'formik';
import { MultiValueGenericProps } from 'react-select';
import CreatableSelectInput, { ReactSelectProps } from 'components/Formik/CreatableSelectInput';
import { SelectOption } from 'components/Formik/SelectInput';
import Api from 'api';
import { PromoBadge, PromoCodeText, PromoText } from './SelectPromoCodesInput.styles';
import { BonusConditionEnum, BonusSystemBriefResponseDto } from 'api/types/investor/bonus_system';

interface Props extends Omit<ReactSelectProps, 'options'> {
  name: string;
  project_id: string | number;
  investmentAmount: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  isDisabled?: boolean;
  isSubmittable?: boolean;
  validations: { bonus_system_code: string; message: string }[];
}

const SelectPromoCodesInput: React.FC<Props> = ({
  name,
  project_id,
  isDisabled = false,
  isSubmittable = false,
  investmentAmount,
  setFieldValue,
  validations,
  ...props
}) => {
  const [promoCodes, setPromoCodes] = useState<BonusSystemBriefResponseDto[] | undefined>(
    undefined,
  );
  const [options, setOptions] = useState<SelectOption[] | undefined>();

  const { t, tHtml } = useTranslation();
  const [field] = useField(name);

  useEffect(() => {
    Api.investor.bonus_system.fetchBonusSystemBrief({ project_id }).then(setPromoCodes);
  }, [project_id]);

  useEffect(() => setFieldValue(name, []), [setFieldValue, name, investmentAmount]);

  const formatOptionLabel = (data: SelectOption): JSX.Element => {
    const promo = promoCodes?.find((p) => p.code === data.value);

    return (
      <div>
        <div className={'col-auto'}>
          <PromoCodeText>{promo?.code ?? data.label}</PromoCodeText>
        </div>
        {promo && (
          <div className={'col-auto'}>
            <span className={'p-0 m-0 label-description-font-size d-flex gap-1'}>
              <PromoBadge>
                {promo.summable
                  ? tHtml('bonus_system.summable_yes')
                  : tHtml('bonus_system.summable_no')}
              </PromoBadge>
              <PromoBadge>{!promo.sellable && tHtml('bonus_system.sellable_no')}</PromoBadge>
              {promo.bonus_condition === BonusConditionEnum.AMOUNT ? (
                <PromoBadge color={'primary'}>
                  +{t('common.money', { value: promo.amount })}
                </PromoBadge>
              ) : null}
              {promo.bonus_condition === BonusConditionEnum.PERCENTAGE ? (
                <PromoBadge color={'dark'}>
                  +{t('common.percents', { value: promo.percentage })}
                </PromoBadge>
              ) : null}
            </span>
          </div>
        )}
      </div>
    );
  };

  const MultiValueLabel = (props: MultiValueGenericProps<SelectOption>) => {
    return (
      <PromoText
        className={`selected-value p-1 ${
          validations.some((item) => Object.values(item).includes(props.data.label)) ? 'error' : ''
        }`}
      >
        {props.data.label}
      </PromoText>
    );
  };

  const isAllSummable = useCallback(() => {
    if (Array.isArray(field.value)) {
      if (field.value.length == 0) {
        return undefined;
      }

      return field.value.every((val) => {
        const isSummable = promoCodes?.find((p) => p.code === val)?.summable;

        if (isSummable === undefined) {
          return true;
        }

        return isSummable;
      });
    }

    return !!promoCodes?.find((p) => p.code === field.value)?.summable;
  }, [field.value, promoCodes]);

  useEffect(() => {
    const isSummable = isAllSummable();

    setOptions(() => {
      if (promoCodes) {
        return [
          ...promoCodes.map((p) => ({
            label: p.code,
            value: p.code,
            isDisabled: !(isSummable === undefined || (isSummable && p.summable)),
          })),
        ];
      }

      return undefined;
    });
  }, [field.value, isAllSummable, promoCodes]);

  return (
    <>
      <CreatableSelectInput
        isDisabled={isDisabled}
        isSubmittable={isSubmittable}
        isMulti={true}
        options={options}
        name={name}
        formatOptionLabel={formatOptionLabel}
        components={{ MultiValueLabel }}
        isSearchable={isAllSummable()}
        placeholder={t('common.select')}
        noOptionsNewMessage={t('projects.investment_modal.no_bonus_options')}
        hideLabel={true}
        allowCreateWhileLoading={false}
        formatCreateLabel={(input) => (
          <>{tHtml('projects.investment_modal.apply_bonus_code', { value: input })}</>
        )}
        {...props}
      />
      {validations.length > 0 && (
        <span>
          {validations.map((item) => (
            <div key={item.bonus_system_code} className="invalid-feedback-visible">
              {item.message} - {item.bonus_system_code}
            </div>
          ))}
        </span>
      )}
    </>
  );
};

export default SelectPromoCodesInput;
