import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Api from 'api';
import { UpdateUserProfileContactInfoRequestDto } from 'api/types/shared/user/profile';
import PrimaryButton from 'components/Button/PrimaryButton';
import TextInput from 'components/Formik/TextInput';
import { success } from 'services/toastr';
import { navigate } from 'helpers';
import { RouteList } from 'routes';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import TaxModal from 'components/Modal/Modals/TaxModal';
import ChangeResidenceModal from './components/modals/ChangeResidenceModal';
import { DatepickerInput, PhoneNumberInput } from 'components/Formik/Inputs';
import { WHITELISTED_TAX_COUNTRIES } from 'helpers/constants/whitelistedTaxCountries';
import { SelectCountryInput } from 'components/Formik/Selects';
import { useAccountQuery } from 'api/queries';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import usePermissions from 'helpers/usePermissions';
import { useStepsModalStateStore } from '../../../investor/modals/RequiredSteps/store';

const ContactInfo: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const modalState = useStepsModalStateStore();
  const { refreshPermissions } = usePermissions();
  const { invalidate: invalidateAccount } = useAccountQuery();
  const { data: userProfileData, setQueryData: updateUserProfileData } = useUserProfileQuery();

  useEffect(() => {
    modalState.setShouldBeShown({ state: true });
    setUpdateContactInfoRequest({
      first_name: userProfileData?.user?.natural?.first_name ?? '',
      middle_name: userProfileData?.user?.natural?.middle_name ?? '',
      last_name: userProfileData?.user?.natural?.last_name ?? '',
      email: userProfileData?.user?.email ?? '',
      dob: userProfileData?.user?.natural?.dob ?? '',
      residence: userProfileData?.user?.natural?.residence ?? '',
      city: userProfileData?.user?.natural?.city ?? '',
      street: userProfileData?.user?.natural?.street ?? '',
      house_number: userProfileData?.user?.natural?.house_number ?? '',
      phone: userProfileData?.user?.natural?.phone ?? '',
      gov_code: userProfileData?.user?.natural?.gov_code ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData]);

  const onSubmit = useCallback(
    async (
      request: UpdateUserProfileContactInfoRequestDto,
      helpers: FormikHelpers<UpdateUserProfileContactInfoRequestDto>,
    ) => {
      try {
        const response = await Api.shared.user.profile.updateUserProfileContactInfo(request);
        updateUserProfileData(response);
        refreshPermissions();
        invalidateAccount();
        success(tHtml('common.updated_successfully'));

        if (request.email !== response.user?.email) {
          success(tHtml('common.confirm_email'));
        }

        navigate(RouteList.USER.PROFILE.HOME);
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [invalidateAccount, refreshPermissions, tHtml, updateUserProfileData],
  );

  const [updateContactInfoRequest, setUpdateContactInfoRequest] =
    useState<UpdateUserProfileContactInfoRequestDto>({
      first_name: userProfileData?.user?.natural?.first_name ?? '',
      middle_name: userProfileData?.user?.natural?.middle_name ?? '',
      last_name: userProfileData?.user?.natural?.last_name ?? '',
      email: userProfileData?.user?.email ?? '',
      dob: userProfileData?.user?.natural?.dob ?? '',
      residence: userProfileData?.user?.natural?.residence ?? '',
      city: userProfileData?.user?.natural?.city ?? '',
      street: userProfileData?.user?.natural?.street ?? '',
      house_number: userProfileData?.user?.natural?.house_number ?? '',
      phone: userProfileData?.user?.natural?.phone ?? '',
      gov_code: userProfileData?.user?.natural?.gov_code ?? '',
    });

  const userHasInvestments = !!userProfileData?.user?.has_invested;

  const getValidationRules = (): any => {
    let rules: any = {
      middle_name: Yup.string().middleName(),
      email: Yup.string().email().required(),
      city: Yup.string().required().max(100),
      street: Yup.string().required().max(100),
      house_number: Yup.string().required().max(10),
      phone: Yup.string().phone().required(),
    };

    if (!userProfileData?.user?.verified) {
      rules = {
        ...rules,
        first_name: Yup.string().firstName().required(),
        last_name: Yup.string().lastName().required(),
        dob: Yup.date().max(new Date()).required(),
        gov_code: Yup.string().governmentCode('residence').required(),
      };
    } else {
      rules = {
        ...rules,
        first_name: Yup.string().firstName().nullable(),
        last_name: Yup.string().lastName().nullable(),
        dob: Yup.date().max(new Date()).nullable(),
        gov_code: Yup.string().governmentCode('residence').nullable(),
      };
    }

    if (!userHasInvestments) {
      rules = {
        ...rules,
        residence: Yup.string().required().max(255),
      };
    }

    return rules;
  };

  const UpdateContactInfoSchema = Yup.object().shape(getValidationRules());

  return (
    <React.Fragment>
      <h1>{tHtml('menu.contact_info')}</h1>
      <div>
        <Formik
          initialValues={updateContactInfoRequest}
          validationSchema={UpdateContactInfoSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <div className={'form-input'}>
                <TextInput disabled={userProfileData?.user?.verified ?? true} name="first_name" />
              </div>
              <div className={'form-input'}>
                <TextInput disabled={userProfileData?.user?.verified ?? true} name="middle_name" />
              </div>
              <div className={'form-input'}>
                <TextInput disabled={userProfileData?.user?.verified ?? true} name="last_name" />
              </div>
              <div className={'form-input'}>
                <TextInput name="email" />
              </div>
              <div className={'mb-3'}>
                <DatepickerInput
                  disabled={userProfileData?.user?.verified ?? true}
                  name={'dob'}
                  label={t('placeholder.date_of_birth')}
                  maxDate={new Date()}
                />
              </div>
              <div className={'form-input'}>
                <SelectCountryInput
                  name={'residence'}
                  placeholder={t('placeholder.residence')}
                  isDisabled={userHasInvestments}
                  onChange={(e: any) => {
                    if (!WHITELISTED_TAX_COUNTRIES.includes(e.value)) showModal(<TaxModal />);
                  }}
                  hasTooltip={true}
                  tooltipContent={tHtml('authentication.contact_info.residence.tooltip')}
                />
                {userHasInvestments && (
                  <small
                    className={'ms-2 pointer font-tiny'}
                    onClick={() => showModal(<ChangeResidenceModal />)}
                  >
                    {tHtml('profile.edit.want_to_change_residence')}
                  </small>
                )}
              </div>
              <div className={'form-input'}>
                <TextInput name="city" />
              </div>
              <div className={'form-input'}>
                <TextInput name="street" />
              </div>
              <div className={'form-input'}>
                <TextInput name="house_number" />
              </div>
              <div className={'form-input'}>
                <PhoneNumberInput
                  name="phone"
                  label={t('placeholder.phone')}
                  dependencyFieldName="residence"
                />
              </div>
              <div className={'form-input'}>
                <TextInput disabled={userProfileData?.user?.verified ?? true} name="gov_code" />
              </div>
              <div className={'mt-5 d-flex flex-row-reverse'}>
                <PrimaryButton type="submit">{tHtml('common.save')}</PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactInfo);
