import React from 'react';
import { Table } from 'reactstrap';
import { StyledTd, StyledTh, StyledTr } from './ProjectRiskTable.style';
import useTranslation from 'helpers/useTranslation';

export const RiskCategory: { [key in number]: string } = {
  1: '0.0% - 3.7%',
  2: '3.71% - 4.7%',
  3: '4.71% - 5.7%',
  4: '5.71% - 6.7%',
  5: '6.71% - 7.7%',
  6: '7.71% - 8.7%',
  7: '8.71% - 9.7%',
  8: '9.71% - 10.7%',
  9: '10.71% - 11.7%',
  10: '11.71% - 100.0%',
};

interface ProjectRiskTableProps {
  riskCategory?: string | null;
}

const ProjectRiskTable: React.FC<ProjectRiskTableProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <StyledTh>{t('projects.owner.risk_category')}</StyledTh>
          <StyledTh>{t('projects.owner.risk_probability')}</StyledTh>
        </tr>
      </thead>
      <tbody>
        {Object.entries(RiskCategory).map(([key, value]) => (
          <StyledTr isSelected={key === props.riskCategory} key={key}>
            <StyledTd>{key}</StyledTd>
            <StyledTd>{value}</StyledTd>
          </StyledTr>
        ))}
      </tbody>
    </Table>
  );
};

export default ProjectRiskTable;
