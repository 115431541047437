import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import { BaseLayout } from 'containers';
import Header from './Header/Desktop/Header';
import Sidebar from './Sidebar/Sidebar';
import useDisplay from 'helpers/useDisplay';
import HeaderMobile from './Header/Mobile/HeaderMobile';
import Footer from 'components/Footer';
import { StyledSidebarPageLayout } from './SidebarPageLayout.style';
import InfoBlockContainer from './InfoBlockContainer';
import BottomNavigation from './BottomNavigation';

interface Props extends RouteComponentProps {
  isProfileLayout?: boolean;
}

const PageLayout: React.FC<Props> = ({ children, isProfileLayout = false }) => {
  const { isMobile, isApp } = useDisplay();
  const [isOpen, setIsOpen] = useState(!isMobile);
  const toggle = () => setIsOpen((value) => !value);

  if (!isOpen && !isMobile) setIsOpen(true);

  return (
    <React.Fragment>
      <BaseLayout>
        <StyledSidebarPageLayout isApp={isApp}>
          <div id={'layout-wrapper'}>
            <div className={'d-block d-lg-none'}>
              <HeaderMobile toggleMenuCallback={toggle} />
            </div>
            <div className={'d-none d-lg-block'}>
              <Header toggleMenuCallback={toggle} />
            </div>
            {((isProfileLayout && isMobile) || !isProfileLayout) && (
              <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
            )}
            <div className={'main-content'}>
              <Container>
                {!isProfileLayout ? (
                  <Row>
                    <InfoBlockContainer />
                  </Row>
                ) : null}
                <Row>{children}</Row>
              </Container>
            </div>
          </div>
          {isApp && (
            <div className={'d-block d-lg-none'}>
              <BottomNavigation />
            </div>
          )}
          {(!isApp || isProfileLayout) && <Footer />}
        </StyledSidebarPageLayout>
      </BaseLayout>
    </React.Fragment>
  );
};

export default withRouter(PageLayout);
