import React from 'react';
import { RouteList } from 'routes';
import { Container } from 'reactstrap';
import { Step } from 'containers/StepFormLayout/Step';
import {
  CompanyDetails,
  CompanyExecutive,
  CreateApplication,
  CreditDetails,
  SecurityDeposits,
} from './index';
import StepFormHeader from 'containers/StepFormLayout/StepFormHeader';
import StepFormStepsWizard from 'containers/StepFormLayout/StepFormStepsWizard';
import { StepFormLayout } from 'containers';
import { ApplicationContextProvider } from './module/ApplicationContext';
import BasicCompanyInfo from './BasicCompanyInfo';
import CompanyExperience from './Experience/CompanyExperience';
import ProspectCompanyBeneficiaries from './Beneficiaries/ProspectCompanyBeneficiaries';
import CompanyDocuments from './CompanyDocuments';
import ApplicationSignatures from './Signatures/ApplicationSignatures';
import Success from './Success';

export interface ApplicationStep extends Step {
  component: React.FC<any> | React.ComponentClass<any>;
  isLastStep?: boolean;
}

export interface ApplicationPageProps {
  previousPage?: string;
  nextPage?: string;
  isLastStep?: boolean;
}

const withWizard = (
  Component: React.FC<any> | React.ComponentClass<any>,
  steps: ApplicationStep[],
  index: number,
  showFinishLater?: boolean,
) => {
  const Wrapper: () => JSX.Element = () => {
    const getNextPage = () => {
      const step = steps[index + 1];
      return step != undefined ? step.route : undefined;
    };

    const getPreviousPage = () => {
      const step = steps[index - 1];
      return step != undefined ? step.route : undefined;
    };

    const getIsLastStep = () => {
      const step = steps[index];
      return step != undefined ? step.isLastStep : undefined;
    };

    return (
      <ApplicationContextProvider key={'prospect_application'}>
        <div className="prospect-application-layout">
          <StepFormLayout>
            <StepFormHeader
              showFinishLater={showFinishLater}
              to={RouteList.GUEST.PROSPECT_APPLICATION.VIEW}
            />
            <Container className={'main-content d-block mx-auto px-2 px-sm-4 px-md-6'}>
              <StepFormStepsWizard steps={steps} />
              <Component
                previousPage={getPreviousPage()}
                nextPage={getNextPage()}
                isLastStep={getIsLastStep()}
              />
            </Container>
          </StepFormLayout>
        </div>
      </ApplicationContextProvider>
    );
  };

  return Wrapper;
};

const getSteps = () => {
  let currentStep = 1;
  let currentIndex = 0;
  const steps: Array<ApplicationStep> = [];
  steps.push(
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.CREATE_APPLICATION,
      component: withWizard(CreateApplication, steps, currentIndex++, false),
    },
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.CREDIT_DETAILS,
      component: withWizard(CreditDetails, steps, currentIndex++, true),
    },
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.SECURITY_DEPOSITS,
      component: withWizard(SecurityDeposits, steps, currentIndex++),
    },
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.COMPANY_INFO,
      component: withWizard(BasicCompanyInfo, steps, currentIndex++),
    },
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.COMPANY_DETAILS,
      component: withWizard(CompanyDetails, steps, currentIndex++),
    },
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.COMPANY_EXECUTIVE,
      component: withWizard(CompanyExecutive, steps, currentIndex++),
    },
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.COMPANY_SHAREHOLDERS,
      component: withWizard(ProspectCompanyBeneficiaries, steps, currentIndex++),
    },
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.COMPANY_EXPERIENCE,
      component: withWizard(CompanyExperience, steps, currentIndex++),
    },
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.DOCUMENTS,
      component: withWizard(CompanyDocuments, steps, currentIndex++),
    },
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.SIGNATURES,
      component: withWizard(ApplicationSignatures, steps, currentIndex++),
      isLastStep: true,
    },
    {
      step: currentStep++,
      name: '',
      route: RouteList.GUEST.PROSPECT_APPLICATION.SUCCESS,
      component: () => <Success />,
      hideInWizard: true,
    },
  );

  return steps;
};

export const prospectApplicationSteps = getSteps();
