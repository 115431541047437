import React, { ReactElement } from 'react';
import useTranslation from 'helpers/useTranslation';

import FAQSection from 'components/Sections/FAQ/FAQSection';

export interface FAQOption {
  title: string | ReactElement;
  content: string | ReactElement;
}

const SectionFaq: React.FC = () => {
  const { tHtml } = useTranslation();

  const items: FAQOption[] = [
    {
      title: tHtml('investments.auto_investment.question_1'),
      content: tHtml('investments.auto_investment.answer_1'),
    },
    {
      title: tHtml('investments.auto_investment.question_2'),
      content: tHtml('investments.auto_investment.answer_2'),
    },
    {
      title: tHtml('investments.auto_investment.question_3'),
      content: tHtml('investments.auto_investment.answer_3'),
    },
    {
      title: tHtml('investments.auto_investment.question_4'),
      content: tHtml('investments.auto_investment.answer_4'),
    },
    {
      title: tHtml('investments.auto_investment.question_5'),
      content: tHtml('investments.auto_investment.answer_5'),
    },
    {
      title: tHtml('investments.auto_investment.question_6'),
      content: tHtml('investments.auto_investment.answer_6'),
    },
  ];

  return <FAQSection items={items} />;
};

export default SectionFaq;
