import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Trans } from 'react-i18next';

import ProjectTitleLink from 'components/Project/ProjectTitleLink';
import { WalletOperationPrivateDataResponseDto } from 'api/types/shared/wallet';
import { WalletOperationTypeEnum } from 'helpers/enums/WalletOperationTypeEnum';

type Props = {
  type: string;
  private_data: WalletOperationPrivateDataResponseDto;
  amount: number;
};

const WalletOperationTypeBadge: React.FC<Props> = ({ type, private_data, amount }) => {
  const { t, tHtml } = useTranslation();

  const project = private_data.project;
  const investment_payout = private_data.investment_payout;
  const general_data = private_data.general_data;

  if (
    project &&
    (type == WalletOperationTypeEnum.PROJECT_INVESTMENT ||
      type === WalletOperationTypeEnum.PROJECT_INVESTMENT_REFUND ||
      type === WalletOperationTypeEnum.PROJECT_INVESTMENT_OVERPAYMENT_REFUND)
  ) {
    return (
      <Trans
        components={[
          <ProjectTitleLink
            key={0}
            title={project.project_name ?? ''}
            projectId={project.id}
            privateId={project.private_id}
          />,
        ]}
      >
        {t(`wallet.operation_type_detailed.${type}`)}
      </Trans>
    );
  }

  if (
    project &&
    (investment_payout || general_data) &&
    type == WalletOperationTypeEnum.PROJECT_INVESTMENT_REPAYMENT
  ) {
    return (
      <Trans
        components={[
          <ProjectTitleLink
            key={0}
            title={project.project_name ?? ''}
            projectId={project.id}
            privateId={project.private_id}
          />,
        ]}
      >
        {t(`wallet.operation_type_detailed.${type}`, {
          interest: t('common.money', {
            value: general_data?.project_interest ?? investment_payout?.project_interest,
          }),
          total_amount: t('common.money', {
            value: general_data?.total_amount ?? investment_payout?.total_amount,
          }),
        })}
      </Trans>
    );
  }

  if (
    project &&
    investment_payout &&
    type === WalletOperationTypeEnum.PROJECT_INVESTMENT_REPAYMENT_BONUS
  ) {
    return (
      <Trans
        components={[
          <ProjectTitleLink
            key={0}
            title={project.project_name ?? ''}
            projectId={project.id}
            privateId={project.private_id}
          />,
        ]}
      >
        {t(`wallet.operation_type_detailed.${type}`, {
          amount: t('common.money', { value: amount }),
        })}
      </Trans>
    );
  }

  if (
    project &&
    (type === WalletOperationTypeEnum.LOAN_REPAYMENT ||
      type === WalletOperationTypeEnum.LOAN_RECEIVE ||
      type === WalletOperationTypeEnum.BONUS_PREMIUM)
  ) {
    return (
      <>
        {t(`wallet.operation_type.${type}`) + ' '}
        <ProjectTitleLink
          key={0}
          title={project.project_name ?? ''}
          projectId={project.id}
          privateId={project.private_id}
        />
      </>
    );
  }

  return <span>{tHtml(`wallet.operation_type.${type}`)}</span>;
};

export default WalletOperationTypeBadge;
