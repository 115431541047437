import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Link } from 'react-router-dom';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { Modifiers } from 'popper.js';
import timeAgo from 'helpers/timeAgo';
import { PrimaryButton } from '../Button';
import Api from 'api';
import { RouteList } from 'routes';
import SanitizedHtmlBlock from '../SanitizedHtmlBlock';
import { StyledNotificationDropdown } from './NotificationDrowdown.style';
import { useNotificationsQuery } from '../../api/queries/useNotificationsQuery';
import Loader from '../Loader';
import useDisplay from 'helpers/useDisplay';

const NotificationDropdown: React.FC = () => {
  const { tHtml, currentLanguage } = useTranslation();
  const [menu, setMenu] = useState(false);
  const { isMobile } = useDisplay();

  const { data, invalidate, isFetching } = useNotificationsQuery();

  useEffect(() => {
    invalidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const markAllRead = async () => {
    await Api.notifications.markAllAsRead().then(() => {
      invalidate();
    });
  };

  const numOfNotifications = (): string => {
    if (data?.unread_amount == undefined || data.unread_amount <= 0) {
      return '';
    }

    if (data.unread_amount > 9) {
      return `${9}+`;
    }

    return data.unread_amount.toString();
  };

  const modifiers: Modifiers = {
    offset: {
      offset: -150,
    },
  };

  return isMobile ? (
    <div className="dropdown d-inline-block">
      <Link
        className="noti-icon waves-effect change-bells-position"
        to={{
          pathname: RouteList.NOTIFICATIONS.LIST,
          hash: data?.notifications.length ? data?.notifications[0]?.id : '',
        }}
      >
        <i
          className={`icon icon-bell align-middle ${
            !data?.unread_amount || data?.unread_amount <= 0 ? 'inactive' : ''
          }`}
        />
        <span className="badge rounded-pill">{numOfNotifications()}</span>
      </Link>
    </div>
  ) : (
    <>
      <StyledNotificationDropdown>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="noti-icon waves-effect change-bells-position"
            tag="a"
            id="page-header-notifications-dropdown"
          >
            <i
              className={`icon icon-bell align-middle ${
                !data?.unread_amount || data?.unread_amount <= 0 ? 'inactive' : ''
              }`}
            />
            <span className="badge rounded-pill">{numOfNotifications()}</span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0" modifiers={modifiers}>
            {data?.notifications === undefined || isFetching ? (
              <Loader />
            ) : (
              <>
                <div className="p-3">
                  <Row className="align-items-center">
                    <Col>
                      <h5 className="m-0 font-size-16">{tHtml('menu.notifications')}</h5>
                    </Col>
                    <div className="col-auto">
                      <a onClick={() => markAllRead()} className="small">
                        {tHtml('menu.mark_all_read')}
                      </a>
                    </div>
                  </Row>
                </div>
                <SimpleBar className={'notification-container'} forceVisible={'y'}>
                  {data?.notifications?.length == 0 && (
                    <p className={'no-notifications-text text-muted'}>
                      {tHtml('menu.no_new_notifications')}
                    </p>
                  )}
                  {data?.notifications?.map((el, idx) => (
                    <Link
                      to={{
                        pathname: RouteList.NOTIFICATIONS.LIST,
                        hash: el.id,
                      }}
                      className="notification-item"
                      key={idx}
                      onClick={() => setMenu(false)}
                    >
                      <div
                        className={`notification-item-inner ${
                          el.has_been_read ? 'text-muted' : ''
                        }`}
                      >
                        <h5
                          className={`notification-title mt-0 mb-2 ${
                            el.has_been_read ? 'text-muted' : ''
                          }`}
                        >
                          {el.title}
                        </h5>
                        <div className="font-size-12">
                          <SanitizedHtmlBlock content={el.body ?? ''} className={'mb-1'} />
                          <p className="mb-0 d-flex align-items-center">
                            <i className={'icon icon-clock align-middle'} />
                            {timeAgo(el.created_at)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </SimpleBar>
                <div className="p-2 border-top">
                  <Link
                    to={{
                      pathname: RouteList.NOTIFICATIONS.LIST,
                      hash: data?.notifications.length ? data?.notifications[0]?.id : '',
                    }}
                    className={'d-grid'}
                    onClick={() => setMenu(false)}
                  >
                    <PrimaryButton>{tHtml('common.view_all')}</PrimaryButton>
                  </Link>
                </div>
              </>
            )}
          </DropdownMenu>
        </Dropdown>
      </StyledNotificationDropdown>
    </>
  );
};

export default NotificationDropdown;
