import { useField, useFormikContext, Field } from 'formik';
import React, { ReactElement } from 'react';
import useTranslation from 'helpers/useTranslation';
import ScrollToOnError from '../ScrollToOnError';

export interface Props
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'placeholder'
  > {
  name: string;
  value: string;
  showPlaceholder: boolean;
  placeholder?: string | ReactElement;
  placeholderPrefix?: string;
}

const CheckboxInputValue: React.FC<Props> = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const [field, meta] = useField(props.name);
  const formik = useFormikContext();

  if (!props.placeholder && props.showPlaceholder) {
    props.placeholder = tHtml((props.placeholderPrefix ?? 'placeholder.') + props.value);
  }

  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;

  return (
    <div className={`form-check`}>
      <ScrollToOnError name={props.name} isError={isError}>
        <label className={`form-check-label form-label ${isError ? ' is-invalid' : ''}`}>
          <Field
            onChange={field.onChange}
            onBlur={field.onBlur}
            type="checkbox"
            className={`form-check-input${isError ? ' is-invalid' : ''}`}
            name={props.name}
            value={props.value}
            disabled={props.disabled}
          />
          <div className={'ms-2'}>{props.placeholder}</div>
        </label>
      </ScrollToOnError>
    </div>
  );
};

export default CheckboxInputValue;
