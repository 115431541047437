import React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

const Marker = styled.div<{ percents: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(50%, 0px);
  right: ${(props) => props.percents || '0'}%;
`;

interface Props {
  place: number;
  investors: number;
}

const TopInvestor: React.FC<Props> = ({ place, investors }) => {
  const { t } = useTranslation();
  const percents = place === 1 ? 0 : (place / investors) * 100;

  return (
    <div className={'page-layout top-investor'}>
      <div className={'panel mb-3 h-100'}>
        <Row className="my-4 ">
          <Col lg={4} className="d-flex align-items-center justify-content-center">
            <div className={'title'}>
              <Trans components={[<span key={0} className="top" />]}>
                {t('dashboard.top_investor', { value: place })}
              </Trans>
            </div>
          </Col>
          <Col lg={8} className="d-flex align-items-center justify-content-center">
            <div className="top-investor-bar">
              <Marker percents={percents}>
                <div className="line" />
                <div className="triangle" />
              </Marker>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TopInvestor;
