import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

interface Props {
  pageIndex: number;
  lastPage: number;
  gotoPage: (pageIndex: number) => void;
}

const NumberedPagination: React.FC<Props> = ({ lastPage, pageIndex, gotoPage }) => {
  const getPageList = (totalPages: number, page: number, maxLength: number): number[] => {
    function range(start: number, end: number) {
      return Array.from(Array(end - start + 1), (_, i) => i + start);
    }

    const sideWidth = maxLength < 9 ? 1 : 2;
    const leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
    const rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
    if (totalPages <= maxLength) {
      // no breaks in list
      return range(1, totalPages);
    }
    if (page <= maxLength - sideWidth - 1 - rightWidth) {
      // no break on left of page
      return range(1, maxLength - sideWidth - 1).concat(
        0,
        range(totalPages - sideWidth + 1, totalPages),
      );
    }
    if (page >= totalPages - sideWidth - 1 - rightWidth) {
      // no break on right of page
      return range(1, sideWidth).concat(
        0,
        range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages),
      );
    }
    // Breaks on both sides
    return range(1, sideWidth).concat(
      0,
      range(page - leftWidth, page + rightWidth),
      0,
      range(totalPages - sideWidth + 1, totalPages),
    );
  };

  return (
    <Pagination aria-label="page navigation">
      <PaginationItem disabled={pageIndex === 1}>
        <PaginationLink className={'previous'} onClick={() => gotoPage(pageIndex - 1)}>
          <i className={'icon icon-arrow-left'} />
        </PaginationLink>
      </PaginationItem>
      {getPageList(lastPage, pageIndex, 8).map((page, key) => {
        return (
          <PaginationItem key={key} disabled={page === 0} active={page == pageIndex}>
            {page === 0 && <PaginationLink>...</PaginationLink>}
            {page !== 0 && (
              <PaginationLink
                onClick={() => {
                  gotoPage(page);
                }}
              >
                {page}
              </PaginationLink>
            )}
          </PaginationItem>
        );
      })}
      <PaginationItem disabled={pageIndex === lastPage}>
        <PaginationLink className={'next'} onClick={() => gotoPage(pageIndex + 1)}>
          <i className={'icon icon-arrow-right'} />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default NumberedPagination;
