import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

const PaymentCancel: React.FC = () => {
  const { tHtml } = useTranslation();

  return (
    <div>
      <h1>{tHtml('wallet.top_up_cancel')}</h1>
    </div>
  );
};

export default withRouter(PaymentCancel);
