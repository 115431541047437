import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import { DataBlock } from 'components/DataBlock';
import YesNoBlock from 'components/YesNoBlock';
import NotFilledProfileSection from 'components/Profile/NotFilledProfileSection';
import CompanyProfileSection from 'scopes/company/components/CompanyProfileSection';
import { CompanyMenuEnum } from 'helpers/enums/CompanyMenuEnum';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';
import { ProjectDeveloperKybDataV1ResponseDto } from 'api/types/shared/company/profile';

const ProjectDeveloperKybV1: React.FC = () => {
  const { tHtml } = useTranslation();

  const { data: companyProfileData } = useUserCompanyProfileQuery();

  const kyb = companyProfileData?.know_your_business?.kyb_data as
    | ProjectDeveloperKybDataV1ResponseDto
    | undefined;

  if (!kyb) {
    return (
      <NotFilledProfileSection
        title={tHtml('menu.kyb')}
        buttonLink={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}
      />
    );
  }

  return (
    <CompanyProfileSection
      name={CompanyMenuEnum.KnowYourBusiness}
      title={tHtml('menu.kyb')}
      buttonLink={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}
    >
      <div className={'content'}>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.company.management_structure')}
            data={
              kyb?.management_structure
                ? tHtml(`placeholder.company.management_structure.${kyb?.management_structure}`)
                : ''
            }
          />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml('placeholder.company.has_bankruptcy_procedure')}
            checked={kyb?.has_bankruptcy_procedure}
          />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml(
              'placeholder.company.is_shareholder_beneficiary_family_member_public_official',
            )}
            checked={kyb?.is_shareholder_beneficiary_family_member_public_official}
          />
        </div>
      </div>
    </CompanyProfileSection>
  );
};

export default withRouter(ProjectDeveloperKybV1);
