import React from 'react';
import CheckboxInputGroup from '../CheckboxGroup/CheckboxInputGroup';

const OriginOfFundsInput: React.FC = () => {
  const purposeValues = ['investments', 'borrowed_funds', 'related_person_funds', 'other'];

  return (
    <CheckboxInputGroup
      name={'origin_of_invested_funds'}
      values={purposeValues}
      valuesWithText={['other']}
      showPlaceholder={true}
      placeholderValuesPrefix={'placeholder.company.origin_of_invested_funds.'}
    />
  );
};

export default OriginOfFundsInput;
