import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container } from 'reactstrap';

const MyProjectsListSkeleton: React.FC = () => (
  <Container>
    <Skeleton height={800} className={'mb-6'} />
  </Container>
);

export default MyProjectsListSkeleton;
