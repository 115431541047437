import AuthenticationRoutes from './pages/Authentication/AuthenticationRoutes';
import ProjectRoutes from './pages/Project/ProjectRoutes';
import HomeRoutes from './pages/Home/HomeRoutes';
import ProspectApplicationRoutes from './pages/ProspectApplication/ProspectApplicationRoutes';

const GuestRoutes: any = () => [
  ...HomeRoutes(),
  ...ProjectRoutes(),
  ...ProspectApplicationRoutes(),
  ...AuthenticationRoutes(),
];

export default GuestRoutes;
