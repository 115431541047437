import React from 'react';
import * as Roles from 'helpers/auth/roles';
import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import NotFoundLayout from 'scopes/developer/containers/PageLayout/NotFoundLayout';
import NotFound from '.';

const NotFoundRoutes: any = () => [
  <AuthRoute
    path={RouteList.NOT_FOUND}
    component={NotFound}
    layout={NotFoundLayout}
    permissions={Roles.All.permissions}
    key={RouteList.NOT_FOUND}
    exact
  />,
];

export default NotFoundRoutes;
