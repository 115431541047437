import React from 'react';
import { Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { ProjectDeveloperApplicationProjectProposal } from 'api/types/project-developer/application';
import { LoanRatioEnum } from 'helpers/enums/LoanRatioEnum';

type Props = ProjectDeveloperApplicationProjectProposal;

const ProposalTable: React.FC<Props> = (props) => {
  const { tHtml, t } = useTranslation();

  return (
    <Row className={'proposal p-sm-2 p-md-4'}>
      <div className={'proposal-items'}>
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>
            {tHtml('projects.proposal_information.required_amount')}
          </span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>{tHtml('common.money', { value: props.required_amount })}</strong>
          </span>
        </Col>
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>
            {tHtml('projects.proposal_information.credit_duration')}
          </span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>{tHtml(`common.month`, { value: props?.credit_duration })}</strong>
          </span>
        </Col>
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>
            {tHtml('projects.proposal_information.contract_administration_fee')}
          </span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>
              {tHtml('common.percents', { value: props.contract_administration_fee })}
            </strong>
          </span>
        </Col>
      </div>
      <div className="proposal-items">
        <Col className={'proposal-item'}>
          {props.loan_ratio === LoanRatioEnum.LTV ? (
            <span className={'w-50 text-lead'}>{tHtml('projects.proposal_information.LTV')}</span>
          ) : (
            <span className={'w-50 text-lead'}>{tHtml('projects.proposal_information.LTC')}</span>
          )}
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>{tHtml('common.percents', { value: props.loan_ratio_external })}</strong>
          </span>
        </Col>
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>{tHtml('projects.proposal_information.surety')}</span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>
              {[t('application.mortgage'), props.surety ? t('application.surety') : null]
                .filter(Boolean)
                .join(', ')}
            </strong>
          </span>
        </Col>
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>
            {tHtml('projects.proposal_information.administration_fee')}
          </span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>{tHtml('common.percents', { value: props.administration_fee })}</strong>
          </span>
        </Col>
      </div>
      <div className="proposal-items">
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>
            {tHtml('projects.proposal_information.days_to_get_money')}
          </span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>{tHtml('common.days', { value: props.days_to_get_money })}</strong>
          </span>
        </Col>
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>
            {tHtml('projects.proposal_information.initial_rating')}
          </span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>{props.initial_rating}</strong>
          </span>
        </Col>
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>
            {tHtml('projects.proposal_information.interest_payout_frequency')}
          </span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>{tHtml('application.interest_frequency.' + props.interest_frequency)}</strong>
          </span>
        </Col>
      </div>
      <div className="proposal-items">
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>
            {tHtml('projects.proposal_information.interest')}
          </span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>{tHtml('common.percents', { value: props.basic_interest })}</strong>
          </span>
        </Col>
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>
            {tHtml('projects.proposal_information.increased_interest')}
          </span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>{tHtml('common.percents', { value: props.increased_interest })}</strong>
          </span>
        </Col>
        <Col className={'proposal-item'}>
          <span className={'w-50 text-lead'}>
            {tHtml('projects.proposal_information.credit_return')}
          </span>
          <span className="w-50 text-profitus-blue pe-sm-3 pe-md-6 text-end">
            <strong>{tHtml(`placeholder.credit_return.${props.credit_return}`)}</strong>
          </span>
        </Col>
      </div>
    </Row>
  );
};

export default ProposalTable;
