import React from 'react';

import * as Flags from 'country-flag-icons/string/3x2';

const Flag: React.FC<FlagProps> = ({ countryCode = 'en' }) => {
  const getFlag = (code: string) => {
    if (code == 'en') code = 'gb';

    return window.btoa(Flags[code.toUpperCase() as keyof typeof Flags]);
  };

  return (
    <img
      style={{
        width: '1.188rem',
        height: '0.75rem',
        marginRight: '0.25rem',
        verticalAlign: 'middle',
        marginLeft: '1px',
        borderRadius: '2px',
      }}
      src={`data:image/svg+xml;base64,${getFlag(countryCode)}`}
      alt={countryCode.toLocaleUpperCase()}
      title={countryCode.toLocaleUpperCase()}
    />
  );
};

interface FlagProps {
  countryCode: string;
}

export default Flag;
