import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';
import { StyledCard } from 'components/Card/Card.style';

export const StyledPartialReturnSectionWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;

  ${breakpointDown.LG} {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
`;

export const StyledPartialReturnSectionCard = styled(StyledCard)`
  margin-top: 2.25rem;
`;

export const StyledPartialReturnSectionForm = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const StyledPartialReturnSectionFormSelect = styled.div`
  flex: 1 1 auto;
  width: 50%;
`;

export const StyledPartialReturnSectionFormSubmit = styled.div`
  flex: 1 1 auto;
  min-width: 15rem;
`;
