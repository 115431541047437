import React, { ReactElement, useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import Select from 'react-select';
import Label from '../Label';

import Api from 'api';
import { GroupBase } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import CountryLabel from '../../Labels/CountryLabel';
import { useCacheQuery } from '../../../api/queries/useCacheQuery';
import { useField } from 'formik';
import { MultiValue } from 'react-select';

interface ReactGroupSelectProps extends StateManagerProps {
  name: string;
  groupedOptions: GroupedOption[] | undefined;
  hideLabel?: boolean;
  hasTooltip?: boolean;
  tooltipContent?: string | React.ReactElement;
}

interface GroupedOption {
  label: string;
  options: ReactSelectOption[];
}

type ReactSelectOption = {
  label: string | JSX.Element;
  value: string | boolean;
  customText?: string;
};

interface Props extends StateManagerProps {
  name: string;
  placeholder: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  hasTooltip?: boolean;
  tooltipContent?: string | ReactElement;
  isDisabled?: boolean;
}

const SelectMultiCountryInputGroup: React.FC<Props> = ({
  name,
  placeholder,
  setFieldValue,
  isDisabled = false,
  ...props
}) => {
  const [formattedCountries, setFormattedCountries] = useState<GroupedOption[]>([]);
  const [field] = useField(name);
  const { data: countries } = useCacheQuery(['countries'], Api.countries.fetchCountries);

  const onChange = (option: MultiValue<ReactSelectOption>) => {
    setFieldValue(
      field.name,
      option.map((item) => item.value),
    );
  };

  useEffect(() => {
    const options: GroupedOption[] = [];

    options.push({
      label: 'primary_countries',
      options: countries?.primary_countries.map(getOption).sort(compareOption) ?? [],
    });

    options.push({
      label: 'secondary_countries',
      options: countries?.secondary_countries.map(getOption).sort(compareOption) ?? [],
    });

    setFormattedCountries(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const { t, currentLanguage } = useTranslation();

  const getOption = (code: string) => {
    return {
      value: code,
      label: <CountryLabel code={code} />,
      customText: t('countries.' + code),
    };
  };

  const compareOption = (o1: ReactSelectOption, o2: ReactSelectOption) =>
    o1.customText && o2.customText ? o1.customText.localeCompare(o2.customText) : 0;

  useEffect(() => {
    if (formattedCountries.length > 0) {
      setFormattedCountries((groups) => [
        ...groups.map((group) => ({
          ...group,
          options: group.options
            .map((option) => getOption(option.value.toString()))
            .sort(compareOption),
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const formatGroupLabel = (data: GroupBase<any>) => {
    return data.label === 'primary_countries' ? null : <hr className={'m-0'} />;
  };

  const customStyles = {
    group: (provided: any) => ({
      ...provided,
      padding: '0.2rem 0',
    }),
  };

  const filterOption = (
    option: FilterOptionOption<ReactSelectOption | unknown>,
    inputValue: string,
  ): boolean =>
    (
      (option as FilterOptionOption<ReactSelectOption>).data.customText
        ?.toLowerCase()
        .toString()
        .match(inputValue.toLowerCase()) || []
    ).length > 0;

  return (
    <ReactMultiSelectGroupInput
      name={name}
      onChange={(option: any) => {
        onChange(option);
      }}
      isMulti={true}
      isDisabled={isDisabled}
      placeholder={placeholder}
      groupedOptions={formattedCountries}
      isSearchable={true}
      formatGroupLabel={formatGroupLabel}
      styles={customStyles}
      filterOption={filterOption}
      {...props}
    />
  );
};

export default SelectMultiCountryInputGroup;

const ReactMultiSelectGroupInput: React.FC<ReactGroupSelectProps> = ({
  name,
  groupedOptions,
  ...props
}) => {
  const { t, currentLanguage } = useTranslation();
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (meta.touched) {
      helpers.setTouched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const isError = meta.touched && !!meta.error;

  const defaultStyles = {
    valueContainer: (provided: any) => ({
      ...provided,
      height: 'auto', // Adjusted for potentially larger multi-select input
    }),
    ...props.styles,
  };

  return (
    <>
      <div className={isError ? 'is-invalid' : ''}>
        {!props.hideLabel && (
          <Label
            title={props.placeholder || ''}
            htmlFor={field.name}
            labelStyle={{ fontSize: '0.7rem', paddingLeft: '1rem' }}
            labelClassName={'form-label py-1'}
            hasTooltip={props.hasTooltip}
            tooltipContent={props.tooltipContent}
            smallTooltip={true}
          />
        )}
        <Select
          isMulti
          className={'fancy-select'}
          classNamePrefix={'fancy-select'}
          loadingMessage={() => t('common.loading')}
          noOptionsMessage={() => t('common.no_options')}
          placeholder={t('common.select')}
          options={groupedOptions}
          name={name}
          onBlur={field.onBlur}
          isLoading={groupedOptions === undefined}
          onChange={(option: any) => {
            const value = option ? option.map((item: ReactSelectOption) => item.value) : [];
            helpers.setValue(value);
          }}
          styles={defaultStyles}
          {...props}
        />
      </div>
      {isError && <div className="invalid-feedback">{meta.error}</div>}
    </>
  );
};
