import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import Api from 'api';
import {
  FinancialRiskCalculatorRequestDto,
  FinancialRiskCalculatorResponseDto,
} from 'api/types/shared/user/profile';
import CheckboxInput from 'components/Formik/CheckboxInput';
import { PrimaryButton, TextInput } from 'components/Formik';
import { success } from 'services/toastr';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import { useAccountQuery } from 'api/queries';
import {
  FinancialRiskCalculatorTooltip,
  FinancialRiskCalculatorTypeEnum,
} from '../../../investor/pages/Profile/FinancialRiskCalculatorTooltip';
import { TrackVisitTypeEnum } from 'helpers/enums/TrackVisitTypeEnum';

enum FinancialRiskCalculatorFormStep {
  CALCULATE_STEP = 'CALCULATE_STEP',
  CONFIRM_STEP = 'CONFIRM_STEP',
}

const UserFinancialRiskCalculator: React.FC = () => {
  const { tHtml } = useTranslation();

  const [formStep, setFormStep] = useState<FinancialRiskCalculatorFormStep>(
    FinancialRiskCalculatorFormStep.CALCULATE_STEP,
  );
  const [latestSubmittedForm, setLatestSubmittedForm] =
    useState<FinancialRiskCalculatorResponseDto | null>(null);
  const [result, setResult] = useState<FinancialRiskCalculatorResponseDto | null>(null);

  const [openedTooltip, setOpenedTooltip] = useState<any>({
    [FinancialRiskCalculatorTypeEnum.ANNUAL_INCOME]: false,
    [FinancialRiskCalculatorTypeEnum.ANNUAL_OBLIGATIONS]: false,
    [FinancialRiskCalculatorTypeEnum.LIQUID_NET_WORTH]: false,
  });

  const { invalidate: invalidateProfile } = useUserProfileQuery();
  const { data: dataAccount, invalidate: invalidateAccount } = useAccountQuery();

  const [financialRiskCalculatorRequest, setFinancialRiskCalculatorRequest] =
    useState<FinancialRiskCalculatorRequestDto>({
      annual_income: null,
      liquid_net_worth: null,
      annual_obligations: null,
      financial_risk_calculator_agreement: false,
    });

  useEffect(() => {
    if (!dataAccount?.financial_risk_calculator_visited) {
      Api.trackVisits
        .trackVisit({ type: TrackVisitTypeEnum.VISIT_FINANCIAL_RISK_CALCULATOR })
        .then(() => {
          invalidateAccount();
        });
    }

    Api.shared.user.profile.getFinancialRiskCalculatorSubmit().then((response) => {
      setFinancialRiskCalculatorRequest({
        annual_income: response.annual_income,
        liquid_net_worth: response.liquid_net_worth,
        annual_obligations: response.annual_obligations,
        financial_risk_calculator_agreement: false,
      });

      setLatestSubmittedForm(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    async (
      request: FinancialRiskCalculatorRequestDto,
      helpers: FormikHelpers<FinancialRiskCalculatorRequestDto>,
    ) => {
      if (formStep === FinancialRiskCalculatorFormStep.CALCULATE_STEP) {
        await Api.shared.user.profile
          .calculateFinancialRisk(request)
          .then((response) => {
            setResult(response);
            setFormStep(FinancialRiskCalculatorFormStep.CONFIRM_STEP);
          })
          .catch((e) => {
            helpers.setErrors(e.response?.errors);
          });
      }

      if (formStep === FinancialRiskCalculatorFormStep.CONFIRM_STEP) {
        await Api.shared.user.profile
          .calculateAndStoreFinancialRisk(request)
          .then((response) => {
            setFinancialRiskCalculatorRequest({
              annual_income: response.annual_income,
              liquid_net_worth: response.liquid_net_worth,
              annual_obligations: response.annual_obligations,
              financial_risk_calculator_agreement: false,
            });
            setLatestSubmittedForm(response);
            setFormStep(FinancialRiskCalculatorFormStep.CALCULATE_STEP); // back to beginning
            invalidateProfile();
            invalidateAccount();
            success(tHtml('common.successfully_filled_risk_calculations'));
          })
          .catch((e) => {
            helpers.setErrors(e.response?.errors);
          });
      }
    },
    [formStep, tHtml, invalidateProfile, invalidateAccount],
  );

  const FinancialRiskCalculatorSchema = Yup.object().shape({
    annual_income: Yup.number().required().min(0),
    liquid_net_worth: Yup.number().required().min(0),
    annual_obligations: Yup.number().required().min(0),
    financial_risk_calculator_agreement: Yup.boolean().optional(),
  });

  const startCalculationFromBeginning = () => {
    setFormStep(FinancialRiskCalculatorFormStep.CALCULATE_STEP);
    setResult(null);
  };

  return (
    <React.Fragment>
      <h1>{tHtml('placeholder.financial_risk_calculator_title')}</h1>
      <div className={'mb-5'}>
        <span>{tHtml('placeholder.financial_risk_calculator_description')}</span>
      </div>
      <Formik
        initialValues={financialRiskCalculatorRequest}
        validationSchema={FinancialRiskCalculatorSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FinancialRiskCalculatorTooltip
              isOpen={openedTooltip[FinancialRiskCalculatorTypeEnum.ANNUAL_INCOME]}
              handleOnClick={() =>
                setOpenedTooltip({
                  ...openedTooltip,
                  [FinancialRiskCalculatorTypeEnum.ANNUAL_INCOME]:
                    !openedTooltip[FinancialRiskCalculatorTypeEnum.ANNUAL_INCOME],
                })
              }
              type={FinancialRiskCalculatorTypeEnum.ANNUAL_INCOME}
            >
              <TextInput
                name={'annual_income'}
                type={'number'}
                onChange={startCalculationFromBeginning}
              />
            </FinancialRiskCalculatorTooltip>
            <FinancialRiskCalculatorTooltip
              isOpen={openedTooltip[FinancialRiskCalculatorTypeEnum.LIQUID_NET_WORTH]}
              handleOnClick={() =>
                setOpenedTooltip({
                  ...openedTooltip,
                  [FinancialRiskCalculatorTypeEnum.LIQUID_NET_WORTH]:
                    !openedTooltip[FinancialRiskCalculatorTypeEnum.LIQUID_NET_WORTH],
                })
              }
              type={FinancialRiskCalculatorTypeEnum.LIQUID_NET_WORTH}
            >
              <TextInput
                name={'liquid_net_worth'}
                type={'number'}
                onChange={startCalculationFromBeginning}
              />
            </FinancialRiskCalculatorTooltip>
            <FinancialRiskCalculatorTooltip
              isOpen={openedTooltip[FinancialRiskCalculatorTypeEnum.ANNUAL_OBLIGATIONS]}
              handleOnClick={() =>
                setOpenedTooltip({
                  ...openedTooltip,
                  [FinancialRiskCalculatorTypeEnum.ANNUAL_OBLIGATIONS]:
                    !openedTooltip[FinancialRiskCalculatorTypeEnum.ANNUAL_OBLIGATIONS],
                })
              }
              type={FinancialRiskCalculatorTypeEnum.ANNUAL_OBLIGATIONS}
            >
              <TextInput
                name={'annual_obligations'}
                type={'number'}
                onChange={startCalculationFromBeginning}
              />
            </FinancialRiskCalculatorTooltip>

            {result && formStep === FinancialRiskCalculatorFormStep.CONFIRM_STEP && (
              <>
                <span>
                  {tHtml('placeholder.your_risk_max_tolerance')}:{' '}
                  <b>
                    {' '}
                    {tHtml('common.money', {
                      value: result.result,
                    })}
                  </b>
                </span>
                <div className={'form-input mb-4 mt-2'}>
                  <CheckboxInput
                    name={'financial_risk_calculator_agreement'}
                    showPlaceholder={true}
                  />
                </div>
              </>
            )}
            {latestSubmittedForm && latestSubmittedForm.submitted_at && (
              <div className={'mt-5'}>
                <div>
                  <span>
                    {tHtml('placeholder.latest_risk_calculation_submit_result')}
                    {': '}
                    <b>{tHtml('common.money', { value: latestSubmittedForm.result })}</b>
                  </span>
                </div>
                <div>
                  <span>
                    {tHtml('placeholder.latest_risk_calculation_submit_date')}
                    {': '}
                    <b>{tHtml('common.date', { date: latestSubmittedForm.submitted_at })}</b>
                  </span>
                </div>
              </div>
            )}
            <div className={'d-flex flex-row-reverse'}>
              <PrimaryButton
                type={'submit'}
                submitting={isSubmitting}
                className={'site btn btn-primary'}
              >
                {formStep === FinancialRiskCalculatorFormStep.CALCULATE_STEP
                  ? tHtml('placeholder.calculate')
                  : tHtml('placeholder.save')}
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withRouter(UserFinancialRiskCalculator);
