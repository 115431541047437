export enum ProjectInvestmentTypeEnum {
  MANUAL = 'manual',
  AUTO = 'auto',
  TRANSFER = 'transfer',
}

export enum ProjectInvestmentStatusEnum { // todo rename
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  LATE = 'late',
  SOLD = 'sold',
  RECOVERING = 'recovering',
  OTHER = 'other',
  TRANSFERABLE = 'transferable',
  ALL = 'all',
}
