import { useEffect, useState } from 'react';

interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed' | 'installed';
    platform: string;
  }>;

  prompt(): Promise<void>;
}

export function useAddToHomescreenPrompt() {
  const [prompt, setState] = useState<IBeforeInstallPromptEvent | null>(null);
  const [appInstalled, setAppInstalled] = useState<boolean>(false);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(
      new Error('Tried installing before browser sent "beforeinstallprompt" event'),
    );
  };

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setState(e);
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: Property 'standalone' does not exist on type 'Navigator'.
    if (window.navigator.standalone) {
      setAppInstalled(true);
    }

    window.addEventListener('beforeinstallprompt', ready as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready as any);
    };
  }, []);

  useEffect(() => {
    const ready = (e: any) => {
      e.preventDefault();
      setAppInstalled(true);
      setState(null);
    };

    window.addEventListener('appinstalled', ready as any);

    return () => {
      window.removeEventListener('appinstalled', ready as any);
    };
  }, []);

  return { prompt, promptToInstall, appInstalled };
}
