import React from 'react';
import useTranslation from 'helpers/useTranslation';

import FAQSection, { FAQOption } from 'components/Sections/FAQ/FAQSection';

const SecondaryMarketFAQ: React.FC = () => {
  const { tHtml } = useTranslation();

  const items: FAQOption[] = [
    {
      title: tHtml('secondary_market.faq.question_1'),
      content: tHtml('secondary_market.faq.answer_1'),
    },
    {
      title: tHtml('secondary_market.faq.question_2'),
      content: tHtml('secondary_market.faq.answer_2'),
    },
    {
      title: tHtml('secondary_market.faq.question_3'),
      content: tHtml('secondary_market.faq.answer_3'),
    },
    {
      title: tHtml('secondary_market.faq.question_4'),
      content: tHtml('secondary_market.faq.answer_4'),
    },
    {
      title: tHtml('secondary_market.faq.question_5'),
      content: tHtml('secondary_market.faq.answer_5'),
    },
    {
      title: tHtml('secondary_market.faq.question_6'),
      content: tHtml('secondary_market.faq.answer_6'),
    },
  ];

  return <FAQSection items={items} />;
};

export default SecondaryMarketFAQ;
