import React, { useCallback, useMemo, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Cell } from 'react-table';

import { PaginationData, PaginationDataFilter } from 'api/types';
import Table, { AmountFilter, Column, DateFilter } from 'components/Table/Table';
import { RouteList } from 'routes';
import { FunctionalButton } from 'components/Button';
import Api from 'api';
import TransactionSkeleton from './components/TransactionsSkeleton/TransactionSkeleton';
import SkeletonLoader from 'components/SkeletonLoader';
import { OperationsResponseDto } from 'api/types/shared/wallet';
import WalletOperationStatusBadge from 'components/Badge/WalletOperationStatusBadge';
import { TableInitialSortBy } from 'api/types/common';
import WalletOperationAmountBadge from 'components/Badge/WalletOperationAmountBadge';
import WalletOperationTypeBadge from 'components/Badge/WalletOperationTypeBadge';
import useUserType from 'helpers/useUserType';
import ColumnFilter, { AvailableGenericType } from 'components/Table/AdvanceFilters/ColumnFilter';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import { downloadXMLFile, downloadCSVFile, downloadXLSXFile } from 'services/media';

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'executed_at',
    desc: true,
  },
];

const Transactions: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const [data, setData] = useState<PaginationData<OperationsResponseDto>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paginationRequest, setPaginationRequest] = useState<PaginationDataFilter | undefined>();
  const { isInvestor } = useUserType();

  const columns = useMemo<Column<OperationsResponseDto>[] | any>(
    () => [
      {
        Header: t(
          isInvestor()
            ? 'table.investor_wallet_transaction_type'
            : 'table.developer_wallet_transaction_type',
        ),
        accessor: 'type',
        sortType: 'string',
        Filter: ColumnFilter,
        filterProps: {
          columnHeaderFilter: true,
          request: Api.wallet.operationsWithTypeFilter(),
          optionsRequest: Api.wallet.availableOperationTypes(),
          optionsMapResponse: (response: AvailableGenericType[]) => {
            return response.map((value: any) => ({
              key: value.key,
              value: t('wallet.operation_type.' + value.key.toLowerCase()),
            }));
          },
        },
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return (
            <WalletOperationTypeBadge
              type={row.type}
              private_data={row.private_data}
              amount={row.amount}
            />
          );
        },
      },
      {
        Header: t('table.executed_at'),
        accessor: 'executed_at',
        sortType: '',
        Filter: DateFilter,
        Cell: (cell: Cell<OperationsResponseDto>) => (
          <span>{tHtml('common.date_full', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return <WalletOperationStatusBadge status={row.status} />;
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return <WalletOperationAmountBadge amount={row.amount} is_receiver={row.is_receiver} />;
        },
        Filter: AmountFilter,
        filterProps: {
          start: -1000,
          end: 50000,
          ignoreLimits: true,
        },
      },
    ],
    [isInvestor, t, tHtml],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter) => {
      isLoading && setIsLoading(!isLoading);
      setPaginationRequest(request);
      return Api.wallet.operations(request).then((response) => setData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, t],
  );

  const exportCsv = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.wallet.exportOperations(CommonDocumentTypeEnum.CSV, request);
    await downloadCSVFile(response.url, 'operations');
  };

  const exportExcel = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.wallet.exportOperations(CommonDocumentTypeEnum.XLSX, request);
    await downloadXLSXFile(response.url, 'operations');
  };

  const exportXml = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.wallet.exportOperations(CommonDocumentTypeEnum.XML, request);
    await downloadXMLFile(response.url);
  };

  return (
    <div className={'page-layout wallet'}>
      <div className={'mb-5 d-flex justify-content-start'}>
        <Link to={RouteList.SHARED.WALLET.OVERVIEW}>
          <FunctionalButton>
            <i className={'icon icon-arrow-left'} /> {tHtml('common.wallet')}
          </FunctionalButton>
        </Link>
      </div>

      <SkeletonLoader loading={isLoading} customPlaceholder={<TransactionSkeleton />}>
        <div>
          <h2>{tHtml('wallet.operations')}</h2>
          <div>
            <Table
              columns={columns}
              data={data}
              onFetchData={fetchData}
              showPageSizes={true}
              searchable={true}
              enableQueryFilter={true}
              defaultLimit={20}
              initialSortBy={INITIAL_SORT_ORDER}
              exportLinks={[
                {
                  onClick: exportCsv,
                  request: paginationRequest,
                  type: CommonDocumentTypeEnum.CSV,
                },
                {
                  onClick: exportExcel,
                  request: paginationRequest,
                  type: CommonDocumentTypeEnum.XLSX,
                },
                {
                  onClick: exportXml,
                  request: paginationRequest,
                  type: CommonDocumentTypeEnum.XML,
                },
              ]}
            />
          </div>
        </div>
      </SkeletonLoader>
    </div>
  );
};

export default withRouter(Transactions);
