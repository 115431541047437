import Client from '../client';
import Http from 'api/http';
import { PaginationData, PaginationDataFilter } from 'api/types';
import {
  InvestorAutoInvestmentResponseDto,
  InvestorAutoInvestmentUpdateStatusRequestDto,
  InvestorInvestmentListResponseDto,
  RefundedReasonI,
  InvestorAutoInvestmentFullConfig,
  StoreStrategyDto,
  AutoInvestPrediction,
  AutoStrategyAvgAnnualInterest,
  AutoStrategyAvgWeightedRating,
  AutoStrategyChatData,
} from 'api/types/investor/investment';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import { ProjectInvestmentStatusEnum } from '../../../helpers/enums/ProjectInvestmentTypeEnum';

class InvestorInvestment extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_ACTIVE_INVESTMENTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/investments/active`,
      ),
      FETCH_INACTIVE_INVESTMENTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/investments/inactive`,
      ),
      FETCH_LATE_INVESTMENTS: this.protectedUrl(`${this.http.baseUrl}/investor/investments/late`),
      FETCH_SOLD_INVESTMENTS: this.protectedUrl(`${this.http.baseUrl}/investor/investments/sold`),
      FETCH_RECOVERING_INVESTMENTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/investments/recovering`,
      ),
      FETCH_OTHER_INVESTMENTS: this.protectedUrl(`${this.http.baseUrl}/investor/investments/other`),
      FETCH_TRANSFERABLE_INVESTMENTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/investments/transferable`,
      ),
      UPDATE_GLOBAL_STATUS_AUTO_INVESTMENT: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/update-status`,
      ),
      FETCH_AUTO_INVESTMENT_CONFIGS: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/configs`,
      ),
      FETCH_AUTO_INVESTMENT: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/strategy`,
      ),
      STORE_AUTO_INVESTMENT_STRATEGY: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/strategy`,
      ),
      UPDATE_AUTO_INVESTMENT_STRATEGY: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/strategy/{id}`,
      ),
      DELETE_AUTO_INVESTMENT_STRATEGY: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/strategy/{id}`,
      ),
      UPDATE_STRATEGY_STATUS_AUTO_INVESTMENT: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/strategy/{id}/update-status`,
      ),
      PREDICT_AUTO_INVESTMENT_STRATEGY: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/strategy/predict`,
      ),
      ORDER_STRATEGIES: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/strategy/order`,
      ),
      FETCH_AUTO_INVESTMENT_STATISTIC_AVG_ANNUAL_INTEREST: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/strategy/{id}/statistic/active-average-annual-interest`,
      ),
      FETCH_AUTO_INVESTMENT_STATISTIC_AVG_WEIGHTED_RATING: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/strategy/{id}/statistic/average-weighted-rating`,
      ),
      FETCH_AUTO_INVESTMENT_STATISTIC_CHART_DATA: this.protectedUrl(
        `${this.http.baseUrl}/investor/auto-investor/strategy/{id}/statistic/chart-data`,
      ),

      EXPORT_INVESTMENTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/investments/export/{investment_type}/{type}`,
      ),
      REFUND_INVESTMENT: this.protectedUrl(
        `${this.http.baseUrl}/investor/investments/investment/{investmentId}/refund`,
      ),
    };
  }

  fetchActiveInvestments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestorInvestmentListResponseDto>> => {
    return this.http.get(this.api.FETCH_ACTIVE_INVESTMENTS, { params: request });
  };

  fetchInactiveInvestments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestorInvestmentListResponseDto>> => {
    return this.http.get(this.api.FETCH_INACTIVE_INVESTMENTS, { params: request });
  };

  fetchLateInvestments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestorInvestmentListResponseDto>> => {
    return this.http.get(this.api.FETCH_LATE_INVESTMENTS, { params: request });
  };

  fetchSoldInvestments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestorInvestmentListResponseDto>> => {
    return this.http.get(this.api.FETCH_SOLD_INVESTMENTS, { params: request });
  };

  fetchRecoveringInvestments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestorInvestmentListResponseDto>> => {
    return this.http.get(this.api.FETCH_RECOVERING_INVESTMENTS, { params: request });
  };

  fetchOtherInvestments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestorInvestmentListResponseDto>> => {
    return this.http.get(this.api.FETCH_OTHER_INVESTMENTS, { params: request });
  };

  fetchTransferableInvestments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestorInvestmentListResponseDto>> => {
    return this.http.get(this.api.FETCH_TRANSFERABLE_INVESTMENTS, { params: request });
  };

  updateStrategyStatusAutoInvestment = (
    payload: InvestorAutoInvestmentUpdateStatusRequestDto,
    id: string,
  ): Promise<InvestorAutoInvestmentResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_STRATEGY_STATUS_AUTO_INVESTMENT, { id });
    return this.http.put(url, payload);
  };

  updateStatusAutoInvestment = (
    payload: InvestorAutoInvestmentUpdateStatusRequestDto,
  ): Promise<InvestorAutoInvestmentResponseDto> => {
    return this.http.put(this.api.UPDATE_GLOBAL_STATUS_AUTO_INVESTMENT, payload);
  };

  fetchAutoInvestmentConfigs = (): Promise<InvestorAutoInvestmentFullConfig> => {
    return this.http.get(this.api.FETCH_AUTO_INVESTMENT_CONFIGS);
  };

  fetchAutoInvestment = (): Promise<InvestorAutoInvestmentResponseDto> => {
    return this.http.get(this.api.FETCH_AUTO_INVESTMENT);
  };

  fetchAutoInvestmentStatisticAvgAnnualInterest = (
    id: string,
  ): Promise<AutoStrategyAvgAnnualInterest> => {
    const url = this.buildUrl(this.api.FETCH_AUTO_INVESTMENT_STATISTIC_AVG_ANNUAL_INTEREST, { id });
    return this.http.get(url);
  };

  fetchAutoInvestmentStatisticAvgWeightedRating = (
    id: string,
  ): Promise<AutoStrategyAvgWeightedRating> => {
    const url = this.buildUrl(this.api.FETCH_AUTO_INVESTMENT_STATISTIC_AVG_WEIGHTED_RATING, { id });
    return this.http.get(url);
  };

  fetchAutoInvestmentStatisticChartData = (id: string): Promise<AutoStrategyChatData> => {
    const url = this.buildUrl(this.api.FETCH_AUTO_INVESTMENT_STATISTIC_CHART_DATA, { id });
    return this.http.get(url);
  };

  storeAutoInvestmentStrategy = (
    payload: StoreStrategyDto,
  ): Promise<InvestorAutoInvestmentResponseDto> => {
    return this.http.post(this.api.STORE_AUTO_INVESTMENT_STRATEGY, payload);
  };

  deleteAutoInvestmentStrategy = (id: string): Promise<InvestorAutoInvestmentResponseDto> => {
    const url = this.buildUrl(this.api.DELETE_AUTO_INVESTMENT_STRATEGY, { id });

    return this.http.delete(url);
  };

  updateAutoInvestmentStrategy = (
    payload: StoreStrategyDto,
    id: string,
  ): Promise<InvestorAutoInvestmentResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_AUTO_INVESTMENT_STRATEGY, { id });

    return this.http.put(url, payload);
  };

  predictAutoInvestmentStrategy = (payload: StoreStrategyDto): Promise<AutoInvestPrediction> => {
    return this.http.post(this.api.PREDICT_AUTO_INVESTMENT_STRATEGY, payload);
  };

  updateAutoInvestmentStrategyOrder = (payload: { strategy_ids: string[] }): Promise<any> => {
    return this.http.post(this.api.ORDER_STRATEGIES, payload);
  };

  refundInvestment = (
    investmentId: string | number,
    payload: RefundedReasonI,
  ): Promise<InvestorAutoInvestmentResponseDto> => {
    const url = this.buildUrl(this.api.REFUND_INVESTMENT, { investmentId });

    return this.http.post(url, payload);
  };

  exportInvestments = (
    investment_type: ProjectInvestmentStatusEnum,
    type: CommonDocumentTypeEnum,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.EXPORT_INVESTMENTS, { investment_type, type });
    return this.http.get(url, { params: request });
  };
}

export default InvestorInvestment;
