import { ErrorMessage, useField } from 'formik';
import React, { ReactElement } from 'react';
import useTranslation from 'helpers/useTranslation';

import CheckboxTextInput from './CheckboxTextInput';
import CheckboxInputValue from './CheckboxInputValue';

export interface Props
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'placeholder'
  > {
  name: string;
  values: string[];
  valuesWithText?: string[];
  showPlaceholder: boolean;
  placeholder?: string | ReactElement;
  textPlaceholders?: { [key: string]: string };
  placeholderValuesPrefix?: string;
}

const CheckboxInputGroup: React.FC<Props> = ({ valuesWithText = [], ...props }) => {
  const { tHtml } = useTranslation();
  const [, meta, helper] = useField(props.name);

  if (!props.placeholder && props.showPlaceholder) {
    props.placeholder = tHtml(`placeholder.${props.name}`);
  }

  const isError = meta?.touched && !!meta?.error;

  const checkboxes = props.values.map((value) => (
    <div className={meta.error ? 'is-invalid' : ''} key={value}>
      {valuesWithText.includes(value) && (
        <CheckboxTextInput
          key={value}
          name={props.name}
          isMulti={true}
          value={value}
          textName={`${value}_${props.name}_text`}
          showPlaceholder={props.showPlaceholder}
          onInput={() => helper.setTouched(true, true)}
          textPlaceholder={
            props.textPlaceholders && props.textPlaceholders[value]
              ? props.textPlaceholders[value]
              : ''
          }
          placeholderPrefix={props.placeholderValuesPrefix}
        />
      )}
      {!valuesWithText.includes(value) && (
        <CheckboxInputValue
          key={value}
          name={props.name}
          value={value}
          showPlaceholder={props.showPlaceholder}
          placeholderPrefix={props.placeholderValuesPrefix}
          onInput={() => helper.setTouched(true, true)}
        />
      )}
    </div>
  ));

  return (
    <div className={`form-input mb-3`}>
      <label className={'form-question-label'} htmlFor={props.name}>
        {props.placeholder}
      </label>
      {checkboxes}
      {isError && (
        <div className={'invalid-feedback ms-2'}>
          {props.name && <ErrorMessage name={props.name} />}
        </div>
      )}
    </div>
  );
};

export default CheckboxInputGroup;
