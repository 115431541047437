import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { format } from 'date-fns';
import * as Yup from 'yup';
import { ResolveOptions } from 'yup/lib/Condition';

import { RouteList } from 'routes';
import Api from 'api';
import {
  QuestionAnswerRequestDto,
  QuestionAnswerResponseDto,
  QuestionResponseDto,
  QuizAnswersRequestDto,
} from 'api/types/investor/quiz';
import { success } from 'services/toastr';
import { navigate } from 'helpers';
import QuizStepWizard from './QuizStepWizard';
import StepsDisplayer from './StepsDisplayer';
import CardInputGroup, { isQuestionDisabled } from './CardInputGroup';
import InvestorQuizSkeleton from './InvestorQuizSkeleton';
import { useAuth } from 'services/useAuth';
import { useAccountQuery } from 'api/queries';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import { useStepsModalStateStore } from '../../modals/RequiredSteps/store';
import { PrimaryButton } from 'components/Button';
import StepFormHeaderSubmit from 'containers/StepFormLayout/StepFormHeaderSubmit';

export interface Props extends RouteComponentProps {
  questionsPerPage: number[];
  stepToDecideExperiencedInvestor: number;
  experiencedInvestorQuestionsToSubmit: number;
}

interface ReactRouterDomParams {
  new: boolean;
}

interface QuizSliceArray {
  quiz_slices: QuestionAnswerResponseDto[][];
}

const InvestorQuiz: React.FC<Props> = ({
  questionsPerPage,
  stepToDecideExperiencedInvestor,
  experiencedInvestorQuestionsToSubmit,
}) => {
  const { data: account } = useAccountQuery();
  const location = useLocation().state as ReactRouterDomParams;
  const isQuizNew: boolean =
    (account?.is_investor_quiz_outdated || (location && location.new)) ?? false;
  const { t, tHtml } = useTranslation();
  const modalState = useStepsModalStateStore();
  const { refresh } = useAuth();

  const { invalidate: invalidateProfile } = useUserProfileQuery();

  if (account?.user === null) {
    questionsPerPage = [6, 1, 1, 4, 4, 1, 1, 1, 1, 1, 1, 1, 1];
    stepToDecideExperiencedInvestor = 2;
    experiencedInvestorQuestionsToSubmit = 6;
  } else {
    questionsPerPage = [5, 1, 1, 4, 4, 1, 1, 1, 1, 1, 1, 1, 1];
    stepToDecideExperiencedInvestor = 2;
    experiencedInvestorQuestionsToSubmit = 5;
  }

  const [agreedAlerts, setAgreedAlerts] = useState<string[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [slices, setSlices] = useState<QuizSliceArray>({ quiz_slices: [] });
  const [experiencedInvestor, setExperiencedInvestor] = useState<boolean>(false);
  const [isForward, setIsForward] = useState<boolean>(true);
  const [quizRequest, setQuizRequest] = useState<QuizAnswersRequestDto>({
    questions_answers: [],
    agreed_alerts: [],
    quiz_agreement: false,
    documents: [],
  });

  useEffect(() => {
    modalState.setShouldBeShown({ state: true });
    /* TODO: fix global loading for skeleton */
    // TODO move to react query
    Promise.all([
      Api.investor.quiz.fetchInvestorQuiz(),
      isQuizNew
        ? Api.investor.quiz.fetchInvestorQuizAnswersNew()
        : Api.investor.quiz.fetchInvestorQuizAnswers(),
    ]).then(([quizResponse, quizAnswersResponse]) => {
      const quizAnswers: QuizAnswersRequestDto = {
        questions_answers: quizResponse.questions.map((item: QuestionResponseDto) => ({
          ...item,
          answers: isQuizNew
            ? []
            : quizAnswersResponse?.questions_answers?.find(
                (value) => value.question === item.question,
              )?.answers ?? [],
        })),
        agreed_alerts: quizAnswersResponse?.agreed_alerts ?? [],
        quiz_agreement: false,
        documents: [],
      };

      quizAnswersResponse?.agreed_alerts && setAgreedAlerts(quizAnswersResponse?.agreed_alerts);

      paginate(quizAnswers.questions_answers);
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [t]);

  const paginate = (ques: Array<QuestionAnswerRequestDto>) => {
    const quizSlices: any[] = [];

    let index = 0;
    let i = 0;

    while (i < ques.length) {
      const slice = ques.slice(i, i + questionsPerPage[index]);
      quizSlices.push(slice);
      i += questionsPerPage[index];
      index = (index + 1) % questionsPerPage.length;
    }

    const reqInitial = {
      questions_answers: quizSlices[0],
      agreed_alerts: [],
      quiz_agreement: false,
      documents: [],
    };

    setQuizRequest(reqInitial);
    setSlices({ quiz_slices: quizSlices });
  };

  const sumElementsUpToIndex = (index: number) => {
    if (index >= 0 && index < questionsPerPage.length) {
      return questionsPerPage
        .slice(0, index + 1)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    } else {
      return 0;
    }
  };

  const onSubmit = useCallback(
    async (request: QuizAnswersRequestDto) => {
      if (experiencedInvestor) {
        request.questions_answers = request.questions_answers.slice(
          0,
          experiencedInvestorQuestionsToSubmit,
        );
      } else {
        request.documents = [];
      }

      await Api.investor.quiz.storeInvestorQuizAnswers({ ...request, agreed_alerts: agreedAlerts });
      success(tHtml('common.created_successfully'));

      invalidateProfile();
      await refresh();
      navigate(RouteList.SHARED.PROFILE.HOME);
    },
    [
      agreedAlerts,
      tHtml,
      invalidateProfile,
      refresh,
      experiencedInvestor,
      experiencedInvestorQuestionsToSubmit,
    ],
  );

  const onSubmitStep = useCallback(
    async (request: QuizAnswersRequestDto, bag, isStepForward: boolean) => {
      if (isStepForward) {
        if (slices.quiz_slices[currentStep]) {
          const objectExists = request.questions_answers.some((obj) =>
            slices.quiz_slices[currentStep].some((sliceObj) => obj.question === sliceObj.question),
          );
          if (!objectExists) {
            request.questions_answers = request.questions_answers.concat(
              slices.quiz_slices[currentStep],
            );
            setQuizRequest(request);
          }
        }
        checkIfInvestorIsExperienced(request);
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(currentStep - 1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep, slices],
  );

  const isLastStep = (): boolean => {
    if (experiencedInvestor) {
      return currentStep === stepToDecideExperiencedInvestor;
    }

    return currentStep === slices.quiz_slices.length + 1;
  };

  const agreementValidationRule: any =
    isLastStep() && isForward
      ? {
          quiz_agreement: Yup.boolean().required().isTrue(),
        }
      : {};

  const QuizSchema = Yup.object().shape({
    questions_answers: Yup.array().of(
      Yup.lazy((value: QuestionAnswerResponseDto, context: ResolveOptions) => {
        const isObjectInArray =
          slices.quiz_slices[currentStep - 1] &&
          slices.quiz_slices[currentStep - 1].some((obj) => obj.question === value.question);
        if (
          isQuestionDisabled(value.disabled_when, context.parent) ||
          !isObjectInArray ||
          !isForward ||
          isLastStep()
        ) {
          return Yup.object()
            .shape({
              answers: Yup.array().nullable(),
            })
            .nullable();
        }
        return Yup.object()
          .shape({
            answers: Yup.array().required().min(1),
          })
          .required();
      }) as any,
    ),
    ...agreementValidationRule,
  });

  const WizardStep = ({ children }: any) => children;

  if (slices.quiz_slices.length <= 0) {
    return <InvestorQuizSkeleton />;
  }

  const checkIfInvestorIsExperienced = (values: QuizAnswersRequestDto): void => {
    let experiencedInvestorAnswersCount = 0;
    if (!account?.user && values && currentStep == stepToDecideExperiencedInvestor - 1) {
      if (
        values.questions_answers[2] &&
        values.questions_answers[2].answers.includes('question_3.option_2')
      ) {
        experiencedInvestorAnswersCount++;
      }
      if (
        values.questions_answers[4] &&
        values.questions_answers[4].answers.includes('question_5.option_2')
      ) {
        experiencedInvestorAnswersCount++;
      }
      if (
        values.questions_answers[5] &&
        values.questions_answers[5].answers.includes('question_6.option_1')
      ) {
        experiencedInvestorAnswersCount++;
      }

      setExperiencedInvestor(experiencedInvestorAnswersCount >= 2);
    } else if (values && currentStep == stepToDecideExperiencedInvestor - 1) {
      if (
        values.questions_answers[1] &&
        values.questions_answers[1].answers.includes('company.question_2.option_1')
      ) {
        experiencedInvestorAnswersCount++;
      }
      if (
        values.questions_answers[2] &&
        values.questions_answers[2].answers.includes('company.question_3.option_1')
      ) {
        experiencedInvestorAnswersCount++;
      }
      if (
        values.questions_answers[3] &&
        values.questions_answers[3].answers.includes('company.question_4.option_1')
      ) {
        experiencedInvestorAnswersCount++;
      }
      if (
        values.questions_answers[4] &&
        values.questions_answers[4].answers.includes('company.question_5.option_1')
      ) {
        experiencedInvestorAnswersCount++;
      }
      setExperiencedInvestor(experiencedInvestorAnswersCount >= 1);
    }
  };

  return (
    <>
      <StepFormHeaderSubmit
        request={quizRequest}
        showFinishLater={!isLastStep()}
        showSaveDraft={
          !account?.has_investor_quiz_filled || account?.has_investor_quiz_draft_active || isQuizNew
        }
      />
      <Container className={'main-content d-block mx-auto px-2 px-sm-4 px-md-6'}>
        <div>
          <StepsDisplayer
            numberOfSteps={slices.quiz_slices.length}
            currentStep={currentStep}
            isLastStep={isLastStep()}
          />
        </div>
        <div className={'col-12 col-md-10 col-lg-8 mx-auto'}>
          {currentStep === 0 ? (
            <>
              <h1 className={'display-6'}>{tHtml('quiz.quiz_title')}</h1>
              <div className={'fw-bold mb-3'}>
                <span>{format(new Date(), 'yyyy-MM-dd')}</span>
                <span className={'mx-4'}>{account?.name}</span>
              </div>
              <div className={'mb-5'}>
                <p>{tHtml('quiz.quiz_information')}</p>
              </div>
              <PrimaryButton onClick={() => setCurrentStep(1)}>
                {tHtml('common.next')}
              </PrimaryButton>
            </>
          ) : (
            <QuizStepWizard
              initialValues={quizRequest}
              onSubmit={onSubmit}
              onStepSubmit={onSubmitStep}
              validationSchema={QuizSchema}
              isLastStep={isLastStep()}
              documentIsRequired={experiencedInvestor && account?.user !== null}
              setIsForward={setIsForward}
            >
              {experiencedInvestor && isLastStep() ? (
                <WizardStep>
                  <div className={'mb-5'}>
                    <div className={'fw-bold mb-3'}></div>
                  </div>
                </WizardStep>
              ) : isLastStep() ? (
                <WizardStep>
                  <div className={'mb-5'}>
                    <div className={'fw-bold mb-3'}></div>
                  </div>
                </WizardStep>
              ) : (
                slices.quiz_slices.map((slice: Array<QuestionResponseDto>, stepIndex) => (
                  <div key={stepIndex} className={'mb-5'}>
                    <div className={'fw-bold mb-3 fs-4'}>
                      <>{tHtml(`quiz.${slice[0].title}`)}</>
                    </div>
                    <WizardStep>
                      <div className={'mb-5'}>
                        {slice.map((item: QuestionResponseDto, index) => (
                          <div key={index} className={'mb-5'}>
                            <CardInputGroup
                              index={index}
                              stepIndex={stepIndex}
                              stepSize={index + sumElementsUpToIndex(stepIndex - 1)}
                              name={item.question}
                              values={item.options}
                              type={item.type}
                              alerts={item.alerts}
                              agreedAlerts={agreedAlerts}
                              setAgreedAlerts={setAgreedAlerts}
                              enabledWhen={item.enabled_when}
                            />
                          </div>
                        ))}
                      </div>
                    </WizardStep>
                  </div>
                ))
              )}
            </QuizStepWizard>
          )}
        </div>
      </Container>
    </>
  );
};

export default withRouter(InvestorQuiz);
