import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Column, Row as TableRow, RowPropGetter } from 'react-table';
import useTranslation from 'helpers/useTranslation';

import { PieChartDataEntry } from 'components/PieChart/PieChart';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { InvestorDashboardStatisticsCommonResponseDto } from 'api/types/investor/dashboard';
import InvestorPortfolioPieChartWithTable from './InvestorPortfolioPieChartWithTable';
import {
  calculateChartTotalAmount,
  getChartValue,
  getShareValue,
  InvestorStatisticsChartData,
  InvestorStatisticsDataByKey,
  transformToChartData,
} from './chartHelpers';
import { ITEMS_PER_PAGE } from 'components/Table/Table';

interface Props {
  companiesData: InvestorStatisticsDataByKey;
  amountColumn: string;
}

const InvestorPortfolioByCompany: React.FC<Props> = ({ companiesData, amountColumn }) => {
  const { t } = useTranslation();

  const [pieChartData, setPieChartData] = useState<PieChartDataEntry[]>([]);
  const [tableData, setTableData] =
    useState<PaginationData<InvestorDashboardStatisticsCommonResponseDto>>();
  const [pieChartActiveIndex, setPieChartActiveIndex] = useState<number>(-1);
  const [companiesDataArr, setCompaniesDataArr] = useState<InvestorStatisticsChartData>([]);

  useEffect(() => {
    setCompaniesDataArr(
      transformToChartData(companiesData).filter(
        (val) => val[amountColumn as keyof typeof val] > 0,
      ),
    );
  }, [setCompaniesDataArr, companiesData, amountColumn]);

  const totalAmount = calculateChartTotalAmount(companiesDataArr, amountColumn);

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.name'),
        accessor: 'name',
        sortType: 'string',
      },
      {
        Header: t('table.share'),
        accessor: 'share',
        sortType: 'string',
        Cell: (cell: any) => {
          return t('common.percents', { value: cell.value });
        },
      },
    ],
    [t],
  );

  useEffect(() => {
    setPieChartData(
      companiesDataArr.map((item) => {
        return {
          name: item['name'],
          value: getChartValue(item, totalAmount, amountColumn),
        };
      }),
    );
  }, [amountColumn, companiesDataArr, totalAmount]);

  const fetchData = useCallback(
    async (paginationFilter?: PaginationDataFilter) => {
      const { page = 1, limit = ITEMS_PER_PAGE } = paginationFilter || {};

      const startIdx = (page - 1) * limit;
      const endIdx = startIdx + limit;

      const slicedData = companiesDataArr.slice(startIdx, endIdx);

      const statisticsData = {
        data: slicedData.map((item, idx: number) => ({
          ...item,
          share: getShareValue(item, totalAmount, amountColumn),
          idx: startIdx + idx,
          isSelected: false,
        })),
        meta: {
          current_page: page,
          from: startIdx + 1,
          last_page: Math.ceil(companiesDataArr.length / limit),
          to: Math.min(endIdx, companiesDataArr.length),
          total: companiesDataArr.length,
        },
      };

      setTableData(statisticsData);
    },
    [amountColumn, companiesDataArr, totalAmount],
  );

  const onChange = (index: number) => {
    if (tableData?.data) {
      tableData.data = tableData.data.map(
        (item: InvestorDashboardStatisticsCommonResponseDto, itemIndex) => ({
          ...item,
          isSelected: index == itemIndex,
        }),
      );

      setTableData({
        ...tableData,
      });
    }
  };

  const getRowProps = (
    row: TableRow<Record<string, unknown>>,
  ): RowPropGetter<Record<string, unknown>> => {
    const result: Record<string, unknown> = {
      onMouseEnter: () => {
        setPieChartActiveIndex(row.original.idx as number);
      },
    };

    if (row.original?.isSelected) {
      result['className'] = 'active-row';
    }

    return result;
  };

  return (
    <InvestorPortfolioPieChartWithTable
      data={pieChartData}
      tableData={tableData}
      columns={columns}
      onFetchData={fetchData}
      isPlacementVertical={true}
      onChange={onChange}
      tableRowProps={getRowProps}
      indexState={{ index: pieChartActiveIndex, setIndex: setPieChartActiveIndex }}
    />
  );
};

export default InvestorPortfolioByCompany;
