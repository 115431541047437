import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Column } from 'react-table';
import { generatePath, Link } from 'react-router-dom';

import { RouteList } from 'routes';
import { PaginationData, PaginationDataFilter } from 'api/types';
import Table from 'components/Table/Table';
import { PrimaryButton } from 'components/Formik';
import ProjectLoanActivityStatusBadge from '../../components/ProjectLoanActivityStatusBadge';
import { SecondaryMarketMyActiveInvestmentsResponseDto } from 'api/types/investor/secondary_market';
import Api from 'api';
import useRender, { ACTIVE_INVESTMENTS_LIST } from './useRender';

const MyActiveInvestmentsList: React.FC = () => {
  const { t, currentLanguage, tHtml } = useTranslation();

  const [activeInvestments, setActiveInvestments] =
    useState<PaginationData<SecondaryMarketMyActiveInvestmentsResponseDto>>();

  const { count } = useRender(ACTIVE_INVESTMENTS_LIST);

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.project_name'),
        accessor: 'project_name',
        sortType: 'string',
        Cell: (cell: any) => {
          const row = cell.row.original;
          return (
            <Link
              to={{
                pathname: generatePath(
                  RouteList.INVESTOR.SECONDARY_MARKET.SELLER.PROJECT_INVESTMENT,
                  {
                    projectInvestmentId: row.id,
                  },
                ),
              }}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <span className={'text-highlight'}>{cell.value}</span>
            </Link>
          );
        },
      },
      {
        Header: t('table.investment'),
        accessor: 'investment_amount',
        sortType: 'number',
        Cell: (cell: any) => t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.investment_date'),
        accessor: 'invested_at',
        sortType: '',
        Cell: (cell: any) => <span>{tHtml('common.date_full', { date: cell.value })}</span>,
      },
      {
        Header: t('table.investment_term_left'),
        accessor: 'term',
        sortType: '',
        Cell: (cell: any) => {
          const { total, passed } = cell.value;

          return <span>{tHtml('common.days_interval', { passed: passed, total: total })}</span>;
        },
      },
      {
        Header: t('table.yearly_interest'),
        accessor: 'basic_interest',
        canFilter: true,
        sortType: 'number',
        Cell: (cell: any) => t('common.percents', { value: cell.value }),
      },
      {
        Header: t('table.received_total'),
        accessor: 'amount_gained',
        sortType: 'number',
        Cell: (cell: any) => t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.receivable_amount_left'),
        accessor: 'receivable_amount_left',
        sortType: 'number',
        Cell: (cell: any) => t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.loan_left_amount'),
        accessor: 'loan_left_amount',
        sortType: 'number',
        Cell: (cell: any) => t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: '',
        Cell: (cell: any) => <ProjectLoanActivityStatusBadge type={cell.value} />,
      },
      {
        accessor: 'action',
        width: 100,
        disableSortBy: true,
        Cell: (cell: any) => {
          const row = cell.row.original;
          return (
            <div className={'secondary-market-table-button-seller'}>
              <Link
                to={{
                  pathname: generatePath(
                    RouteList.INVESTOR.SECONDARY_MARKET.SELLER.PROJECT_INVESTMENT,
                    {
                      projectInvestmentId: row.id,
                    },
                  ),
                }}
              >
                <PrimaryButton>{tHtml('common.sell')}</PrimaryButton>
              </Link>
            </div>
          );
        },
      },
    ],
    [t, tHtml],
  );

  const fetchTableData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      await Api.investor.secondary_market
        .fetchPersonalActiveInvestmentList(request)
        .then((response) => setActiveInvestments(response));
    },
    // eslint-disable-next-line
    [count, currentLanguage],
  );

  useEffect(() => {
    return;
  }, [count]);

  return (
    <div className={'mb-5'}>
      <h2 className={'mb-4'}>{tHtml('secondary_market.my_active_investments')}</h2>
      <Table
        columns={columns}
        data={activeInvestments}
        onFetchData={fetchTableData}
        noDataLabel={tHtml('investments.no_active_investments')}
        mobile={true}
      />
    </div>
  );
};

export default MyActiveInvestmentsList;
