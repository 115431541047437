import { FunctionalButton } from 'components/Button';
import React from 'react';
import useTranslation from 'helpers/useTranslation';

interface Props {
  handler: () => void;
  title?: string;
  icon?: string;
}

const EditHandlerAction = ({ handler, title, icon }: Props) => {
  const { tHtml } = useTranslation();

  return (
    <div>
      <FunctionalButton
        className={'site btn btn-functional edit-button'}
        icon={icon ?? ''}
        onClick={handler}
      >
        {title ?? tHtml('common.edit')}
      </FunctionalButton>
    </div>
  );
};

export default EditHandlerAction;
