import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import { action } from 'typesafe-actions';
import * as Yup from 'yup';
import Api from 'api';
import { PrimaryButton, TextInput } from 'components/Formik';
import transformErrors from 'helpers/formik';
import { redirect } from 'scopes/guest/helpers/utils';
import { ProspectUserCompanyExperienceResponseDto } from 'api/types/guest/prospectApplication';
import Loader from 'components/Loader';
import { ApplicationPageProps } from '../steps';
import { ApplicationContext } from '../module/ApplicationContext';
import { UPDATE_APPLICATION } from '../module/ApplicationReducer';
import CompanyExperienceFormBlock from './CompanyExperienceFormBlock';

const CompanyExperience: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();
  const { state, dispatch } = useContext(ApplicationContext);
  const [initialFormValues, setInitialFormValues] =
    useState<ProspectUserCompanyExperienceResponseDto | null>(null);

  useEffect(() => {
    if (state.application?.prospect_application.id !== applicationId) return;
    const companyInfo = state.application?.prospect_application.company_experience;
    if (companyInfo) {
      setInitialFormValues(companyInfo);
    } else {
      setInitialFormValues({
        id: '',
        manager_experience_in_area_size: 0,
        manager_comments: '',
        group_experience_in_area_size: 0,
        group_comments: '',
        financial_obligations: [],
      });
    }
  }, [t, applicationId, state]);

  const onSubmit = useCallback(
    async (
      request: ProspectUserCompanyExperienceResponseDto,
      helpers: FormikHelpers<ProspectUserCompanyExperienceResponseDto>,
    ) => {
      try {
        await Api.guest.prospect_application
          .storeApplicationCompanyExperience(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  const schema = Yup.object().shape({
    manager_experience_in_area_size: Yup.number().min(0).required(),
    manager_comments: Yup.string().max(255).required(),
    group_experience_in_area_size: Yup.number().min(0).required(),
    group_comments: Yup.string().max(255).required(),
    financial_obligations: Yup.array()
      .of(
        Yup.object().shape({
          amount: Yup.number().money().required(),
          repayment_deadline: Yup.string().required(),
          security_measure: Yup.string().max(255).required(),
          purpose: Yup.string().max(255).required(),
          creditor: Yup.string().max(255).required(),
        }),
      )
      .optional(),
  });

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          {initialFormValues ? (
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              initialValues={initialFormValues}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {(helpers) => (
                <Form onSubmit={helpers.handleSubmit}>
                  <FormGroup>
                    <div className="mb-4">
                      <TextInput
                        type={'number'}
                        name={'group_experience_in_area_size'}
                        placeholder={t(
                          'placeholder.company.experience.group_experience_in_area_size',
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'group_comments'}
                        placeholder={t('placeholder.company.experience.group_comments')}
                      />
                    </div>

                    <div className="mb-4">
                      <TextInput
                        type={'number'}
                        name={'manager_experience_in_area_size'}
                        placeholder={t(
                          'placeholder.company.experience.manager_experience_in_area_size',
                        )}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'manager_comments'}
                        placeholder={t('placeholder.company.experience.manager_comments')}
                      />
                    </div>
                    <hr />
                    <h5 className="mb-4 mt-4">
                      {tHtml('placeholder.company.experience.financial_obligations')}
                    </h5>
                    <CompanyExperienceFormBlock
                      fieldName={'financial_obligations'}
                      values={helpers.values.financial_obligations}
                      formikHelpers={helpers}
                    />
                  </FormGroup>
                  <hr />
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <PrimaryButton
                      className={'btn btn-grey w-25'}
                      type={'button'}
                      onClick={() => {
                        redirect(history, previousPage, applicationId);
                      }}
                    >
                      {tHtml('common.back')}
                    </PrimaryButton>
                    <PrimaryButton
                      className={'btn btn-primary w-25'}
                      submitting={helpers.isSubmitting}
                    >
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default withRouter(CompanyExperience);
