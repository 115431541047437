import styled from 'styled-components';
import { StyledDataBlockLargeProps } from './DataBlockLarge';

export const StyledDataBlockLarge = styled.div`
  display: ${(props: StyledDataBlockLargeProps) => (props.isInline ? 'inline' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StyledDataBlockLargeTitle = styled.span`
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
  padding-right: ${(props: StyledDataBlockLargeProps) => (props.isInline ? '1rem' : 'none')};
`;

export const StyledDataBlockLargeContent = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--profitus-h1-color);
  margin-bottom: 0;
`;
