import React, { useEffect, useState } from 'react';
import { ThemeEnum } from 'helpers/enums/ThemeEnum';
import { CommonEnum } from 'helpers/enums/CommonEnum';
import { useLocalStorage } from 'helpers';
import useDisplay from 'helpers/useDisplay';

const ThemeSetter: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [theme, setTheme] = useLocalStorage<string>(CommonEnum.Theme, ThemeEnum.Light);
  const { isNativeApp } = useDisplay();

  useEffect(() => {
    if (!isLoaded) {
      document.body.classList.toggle('preload', true);
      setIsLoaded(true);
    } else {
      document.body.classList.toggle('preload', false);
    }
  }, [isLoaded]);

  useEffect(() => {
    setTheme(theme);
    if (!isNativeApp) {
      document.documentElement.setAttribute('data-theme', theme);
    }
  }, [setTheme, theme, isNativeApp]);

  return null;
};

export default ThemeSetter;
