import styled from 'styled-components';
import { StyledCard } from 'components/Card/Card.style';

export const StyledProjectInformation = styled.div`
  margin-bottom: 2rem;
`;

export const StyledProjectInformationCard = styled(StyledCard)`
  margin-top: 2.25rem;
`;

export const StyledProjectInformationDetails = styled.div`
  margin-top: 2rem;
`;

export const StyledProjectInformationDescriptionSection = styled.div`
  margin-top: 3rem;
`;
