import React, { useRef } from 'react';
import { ProjectArticle } from '../types';
import SimpleBar from 'simplebar-react';
import useTranslation from 'helpers/useTranslation';

interface Props {
  articles: ProjectArticle[];
}

const Articles: React.FC<Props> = ({ articles }) => {
  const ref = useRef<SimpleBar>();
  const { tHtml } = useTranslation();

  return (
    <>
      <SimpleBar ref={ref as React.LegacyRef<SimpleBar>} className={'news'}>
        <div>
          {articles.map((item, index) => (
            <div className={'news-item'} key={index}>
              <div className={'news-title'}>{item.title}</div>
              <div className={'news-content text-pre'}>{item.value}</div>
              <div className={'news-date'}>
                {tHtml('common.datetime', { date: item.published_at })}
              </div>
            </div>
          ))}
        </div>
      </SimpleBar>
    </>
  );
};

export default Articles;
