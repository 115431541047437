import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import { UPDATE_APPLICATION } from '../module/ApplicationReducer';
import { action } from 'typesafe-actions';
import * as Yup from 'yup';

import Api from 'api';
import { PrimaryButton, SelectBox, TextInput } from 'components/Formik';
import transformErrors from 'helpers/formik';
import { ApplicationContext } from '../module/ApplicationContext';
import { redirect } from 'scopes/guest/helpers/utils';
import { ApplicationPageProps } from '../steps';
import { ProspectApplicationSecurityDepositsDto } from 'api/types/guest/prospectApplication';
import SecurityDepositsFormBlock from './SecurityDepositsFormBlock';
import YesNoSelector from 'components/Formik/YesNoSelector';
import FileUpload, { FileUploadHandle } from 'components/FileUpload/FileUpload';
import Loader from 'components/Loader';
import { ApplicationRealEstateType } from 'scopes/guest/helpers/enums/ApplicationRealEstateType';

const SecurityDeposits: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const [risks, setRisks] = useState<string[]>([]);
  const { state, dispatch } = useContext(ApplicationContext);
  const [initialFormValues, setInitialFormValues] =
    useState<ProspectApplicationSecurityDepositsDto | null>(null);

  useEffect(() => {
    Api.guest.prospect_application.fetchProjectRisks().then((response) => {
      setRisks(response);
    });

    if (state.application?.prospect_application.id !== applicationId) return;
    if (state.application?.prospect_application.security_deposits) {
      const deposits = state.application?.prospect_application.security_deposits;
      setInitialFormValues(deposits);
    } else {
      setInitialFormValues({
        assessment_report_document_exists: true,
        assessment_report_media_ids: [],
        security_deposits: [],
        surety_exists: false,
        surety_name: '',
        surety_code: null,
        risks: [],
      });
    }
  }, [t, applicationId, state]);

  const onSubmit = useCallback(
    async (
      request: ProspectApplicationSecurityDepositsDto,
      helpers: FormikHelpers<ProspectApplicationSecurityDepositsDto>,
    ) => {
      try {
        await Api.guest.prospect_application
          .storeApplicationSecurityDeposits(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  const schema = Yup.object().shape({
    assessment_report_document_exists: Yup.boolean(),
    security_deposits: Yup.array().when('assessment_report_document_exists', {
      is: false,
      then: Yup.array()
        .min(1)
        .of(
          Yup.object().shape({
            real_estate_type: Yup.string()
              .oneOf(Object.values(ApplicationRealEstateType).map((value) => value))
              .required(),
            real_estate_unique: Yup.string().when('real_estate_type', {
              is: (value: ApplicationRealEstateType) =>
                value === ApplicationRealEstateType.LAND_PLOT,
              then: Yup.string().required(),
              otherwise: Yup.string().optional().nullable(),
            }),
            real_estate_plot: Yup.number().min(1).required(),
            real_estate_address: Yup.string().when('real_estate_type', {
              is: (value: ApplicationRealEstateType) =>
                value === ApplicationRealEstateType.LAND_PLOT,
              then: Yup.string().optional().nullable(),
              otherwise: Yup.string().required(),
            }),
            real_estate_value: Yup.number().money().required(),
          }),
        ),
      otherwise: Yup.array().nullable(),
    }),
    assessment_report_media_ids: Yup.array().when('assessment_report_document_exists', {
      is: true,
      then: Yup.array().min(1).required(),
      otherwise: Yup.array(),
    }),
    surety_exists: Yup.boolean().required(),
    surety_name: Yup.string().when('surety_exists', {
      is: true,
      then: Yup.string().fullName().required(),
      otherwise: Yup.string().nullable(),
    }),
    surety_code: Yup.string().when('surety_exists', {
      is: true,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable(),
    }),
  });

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          {initialFormValues ? (
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              initialValues={initialFormValues}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {(helpers) => (
                <Form onSubmit={helpers.handleSubmit}>
                  <FormGroup>
                    <div className="mb-3">
                      <h2 className={'mb-1'}>{tHtml('prospect_application.security_deposits')}</h2>
                      <YesNoSelector
                        name={'assessment_report_document_exists'}
                        placeholder={t(
                          'placeholder.prospect_application.assessment_report_document_exists',
                        )}
                      />
                    </div>
                    {helpers.values?.assessment_report_document_exists === true ? (
                      <>
                        <FileUpload
                          ref={fileUploadRef}
                          name={'assessment_report_media_ids'}
                          onPresign={(request) =>
                            Api.guest.prospect_application.uploadAssessmentDocuments(request)
                          }
                          disabled={helpers.isSubmitting}
                        />
                        <div className={'invalid-feedback-visible ms-2'}>
                          <ErrorMessage name={'assessment_report_media_ids'} />
                        </div>
                      </>
                    ) : (
                      <>
                        <h2 className={'mt-4'}>{tHtml('application.deposits')}</h2>
                        <SecurityDepositsFormBlock
                          fieldName={'security_deposits'}
                          values={helpers.values.security_deposits}
                          formikHelpers={helpers}
                        />
                      </>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <div className="mb-3 mt-4">
                      <h2 className={'mb-1'}>{tHtml('prospect_application.surety')}</h2>
                      <YesNoSelector
                        name={'surety_exists'}
                        placeholder={t('placeholder.prospect_application.surety_exists')}
                      />
                    </div>
                    {helpers.values?.surety_exists === true && (
                      <>
                        <div className="mb-4">
                          <TextInput
                            name={'surety_name'}
                            placeholder={t('placeholder.prospect_application.surety_name')}
                          />
                        </div>
                        <div className="mb-4">
                          <TextInput
                            name={'surety_code'}
                            placeholder={t('placeholder.prospect_application.surety_code')}
                          />
                        </div>
                      </>
                    )}
                  </FormGroup>
                  <h2 className="mb-1 mt-4">
                    {tHtml('prospect_application.security_deposits.risks')}
                  </h2>
                  <div className="mb-3">
                    <SelectBox
                      defaultValue={undefined}
                      selectOptions={risks.map((value) => {
                        return {
                          label: t(value),
                          value: value,
                        };
                      })}
                      name={'risks'}
                      setFieldValue={helpers.setFieldValue}
                      closeMenuOnSelect={false}
                    />
                  </div>
                  <hr />
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <PrimaryButton
                      className={'btn btn-grey w-25'}
                      type={'button'}
                      onClick={() => {
                        redirect(history, previousPage, applicationId);
                      }}
                    >
                      {tHtml('common.back')}
                    </PrimaryButton>
                    <PrimaryButton
                      className={'btn btn-primary w-25'}
                      submitting={helpers.isSubmitting}
                    >
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default withRouter(SecurityDeposits);
