export enum WalletProviderEnum {
  Lemonway = 'lemonway',
  Paysera = 'paysera',
  Trustly = 'trustly',
  Mock = 'mock',
}

export const isWalletProviderObsolete = (provider: WalletProviderEnum | null | undefined) => {
  if (!provider) {
    return false;
  }

  return provider == WalletProviderEnum.Trustly || provider == WalletProviderEnum.Paysera;
};

export const isWalletProviderIgnored = (provider: WalletProviderEnum | null | undefined) => {
  if (!provider) {
    return false;
  }

  return provider == WalletProviderEnum.Paysera;
};
