import useScope from './useScope';
import usePermissions from './usePermissions';
import { useEffect, useState } from 'react';
import useUserType from './useUserType';
import { Permission } from './auth/permissions';
import { useUserCompanyProfileQuery } from '../api/queries/useUserCompanyProfileQuery';
import { useUserProfileQuery } from '../api/queries/useUserProfileQuery';

type OnBoardingStatus = {
  hasFilledQuiz: boolean | undefined;
};

const DEFAULT_STATE: OnBoardingStatus = {
  hasFilledQuiz: undefined,
};

const useInvestor = () => {
  const { isUser, isCompany } = useScope();
  const { isInvestor } = useUserType();
  const { can } = usePermissions();

  const [state, setState] = useState<OnBoardingStatus>(DEFAULT_STATE);

  const userProfileQuery = useUserProfileQuery({ enabled: false });
  const userCompanyProfileQuery = useUserCompanyProfileQuery({ enabled: false });

  useEffect(() => {
    if (isUser()) {
      userProfileQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUser]);

  useEffect(() => {
    if (isCompany()) {
      userCompanyProfileQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompany]);

  useEffect(() => {
    let newState = DEFAULT_STATE;
    if (isInvestor()) {
      newState = {
        ...newState,
        hasFilledQuiz: can(Permission.INVESTOR_QUIZ_FILLED),
      };
    }

    setState(newState);
  }, [can, isInvestor]);

  return state;
};

export default useInvestor;
