import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Api from 'api';
import { UserKnowYourCustomerRequestDto } from 'api/types/shared/user/profile';
import { RouteList } from 'routes';
import CheckboxInput from 'components/Formik/CheckboxInput';
import { success } from 'services/toastr';
import CheckboxInputGroup from 'components/Formik/CheckboxGroup/CheckboxInputGroup';
import RadioInputGroup from 'components/Formik/RadioGroup/RadioInputGroup';
import { navigate } from 'helpers';
import CheckboxTextInput from 'components/Formik/CheckboxGroup/CheckboxTextInput';
import { setGlobalLoading } from 'modules/app/actions';
import TextInputLabelHighlighted from './components/TextInputLabelHighlighted';
import { UseNumbers } from 'helpers/useNumbers';
import YesNoSelector from 'components/Formik/YesNoSelector';
import { PrimaryButton } from 'components/Formik';
import { useAccountQuery } from 'api/queries';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import usePermissions from 'helpers/usePermissions';
import { useStepsModalStateStore } from 'scopes/investor/modals/RequiredSteps/store';
import SelectInvestmentPurposeInput from 'components/Formik/Selects/SelectInvestmentPurposeInput';

const KnowYourCustomer: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const modalState = useStepsModalStateStore();
  const { refreshPermissions } = usePermissions();
  const { invalidate } = useAccountQuery();
  const { data: userProfileData, setQueryData: updateUserProfileData } = useUserProfileQuery();

  const workValues = [
    'worker',
    'pensioner',
    'student',
    'welfare_recipient',
    'self_employed',
    'other',
  ];
  const selfEmployedActivityValues = [
    'trade',
    'real_estate',
    'services',
    'agricultural_activities',
    'artistic_activities',
    'legal_services',
    'cash_activities',
    'activities_abroad',
    'activities_ukraine_war',
    'other',
  ];
  const incomeSourceValues = [
    'wage',
    'individual_operating_income',
    'pension_scholarship',
    'securities',
    'savings',
    'welfare',
    'sold_real_estate',
    'other',
  ];
  const investmentAmountValues = [
    'investment_1',
    'investment_2',
    'investment_3',
    'investment_4',
    'investment_5',
    'investment_6',
    'investment_7',
  ];

  useEffect(() => {
    modalState.setShouldBeShown({ state: true });
    if (userProfileData) {
      setKycRequest({
        purpose: userProfileData?.know_your_customer?.purpose ?? [],
        other_purpose_text: userProfileData?.know_your_customer?.other_purpose_text ?? '',
        work: userProfileData?.know_your_customer?.work ?? [],
        worker_work_text: userProfileData?.know_your_customer?.worker_work_text ?? '',
        other_work_text: userProfileData?.know_your_customer?.other_work_text ?? '',
        self_employed_activity: userProfileData?.know_your_customer?.self_employed_activity ?? null,
        other_self_employed_activity_text:
          userProfileData?.know_your_customer?.other_self_employed_activity_text ?? '',
        planned_investment_amount:
          userProfileData?.know_your_customer?.planned_investment_amount ?? '',
        average_income: userProfileData?.know_your_customer?.average_income ?? 0,
        main_income_source: userProfileData?.know_your_customer?.main_income_source ?? [],
        other_main_income_source_text:
          userProfileData?.know_your_customer?.other_main_income_source_text ?? '',
        public_office_position: !!userProfileData?.know_your_customer?.public_office_position,
        public_office_confirm: userProfileData?.know_your_customer?.public_office_confirm ?? '',
        origin_of_invested_funds:
          userProfileData?.know_your_customer?.origin_of_invested_funds ?? '',
        family_public_office_position:
          !!userProfileData?.know_your_customer?.family_public_office_position,
        public_office_family_confirm:
          userProfileData?.know_your_customer?.public_office_family_confirm ?? '',
        bankruptcy_procedure: userProfileData?.know_your_customer?.bankruptcy_procedure ?? false,
        convicted: userProfileData?.know_your_customer
          ? userProfileData?.know_your_customer.convicted
          : null,
        agreement: !!userProfileData?.know_your_customer?.agreement,
      });
      setGlobalLoading(false);
    } else {
      setGlobalLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData]);

  const onSubmit = useCallback(
    async (
      request: UserKnowYourCustomerRequestDto,
      helpers: FormikHelpers<UserKnowYourCustomerRequestDto>,
    ) => {
      request.other_work_text = request.work.includes('other') ? request.other_work_text : '';
      request.worker_work_text = request.work.includes('worker') ? request.worker_work_text : null;

      request.self_employed_activity = request.work.includes('self_employed')
        ? request.self_employed_activity
        : null;
      request.other_self_employed_activity_text = request.self_employed_activity?.includes('other')
        ? request.other_self_employed_activity_text
        : null;

      request.other_main_income_source_text = request.main_income_source.includes('other')
        ? request.other_main_income_source_text
        : '';

      if (!request.public_office_position) {
        request.public_office_confirm = null;
      }

      if (!request.family_public_office_position) {
        request.public_office_family_confirm = null;
      }

      await Api.shared.user.profile
        .storeKYC(request)
        .then((response) => {
          userProfileData?.know_your_customer
            ? success(tHtml('common.updated_successfully'))
            : success(tHtml('common.created_successfully'));
          refreshPermissions();
          updateUserProfileData(response);
          invalidate();
          navigate(RouteList.USER.PROFILE.HOME);
        })
        .catch((e) => {
          helpers.setErrors(e.response?.errors);
        });
    },
    [
      invalidate,
      refreshPermissions,
      tHtml,
      updateUserProfileData,
      userProfileData?.know_your_customer,
    ],
  );

  const [kycRequest, setKycRequest] = useState<UserKnowYourCustomerRequestDto>({
    purpose: userProfileData?.know_your_customer?.purpose ?? [],
    other_purpose_text: userProfileData?.know_your_customer?.other_purpose_text ?? '',
    work: userProfileData?.know_your_customer?.work ?? [],
    worker_work_text: userProfileData?.know_your_customer?.worker_work_text ?? '',
    other_work_text: userProfileData?.know_your_customer?.other_work_text ?? '',
    self_employed_activity: userProfileData?.know_your_customer?.self_employed_activity ?? null,
    other_self_employed_activity_text:
      userProfileData?.know_your_customer?.other_self_employed_activity_text ?? '',
    planned_investment_amount: userProfileData?.know_your_customer?.planned_investment_amount ?? '',
    average_income: userProfileData?.know_your_customer?.average_income ?? 0,
    main_income_source: userProfileData?.know_your_customer?.main_income_source ?? [],
    other_main_income_source_text:
      userProfileData?.know_your_customer?.other_main_income_source_text ?? '',
    public_office_position: !!userProfileData?.know_your_customer?.public_office_position,
    public_office_confirm: userProfileData?.know_your_customer?.public_office_confirm ?? '',
    origin_of_invested_funds: userProfileData?.know_your_customer?.origin_of_invested_funds ?? '',
    family_public_office_position:
      !!userProfileData?.know_your_customer?.family_public_office_position,
    public_office_family_confirm:
      userProfileData?.know_your_customer?.public_office_family_confirm ?? '',
    bankruptcy_procedure: !!userProfileData?.know_your_customer?.bankruptcy_procedure,
    convicted: userProfileData?.know_your_customer
      ? userProfileData?.know_your_customer.convicted
      : null,
    agreement: !!userProfileData?.know_your_customer?.agreement,
  });

  const KYCSchema = Yup.object().shape({
    purpose: Yup.array().min(1),
    other_purpose_text: Yup.string()
      .max(255)
      .when('purpose', {
        is: (purpose: string[]) => purpose.includes('other'),
        then: Yup.string().max(255).required(),
        otherwise: Yup.string().max(255).nullable(),
      }),
    work: Yup.array().min(1),
    worker_work_text: Yup.string()
      .max(255)
      .when('work', {
        is: (work: string[]) => work.includes('worker'),
        then: Yup.string().max(255).required(),
        otherwise: Yup.string().max(255).nullable(),
      }),
    other_work_text: Yup.string()
      .max(255)
      .when('work', {
        is: (work: string[]) => work.includes('other'),
        then: Yup.string().max(255).required(),
        otherwise: Yup.string().max(255).nullable(),
      }),
    self_employed_activity: Yup.array().when('work', {
      is: (work: string[]) => work.includes('self_employed'),
      then: Yup.array().min(1).required(),
      otherwise: Yup.array().nullable(),
    }),
    other_self_employed_activity_text: Yup.string()
      .max(255)
      .when('self_employed_activity', {
        is: (self_employed_activity: string[]) => self_employed_activity?.includes('other'),
        then: Yup.string().max(255).required(),
        otherwise: Yup.string().max(255).nullable(),
      }),
    planned_investment_amount: Yup.string()
      .required()
      .test(
        'is-valid-investment-amount',
        t('validation.required'),
        (value) => typeof value === 'string' && investmentAmountValues.includes(value),
      ),
    average_income: Yup.number().min(1).required(),
    main_income_source: Yup.array().min(1),
    origin_of_invested_funds: Yup.string().max(255).required(),
    other_main_income_source_text: Yup.string()
      .max(255)
      .when('main_income_source', {
        is: (main_income_source: string[]) => main_income_source.includes('other'),
        then: Yup.string().max(255).required(),
        otherwise: Yup.string().max(255).nullable(),
      }),
    public_office_position: Yup.boolean(),
    public_office_confirm: Yup.string()
      .max(255)
      .when('public_office_position', {
        is: (public_office_position: boolean) => public_office_position,
        then: Yup.string().max(255).required(),
        otherwise: Yup.string().max(255).nullable(),
      }),
    family_public_office_position: Yup.boolean(),
    public_office_family_confirm: Yup.string()
      .max(255)
      .when('family_public_office_position', {
        is: (family_public_office_position: boolean) => family_public_office_position,
        then: Yup.string().max(255).required(),
        otherwise: Yup.string().max(255).nullable(),
      }),
    bankruptcy_procedure: Yup.boolean(),
    agreement: Yup.boolean().required().isTrue(),
  });

  return (
    <React.Fragment>
      <h1>{tHtml('placeholder.kyc_title')}</h1>
      {
        <Formik
          initialValues={kycRequest}
          validationSchema={KYCSchema}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <div className={'mb-4'}>
                <SelectInvestmentPurposeInput />
              </div>
              <div className={'form-input mb-4'}>
                <CheckboxInputGroup
                  name={'work'}
                  values={workValues}
                  valuesWithText={['worker', 'other']}
                  showPlaceholder={true}
                  textPlaceholders={{ worker: t('placeholder.worker.text') }}
                />
              </div>
              {values.work?.includes('self_employed') && (
                <div className={'form-input mb-4'}>
                  <CheckboxInputGroup
                    name={'self_employed_activity'}
                    values={selfEmployedActivityValues}
                    valuesWithText={['other']}
                    showPlaceholder={true}
                  />
                </div>
              )}
              <div className={'form-input mb-4'}>
                <TextInputLabelHighlighted
                  name={'average_income'}
                  type={'number'}
                  onKeyPress={UseNumbers.preventNonNumericalInput}
                  onWheel={(e) => e.currentTarget.blur()} /* Disabling number change on scroll */
                />
              </div>
              <div className={'form-input mb-4'}>
                <CheckboxInputGroup
                  name={'main_income_source'}
                  values={incomeSourceValues}
                  valuesWithText={['other']}
                  showPlaceholder={true}
                />
              </div>
              <div className={'form-input mb-4'}>
                <RadioInputGroup
                  name={'planned_investment_amount'}
                  values={investmentAmountValues}
                  showPlaceholder={true}
                />
              </div>
              <div className={'form-input mb-4'}>
                <TextInputLabelHighlighted name={'origin_of_invested_funds'} />
              </div>
              <div className={'form-input mb-4'}>
                <CheckboxTextInput
                  name={'public_office_position'}
                  textName={'public_office_confirm'}
                  isMulti={false}
                  showPlaceholder={true}
                  placeholder={tHtml('placeholder.public_office_position')}
                  textPlaceholder={tHtml('placeholder.public_office_confirm')}
                  withButtons={true}
                />
              </div>
              <div className={'form-input mb-4'}>
                <CheckboxTextInput
                  name={'family_public_office_position'}
                  textName={'public_office_family_confirm'}
                  isMulti={false}
                  showPlaceholder={true}
                  placeholder={tHtml('placeholder.family_public_office_position')}
                  textPlaceholder={tHtml('placeholder.public_office_family_confirm')}
                  withButtons={true}
                />
              </div>
              <div className={'form-input mb-4'}>
                <YesNoSelector
                  name={'bankruptcy_procedure'}
                  placeholder={tHtml('placeholder.bankruptcy_procedure')}
                />
              </div>
              <div className={'form-input mb-4'}>
                <CheckboxInput name={'agreement'} showPlaceholder={true} />
              </div>
              <div className={'d-flex flex-row-reverse'}>
                <PrimaryButton
                  type={'submit'}
                  submitting={isSubmitting}
                  className={'site btn btn-primary'}
                >
                  {tHtml('placeholder.save')}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      }
    </React.Fragment>
  );
};

export default withRouter(KnowYourCustomer);
