// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import i18n from 'i18next';
import * as Yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const buildYupLocale = () => {
  i18n.on('languageChanged', () => {
    updateYupValidationText();
  });

  updateYupValidationText();
};

function updateYupValidationText() {
  Yup.setLocale({
    mixed: {
      default: i18n.t('validation.invalid'),
      notType: i18n.t('validation.select-value'),
      required: i18n.t('validation.required'),
    },
    array: {
      min: i18n.t('validation.required'),
    },
    boolean: {
      isValue: i18n.t('validation.required'),
    },
    number: {
      min: ({ min }) => i18n.t('validation.number.min', { min }),
      max: ({ max }) => i18n.t('validation.number.max', { max }),
    },
    string: {
      email: i18n.t('validation.invalid'),
      length: ({ length }) => i18n.t('validation.length', { length }),
      min: ({ min }) => i18n.t('validation.min', { min }),
      max: ({ max }) => i18n.t('validation.max', { max }),
    },
    date: {
      min: ({ min }) => {
        min = i18n.t('common.date', { date: min });
        return i18n.t('validation.date.min', { min });
      },
      max: ({ max }) => {
        max = i18n.t('common.date', { date: max });
        return i18n.t('validation.date.max', { max });
      },
    },
  });
}
