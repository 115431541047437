import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';

export const StyledUpcomingPaymentsWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const StyledUpcomingPaymentsTable = styled.div`
  margin-top: 1.5rem;
`;

export const StyledUpcomingPaymentsTableSubComponent = styled.div`
  background-color: var(--profitus-table-sub-component-background-color);
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 4.25rem;

  ${breakpointDown.MD} {
    flex-direction: column;
    gap: 0;
  }
`;
