import React, { useEffect } from 'react';
import useTranslation from 'helpers/useTranslation';
import { getTrackBackground, Range as ExternalRange } from 'react-range';

interface Props {
  step?: number;
  min?: number;
  max: number;
  onChange?: (values: [number, number]) => void;
  values: [number, number];
  translation?: string;
  showLimits?: boolean;
}

const defaultProps: Props = {
  step: 1,
  min: 0,
  max: 0,
  onChange: undefined,
  values: [0, 0],
  translation: 'common.money',
  showLimits: true,
};

const SliderWithRange: React.FC<Props> = (props) => {
  const { tHtml } = useTranslation();

  useEffect(() => {
    if (props.onChange != undefined) {
      props.onChange(props.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const isBetweenMinAndMax = (value: number): boolean => {
    return props.min! <= value && value <= props.max;
  };

  const trimSelectedValues = (): [number, number] => {
    const firstValue = isBetweenMinAndMax(props.values[0]) ? props.values[0] : props.min;
    const secondValue = isBetweenMinAndMax(props.values[1]) ? props.values[1] : props.max;

    return [firstValue!, secondValue];
  };

  return (
    <div className={'slider range'}>
      <ExternalRange
        step={props.step}
        min={props.min}
        max={props.max}
        values={trimSelectedValues()}
        onChange={(values: any) => {
          props.onChange && props.onChange(values);
        }}
        renderTrack={(trackProps) => (
          <div
            onMouseDown={trackProps.props.onMouseDown}
            onTouchStart={trackProps.props.onTouchStart}
            className={'wrapper'}
          >
            <div
              ref={trackProps.props.ref}
              className={'track'}
              style={{
                background: getTrackBackground({
                  values: props.values,
                  colors: [
                    'var(--profitus-slider-track-bg-color)',
                    'var(--profitus-slider-track-accent-bg-color)',
                    'var(--profitus-slider-track-bg-color)',
                  ],
                  min: props.min!,
                  max: props.max!,
                }),
              }}
            >
              {trackProps.children}
            </div>
          </div>
        )}
        renderThumb={(thumbProps) => {
          return (
            <div {...thumbProps.props} className={thumbProps.isDragged ? 'thumb drag' : 'thumb'} />
          );
        }}
      />
      {props.showLimits && (
        <div className={'limits'}>
          <span>{tHtml(`${props.translation}`, { value: props.values[0] })}</span>
          <span>{tHtml(`${props.translation}`, { value: props.values[1] })}</span>
        </div>
      )}
    </div>
  );
};

SliderWithRange.defaultProps = defaultProps;
export default SliderWithRange;
