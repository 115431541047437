import React, { useState } from 'react';
import { Collapse, Container, Navbar, NavbarToggler } from 'reactstrap';

import ResponsiveLogo from 'components/Logo/ResponsiveLogo';
import ThemeSwitcher from 'components/ThemeSwitcher/ThemeSwitcher';
import LocaleSwitcher from 'components/LocaleSwitcher';
import NotificationDropdown from 'components/NotificationDropDown/NotificationDropdown';
import WalletButton from 'scopes/shared/WalletButton';
import { StyledDesktopHeader } from './Header.style';
import AuthenticatedAccountDropdown from 'scopes/shared/components/AuthenticatedAccountDropdown';
import useAppState from 'modules/appState';
import useDisplay from 'helpers/useDisplay';

interface Props {
  toggleMenuCallback: () => unknown;
}

const Header: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { isApp } = useDisplay();

  const { isImpersonated } = useAppState();

  return (
    <React.Fragment>
      <StyledDesktopHeader top={isImpersonated ? '30px' : '0'}>
        <div className={'header-line'} />
        <Navbar expand={true}>
          <Container fluid>
            <ResponsiveLogo />
            <NavbarToggler onClick={toggle} />
            <Collapse navbar isOpen={isOpen} className={'justify-content-end'}>
              <div className={'item item-no-margin'}>
                <WalletButton />
              </div>
              <div className={'item mx-4'}>
                <NotificationDropdown />
              </div>
              <div className={'item'}>
                <AuthenticatedAccountDropdown />
              </div>
              <div className={'item mx-2'}>
                <LocaleSwitcher />
              </div>
              {!isApp && (
                <div className={'item mx-2'}>
                  <ThemeSwitcher />
                </div>
              )}
            </Collapse>
          </Container>
        </Navbar>
      </StyledDesktopHeader>
    </React.Fragment>
  );
};

export default Header;
