import React from 'react';
import { useSwipeable } from 'react-swipeable';

export interface SwipeModalComponent {
  children: JSX.Element;
  onClose: () => void;
}

export const SwipeModalComponent: React.FC<SwipeModalComponent> = ({ children, onClose }) => {
  const [animation, setAnimation] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setAnimation(true), 0);
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const handlers = useSwipeable({
    onSwipedDown: () => {
      setAnimation(false);
      setTimeout(() => onClose(), 400);
    },
    trackTouch: true,
  });

  return (
    <div className={`mobile-modal ${animation ? 'open' : ''}`} {...handlers}>
      <div
        className="mobile-modal-overlay"
        onClick={() => {
          setAnimation(false);
          setTimeout(() => onClose(), 400);
        }}
      ></div>
      <div className="mobile-modal-content">
        <div className="line" />
        {children}
      </div>
    </div>
  );
};
