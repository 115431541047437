import React from 'react';
import { Row } from 'react-table';
import { IconArrowDown, IconArrowUp } from '../Icons';

interface Props {
  row: Row<any>;
}

const Expander = ({ row }: Props) => {
  if (row.canExpand) {
    return (
      <span
        {...row.getToggleRowExpandedProps({
          style: {
            paddingLeft: `${row.depth * 2}rem`,
          },
        })}
        className="text-highlight"
      >
        {row.isExpanded ? (
          <IconArrowUp classes={'icon-dropdown'} />
        ) : (
          <IconArrowDown classes={'icon-dropdown'} />
        )}
      </span>
    );
  }

  return <></>;
};

export default Expander;
