import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { RouteList } from 'routes';
import usePermissions from 'helpers/usePermissions';
import { Permission } from 'helpers/auth/permissions';

const AuthRoute: React.FC<Props> = ({
  component: Component,
  layout: Layout,
  permissions,
  location,
  redirect,
  ...rest
}) => {
  const remotePermissions = usePermissions();

  const isException = () => {
    return (
      remotePermissions.hasAny(Permission.REGISTERED) &&
      !remotePermissions.hasAny(Permission.EMAIL_VERIFIED) &&
      //This check is used, if user is logged in with unverfied account and tries to impersonate
      //another unverified account. Because we're forcing unverified accounts to verify account
      // /impersonate route is skipped
      !location?.pathname.startsWith('/impersonate')
    );
  };

  const isLocationSameAsPath = (currentLocation: any, targetPathname: string) => {
    return currentLocation !== undefined && currentLocation.pathname.startsWith(targetPathname);
  };

  const getRedirectionToPath = (currentLocation: any, targetPathname: string) => {
    return (
      <Redirect
        to={{
          pathname: targetPathname,
          state: { from: currentLocation },
        }}
      />
    );
  };

  const hasAllPermissions = () => {
    return remotePermissions.hasAll(permissions);
  };

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (isException()) {
          if (
            !isLocationSameAsPath(
              location,
              RouteList.GUEST.AUTHENTICATION.REGISTER_MAIL_VERIFICATION,
            )
          ) {
            return getRedirectionToPath(
              location,
              RouteList.GUEST.AUTHENTICATION.REGISTER_MAIL_VERIFICATION,
            );
          }
        }

        if (!hasAllPermissions()) {
          const pathname = redirect !== undefined ? redirect : RouteList.HOME;
          return getRedirectionToPath(location, pathname);
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

interface Props extends RouteProps {
  component: React.FC<any> | React.ComponentClass<any>;
  layout: React.FC<any> | React.ComponentClass<any>;
  permissions: Array<string>;
  redirect?: string;
}

export default AuthRoute;
