import React from 'react';

import DownloadButtonAuthorized from 'components/DownloadButtonAuthorized';

interface Props {
  url: string;
  name: string;
  type: string;
}

const DownloadFileIcon: React.FC = () => (
  <div className={'download-icon'}>
    <i className="icon icon-download-file" />
  </div>
);

const DownloadButton: React.FC<Props> = (props) => {
  return (
    <div className={'download-item'}>
      <h2>{props.name}</h2>
      <DownloadButtonAuthorized url={props.url} className={'btn btn-highlight download-button'}>
        <div className={'download-item-content'}>
          <div className={'download-icon-wrapper'}>
            <DownloadFileIcon />
          </div>
          <span>{props.type.toUpperCase()}</span>
        </div>
      </DownloadButtonAuthorized>
    </div>
  );
};

export default DownloadButton;
