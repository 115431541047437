import React from 'react';
import {
  StyledProjectInformationHeader,
  StyledProjectInformationHeaderIdBadge,
  StyledProjectInformationHeaderTitle,
} from './ProjectInformationHeader.style';

type Props = {
  projectName: string;
  projectId: string;
  className?: string;
};

const ProjectInformationHeader: React.FC<Props> = ({ projectName, projectId, className = '' }) => {
  return (
    <StyledProjectInformationHeader className={className}>
      <StyledProjectInformationHeaderTitle>{projectName}</StyledProjectInformationHeaderTitle>
      <StyledProjectInformationHeaderIdBadge>{projectId}</StyledProjectInformationHeaderIdBadge>
    </StyledProjectInformationHeader>
  );
};

export default ProjectInformationHeader;
