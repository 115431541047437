import { RouteList } from 'routes';
import { Step } from 'containers/StepFormLayout/Step';

export const createCompanySteps: Array<Step> = [
  {
    step: 1,
    name: 'placeholder.company.add',
    route: RouteList.USER.CREATE_COMPANY.COMPANY,
  },
  {
    step: 2,
    name: 'placeholder.company.get_information',
    route: RouteList.USER.CREATE_COMPANY.KNOW_YOUR_BUSINESS,
  },
];
