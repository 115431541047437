import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { RouteList } from 'routes';
import CheckboxBlock from 'components/CheckboxBlock';
import Flag from 'components/Flag';
import CompanyProfileSection from 'scopes/company/components/CompanyProfileSection';
import { CompanyMenuEnum } from 'helpers/enums/CompanyMenuEnum';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';
import { UserMarketingSettingTypeEnum } from 'helpers/enums/UserMarketingSettingTypeEnum';
import {
  CALL_MARKETING_SETTINGS,
  EMAIL_MARKETING_SETTINGS,
  SMS_MARKETING_SETTINGS,
} from 'helpers/constants/UserMarketingSettingTypes';
import useUserType from 'helpers/useUserType';

const MarketingSettings: React.FC = () => {
  const { tHtml, currentLanguage } = useTranslation();
  const userType = useUserType();
  const { data: companyProfileData } = useUserCompanyProfileQuery();

  return (
    <CompanyProfileSection
      name={CompanyMenuEnum.MarketingSettings}
      title={tHtml('menu.marketing_settings')}
      buttonLink={RouteList.COMPANY.EDIT.MARKETING_SETTINGS}
    >
      {companyProfileData?.marketing_settings && (
        <>
          {userType.isInvestor() &&
            companyProfileData?.marketing_settings?.filter((setting) =>
              EMAIL_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
            ).length > 0 && (
              <div className="marketing-settings mb-4">
                <div className={'profile-marketing-settings--header'}>
                  {tHtml('profile.notifications.email')}
                </div>
                <div className={'profile-marketing-settings--body'}>
                  {companyProfileData?.marketing_settings
                    ?.filter((setting) =>
                      EMAIL_MARKETING_SETTINGS.includes(
                        setting.name as UserMarketingSettingTypeEnum,
                      ),
                    )
                    .map((setting, index) => {
                      return (
                        <div key={`email-marketing-settings-${index}`}>
                          <CheckboxBlock
                            label={tHtml(setting.name ?? '--')}
                            checked={setting.via_email}
                            readonly={true}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          {companyProfileData?.marketing_settings?.filter((setting) =>
            CALL_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
          ).length > 0 && (
            <div className="marketing-settings mb-4">
              <div className={'profile-marketing-settings--header'}>
                {tHtml('profile.notifications.call')}
              </div>
              <div className={'profile-marketing-settings--body'}>
                {companyProfileData?.marketing_settings
                  ?.filter((setting) =>
                    CALL_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
                  )
                  .map((setting, index) => {
                    return (
                      <div key={`call-marketing-settings-${index}`}>
                        <CheckboxBlock
                          label={tHtml(setting.name ?? '--')}
                          checked={setting.via_phone}
                          readonly={true}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {companyProfileData?.marketing_settings?.filter((setting) =>
            SMS_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
          ).length > 0 && (
            <div className="marketing-settings mb-4">
              <div className={'profile-marketing-settings--header'}>
                {tHtml('profile.notifications.sms')}
              </div>
              <div className={'profile-marketing-settings--body'}>
                {companyProfileData?.marketing_settings
                  ?.filter((setting) =>
                    SMS_MARKETING_SETTINGS.includes(setting.name as UserMarketingSettingTypeEnum),
                  )
                  .map((setting, index) => {
                    return (
                      <div key={`sms-marketing-settings-${index}`}>
                        <CheckboxBlock
                          label={tHtml(setting.name ?? '--')}
                          checked={setting.via_sms}
                          readonly={true}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </>
      )}
      <hr />
      <div className={'mb-4'}>
        <span className={'text me-2'}>
          <strong>{tHtml('profile.notifications.receive_information_language')}</strong>
        </span>
        <Flag
          countryCode={companyProfileData?.company.channel_notification_language ?? currentLanguage}
        />
      </div>
    </CompanyProfileSection>
  );
};

export default withRouter(MarketingSettings);
