import * as Yup from 'yup';

export default (): any => {
  return Yup.object().shape({
    name: Yup.string().fullName().required(),
    shares: Yup.number().min(0).required(),
    legal_code: Yup.string().companyLegalCode().required(),
    traded_securities_on_eu_exchanges: Yup.boolean().required(),
    country: Yup.string().required(),
  });
};
