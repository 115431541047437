import styled from 'styled-components';

export const StyledProjectInformationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledProjectInformationHeaderTitle = styled.h2`
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--profitus-h1-color);
`;

export const StyledProjectInformationHeaderIdBadge = styled.h2`
  font-weight: 700;
  text-align: center;
  background-color: var(--profitus-project-id-badge-color);
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
`;
