import React, { useState } from 'react';

import SelectBox, { SelectBoxProps } from 'components/Formik/SelectBox';
import { SelectOption } from 'components/Formik/SelectInput';
import useTranslation from 'helpers/useTranslation';
import { InvestmentPurposeEnum } from 'helpers/enums/InvestmentPurposeEnum';

const MultiSelectInvestmentPurpose: React.FC<Omit<SelectBoxProps, 'selectOptions'>> = (props) => {
  const { t } = useTranslation();

  const [optionsRatings] = useState<SelectOption[]>(() =>
    Object.values(InvestmentPurposeEnum).map((value) => {
      return {
        label: t('investments.auto_investment.investment_purpose.' + value),
        value: value,
      };
    }),
  );

  return (
    <>
      <SelectBox selectOptions={optionsRatings} closeMenuOnSelect={false} {...props} />
    </>
  );
};

export default MultiSelectInvestmentPurpose;
