import React, { useState } from 'react';
import { ErrorMessage, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import useTranslation from 'helpers/useTranslation';
import { animateScroll as scroll } from 'react-scroll';
import { QuizCheckboxInput, PrimaryButton } from 'components/Formik';
import FileUpload, { FileUploadHandle } from 'components/FileUpload/FileUpload';
import Api from 'api';

export interface Props {
  children: React.ReactNode;
  initialValues: FormikValues;
  onSubmit: any;
  onStepSubmit: any;
  validationSchema: any;
  isLastStep?: boolean;
  documentIsRequired?: boolean;
  setIsForward: any;
}

const QuizStepWizard: React.FC<Props> = ({
  children,
  initialValues,
  onSubmit,
  onStepSubmit,
  validationSchema,
  isLastStep,
  documentIsRequired,
  setIsForward,
}: Props) => {
  const { tHtml } = useTranslation();
  const [stepNumber, setStepNumber] = useState(0);
  const [isStepForward, setIsStepForward] = useState<boolean>(true);
  const steps = React.Children.toArray(children);
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const step = steps[stepNumber];
  const totalSteps = steps.length;

  const next = () => {
    setStepNumber(Math.min(stepNumber + 1, totalSteps));
  };

  const prev = () => {
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values: any, bag: FormikHelpers<any>) => {
    if (isLastStep && isStepForward) {
      return onSubmit(values, bag);
    } else {
      await onStepSubmit(values, bag, isStepForward);
      bag.setTouched({});
      isStepForward ? next() : prev();
      scroll.scrollTo('.step-form-layout');
    }
  };

  return (
    <>
      <Formik
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            {step}
            {isLastStep && documentIsRequired ? (
              <div>
                <div className={'fw-bold mb-3'}>{tHtml('quiz.documents')}</div>
                <div className="mb-3">
                  <FileUpload
                    ref={fileUploadRef}
                    name={'documents'}
                    onPresign={(request) => Api.investor.quiz.uploadInvestorQuizDocuments(request)}
                  />
                  {formik.errors.documents && (
                    <div
                      className={'invalid-feedback-visible ms-2'}
                      style={{ fontSize: '0.688rem' }}
                    >
                      <ErrorMessage name={'documents'} />
                    </div>
                  )}
                  <div className={'mb-5 mt-5'}>
                    <p className={'text-info fw-bold'}>
                      {tHtml('quiz.experienced_investor_disclaimer')}
                    </p>
                    <hr />
                    <QuizCheckboxInput
                      name={'quiz_agreement'}
                      showPlaceholder={true}
                      translationPrefix={'quiz.'}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {isLastStep && (
                  <div className={'mb-5'}>
                    <p className={'text-info fw-bold'}>{tHtml('quiz.quiz_disclaimer')}</p>
                    <hr />
                    <QuizCheckboxInput
                      name={'quiz_agreement'}
                      showPlaceholder={true}
                      translationPrefix={'quiz.'}
                    />
                  </div>
                )}
              </>
            )}
            <div className={'pb-5 d-flex justify-content-between'}>
              <PrimaryButton
                className={'btn btn-primary w-25'}
                type={'submit'}
                onClick={() => {
                  setIsStepForward(false);
                  setIsForward(false);
                }}
                submitting={formik.isSubmitting}
              >
                {tHtml('common.back')}
              </PrimaryButton>
              <PrimaryButton
                className={'btn btn-primary w-25'}
                type={'submit'}
                onClick={() => {
                  setIsStepForward(true);
                  setIsForward(true);
                }}
                submitting={formik.isSubmitting}
              >
                {isLastStep ? tHtml('common.submit') : tHtml('common.next')}
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default QuizStepWizard;
