import * as Yup from 'yup';
import { ApplicationTypeEnum } from 'scopes/developer/helpers/enums/ApplicationTypeEnum';
import { ApplicationRealEstateType } from 'scopes/guest/helpers/enums/ApplicationRealEstateType';

export function createDynamicValidationSchema(creditPurpose: ApplicationTypeEnum[]) {
  const schemaFields: Record<string, any> = {};

  if (creditPurpose.includes(ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT)) {
    const realEstateTypeOptions = Object.values(ApplicationRealEstateType);

    const assetSchema = Yup.object().shape({
      real_estate_type: Yup.string()
        .required()
        .oneOf(realEstateTypeOptions, 'Invalid real estate type'),
      real_estate_count: Yup.number().required().min(0).integer(),
      real_estate_plot: Yup.number().min(0).required(),
      real_estate_value: Yup.number().money().min(0).required(),
    });

    schemaFields.real_estate_development = Yup.object({
      project_name: Yup.string().min(1).max(255).nullable(),
      project_address: Yup.string().min(1).max(255).required(),
      start_construction_at: Yup.date().required(),
      end_construction_at: Yup.date()
        .required()
        .min(Yup.ref('start_construction_at'), "End date can't be before start date"),
      total_area: Yup.number().min(0).nullable(),
      project_plot_price: Yup.number().money().min(0).required(),
      project_engineering_price: Yup.number().money().min(0).required(),
      project_estimate: Yup.number().money().min(0).required(),
      existing_investment_total: Yup.number().money().min(0).required(),
      existing_investment_own_funds: Yup.number().money().min(0).required(),
      existing_investment_lend_funds: Yup.number().money().min(0).required(),
      project_documentation_price: Yup.number().money().min(0).required(),
      project_construction_price: Yup.number().money().min(0).required(),
      existing_investment_plot_price: Yup.number().money().min(0).required(),
      existing_investment_engineering_price: Yup.number().money().min(0).required(),
      existing_investment_documentation_price: Yup.number().money().min(0).required(),
      existing_investment_construction_price: Yup.number().money().min(0).required(),
      presale_contracts: Yup.string().max(255).required(),
      presale_contracts_amount: Yup.number().nullable(),
      building_permit: Yup.boolean().required(),
      assets_to_be_sold: Yup.array().of(assetSchema),
    });
  }
  if (creditPurpose.includes(ApplicationTypeEnum.REFINANCING)) {
    schemaFields.refinancing = Yup.object().shape({
      debt_amount: Yup.number().money().min(0).required(),
      debt_due_date: Yup.date().required(),
      debt_purpose: Yup.string().maxLengthLongText().required(),
      debt_security_deposit: Yup.string().maxLength().required(),
      debt_security_deposit_value: Yup.number().money().min(0).required(),
      debt_refinancing_reason: Yup.string().maxLengthLongText().required(),
    });
  }
  if (creditPurpose.includes(ApplicationTypeEnum.WORKING_CAPITAL)) {
    schemaFields.working_capital = Yup.object({
      loan_details_working_capitals: Yup.string().maxLengthLongText().required(),
    });
  }
  if (creditPurpose.includes(ApplicationTypeEnum.OTHER)) {
    schemaFields.other = Yup.object({
      loan_details_others: Yup.string().maxLengthLongText().required(),
    });
  }
  if (creditPurpose.includes(ApplicationTypeEnum.REAL_ESTATE_ACQUISITION)) {
    schemaFields.real_estate_acquisition = Yup.object({
      re_price_without_vat: Yup.number().money().min(0).required(),
      re_price_vat: Yup.number().min(0).required(),
      own_funds_amount: Yup.number().min(0).required(),
      own_funds_origin: Yup.string().maxLength().required(),
      re_seller_name: Yup.string().maxLength().required(),
      re_address: Yup.string().addressLine().required(),
      re_purpose: Yup.string().maxLength().required(),
      re_value: Yup.number().money().min(0).required(),
      re_evaluated_by: Yup.string().max(255).required(),
    });
  }
  return Yup.object().shape(schemaFields);
}
