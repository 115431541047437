import React from 'react';
import {
  ErrorMessage,
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext,
} from 'formik';
import useTranslation from 'helpers/useTranslation';

import { FunctionalButton } from 'components/Button';
import { StoreProspectUserCompanyBeneficiaryKYBRequestDto } from 'api/types/shared/user/company';
import SingleBeneficiaryForm from './SingleBeneficiaryForm';

interface Props {
  dependencyFieldName: string;
  selectorDisabledFieldName: string;
  values: any;
  fieldName: string;
  formikHelpers: any;
}

const BeneficiariesFormBlock: React.FC<Props> = ({
  dependencyFieldName,
  selectorDisabledFieldName,
  values,
  fieldName,
  formikHelpers,
}) => {
  const { tHtml } = useTranslation();
  const [, meta] = useField(fieldName);
  const formik = useFormikContext();
  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;

  const emptyBeneficiary: StoreProspectUserCompanyBeneficiaryKYBRequestDto = {
    full_name: '',
    shares_direct: 0,
    shares_not_direct: 0,
    gov_code: '',
    is_public_official: false,
    nationality: '',
    tax_residency_country: '',
    shareholder_id: null,
  };

  return (
    <div className={meta.error ? 'is-invalid' : ''}>
      {isError && (
        <div className={'invalid-feedback-visible ms-2'}>
          {typeof meta.error === 'string' && <ErrorMessage name={fieldName} />}
        </div>
      )}
      <FieldArray
        name={fieldName}
        render={(arrayHelpers: FieldArrayRenderProps) => {
          const addShareholder = () => {
            arrayHelpers.push({ ...emptyBeneficiary });
          };

          return (
            <>
              <>
                {values.map(
                  (
                    beneficiary: StoreProspectUserCompanyBeneficiaryKYBRequestDto,
                    index: number,
                  ) => (
                    <SingleBeneficiaryForm
                      beneficiary={beneficiary}
                      key={index}
                      index={index}
                      selectorDisabledFieldName={selectorDisabledFieldName}
                      dependencyFieldName={dependencyFieldName}
                      fieldName={fieldName}
                      formikHelpers={formikHelpers}
                      formikArrayHelpers={arrayHelpers}
                    />
                  ),
                )}
              </>
              <div className={'mt-4 mb-4'}>
                <FunctionalButton
                  type={'button'}
                  onClick={async () => {
                    addShareholder();
                  }}
                >
                  {tHtml('common.add_with_sign_short')}
                </FunctionalButton>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};

export default BeneficiariesFormBlock;
