import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import { Trans } from 'react-i18next';
import useTranslation from 'helpers/useTranslation';
import { useParams } from 'react-router-dom';

import Api from 'api';
import {
  ApplicationSingleSecurityDepositDto,
  ProjectDeveloperApplicationProjectProposal,
} from 'api/types/project-developer/application';
import { navigate } from 'helpers';
import StepFormHeader from 'containers/StepFormLayout/StepFormHeader';
import DownloadLink from 'components/DownloadLink';
import { RouteList, LANDING_ROUTES } from 'routes';
import { success } from 'services/toastr';
import ProposalTable from './ProposalTable';
import { setGlobalLoading } from 'modules/app/actions';
import { ProjectProposalSkeleton } from './index';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';

const ProjectProposal: React.FC = () => {
  const { t, tHtml, currentLanguage } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();
  const [projectProposal, setProjectProposal] =
    useState<ProjectDeveloperApplicationProjectProposal>();

  const { data: companyProfileData } = useUserCompanyProfileQuery();

  useEffect(() => {
    if (applicationId) {
      Api.projectDeveloper.applications
        .fetchApplicationProjectProposal(applicationId)
        .then((response: ProjectDeveloperApplicationProjectProposal) => {
          setGlobalLoading(false);
          setProjectProposal(response);
        });
    }
  }, [applicationId]);

  const rejectApplicationProjectProposal = () => {
    Api.projectDeveloper.applications.rejectApplicationProjectProposal(applicationId).then(() => {
      success(tHtml('application.proposal.rejected'));
      navigate(RouteList.DASHBOARD);
    });
  };

  const acceptApplicationProjectProposal = () => {
    Api.projectDeveloper.applications.acceptApplicationProjectProposal(applicationId).then(() => {
      success(tHtml('application.proposal.offer_accepted'));
      navigate(RouteList.DASHBOARD);
    });
  };

  if (!projectProposal) return <ProjectProposalSkeleton />;

  return (
    <>
      <StepFormHeader to={RouteList.DASHBOARD} text={'common.back'} />
      <Container className={'px-2 px-sm-4 px-md-6 my-5'}>
        <Container className={'d-block developer-proposal'}>
          <Row className={'mb-4'}>
            <Col sm={12} lg={9}>
              <h2 className={'mb-4 mb-lg-3'}>{companyProfileData?.company.name}</h2>

              <div className={'mb-4 mb-lg-0 d-block d-lg-flex'}>
                <span className={'d-block text-lead me-lg-6'}>
                  <strong>
                    {tHtml('projects.proposal_information.object')}: {projectProposal.project_name}
                  </strong>
                </span>
                <span className={'d-block text-profitus-blue text-lead'}>
                  {projectProposal.offered_at &&
                    t('common.date', { date: projectProposal.offered_at })}{' '}
                  {tHtml('projects.proposal_information.offer_valid_term')}
                </span>
              </div>
            </Col>
            {projectProposal.document && (
              <Col sm={12} lg={3}>
                <DownloadLink
                  url={projectProposal.document.url}
                  type={projectProposal.document.mime_type}
                  name={t('projects.proposal_information.offer_document')}
                  showMeta={false}
                />
              </Col>
            )}
          </Row>
          <ProposalTable {...projectProposal} />
          <Row className={'buttons'}>
            <button className={'btn btn-option'} onClick={rejectApplicationProjectProposal}>
              {tHtml('common.decline')}
            </button>
            <button className={'btn btn-primary'} onClick={acceptApplicationProjectProposal}>
              {tHtml('projects.proposal_information.get_funding')}
            </button>
          </Row>
          <hr className={'my-4'} />
          <Row className={'terms'}>
            <h3 className={'mb-4'}>{tHtml('projects.proposal_information.funding_conditions')}</h3>
            <div className={'term'}>
              <h4>1. {tHtml('projects.proposal_information.fees_title')}:</h4>
              <p>{tHtml('projects.proposal_information.fees_description')}</p>
            </div>
            {projectProposal.security_deposits.length > 0 && (
              <div className={'term'}>
                <h4>2. {tHtml('projects.proposal_information.pledged_objects')}:</h4>

                <div>
                  <Table borderless={true} hover={false}>
                    <thead>
                      <tr>
                        <th>{tHtml('projects.proposal_information.property')}</th>
                        <th>{tHtml('projects.proposal_information.area')}</th>
                        <th>{tHtml('common.address')}</th>
                        <th>{tHtml('common.unique_number')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {projectProposal.security_deposits.map(
                        (security_deposit: ApplicationSingleSecurityDepositDto, idx) => (
                          <tr key={idx}>
                            <td>
                              {tHtml(
                                `placeholder.application.real_estate_unique.${security_deposit.real_estate_type}`,
                              )}
                            </td>
                            <td>{security_deposit.real_estate_plot}</td>
                            <td>{security_deposit.real_estate_address}</td>
                            <td>{security_deposit.real_estate_unique}</td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
            {/* TODO: update for new ratings */}
            <div className={'term'}>
              <h4>3. {tHtml('projects.proposal_information.risk_levels')}:</h4>
              <div>
                <Table borderless={true} hover={false}>
                  <thead>
                    <tr>
                      <th>{tHtml('common.level')}</th>
                      <th>{tHtml('projects.proposal_information.risk_description')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{tHtml('projects.proposal_information.levels.low')}</td>
                      <td>{tHtml('projects.proposal_information.levels.low.description')}</td>
                    </tr>
                    <tr>
                      <td>{tHtml('projects.proposal_information.levels.average')}</td>
                      <td>{tHtml('projects.proposal_information.levels.average.description')}</td>
                    </tr>
                    <tr>
                      <td>{tHtml('projects.proposal_information.levels.high')}</td>
                      <td>{tHtml('projects.proposal_information.levels.high.description')}</td>
                    </tr>
                    <tr>
                      <td>{tHtml('projects.proposal_information.levels.highest')}</td>
                      <td>{tHtml('projects.proposal_information.levels.highest.description')}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className={'term'}>
              <h4>4. {t('projects.proposal_information.early_return_title')}:</h4>
              <p>
                <Trans
                  components={[
                    <a
                      key={0}
                      href={LANDING_ROUTES[currentLanguage].FEES}
                      className={'link-secondary'}
                      target={'_blank'}
                      rel="noreferrer"
                    />,
                  ]}
                >
                  {t('projects.proposal_information.early_return_description')}
                </Trans>
              </p>
            </div>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default ProjectProposal;
