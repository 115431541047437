import styled from 'styled-components';

export const ColumnCodeStyle = styled.span`
  word-break: break-all;
  max-width: 250px;
  display: inline-block;
`;

export const ColumnProjectsStyle = styled.span`
  word-break: break-word;
  max-width: 250px;
  display: inline-block;
`;
