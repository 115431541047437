import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { useHistory } from 'react-router-dom';

import { PrimaryButton } from 'components/Button';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import InvestmentModal from '../InvestmentModal/InvestmentModal';
import InvestorProjectGuard from 'helpers/guards/investor';
import { ProjectContextState, withProject } from 'components/Project/Scope/ProjectProvider';
import { InvestorProjectResponse } from 'api/types/investor/project';
import { UseNumbers } from 'helpers/useNumbers';
import Guard, { ActionGuard } from 'helpers/guards';
import { warning } from 'services/toastr';
import { RouteList } from 'routes';
import useOnboarding from 'helpers/useOnboarding';
import useScope from 'helpers/useScope';
import { useWalletQuery } from 'api/queries/useWalletQuery';
import { isWalletProviderObsolete } from '../../../../../../helpers/enums/wallet/WalletProviderEnum';

interface Props extends ProjectContextState {
  minAmount: number;
  maxAmount: number;
  initialAmount?: number;
  onChange?: (value: number) => void;
}

const InlineCalculator: React.FC<Props> = ({ project, setProject, ...props }) => {
  const { tHtml } = useTranslation();
  const [investment, setInvestment] = useState<string>('0');
  const { showModal } = useGlobalModalContext();
  const history = useHistory();

  const { isWalletVerified, isAccredited } = useOnboarding();
  const { isUser } = useScope();

  const { data: wallet } = useWalletQuery();

  const p: InvestorProjectResponse = project as InvestorProjectResponse;

  const investmentGuards: ActionGuard[] = [
    {
      rejectIf: isWalletProviderObsolete(wallet?.provider),
      onReject: () => warning(tHtml('projects.investment_modal.guards.wrong_provider')),
    },
    {
      rejectIf: !isAccredited,
      onReject: () => {
        if (isUser()) {
          history.push(RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION);
          warning(tHtml('projects.investment_modal.guards.not_accredited.user'));
        } else {
          warning(tHtml('projects.investment_modal.guards.not_accredited.company'));
        }
      },
    },
    {
      rejectIf: !isWalletVerified,
      onReject: () => warning(tHtml('projects.investment_modal.guards.wallet_not_verified')),
    },
    {
      rejectIf: (wallet?.balance ?? 0) < Number(investment),
      onReject: () => warning(tHtml('projects.investment_modal.guards.insufficient_funds')),
    },
  ];

  useEffect(() => {
    if (props.onChange != undefined) {
      props.onChange(Number(investment));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment]);

  useEffect(() => {
    if (props.initialAmount == undefined) return;
    const newValue = String(props.initialAmount.toFixed(2));
    if (newValue != investment) {
      setInvestment(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialAmount]);

  if (!p) return <></>;

  if (props.minAmount > props.maxAmount) return <></>;

  return (
    <div className={'calculator calculator-inline'}>
      <div className={'calculator-invest'}>
        <input
          type="number"
          className="form-control"
          id="two"
          min={props.minAmount}
          max={props.maxAmount}
          value={investment}
          onChange={(e) => setInvestment(String(e.target.value))}
          onKeyPress={UseNumbers.preventNonNumericalInput}
        />
      </div>
      {InvestorProjectGuard.canInvest(p) && (
        <div className={'calculation-button'}>
          <PrimaryButton
            className={'form btn btn-primary w-100'}
            onClick={() => {
              if (Guard.isValid(investmentGuards)) {
                showModal(
                  <InvestmentModal
                    amount={Number(investment)}
                    onAmountChange={setInvestment}
                    project={p}
                    setProject={setProject}
                  />,
                );
              }
            }}
          >
            {tHtml('projects.invest')}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default withProject(InlineCalculator);
