import { useEffect, useState } from 'react';
import useScope from './useScope';
import usePermissions from './usePermissions';
import { Permission } from './auth/permissions';

type OnBoardingStatus = {
  isCompleted: boolean;
  isAccredited: boolean | undefined;
  isWalletVerified: boolean | undefined;
  isEmailVerified: boolean | undefined;

  // User specific start
  isContactDetailsFilled: boolean | undefined;
  isKnowYourCustomerFilled: boolean | undefined;
  // User specific end

  // Company specific start
  isKnowYourBusinessFilled: boolean | undefined;
  // Company specific end

  // Investor specific start
  isInvestorQuizFilled: boolean | undefined;
  // Investor specific end

  currentStep: number | undefined;
};

const DEFAULT_STATE: OnBoardingStatus = {
  currentStep: undefined,
  isAccredited: undefined,
  isContactDetailsFilled: undefined,
  isKnowYourCustomerFilled: undefined,
  isEmailVerified: undefined,
  isWalletVerified: undefined,
  isKnowYourBusinessFilled: undefined,
  isInvestorQuizFilled: undefined,
  isCompleted: true,
};

const useOnboarding = () => {
  const { isUser, isCompany } = useScope();
  const { can } = usePermissions();

  const [state, setState] = useState<OnBoardingStatus>(DEFAULT_STATE);

  useEffect(() => {
    let newState = DEFAULT_STATE;
    let currentStep = undefined;

    if (isUser()) {
      currentStep = 1;

      if (can(Permission.EMAIL_VERIFIED)) {
        currentStep = 2;

        if (can(Permission.CONTACT_DATA_FILLED)) {
          currentStep = 3;

          if (can(Permission.ACCREDITATION_VERIFIED)) {
            currentStep = 4;
          }
        }
      }

      newState = {
        ...newState,
        isWalletVerified: can(Permission.WALLET_VERIFIED),
        isAccredited: can(Permission.ACCREDITATION_VERIFIED),
        isContactDetailsFilled: can(Permission.CONTACT_DATA_FILLED),
        isEmailVerified: can(Permission.EMAIL_VERIFIED),
        isKnowYourCustomerFilled: can(Permission.USER_KYC_FILLED),
        isInvestorQuizFilled: can(Permission.INVESTOR_QUIZ_FILLED),
        isCompleted:
          can(Permission.ACCREDITATION_VERIFIED) &&
          can(Permission.CONTACT_DATA_FILLED) &&
          can(Permission.EMAIL_VERIFIED),
        currentStep: currentStep,
      };
    } else if (isCompany()) {
      currentStep = 1;

      if (can(Permission.COMPANY_KYB_FILLED)) {
        currentStep = 2;

        if (can(Permission.ACCREDITATION_VERIFIED)) {
          currentStep = 3;
        }
      }

      newState = {
        ...newState,
        isWalletVerified: can(Permission.WALLET_VERIFIED),
        isAccredited: can(Permission.ACCREDITATION_VERIFIED),
        isContactDetailsFilled: can(Permission.CONTACT_DATA_FILLED),
        isKnowYourBusinessFilled: can(Permission.COMPANY_KYB_FILLED),
        isEmailVerified: can(Permission.EMAIL_VERIFIED),
        isInvestorQuizFilled: can(Permission.INVESTOR_QUIZ_FILLED),
        isCompleted:
          can(Permission.EMAIL_VERIFIED) &&
          can(Permission.COMPANY_KYB_FILLED) &&
          can(Permission.ACCREDITATION_VERIFIED),
        currentStep: currentStep,
      };
    }

    setState(newState);
  }, [can, isUser, isCompany]);

  return state;
};

export default useOnboarding;
