import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { FieldArray, useField } from 'formik';
import TextInput from 'components/Formik/TextInput';
import { DatepickerInput } from 'components/Formik/Inputs';
import YesNoSelector from 'components/Formik/YesNoSelector';
import { ApplicationRealEstateType } from 'scopes/guest/helpers/enums/ApplicationRealEstateType';
import { AssetsToBeSoldI } from 'api/types/guest/prospectApplication';
import { CellWrapper } from './CreditDetails.style';

export const RealEstateDevelopmentFormSection = () => {
  const { t, tHtml } = useTranslation();
  const [field, , helper] = useField('real_estate_development.assets_to_be_sold');
  const [assets, setAssets] = React.useState<AssetsToBeSoldI[]>(field.value);

  React.useEffect(() => {
    if (assets && assets.length === 0) {
      helper.setValue(
        Object.values(ApplicationRealEstateType).map((asset) => {
          return {
            real_estate_type: asset,
            real_estate_count: null,
            real_estate_plot: null,
            real_estate_value: null,
          };
        }),
        true,
      );
      setAssets(
        Object.values(ApplicationRealEstateType).map((asset) => {
          return {
            real_estate_type: asset,
            real_estate_count: null,
            real_estate_plot: null,
            real_estate_value: null,
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h2 className="mt-5 mb-4">{tHtml('prospect_application.real_estate_development_details')}</h2>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.project_name"
          placeholder={t('placeholder.prospect_application.real_estate_development.project_name')}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.project_address"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.project_address',
          )}
        />
      </div>
      <div className="mb-4">
        <DatepickerInput
          name={'real_estate_development.start_construction_at'}
          label={t(
            'placeholder.prospect_application.real_estate_development.start_construction_at',
          )}
          hasFutureRange={true}
        />
      </div>
      <div className="mb-4">
        <DatepickerInput
          name={'real_estate_development.end_construction_at'}
          label={t('placeholder.prospect_application.real_estate_development.end_construction_at')}
          hasFutureRange={true}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.total_area"
          type="number"
          placeholder={t('placeholder.prospect_application.real_estate_development.total_area')}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.project_estimate"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.project_estimate',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.project_plot_price"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.project_plot_price',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.project_engineering_price"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.project_engineering_price',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.project_documentation_price"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.project_documentation_price',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.project_construction_price"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.project_construction_price',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.existing_investment_total"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.existing_investment_total',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.existing_investment_plot_price"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.existing_investment_plot_price',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.existing_investment_engineering_price"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.existing_investment_engineering_price',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.existing_investment_documentation_price"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.existing_investment_documentation_price',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.existing_investment_construction_price"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.existing_investment_construction_price',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.existing_investment_own_funds"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.existing_investment_own_funds',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.existing_investment_lend_funds"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.existing_investment_lend_funds',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="real_estate_development.presale_contracts"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.presale_contracts',
          )}
        />
      </div>
      <div className="mb-3">
        <TextInput
          name="real_estate_development.presale_contracts_amount"
          type="number"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.presale_contracts_amount',
          )}
        />
      </div>
      <div className="mb-3">
        <YesNoSelector
          name="real_estate_development.building_permit"
          placeholder={t(
            'placeholder.prospect_application.real_estate_development.building_permit',
          )}
        />
      </div>
      <div className="mb-4">
        <FieldArray name="real_estate_development.assets_to_be_sold">
          {() => (
            <table>
              <thead>
                <tr>
                  <th>
                    {t(
                      'placeholder.prospect_application.real_estate_development.assets_to_be_sold.asset_name',
                    )}
                  </th>
                  <th>
                    {t(
                      'placeholder.prospect_application.real_estate_development.assets_to_be_sold.square_meters',
                    )}
                  </th>
                  <th>
                    {t(
                      'placeholder.prospect_application.real_estate_development.assets_to_be_sold.units',
                    )}
                  </th>
                  <th>
                    {t(
                      'placeholder.prospect_application.real_estate_development.assets_to_be_sold.average_planned_selling_price',
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {assets.map((asset, index) => (
                  <tr key={index}>
                    <td>
                      {t(
                        `placeholder.prospect_application.real_estate_development.assets_to_be_sold.${asset.real_estate_type}`,
                      )}
                    </td>
                    <td>
                      <CellWrapper>
                        <TextInput
                          type="number"
                          name={`real_estate_development.assets_to_be_sold[${index}].real_estate_plot`}
                          placeholder={t(
                            'placeholder.prospect_application.real_estate_development.assets_to_be_sold.real_estate_plot',
                          )}
                        />
                      </CellWrapper>
                    </td>
                    <td>
                      <CellWrapper>
                        <TextInput
                          name={`real_estate_development.assets_to_be_sold[${index}].real_estate_count`}
                          type="number"
                          placeholder={t(
                            'placeholder.prospect_application.real_estate_development.assets_to_be_sold.real_estate_count',
                          )}
                        />
                      </CellWrapper>
                    </td>
                    <td>
                      <CellWrapper>
                        <TextInput
                          name={`real_estate_development.assets_to_be_sold[${index}].real_estate_value`}
                          type="number"
                          placeholder={t(
                            'placeholder.prospect_application.real_estate_development.assets_to_be_sold.real_estate_value',
                          )}
                        />
                      </CellWrapper>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </FieldArray>
      </div>
    </>
  );
};
