import React from 'react';

import TextInput, { TextInputProps } from './TextInput';
import { UseNumbers } from '../../helpers/useNumbers';

const NumberInput: React.FC<TextInputProps> = (props) => {
  return (
    <TextInput
      type={'number'}
      onKeyPress={UseNumbers.preventNonNumericalInput}
      onWheel={(e) => e.currentTarget.blur()} /* Disabling number change on scroll */
      {...props}
    />
  );
};

export default NumberInput;
