import React from 'react';
import { RouteList } from 'routes';
import { Container } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { ApplicationContextProvider } from './module/ApplicationContext';
import { Step } from 'containers/StepFormLayout/Step';
import {
  ApplicationDevelopment,
  ApplicationDocument,
  ApplicationEstimate,
  ApplicationPurchase,
  ApplicationRefinancing,
  ApplicationSecurityDeposits,
  CreateApplication,
  ApplicationReview,
} from './index';
import StepFormHeader from 'containers/StepFormLayout/StepFormHeader';
import StepFormStepsWizard from 'containers/StepFormLayout/StepFormStepsWizard';
import updatePath from 'helpers/updatePath';
import { ApplicationTypeEnum } from 'scopes/developer/helpers/enums/ApplicationTypeEnum';

export interface ApplicationStep extends Step {
  component: React.FC<any> | React.ComponentClass<any>;
  isLastStep?: boolean;
}

export interface ApplicationPageProps {
  previousPage?: string;
  nextPage?: string;
  isLastStep?: boolean;
}

const withWizard = (
  Component: React.FC<any> | React.ComponentClass<any>,
  steps: ApplicationStep[],
  index: number,
) => {
  const Wrapper: () => JSX.Element = () => {
    const getNextPage = () => {
      const step = steps[index + 1];
      return step != undefined ? step.route : undefined;
    };

    const getPreviousPage = () => {
      const step = steps[index - 1];
      return step != undefined ? step.route : undefined;
    };

    const getIsLastStep = () => {
      const step = steps[index];
      return step != undefined ? step.isLastStep : undefined;
    };

    return (
      <ApplicationContextProvider key={'application'}>
        <StepFormHeader />
        <Container className={'main-content d-block mx-auto px-2 px-sm-4 px-md-6'}>
          <StepFormStepsWizard steps={steps} />
          <Component
            previousPage={getPreviousPage()}
            nextPage={getNextPage()}
            isLastStep={getIsLastStep()}
          />
        </Container>
      </ApplicationContextProvider>
    );
  };

  return Wrapper;
};

// TODO: Refactor index part. Try to eliminated the need of that.
const getSteps = (applicationType: ApplicationTypeEnum) => {
  let currentStep = 1;
  let currentIndex = 0;
  const steps: Array<ApplicationStep> = [];
  steps.push({
    step: currentStep++,
    name: 'application.create_application',
    route: updatePath(RouteList.PROJECT_DEVELOPER.APPLICATIONS.CREATE_APPLICATION, {
      type: applicationType,
    }),
    component: withWizard(CreateApplication, steps, currentIndex++),
  });
  switch (applicationType) {
    case ApplicationTypeEnum.REFINANCING:
      steps.push({
        step: currentStep++,
        name: 'application.refinancing',
        route: updatePath(RouteList.PROJECT_DEVELOPER.APPLICATIONS.REFINANCING, {
          type: applicationType,
        }),
        component: withWizard(ApplicationRefinancing, steps, currentIndex++),
      });
      break;
    case ApplicationTypeEnum.REAL_ESTATE_ACQUISITION:
      steps.push({
        step: currentStep++,
        name: 'application.purchase',
        route: updatePath(RouteList.PROJECT_DEVELOPER.APPLICATIONS.PURCHASE, {
          type: applicationType,
        }),
        component: withWizard(ApplicationPurchase, steps, currentIndex++),
      });
      break;
    case ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT:
      steps.push(
        {
          step: currentStep++,
          name: 'application.application_development',
          route: updatePath(RouteList.PROJECT_DEVELOPER.APPLICATIONS.DEVELOPMENT, {
            type: applicationType,
          }),
          component: withWizard(ApplicationDevelopment, steps, currentIndex++),
        },
        {
          step: currentStep++,
          name: 'application.estimate',
          route: updatePath(RouteList.PROJECT_DEVELOPER.APPLICATIONS.ESTIMATE, {
            type: applicationType,
          }),
          component: withWizard(ApplicationEstimate, steps, currentIndex++),
        },
      );
      break;
  }
  steps.push(
    {
      step: currentStep++,
      name: 'application.security_deposits',
      route: updatePath(RouteList.PROJECT_DEVELOPER.APPLICATIONS.SECURITY_DEPOSITS, {
        type: applicationType,
      }),
      component: withWizard(ApplicationSecurityDeposits, steps, currentIndex++),
    },
    {
      step: currentStep++,
      name: 'application.documents',
      route: updatePath(RouteList.PROJECT_DEVELOPER.APPLICATIONS.DOCUMENTS, {
        type: applicationType,
      }),
      component: withWizard(ApplicationDocument, steps, currentIndex++),
    },
    {
      step: currentStep++,
      name: 'application.review',
      route: updatePath(RouteList.PROJECT_DEVELOPER.APPLICATIONS.REVIEW, {
        type: applicationType,
      }),
      component: withWizard(ApplicationReview, steps, currentIndex++),
      isLastStep: true,
    },
    {
      step: currentStep++,
      name: 'application.created_successfully',
      route: updatePath(RouteList.DASHBOARD, {}),
      component: () => <Redirect to={RouteList.DASHBOARD} />,
      hideInWizard: true,
    },
  );
  return steps;
};

export const refinancingSteps = getSteps(ApplicationTypeEnum.REFINANCING);
export const workingCapitalSteps = getSteps(ApplicationTypeEnum.WORKING_CAPITAL);
export const realEstatePurchaseSteps = getSteps(ApplicationTypeEnum.REAL_ESTATE_ACQUISITION);
export const otherSteps = getSteps(ApplicationTypeEnum.OTHER);
export const realEstateDevelopmentSteps = getSteps(ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT);
