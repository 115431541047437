import React, { useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import DateFilter from 'components/Table/AdvanceFilters/DateFilter';
import PredefinedDates from 'components/Table/AdvanceFilters/Other/PredefinedDates';
import Tooltip from 'components/Tooltip';
import { dateTimeToDate } from 'helpers/utils';
import Loader from 'components/Loader';
import { useStatisticQuery } from 'api/queries/dashboard';
import Api from 'api';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import ExportTableButton from 'components/Table/components/Buttons/ExportTableButton';
import { downloadXMLFile, downloadCSVFile, downloadXLSXFile } from 'services/media';
import useDisplay from 'helpers/useDisplay';
import { SwipeModalComponent } from 'components/Modal/SwipeModalComponent';

const InterestReceived: React.FC = () => {
  const { tHtml } = useTranslation();
  const { isMobile, isApp } = useDisplay();

  const [filterValue, setFilterValue] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data, isFetching } = useStatisticQuery(
    [dateTimeToDate(startDate), dateTimeToDate(endDate)],
    () =>
      Api.investor.dashboard.fetchDashboardInterestReceived(
        dateTimeToDate(startDate),
        dateTimeToDate(endDate),
      ),
  );

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const exportCsv = () => async () => {
    const response = await Api.investor.dashboard.exportInterestReceived(
      CommonDocumentTypeEnum.CSV,
      dateTimeToDate(startDate),
      dateTimeToDate(endDate),
    );
    await downloadCSVFile(response.url, 'interest-received');
  };

  const exportXml = () => async () => {
    const response = await Api.investor.dashboard.exportInterestReceived(
      CommonDocumentTypeEnum.XML,
      dateTimeToDate(startDate),
      dateTimeToDate(endDate),
    );
    await downloadXMLFile(response.url);
  };

  const exportXlsx = () => async () => {
    const response = await Api.investor.dashboard.exportInterestReceived(
      CommonDocumentTypeEnum.XLSX,
      dateTimeToDate(startDate),
      dateTimeToDate(endDate),
    );
    await downloadXLSXFile(response.url, 'interest-received');
  };

  const renderExportButtons = () => {
    return (
      <div className={'actions'}>
        <div className={'download-button mobile-view'}>
          <ExportTableButton
            type={CommonDocumentTypeEnum.CSV}
            onClick={exportCsv}
            request={undefined}
          />
        </div>
        <div className={'download-button mobile-view'}>
          <ExportTableButton
            type={CommonDocumentTypeEnum.XML}
            onClick={exportXml}
            request={undefined}
          />
        </div>
        <div className={'download-button mobile-view'}>
          <ExportTableButton
            type={CommonDocumentTypeEnum.XLSX}
            onClick={exportXlsx}
            request={undefined}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={'page-layout wallet h-100 xs:mb-3'}>
        <div className={'panel mb-0 h-100 interest-received'}>
          <Card body className={'light-pink balance py-4 px-1 px-md-3'}>
            <Row className={'balance-items'}>
              <Col lg={12} className={'balance-information'}>
                {isFetching ? (
                  <Loader />
                ) : (
                  <div className={'balance-amount'}>
                    {data?.total_interest != null
                      ? tHtml('common.money', { value: data.total_interest })
                      : '-'}
                  </div>
                )}
              </Col>
              <Col lg={12} className={'balance-information d-flex align-items-center'}>
                <div className={'balance-description'}>
                  {tHtml('dashboard.interest_received')}
                  <i className={'icon icon-info position-absolute'} id={'interest-received-icon'} />
                </div>
                <Tooltip
                  id={'interest-received-icon'}
                  text={tHtml('dashboard.investor.tooltip.interest_received')}
                />
              </Col>
            </Row>
          </Card>
          {!isMobile && (
            <div className={'action predefined-dates p-3'}>
              <div className={'title mt-2 mb-4'}> {tHtml('dashboard.set_the_time_frame')}</div>
              <PredefinedDates startDate={startDate} endDate={endDate} onChange={onDateChange} />
            </div>
          )}
          <Row className={'px-3'}>
            <div className={'actions'}>
              <DateFilter
                start={startDate}
                end={endDate}
                hidePredefinedDates={!isMobile}
                column={{
                  filterValue: filterValue,
                  setFilter: setFilterValue,
                }}
                fireEventAfterSaved={(start, end) => onDateChange([start, end])}
                fireEventAfterCleared={(start, end) => onDateChange([start, end])}
              />
              {isMobile && !isApp && (
                <div className="action">
                  <button className={`btn action-btn`} onClick={() => setIsOpen(true)}>
                    <i className={`icon icon-vertical-dots`} />
                  </button>
                  {isOpen && (
                    <SwipeModalComponent onClose={() => setIsOpen(false)}>
                      {renderExportButtons()}
                    </SwipeModalComponent>
                  )}
                </div>
              )}
            </div>
          </Row>
          {!isMobile && !isApp && <Row className={'p-3'}>{renderExportButtons()}</Row>}
        </div>
      </div>
    </>
  );
};

export default InterestReceived;
export { DateFilter };
