import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { action } from 'typesafe-actions';

import Api from 'api';
import { StoreApplicationEstimateRequestDto } from 'api/types/project-developer/application';
import { setGlobalLoading } from 'modules/app/actions';
import { PrimaryButton } from 'components/Formik';
import TextInput from 'components/Formik/TextInput';
import SelectYesNo from 'components/Formik/SelectYesNoInput';
import { convertToBoolean, redirect } from './CreateApplication';
import { ApplicationContext } from './module/ApplicationContext';
import { UPDATE_APPLICATION } from './module/ApplicationReducer';
import { ApplicationPageProps } from './steps';

interface IForm {
  project_plot_price: string;
  project_engineering_price: string;
  project_documentation_price: string;
  project_construction_price: string;
  existing_investment_plot_price: string;
  existing_investment_engineering_price: string;
  existing_investment_documentation_price: string;
  existing_investment_construction_price: string;
  presale_contracts: string;
  presale_contracts_amount: string;
  notarised_contracts: string;
  notarised_contracts_amount: string;
  building_permit: string;
}

const Estimate: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { state, setApplicationId, dispatch } = useContext(ApplicationContext);

  useEffect(() => {
    if (applicationId !== undefined) {
      setApplicationId(applicationId);
    }

    setGlobalLoading(false);
  }, [t, applicationId, setApplicationId]);

  const [initialFormValues, setInitialFormValues] = useState<IForm>({
    project_plot_price: '',
    project_engineering_price: '',
    project_documentation_price: '',
    project_construction_price: '',
    existing_investment_plot_price: '',
    existing_investment_engineering_price: '',
    existing_investment_documentation_price: '',
    existing_investment_construction_price: '',
    presale_contracts: '',
    presale_contracts_amount: '',
    notarised_contracts: '',
    notarised_contracts_amount: '',
    building_permit: '',
  });

  useEffect(() => {
    setInitialFormValues({
      project_plot_price: String(state.application?.estimate?.project_plot_price ?? ''),
      project_engineering_price: String(
        state.application?.estimate?.project_engineering_price ?? '',
      ),
      project_documentation_price: String(
        state.application?.estimate?.project_documentation_price ?? '',
      ),
      project_construction_price: String(
        state.application?.estimate?.project_construction_price ?? '',
      ),
      existing_investment_plot_price: String(
        state.application?.estimate?.existing_investment_plot_price ?? '',
      ),
      existing_investment_engineering_price: String(
        state.application?.estimate?.existing_investment_engineering_price ?? '',
      ),
      existing_investment_documentation_price: String(
        state.application?.estimate?.existing_investment_documentation_price ?? '',
      ),
      existing_investment_construction_price: String(
        state.application?.estimate?.existing_investment_construction_price ?? '',
      ),
      presale_contracts: String(state.application?.estimate?.presale_contracts ?? ''),
      presale_contracts_amount: String(state.application?.estimate?.presale_contracts_amount ?? ''),
      notarised_contracts: String(state.application?.estimate?.notarised_contracts ?? ''),
      notarised_contracts_amount: String(
        state.application?.estimate?.notarised_contracts_amount ?? '',
      ),
      building_permit: String(state.application?.estimate?.building_permit ?? ''),
    });
  }, [state]);

  const CreateApplicationSchema = Yup.object().shape({
    number_of_re: Yup.number().min(1),
    project_plot_price: Yup.number().min(1).required(),
    project_engineering_price: Yup.number().min(1).required(),
    project_documentation_price: Yup.number().min(1).required(),
    project_construction_price: Yup.number().min(1).required(),
    existing_investment_plot_price: Yup.number().min(0).required(),
    existing_investment_engineering_price: Yup.number().min(0).required(),
    existing_investment_documentation_price: Yup.number().min(0).required(),
    existing_investment_construction_price: Yup.number().min(0).required(),
    presale_contracts: Yup.number().min(0).required(),
    presale_contracts_amount: Yup.number().min(0),
    notarised_contracts: Yup.number().min(0).required(),
    notarised_contracts_amount: Yup.number().min(0),
    building_permit: Yup.string().required(),
  });

  const formatApplicationEstimateStoreRequest = useCallback(
    (request: IForm): StoreApplicationEstimateRequestDto => {
      return {
        project_plot_price: Number(request.project_plot_price),
        project_engineering_price: Number(request.project_engineering_price),
        project_documentation_price: Number(request.project_documentation_price),
        project_construction_price: Number(request.project_construction_price),
        existing_investment_plot_price: Number(request.existing_investment_plot_price),
        existing_investment_engineering_price: Number(
          request.existing_investment_engineering_price,
        ),
        existing_investment_documentation_price: Number(
          request.existing_investment_documentation_price,
        ),
        existing_investment_construction_price: Number(
          request.existing_investment_construction_price,
        ),
        presale_contracts: Number(request.presale_contracts),
        presale_contracts_amount: Number(request.presale_contracts_amount),
        notarised_contracts: Number(request.notarised_contracts),
        notarised_contracts_amount: Number(request.notarised_contracts_amount),
        building_permit: convertToBoolean(request.building_permit),
      } as StoreApplicationEstimateRequestDto;
    },
    [],
  );

  const onSubmit = useCallback(
    async (request: IForm, helpers: FormikHelpers<IForm>) => {
      try {
        const applicationEstimateRequest: StoreApplicationEstimateRequestDto =
          formatApplicationEstimateStoreRequest(request);

        await Api.projectDeveloper.applications
          .storeApplicationEstimate(applicationId, applicationEstimateRequest)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [formatApplicationEstimateStoreRequest, applicationId, dispatch, history, nextPage],
  );

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          <h1 className="mb-4 mb-md-6">{tHtml('application.estimate')}</h1>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={CreateApplicationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <h2 className="mb-3">{tHtml('application.estimate.project_estimate')}</h2>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'project_plot_price'}
                      placeholder={t('placeholder.application.project_plot_price')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'project_engineering_price'}
                      placeholder={t('placeholder.application.project_engineering_price')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'project_documentation_price'}
                      placeholder={t('placeholder.application.project_documentation_price')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'project_construction_price'}
                      placeholder={t('placeholder.application.project_construction_price')}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <h2 className="mt-4 mb-3">
                    {tHtml('placeholder.application.estimate.project_investment')}
                  </h2>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'existing_investment_plot_price'}
                      placeholder={t('placeholder.application.existing_investment_plot_price')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'existing_investment_engineering_price'}
                      placeholder={t(
                        'placeholder.application.existing_investment_engineering_price',
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'existing_investment_documentation_price'}
                      placeholder={t(
                        'placeholder.application.existing_investment_documentation_price',
                      )}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'existing_investment_construction_price'}
                      placeholder={t(
                        'placeholder.application.existing_investment_construction_price',
                      )}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <h2 className="mt-4 mb-3">
                    {tHtml('placeholder.application.estimate.presale_contracts')}
                  </h2>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'presale_contracts'}
                      placeholder={t('placeholder.application.presale_contracts')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'presale_contracts_amount'}
                      placeholder={t('placeholder.application.presale_contracts_amount')}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <h2 className="mt-4 mb-3">
                    {tHtml('placeholder.application.estimate.notarised_contracts')}
                  </h2>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'notarised_contracts'}
                      placeholder={t('placeholder.application.notarised_contracts')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'notarised_contracts_amount'}
                      placeholder={t('placeholder.application.notarised_contracts_amount')}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <h2 className="mt-4 mb-3">
                    {tHtml('placeholder.application.estimate.building_permit')}
                  </h2>
                  <div className="mb-3">
                    <SelectYesNo
                      name={'building_permit'}
                      true_label={tHtml('common.present')}
                      false_label={tHtml('common.absent')}
                      placeholder={tHtml('placeholder.application.building_permit')}
                    />
                  </div>
                </FormGroup>
                <hr />
                <div className={'d-flex justify-content-between align-items-center'}>
                  {/* TODO: add translations */}
                  <PrimaryButton
                    className={'btn btn-grey w-25'}
                    type={'button'}
                    onClick={() => {
                      redirect(history, previousPage, applicationId);
                    }}
                  >
                    {tHtml('common.back')}
                  </PrimaryButton>
                  <PrimaryButton className={'btn btn-primary w-25'} submitting={isSubmitting}>
                    {tHtml('common.continue')}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Row>
    </>
  );
};

export default withRouter(Estimate);
