import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';

export const StyledLogo = styled.div`
  .logo {
    height: 1.75rem;
    align-self: center;

    ${breakpointDown.SM} {
      height: 2rem;
    }
  }
`;
