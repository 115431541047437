import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Form, FormGroup } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Api from 'api';
import { PasswordResetRequestDto } from 'api/types/guest/authenticate';
import TextInput from 'components/Formik/TextInput';
import PrimaryButton from 'components/Formik/PrimaryButton';
import { success } from 'services/toastr';
import { setGlobalLoading } from 'modules/app/actions';

const ResetPassword: React.FC<RouteComponentProps> = () => {
  const { t, tHtml } = useTranslation();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t('validation.invalid')).required(),
  });

  const [resetPasswordRequest] = useState<PasswordResetRequestDto>({
    email: '',
  });

  const onSubmit = useCallback(
    async (request: PasswordResetRequestDto, helpers: FormikHelpers<PasswordResetRequestDto>) => {
      try {
        const response = await Api.guest.auth.passwordReset(request);

        if (response) {
          success(tHtml('authentication.password_reset_successfully'));
        }
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [tHtml],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <div className={'top-title d-flex flex-row'}>
        <h4 className={'me-auto mb-0 align-self-center'}>
          {tHtml('authentication.change_password')}
        </h4>
      </div>
      <Formik
        initialValues={resetPasswordRequest}
        validateOnBlur={false}
        validateOnChange={true}
        validationSchema={ResetPasswordSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div className="mb-3">
                <TextInput name="email" />
              </div>
              <div className={'mt-6'}>
                <PrimaryButton submitting={isSubmitting}>{tHtml('common.submit')}</PrimaryButton>
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
