import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import SimpleBar from 'simplebar-react';

import Api from 'api';
import { NotificationShortResponseDto } from 'api/types/app';
import NotificationsSidebarItem from './NotificationsSidebarItem';
import { PaginationMeta } from '../../api/types';
import useTranslation from '../../helpers/useTranslation';

const NotificationsSidebar: React.FC = () => {
  const { currentLanguage } = useTranslation();
  const [notifications, setNotifications] = useState<Array<NotificationShortResponseDto>>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [latestMeta, setLatestMeta] = useState<PaginationMeta>();
  const ref = React.createRef<any>();

  useEffect(() => {
    setNotifications([]);
    setCurrentPage(1);

    Api.notifications
      .fetchNotifications({
        limit: 10,
        page: 1,
      })
      .then((data) => {
        setNotifications(data.data);
        setLatestMeta(data.meta);
      });
  }, [currentLanguage]);

  const onNextFetch = useCallback(() => {
    Api.notifications
      .fetchNotifications({
        limit: 10,
        page: currentPage + 1,
      })
      .then((data) => {
        setCurrentPage((prevState) => prevState + 1);
        setNotifications((prevState) => [...prevState, ...data.data]);
        setLatestMeta(data.meta);
      });
  }, [currentPage]);

  return (
    <div
      className={
        'd-flex flex-column align-items-stretch flex-shrink-0 bg-white notifications-sidebar'
      }
    >
      <SimpleBar
        className={'list-group rounded-0 overflow-auto pt-0 pb-0'}
        forceVisible={'y'}
        scrollableNodeProps={{ id: 'notifications-sidebar-group', ref: ref }}
      >
        <InfiniteScroll
          next={onNextFetch}
          hasMore={latestMeta?.current_page !== latestMeta?.last_page}
          loader={
            <Skeleton
              className={'notifications-sidebar-item-skeleton'}
              enableAnimation={true}
              height={150}
            />
          }
          dataLength={notifications.length}
          style={{ height: 'inherit' }}
          scrollableTarget={'notifications-sidebar-group'}
        >
          {notifications?.map((notification) => (
            <NotificationsSidebarItem key={notification.id} {...notification} />
          ))}
        </InfiniteScroll>
      </SimpleBar>
    </div>
  );
};

export default NotificationsSidebar;
