import React from 'react';

import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import * as Roles from 'helpers/auth/roles';
import PageLayout from 'scopes/investor/containers/PageLayout';
import { Project } from '.';

const ProjectRoutes: any = () => [
  <AuthRoute
    path={RouteList.INVESTOR.PROJECTS.VIEW}
    component={Project}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    key={RouteList.INVESTOR.PROJECTS.VIEW}
    exact
  />,
];

export default ProjectRoutes;
