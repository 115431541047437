import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';
import ScrollspyLinkDelayed from 'components/Nav/ScrollspyLinkDelayed';
import useUserType from 'helpers/useUserType';
import useOnboarding from 'helpers/useOnboarding';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import { useAccountQuery } from 'api/queries/useAccountQuery';
import useProfileMenuState from '../../pages/Profile/ProfileMenuState';

const SCROLL_DELAY = 350;

const ProfileSidebar: React.FC = () => {
  const { tHtml } = useTranslation();
  const { isInvestor } = useUserType();
  const onBoarding = useOnboarding();

  const { isOpen, openOneCloseOther } = useProfileMenuState();

  const { data: userProfileData } = useUserProfileQuery();
  const { data } = useAccountQuery();

  const showIdVerificationSection =
    !(onBoarding.isAccredited && userProfileData?.id_verification == null) &&
    onBoarding.isContactDetailsFilled;

  return (
    <React.Fragment>
      <div className={'profile-sidebar w-100'}>
        <div className={'profile-info'}>
          {data?.main_user_avatar_url && (
            <img src={data?.main_user_avatar_url} alt="Avatar" className={'avatar'} />
          )}
          {userProfileData?.user?.natural?.first_name
            ? tHtml('profile.greeting_with_name', {
                value: userProfileData?.user?.natural?.first_name,
              })
            : tHtml('profile.greeting')}
        </div>
        <div className={'profile-menu mt-5'}>
          <Nav vertical>
            <NavItem>
              <ScrollspyLinkDelayed
                id={'contact-info'}
                onClick={() => openOneCloseOther(UserMenuEnum.ContactInfo)}
                delay={SCROLL_DELAY}
                className={isOpen(UserMenuEnum.ContactInfo) ? 'active' : ''}
              >
                {tHtml('menu.contact_info')}
              </ScrollspyLinkDelayed>
            </NavItem>
            {showIdVerificationSection && (
              <NavItem>
                <ScrollspyLinkDelayed
                  id={'id-verification'}
                  onClick={() => openOneCloseOther(UserMenuEnum.IdVerification)}
                  delay={SCROLL_DELAY}
                  className={isOpen(UserMenuEnum.IdVerification) ? 'active' : ''}
                >
                  {tHtml('menu.id_verification')}
                </ScrollspyLinkDelayed>
              </NavItem>
            )}
            <NavItem>
              <ScrollspyLinkDelayed
                id={'know-your-customer'}
                onClick={() => openOneCloseOther(UserMenuEnum.KnowYourCustomer)}
                delay={SCROLL_DELAY}
                className={isOpen(UserMenuEnum.KnowYourCustomer) ? 'active' : ''}
              >
                {tHtml('menu.kyc')}
              </ScrollspyLinkDelayed>
            </NavItem>
            {isInvestor() && (
              <>
                <NavItem>
                  <ScrollspyLinkDelayed
                    id={'investor-quiz'}
                    onClick={() => openOneCloseOther(UserMenuEnum.InvestorQuiz)}
                    delay={SCROLL_DELAY}
                    className={isOpen(UserMenuEnum.InvestorQuiz) ? 'active' : ''}
                  >
                    {tHtml('menu.investor_quiz')}
                  </ScrollspyLinkDelayed>
                </NavItem>
                <NavItem>
                  <ScrollspyLinkDelayed
                    id={'financial-risk-calculator'}
                    onClick={() => openOneCloseOther(UserMenuEnum.FinancialRiskCalculator)}
                    delay={SCROLL_DELAY}
                    className={isOpen(UserMenuEnum.FinancialRiskCalculator) ? 'active' : ''}
                  >
                    {tHtml('menu.financial_risk_calculator')}
                  </ScrollspyLinkDelayed>
                </NavItem>
              </>
            )}
            <NavItem>
              <ScrollspyLinkDelayed
                id={'security'}
                onClick={() => openOneCloseOther(UserMenuEnum.Security)}
                delay={SCROLL_DELAY}
                className={isOpen(UserMenuEnum.Security) ? 'active' : ''}
              >
                {tHtml('menu.security')}
              </ScrollspyLinkDelayed>
            </NavItem>
            <NavItem>
              <ScrollspyLinkDelayed
                id={'marketing-settings'}
                onClick={() => openOneCloseOther(UserMenuEnum.MarketingSettings)}
                delay={SCROLL_DELAY}
                className={isOpen(UserMenuEnum.MarketingSettings) ? 'active' : ''}
              >
                {tHtml('menu.marketing_settings')}
              </ScrollspyLinkDelayed>
            </NavItem>
          </Nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProfileSidebar);
