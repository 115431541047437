export interface ActionGuard {
  rejectIf: boolean;
  onReject?: () => void;
}

const isValid = (guards: ActionGuard[]) => {
  for (let i = 0; i < guards.length; i++) {
    if (guards[i].rejectIf) {
      guards[i].onReject?.();
      return false;
    }
  }
  return true;
};

const Guard = {
  isValid,
};

export default Guard;
