import React from 'react';
import { Container, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import TrustBoxContainer from './TrustPilotSmall';
import LemonWayComponent from './LemonWayComponent';
import LOGO from 'assets/images/logo/logo-dark.svg';
import FACEBOOK_ICON from 'assets/images/icons/facebook_footer.svg';
import LINKEDIN_ICON from 'assets/images/icons/linkedin_footer.svg';
import { LANDING_ROUTES } from 'routes';

const Footer: React.FC = () => {
  const { t, tHtml, currentLanguage } = useTranslation();

  const MENU: { [key: string]: string } = {
    [LANDING_ROUTES[currentLanguage].DOCUMENTS]: 'footer.documents',
    [LANDING_ROUTES[currentLanguage].RISKS]: 'footer.investment-risks',
    [LANDING_ROUTES[currentLanguage].FEES]: 'footer.fees',
    [LANDING_ROUTES[currentLanguage].BUSINESS_CALCULATOR]: 'footer.business-calculator',
    [LANDING_ROUTES[currentLanguage].INVESTMENT_CALCULATOR]: 'footer.investment-calculator',
    [LANDING_ROUTES[currentLanguage].CONTACTS]: 'footer.contacts',
    [LANDING_ROUTES[currentLanguage].PRIVACY_POLICY]: 'footer.privacy-policy',
  };

  const ABOUT_PLATFORM: { [key: string]: string } = {
    [LANDING_ROUTES[currentLanguage].FAQ]: 'footer.faq',
    [LANDING_ROUTES[currentLanguage].STATISTIC]: 'footer.statistic',
    [LANDING_ROUTES[currentLanguage].HOW_TO_INVEST]: 'footer.how-do-i-invest',
    [LANDING_ROUTES[currentLanguage].HOW_TO_BORROW]: 'footer.how-to-borrow',
    [LANDING_ROUTES[currentLanguage].AFFILIATE_PROGRAM]: 'footer.affiliate-program',
    [LANDING_ROUTES[currentLanguage].RECOMMENDATION_PROGRAM]: 'footer.recommendation-program',
  };

  return (
    <div className={'footer'}>
      <Container>
        <Row>
          <div className={'footer_main'}>
            <div className={'logo'}>
              <a href={LANDING_ROUTES[currentLanguage].HOME} target="_self">
                <img src={LOGO} alt="logo" />
              </a>
            </div>
            <div className={'about_platform'}>
              <div className={'footer_main_item_title'}>
                <h3>{tHtml('footer.about_platform')}</h3>
              </div>

              {Object.keys(ABOUT_PLATFORM).map((href) => {
                return (
                  <a href={href} key={href} className={'footer_main_item_link'} target="_self">
                    <p>{tHtml(ABOUT_PLATFORM[href])}</p>
                  </a>
                );
              })}
            </div>
            <div className={'footer_menu'}>
              {Object.keys(MENU).map((href) => {
                return (
                  <a href={href} key={href} className={'footer_main_item_link'} target="_self">
                    <p>{tHtml(MENU[href])}</p>
                  </a>
                );
              })}
            </div>
            <div className={'contacts'}>
              <div className={'footer_main_item_title'}>
                <h3>{tHtml('footer.contacts')}</h3>
              </div>
              <p>{tHtml('footer.working_hours')}</p>
              <a href={`tel:${t('footer.phone_number')}`} className={'footer_main_item_link'}>
                <p>{tHtml('footer.phone_number')}</p>
              </a>
              <a href={`mailto:${t('footer.mail')}`} className={'footer_main_item_link'}>
                <p>{tHtml('footer.mail')}</p>
              </a>
              <p>{tHtml('footer.address')}</p>
              <div className={'contacts_links'}>
                <a
                  href="https://www.facebook.com/people/Profitus/61555948506181/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={FACEBOOK_ICON} alt="facebook" />
                </a>
                <a
                  href="https://www.linkedin.com/company/profituslt/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LINKEDIN_ICON} alt="facebook" />
                </a>
              </div>
            </div>
          </div>

          <div className="divider_footer" />

          <div className={'info'}>
            <div className={'info_left'}>
              <h6>{tHtml('footer.license')}</h6>
              <h6>{tHtml('footer.license_info')}</h6>
            </div>
            <div className={'info_right'}>
              <LemonWayComponent />
              <TrustBoxContainer />
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
