import React from 'react';
import useMobileDetect from 'helpers/useMobileDetect';
import useTranslation from 'helpers/useTranslation';

const TwoFactorApplicationRecommendation = () => {
  const mobileDetect = useMobileDetect();
  const { tHtml } = useTranslation();

  const iosTwoFactorApplicationRecommendation = (
    <div className={'mt-2'}>{tHtml('placeholder.2fa.recommend_apps_ios')}</div>
  );

  const androidTwoFactorApplicationRecommendation = (
    <div className={'mt-2'}>{tHtml('placeholder.2fa.recommend_apps_android')}</div>
  );

  if (mobileDetect.isIos()) {
    return iosTwoFactorApplicationRecommendation;
  }

  if (mobileDetect.isAndroid()) {
    return androidTwoFactorApplicationRecommendation;
  }

  return (
    <>
      {iosTwoFactorApplicationRecommendation}
      {androidTwoFactorApplicationRecommendation}
    </>
  );
};

export default TwoFactorApplicationRecommendation;
