import React, { useEffect, useState } from 'react';
import { ArrayHelpers } from 'formik';
import { Card } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { StoreProspectUserCompanyShareholderKYBRequestDto } from 'api/types/shared/user/company';
import { DataBlock } from 'components/DataBlock';
import { FunctionalButton } from 'components/Button';
import { TextInput } from 'components/Formik';
import { SelectCountryInput } from 'components/Formik/Selects';
import YesNoSelector from 'components/Formik/YesNoSelector';

const SingleShareholderForm: React.FC<{
  shareholder: StoreProspectUserCompanyShareholderKYBRequestDto;
  index: number;
  fieldName: string;
  formikHelpers: any;
  formikArrayHelpers: ArrayHelpers;
}> = ({ shareholder, index, fieldName, formikHelpers, formikArrayHelpers }) => {
  const { t, tHtml } = useTranslation();
  const [show, setShow] = useState<boolean>(true);
  const fullName = `${fieldName}[${index}].name`;
  const shares = `${fieldName}[${index}].shares`;
  const legalCode = `${fieldName}[${index}].legal_code`;
  const country = `${fieldName}[${index}].country`;
  const tradedSecuritiesOnEuExchanges = `${fieldName}[${index}].traded_securities_on_eu_exchanges`;

  const setVisibility = async () => {
    const errors = await formikHelpers.validateForm();

    let show = false;
    if (
      errors != undefined &&
      errors.shareholders != undefined &&
      errors.shareholders[index] != undefined
    ) {
      show = true;
    }

    setShow(show);
  };

  useEffect(() => {
    setVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    index,
    formikHelpers.validateForm,
    formikHelpers.submitCount,
    formikHelpers.values.shareholders.length,
  ]);

  if (!show) {
    return (
      <div className={'form-cards'}>
        <Card body className={'light-pink'}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <DataBlock
              title={tHtml('placeholder.company.shareholder.name')}
              data={shareholder.name}
            />
            <DataBlock
              title={tHtml('placeholder.company.shareholder.legal_code')}
              data={shareholder.legal_code}
            />
            <DataBlock
              title={tHtml('placeholder.company.shareholder.country')}
              data={t('countries.' + shareholder.country)}
            />
            <div className="buttons">
              <FunctionalButton onClick={() => setShow(true)}>
                {tHtml('common.edit')}
              </FunctionalButton>
              <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
                {tHtml('common.remove')}
              </FunctionalButton>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="sub-form">
      <div className={'mb-4'}>
        <TextInput placeholder={t('placeholder.company.shareholder.name')} name={fullName} />
      </div>
      <div className={'mb-1'}>
        <TextInput
          placeholder={t('placeholder.company.shareholder.shares')}
          name={shares}
          type={'number'}
        />
      </div>
      <div className={'mb-2 pb-1'}>
        <SelectCountryInput
          name={country}
          placeholder={t('placeholder.company.shareholder.country')}
        />
      </div>
      <div className={'mb-3'}>
        <TextInput placeholder={t('placeholder.company.shareholder.legal_code')} name={legalCode} />
      </div>
      <div className={'mb-3'}>
        <YesNoSelector
          name={tradedSecuritiesOnEuExchanges}
          placeholder={tHtml('placeholder.company.shareholder.traded_securities_on_eu_exchanges')}
        />
      </div>
      <div className="mb-3">
        <div className="buttons">
          <FunctionalButton type="button" onClick={() => setVisibility()}>
            {tHtml('common.save')}
          </FunctionalButton>
          <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
            {tHtml('common.remove')}
          </FunctionalButton>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default SingleShareholderForm;
