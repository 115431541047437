import React from 'react';

import * as Roles from 'helpers/auth/roles';
import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import { BaseLayout } from 'containers';
import { ConfirmEmail, Impersonate, ConfirmEmailChange, PreviewProject } from '.';
import SharedLayoutWrapper from '../../../shared/containers/SharedLayoutWrapper';

const AuthenticationRoutes: any = () => [
  <AuthRoute
    path={RouteList.GLOBAL.AUTHENTICATION.IMPERSONATE}
    component={Impersonate}
    layout={BaseLayout}
    permissions={Roles.All.permissions}
    key={RouteList.GLOBAL.AUTHENTICATION.IMPERSONATE}
    exact
  />,
  <AuthRoute
    path={RouteList.GLOBAL.AUTHENTICATION.REGISTER_CONFIRM_EMAIL}
    component={ConfirmEmail}
    layout={BaseLayout}
    permissions={Roles.All.permissions}
    redirect={Roles.All.redirect}
    key={RouteList.GLOBAL.AUTHENTICATION.REGISTER_CONFIRM_EMAIL}
    exact
  />,
  <AuthRoute
    path={RouteList.GLOBAL.AUTHENTICATION.CONFIRM_EMAIL_CHANGE}
    component={ConfirmEmailChange}
    layout={BaseLayout}
    permissions={Roles.All.permissions}
    redirect={Roles.All.redirect}
    key={RouteList.GLOBAL.AUTHENTICATION.CONFIRM_EMAIL_CHANGE}
    exact
  />,
  <AuthRoute
    path={RouteList.GLOBAL.PROJECTS.PREVIEW}
    component={PreviewProject}
    layout={SharedLayoutWrapper}
    permissions={Roles.All.permissions}
    key={RouteList.GLOBAL.PROJECTS.PREVIEW}
    exact
  />,
];

export default AuthenticationRoutes;
