import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import useOnboarding from 'helpers/useOnboarding';
import Api from '../../../api';
import { LANDING_ROUTES, RouteList } from '../../../routes';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Loader from '../../../components/Loader';
import { Link, NavLink } from 'react-router-dom';
import { useAccountQuery } from '../../../api/queries';
import SwitchAutheticatedUser from './SwitchAutheticatedUser';
import { useAuth } from '../../../services/useAuth';
import SUITCASE_ICON from '../../../assets/images/icons/suitcase.svg';

const AuthenticatedAccountDropdown: React.FC = () => {
  const { tHtml, currentLanguage } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const onBoarding = useOnboarding();

  const { data, isLoading, isFetching } = useAccountQuery();
  const { logout } = useAuth();

  if (!data || isLoading || isFetching) {
    return (
      <React.Fragment>
        <Loader size={'sm'} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Dropdown
        className={'profile-dropdown'}
        isOpen={isOpen}
        toggle={() => setOpen((prev) => !prev)}
      >
        <div className={'justify-content-center d-flex align-items-center'}>
          {!onBoarding.isCompleted && (
            <NavLink
              to={RouteList.COMPANY.PROFILE.HOME}
              className={'justify-content-center d-flex align-items-center'}
            >
              <i className={'icon-alert p-0'} />
            </NavLink>
          )}
          <DropdownToggle className={isOpen ? 'open' : ''} caret>
            <span title={data.name}>
              {!data.user ? (
                <img src={data.main_user_avatar_url} alt="Avatar" className={'avatar'} />
              ) : (
                <img src={SUITCASE_ICON} alt="Avatar" className={'company avatar'} />
              )}
              <span className={'only-icon-phone label'}>{data.name}</span>
            </span>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem className={'action'} to={RouteList.COMPANY.PROFILE.HOME} tag={Link}>
              <i className={'icon icon-user'} />
              {tHtml('menu.user')}
              {!onBoarding.isCompleted && <i className={'icon-alert p-0'} />}
            </DropdownItem>
            <DropdownItem
              className={'action'}
              href={LANDING_ROUTES[currentLanguage].HOME}
              onClick={() => {
                logout();
              }}
            >
              <i className={'icon icon-logout'} />
              {tHtml('common.logout')}
            </DropdownItem>
            {data.user && (
              <SwitchAutheticatedUser
                account={data.user}
                avatar={data.main_user_avatar_url}
                onSwitch={Api.shared.loginAs.userLogin}
              />
            )}
            {data.companies &&
              data.companies.map((company) => {
                return (
                  <SwitchAutheticatedUser
                    key={company.id}
                    account={company}
                    onSwitch={(id) => Api.shared.loginAs.companyLogin(id)}
                    isCompany={true}
                  />
                );
              })}
          </DropdownMenu>
        </div>
      </Dropdown>
    </React.Fragment>
  );
};

export default AuthenticatedAccountDropdown;
