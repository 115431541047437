import Client from '../client';
import Http from '../../http';
import { PaginationData, PaginationDataFilter } from '../../types';
import { OperationsResponseDto } from '../../types/shared/wallet';
import { CommonDocumentTypeEnum } from '../../../helpers/enums/CommonDocumentTypeEnum';
import { ExternalMediaResponseDto } from '../../types/common';

class InvestorTransaction extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_ALL_TRANSACTIONS: this.protectedUrl(`${this.http.baseUrl}/investor/operations`),
      EXPORT_ALL_TRANSACTIONS: this.protectedUrl(
        `${this.http.baseUrl}/investor/operations/export/{type}`,
      ),
      FETCH_INVESTMENT_TRANSACTIONS: this.protectedUrl(
        `${this.http.baseUrl}/investor/operations/investment`,
      ),
      EXPORT_INVESTMENT_TRANSACTIONS: this.protectedUrl(
        `${this.http.baseUrl}/investor/operations/investment/export/{type}`,
      ),
      FETCH_INVESTMENT_REPAYMENT_TRANSACTIONS: this.protectedUrl(
        `${this.http.baseUrl}/investor/operations/repayment`,
      ),
      EXPORT_INVESTMENT_REPAYMENT_TRANSACTIONS: this.protectedUrl(
        `${this.http.baseUrl}/investor/operations/repayment/export/{type}`,
      ),
    };
  }

  fetchAllTransactions = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<OperationsResponseDto>> => {
    return this.http.get(this.api.FETCH_ALL_TRANSACTIONS, { params: request });
  };

  exportAllTransactions = (
    type: CommonDocumentTypeEnum,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.EXPORT_ALL_TRANSACTIONS, { type });
    return this.http.get(url, { params: request });
  };

  fetchInvestmentTransactions = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<OperationsResponseDto>> => {
    return this.http.get(this.api.FETCH_INVESTMENT_TRANSACTIONS, { params: request });
  };

  exportInvestmentTransactions = (
    type: CommonDocumentTypeEnum,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.EXPORT_INVESTMENT_TRANSACTIONS, { type });
    return this.http.get(url, { params: request });
  };

  fetchInvestmentRepaymentTransactions = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<OperationsResponseDto>> => {
    return this.http.get(this.api.FETCH_INVESTMENT_REPAYMENT_TRANSACTIONS, { params: request });
  };

  exportInvestmentRepaymentTransactions = (
    type: CommonDocumentTypeEnum,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<ExternalMediaResponseDto> => {
    const url = this.buildUrl(this.api.EXPORT_INVESTMENT_REPAYMENT_TRANSACTIONS, { type });
    return this.http.get(url, { params: request });
  };
}

export default InvestorTransaction;
