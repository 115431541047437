import React from 'react';
import { Container, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import InlineCalculator from 'scopes/investor/pages/Project/components/Calculator/InlineCalculator';
import { ProjectContextState, withProject } from 'components/Project/Scope/ProjectProvider';
import { InvestorProjectResponse } from 'api/types/investor/project';
import { canInvestOnlyRemainingAmount } from 'helpers/investmentsHelper';

interface Props extends ProjectContextState {
  show: boolean;
}

const ProjectMinimized: React.FC<Props> = ({ project, ...props }) => {
  const { tHtml } = useTranslation();

  const p: InvestorProjectResponse = project as InvestorProjectResponse;

  if (!p || !p.is_investable) return <></>;

  if (canInvestOnlyRemainingAmount(p)) {
    return <></>;
  }

  return (
    <Container
      className={
        props.show && props.type !== 'preview'
          ? 'project-element-fixed-top'
          : 'project-element-fixed-top hidden'
      }
    >
      <Row hidden={!props.show}>
        <div className={'project-minimized'}>
          <div className={'project-minimized-inner'}>
            <div className={'project-minimized-inner-content'}>
              <div className={'project-minimized-inner-location'}>
                <div className={'name'}>{p.project_name}</div>
                <div className={'info'}>
                  <div className={'investors'}>
                    <i className={'icon icon-investors'} /> {p.investors}
                  </div>
                  <div className={'status'}>
                    {tHtml('common.money', { value: p.invested_amount })} /{' '}
                    {tHtml('common.money', { value: p.required_amount })}
                  </div>
                </div>
              </div>
              <div className={'project-minimized-inner-calculator'}>
                {p.required_amount && p.minimal_investment <= p.required_amount && (
                  <InlineCalculator
                    minAmount={p.minimal_investment}
                    maxAmount={p.required_amount}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default withProject(ProjectMinimized);
