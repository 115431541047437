import React, { ReactElement } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';

import Table, { ExportTableLinkProp } from 'components/Table/Table';
import { FunctionalButton } from 'components/Button';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { TableInitialSortBy } from 'api/types/common';
import useDisplay from 'helpers/useDisplay';

interface Props {
  title?: string | ReactElement;
  columns: Column<any>[];
  data: PaginationData<any> | undefined;
  fetchData: (request: PaginationDataFilter | undefined) => Promise<any>;
  link: string;
  minimizeCard?: boolean;
  noDataLabel?: string | ReactElement;
  initialSortBy?: TableInitialSortBy[];
  hideSubRowColumns?: string[];
  hideColumnsOnDesktop?: string[];
  hideColumnsOnMobile?: string[];
  hideSortFields?: string[];
  exportLinks?: ExportTableLinkProp[];
}

// TODO: remove data length check in my investments
const TableOverview: React.FC<Props> = (props) => {
  const { tHtml } = useTranslation();
  const { isMobile } = useDisplay();

  return (
    <div className={props?.data?.data.length && props.data.data.length > 0 ? '' : 'mb-4'}>
      {props.title && <h2>{props.title}</h2>}
      <div className={'mt-4'}>
        <Table
          columns={props.columns}
          data={props.data}
          onFetchData={props.fetchData}
          showPagination={false}
          noDataLabel={props.noDataLabel}
          initialSortBy={props.initialSortBy}
          mobile={true}
          minimizeCard={props.minimizeCard}
          exportLinks={props.exportLinks ?? []}
          hideSubRowColumns={props.hideSubRowColumns}
          hideSortFields={props.hideSortFields}
          hideColumnsOnDesktop={props.hideColumnsOnDesktop}
          hideColumnsOnMobile={props.hideColumnsOnMobile}
        />
      </div>
      {!isMobile && (
        <>
          {props.data && props.data.data.length > 0 && (
            <div className={'mt-4 d-flex justify-content-end'}>
              <Link to={props.link}>
                <FunctionalButton>
                  {tHtml('common.more')} <i className={'icon icon-arrow-right'} />
                </FunctionalButton>
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TableOverview;
