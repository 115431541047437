import React, { useEffect } from 'react';
import { ErrorMessage, Field, useField } from 'formik';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  label: string;
  showPlaceholder?: boolean;
  translationPrefix?: string;
  isDisabled?: boolean;
}

const SwitchInput: React.FC<Props> = ({ ...props }) => {
  const [field, meta, helper] = useField(props.name);

  useEffect(() => {
    helper.setTouched(true, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const handleChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e);
    }

    field.onChange(e);
  };

  const isError = meta?.touched && !!meta?.error;

  return (
    <div className={'switch-input-block'}>
      {props.label && (
        <span className={`label${field.value ? ' checked' : ''}`}>{props.label}</span>
      )}
      <label className={'switch'}>
        <Field
          onChange={handleChange}
          onBlur={field.onBlur}
          type={'checkbox'}
          className={`form-check-input${isError ? ' is-invalid' : ''}`}
          name={props.name}
          disabled={props.isDisabled}
        />
        <span className={'slider'} />
      </label>
      {isError && (
        <div className={'invalid-feedback ms-2'}>
          {props.name && <ErrorMessage name={props.name} />}
        </div>
      )}
    </div>
  );
};

export default SwitchInput;
