import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import { UPDATE_APPLICATION } from './module/ApplicationReducer';
import { action } from 'typesafe-actions';
import * as Yup from 'yup';

import Api from 'api';
import { DatepickerInput, PrimaryButton, TextInput } from 'components/Formik';
import transformErrors from 'helpers/formik';
import { ApplicationContext } from './module/ApplicationContext';
import { redirect } from 'scopes/guest/helpers/utils';
import { ApplicationPageProps } from './steps';
import { BasicCompanyInfoDto } from 'api/types/guest/prospectApplication';
import Loader from 'components/Loader';
import { SelectCountryInput } from 'components/Formik/Selects';
import { PhoneNumberInput } from 'components/Formik/Inputs';

const BasicCompanyInfo: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { state, dispatch } = useContext(ApplicationContext);
  const [initialFormValues, setInitialFormValues] = useState<BasicCompanyInfoDto | null>(null);

  useEffect(() => {
    if (state.application?.prospect_application.id !== applicationId) return;
    const companyInfo = state.application?.prospect_application.company;
    setInitialFormValues(companyInfo);
  }, [t, applicationId, state]);

  const onSubmit = useCallback(
    async (request: BasicCompanyInfoDto, helpers: FormikHelpers<BasicCompanyInfoDto>) => {
      try {
        await Api.guest.prospect_application
          .storeApplicationBasicCompanyInfo(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  const schema = Yup.object().shape({
    name: Yup.string().fullName().required(),
    legal_code: Yup.string().companyLegalCode().required(),
    country: Yup.string().required(),
    address: Yup.string().addressLine().required(),
    office_address: Yup.string().addressLine().required(),
    activity_duration: Yup.number().max(2000).required(),
    established_at: Yup.date().required().max(new Date()),
    website: Yup.string().max(100).required(),
    email: Yup.string().email(t('validation.invalid')).required(),
    phone: Yup.string().phone().required(),
  });

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          {initialFormValues ? (
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              initialValues={initialFormValues}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {(helpers) => (
                <Form onSubmit={helpers.handleSubmit}>
                  <FormGroup>
                    <h2 className={'mb-4'}>{tHtml('prospect_application.basic_company_info')}</h2>
                    <div className="mb-4">
                      <TextInput name={'name'} placeholder={t('placeholder.name')} />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'legal_code'}
                        placeholder={t('placeholder.company.legal_code')}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput name={'address'} placeholder={t('placeholder.company.address')} />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'office_address'}
                        placeholder={t('placeholder.company.office_address')}
                      />
                    </div>
                    <div className="mb-4">
                      <DatepickerInput
                        hasFutureRange={false}
                        name={'established_at'}
                        label={t('placeholder.company.established_at')}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        type="number"
                        name={'activity_duration'}
                        placeholder={t('placeholder.company.activity_duration')}
                      />
                    </div>
                    <div className="mb-4">
                      <SelectCountryInput
                        name="country"
                        placeholder={t('placeholder.company.country')}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput name={'website'} placeholder={t('placeholder.company.website')} />
                    </div>
                    <div className="mb-4">
                      <TextInput name={'email'} placeholder={t('placeholder.email')} />
                    </div>
                    <div className={'mb-4'}>
                      <PhoneNumberInput name={'phone'} label={t('placeholder.phone')} />
                    </div>
                  </FormGroup>
                  <hr />
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <PrimaryButton
                      className={'btn btn-grey w-25'}
                      type={'button'}
                      onClick={() => {
                        redirect(history, previousPage, applicationId);
                      }}
                    >
                      {tHtml('common.back')}
                    </PrimaryButton>
                    <PrimaryButton
                      className={'btn btn-primary w-25'}
                      submitting={helpers.isSubmitting}
                    >
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default withRouter(BasicCompanyInfo);
