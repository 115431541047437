import React from 'react';
import Watchers from 'scopes/investor/pages/Project/components/Watchers';
import { AnimatedProgressProvider, LinearProgressBar } from 'components/ProgressBar';
import CalculatorWithCollection from './components/CalculatorWithCollection';
import { ProjectContextState, withProject } from 'components/Project/Scope/ProjectProvider';
import ProjectGallery from 'components/Project/ProjectGallery';
import { round } from 'lodash';
import useTranslation from 'helpers/useTranslation';

const ProjectTopApp: React.FC<ProjectContextState> = ({ project }) => {
  const { tHtml } = useTranslation();

  if (!project) return <></>;

  const getPercent = (firstNumber: number, secondNumber: number) => {
    if (!secondNumber) return 0;

    return round((firstNumber / secondNumber) * 100, 2);
  };

  return (
    <>
      <h2>{tHtml('projects.header', { id: project.pid })}</h2>
      <div className={'project-section'}>
        <div className={'project-top'}>
          <div className={'project-view app'}>
            {project.watchers !== null && <Watchers number={project.watchers} />}
            <ProjectGallery pictures={project.pictures} displayAll={false} />
          </div>
          <div className={'project-calculator app'}>
            <div className={'top d-flex justify-content-between'}>
              <h1>{project.project_name}</h1>
              <div className={'city'}>{project.project_address}</div>
            </div>
            <div className={'body'}>
              <div className={'percent'}>
                <AnimatedProgressProvider
                  valueEnd={getPercent(project.invested_amount, project.required_amount)}
                >
                  {({ value }) => <LinearProgressBar percentage={value} />}
                </AnimatedProgressProvider>
              </div>
              <CalculatorWithCollection />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withProject(ProjectTopApp);
