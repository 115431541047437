import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import * as Yup from 'yup';
import { Form } from 'reactstrap';
import { Formik } from 'formik';

import Api from 'api';
import CheckboxInput from 'components/Formik/CheckboxInput';
import PrimaryButton from 'components/Formik/PrimaryButton';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import QrCodeModal from '../../modals/authentication/QrCodeModal';
import { useStepsModalStateStore } from '../../modals/RequiredSteps/store';
import { setGlobalLoading } from 'modules/app/actions';

const IDVerification: React.FC<RouteComponentProps> = () => {
  const { t, tHtml } = useTranslation();
  const modalState = useStepsModalStateStore();
  const [request] = useState<{ personal_data: boolean }>({ personal_data: false });
  const { showModal } = useGlobalModalContext();
  const [proceedByQrCode, setProceedByQrCode] = useState<boolean>();

  const VerificationSchema = Yup.object().shape({
    personal_data: Yup.boolean().oneOf([true], t('validation.required')),
  });

  const onSubmit = useCallback(
    async (values) => {
      if (!values.personal_data) return false;
      const response = await Api.shared.user.signUpSteps.registrationVerification();

      if (proceedByQrCode) {
        return showModal(
          <QrCodeModal
            qrCode={response.redirectUrl}
            title={t('authentication.qr_code.modal.title')}
            message={t('authentication.qr_code.modal.body')}
          />,
        );
      } else {
        window.open(response.redirectUrl, '_self', 'noopener,noreferrer');
      }
      return true;
    },
    [proceedByQrCode, t, showModal],
  );

  useEffect(() => {
    modalState.setShouldBeShown({ state: true });
    setGlobalLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className={'top-title d-flex flex-row mb-4'}>
        <Formik
          validationSchema={VerificationSchema}
          onSubmit={onSubmit}
          initialValues={request}
          enableReinitialize={true}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form className={'w-100 col-sm-12 col-md-9'} onSubmit={handleSubmit}>
              <h4 className={'me-auto mb-4 align-self-center'}>
                {tHtml('authentication.step_id_verification')}
              </h4>
              <div>
                <div className={'mb-4'}>
                  <CheckboxInput name={'personal_data'} showPlaceholder={true} />
                </div>
                <div className={'d-flex align-items-center gap-2 margin-right-sm'}>
                  <PrimaryButton
                    className={'btn btn-primary w-50'}
                    name={'submit'}
                    disabled={isSubmitting}
                    onClick={() => setProceedByQrCode(false)}
                  >
                    {tHtml('common.submit')}
                  </PrimaryButton>
                  <div>{t('authentication.or')}</div>
                  <PrimaryButton
                    className={'btn btn-primary w-50'}
                    disabled={isSubmitting}
                    onClick={() => setProceedByQrCode(true)}
                  >
                    {t('authentication.qr_code.button')}
                  </PrimaryButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default withRouter(IDVerification);
