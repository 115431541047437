import React, { useMemo } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { ErrorMessage, useField } from 'formik';
import { Card, Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import { getClassName, SHOWN_ERRORS } from './FileUpload';
import FileUploadListItem from './FileUploadListItem';
import { warning } from 'services/toastr';

export interface FileUploadDirectProps extends DropzoneOptions {
  name: string;
}

const FileUploadDirect: React.FC<FileUploadDirectProps> = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  const isSingleFileInput = props.maxFiles && props.maxFiles === 1;

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (isSingleFileInput) {
        helper.setValue(acceptedFiles[0]);
      } else {
        const files = field.value;
        acceptedFiles.forEach(
          (file) =>
            (!props.maxFiles || (props.maxFiles && files.length < props.maxFiles)) &&
            files.push(file),
        );
        helper.setValue(files);
      }
    },
    onDropRejected: (fileRejections) => {
      fileRejections.map((rejection) =>
        rejection.errors
          .filter((error) => SHOWN_ERRORS.includes(error.code))
          .map((error) =>
            warning(tHtml('validation.file_upload.' + error.code, { file: rejection.file.name })),
          ),
      );
    },
    ...props,
  });

  const isError = meta?.touched && !!meta?.error;

  const className = useMemo(
    () => getClassName(isFocused, isDragAccept, isDragReject, props.disabled),
    [isFocused, isDragAccept, isDragReject, props.disabled],
  );

  const removeFile = (index: number) => {
    const files = field.value;
    files.splice(index, 1);
    helper.setValue(files);
  };

  return (
    <>
      <div className={'file-upload'}>
        <div className={isError ? 'is-invalid' : ''}>
          <div className={isError ? 'is-invalid' : ''}>
            <div {...getRootProps({ className })}>
              <input {...getInputProps()} />
              <div>
                <i className={'icon-upload'} />
                {tHtml('placeholder.file_upload.drag_and_drop')}
              </div>
              <div className={'splitter'}>{tHtml('placeholder.file_upload.or')}</div>
              <div>
                <a>{tHtml('placeholder.file_upload.open_file_browser')}</a>
              </div>
            </div>
          </div>
          {isError && (
            <div className={'invalid-feedback text-pre'}>
              {props.name && (
                <ErrorMessage
                  component={'div'}
                  name={props.name}
                  render={(msg: string | string[]) => {
                    if (typeof msg === 'string') {
                      return msg;
                    }

                    return msg.join('\n');
                  }}
                />
              )}
            </div>
          )}
        </div>
        {field.value && isSingleFileInput && (
          <div className={'file-upload-list mt-2'}>
            <Card>
              <Row>
                <Col>
                  <FileUploadListItem
                    file={field.value}
                    onRemove={() => helper.setValue(null)}
                    isDirect={true}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        )}
        {field.value && field.value.length > 0 && (
          <div className={'file-upload-list mt-2'}>
            <Card>
              <Row>
                <Col>
                  {Object.keys(field.value).map((key, index) => (
                    <FileUploadListItem
                      file={field.value[key]}
                      onRemove={() => removeFile(index)}
                      isDirect={true}
                      key={index}
                    />
                  ))}
                </Col>
              </Row>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploadDirect;
