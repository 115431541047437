import Tooltip from 'components/Tooltip';
import useTranslation from 'helpers/useTranslation';
import useVerifiedWallet from 'helpers/useVerifiedWallet';
import React from 'react';
import { Nav } from 'reactstrap';
import { RouteList } from 'routes';

import { StyledBottomNavigation, StyledLink, StyledDisabledLink } from './BottomNavigation.style';

interface MenuItem {
  name: string;
  path: string;
  icon: string;
  disabled?: boolean;
}

const BottomNavigation: React.FC = () => {
  const { t } = useTranslation();
  const { isWalletVerified, errorMessage } = useVerifiedWallet();

  const menuItems: MenuItem[] = [
    {
      name: t('menu.account_overview'),
      path: RouteList.DASHBOARD,
      icon: 'icon-home',
    },
    {
      name: t('menu.invest'),
      path: RouteList.INVESTOR.PROJECTS.LIST,
      icon: 'icon-arrow-trend',
    },
    {
      name: t('menu.wallet'),
      path: RouteList.SHARED.WALLET.OVERVIEW,
      icon: 'icon-wallet-new',
      disabled: !isWalletVerified,
    },
    {
      name: t('menu.notifications'),
      path: RouteList.NOTIFICATIONS.LIST,
      icon: 'icon-mail',
    },
  ];

  const renderItem = (menuItem: MenuItem) => {
    return menuItem.disabled ? (
      <>
        <StyledDisabledLink id={menuItem.icon}>
          <i className={'icon-alert p-0'} />
          <div>
            <i className={`icon ${menuItem.icon}`} />
            <span>{menuItem.name}</span>
          </div>
        </StyledDisabledLink>
        {errorMessage && <Tooltip id={menuItem.icon} text={errorMessage} />}
      </>
    ) : (
      <StyledLink to={menuItem.path ?? '#'} key={menuItem.path}>
        <i className={`icon ${menuItem.icon}`} />
        <span>{menuItem.name}</span>
      </StyledLink>
    );
  };

  return (
    <StyledBottomNavigation>
      <div className={'header-line app'} />
      <Nav className="h-100">{menuItems.map((menuItem) => renderItem(menuItem))}</Nav>
    </StyledBottomNavigation>
  );
};

export default BottomNavigation;
