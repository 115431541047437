import styled from 'styled-components';

export const StyledPartialReturn = styled.div``;

export const StyledPartialReturnHeaderWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledPartialReturnHeader = styled.h1`
  color: var(--profitus-h2-color);
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const StyledPartialReturnLabel = styled.h1`
  color: var(--profitus-h2-color);
  font-size: 1rem;
  font-weight: 700;
`;

export const StyledPartialReturnParagraph = styled.p`
  color: var(--profitus-text-p-color);
`;
