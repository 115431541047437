import React from 'react';
import useTranslation from 'helpers/useTranslation';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from './SecondaryMarketInvestmentProvider';
import ProjectInformation from './ProjectInformation';
import TabData, { TabInfo } from 'components/Tabs';
import TabProjectOwner from './Tabs/TabProjectOwner';
import TabInvestmentInformation from './Tabs/TabInvestmentInformation';
import TabSecurityDeposits from './Tabs/TabSecurityDeposits';
import TabProjectInformation from './Tabs/TabProjectInformation';
import TabDocuments from './Tabs/TabDocuments';
import TabNews from './Tabs/TabNews';
import TabPayouts from './Tabs/TabPayouts';

const ProjectVerifiedUser: React.FC<SecondaryMarketInvestmentContextState> = ({
  secondaryMarketInvestment,
}) => {
  const { t } = useTranslation();

  const tabs: TabInfo<any>[] = [
    {
      name: t('secondary_market.project.tab.project_owner'),
      component: TabProjectOwner,
    },
    {
      name: t('secondary_market.project.tab.investment_information'),
      component: TabInvestmentInformation,
    },
    {
      name: t('secondary_market.project.tab.security_deposits'),
      component: TabSecurityDeposits,
    },
    {
      name: t('secondary_market.project.tab.project_information'),
      component: TabProjectInformation,
    },
    {
      name: t('secondary_market.project.tab.payouts'),
      component: TabPayouts,
    },
    {
      name: t('secondary_market.project.tab.documents'),
      component: TabDocuments,
    },
    {
      name: t('secondary_market.project.tab.news'),
      component: TabNews,
    },
  ];

  return (
    <>
      {secondaryMarketInvestment?.more_information && (
        <>
          <ProjectInformation />
          <TabData tabs={tabs} className={'justify-content-around'} />
        </>
      )}
    </>
  );
};

export default withSecondaryMarketInvestment(ProjectVerifiedUser);
