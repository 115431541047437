import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Nav, NavItem } from 'reactstrap';

import ScrollspyLinkDelayed from 'components/Nav/ScrollspyLinkDelayed';
import { CompanyMenuEnum } from 'helpers/enums/CompanyMenuEnum';
import useUserType from 'helpers/useUserType';
import { useAccountQuery } from 'api/queries';
import useProfileMenuState from '../../../user/pages/Profile/ProfileMenuState';
import SUITCASE_ICON from '../../../../assets/images/icons/suitcase.svg';
import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';

const SCROLL_DELAY = 350;

const ProfileSidebar: React.FC = () => {
  const { tHtml } = useTranslation();
  const { isInvestor } = useUserType();

  const { data } = useAccountQuery();

  const { isOpen, openOneCloseOther } = useProfileMenuState();

  return (
    <React.Fragment>
      <div className={'p-4 profile-sidebar w-100'}>
        <div className={'profile-info'}>
          <img src={SUITCASE_ICON} alt="Avatar" className={'company avatar'} />
          {data?.name
            ? tHtml('profile.greeting_with_name', { value: data?.name })
            : tHtml('profile.greeting')}
        </div>
        <div className={'profile-menu mt-5'}>
          <Nav vertical>
            <NavItem>
              <ScrollspyLinkDelayed
                id={'contact-info'}
                onClick={() => openOneCloseOther(CompanyMenuEnum.ContactInfo)}
                delay={SCROLL_DELAY}
                className={isOpen(CompanyMenuEnum.ContactInfo) ? 'active' : ''}
              >
                {tHtml('menu.contact_info')}
              </ScrollspyLinkDelayed>
            </NavItem>
            <NavItem>
              <ScrollspyLinkDelayed
                id={'know-your-business'}
                onClick={() => openOneCloseOther(CompanyMenuEnum.KnowYourBusiness)}
                delay={SCROLL_DELAY}
                className={isOpen(CompanyMenuEnum.KnowYourBusiness) ? 'active' : ''}
              >
                {tHtml('menu.kyb')}
              </ScrollspyLinkDelayed>
            </NavItem>
            {isInvestor() && (
              <>
                <NavItem>
                  <ScrollspyLinkDelayed
                    id={'investor-quiz'}
                    onClick={() => openOneCloseOther(CompanyMenuEnum.InvestorQuiz)}
                    delay={SCROLL_DELAY}
                    className={isOpen(CompanyMenuEnum.InvestorQuiz) ? 'active' : ''}
                  >
                    {tHtml('menu.investor_quiz')}
                  </ScrollspyLinkDelayed>
                </NavItem>
                <NavItem>
                  <ScrollspyLinkDelayed
                    id={'financial-risk-calculator'}
                    onClick={() => openOneCloseOther(UserMenuEnum.FinancialRiskCalculator)}
                    delay={SCROLL_DELAY}
                    className={isOpen(UserMenuEnum.FinancialRiskCalculator) ? 'active' : ''}
                  >
                    {tHtml('menu.financial_risk_calculator')}
                  </ScrollspyLinkDelayed>
                </NavItem>
              </>
            )}
            <NavItem>
              <ScrollspyLinkDelayed
                id={'marketing_settings'}
                onClick={() => openOneCloseOther(CompanyMenuEnum.MarketingSettings)}
                delay={SCROLL_DELAY}
                className={isOpen(CompanyMenuEnum.MarketingSettings) ? 'active' : ''}
              >
                {tHtml('menu.marketing_settings')}
              </ScrollspyLinkDelayed>
            </NavItem>
          </Nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ProfileSidebar);
