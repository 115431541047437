import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  generatePath,
  RouteComponentProps,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { History } from 'history';
import { action } from 'typesafe-actions';

import Api from 'api';
import { StoreApplicationRequestDto } from 'api/types/project-developer/application';
import { setGlobalLoading } from 'modules/app/actions';
import { PrimaryButton } from 'components/Formik';
import TextInput from 'components/Formik/TextInput';
import SelectInput, { SelectOption } from 'components/Formik/SelectInput';
import { ApplicationCreditReturnEnum } from 'scopes/developer/helpers/enums/ApplicationCreditReturnEnum';
import { ApplicationTypeEnum } from 'scopes/developer/helpers/enums/ApplicationTypeEnum';
import { ApplicationInterestFrequencyEnum } from 'scopes/developer/helpers/enums/ApplicationInterestFrequencyEnum';
import { ApplicationContext } from './module/ApplicationContext';
import { INITIALIZE_APPLICATION, UPDATE_APPLICATION } from './module/ApplicationReducer';
import { ApplicationPageProps } from './steps';
import SelectCreditDurationInput from 'components/Formik/Selects/SelectCreditDurationInput';

interface IForm {
  project_name: string;
  credit_purpose: string;
  required_amount: string;
  minimal_credit_amount: string;
  credit_return: string;
  interest_frequency: string;
  credit_duration: number | null;
  source_of_funds: string;
}

export const convertToBoolean = (input: string | undefined): boolean | undefined => {
  if (input == undefined) {
    return undefined;
  }

  try {
    return Boolean(JSON.parse(input));
  } catch (e) {
    return undefined;
  }
};

export const redirect = (
  history: History,
  page: string | undefined,
  applicationId: string | number,
): void => {
  if (page != undefined) {
    const path = generatePath(page, { applicationId });
    history.push(path);
  }
};

interface LocationProps {
  state: {
    type: string;
  };
}

const CreateApplication: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();
  const location: LocationProps = useLocation();
  const { state, setApplicationId, dispatch } = useContext(ApplicationContext);

  useEffect(() => {
    if (applicationId !== undefined) {
      setApplicationId(applicationId);
    }
    if (location?.state?.type) {
      dispatch(action(INITIALIZE_APPLICATION, location?.state?.type));
    }

    setGlobalLoading(false);
  }, [t, applicationId, setApplicationId, location?.state?.type, dispatch]);

  const [applicationCreditReturns] = useState<SelectOption[]>(() =>
    Object.values(ApplicationCreditReturnEnum).map((value) => {
      return {
        label: t(`placeholder.credit_return.${value}`),
        value: value,
      };
    }),
  );

  const [applicationInterestFrequencies] = useState<SelectOption[]>(() =>
    Object.values(ApplicationInterestFrequencyEnum).map((value) => {
      return {
        label: t(`placeholder.interest_frequency.${value}`),
        value: value,
      };
    }),
  );

  const [initialFormValues, setInitialFormValues] = useState<IForm>({
    project_name: '',
    credit_purpose: '',
    required_amount: '',
    minimal_credit_amount: '',
    credit_return: '',
    interest_frequency: '',
    credit_duration: 6,
    source_of_funds: '',
  });

  useEffect(() => {
    setInitialFormValues({
      project_name: String(state.application?.project_name ?? ''),
      required_amount: String(state.application?.required_amount ?? ''),
      minimal_credit_amount: String(state.application?.minimal_credit_amount ?? ''),
      credit_return: String(state.application?.credit_return ?? ''),
      interest_frequency: String(state.application?.interest_frequency ?? ''),
      credit_duration: state.application?.credit_duration ?? null,
      source_of_funds: String(state.application?.source_of_funds ?? ''),
      credit_purpose: String(state.application?.credit_purpose ?? ''),
    });
  }, [state]);

  const CreateApplicationSchema = Yup.object().shape({
    project_name: Yup.string().min(1).max(35).required(),
    required_amount: Yup.number().min(0).required(),
    minimal_credit_amount: Yup.number()
      .lessThanOrEqualField('required_amount', t('placeholder.application.required_amount'))
      .min(0)
      .required(),
    credit_return: Yup.string()
      .oneOf(Object.values(ApplicationCreditReturnEnum).map((value) => value))
      .required(),
    interest_frequency: Yup.string()
      .oneOf(Object.values(ApplicationInterestFrequencyEnum).map((value) => value))
      .required(),
    credit_duration: Yup.number().min(1).max(120).required(),
    source_of_funds: Yup.string().min(1).required(),
    credit_purpose:
      state?.application?.type == ApplicationTypeEnum.OTHER
        ? Yup.string().min(1).required()
        : Yup.string().nullable(),
  });

  const formatApplicationStoreRequest = useCallback(
    (request: IForm): StoreApplicationRequestDto => {
      return {
        project_name: request.project_name,
        credit_purpose:
          state?.application?.type == ApplicationTypeEnum.OTHER
            ? request.credit_purpose
            : state?.application?.type,
        required_amount: Number(request.required_amount),
        minimal_credit_amount: Number(request.minimal_credit_amount),
        credit_return: request.credit_return,
        interest_frequency: request.interest_frequency,
        credit_duration: request.credit_duration,
        source_of_funds: request.source_of_funds,
        type: state?.application?.type ?? '',
      } as StoreApplicationRequestDto;
    },
    [state?.application?.type],
  );

  const onSubmit = useCallback(
    async (request: IForm, helpers: FormikHelpers<IForm>) => {
      try {
        const applicationRequest: StoreApplicationRequestDto =
          formatApplicationStoreRequest(request);

        if (applicationId == undefined) {
          await Api.projectDeveloper.applications
            .storeApplication(applicationRequest)
            .then((response) => {
              dispatch(action(UPDATE_APPLICATION, response));
              if (response.id) {
                redirect(history, nextPage, response.id);
              }
            });
        } else {
          await Api.projectDeveloper.applications
            .updateApplication(applicationId, applicationRequest)
            .then((response) => {
              dispatch(action(UPDATE_APPLICATION, response));
              redirect(history, nextPage, applicationId);
            });
        }
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [formatApplicationStoreRequest, applicationId, nextPage, history, dispatch],
  );

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          <h1 className="mb-4 mb-md-6">{tHtml('application.create_application')}</h1>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={CreateApplicationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="mb-3">
                    <TextInput
                      name={'project_name'}
                      placeholder={t('placeholder.application.project_name')}
                    />
                  </div>
                  {state?.application?.type == ApplicationTypeEnum.OTHER && (
                    <div className="mb-3">
                      <TextInput
                        name={'credit_purpose'}
                        placeholder={t('placeholder.application.credit_purpose')}
                      />
                    </div>
                  )}
                  <div className="mb-3">
                    <TextInput
                      name={'required_amount'}
                      type={'number'}
                      placeholder={t('placeholder.application.required_amount')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      name={'minimal_credit_amount'}
                      type={'number'}
                      placeholder={t('placeholder.application.minimal_credit_amount')}
                    />
                  </div>
                  <div className="mb-3">
                    <SelectInput
                      name={'credit_return'}
                      options={applicationCreditReturns}
                      placeholder={tHtml('placeholder.application.credit_return')}
                    />
                  </div>
                  <div className="mb-3">
                    <SelectInput
                      name={'interest_frequency'}
                      options={applicationInterestFrequencies}
                      placeholder={tHtml('placeholder.application.interest_frequency')}
                    />
                  </div>
                  <div className="mb-3">
                    <SelectCreditDurationInput
                      name={'credit_duration'}
                      placeholder={t('placeholder.application.credit_duration')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      name={'source_of_funds'}
                      placeholder={t('placeholder.application.source_of_funds')}
                    />
                  </div>
                  <hr />
                  <div className={'d-flex justify-content-end align-items-center'}>
                    <PrimaryButton className={'btn btn-primary w-25'} submitting={isSubmitting}>
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </div>
      </Row>
    </>
  );
};

export default withRouter(CreateApplication);
