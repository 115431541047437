import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Link } from 'react-router-dom';

import Api from 'api';
import { RouteList } from 'routes';
import { FunctionalButton } from 'components/Button';
import TransactionsTable from './TransactionsTable';
import { PaginationDataFilter } from 'api/types';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import { downloadXMLFile, downloadCSVFile, downloadXLSXFile } from 'services/media';

const TransactionsAll: React.FC = () => {
  const { t, tHtml } = useTranslation();

  const exportCsv = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.investor.transactions.exportAllTransactions(
      CommonDocumentTypeEnum.CSV,
      request,
    );
    await downloadCSVFile(response.url, 'transactions-all');
  };

  const exportXlsx = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.investor.transactions.exportAllTransactions(
      CommonDocumentTypeEnum.XLSX,
      request,
    );
    await downloadXLSXFile(response.url, 'transactions-all');
  };

  const exportXml = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.investor.transactions.exportAllTransactions(
      CommonDocumentTypeEnum.XML,
      request,
    );
    await downloadXMLFile(response.url);
  };

  return (
    <div className={'page-layout'}>
      <div className={'operations'}>
        <div className={'mb-5 d-flex justify-content-start'}>
          <Link to={RouteList.INVESTOR.TRANSACTIONS.LIST}>
            <FunctionalButton>
              <i className={'icon icon-arrow-left'} /> {tHtml('menu.transactions')}
            </FunctionalButton>
          </Link>
        </div>
        <div>
          <TransactionsTable
            onFetch={Api.investor.transactions.fetchAllTransactions}
            title={t('transactions.all_investor')}
            exportCsv={exportCsv}
            exportXlsx={exportXlsx}
            exportXml={exportXml}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionsAll;
