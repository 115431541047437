import React, { ReactElement } from 'react';
import { Collapse } from 'reactstrap';

import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';
import useProfileMenuState from '../pages/Profile/ProfileMenuState';
import { IconArrowDown, IconArrowUp } from 'components/Icons';

interface Props {
  menu: UserMenuEnum;
  title: string | ReactElement;
  buttonLink?: string;
}

const UserProfileSectionMultipleActions: React.FC<Props> = ({ children, menu, title }) => {
  const { toggleProfileMenu, isOpen } = useProfileMenuState();

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div
          className={'d-flex w-100 justify-content-between align-items-center'}
          onClick={() => toggleProfileMenu(menu)}
        >
          <h2 className={'pointer me-2'}>{title}</h2>
          <div>
            <div className="inline">
              {isOpen(menu) ? (
                <IconArrowUp classes={'icon-dropdown'} />
              ) : (
                <IconArrowDown classes={'icon-dropdown'} />
              )}
            </div>
          </div>
        </div>
      </div>
      <Collapse isOpen={isOpen(menu)}>{children}</Collapse>
    </>
  );
};

export default UserProfileSectionMultipleActions;
