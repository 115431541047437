import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Card, Col, ColProps, Row } from 'reactstrap';
import { RouteList } from 'routes';
import { FunctionalButton } from 'components/Button';
import SectionFaq from './components/SectionFaq';
import WalletAmount from 'components/Wallet/WalletAmount';
import MoneyInByBank from './components/MoneyIn/MoneyInByBank';
import MoneyInByCard from './components/MoneyIn/MoneyInByCard';
import MoneyInByBankTransfer from './components/MoneyIn/MoneyInByBankTransfer';
import usePermissions from 'helpers/usePermissions';
import { Permission } from 'helpers/auth/permissions';
import { useAccountQuery } from 'api/queries';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';
import useUserType from 'helpers/useUserType';
import useDisplay from 'helpers/useDisplay';

const colProps: ColProps = {
  lg: 12,
  xl: '',
};

interface BlockDataInterface {
  iconClass: string;
  title: string;
}

const BlockData: React.FC<BlockDataInterface> = ({ iconClass, title }) => (
  <div className={'block'}>
    <div className={'icon-block'}>
      <i className={`icon ${iconClass}`} />
    </div>
    <h3>{title}</h3>
  </div>
);

enum TopUpMethods {
  CARD = 'card',
  BANK_TRANSFER = 'bank-transfer',
  BANK = 'bank',
}

const TopUp: React.FC = () => {
  const { isMobile } = useDisplay();
  const [activeMethod, setActiveMethod] = useState<TopUpMethods | null>(null);
  const { t, tHtml } = useTranslation();
  const { data } = useAccountQuery();
  const p = usePermissions();
  const userType = useUserType();

  const paymentMethods = [
    {
      type: TopUpMethods.CARD,
      icon: 'icon-credit-cards',
      title: t('wallet.pay_by_card'),
      component: <MoneyInByCard />,
    },
    {
      type: TopUpMethods.BANK,
      icon: 'icon-transfer-money',
      title: t('wallet.pay_by_bank'),
      component: <MoneyInByBank />,
      isHidden: !(p.can(Permission.PAY_BY_BANK) && data?.type !== UserTypeEnum.PROJECT_DEVELOPER),
    },
    {
      type: TopUpMethods.BANK_TRANSFER,
      icon: 'icon-transfer-money',
      title: t('wallet.direct_transfer'),
      component: <MoneyInByBankTransfer userType={data && data.type ? data.type : undefined} />,
    },
  ];

  return (
    <div className={'page-layout wallet'}>
      <div className={'mb-5 d-flex justify-content-start'}>
        <Link to={RouteList.SHARED.WALLET.OVERVIEW}>
          <FunctionalButton>
            <i className={'icon icon-arrow-left'} /> {tHtml('common.wallet')}
          </FunctionalButton>
        </Link>
      </div>
      <div>
        <h2>{tHtml('wallet.top_up_wallet')}</h2>
        <div className={'panel mobile'}>
          <div>
            <Card body className={'light-pink balance'}>
              <Row className={'balance-items'}>
                <Col lg={8} md={6} className={'balance-information'}>
                  <WalletAmount />
                  <div className={'balance-description'}>{tHtml('wallet.account_balance')}</div>
                </Col>
              </Row>
            </Card>
            {!isMobile && (
              <Card body className={'promotion-block mt-2'}>
                <Row className={'balance-items'}>
                  <Col lg={12} md={12} className={'balance-information'}>
                    <div className={'balance-description'}>
                      {userType.isInvestor()
                        ? tHtml('wallet.description.investor')
                        : tHtml('wallet.description.project_developer')}
                    </div>
                  </Col>
                </Row>
              </Card>
            )}
          </div>

          <h3 className="choice_title">{t('wallet.choose_top_up_method')}</h3>
          <div className={'affiliate-page promotion-block text-center mt-2'}>
            <Row className={'promotion-block-icons'}>
              {paymentMethods.map((method) => {
                if (method.isHidden === true) return;

                return (
                  <Col key={method.type} {...colProps}>
                    <Card
                      body
                      className={`choice-card ${activeMethod === method.type && 'active'}`}
                      onClick={() => activeMethod !== method.type && setActiveMethod(method.type)}
                    >
                      <div onClick={() => activeMethod === method.type && setActiveMethod(null)}>
                        <BlockData iconClass={method.icon} title={method.title} />
                      </div>

                      {isMobile && activeMethod === method.type && (
                        <div className={'content mobile'}>{method.component}</div>
                      )}
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
          {!isMobile && activeMethod && (
            <div className={'content'}>
              {activeMethod == TopUpMethods.BANK ? <MoneyInByBank /> : null}
              {activeMethod == TopUpMethods.BANK_TRANSFER ? (
                <MoneyInByBankTransfer userType={data && data.type ? data.type : undefined} />
              ) : null}
              {activeMethod == TopUpMethods.CARD ? <MoneyInByCard /> : null}
            </div>
          )}
        </div>
      </div>
      <div className={'wallet-faq-wrapper'}>
        <SectionFaq />
      </div>
    </div>
  );
};

export default withRouter(TopUp);
