import React from 'react';
import { RatingEnum } from 'helpers/enums/RatingEnum';
import Tooltip from 'components/Tooltip';
import useTranslation from 'helpers/useTranslation';

interface Props {
  projectId: string;
  rating?: string;
}

export const newRatingToOldRating = (rating: string) => {
  const converted = Object.values(RatingEnum).find((type) => type == rating);

  switch (converted) {
    case RatingEnum.AAA:
      return 'A+';
    case RatingEnum.AAPLUS:
      return 'A';
    case RatingEnum.AA:
      return 'A-';
    case RatingEnum.AAMINUS:
      return 'B+';
    case RatingEnum.APLUS:
      return 'B';
    case RatingEnum.A:
      return 'B-';
    case RatingEnum.AMINUS:
      return 'C+';
    case RatingEnum.BPLUS:
      return 'C';
    case RatingEnum.B:
      return 'C-';
    case RatingEnum.BMINUS:
      return 'D';
    default:
      return '';
  }
};

const Rating: React.FC<Props> = ({ projectId, rating }) => {
  const { tHtml } = useTranslation();

  if (!rating) {
    return null;
  }

  const input = rating.toUpperCase();

  const getRankingLetter = (r: string): string => {
    switch (r) {
      case 'AAA':
      case 'AA+':
      case 'AA':
        return 'a';
      case 'AA-':
      case 'A+':
      case 'A':
        return 'b';
      case 'A-':
      case 'BBB+':
      case 'BBB':
        return 'c';
      case 'BBB-':
        return 'd';
      default:
        return 'unknown';
    }
  };

  const tooltipId = 'ranking-' + projectId;

  return (
    <div>
      <Tooltip
        id={tooltipId}
        text={tHtml('placeholder.initial_rating.tooltip', {
          old_rating: newRatingToOldRating(rating),
        })}
      />
      <div id={tooltipId} className={`ranking class-${getRankingLetter(input)}`}>
        {input}
      </div>
    </div>
  );
};

export default Rating;
