import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SectionAutoInvestorInformationSkeleton: React.FC = () => (
  <>
    <div className={'section mt-5'} id="auto-invest-strategy-info-skeleton">
      <div className={'panel section__info'}>
        <Skeleton height={84} />
      </div>
    </div>
  </>
);

export default SectionAutoInvestorInformationSkeleton;
