import React, { useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Form, FormGroup } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { StringParam, useQueryParam } from 'use-query-params';
import { useCookies } from 'react-cookie';
import { debounce } from 'lodash';
import { GoogleTagManager } from 'GoogleTagManager';

import Api from 'api';
import { SocialNetworkType, SocialSignUpRequestDto } from 'api/types/guest/social';
import { RegisterUserRequestDto } from 'api/types/guest/authenticate';
import { RouteList } from 'routes';
import { success } from 'services/toastr';
import TextInput from 'components/Formik/TextInput';
import PrimaryButton from 'components/Formik/PrimaryButton';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import SocialSignUpModal from 'components/Modal/Modals/SocialSignUpModal';
import CheckboxInput from 'components/Formik/CheckboxInput';
import PasswordInput from 'components/Formik/PasswordInput';
import { setGlobalLoading } from 'modules/app/actions';
import SocialButton from 'components/Formik/SocialButton';
import { useAuth } from 'services/useAuth';

const NewAccount: React.FC<RouteComponentProps> = ({ history }) => {
  const [cookies, setCookie] = useCookies(['tc_code']);
  const { t, tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { login } = useAuth();
  const [ref] = useQueryParam('ref', StringParam);
  const [affiliateCode, setAffiliateCode] = useState<{ code: string; valid: boolean }>({
    code: '',
    valid: true,
  });

  const [loginRequest, setLoginRequest] = useState<RegisterUserRequestDto>({
    email: '',
    password: '',
    password_confirmation: '',
    tac: false,
    direct_marketing: false,
    affiliate_code: ref!,
    tc_code: null,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setLoginRequest((prevState) => ({
      ...prevState,
      tc_code: cookies.tc_code ?? urlParams.get('click_id'),
    }));
    if (cookies.tc_code == null && loginRequest.tc_code != null) {
      setCookie('tc_code', loginRequest.tc_code);
    }
  }, [loginRequest.tc_code, setCookie, cookies.tc_code]);

  const showSocialModal = useCallback(
    (socialNetworkType: SocialNetworkType) => {
      const initValues: SocialSignUpRequestDto = {
        social_network_type: socialNetworkType,
        token: '',
        tac: false,
        direct_marketing: false,
        affiliate_code: ref!,
        tc_code: loginRequest.tc_code,
      };

      showModal(<SocialSignUpModal initialValues={initValues} />);
    },
    [ref, showModal, loginRequest.tc_code],
  );

  const useDynamicValidationSchema = (affiliateCode: { code: string; valid: boolean }) => {
    return React.useMemo(() => {
      return Yup.object().shape({
        email: Yup.string().email().min(6).max(128).required(),
        password: Yup.string().password().required(),
        password_confirmation: Yup.string().passwordMatch('password').required(),
        tac: Yup.boolean().oneOf([true], t('validation.required')),
        direct_marketing: Yup.boolean(),
        affiliate_code: Yup.string().test(
          'affiliate_code',
          t('validation.invalid'),
          () => affiliateCode.valid,
        ),
      });
    }, [affiliateCode]);
  };

  const schema = useDynamicValidationSchema(affiliateCode);

  const fetchAndValidateAffiliateCode = async (affiliateCode: string) => {
    try {
      if (affiliateCode.length === 0) {
        setAffiliateCode({ code: '', valid: true });
      } else {
        await Api.guest.auth.validateAffiliateCode(affiliateCode);
        setAffiliateCode({ code: affiliateCode, valid: true });
      }
      return true;
    } catch (error) {
      setAffiliateCode({ code: affiliateCode, valid: false });
      return false;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceValidation = useCallback(
    debounce(async (code: string, fn: (field: string, error: string | undefined) => void) => {
      const result = await fetchAndValidateAffiliateCode(code);
      fn('affiliate_code', result ? undefined : t('validation.invalid'));
    }, 500),
    [fetchAndValidateAffiliateCode, t],
  );

  const onSubmit = useCallback(
    async (request: RegisterUserRequestDto, helpers: FormikHelpers<RegisterUserRequestDto>) => {
      try {
        const response = await Api.guest.auth.register(request);

        if (response.access_token) {
          await login(response.access_token, response.permissions);
          success(tHtml('authentication.registration_success'));

          GoogleTagManager.push({
            event: 'acc_created',
          });
          // TODO swap?
          history.push(RouteList.GUEST.AUTHENTICATION.REGISTER_MAIL_VERIFICATION);
        }
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [history, login, tHtml],
  );

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <React.Fragment>
      <div className={'top-title d-flex flex-row'}>
        <h4 className={'me-auto mb-0 align-self-center'}>{tHtml('authentication.register')}</h4>
        <Link className={'align-self-center'} to={RouteList.GUEST.AUTHENTICATION.LOGIN}>
          {tHtml('authentication.login_question')}
        </Link>
      </div>
      <Formik
        initialValues={loginRequest}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          isSubmitting,
          setFieldError,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={'mb-4'}>
              <TextInput name={'email'} />
            </div>
            <div className={'mb-4'}>
              <PasswordInput name={'password'} />
            </div>
            <div className={'mb-4'}>
              <PasswordInput name={'password_confirmation'} />
            </div>
            <div className={'mb-4'}>
              <TextInput
                name={'affiliate_code'}
                onChange={async (e) => {
                  const code = e.target.value;
                  await debounceValidation(code, setFieldError);
                }}
              />
            </div>
            <div className={'mb-4'}>
              <CheckboxInput
                name={'tac'}
                showPlaceholder={true}
                customLabel={<div className={'ms-2'}>{tHtml('placeholder.tac')}</div>}
              />
            </div>
            <FormGroup className={'mb-4'}>
              <CheckboxInput name={'direct_marketing'} showPlaceholder={true} />
            </FormGroup>
            <div className={'mt-6 mb-3'}>
              <PrimaryButton submitting={isSubmitting}>
                {tHtml('authentication.register')}
              </PrimaryButton>
            </div>
            <div className={'mb-3'}>
              <SocialButton
                type={'button'}
                icon={'icon-google'}
                onClick={() => showSocialModal(SocialNetworkType.Google)}
              >
                {tHtml('authentication.register_google')}
              </SocialButton>
            </div>
            <div className={'mb-5'}>
              <SocialButton
                type={'button'}
                icon={'icon-facebook'}
                onClick={() => showSocialModal(SocialNetworkType.Facebook)}
              >
                {tHtml('authentication.register_facebook')}
              </SocialButton>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withRouter(NewAccount);
