import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Cell, Column } from 'react-table';

import Api from 'api';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { RouteList } from 'routes';
import { FunctionalButton } from 'components/Button';
import Table, { AmountFilter, DateFilter } from 'components/Table/Table';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';
import { PaymentsResponseDto } from 'api/types/investor/dashboard';
import PayoutPaymentStatusBadge from 'components/Badge/PayoutPaymentStatusBadge';
import Tooltip from 'components/Tooltip';
import { emptyObject } from 'helpers/utils';
import useDisplay from 'helpers/useDisplay';

const INITIAL_SORT_ORDER = [
  {
    id: 'payment_date',
    desc: false,
  },
];

const PaymentsOverview: React.FC = () => {
  const { isMobile } = useDisplay();
  const { t, tHtml, currentLanguage } = useTranslation();
  const [isLatePayments, setIsLatePayments] = useState<boolean>(false);
  const [lateData, setLateData] = useState<PaginationData<PaymentsResponseDto>>();
  const [upcomingData, setUpcomingData] = useState<PaginationData<PaymentsResponseDto>>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.date'),
        accessor: 'payment_date',
        sortType: 'datetime',
        Filter: DateFilter,
        width: 150,
        Cell: (cell: Cell<PaymentsResponseDto>) => (
          <span>{tHtml('common.date', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.pid'),
        accessor: 'project_name',
        sortType: 'string',
        Cell: (cell: Cell<PaymentsResponseDto>) => {
          return <ProjectTitleLink title={cell.value} projectId={cell.row.original.project_id} />;
        },
      },
      {
        Header: t('table.investor_incoming_payments_type'),
        accessor: 'type',
        sortType: 'string',
        Cell: (cell: Cell<PaymentsResponseDto>) => {
          return <span>{tHtml(`payments.borrower_payouts.type.${cell.value}`)}</span>;
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'amount',
        sortType: 'number',
        Filter: AmountFilter,
        Cell: (cell: Cell<PaymentsResponseDto>) => {
          return (
            <div className="d-flex justify-content-end justify-content-lg-start">
              <PayoutPaymentStatusBadge status={cell.row.original.status} isHiddenText={true} />
              <span>{tHtml('common.money', { value: cell.value })}</span>
            </div>
          );
        },
      },
    ],
    [t, tHtml],
  );

  const fetchUpcomingPaymentsData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.investor.dashboard.fetchUpcomingPayments(request).then((response) => {
        setUpcomingData(response);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage],
  );

  const fetchLatePaymentsData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.investor.dashboard.fetchLatePayments(request).then((response) => {
        setLateData(response);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage],
  );

  return (
    <div className="payments mt-5">
      <div className={'d-flex align-items-center flex-nowrap tab-container justify-content-end'}>
        <div className={'d-flex gap-3 tabs'}>
          <div className="d-flex">
            <button
              disabled={emptyObject(upcomingData)}
              className={`me-1 me-md-2 filter-button ${!isLatePayments ? 'active' : ''} ${
                emptyObject(upcomingData) ? 'filter-button-disabled' : ''
              }`}
              onClick={() => setIsLatePayments(false)}
            >
              {tHtml('dashboard.upcoming_payments')}
            </button>
            {!isLatePayments && (
              <div className="d-flex align-items-center">
                <i className={'icon icon-info ms-1'} id={`upcoming_payments_overview`} />
                <Tooltip
                  id={`upcoming_payments_overview`}
                  text={tHtml('dashboard.upcoming_payments_tooltip')}
                />
              </div>
            )}
          </div>
          <button
            className={`filter-button ${isLatePayments ? 'active' : ''}`}
            onClick={() => setIsLatePayments(true)}
          >
            {tHtml('dashboard.late_payments')}
          </button>
        </div>
      </div>
      <div className={'mt-4'} key={isLatePayments ? 'late' : 'upcoming'}>
        {isLatePayments ? (
          <Table
            columns={columns}
            data={lateData}
            onFetchData={fetchLatePaymentsData}
            showPagination={false}
            initialSortBy={INITIAL_SORT_ORDER}
            defaultLimit={5}
            mobile={true}
            minimizeCard={true}
          />
        ) : (
          <Table
            columns={columns}
            data={upcomingData}
            onFetchData={fetchUpcomingPaymentsData}
            showPagination={false}
            initialSortBy={INITIAL_SORT_ORDER}
            defaultLimit={5}
            mobile={true}
            minimizeCard={true}
          />
        )}
      </div>
      {!isMobile && (
        <div className={'mt-4 d-flex justify-content-end'}>
          {isLatePayments ? (
            <Link to={RouteList.INVESTOR.PAYOUTS.LATE_PAYMENTS}>
              <FunctionalButton>
                {tHtml('common.more')} <i className={'icon icon-arrow-right'} />
              </FunctionalButton>
            </Link>
          ) : (
            <Link to={RouteList.INVESTOR.PAYOUTS.UPCOMING_PAYMENTS}>
              <FunctionalButton>
                {tHtml('common.more')} <i className={'icon icon-arrow-right'} />
              </FunctionalButton>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentsOverview;
