import React, { useState } from 'react';
import { Collapse } from 'reactstrap';

import { FAQOption } from './FAQSection';

const CollapsableBlock: React.FC<FAQOption> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={'collapsable-block'}>
      <div className={'collapsable-block__header'} onClick={() => setIsOpen(!isOpen)}>
        <h2 className={'d-flex justify-content-between align-items-center'}>
          <span className={'pe-4'}>{title}</span>
          <span>
            {isOpen ? (
              <i className={'icon icon-arrow-up'} />
            ) : (
              <i className={'icon icon-arrow-down'} />
            )}
          </span>
        </h2>
      </div>
      <Collapse isOpen={isOpen} className={'collapsable-block__content'}>
        <div className={'pb-4'}>
          <p>{content}</p>
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsableBlock;
