import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Col, Row } from 'reactstrap';

import NotificationsSidebar from './NotificationsSidebar';
import Notification from './Notification';
import { useNotifications } from './NotificationsProvider';
import NotificationSkeleton from './NotificationSkeleton';

const NotificationsList: React.FC = () => {
  const { tHtml } = useTranslation();
  const { selectedNotification, isLoading, markAllRead } = useNotifications();

  return (
    <div className={'mb-6'}>
      <div className={'mb-2 d-flex justify-content-between align-items-center'}>
        <h2 className={'m-0'}>{tHtml('common.notifications')}</h2>
        {markAllRead && (
          <a onClick={() => markAllRead()} className="small">
            {tHtml('menu.mark_all_read')}
          </a>
        )}
      </div>
      <Row className={'notifications-layout'}>
        <Col md={12} lg={3} className={'ps-0 pe-0'}>
          <NotificationsSidebar />
        </Col>
        <Col sm={12} md={8} lg={9} className={'d-none d-lg-block'}>
          {isLoading && <NotificationSkeleton />}
          {!isLoading && selectedNotification && <Notification {...selectedNotification} />}
        </Col>
      </Row>
    </div>
  );
};

export default NotificationsList;
