import useDisplay from 'helpers/useDisplay';
import React, { ReactElement } from 'react';

interface Props {
  title: string | ReactElement;
  withDivider?: boolean;
}

const PageHeader: React.FC<Props> = ({ children, title, withDivider = true }) => {
  const { isApp, isMobile } = useDisplay();
  return (
    <>
      <div className={'page-header'}>
        {!(isApp && isMobile) && <h1 className={'page-header__title'}>{title}</h1>}
        {children}
      </div>
      {withDivider && !(isApp && isMobile && !children) && (
        <hr className={'page-header__divider'} />
      )}
    </>
  );
};

export default PageHeader;
