import { ErrorMessage, useField, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import useTranslation from 'helpers/useTranslation';
import RadioInput from './RadioInput';

export interface Props
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'placeholder'
  > {
  name: string;
  values: string[];
  showPlaceholder: boolean;
  placeholder?: string | ReactElement;
  placeholderValuesPrefix?: string;
}

const RadioInputGroup: React.FC<Props> = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const [, meta] = useField(props.name);
  const formik = useFormikContext();

  if (!props.placeholder) {
    props.placeholder = tHtml('placeholder.' + props.name);
  }

  const isError = meta?.touched && !!meta?.error && formik.submitCount > 0;

  const radios = props.values.map((value) => (
    <div className={meta.error ? 'is-invalid' : ''} key={value}>
      <RadioInput
        key={value}
        name={props.name}
        value={value}
        showPlaceholder={true}
        placeholderPrefix={props.placeholderValuesPrefix}
      />
    </div>
  ));

  return (
    <div className={`form-input`}>
      {props.showPlaceholder && (
        <label className={'form-question-label'} htmlFor={props.name}>
          {props.placeholder}
        </label>
      )}
      {radios}
      {isError && (
        <div className={'invalid-feedback ms-2'}>
          {props.name && <ErrorMessage name={props.name} />}
        </div>
      )}
    </div>
  );
};

export default RadioInputGroup;
