import React from 'react';
import { RouteList } from 'routes';
import * as Roles from 'helpers/auth/roles';
import AuthRoute from 'routes/AuthRoute';
import { EditProfileLayout } from 'scopes/company/containers';
import KYB from './KYB';
import { ContactInfo, MarketingSettings, CompanyFinancialRiskCalculator } from '.';

const EditProfileRoutes: any = () => [
  <AuthRoute
    path={RouteList.COMPANY.EDIT.CONTACT_INFO}
    component={ContactInfo}
    layout={EditProfileLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.COMPANY.EDIT.CONTACT_INFO}
    exact
  />,
  <AuthRoute
    path={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}
    component={KYB}
    layout={EditProfileLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.FINANCIAL_RISK_CALCULATOR.VIEW}
    component={CompanyFinancialRiskCalculator}
    layout={EditProfileLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.INVESTOR.FINANCIAL_RISK_CALCULATOR.VIEW}
    exact
  />,
  <AuthRoute
    path={RouteList.COMPANY.EDIT.MARKETING_SETTINGS}
    component={MarketingSettings}
    layout={EditProfileLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.COMPANY.EDIT.MARKETING_SETTINGS}
    exact
  />,
];

export default EditProfileRoutes;
