import React from 'react';

import { RouteList } from 'routes';
import PageLayout from 'scopes/developer/containers/PageLayout';
import AuthRoute from 'routes/AuthRoute';
import * as Roles from 'helpers/auth/roles';
import { Payments } from './index';
import PartialReturn from './PartialReturn';

const PaymentRoutes: any = () => [
  <AuthRoute
    path={RouteList.PROJECT_DEVELOPER.PAYMENTS.VIEW}
    component={Payments}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.PROJECT_DEVELOPER.PAYMENTS.VIEW}
    exact
  />,
  <AuthRoute
    path={RouteList.PROJECT_DEVELOPER.PAYMENTS.PARTIAL_RETURN}
    component={PartialReturn}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.PROJECT_DEVELOPER.PAYMENTS.PARTIAL_RETURN}
    exact
  />,
];

export default PaymentRoutes;
