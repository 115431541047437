import React, { createContext, useContext, useMemo, useState } from 'react';

const RenderContext = createContext<{
  render: Record<string, any>;
  setRender: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}>({
  render: {},
  setRender: () => {
    return;
  },
});

export const SALE_LIST = 'sale_list';
export const ACTIVE_INVESTMENTS_LIST = 'active_investments_list';

export const RenderProvider: React.FC = (props) => {
  const [render, setRender] = useState<Record<string, any>>({
    [SALE_LIST]: 0,
    [ACTIVE_INVESTMENTS_LIST]: 0,
  });

  return (
    <RenderContext.Provider value={{ render, setRender }}>{props.children}</RenderContext.Provider>
  );
};

interface RenderControl {
  rerender: () => void;
  count: number;
}

export const useRender = (key: string): RenderControl => {
  const { render, setRender } = useContext(RenderContext);

  return useMemo<RenderControl>(
    () => ({
      rerender: () => {
        setRender({ ...render, [key]: render[key] + 1 });
      },
      count: render[key],
    }),
    // eslint-disable-next-line
    [render, setRender],
  );
};

export default useRender;
