import Extensions from 'helpers/data/file-extensions.json';
import { useTranslation } from 'react-i18next';
import { format, intervalToDuration } from 'date-fns';
import { TranslatableValueDto } from '../api/types/common';
import { LanguageEnum } from './enums/LanguageEnum';
import i18n from 'services/i18n';

export const parseJwt = (token: string | null): any | null => {
  if (token === null) return null;

  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
};

export const delay = (time = 500): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const mimeTypeToExtension = (mimeType: string): string => {
  const key = (Object.keys(Extensions) as Array<keyof typeof Extensions>).find(
    (key) => Extensions[key] === mimeType,
  );

  if (key) {
    return key;
  }

  return mimeType;
};

export const resolveTranslatableValueDto = (value: TranslatableValueDto<string>): string => {
  const locale = i18n.resolvedLanguage ?? 'lt';

  return value[locale as LanguageEnum];
};

export const useDifferenceBetweenTwoDatesToString = () => {
  const { t } = useTranslation();

  return (firstDate: string, secondDate: string | null = null): string => {
    const oldDate = new Date(firstDate);
    const currentDate = secondDate ? new Date(secondDate) : new Date();

    const interval = intervalToDuration({ start: oldDate, end: currentDate });

    const result: string[] = [];

    if (interval.years !== 0) {
      result.push(t('investments.late_format.only_years', { years: interval.years }));
    }

    if (interval.months !== 0) {
      result.push(t('investments.late_format.only_months', { months: interval.months }));
    }

    if (interval.days !== 0) {
      result.push(t('investments.late_format.days', { days: interval.days }));
    }

    return result.join(' ');
  };
};

export const dateTimeToDate = (date: Date | null): string | null => {
  return date ? format(date, 'yyyy-MM-dd') : null;
};

export const emptyObject = (obj: any): boolean => {
  if (obj === null || obj === undefined) return true;

  return Object.keys(obj).length === 0;
};

export const responseToCsv = (response: any, fileName: string) => {
  const blob = new Blob(
    [
      // new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8-BOM encoding for excel
      response,
    ],
    {
      type: 'application/octet-stream',
    },
  );

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
};

export const responseToPdf = (response: any, fileName: string) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(response);
  link.download = fileName;
  link.click();
};

export const notZero = (value: number | null): boolean => value != null && value != 0;

export const handleDecimalsOnValue = (value: string) => {
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,1})/s;
  return value.match(regex)?.[0] ?? value;
};

export const handleDecimalsTensOnValue = (value: string) => {
  const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
  return value.match(regex)?.[0] ?? value;
};
