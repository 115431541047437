import React, { ReactElement } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { RouteList } from 'routes';
import useUserType from 'helpers/useUserType';
import useDisplay from 'helpers/useDisplay';

interface Props {
  title: string | ReactElement;
  projectId: string;
  privateId?: string;
  isTitleHighlighted?: boolean;
}

const ProjectTitleLink: React.FC<Props> = ({ title, projectId, isTitleHighlighted = true }) => {
  const userType = useUserType();
  const { isApp } = useDisplay();

  const getLinkToProject = (id: string) => {
    if (userType.isInvestor()) {
      return generatePath(RouteList.INVESTOR.PROJECTS.VIEW, {
        projectId: id,
      });
    }

    if (userType.isDeveloper()) {
      return generatePath(RouteList.PROJECT_DEVELOPER.PROJECTS.VIEW, {
        projectId: id,
      });
    }

    return '#';
  };

  if (isApp) {
    return (
      <Link
        to={{
          pathname: getLinkToProject(projectId),
        }}
        className={'secondary-market-table-button'}
      >
        <span className={isTitleHighlighted ? 'text-highlight' : ''}>{title}</span>
      </Link>
    );
  }

  return (
    <Link
      to={{
        pathname: getLinkToProject(projectId),
      }}
      target={'_blank'}
      rel={'noopener noreferrer'}
      className={'secondary-market-table-button'}
    >
      <span className={isTitleHighlighted ? 'text-highlight' : ''}>{title}</span>
    </Link>
  );
};

export default ProjectTitleLink;
