import React, { ReactElement } from 'react';
import CheckboxInputGroup from '../CheckboxGroup/CheckboxInputGroup';

interface Props {
  placeholder?: string | ReactElement;
}

const SelectInvestmentPurposeInput: React.FC<Props> = ({ placeholder }) => {
  const purposeValues = ['invest_into_loans', 'invest_into_securities', 'other'];

  return (
    <CheckboxInputGroup
      name={'purpose'}
      values={purposeValues}
      valuesWithText={['other']}
      showPlaceholder={true}
      placeholder={placeholder}
    />
  );
};

export default SelectInvestmentPurposeInput;
