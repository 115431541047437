import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import useDisplay from 'helpers/useDisplay';
import { InvestorProjectsWithTransferableInvestmentsInvestmentResponse } from 'api/types/investor/project';
import InvestorTransferInvestmentsModal from '../../../modals/transferableInvestments/InvestorTransferInvestmentsModal';

interface Props {
  data: {
    project_id: string;
    project_pid: string;
    project_name: string;
    refinancing_project_name: string;
    refinancing_project_id: string;
    refinancing_project_pid: string;
    investment: Array<{ amount: number; id: string }>;
    onTransfer: () => void;
  };
}

const TransferInvestment: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const { isMobile } = useDisplay();

  return isMobile ? (
    <button
      onClick={() =>
        showModal(
          <InvestorTransferInvestmentsModal
            project_id={data.project_id}
            project_pid={data.project_pid}
            project_name={data.project_name}
            refinancing_project_pid={data.refinancing_project_pid}
            refinancing_project_id={data.refinancing_project_id}
            refinancing_project_name={data.refinancing_project_name}
            investments={
              data.investment as InvestorProjectsWithTransferableInvestmentsInvestmentResponse[]
            }
            onTransfer={data.onTransfer}
          />,
        )
      }
      className="btn btn-functional mt-4"
    >
      {t('investments.transfer_investment')}
    </button>
  ) : (
    <div>
      <span
        id={'t-' + data.project_id}
        onClick={() =>
          showModal(
            <InvestorTransferInvestmentsModal
              project_id={data.project_id}
              project_pid={data.project_pid}
              project_name={data.project_name}
              refinancing_project_name={data.refinancing_project_name}
              refinancing_project_pid={data.refinancing_project_pid}
              refinancing_project_id={data.refinancing_project_id}
              investments={
                data.investment as InvestorProjectsWithTransferableInvestmentsInvestmentResponse[]
              }
              onTransfer={data.onTransfer}
            />,
          )
        }
      >
        <i className="icon icon-right-left cursor" />
      </span>
    </div>
  );
};

export default TransferInvestment;
