import React from 'react';
import useTranslation from 'helpers/useTranslation';

import { DocumentResponseDto } from 'api/types/app';
import { mimeTypeToExtension } from 'helpers/utils';
import DownloadButtonAuthorized from 'components/DownloadButtonAuthorized';

interface Props {
  files: DocumentResponseDto[];
  projectRisks: DocumentResponseDto | null;
}

// TODO: add download blob functionality
const Files: React.FC<Props> = ({ files, projectRisks }) => {
  const { tHtml } = useTranslation();

  const allFiles = projectRisks ? [projectRisks, ...files] : files;

  return (
    <>
      <div className={'files'}>
        {allFiles.map((item, index) => (
          <div className={'files-item'} key={index}>
            <div className={'files-icon'}>
              <i className={'icon icon-download-file'} />
            </div>
            <div className={'data-block'}>
              <div className={'title'}>
                <DownloadButtonAuthorized url={item.url}>{item.name}</DownloadButtonAuthorized>
              </div>
              <div className={'text'}>
                {mimeTypeToExtension(item.mime_type)} /{' '}
                {tHtml('common.bytes', { value: item.size })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Files;
