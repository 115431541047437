import Client from '../client';
import Http from '../../http';
import { PaginationData, PaginationDataFilter } from '../../types';
import {
  ProjectDeveloperInvoiceListResponseDto,
  ProjectDeveloperBorrowerPayoutListResponseDto,
  ProjectDeveloperPartialReturnCalculationResponseDto,
  ProjectDeveloperPartialReturnProjectResponseDto,
  ProjectDeveloperPartialReturnRequestDto,
} from '../../types/project-developer/payment';
import { ProjectBriefResponseDto } from '../../types/investor/project';

class ProjectDeveloperPayment extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_ALL_UPCOMING_PAYMENTS: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/payments/borrower-payouts/filter`,
      ),
      MAKE_PAYMENT: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/payments/borrower-payouts/borrower-payout/{borrowerPayoutId}/pay`,
      ),
      FETCH_FILTERED_INVOICES: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/payments/invoices/filter`,
      ),
      MAKE_INVOICE_PAYMENT: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/payments/invoices/invoice/{invoiceId}/pay`,
      ),
      FETCH_PROJECTS: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/payments/partial-return/projects`,
      ),
      FETCH_PROJECT: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/payments/partial-return/projects/project/{projectId}`,
      ),
      FETCH_CALCULATION: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/payments/partial-return/projects/project/{projectId}/calculation`,
      ),
      PAY_PARTIAL_RETURN: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/payments/partial-return/projects/project/{projectId}/pay`,
      ),
    };
  }

  filterUpcomingPayments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ProjectDeveloperBorrowerPayoutListResponseDto>> => {
    return this.http.get(this.api.FETCH_ALL_UPCOMING_PAYMENTS, { params: request });
  };

  makePayment = (borrowerPayoutId: string): Promise<any> => {
    const url = this.buildUrl(this.api.MAKE_PAYMENT, { borrowerPayoutId });
    return this.http.put(url);
  };

  filterInvoices = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ProjectDeveloperInvoiceListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_INVOICES, { params: request });
  };

  makeInvoicePayment = (invoiceId: string): Promise<any> => {
    const url = this.buildUrl(this.api.MAKE_INVOICE_PAYMENT, { invoiceId });
    return this.http.put(url);
  };

  fetchProjects = (): Promise<ProjectBriefResponseDto[]> => {
    return this.http.get(this.api.FETCH_PROJECTS);
  };

  fetchProject = (projectId: string): Promise<ProjectDeveloperPartialReturnProjectResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT, { projectId });
    return this.http.get(url);
  };

  fetchCalculation = (
    projectId: string,
    request: ProjectDeveloperPartialReturnRequestDto,
  ): Promise<ProjectDeveloperPartialReturnCalculationResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_CALCULATION, { projectId });
    return this.http.get(url, { params: request });
  };

  payPartialReturn = (
    projectId: string,
    request: ProjectDeveloperPartialReturnRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.PAY_PARTIAL_RETURN, { projectId });
    return this.http.put(url, request);
  };
}

export default ProjectDeveloperPayment;
