import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { createBrowserHistory } from 'history';
import { Cookies, CookiesProvider } from 'react-cookie';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { enableSentry } from './helpers/sentry';
import ScrollToTop from './components/ScrollToTop';
import { QueryClient, QueryClientProvider } from 'react-query';

enableSentry();

export const history = createBrowserHistory();
export const cookies = new Cookies();
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CookiesProvider cookies={cookies}>
        <Router history={history}>
          <ScrollToTop />
          <QueryParamProvider ReactRouterRoute={Route}>
            <App />
          </QueryParamProvider>
        </Router>
      </CookiesProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
