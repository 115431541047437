export enum UserMenuEnum {
  ContactInfo = 'contactInfo',
  IdVerification = 'idVerification',
  KnowYourCustomer = 'knowYourCustomer',
  Password = 'password',
  Security = 'security',
  MarketingSettings = 'marketingSettings',
  InvestorQuiz = 'investorQuiz',
  FinancialRiskCalculator = 'financialRiskCalculator',
}
