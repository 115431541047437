import React from 'react';
import { useFormikContext } from 'formik';

import { FileStateEnum, IFile } from './module/FileUploadReducer';

interface Props {
  file: IFile;
  onRemove: () => void;
  isDirect?: boolean;
  disableLoadSpinner?: boolean;
}

const FileUploadListItem: React.FC<Props> = ({
  file,
  onRemove,
  isDirect = false,
  disableLoadSpinner = false,
}) => {
  const { isSubmitting } = useFormikContext();

  const isUploading = (fileState: FileStateEnum) =>
    isDirect ? isSubmitting : fileState == FileStateEnum.Uploading || isSubmitting;

  const isUploaded = (fileState: FileStateEnum) =>
    isDirect ? !isSubmitting : fileState == FileStateEnum.Uploaded && !isSubmitting;

  return (
    <>
      <div className={'justify-content-center d-flex align-items-center pb-1'}>
        <span className={'me-auto'}>{file.name}</span>

        {isUploading(file.state) && !disableLoadSpinner && (
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        {isUploaded(file.state) && (
          <i
            className={'icon-close'}
            onClick={() => {
              if (!isSubmitting) {
                onRemove();
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export default FileUploadListItem;
