import styled from 'styled-components';

export const ButtonsDiv = styled.div`
  width: 26%;
`;

export const RealEstateTypeDiv = styled.div`
  width: 50%;
  padding-right: 0.5rem;
`;

export const RealEstatePlotDiv = styled.div`
  width: 24%;
  padding-right: 0.5rem;
`;
