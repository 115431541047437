import React, { useCallback, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { ErrorMessage, useField } from 'formik';
import ScrollToOnError from '../../ScrollToOnError';
import useTranslation from 'helpers/useTranslation';

interface Props {
  name: string;
  label?: string;
  dependencyFieldName?: string;
}

const PhoneNumberInput: React.FC<Props> = ({
  name,
  label,
  dependencyFieldName = '',
}): JSX.Element => {
  const [field, meta, helper] = useField(name);
  const [depField] = useField(dependencyFieldName);
  const { currentLanguage } = useTranslation();

  useEffect(() => {
    if (field.value) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const onChange = useCallback(
    (v: string) => {
      helper.setValue(v);
    },
    [helper],
  );

  return (
    <>
      <div className={`form-label-group in-border site-input ${meta.error ? 'is-invalid' : ''}`}>
        <ScrollToOnError name={name} isError={!!meta?.error ?? false}>
          <label style={{ top: '-10px', fontSize: '0.7rem' }}>{label}</label>
          <PhoneInput
            onChange={onChange}
            value={field.value || ''}
            onBlur={() => helper.setTouched(true, true)}
            country={
              typeof depField.value == 'string' && depField.value !== ''
                ? depField.value
                : currentLanguage
            }
            preferredCountries={['lt', 'gb', 'de', 'ee']}
            masks={{ lt: '... .. ...' }}
          />
        </ScrollToOnError>
        {meta.error && (
          <div className={'invalid-feedback text-pre'} style={{ display: 'block' }}>
            <ErrorMessage
              component={'div'}
              name={name}
              render={(msg: string | string[]) => {
                if (typeof msg === 'string') {
                  return msg;
                }

                return msg.join('\n');
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PhoneNumberInput;
