import { RefObject, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getFirstErrorKey = (object, keys: string[] = []) => {
  if (!object) return '';
  const firstErrorKey = Object.keys(object)[0];
  if (typeof object[firstErrorKey] === 'object' && object[firstErrorKey] !== null) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join('.');
};

export const useScrollToOnError = (scrollable: RefObject<HTMLDivElement>, name: string): void => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = getFirstErrorKey(formik.errors);

  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (scrollable.current && firstErrorKey === name) {
        scrollable.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
      prevSubmitCountRef.current = formik.submitCount;
    }
  }, [formik.submitCount, formik.isValid, firstErrorKey, scrollable, name]);
};
