import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { PrimaryButton } from 'components/Formik';
import ModalCloseButton from 'components/Modal/ModalCloseButton';

const ChangeResidenceModal: React.FC<ModalComponentProps> = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  return (
    <React.Fragment>
      <ModalComponent size={'lg'} {...props}>
        <div className={'p-2 p-lg-5'}>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />} />
          <ModalBody>
            <p className={'text-pre'}>{tHtml('profile.edit.instructions_to_change_residence')}</p>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-center'}>
            <div className={'w-50'}>
              <PrimaryButton onClick={hideModal}>{tHtml('common.close')}</PrimaryButton>
            </div>
          </ModalFooter>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ChangeResidenceModal;
