import { toast, ToastContent, ToastOptions } from 'react-toastify';
import React, { CSSProperties } from 'react';
import i18n from 'i18next';
import { TypeOptions } from 'react-toastify/dist/types';
import Notification, { NotificationLevel } from 'components/Notification';
import * as H from 'history';

const DefaultBodyStyle: CSSProperties = {
  padding: 0,
  background: 'transparent',
  borderRadius: 'unset',
  margin: 0,
};

const DefaultStyle: CSSProperties = {
  padding: 0,
  background: 'transparent',
  borderRadius: '8px',
  margin: 0,
};

const DefaultOptions: ToastOptions = {
  icon: false,
  closeOnClick: false,
  closeButton: false,
  bodyStyle: DefaultBodyStyle,
  style: DefaultStyle,
};

export function success(content: ToastContent): void {
  openToast(content, 'success');
}

export function warning(
  content: ToastContent,
  cta?: string,
  ctaAction?: (history: H.History) => void,
): void {
  openToast(content, 'warning', cta, ctaAction);
}

export function error(content: ToastContent): void {
  openToast(content, 'error');
}

function openToast(
  content: ToastContent,
  type: TypeOptions,
  cta?: string,
  ctaAction?: (history: H.History) => void,
): void {
  toast(
    (props) => (
      <Notification
        text={content}
        level={type as NotificationLevel}
        cta={cta ?? i18n.t('common.close')}
        ctaAction={ctaAction ?? props.closeToast}
      />
    ),
    {
      type: type,
      ...DefaultOptions,
    },
  );
}
