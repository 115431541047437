import Client from 'api/services/client';
import Http from 'api/http';
import { TrackVisitRequestDto } from 'api/types/shared/user/profile';

class TrackVisits extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      TRACK_VISIT: this.protectedUrl(`${this.http.baseUrl}/visit`),
    };
  }

  trackVisit = (data: TrackVisitRequestDto): Promise<any> => {
    return this.http.post(this.api.TRACK_VISIT, data);
  };
}

export default TrackVisits;
