import Http from './http';
import S3 from './services/common/s3';
import Media from './services/common/media';
import InvestorProject from './services/investor/project';
import ProjectDeveloperProject from './services/project-developer/project';
import ProjectDeveloperDashboard from './services/project-developer/dashboard';
import GuestProject from './services/guest/project';
import GuestAuth from './services/guest/auth';
import GuestSocial from './services/guest/social';
import SharedUserSignUpSteps from './services/shared/user/signUpSteps';
import SharedCompanyCurrent from './services/shared/company/current';
import SharedCompanyProfile from './services/shared/company/profile';
import SharedUserCurrent from './services/shared/user/current';
import SharedUserProfile from './services/shared/user/profile';
import SharedLoginAs from './services/shared/login-as';
import SharedUserCompany from './services/shared/user/company';
import SharedProject from './services/shared/project';
import InvestorQuiz from './services/investor/quiz';
import InvestorDocument from './services/investor/document';
import ProjectDeveloperDocument from './services/project-developer/document';
import ProjectDeveloperApplication from './services/project-developer/application';
import InvestorDashboard from './services/investor/dashboard';
import Notifications from './services/common/notifications';
import Wallet from './services/common/wallet';
import InvestorInvestment from './services/investor/investment';
import InvestorSecondaryMarket from './services/investor/secondary_market';
import InvestorBonusSystem from './services/investor/bonus_system';
import Countries from './services/common/countries';
import InvestorTransaction from './services/investor/transaction';
import ProjectDeveloperTransaction from './services/project-developer/transaction';
import InvestorAffiliate from './services/investor/affiliate';
import ProjectDeveloperPayment from './services/project-developer/payment';
import SharedAuthenticatedAccount from './services/shared/authenticatedAccount';
import TrackVisits from './services/common/trackVisits';
import ProspectApplication from './services/guest/prospectApplication';

export const http = new Http();
export { http as Http };

const Api = {
  investor: {
    quiz: new InvestorQuiz(http),
    projects: new InvestorProject(http),
    documents: new InvestorDocument(http),
    dashboard: new InvestorDashboard(http),
    investments: new InvestorInvestment(http),
    transactions: new InvestorTransaction(http),
    affiliate: new InvestorAffiliate(http),
    secondary_market: new InvestorSecondaryMarket(http),
    bonus_system: new InvestorBonusSystem(http),
  },
  projectDeveloper: {
    projects: new ProjectDeveloperProject(http),
    documents: new ProjectDeveloperDocument(http),
    dashboard: new ProjectDeveloperDashboard(http),
    applications: new ProjectDeveloperApplication(http),
    transactions: new ProjectDeveloperTransaction(http),
    payments: new ProjectDeveloperPayment(http),
  },
  guest: {
    projects: new GuestProject(http),
    auth: new GuestAuth(http),
    social: new GuestSocial(http),
    prospect_application: new ProspectApplication(http),
  },
  shared: {
    user: {
      current: new SharedUserCurrent(http),
      signUpSteps: new SharedUserSignUpSteps(http),
      profile: new SharedUserProfile(http),
      company: new SharedUserCompany(http),
    },
    company: {
      current: new SharedCompanyCurrent(http),
      profile: new SharedCompanyProfile(http),
    },
    loginAs: new SharedLoginAs(http),
    projects: new SharedProject(http),
    authenticatedAccount: new SharedAuthenticatedAccount(http),
  },
  // auth: new Auth(http),
  // user: new User(http),
  // userProfile: new UserProfile(http),
  // company: new Company(http),
  // companyProfile: new CompanyProfile(http),
  // companyDocument: new CompanyDocument(http),
  // creating fresh http client, because we dont need to reuse authorization and other stuff that's used by app
  s3: new S3(),
  media: new Media(http),
  notifications: new Notifications(http),
  trackVisits: new TrackVisits(http),
  wallet: new Wallet(http),
  countries: new Countries(http),
};

export default Api;
