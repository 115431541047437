export { useAccountQuery } from './useAccountQuery';

const queries = {
  account: 'account',
  profile: 'profile',
  notifications: 'notifications',
  wallet: 'wallet',
  statistics: 'statistics',
  projects: 'projects',
} as const;

type Queries = typeof queries[keyof typeof queries];

export { queries };
export type { Queries };
