import { formatDistanceToNow } from 'date-fns';
import { de, enGB, lt, et } from 'date-fns/locale';
import i18n from 'i18next';
import { LanguageEnum } from './enums/LanguageEnum';

export default function (date: string): string {
  const locale = i18n.resolvedLanguage ?? 'lt';
  let loc = lt;
  if (locale === LanguageEnum.LT) {
    loc = lt;
  } else if (locale === LanguageEnum.EN) {
    loc = enGB;
  } else if (locale === LanguageEnum.DE) {
    loc = de;
  } else if (locale === LanguageEnum.EE) {
    loc = et;
  }

  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
    locale: loc,
  });
}
