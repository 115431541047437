import Client from '../client';
import Http from '../../http';
import {
  BonusSystemBriefRequestDto,
  BonusSystemBriefResponseDto,
  BonusSystemListResponseDto,
  WithdrawalEventSettingsDto,
} from 'api/types/investor/bonus_system';
import { PaginationData, PaginationDataFilter } from 'api/types';

class InvestorBonusSystem extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_ALL_USERS_BONUS_SYSTEM: this.protectedUrl(`${this.http.baseUrl}/investor/bonus-system`),
      FETCH_BONUS_SYSTEM_BRIEF: this.protectedUrl(
        `${this.http.baseUrl}/investor/bonus-system/brief`,
      ),
      FETCH_WITHDRAWAL_EVENT: this.protectedUrl(
        `${this.http.baseUrl}/investor/bonus-system/withdrawal-event-settings`,
      ),
      REWARD_WITHDRAWAL_BONUS: this.protectedUrl(
        `${this.http.baseUrl}/investor/bonus-system/reward-withdrawal-bonus`,
      ),
    };
  }

  fetchFilteredBonusSystem = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<BonusSystemListResponseDto>> => {
    return this.http.get(this.api.FETCH_ALL_USERS_BONUS_SYSTEM, { params: request });
  };

  fetchBonusSystemBrief = (
    request: BonusSystemBriefRequestDto | undefined = undefined,
  ): Promise<Array<BonusSystemBriefResponseDto>> => {
    return this.http.get(this.api.FETCH_BONUS_SYSTEM_BRIEF, { params: request });
  };

  fetchWithdrawalEventSettings = (): Promise<WithdrawalEventSettingsDto> => {
    return this.http.get(this.api.FETCH_WITHDRAWAL_EVENT);
  };

  rewardWithdrawalBonus = (): Promise<any> => {
    return this.http.put(this.api.REWARD_WITHDRAWAL_BONUS);
  };
}

export default InvestorBonusSystem;
