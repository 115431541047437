import React from 'react';
import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import * as Roles from 'helpers/auth/roles';
import { AuthenticationLayout } from 'scopes/guest/containers';
import Login from './Login';
import NewAccount from './Registration/NewAccount';
import ResetPassword from './ResetPassword/ResetPassword';
import ConfirmResetPassword from './ResetPassword/ConfirmResetPassword';

const AuthenticationRoutes: any = () => [
  <AuthRoute
    path={RouteList.GUEST.AUTHENTICATION.LOGIN}
    component={Login}
    layout={AuthenticationLayout}
    permissions={Roles.Guest.permissions}
    redirect={Roles.Guest.redirect}
    key={RouteList.GUEST.AUTHENTICATION.LOGIN}
    exact
  />,
  <AuthRoute
    path={RouteList.GUEST.AUTHENTICATION.RESET_PASSWORD}
    component={ResetPassword}
    layout={AuthenticationLayout}
    permissions={Roles.Guest.permissions}
    redirect={Roles.Guest.redirect}
    key={RouteList.GUEST.AUTHENTICATION.RESET_PASSWORD}
    exact
  />,
  <AuthRoute
    path={RouteList.GUEST.AUTHENTICATION.CONFIRM_RESET_PASSWORD}
    component={ConfirmResetPassword}
    layout={AuthenticationLayout}
    permissions={Roles.Guest.permissions}
    redirect={Roles.Guest.redirect}
    key={RouteList.GUEST.AUTHENTICATION.CONFIRM_RESET_PASSWORD}
    exact
  />,
  <AuthRoute
    path={RouteList.GUEST.AUTHENTICATION.REGISTER}
    component={NewAccount}
    layout={AuthenticationLayout}
    permissions={Roles.Guest.permissions}
    redirect={Roles.Guest.redirect}
    key={RouteList.GUEST.AUTHENTICATION.REGISTER}
    exact
  />,
];

export default AuthenticationRoutes;
