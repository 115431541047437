import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Cell, Column } from 'react-table';
import { generatePath, Link } from 'react-router-dom';

import Api from 'api';
import { RouteList } from 'routes';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { ApplicationListResponseDto } from 'api/types/project-developer/application';
import { TableInitialSortBy } from 'api/types/common';
import { ApplicationStatusEnum } from 'scopes/developer/helpers/enums/ApplicationStatusEnum';
import TableExpandable from 'components/Table/TableExpandable';
import { PrimaryButton } from 'components/Button';

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'created_at',
    desc: true,
  },
];

const ApplicationList: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const [data, setData] = useState<PaginationData<ApplicationListResponseDto>>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.developer.application_list.pid'),
        accessor: 'pid',
        sortType: 'string',
      },
      {
        Header: t('table.developer.application_list.project_name'),
        accessor: 'project_name',
        sortType: 'string',
      },
      {
        Header: t('table.developer.application_list.type'),
        accessor: 'type',
        sortType: 'string',
        Cell: (cell: Cell<ApplicationListResponseDto>) => {
          return <span>{tHtml('application.type.' + cell.value)}</span>;
        },
      },
      {
        Header: t('table.developer.application_list.required_amount'),
        accessor: 'required_amount',
        sortType: 'number',
        Cell: (cell: Cell<ApplicationListResponseDto>) => {
          if (cell.value) {
            return <span>{cell.value ? tHtml('common.money', { value: cell.value }) : '-'}</span>;
          }

          return <span>-</span>;
        },
      },
      {
        Header: t('table.developer.application_list.status'),
        accessor: 'status',
        sortType: 'string',
        Cell: (cell: Cell<ApplicationListResponseDto>) => {
          const row = cell.row.original;

          const rejectedStatusMap = {
            rejecting_pending_state: ApplicationStatusEnum.PENDING,
            rejecting_committee_review_state: ApplicationStatusEnum.COMMITTEE_REVIEW,
            rejecting_confirmed_state: ApplicationStatusEnum.CONFIRMED,
            rejecting_offer_accepted_state: ApplicationStatusEnum.OFFER_ACCEPTED,
            rejecting_analytic_state: ApplicationStatusEnum.ANALYZING,
            rejecting_sales_manager_state: ApplicationStatusEnum.REJECTED,
            rejecting_committee_chief_state: ApplicationStatusEnum.CHIEF_COMMITTEE_MEMBER_REVIEW,
          };

          const transformedStatus =
            row.status != ApplicationStatusEnum.REJECTED || !row.rejected_state
              ? row.status
              : rejectedStatusMap[row.rejected_state as keyof typeof rejectedStatusMap];

          return <span>{tHtml('application.status.' + transformedStatus ?? row.status)}</span>;
        },
      },
      {
        Header: t('table.developer.application_list.created_at'),
        accessor: 'created_at',
        sortType: 'datetime',
        Cell: (cell: Cell<ApplicationListResponseDto>) => {
          return <span>{tHtml('common.date', { date: cell.value })}</span>;
        },
      },
      {
        Header: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: (cell: Cell<ApplicationListResponseDto>) => {
          const row = cell.row.original;

          if (row.status == ApplicationStatusEnum.PENDING) {
            return (
              <Link
                to={{
                  pathname: generatePath(
                    RouteList.PROJECT_DEVELOPER.APPLICATIONS.CREATE_APPLICATION,
                    {
                      type: row.type,
                      applicationId: row.id,
                    },
                  ),
                  state: { type: row.type },
                }}
              >
                <PrimaryButton className={'btn btn-primary btn-sm'}>
                  {tHtml('application.application_list.fill')}
                </PrimaryButton>
              </Link>
            );
          }

          if (row.status == ApplicationStatusEnum.CONFIRMED) {
            return (
              <Link
                to={{
                  pathname: generatePath(RouteList.PROJECT_DEVELOPER.APPLICATIONS.PROPOSAL, {
                    applicationId: row.id,
                  }),
                }}
              >
                <PrimaryButton className={'btn btn-highlight btn-sm'}>
                  {tHtml('application.application_list.offer')}
                </PrimaryButton>
              </Link>
            );
          }

          return null;
        },
      },
    ],
    [t, tHtml],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.projectDeveloper.applications
        .filterApplicationList(request)
        .then((response) => setData(response));
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [],
  );

  return (
    <div>
      <div className={'mt-6'}>
        <div>
          <TableExpandable
            title={tHtml('application.application_list.title')}
            columns={columns}
            data={data}
            onFetchData={fetchData}
            showPageSizes={false}
            initialSortBy={INITIAL_SORT_ORDER}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplicationList;
