import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Form, FormGroup } from 'reactstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Api from 'api';
import { UpdateUserNaturalRequestDto } from 'api/types/shared/user/signUpSteps';
import { RouteList } from 'routes';
import { setGlobalLoading } from 'modules/app/actions';
import { success } from 'services/toastr';
import { WHITELISTED_TAX_COUNTRIES } from 'helpers/constants/whitelistedTaxCountries';
import TextInput from 'components/Formik/TextInput';
import { DatepickerInput, PhoneNumberInput } from 'components/Formik/Inputs';
import PrimaryButton from 'components/Formik/PrimaryButton';
import TaxModal from 'components/Modal/Modals/TaxModal';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { SelectCountryInput } from 'components/Formik/Selects';
import { useAccountQuery } from 'api/queries';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import usePermissions from 'helpers/usePermissions';

const INITIAL_REGISTRATION_DATA_FILLING_REQUEST: UpdateUserNaturalRequestDto = {
  first_name: '',
  last_name: '',
  dob: '',
  gov_code: '',
  residence: '',
  phone: '',
  city: '',
  street: '',
  house_number: '',
};

const DataFilling: React.FC<RouteComponentProps> = ({ history }) => {
  const { t, tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const { refreshPermissions } = usePermissions();
  const { invalidate } = useAccountQuery();
  const { invalidate: invalidateProfile } = useUserProfileQuery();

  const [dataFillingRequest, setDataFillingRequest] = useState<UpdateUserNaturalRequestDto>(
    INITIAL_REGISTRATION_DATA_FILLING_REQUEST,
  );

  const { data: userProfileData } = useUserProfileQuery();

  useEffect(() => {
    if (userProfileData?.user?.natural) {
      const natural = userProfileData?.user?.natural;
      setDataFillingRequest((prevState) => {
        const state: UpdateUserNaturalRequestDto = {
          first_name: natural.first_name ?? prevState.first_name,
          last_name: natural.last_name ?? prevState.last_name,
          dob: natural.dob ?? prevState.dob,
          gov_code: natural.gov_code ?? prevState.gov_code,
          residence: natural.residence ?? prevState.residence,
          phone: natural.phone ?? prevState.phone,
          city: natural.city ?? prevState.city,
          street: natural.street ?? prevState.street,
          house_number: natural.house_number ?? prevState.house_number,
        };

        return state;
      });
    } else {
      setDataFillingRequest(INITIAL_REGISTRATION_DATA_FILLING_REQUEST);
    }

    setGlobalLoading(false);
  }, [userProfileData]);

  const DataFillingSchema = Yup.object().shape({
    first_name: Yup.string().firstName().required(),
    last_name: Yup.string().lastName().required(),
    dob: Yup.date().max(new Date()).required(),
    gov_code: Yup.string().governmentCode('residence').required(),
    residence: Yup.string().required().max(255),
    phone: Yup.string().phone().required(),
    city: Yup.string().required().max(100),
    street: Yup.string().required().max(100),
    house_number: Yup.string().required().max(10),
  });

  const onSubmit = useCallback(
    async (
      request: UpdateUserNaturalRequestDto,
      helpers: FormikHelpers<UpdateUserNaturalRequestDto>,
    ) => {
      try {
        await Api.shared.user.signUpSteps.registrationDataFilling(request);
        refreshPermissions();
        invalidate();
        invalidateProfile();

        success(tHtml('common.updated_successfully'));

        history.push(RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION);
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [history, invalidate, invalidateProfile, refreshPermissions, tHtml],
  );

  return (
    <React.Fragment>
      <div className={'top-title d-flex flex-row mb-4'}>
        <h4 className={'me-auto mb-0 align-self-center'}>
          {tHtml('authentication.step_data_filling')}
        </h4>
      </div>
      <Formik
        initialValues={dataFillingRequest}
        enableReinitialize={true}
        validationSchema={DataFillingSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <div className="mb-3">
                <TextInput name="first_name" />
              </div>
              <div className="mb-3">
                <TextInput name="last_name" />
              </div>
              <div className={'form-input'}>
                <DatepickerInput
                  name="dob"
                  label={t('placeholder.date_of_birth')}
                  maxDate={new Date()}
                />
              </div>
              <div className="mb-3">
                <SelectCountryInput
                  name={'residence'}
                  placeholder={t('placeholder.residence')}
                  onChange={(e: any) => {
                    if (!WHITELISTED_TAX_COUNTRIES.includes(e.value)) showModal(<TaxModal />);
                  }}
                  hasTooltip={true}
                  tooltipContent={tHtml('authentication.contact_info.residence.tooltip')}
                />
              </div>
              <div className={'form-input'}>
                <TextInput name="city" />
              </div>
              <div className={'form-input'}>
                <TextInput name="street" />
              </div>
              <div className={'form-input'}>
                <TextInput name="house_number" />
              </div>
              <div className="mb-4 pb-1">
                <PhoneNumberInput
                  name={'phone'}
                  label={t('placeholder.phone')}
                  dependencyFieldName="residence"
                />
              </div>
              <div className="mb-3">
                <TextInput name="gov_code" />
              </div>
              <div className={'mt-4 mb-4'}>
                <PrimaryButton type={'submit'} submitting={isSubmitting}>
                  {tHtml('common.submit')}
                </PrimaryButton>
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withRouter(DataFilling);
