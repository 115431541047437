import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Column } from 'react-table';
import { generatePath, Link } from 'react-router-dom';

import { PaginationData, PaginationDataFilter } from 'api/types';
import Table from 'components/Table/Table';
import { SecondaryMarketMyForSaleInvestmentsResponseDto } from 'api/types/investor/secondary_market';
import { RouteList } from 'routes';
import { HighlightButton } from 'components/Button';
import ProjectLoanActivityStatusBadge from '../../components/ProjectLoanActivityStatusBadge';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import CancelForSaleInvestmentModal from '../../components/CancelForSaleInvestmentModal';
import Api from 'api';
import useRender, { ACTIVE_INVESTMENTS_LIST } from './useRender';

const MyForSaleInvestmentsList: React.FC = () => {
  const { t, currentLanguage, tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const { rerender } = useRender(ACTIVE_INVESTMENTS_LIST);

  const [investments, setInvestments] =
    useState<PaginationData<SecondaryMarketMyForSaleInvestmentsResponseDto>>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.project_name'),
        accessor: 'project_name',
        sortType: 'string',
        Cell: (cell: any) => {
          const row = cell.row.original;
          return (
            <Link
              to={{
                pathname: generatePath(
                  RouteList.INVESTOR.SECONDARY_MARKET.BUYER.SECONDARY_MARKET_INVESTMENT,
                  {
                    secondaryMarketInvestmentId: row.id,
                  },
                ),
              }}
              target={'_blank'}
              rel={'noopener noreferrer'}
            >
              <span className={'text-highlight'}>{cell.value}</span>
            </Link>
          );
        },
      },
      {
        Header: t('table.receivable_amount_left'),
        accessor: 'receivable_amount_left',
        sortType: 'number',
        Cell: (cell: any) => t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.investment_term_left'),
        accessor: 'term',
        sortType: '',
        Cell: (cell: any) => {
          const { total, passed } = cell.value;

          return <span>{tHtml('common.days_interval', { passed: passed, total: total })}</span>;
        },
      },
      {
        Header: t('table.yearly_interest'),
        accessor: 'yearly_interest',
        sortType: 'number',
        Cell: (cell: any) => t('common.percents', { value: cell.value }),
      },
      {
        Header: t('table.loan_left_amount'),
        accessor: 'loan_left_amount',
        sortType: 'number',
        Cell: (cell: any) => t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.sale_price'),
        accessor: 'sale_price',
        sortType: 'number',
        Cell: (cell: any) => t('common.money', { value: cell.value }),
      },
      {
        Header: t('table.sale_days_left'),
        accessor: 'sale_days_left',
        sortType: '',
        Cell: (cell: any) => t('common.days', { value: cell.value }),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        Cell: (cell: any) => <ProjectLoanActivityStatusBadge type={cell.value} />,
      },
      {
        accessor: 'action',
        width: 100,
        disableSortBy: true,
        Cell: (cell: any) => {
          const row = cell.row.original;
          return (
            <div className={'secondary-market-table-button-seller'}>
              <HighlightButton
                onClick={() =>
                  showModal(
                    <CancelForSaleInvestmentModal
                      secondaryMarketInvestmentId={row.id}
                      onCancel={() => {
                        cell.dispatch({ type: 'refreshData' });
                        rerender();
                      }}
                    />,
                  )
                }
              >
                {tHtml('common.cancel')}
              </HighlightButton>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [showModal, t],
  );

  const fetchTableData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      await Api.investor.secondary_market
        .fetchPersonalForSaleInvestmentList(request)
        .then((response) => setInvestments(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage],
  );

  return (
    <div className={'mb-5'}>
      <div className={'d-flex justify-content-between flex-wrap align-items-end mb-3'}>
        <h2 className={'pb-1'}>{tHtml('secondary_market.my_for_sale_investments')}</h2>
        <Link to={RouteList.INVESTOR.SECONDARY_MARKET.BUYER.OVERVIEW}>
          <HighlightButton className={'btn btn-highlight secondary-market-link mt-1'}>
            {tHtml('secondary_market.buy_investments')}
          </HighlightButton>
        </Link>
      </div>
      <Table columns={columns} data={investments} onFetchData={fetchTableData} mobile={true} />
    </div>
  );
};

export default MyForSaleInvestmentsList;
