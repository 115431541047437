import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { IconArrowUp, IconArrowDown } from 'components/Icons';

interface Props {
  handleClick: () => void;
  isOpen?: boolean;
}

const ToggleFunctionalButton: React.FC<Props> = ({ handleClick, isOpen }) => {
  const { tHtml } = useTranslation();

  return (
    <button className={'site btn btn-functional'} onClick={handleClick}>
      {isOpen ? (
        <>
          {tHtml('common.more')}
          <IconArrowDown classes={'icon sm'} />
        </>
      ) : (
        <>
          {tHtml('common.less')}
          <IconArrowUp classes={'icon sm'} />
        </>
      )}
    </button>
  );
};

export default ToggleFunctionalButton;

ToggleFunctionalButton.defaultProps = {
  isOpen: true,
};
