import { Cell } from 'react-table';
import { InvestorInvestmentListResponseDto } from 'api/types/investor/investment';
import useTranslation from 'helpers/useTranslation';
import Tooltip from 'components/Tooltip';
import React from 'react';

interface Props {
  cell: Cell<InvestorInvestmentListResponseDto>;
}

export const BonusPercentageTooltip = ({ cell }: Props) => {
  const { t, tHtml } = useTranslation();

  const randomValue = Math.floor(Math.random() * 100);
  const tooltipId = `percentage-${cell.row.index}-${randomValue}`;

  const bonusSystem = cell.row.original.bonus_system;

  if (
    (bonusSystem.bonus_code_percentage_applied &&
      bonusSystem.individual_bonus_code_percentage_applied) ||
    (bonusSystem.bonus_code_percentage_applied &&
      !bonusSystem.individual_bonus_code_percentage_applied)
  ) {
    return (
      <>
        <i className={'ms-1 icon icon-info-sm'} id={tooltipId} />
        <Tooltip
          id={tooltipId}
          text={tHtml('investments.tooltip.bonus_code')}
          placement={'right'}
        />
      </>
    );
  }

  if (
    !bonusSystem.bonus_code_percentage_applied &&
    bonusSystem.individual_bonus_code_percentage_applied
  ) {
    return (
      <>
        <i className={'ms-1 icon icon-info-sm'} id={tooltipId} />
        <Tooltip
          id={tooltipId}
          text={t('investments.tooltip.individual_bonus_code')}
          placement={'right'}
        />
      </>
    );
  }

  return <></>;
};
