import MyProjectRoutes from './pages/MyProjectsList/MyProjectRoutes';
import DocumentRoutes from './pages/Documents/DocumentRoutes';
import TransactionRoutes from './pages/Transactions/TransactionRoutes';
import DashboardRoutes from './pages/Dashboard/DashboardRoutes';
import ApplicationRoutes from './pages/Application/ApplicationRoutes';
import ProjectProposalRoutes from './pages/ProjectProposal/ProjectProposalRoutes';
import ProjectRoutes from './pages/Project/ProjectRoutes';
import NotificationsRoutes from './pages/Notifications/NotificationsRoutes';
import NotFoundRoutes from './pages/NotFound/NotFoundRoutes';
import PaymentRoutes from './pages/Payments/PaymentRoutes';

const DeveloperRoutes: any = () => [
  ...DashboardRoutes(),
  ...TransactionRoutes(),
  ...PaymentRoutes(),
  ...DocumentRoutes(),
  ...MyProjectRoutes(),
  ...ProjectProposalRoutes(),
  ...ProjectRoutes(),
  ...NotificationsRoutes(),
  ...ApplicationRoutes(),
  ...NotFoundRoutes(),
];

export default DeveloperRoutes;
