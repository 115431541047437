import React, { useEffect, useState } from 'react';
import { FilterValue, Row } from 'react-table';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';

import useOnClickOutsideNode from 'helpers/useOnClickOutsideNode';
import { FilterProps } from '../Table';
import { SwipeModalComponent } from 'components/Modal/SwipeModalComponent';
import useDisplay from 'helpers/useDisplay';

export interface AdvanceFilterProps extends FilterProps {
  hidePredefinedDates: boolean;
  column: {
    filterValue: FilterValue;
    setFilter: (updater: ((filterValue: FilterValue) => FilterValue) | FilterValue) => void;
    preFilteredRows?: Array<Row<any>>;
    id?: string;
  };
  placement?: Placement;
  fireEventAfterSaved?: (start: Date | null, end: Date | null) => void;
  fireEventAfterCleared?: (start: Date | null, end: Date | null) => void;
}

interface Props {
  title: string;
  icon: string;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  hidePredefinedDates?: boolean;
  placement?: Placement;
}

const AdvanceFilter: React.FC<Props> = (props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { isMobile } = useDisplay();

  useOnClickOutsideNode(referenceElement, () => props.setIsOpen(false));

  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: props.placement!,
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  useEffect(() => {
    const updatePosition = async () => {
      if (update) await update();
    };
    updatePosition();
  }, [props.title, update]);

  const toggle = async () => {
    props.setIsOpen(!props.isOpen);
    if (update) await update();
  };

  const getClassName = (): string => {
    if (props.isOpen || props.title) {
      return 'filter-selected';
    }
    return '';
  };

  return (
    <div className={'action'} ref={setReferenceElement}>
      <button className={`btn action-btn ${getClassName()}`} onClick={() => toggle()}>
        <i className={`icon ${props.icon}`} />
        {props.title && <span className={'ms-1'}>{props.title}</span>}
      </button>
      {props.isOpen && (
        <>
          {isMobile ? (
            <SwipeModalComponent onClose={() => props.setIsOpen(false)}>
              <div className={'action-options'}>{props.children}</div>
            </SwipeModalComponent>
          ) : (
            <div
              ref={setPopperElement}
              className={'action-options'}
              style={styles.popper}
              {...attributes.popper}
            >
              <div>{props.children}</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

AdvanceFilter.defaultProps = {
  placement: 'bottom-start',
};

export default AdvanceFilter;
