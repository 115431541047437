import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Container } from 'reactstrap';

const DocumentsSkeleton: React.FC = () => (
  <Container>
    <Skeleton height={80} className={'mb-6'} />
    <Skeleton height={80} className={'mb-6'} />
    <Skeleton height={80} className={'mb-6'} />
  </Container>
);

export default DocumentsSkeleton;
