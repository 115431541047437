import { RouteList } from 'routes';
import { Permission } from './permissions';

interface Role {
  permissions: Array<string>;
  redirect: string;
}

export const All: Role = {
  permissions: [],
  redirect: RouteList.HOME,
};

export const Guest: Role = {
  permissions: [Permission.GUEST],
  redirect: RouteList.HOME,
};

export const UnverifiedUser: Role = {
  permissions: [Permission.REGISTERED],
  redirect: RouteList.HOME,
};

export const User: Role = {
  permissions: [Permission.REGISTERED, Permission.EMAIL_VERIFIED],
  redirect: RouteList.GUEST.AUTHENTICATION.LOGIN,
};
