import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import SelectInput, { SelectEnumProps, SelectOption } from '../../SelectInput';
import { UserCompanySizeEnum } from 'scopes/user/helpers/enums/UserCompanySizeEnum';

const SelectUserCompanySizeInput: React.FC<SelectEnumProps> = ({ name, placeholder }) => {
  const { t } = useTranslation();

  const [options] = useState<SelectOption[]>(() =>
    Object.values(UserCompanySizeEnum).map((value) => {
      return {
        label: t('company.size.' + value),
        value: value,
      };
    }),
  );

  return <SelectInput name={name} options={options} placeholder={placeholder} />;
};

export default SelectUserCompanySizeInput;
