import React from 'react';

import { NotificationsList } from 'components/Notifications';
import NotificationsProvider from 'components/Notifications/NotificationsProvider';

const NotificationsWrapper: React.FC = () => {
  return (
    <NotificationsProvider>
      <Notifications />
    </NotificationsProvider>
  );
};

const Notifications: React.FC = () => {
  return (
    <div className={'notifications-page'}>
      <NotificationsList />
    </div>
  );
};

export default NotificationsWrapper;
