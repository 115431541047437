import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Container, Form, FormGroup, Row } from 'reactstrap';

import Api from 'api';
import { StoreUserCompanyRequestDto } from 'api/types/shared/user/company';
import { setGlobalLoading } from 'modules/app/actions';
import { RouteList } from 'routes';
import { delay } from 'helpers/utils';
import { SelectGovDocumentTypeInput } from 'components/Formik/Selects/User';
import { CheckboxInput, PrimaryButton, TextInput } from 'components/Formik';
import FileUpload, { FileUploadHandle } from 'components/FileUpload/FileUpload';
import StepFormStepsWizard from 'containers/StepFormLayout/StepFormStepsWizard';
import StepFormHeader from 'containers/StepFormLayout/StepFormHeader';
import {
  SelectCompanyManagementStructureInput,
  SelectCountryInput,
} from 'components/Formik/Selects';
import {
  SelectRepresentativeTitleInput,
  SelectUserCompanySizeInput,
} from 'components/Formik/Selects/Company';
import { DatepickerInput, PhoneNumberInput } from 'components/Formik/Inputs';
import { CompanyAddressTypeEnum } from 'scopes/user/helpers/enums/CompanyAddressTypeEnum';
import { CompanyRepresentativeTitleEnum } from 'scopes/user/helpers/enums/CompanyRepresentativeTitleEnum';
import { createCompanySteps } from './steps';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';
import { CompanyValidationSchema } from './ValidationSchemas/CompanyValidationSchema';
import { useAccountQuery } from 'api/queries';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';

interface IFormValues extends StoreUserCompanyRequestDto {
  residential_address_street: string;
  residential_address_address: string;
  residential_address_city: string;
  contact_address_street: string;
  contact_address_address: string;
  contact_address_city: string;
  residence: string;
  is_contact_address_match_residential: boolean;
}

interface LocationProps {
  state: {
    type: string;
  };
}

const CreateCompany: React.FC<RouteComponentProps> = ({ history }) => {
  const { t, tHtml } = useTranslation();
  const location: LocationProps = useLocation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const { invalidate: invalidateProfile } = useAccountQuery();

  const { data: userProfileData } = useUserProfileQuery();

  const [initialFormValues] = useState<IFormValues>({
    name: '',
    type: '',
    legal_code: '',
    vat_code: '',
    email: '',
    phone: '',
    representative_title: CompanyRepresentativeTitleEnum.DIRECTOR,
    is_representative_executive: true,
    established_at: '',
    size: null,
    executive_first_name: '',
    executive_last_name: '',
    executive_address_house_number: '',
    executive_address_street: '',
    executive_address_city: '',
    executive_address_country: '',
    executive_phone: '',
    executive_email: '',
    executive_tax_country: '',
    executive_nationality: '',
    executive_gov_code: '',
    executive_gov_document_type: '',
    executive_gov_document_number: '',
    executive_gov_document_issue_country: '',
    executive_gov_document_expiration_date: '',
    executive_is_pep: false,
    bank_name: '',
    iban: '',
    management_structure: '',
    contact_address_street: '',
    contact_address_address: '',
    contact_address_city: '',
    residential_address_address: '',
    residential_address_city: '',
    residential_address_street: '',
    country: '',
    residence: '',
    documents: [],
    addresses: [],
    direct_marketing: false,
    is_contact_address_match_residential: true,
  });

  useEffect(() => {
    if (location.state === undefined || !location.state.type) history.push(RouteList.NOT_FOUND);
    setGlobalLoading(false);
  }, [location, history]);

  const isDeveloper = location.state.type == UserTypeEnum.PROJECT_DEVELOPER;

  const formatCompanyStoreRequest = useCallback(
    (request: IFormValues): StoreUserCompanyRequestDto => {
      const {
        is_contact_address_match_residential,
        residential_address_street,
        residential_address_address,
        residential_address_city,
        contact_address_street,
        contact_address_address,
        contact_address_city,
        residence,
      } = request;

      const residentialAddress = {
        street: residential_address_street,
        address: residential_address_address,
        city: residential_address_city,
        country: residence,
        type: CompanyAddressTypeEnum.RESIDENTIAL,
      };

      let executiveDetails: {
        executive_address_house_number: string;
        executive_address_street: string;
        executive_address_city: string;
        executive_address_country: string;
        executive_first_name: string;
        executive_gov_document_type: string;
        executive_last_name: string;
        executive_phone: string;
        is_representative_executive: boolean;
        executive_gov_document_expiration_date: string;
        executive_gov_document_number: string;
        executive_email: string;
        executive_tax_country: string;
        executive_nationality: string;
        executive_gov_code: string;
        executive_gov_document_issue_country: string;
      };

      if (
        !request.is_representative_executive &&
        request.representative_title !== CompanyRepresentativeTitleEnum.DIRECTOR
      ) {
        executiveDetails = {
          is_representative_executive: request.is_representative_executive,
          executive_first_name: request.executive_first_name ?? '',
          executive_last_name: request.executive_last_name ?? '',
          executive_address_house_number: request.executive_address_house_number ?? '',
          executive_address_street: request.executive_address_street ?? '',
          executive_address_city: request.executive_address_city ?? '',
          executive_address_country: request.executive_address_country ?? '',
          executive_phone: request.executive_phone ?? '',
          executive_email: request.executive_email ?? '',
          executive_tax_country: request.executive_tax_country ?? '',
          executive_nationality: request.executive_nationality ?? '',
          executive_gov_code: request.executive_gov_code ?? '',
          executive_gov_document_type: request.executive_gov_document_type ?? '',
          executive_gov_document_number: request.executive_gov_document_number ?? '',
          executive_gov_document_issue_country: request.executive_gov_document_issue_country ?? '',
          executive_gov_document_expiration_date:
            request.executive_gov_document_expiration_date ?? '',
        };
      } else {
        executiveDetails = {
          is_representative_executive: request.is_representative_executive,
          executive_first_name: userProfileData?.user?.natural?.first_name ?? '',
          executive_last_name: userProfileData?.user?.natural?.last_name ?? '',
          executive_address_house_number: userProfileData?.user?.natural?.house_number ?? '',
          executive_address_street: userProfileData?.user?.natural?.street ?? '',
          executive_address_city: userProfileData?.user?.natural?.city ?? '',
          executive_address_country: userProfileData?.user?.natural?.residence ?? '',
          executive_phone: userProfileData?.user?.natural?.phone ?? '',
          executive_email: userProfileData?.user?.email ?? '',
          executive_tax_country: userProfileData?.user?.natural?.residence ?? '',
          executive_nationality: userProfileData?.id_verification?.nationality ?? '',
          executive_gov_code: userProfileData?.user?.natural?.gov_code ?? '',
          executive_gov_document_type: userProfileData?.id_verification?.document_type ?? '',
          executive_gov_document_number: userProfileData?.id_verification?.document_number ?? '',
          executive_gov_document_issue_country: userProfileData?.user?.natural?.residence ?? '',
          executive_gov_document_expiration_date: userProfileData?.id_verification?.document_doe
            ? t('common.date', { date: userProfileData.id_verification.document_doe })
            : '',
        };
      }

      const contactAddress = Object.assign({}, residentialAddress);
      contactAddress.type = CompanyAddressTypeEnum.CONTACT;

      if (!is_contact_address_match_residential) {
        contactAddress.street = contact_address_street;
        contactAddress.address = contact_address_address;
        contactAddress.city = contact_address_city;
        contactAddress.country = residence;
      }

      return {
        name: request.name,
        type: location.state.type,
        legal_code: request.legal_code,
        vat_code: request.vat_code,
        email: request.email,
        phone: request.phone,
        country: request.residence,
        representative_title: request.representative_title,
        established_at: request.established_at,
        size: isDeveloper ? request.size : null,
        ...executiveDetails,
        executive_is_pep: request.executive_is_pep,
        documents: request.documents,
        addresses: [residentialAddress, contactAddress],
        direct_marketing: request.direct_marketing,
        bank_name: request.bank_name,
        iban: request.iban,
        management_structure: request.management_structure,
      };
    },
    [isDeveloper, location, userProfileData, t],
  );

  const onSubmit = useCallback(
    async (request: IFormValues, helpers: FormikHelpers<any>) => {
      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (fileUploadRef?.current?.isFilesUploading()) {
          await delay();
          return await helpers.submitForm();
        }

        const formattedCompanyStoreRequest: StoreUserCompanyRequestDto =
          formatCompanyStoreRequest(request);

        await Api.shared.user.company
          .storeCompany(formattedCompanyStoreRequest)
          .then((response) => {
            invalidateProfile();
            history.push(RouteList.USER.CREATE_COMPANY.KNOW_YOUR_BUSINESS, {
              id: response.id,
              type: location.state.type,
            });
          });
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [formatCompanyStoreRequest, history, invalidateProfile, location.state.type],
  );

  return (
    <>
      <StepFormHeader />
      <Container className={'main-content d-block mx-auto px-2 px-sm-4 px-md-6'}>
        <StepFormStepsWizard steps={createCompanySteps} />
        <Row>
          <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
            <h1 className="mb-4 mb-md-6">{tHtml('placeholder.company.add')}</h1>
            <Formik
              enableReinitialize={true}
              initialValues={initialFormValues}
              validationSchema={CompanyValidationSchema(isDeveloper, false)}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, isSubmitting, values }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className={'mb-4'}>
                      <TextInput name={'name'} placeholder={t('placeholder.company.name')} />
                    </div>
                    <div className={'mb-4'}>
                      <TextInput
                        name={'legal_code'}
                        placeholder={t('placeholder.company.legal_code')}
                      />
                    </div>
                    <div className={'mb-4'}>
                      <TextInput name={'vat_code'} placeholder={t('placeholder.vat_code')} />
                    </div>
                    <div className={'mb-4'}>
                      <DatepickerInput
                        name={'established_at'}
                        label={t('placeholder.company.established_at')}
                      />
                    </div>
                    {isDeveloper && (
                      <div className={'mb-4'}>
                        <SelectUserCompanySizeInput
                          name={'size'}
                          placeholder={tHtml('placeholder.company.size')}
                        />
                      </div>
                    )}
                    <div className={'mb-4'}>
                      <TextInput name={'email'} placeholder={t('placeholder.email')} />
                    </div>
                    <div className={'mb-4'}>
                      <PhoneNumberInput name="phone" label={t('placeholder.phone')} />
                    </div>
                    <div className={'mb-4'}>
                      <TextInput
                        name={'bank_name'}
                        placeholder={t('placeholder.company.bank_name')}
                      />
                    </div>
                    <div className={'mb-4'}>
                      <TextInput name={'iban'} placeholder={t('placeholder.company.iban')} />
                    </div>
                    <div className={'mb-2'}>
                      <SelectCompanyManagementStructureInput
                        name={'management_structure'}
                        placeholder={tHtml('placeholder.company.management_structure')}
                      />
                    </div>
                    <div className={'mb-4'}>
                      <SelectCountryInput
                        name={'residence'}
                        placeholder={t('placeholder.residence')}
                        hasTooltip={true}
                        tooltipContent={tHtml('company.contact_info.residence.tooltip')}
                      />
                    </div>
                    <div className={'mb-4'}>
                      <TextInput
                        name={'residential_address_street'}
                        placeholder={t('placeholder.street')}
                      />
                    </div>
                    <div className={'mb-4'}>
                      <TextInput
                        name={'residential_address_address'}
                        placeholder={t('placeholder.house_number')}
                      />
                    </div>
                    <div className={'mb-4'}>
                      <TextInput
                        name={'residential_address_city'}
                        placeholder={t('placeholder.city')}
                      />
                    </div>
                    <div className={'mb-4'}>
                      <CheckboxInput
                        name={'is_contact_address_match_residential'}
                        showPlaceholder={true}
                        placeholder={tHtml('placeholder.contact_address_matches_residence_address')}
                      />
                    </div>
                    {!values.is_contact_address_match_residential ? (
                      <>
                        <h2 className="mb-4">{tHtml('placeholder.company.contact_details')}</h2>
                        <div className={'mb-4'}>
                          <TextInput
                            name={'contact_address_street'}
                            placeholder={t('placeholder.street')}
                          />
                        </div>
                        <div className={'mb-4'}>
                          <TextInput
                            name={'contact_address_address'}
                            placeholder={t('placeholder.house_number')}
                          />
                        </div>
                        <div className={'mb-4'}>
                          <TextInput
                            name={'contact_address_city'}
                            placeholder={t('placeholder.city')}
                          />
                        </div>
                      </>
                    ) : null}

                    <>
                      <hr />
                      <h2 className="mb-4">{tHtml('placeholder.company.executive.details')}</h2>
                      <div className={'mb-4'}>
                        <SelectRepresentativeTitleInput
                          name={'representative_title'}
                          placeholder={tHtml('placeholder.company.representative_title')}
                        />
                      </div>
                      {values.representative_title !== CompanyRepresentativeTitleEnum.DIRECTOR && (
                        <>
                          <div className={'mb-4'}>
                            <CheckboxInput
                              name={'is_representative_executive'}
                              showPlaceholder={true}
                              placeholder={tHtml('placeholder.company.executive.is_representative')}
                            />
                          </div>
                          {!values.is_representative_executive && (
                            <>
                              <div className={'mb-4'}>
                                <TextInput
                                  name={'executive_first_name'}
                                  placeholder={t('placeholder.first_name')}
                                />
                              </div>
                              <div className={'mb-4'}>
                                <TextInput
                                  name={'executive_last_name'}
                                  placeholder={t('placeholder.last_name')}
                                />
                              </div>
                              <div className={'mb-4'}>
                                <TextInput
                                  name={'executive_address_house_number'}
                                  placeholder={t('placeholder.house_number')}
                                />
                              </div>
                              <div className={'mb-4'}>
                                <TextInput
                                  name={'executive_address_street'}
                                  placeholder={t('placeholder.street')}
                                />
                              </div>
                              <div className={'mb-2'}>
                                <TextInput
                                  name={'executive_address_city'}
                                  placeholder={t('placeholder.city')}
                                />
                              </div>
                              <div className={'mb-4'}>
                                <SelectCountryInput
                                  name={'executive_address_country'}
                                  placeholder={t('placeholder.country')}
                                />
                              </div>
                              <div className={'mb-4'}>
                                <PhoneNumberInput
                                  name="executive_phone"
                                  label={t('placeholder.phone')}
                                  dependencyFieldName="executive_address_country"
                                />
                              </div>
                              <div className={'mb-2'}>
                                <TextInput
                                  name={'executive_email'}
                                  placeholder={t('placeholder.email')}
                                />
                              </div>
                              <div className={'mb-2'}>
                                <SelectCountryInput
                                  name={'executive_tax_country'}
                                  placeholder={t('placeholder.executive_tax_country')}
                                />
                              </div>
                              <div className={'mb-4'}>
                                <SelectCountryInput
                                  name={'executive_nationality'}
                                  placeholder={t('placeholder.executive_nationality')}
                                />
                              </div>
                              <div className={'mb-4'}>
                                <TextInput
                                  name={'executive_gov_code'}
                                  placeholder={t('placeholder.gov_code')}
                                />
                              </div>
                              <div className={'mb-4'}>
                                <SelectGovDocumentTypeInput
                                  name={'executive_gov_document_type'}
                                  placeholder={tHtml('placeholder.gov.document.type')}
                                />
                              </div>
                              <div className={'mb-2'}>
                                <TextInput
                                  name={'executive_gov_document_number'}
                                  placeholder={t('placeholder.gov.document.number')}
                                />
                              </div>
                              <div className={'mb-4'}>
                                <SelectCountryInput
                                  name={'executive_gov_document_issue_country'}
                                  placeholder={t('placeholder.gov.document.issuer_country')}
                                />
                              </div>
                              <div className={'mb-4'}>
                                <DatepickerInput
                                  hasFutureRange={true}
                                  name={'executive_gov_document_expiration_date'}
                                  label={t('placeholder.gov.document.expiration_date')}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                    <div className={'mb-3'}>
                      <CheckboxInput
                        name={'executive_is_pep'}
                        showPlaceholder={true}
                        placeholder={tHtml('placeholder.company.executive.is_pep')}
                      />
                    </div>
                    <hr />
                    <div className={'mt-3'}>
                      <h4>{tHtml('placeholder.documents')}</h4>
                      <p>
                        <small>{tHtml('placeholder.documents.company')}</small>
                      </p>
                      <FileUpload
                        ref={fileUploadRef}
                        name={'documents'}
                        onPresign={(request) =>
                          Api.shared.user.company.uploadCompanyDocuments(request)
                        }
                        disabled={isSubmitting}
                      />
                      <hr />
                    </div>
                    <div className={'mb-3'}>
                      <CheckboxInput
                        name={'direct_marketing'}
                        showPlaceholder={true}
                        placeholder={tHtml('placeholder.company.marketing.confirm')}
                      />
                    </div>
                    <div className={'mt-4 mb-4'}>
                      <PrimaryButton
                        className={'btn btn-primary w-50'}
                        submitting={isSubmitting}
                        type={'submit'}
                      >
                        {tHtml('common.submit')}
                      </PrimaryButton>
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(CreateCompany);
