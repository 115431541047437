import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import { action } from 'typesafe-actions';
import * as Yup from 'yup';

import Api from 'api';
import { StoreApplicationPurchaseRequestDto } from 'api/types/project-developer/application';
import { setGlobalLoading } from 'modules/app/actions';
import { PrimaryButton } from 'components/Formik';
import { DatepickerInput } from 'components/Formik/Inputs';
import TextInput from 'components/Formik/TextInput';
import { redirect } from './CreateApplication';
import { ApplicationContext } from './module/ApplicationContext';
import { UPDATE_APPLICATION } from './module/ApplicationReducer';
import { ApplicationPageProps } from './steps';

const INITIAL_VALUE: StoreApplicationPurchaseRequestDto = {
  re_price_without_vat: 0,
  re_price_vat: 0,
  own_funds_amount: 0,
  own_funds_origin: '',
  re_seller_name: '',
  re_address: '',
  re_purpose: '',
  re_value: 0,
  re_evaluated_by: '',
  re_evaluated_at: '',
};

const Purchase: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { state, setApplicationId, dispatch } = useContext(ApplicationContext);

  useEffect(() => {
    if (applicationId !== undefined) {
      setApplicationId(applicationId);
    }

    setGlobalLoading(false);
  }, [t, applicationId, setApplicationId]);

  const [initialFormValues, setInitialFormValues] =
    useState<StoreApplicationPurchaseRequestDto>(INITIAL_VALUE);

  useEffect(() => {
    setInitialFormValues(state.application?.purchase ?? INITIAL_VALUE);
  }, [state]);

  const CreateApplicationPurchaseSchema = Yup.object().shape({
    re_price_without_vat: Yup.number().money().required(),
    re_price_vat: Yup.number().min(0).required(),
    own_funds_amount: Yup.number().min(0).required(),
    own_funds_origin: Yup.string().maxLength().required(),
    re_seller_name: Yup.string().maxLength().required(),
    re_address: Yup.string().addressLine().required(),
    re_purpose: Yup.string().maxLength().required(),
    re_value: Yup.number().money().required(),
    re_evaluated_by: Yup.string().maxLength().required(),
    re_evaluated_at: Yup.date().required(),
  });

  const onSubmit = useCallback(
    async (
      request: StoreApplicationPurchaseRequestDto,
      helpers: FormikHelpers<StoreApplicationPurchaseRequestDto>,
    ) => {
      try {
        await Api.projectDeveloper.applications
          .storeApplicationPurchase(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          <h1 className="mb-4 mb-md-6">{tHtml('application.purchase')}</h1>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={CreateApplicationPurchaseSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'re_price_without_vat'}
                      placeholder={t('placeholder.application.re_price_without_vat')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'re_price_vat'}
                      placeholder={t('placeholder.application.re_price_vat')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'own_funds_amount'}
                      placeholder={t('placeholder.application.own_funds_amount')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'text'}
                      name={'own_funds_origin'}
                      placeholder={t('placeholder.application.own_funds_origin')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'text'}
                      name={'re_seller_name'}
                      placeholder={t('placeholder.application.re_seller_name')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'text'}
                      name={'re_address'}
                      placeholder={t('placeholder.application.re_address')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'text'}
                      name={'re_purpose'}
                      placeholder={t('placeholder.application.re_purpose')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'number'}
                      name={'re_value'}
                      placeholder={t('placeholder.application.re_value')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      type={'text'}
                      name={'re_evaluated_by'}
                      placeholder={t('placeholder.application.re_evaluated_by')}
                    />
                  </div>
                  <div className="mb-3">
                    <DatepickerInput
                      hasFutureRange={true}
                      name={'re_evaluated_at'}
                      label={t('placeholder.application.re_evaluated_at')}
                    />
                  </div>
                </FormGroup>
                <hr />
                <div className={'d-flex justify-content-between align-items-center'}>
                  <PrimaryButton
                    className={'btn btn-grey w-25'}
                    type={'button'}
                    onClick={() => {
                      redirect(history, previousPage, applicationId);
                    }}
                  >
                    {tHtml('common.back')}
                  </PrimaryButton>
                  <PrimaryButton className={'btn btn-primary w-25'} submitting={isSubmitting}>
                    {tHtml('common.continue')}
                  </PrimaryButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Row>
    </>
  );
};

export default withRouter(Purchase);
