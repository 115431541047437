import React from 'react';
import { buildStyles } from 'react-circular-progressbar';
import GradientSVG from './GradientSVG';
import CustomCircularProgressbar from './CustomCircularProgressbar';

interface Props {
  percentage: number;
  investors?: number;
}

const ProgressBar: React.FC<Props> = ({ percentage, investors }) => {
  const getTextColor = (percentage: number): string => {
    if (percentage == 0) {
      return 'var(--profitus-progress-zero-percentage-color)';
    }
    return 'var(--profitus-progress-percentage-color)';
  };

  return (
    <>
      <GradientSVG
        startColor={'var(--profitus-progress-bar-start-color)'}
        endColor={'var(--profitus-progress-bar-end-color)'}
        id={'progress-bar-gradient'}
      />
      <CustomCircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        subtext={`${investors}`}
        strokeWidth={6}
        styles={buildStyles({
          textColor: getTextColor(percentage),
          pathColor: `url(#progress-bar-gradient)`,
          trailColor: 'var(--profitus-progress-bar-trail-color)',
        })}
      >
        {investors != undefined ? (
          <>
            <i className={'icon icon-investors'} /> {investors}
          </>
        ) : null}
      </CustomCircularProgressbar>
    </>
  );
};

export default ProgressBar;
