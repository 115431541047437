import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, ColProps, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Trans } from 'react-i18next';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { CheckboxInput, PrimaryButton, TextInput } from 'components/Formik';
import { success } from 'services/toastr';
import { SellInvestmentRequestDto } from 'api/types/investor/secondary_market';
import Api from 'api';
import { RouteList } from 'routes';

interface Props extends ModalComponentProps {
  investmentId: string;
  amount: number;
  onAmountChange: React.Dispatch<React.SetStateAction<string>>;
  taxSeller: number;
  taxBuyer: number;
}

interface IFormValues {
  amount: number;
  agreement_all: boolean;
  agreement_transfer_of_rights: boolean;
  agreement_buyer_purchase: boolean;
  agreement_buyer_rights: boolean;
  agreement_fees_internal_procedures: boolean;
}

const SellInvestmentModal: React.FC<Props> = ({
  investmentId,
  amount,
  onAmountChange,
  taxSeller,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  taxBuyer,
  ...props
}) => {
  const { t, tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const history = useHistory();

  const [formRequest] = useState<IFormValues>({
    amount: amount,
    agreement_all: false,
    agreement_transfer_of_rights: false,
    agreement_buyer_purchase: false,
    agreement_buyer_rights: false,
    agreement_fees_internal_procedures: false,
  });

  const schema = Yup.object().shape({
    amount: Yup.number().positive().min(1).max(1000000).required(),
    agreement_all: Yup.boolean(),
    agreement_transfer_of_rights: Yup.boolean().required().isTrue(),
    agreement_buyer_purchase: Yup.boolean().required().isTrue(),
    agreement_buyer_rights: Yup.boolean().required().isTrue(),
    agreement_fees_internal_procedures: Yup.boolean().required().isTrue(),
  });

  const onSubmit = useCallback(
    async (request: IFormValues, helpers: FormikHelpers<IFormValues>) => {
      const sellRequest: SellInvestmentRequestDto = {
        amount: request.amount,
        agreement_transfer_of_rights: request.agreement_transfer_of_rights,
        agreement_buyer_purchase: request.agreement_buyer_purchase,
        agreement_buyer_rights: request.agreement_buyer_rights,
        agreement_fees_internal_procedures: request.agreement_fees_internal_procedures,
      };
      try {
        await Api.investor.secondary_market.sellInvestment(sellRequest, investmentId).then(() => {
          success(tHtml('secondary_market.project.sell_investment.success'));
          hideModal();
          history.push(RouteList.INVESTOR.SECONDARY_MARKET.SELLER.OVERVIEW);
        });
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [hideModal, history, investmentId, tHtml],
  );

  const handleCheckAll = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    setFieldValue('agreement_transfer_of_rights', event.target.checked, false);
    setFieldValue('agreement_buyer_purchase', event.target.checked, false);
    setFieldValue('agreement_buyer_rights', event.target.checked, false);
    setFieldValue('agreement_fees_internal_procedures', event.target.checked, false);
  };

  const colProps: ColProps = {
    lg: 6,
    md: 6,
    sm: 12,
    xs: 12,
  };

  const calculateAmountAfterTax = (amount: number): number => {
    return amount * (1 - (taxSeller + taxBuyer))!;
  };

  return (
    <React.Fragment>
      <ModalComponent
        size={'lg'}
        {...props}
        contentClassName={'modal-investment secondary-market-page'}
      >
        <div className={'p-3'}>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />} tag={'h1'}>
            {tHtml('secondary_market.project.sell_investment.title')}
          </ModalHeader>
          <ModalBody>
            <div className={'w-100'}>
              <Formik
                initialValues={formRequest}
                validationSchema={schema}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validateOnBlur={true}
                validateOnChange={true}
              >
                {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className={'sell-investment-calculator mb-4'}>
                      <Row className={'mb-2'}>
                        <Col {...colProps}>
                          <div className={'py-2'}>
                            <h2 className={'mb-3'}>
                              {tHtml('secondary_market.project.calculator.sale_price')}
                            </h2>
                            <div>
                              <TextInput
                                name={'amount'}
                                type={'number'}
                                hideLabel={true}
                                onChange={(e) => onAmountChange(e.target.value)}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col {...colProps}>
                          <div className={'py-2'}>
                            <h2 className={'mb-2'}>
                              {tHtml('secondary_market.project.calculator.amount_after_tax')}
                            </h2>
                            <div>
                              <h1 className={''}>
                                {tHtml('common.money', {
                                  value: calculateAmountAfterTax(values.amount),
                                })}
                              </h1>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <p>{tHtml('secondary_market.project.calculator.disclaimer')}</p>
                      </div>
                    </div>
                    {/* TODO: links to rules */}
                    <div className={'form-input mb-3'}>
                      <CheckboxInput
                        name={'agreement_all'}
                        onChange={(event) => handleCheckAll(event, setFieldValue)}
                      />
                    </div>
                    <div className={'form-input mb-3'}>
                      <CheckboxInput
                        name={'agreement_transfer_of_rights'}
                        customLabel={
                          <div className={'ms-2'}>
                            <Trans
                              components={[
                                <a key={0} href="#" className={'link-secondary fw-bold'} />,
                              ]}
                            >
                              {t('placeholder.agreement_transfer_of_rights')}
                            </Trans>
                          </div>
                        }
                      />
                    </div>
                    <div className={'form-input mb-3'}>
                      <CheckboxInput name={'agreement_buyer_purchase'} />
                    </div>
                    <div className={'form-input mb-3'}>
                      <CheckboxInput name={'agreement_buyer_rights'} />
                    </div>
                    <div className={'form-input mb-3'}>
                      <CheckboxInput
                        name={'agreement_fees_internal_procedures'}
                        customLabel={
                          <div className={'ms-2'}>
                            <Trans
                              components={[
                                <a key={0} href="#" className={'link-secondary fw-bold'} />,
                              ]}
                            >
                              {t('placeholder.agreement_fees_internal_procedures')}
                            </Trans>
                          </div>
                        }
                      />
                    </div>
                    <PrimaryButton
                      className={'btn btn-primary btn-lg mt-3 fw-normal'}
                      disabled={isSubmitting}
                    >
                      {tHtml('common.sell')}
                    </PrimaryButton>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default SellInvestmentModal;
