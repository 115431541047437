import React, { useState } from 'react';
import { ModalComponent } from 'components/Modal/ModalComponent';
import { ModalBody, ModalHeader } from 'reactstrap';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { HighlightButton } from 'components/Button';
import { PrimaryButton } from 'components/Formik';
import useTranslation from 'helpers/useTranslation';
import TwoFactorApplicationRecommendation from 'scopes/user/components/TwoFactorApplicationRecommendation';
import Api from 'api';
import {
  EnableTwoFactorAuthRequestDto,
  UserProfileTwoFactorAuthResponseDto,
} from 'api/types/shared/user/profile';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { TextInput } from 'components/Formik';
import { success } from 'services/toastr';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import { StyledQRCode } from './TwoFactorAuthModal.style';

enum ModalStep {
  INITIAL_STEP = 'INITIAL_STEP',
  QR_SCAN_STEP = 'QR_DISPLAY_STEP',
  CONFIRMATION_STEP = 'CONFIRMATION_STEP',
}

const EnableTwoFactorAuthModal = () => {
  const { t, tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const { refetch: userProfileDataRefetch } = useUserProfileQuery();

  const [step, setStep] = useState<ModalStep>(ModalStep.INITIAL_STEP);
  const [qrCode, setQrCode] = useState<UserProfileTwoFactorAuthResponseDto | null>(null);

  let modalBody = null;

  const handleConfirmTwoFactorAuth = async (
    values: EnableTwoFactorAuthRequestDto,
    helpers: FormikHelpers<EnableTwoFactorAuthRequestDto>,
  ) => {
    try {
      await Api.shared.user.profile.createTwoFactorAuth(values);
      setStep(ModalStep.CONFIRMATION_STEP);
    } catch (e) {
      helpers.setErrors({ code: t('placeholder.2fa.2fa_confirm_failed') });
      return;
    }
  };

  switch (step) {
    case ModalStep.INITIAL_STEP:
      modalBody = (
        <div className={'w-100'}>
          <span>{tHtml('placeholder.2fa.description')}</span>
          <TwoFactorApplicationRecommendation />
          <div className={'d-flex justify-content-around mt-3'}>
            <HighlightButton
              className={'btn btn-highlight w-50 fw-normal me-3'}
              onClick={hideModal}
            >
              {tHtml('common.cancel')}
            </HighlightButton>
            <PrimaryButton
              className={'btn btn-primary w-50 fw-normal'}
              submitting={false}
              onClick={async () => {
                setStep(ModalStep.QR_SCAN_STEP);
                const res = await Api.shared.user.profile.generateTwoFactorAuthQrCode();
                setQrCode(res);
              }}
            >
              {tHtml('common.continue')}
            </PrimaryButton>
          </div>
        </div>
      );
      break;
    case ModalStep.QR_SCAN_STEP:
      const TwoFactorAuthConfirm = Yup.object().shape({
        secret: Yup.string().required(),
        code: Yup.string().required(),
      });

      if (qrCode === null) {
        modalBody = <div className="spinner-border" role="status" />;
      } else {
        modalBody = (
          <>
            <div className={'row mb-4'}>
              <div className={'col-sm text-center'}>
                <StyledQRCode size={180} value={qrCode.qr_code} /> <br />
                <span>
                  {tHtml('placeholder.2fa.code')} <b>{qrCode.secret}</b>
                </span>
              </div>
              <div className={'col-sm'}>
                <span>{tHtml('placeholder.2fa.please_scan_this_code')}</span>
              </div>
            </div>
            <Formik
              initialValues={{
                secret: qrCode.secret,
                code: '',
              }}
              validationSchema={TwoFactorAuthConfirm}
              onSubmit={handleConfirmTwoFactorAuth}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={'form-input'}>
                    <TextInput
                      name="code"
                      placeholder={t('placeholder.2fa.2fa_code')}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSubmit();
                        }
                      }}
                    />
                  </div>
                  <div className={'d-flex justify-content-around mt-3'}>
                    <HighlightButton
                      className={'btn btn-highlight w-50 fw-normal me-3'}
                      onClick={hideModal}
                    >
                      {tHtml('common.cancel')}
                    </HighlightButton>
                    <PrimaryButton
                      disabled={isSubmitting}
                      className={'btn btn-primary w-50 fw-normal'}
                      type={'submit'}
                    >
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        );
      }
      break;
    case ModalStep.CONFIRMATION_STEP:
      modalBody = (
        <>
          <span>{tHtml('placeholder.2fa.success_enabled')}</span>
          <div className={'d-flex justify-content-around mt-3'}>
            <PrimaryButton
              className={'btn btn-primary w-50 fw-normal'}
              onClick={() => {
                success(tHtml('placeholder.2fa.add_success'));
                hideModal();
                userProfileDataRefetch();
              }}
            >
              {tHtml('common.done')}
            </PrimaryButton>
          </div>
        </>
      );
      break;
    default:
      throw new Error('Unknown step');
  }

  return (
    <>
      <ModalComponent size={'md'} contentClassName={'two-factor-auth-modal'}>
        <div className={'p-3'}>
          <ModalHeader
            toggle={hideModal}
            close={<ModalCloseButton />}
            tag={'h2'}
            className={'mt-2'}
          >
            {tHtml('placeholder.2fa')}
          </ModalHeader>
          <ModalBody>{modalBody}</ModalBody>
        </div>
      </ModalComponent>
    </>
  );
};

export default EnableTwoFactorAuthModal;
