import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import { UPDATE_APPLICATION } from './module/ApplicationReducer';
import { action } from 'typesafe-actions';
import * as Yup from 'yup';

import Api from 'api';
import { CheckboxInput, PrimaryButton, SelectInput, TextInput } from 'components/Formik';
import transformErrors from 'helpers/formik';
import { ApplicationContext } from './module/ApplicationContext';
import { redirect } from 'scopes/guest/helpers/utils';
import { ApplicationPageProps } from './steps';
import { CompanyDetailsDto } from 'api/types/guest/prospectApplication';
import Loader from 'components/Loader';
import { SelectCompanyManagementStructureInput } from 'components/Formik/Selects';
import CheckboxInputGroup from 'components/Formik/CheckboxGroup/CheckboxInputGroup';
import { SelectOption } from 'components/Formik/SelectInput';
import SelectMultiCountryInputGroup from 'components/Formik/Selects/SelectMultiCountryInput';
import YesNoSelector from 'components/Formik/YesNoSelector';

const ACTIVITY_KEYS = [
  'real_estate_development',
  'real_estate_brokerage',
  'retail',
  'service_provision',
  'agriculture',
  'artistic_intellectual',
  'legal_services',
  'gas_industry_activities',
  'military_technology',
  'chemicals_industry',
  'dual_use_goods',
  'cryptocurrencies',
  'art_antiques_trade',
  'gambling_activities',
  'charity',
  'other',
];

const CompanyDetails: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { state, dispatch } = useContext(ApplicationContext);
  const [initialFormValues, setInitialFormValues] = useState<CompanyDetailsDto | null>(null);

  const [cashUsageValues] = useState<SelectOption[]>(() =>
    ['more_than_half', 'middle', 'less_than_30', 'no_use'].map((value) => {
      return {
        label: t(`placeholder.prospect_application.cash_usage.${value}`),
        value: value,
      };
    }),
  );

  useEffect(() => {
    if (state.application?.prospect_application.id !== applicationId) return;
    const companyInfo = state.application?.prospect_application.company?.kyb_data;
    if (companyInfo) {
      setInitialFormValues(companyInfo);
    } else {
      setInitialFormValues({
        management_structure: '',
        activities: [],
        other_activities_text: null,
        cash_usage: '',
        countries_of_activities: [],
        business_partners: '',
        non_performance_credit_agreement: false,
        has_bankruptcy_procedure: false,
        is_not_operating_in_russia_belarus_ukraine_occupied_territories: false,
        shareholders: [],
        beneficiaries: [],
        peps: [],
        has_shareholders_with_more_than_25_percent: false,
        public_positions_held_by_related_individuals: false,
        convicted: false,
      });
    }
  }, [t, applicationId, state]);

  const onSubmit = useCallback(
    async (request: CompanyDetailsDto, helpers: FormikHelpers<CompanyDetailsDto>) => {
      try {
        await Api.guest.prospect_application
          .storeApplicationCompanyDetails(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  const schema = Yup.object().shape({
    management_structure: Yup.string().required(),
    activities: Yup.array().min(1).of(Yup.string().required()),
    other_activities_text: Yup.string().when('activities', {
      is: (activities: string[]) => activities.includes('other'),
      then: Yup.string().max(255).required(),
      otherwise: Yup.string().max(255).nullable(),
    }),
    cash_usage: Yup.string().required(),
    countries_of_activities: Yup.array().min(1).of(Yup.string().required()),
    business_partners: Yup.string().required(),
    non_performance_credit_agreement: Yup.boolean().required(),
    has_bankruptcy_procedure: Yup.boolean().required(),
    is_not_operating_in_russia_belarus_ukraine_occupied_territories: Yup.boolean().required(),
  });

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          {initialFormValues ? (
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              initialValues={initialFormValues}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {(helpers) => (
                <Form onSubmit={helpers.handleSubmit}>
                  <FormGroup>
                    <h2 className={'mb-4'}>{tHtml('prospect_application.company_details')}</h2>
                    <div className="mb-3">
                      <SelectCompanyManagementStructureInput
                        name={'management_structure'}
                        placeholder={t('placeholder.prospect_application.management_structure')}
                      />
                    </div>
                    <div className="mb-3">
                      <CheckboxInputGroup
                        name={'activities'}
                        values={ACTIVITY_KEYS}
                        valuesWithText={['other']}
                        textPlaceholders={{
                          other: t('placeholder.prospect_application.activities.other_text'),
                        }}
                        showPlaceholder={true}
                        placeholder={t('placeholder.prospect_application.activities')}
                      />
                    </div>
                    <div className="mb-1 mt-5">
                      <SelectInput
                        name={'cash_usage'}
                        options={cashUsageValues}
                        placeholder={tHtml('placeholder.prospect_application.cash_usage')}
                      />
                    </div>
                    <div className="mb-4">
                      <SelectMultiCountryInputGroup
                        setFieldValue={helpers.setFieldValue}
                        name="countries_of_activities"
                        placeholder={t('placeholder.prospect_application.countries_of_activities')}
                      />
                    </div>
                    <div className="mb-3">
                      <TextInput
                        name={'business_partners'}
                        placeholder={t('placeholder.prospect_application.business_partners')}
                      />
                    </div>
                    <div className="mb-3">
                      <YesNoSelector
                        name={'non_performance_credit_agreement'}
                        placeholder={t(
                          'placeholder.prospect_application.non_performance_credit_agreement',
                        )}
                      />
                    </div>
                    <div className="mb-3">
                      <YesNoSelector
                        name={'has_bankruptcy_procedure'}
                        placeholder={t('placeholder.prospect_application.has_bankruptcy_procedure')}
                      />
                    </div>
                    <div className="mb-3">
                      <CheckboxInput
                        name={'is_not_operating_in_russia_belarus_ukraine_occupied_territories'}
                        placeholder={tHtml(
                          'placeholder.prospect_application.is_not_operating_in_russia_belarus_ukraine_occupied_territories',
                        )}
                      />
                    </div>
                  </FormGroup>
                  <hr />
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <PrimaryButton
                      className={'btn btn-grey w-25'}
                      type={'button'}
                      onClick={() => {
                        redirect(history, previousPage, applicationId);
                      }}
                    >
                      {tHtml('common.back')}
                    </PrimaryButton>
                    <PrimaryButton
                      className={'btn btn-primary w-25'}
                      submitting={helpers.isSubmitting}
                    >
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default withRouter(CompanyDetails);
