import React, { useEffect, useRef, useState } from 'react';
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import {
  CustomGallery as LightboxGallery,
  DefaultLayout as LightboxLayout,
} from 'react-photoswipe-gallery';
import { Swiper, SwiperSlide } from 'swiper/react';

import Video from './Video';
import Image from './Image';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { TranslatableValueDto } from 'api/types/common';
export interface GalleryItem {
  original: string;
  thumbnail: string;
  label: TranslatableValueDto<string> | null;
  video?: string;
}

interface Props {
  items: GalleryItem[];
  loop: boolean;
  displayAll: boolean;
}

const Gallery: React.FC<Props> = ({ items, loop, displayAll }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>();
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const swiperRef = useRef<any>();
  const lightboxLayoutRef = useRef();

  useEffect(() => {
    if (swiperRef.current != undefined) {
      swiperRef.current.slideTo(currentSlideIndex, 0);
    }
  }, [currentSlideIndex]);

  const Slide: React.FC<{ item: GalleryItem }> = ({ item }) => {
    if (item.video) {
      return <Video videoUrl={item.video} imageUrl={item.original} thumbnailUrl={item.thumbnail} />;
    }
    return <Image imageUrl={item.original} thumbnailUrl={item.thumbnail} label={item.label} />;
  };

  return (
    <div className={'gallery'}>
      <LightboxGallery
        layoutRef={lightboxLayoutRef as React.MutableRefObject<any>}
        ui={PhotoswipeUIDefault}
        options={{ getThumbBoundsFn: undefined }}
        onOpen={(photoSwipe) => {
          setCurrentSlideIndex(photoSwipe.getCurrentIndex());

          photoSwipe.listen('afterChange', () => {
            setCurrentSlideIndex(photoSwipe.getCurrentIndex());
          });
        }}
      >
        <Swiper
          rewind={loop}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          zoom={true}
          className="slide"
          onInit={(core) => {
            swiperRef.current = core;
          }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={`Slide ${index}`}>
              <Slide item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
        {displayAll && (
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={false}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="thumbs"
          >
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.thumbnail} alt={`Item ${index}`} />
                <div className={'overlay'} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </LightboxGallery>

      <LightboxLayout
        shareButton={false}
        fullscreenButton={true}
        zoomButton={true}
        ref={lightboxLayoutRef as React.MutableRefObject<any>}
      />
    </div>
  );
};

export default Gallery;
