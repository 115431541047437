import styled from 'styled-components';
import { breakpointDown } from '../../styles/helpers/responsive';

export const StyledInfoBlock = styled.div``;

export const StyledInfoBlockItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledInfoBlockTitle = styled.div`
  font-size: 1.125rem;
  color: var(--profitus-profile-card-info-block-title-color);
  font-weight: 600;
`;

export const StyledInfoBlockDescription = styled.div`
  font-size: 0.8rem;
  color: var(--profitus-profile-card-info-block-text-color);
`;

export const StyledInfoBlockButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0.25rem 0;

  & > * {
    margin-top: 0.25rem;
  }

  *:not(:last-child) {
    margin-right: 0.375rem;
  }

  ${breakpointDown.XL} {
    margin-top: 0.75rem;
  }

  ${breakpointDown.MD} {
    button {
      font-size: 0.875rem;
      padding: 0.4rem 1rem;
    }
  }
`;
