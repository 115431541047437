import React, { ReactElement } from 'react';
import Tooltip from '../Tooltip';

interface Props {
  title: string | React.ReactNode;
  htmlFor: string;
  isBold?: boolean;
  hasTooltip?: boolean;
  tooltipContent?: string | ReactElement;
  smallTooltip?: boolean;
  labelStyle?: React.CSSProperties;
  labelClassName?: string;
}

const Label: React.FC<Props> = ({
  title,
  htmlFor,
  labelStyle,
  labelClassName = '',
  isBold = false,
  hasTooltip = false,
  smallTooltip = false,
  tooltipContent,
}) => {
  // TODO think of better way to connect tooltip to icon
  // ~ 1/50000 possibility of dupe
  const tooltipId = React.useMemo(() => {
    return (Math.random() + 1).toString(36).substring(7);
  }, []);

  return (
    <label
      className={`${isBold ? 'text-bold' : ''} d-flex ${labelClassName}`}
      htmlFor={htmlFor}
      style={labelStyle}
    >
      <span>{title}</span>
      {hasTooltip && tooltipContent && tooltipId && (
        <span>
          <i
            className={`icon icon-info${smallTooltip ? '-sm' : ''}`}
            id={`tooltip-icon-${tooltipId}`}
          />
          <Tooltip id={`tooltip-icon-${tooltipId}`} text={tooltipContent} />
        </span>
      )}
    </label>
  );
};

export default Label;
