import React, { ReactElement } from 'react';
import useTranslation from 'helpers/useTranslation';

import CollapsableBlock from './CollpsableBlock';

export interface FAQOption {
  title: string | ReactElement;
  content: string | ReactElement;
}

interface Props {
  items: FAQOption[];
}

const FAQSection: React.FC<Props> = ({ items }) => {
  const { tHtml } = useTranslation();
  return (
    <div className={'section__faq'}>
      <h2 className={'section__faq-title'}>{tHtml('common.faq')}</h2>
      <div className={'panel'}>
        {items.map((item, index) => (
          <CollapsableBlock key={index} title={item.title} content={item.content} />
        ))}
      </div>
    </div>
  );
};

export default FAQSection;
