import React, { ReactElement, useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import Api from 'api';
import { GroupBase } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import ReactSelectGroupInput, {
  GroupedOption,
} from 'components/Formik/Selects/ReactSelectGroupInput';
import { useCacheQuery } from 'api/queries/useCacheQuery';
import CountryLabel from 'components/Labels/CountryLabel';
import { ReactSelectOption } from 'components/Formik/Selects/ReactSelectInput';

interface Props extends StateManagerProps {
  name: string;
  placeholder: string;
  hasTooltip?: boolean;
  tooltipContent?: string | ReactElement;
  isDisabled?: boolean;
}

const SelectCountryInputGroup: React.FC<Props> = ({
  name,
  placeholder,
  isDisabled = false,
  ...props
}) => {
  const [formattedCountries, setFormattedCountries] = useState<GroupedOption[]>([]);
  const { t, currentLanguage } = useTranslation();

  const { data, isLoading } = useCacheQuery(
    ['money-in-by-bank-countries'],
    Api.wallet.moneyInByBankCountries,
  );

  const getOption = (code: string) => {
    return {
      value: code,
      label: <CountryLabel code={code} />,
      customText: t('countries.' + code),
    };
  };

  useEffect(() => {
    const options: GroupedOption[] = [];

    options.push({
      label: 'primary_countries',
      options: data?.countries?.map(getOption).sort(compareOption) ?? [],
    });

    setFormattedCountries(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const compareOption = (o1: ReactSelectOption, o2: ReactSelectOption) =>
    o1.customText && o2.customText ? o1.customText.localeCompare(o2.customText) : 0;

  useEffect(() => {
    if (formattedCountries.length > 0) {
      setFormattedCountries((groups) => [
        ...groups.map((group) => ({
          ...group,
          options: group.options
            .map((option) => getOption(option.value.toString()))
            .sort(compareOption),
        })),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const formatGroupLabel = (data: GroupBase<any>) => {
    return data.label === 'primary_countries' ? null : <hr className={'m-0'} />;
  };

  const customStyles = {
    group: (provided: any) => ({
      ...provided,
      padding: '0.2rem 0',
    }),
  };

  const filterOption = (
    option: FilterOptionOption<ReactSelectOption | unknown>,
    inputValue: string,
  ): boolean =>
    (
      (option as FilterOptionOption<ReactSelectOption>).data.customText
        ?.toLowerCase()
        .toString()
        .match(inputValue.toLowerCase()) || []
    ).length > 0;

  return (
    <ReactSelectGroupInput
      name={name}
      isDisabled={isDisabled}
      placeholder={placeholder}
      groupedOptions={formattedCountries}
      isSearchable={true}
      formatGroupLabel={formatGroupLabel}
      styles={customStyles}
      filterOption={filterOption}
      isLoading={isLoading}
      {...props}
    />
  );
};

export default SelectCountryInputGroup;
