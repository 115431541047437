import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Cell, Column } from 'react-table';
import { AmountFilter, DateFilter } from 'components/Table/Table';
import Api from 'api';
import { RouteList } from 'routes';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { OperationsResponseDto } from 'api/types/shared/wallet';
import TableOverview from 'components/Table/TableOverview';
import WalletOperationTypeBadge from 'components/Badge/WalletOperationTypeBadge';
import WalletOperationStatusBadge from 'components/Badge/WalletOperationStatusBadge';
import WalletOperationAmountBadge from 'components/Badge/WalletOperationAmountBadge';
import { TableInitialSortBy } from 'api/types/common';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import { downloadXMLFile, downloadCSVFile, downloadXLSXFile } from 'services/media';

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'executed_at',
    desc: true,
  },
];

const Transactions: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const [allTransactionData, setAllTransactionData] =
    useState<PaginationData<OperationsResponseDto>>();
  const [allTransactionPaginationRequest, setAllTransactionPaginationRequest] = useState<
    PaginationDataFilter | undefined
  >();

  const [loanRepaymentTransactionData, setLoanRepaymentTransactionData] =
    useState<PaginationData<OperationsResponseDto>>();
  const [loanRepaymentPaginationRequest, setLoanRepaymentPaginationRequest] = useState<
    PaginationDataFilter | undefined
  >();

  const [loanReceiveTransactionData, setLoanReceiveTransactionData] =
    useState<PaginationData<OperationsResponseDto>>();
  const [loanReceivePaginationRequest, setLoanReceivePaginationRequest] = useState<
    PaginationDataFilter | undefined
  >();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.developer_transaction_type'),
        accessor: 'type',
        sortType: 'string',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return (
            <WalletOperationTypeBadge
              type={row.type}
              private_data={row.private_data}
              amount={row.amount}
            />
          );
        },
      },
      {
        Header: t('table.executed_at'),
        accessor: 'executed_at',
        sortType: '',
        Filter: DateFilter,
        Cell: (cell: Cell<OperationsResponseDto>) => (
          <span>{tHtml('common.date_full', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return <WalletOperationStatusBadge status={row.status} />;
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<OperationsResponseDto>) => {
          const row = cell.row.original;
          return <WalletOperationAmountBadge amount={row.amount} is_receiver={row.is_receiver} />;
        },
        Filter: AmountFilter,
        filterProps: {
          start: -1000,
          end: 50000,
          ignoreLimits: true,
        },
      },
    ],
    [t, tHtml],
  );

  const fetchAllTransactions = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setAllTransactionPaginationRequest(request);
      return Api.projectDeveloper.transactions
        .fetchAllTransactions(request)
        .then((response) => setAllTransactionData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const fetchLoanRepaymentTransactions = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setLoanRepaymentPaginationRequest(request);
      return Api.projectDeveloper.transactions
        .fetchLoanRepaymentTransactions(request)
        .then((response) => setLoanRepaymentTransactionData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const fetchLoanReceiveTransactions = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      setLoanReceivePaginationRequest(request);
      return Api.projectDeveloper.transactions
        .fetchLoanReceiveTransactions(request)
        .then((response) => setLoanReceiveTransactionData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  const exportAllTransactionsCsv = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.projectDeveloper.transactions.exportAllTransactions(
      CommonDocumentTypeEnum.CSV,
      request,
    );
    await downloadCSVFile(response.url, 'all-transactions');
  };

  const exportAllTransactionsXlsx = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.projectDeveloper.transactions.exportAllTransactions(
      CommonDocumentTypeEnum.XLSX,
      request,
    );
    await downloadXLSXFile(response.url, 'all-transactions');
  };

  const exportAllTransactionsXml = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.projectDeveloper.transactions.exportAllTransactions(
      CommonDocumentTypeEnum.XML,
      request,
    );
    await downloadXMLFile(response.url);
  };

  const exportLoanReceiveCsv = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.projectDeveloper.transactions.exportLoanReceiveTransactions(
      CommonDocumentTypeEnum.CSV,
      request,
    );
    await downloadCSVFile(response.url, 'loan-receive-transactions');
  };

  const exportLoanReceiveXlsx = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.projectDeveloper.transactions.exportLoanReceiveTransactions(
      CommonDocumentTypeEnum.XLSX,
      request,
    );
    await downloadXLSXFile(response.url, 'loan-receive-transactions');
  };

  const exportLoanReceiveXml = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.projectDeveloper.transactions.exportLoanReceiveTransactions(
      CommonDocumentTypeEnum.XML,
      request,
    );
    await downloadXMLFile(response.url);
  };

  const exportLoanRepaymentCsv = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.projectDeveloper.transactions.exportLoanRepaymentTransactions(
      CommonDocumentTypeEnum.CSV,
      request,
    );
    await downloadCSVFile(response.url, 'loan-repayments-transactions');
  };

  const exportLoanRepaymentXlsx = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.projectDeveloper.transactions.exportLoanRepaymentTransactions(
      CommonDocumentTypeEnum.XLSX,
      request,
    );
    await downloadXLSXFile(response.url, 'loan-repayments-transactions');
  };

  const exportLoanRepaymentXml = (request: PaginationDataFilter | undefined) => async () => {
    const response = await Api.projectDeveloper.transactions.exportLoanRepaymentTransactions(
      CommonDocumentTypeEnum.XML,
      request,
    );
    await downloadXMLFile(response.url);
  };

  return (
    <div className={'operations py-4'}>
      <TableOverview
        title={tHtml('transactions.all_developer')}
        columns={columns}
        link={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.ALL}
        data={allTransactionData}
        fetchData={fetchAllTransactions}
        initialSortBy={INITIAL_SORT_ORDER}
        exportLinks={[
          {
            onClick: exportAllTransactionsCsv,
            request: allTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.CSV,
          },
          {
            onClick: exportAllTransactionsXlsx,
            request: allTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.XLSX,
          },
          {
            onClick: exportAllTransactionsXml,
            request: allTransactionPaginationRequest,
            type: CommonDocumentTypeEnum.XML,
          },
        ]}
      />
      <TableOverview
        title={tHtml('transactions.loan_repayment')}
        columns={columns}
        link={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.LOAN_REPAYMENT}
        data={loanRepaymentTransactionData}
        fetchData={fetchLoanRepaymentTransactions}
        initialSortBy={INITIAL_SORT_ORDER}
        exportLinks={[
          {
            onClick: exportLoanRepaymentCsv,
            request: loanRepaymentPaginationRequest,
            type: CommonDocumentTypeEnum.CSV,
          },
          {
            onClick: exportLoanRepaymentXlsx,
            request: loanRepaymentPaginationRequest,
            type: CommonDocumentTypeEnum.XLSX,
          },
          {
            onClick: exportLoanRepaymentXml,
            request: loanRepaymentPaginationRequest,
            type: CommonDocumentTypeEnum.XML,
          },
        ]}
      />
      <TableOverview
        title={tHtml('transactions.loan_receive')}
        columns={columns}
        link={RouteList.PROJECT_DEVELOPER.TRANSACTIONS.LOAN_RECEIVE}
        data={loanReceiveTransactionData}
        fetchData={fetchLoanReceiveTransactions}
        initialSortBy={INITIAL_SORT_ORDER}
        exportLinks={[
          {
            onClick: exportLoanReceiveCsv,
            request: loanReceivePaginationRequest,
            type: CommonDocumentTypeEnum.CSV,
          },
          {
            onClick: exportLoanReceiveXlsx,
            request: loanReceivePaginationRequest,
            type: CommonDocumentTypeEnum.XLSX,
          },
          {
            onClick: exportLoanReceiveXml,
            request: loanReceivePaginationRequest,
            type: CommonDocumentTypeEnum.XML,
          },
        ]}
      />
    </div>
  );
};
export default Transactions;
