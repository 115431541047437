import React from 'react';

import { PictureResponseDto } from 'api/types/app';
import Gallery from '../Gallery/Gallery';

type Props = {
  pictures: PictureResponseDto[] | null;
  displayAll?: boolean;
};

const ProjectGallery: React.FC<Props> = ({ pictures, displayAll = true }) => {
  return (
    <Gallery
      loop={true}
      displayAll={displayAll}
      items={(pictures ?? []).map((picture) => ({
        original: picture.url,
        thumbnail: picture.thumbnail_url,
        label: picture.label,
      }))}
    />
  );
};

export default ProjectGallery;
