import React from 'react';
import { Container } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const TransactionSkeleton: React.FC = () => (
  <Container>
    <Skeleton height={800} className={'mb-6'} />
  </Container>
);

export default TransactionSkeleton;
