import React from 'react';

const TrustBoxContainer = () => {
  const trustBoxRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.Trustpilot) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Trustpilot.loadFromElement(trustBoxRef.current, true);
    }
  }, []);
  return (
    <div
      ref={trustBoxRef}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="5bebf1016a785800016bfe0c"
      data-style-height="20px"
      data-style-width="270px"
      data-theme="dark"
      data-font-family="Open Sans"
      id="trustBox"
    >
      <a href="https://www.trustpilot.com/review/profitus.lt" target="_blank" rel="noreferrer">
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBoxContainer;
