import React from 'react';
import WhiteLogo from 'assets/images/lemonway/Lemon-Way-Logo-Inline-200px-white.png';
import useTranslation from 'helpers/useTranslation';

const LemonWayComponent: React.FC = () => {
  const { tHtml } = useTranslation();

  return (
    <div className={'lemonWay'}>
      <a
        title={'Lemon Way - Payment Institution for the new economy'}
        href={'http://www.lemonway.com/'}
        target={'_blank'}
        rel="noreferrer"
      >
        <img style={{ verticalAlign: 'middle' }} src={WhiteLogo} />
      </a>
      <div className={'lemonWay_text'}>
        <h6>{tHtml('footer.payment_operator')}</h6>
        <h6>{tHtml('footer.lemonWay_info')}</h6>
      </div>
    </div>
  );
};

export default LemonWayComponent;
