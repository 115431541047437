import Table from 'components/Table/Table';
import React, { useCallback, useMemo, useState } from 'react';
import { Cell, Column } from 'react-table';
import useTranslation from 'helpers/useTranslation';

import Api from 'api';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { ProjectDeveloperInvoiceListResponseDto } from 'api/types/project-developer/payment';
import { PrimaryButton } from 'components/Button';
import { success } from 'services/toastr';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import ConfirmationModal from 'components/Modal/Modals/ConfirmationModal';
import {
  StyledInvoicesSubComponent,
  StyledInvoicesHeader,
  StyledInvoicesTable,
  StyledInvoicesWrapper,
} from './Invoices.style';
import DownloadLink from 'components/DownloadLink';

const UpcomingPayments: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [data, setData] = useState<PaginationData<ProjectDeveloperInvoiceListResponseDto>>();

  const columns = useMemo<Column<ProjectDeveloperInvoiceListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.invoice_name'),
        accessor: 'name',
        sortType: 'string',
      },
      {
        Header: t('table.invoice_payment_amount'),
        accessor: 'amount',
        sortType: 'number',
        Cell: (cell: Cell<ProjectDeveloperInvoiceListResponseDto>) => (
          <span>{tHtml('common.money', { value: cell.value })}</span>
        ),
      },
      {
        Header: t('table.invoice_payment_date'),
        accessor: 'payment_date',
        sortType: 'datetime',
        width: 150,
        Cell: (cell: Cell<ProjectDeveloperInvoiceListResponseDto>) => (
          <span>{tHtml('common.date', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.invoice_document'),
        accessor: 'document',
        disableSortBy: true,
        isOverflowVisible: true,
        Cell: (cell: Cell<ProjectDeveloperInvoiceListResponseDto>) => {
          return (
            <DownloadLink
              url={cell.value.url}
              type={cell.value.mime_type}
              name={cell.value.name}
              showMeta={false}
            />
          );
        },
      },
      {
        disableSortBy: true,
        accessor: 'id',
        Cell: (cell: Cell<ProjectDeveloperInvoiceListResponseDto> | any) => {
          const row = cell.row.original;
          return (
            <PrimaryButton
              onClick={() =>
                showModal(
                  <ConfirmationModal
                    title={tHtml('payments.make_invoice_payment.confirmation')}
                    onSubmit={() =>
                      Api.projectDeveloper.payments.makeInvoicePayment(row.id).then(() => {
                        success(tHtml('payments.make_invoice_payment.success'));
                        cell.dispatch({ type: 'refreshData' });
                      })
                    }
                  />,
                )
              }
            >
              {tHtml('payments.make_invoice_payment')}
            </PrimaryButton>
          );
        },
      },
    ],
    [showModal, t, tHtml],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.projectDeveloper.payments
        .filterInvoices(request)
        .then((response) => setData(response));
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [t],
  );

  return (
    <StyledInvoicesWrapper>
      <StyledInvoicesHeader>{tHtml('payments.invoices')}</StyledInvoicesHeader>
      <StyledInvoicesTable>
        <Table
          columns={columns}
          data={data}
          onFetchData={fetchData}
          defaultLimit={5}
          mobile={true}
          searchable={false}
          renderRowSubComponent={(row) => {
            return (
              <StyledInvoicesSubComponent>{row.row.original.purpose}</StyledInvoicesSubComponent>
            );
          }}
        />
      </StyledInvoicesTable>
    </StyledInvoicesWrapper>
  );
};

export default UpcomingPayments;
