import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import Api from 'api';
import { RouteList } from 'routes';
import { navigate } from 'helpers';
import { delay } from 'helpers/utils';
import { success } from 'services/toastr';
import CheckboxInputGroup from 'components/Formik/CheckboxGroup/CheckboxInputGroup';
import { CheckboxInput, PrimaryButton } from 'components/Formik';
import RadioInputGroup from 'components/Formik/RadioGroup/RadioInputGroup';
import FileUpload, { FileUploadHandle } from 'components/FileUpload/FileUpload';
import YesNoSelector from 'components/Formik/YesNoSelector';
import { useAccountQuery } from '../../api/queries';
import { useAuth } from '../../services/useAuth';
import { useUserCompanyProfileQuery } from '../../api/queries/useUserCompanyProfileQuery';
import { useStepsModalStateStore } from '../../scopes/investor/modals/RequiredSteps/store';
import ShareholdersFormBlock from 'scopes/guest/pages/ProspectApplication/Beneficiaries/ShareholdersFormBlock';
import BeneficiariesFormBlock from '../../scopes/guest/pages/ProspectApplication/Beneficiaries/BeneficiariesFormBlock';
import PepsFormBlock from '../../scopes/guest/pages/ProspectApplication/Beneficiaries/PepsFormBlock';
import TextInputLabelHighlighted from '../../scopes/user/pages/EditProfile/components/TextInputLabelHighlighted';
import InvestorKybValidationSchema from '../../scopes/user/pages/CreateCompany/ValidationSchemas/InvestorKybValidationSchema';
import SelectInvestmentPurposeInput from 'components/Formik/Selects/SelectInvestmentPurposeInput';
import OriginOfFundsInput from '../Formik/Selects/OriginOfFundsInput';
import { InvestorKybDataV2RequestDto } from '../../api/types/shared/company/profile';
import { KybVersionEnum } from '../../helpers/enums/KybVersionEnum';

type IFormValues = InvestorKybDataV2RequestDto;

interface Props {
  companyId: string;
  isEdit?: boolean;
}

const InvestorKybForm: React.FC<Props> = ({ companyId, isEdit }) => {
  const { t, tHtml } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const modalState = useStepsModalStateStore();
  const { login } = useAuth();
  const { invalidate: invalidateAccount } = useAccountQuery();
  const { data: companyProfileData, setQueryData: updateCompanyProfileData } =
    useUserCompanyProfileQuery();

  const kyb = isEdit
    ? (companyProfileData?.know_your_business?.kyb_data as InvestorKybDataV2RequestDto)
    : null;

  const getInitialValues = (): InvestorKybDataV2RequestDto => {
    if (companyProfileData?.know_your_business?.version === KybVersionEnum.V2) {
      return {
        purpose: kyb?.purpose ?? [],
        other_purpose_text: kyb?.other_purpose_text ?? '',
        activities: kyb?.activities ?? [],
        other_activities_text: kyb?.other_activities_text ?? '',
        average_income: kyb?.average_income ?? 0,
        cash_usage: kyb?.cash_usage ?? '',
        activity_regions: kyb?.activity_regions ?? [],
        other_activity_regions_text: kyb?.other_activity_regions_text ?? '',
        is_not_operating_in_russia_belarus_ukraine_occupied_territories:
          !!kyb?.is_not_operating_in_russia_belarus_ukraine_occupied_territories,
        business_partners: kyb?.business_partners ?? '',
        has_bankruptcy_procedure: kyb?.has_bankruptcy_procedure ?? undefined,
        convicted: kyb?.convicted ?? undefined,
        planned_investment_amount: kyb?.planned_investment_amount ?? '',
        origin_of_invested_funds: kyb?.origin_of_invested_funds ?? [],
        other_origin_of_invested_funds_text: kyb?.other_origin_of_invested_funds_text ?? null,
        shareholders: kyb?.shareholders ?? [],
        has_shareholders_with_more_than_25_percent:
          kyb?.has_shareholders_with_more_than_25_percent ?? undefined,
        beneficiaries: kyb?.beneficiaries ?? [],
        public_positions_held_by_related_individuals:
          kyb?.public_positions_held_by_related_individuals ?? undefined,
        peps: kyb?.peps ?? [],
        has_confirmed_data_integrity: false,
        has_confirmed_legitimacy: false,
        documents: kyb?.documents ?? [],
      };
    }

    return {
      purpose: [],
      other_purpose_text: '',
      activities: [],
      other_activities_text: '',
      average_income: 0,
      cash_usage: '',
      activity_regions: [],
      other_activity_regions_text: '',
      is_not_operating_in_russia_belarus_ukraine_occupied_territories: false,
      business_partners: '',
      has_bankruptcy_procedure: undefined,
      convicted: undefined,
      planned_investment_amount: '',
      origin_of_invested_funds: [],
      other_origin_of_invested_funds_text: null,
      shareholders: [],
      has_shareholders_with_more_than_25_percent: undefined,
      beneficiaries: [],
      public_positions_held_by_related_individuals: undefined,
      peps: [],
      has_confirmed_data_integrity: false,
      has_confirmed_legitimacy: false,
      documents: [],
    };
  };

  const [initialValues] = useState<IFormValues>(getInitialValues());

  const cashUsageValues = ['more_than_half', 'middle', 'less_than_30', 'no_use'];

  const plannedInvestmentAmounts: string[] = [
    'investment_1',
    'investment_2',
    'investment_3',
    'investment_4',
    'investment_5',
    'investment_6',
    'investment_7',
  ];

  const ACTIVITY_KEYS = [
    'real_estate_development',
    'real_estate_brokerage',
    'retail',
    'service_provision',
    'agriculture',
    'artistic_intellectual',
    'legal_services',
    'gas_industry_activities',
    'military_technology',
    'chemicals_industry',
    'dual_use_goods',
    'cryptocurrencies',
    'art_antiques_trade',
    'gambling_activities',
    'charity',
    'other',
  ];

  const activityRegions: string[] = ['lithuania', 'eu/eea', 'cis', 'usa/canada', 'other'];

  useEffect(() => {
    modalState.setShouldBeShown({ state: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    async (request: IFormValues, helpers: FormikHelpers<any>) => {
      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (fileUploadRef?.current?.isFilesUploading()) {
          await delay();
          return await helpers.submitForm();
        }

        request.other_purpose_text = request.purpose.includes('other')
          ? request.other_purpose_text
          : '';
        request.other_activities_text = request.activities.includes('other')
          ? request.other_activities_text
          : '';
        request.other_activity_regions_text = request.activity_regions.includes('other')
          ? request.other_activity_regions_text
          : '';
        request.other_origin_of_invested_funds_text = request.origin_of_invested_funds.includes(
          'other',
        )
          ? request.other_origin_of_invested_funds_text
          : '';

        if (isEdit) {
          await Api.shared.company.profile
            .storeCompanyInvestorKYB(companyId, request)
            .then(async () => {
              const newProfile = await Api.shared.company.profile.fetchCompanyProfile();
              updateCompanyProfileData(newProfile);
              invalidateAccount();
            })
            .then(() => navigate(RouteList.COMPANY.PROFILE.HOME));
        } else {
          await Api.shared.user.company
            .storeCompanyInvestorKYB(companyId, request)
            .then(async () => {
              const response = await Api.shared.loginAs.companyLogin(companyId);

              if (response.access_token) {
                await login(response.access_token, response.permissions);
              }
            });
        }

        success(
          isEdit ? tHtml('common.created_successfully') : tHtml('common.updated_successfully'),
        );
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEdit, tHtml, companyId, updateCompanyProfileData, invalidateAccount],
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={InvestorKybValidationSchema}
      >
        {(helpers) => (
          <Form onSubmit={helpers.handleSubmit}>
            <FormGroup>
              <div className={'mb-4'}>
                <SelectInvestmentPurposeInput placeholder={tHtml('placeholder.company.purpose')} />
              </div>
              <div className={'mb-4'}>
                <CheckboxInputGroup
                  name={'activities'}
                  values={ACTIVITY_KEYS}
                  valuesWithText={['other']}
                  showPlaceholder={true}
                  placeholder={t('placeholder.company.activities')}
                />
              </div>
              <div className={'mb-4'}>
                <TextInputLabelHighlighted
                  name={'average_income'}
                  placeholder={t('placeholder.company.average_income')}
                  type={'number'}
                />
              </div>
              <div className={'mb-4 pt-1'}>
                <RadioInputGroup
                  name={'cash_usage'}
                  values={cashUsageValues}
                  showPlaceholder={true}
                  placeholder={tHtml('placeholder.company.cash_usage')}
                  placeholderValuesPrefix={'placeholder.prospect_application.cash_usage.'}
                />
              </div>
              <div className={'mb-4'}>
                <CheckboxInputGroup
                  name={'activity_regions'}
                  values={activityRegions}
                  valuesWithText={['other']}
                  showPlaceholder={true}
                  placeholder={t('placeholder.company.activity_regions')}
                />
              </div>
              <div className={'mb-4'}>
                <CheckboxInput
                  name={'is_not_operating_in_russia_belarus_ukraine_occupied_territories'}
                  placeholder={tHtml(
                    'placeholder.company.is_not_operating_in_russia_belarus_ukraine_occupied_territories',
                  )}
                />
              </div>
              <div className={'mb-4'}>
                <TextInputLabelHighlighted
                  name={'business_partners'}
                  placeholder={t('placeholder.company.business_partners')}
                />
              </div>
              <div className={'mb-4'}>
                <YesNoSelector
                  name={'has_bankruptcy_procedure'}
                  placeholder={tHtml('placeholder.company.has_bankruptcy_procedure')}
                />
              </div>
              <div className={'mb-4'}>
                <YesNoSelector
                  name={'convicted'}
                  placeholder={tHtml('placeholder.company.convicted')}
                />
              </div>
              <hr />
              <div className={'mb-4'}>
                <RadioInputGroup
                  name={'planned_investment_amount'}
                  values={plannedInvestmentAmounts}
                  showPlaceholder={true}
                  placeholder={tHtml('placeholder.company.planned_investment_amount')}
                />
              </div>
              <div className={'mb-4'}>
                <OriginOfFundsInput />
              </div>
              <hr />
              <div className={'mb-4'}>
                <YesNoSelector
                  name={'has_shareholders_with_more_than_25_percent'}
                  placeholder={t(
                    'placeholder.prospect_application.has_shareholders_with_more_than_25_percent',
                  )}
                />
              </div>
              {helpers.values?.has_shareholders_with_more_than_25_percent === true && (
                <>
                  <h5 className={'mb-4 mt-4'}>
                    {tHtml('placeholder.company.shareholders.specify')}
                  </h5>
                  <ShareholdersFormBlock
                    fieldName={'shareholders'}
                    values={helpers.values.shareholders}
                    formikHelpers={helpers}
                  />
                </>
              )}
              <h5 className={'mb-4 mt-4'}>{tHtml('placeholder.company.beneficiary.specify')}</h5>
              <BeneficiariesFormBlock
                dependencyFieldName={'shareholders'}
                selectorDisabledFieldName={'has_shareholders_with_more_than_25_percent'}
                fieldName={'beneficiaries'}
                values={helpers.values.beneficiaries}
                formikHelpers={helpers}
              />
              <hr />
              <div className={'mb-4'}>
                <YesNoSelector
                  name={'public_positions_held_by_related_individuals'}
                  placeholder={t(
                    'placeholder.prospect_application.public_positions_held_by_related_individuals',
                  )}
                />
              </div>
              {helpers.values?.public_positions_held_by_related_individuals === true && (
                <>
                  <PepsFormBlock
                    fieldName={'peps'}
                    values={helpers.values.peps}
                    formikHelpers={helpers}
                  />
                </>
              )}
              <hr />
              <div className={'mt-4'}>
                <h4>{tHtml('placeholder.documents')}</h4>
                <p>
                  <small>{tHtml('placeholder.company.documents')}</small>
                </p>
                <FileUpload
                  ref={fileUploadRef}
                  name={'documents'}
                  onPresign={(request) =>
                    isEdit
                      ? Api.shared.company.profile.uploadCompanyDocuments(request)
                      : Api.shared.user.company.uploadCompanyDocuments(request)
                  }
                  disabled={helpers.isSubmitting}
                />
                <hr />
              </div>
              <div className={'mb-3'}>
                <CheckboxInput
                  name={'has_confirmed_data_integrity'}
                  placeholder={tHtml('placeholder.confirm_data_integrity')}
                />
              </div>
              <div className={'mb-4'}>
                <CheckboxInput
                  name={'has_confirmed_legitimacy'}
                  placeholder={tHtml('placeholder.company.has_confirmed_legitimacy')}
                />
              </div>
              <div className={'mt-4 mb-4'}>
                <PrimaryButton
                  className={'btn btn-primary w-50'}
                  type={'submit'}
                  submitting={helpers.isSubmitting}
                >
                  {tHtml('common.submit')}
                </PrimaryButton>
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </>
  );
};

InvestorKybForm.defaultProps = {
  isEdit: false,
};

export default InvestorKybForm;
