import React from 'react';
import { useLocalStorage } from 'helpers';
import { CommonEnum } from 'helpers/enums/CommonEnum';
import { ThemeEnum } from 'helpers/enums/ThemeEnum';

import LIGHT_ICON from '../../assets/images/icons/navbar/sun.svg';
import DARK_ICON from '../../assets/images/icons/navbar/moon.svg';
import {
  StyledDesktopThemeSwitcher,
  StyledDesktopThemeSwitcherButtonDark,
  StyledDesktopThemeSwitcherButtonLight,
} from './ThemeSwitcher.style';

const ThemeSwitcher: React.FC = () => {
  const [theme, setTheme] = useLocalStorage<string>(CommonEnum.Theme, ThemeEnum.Light);

  React.useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <StyledDesktopThemeSwitcher>
      <StyledDesktopThemeSwitcherButtonLight
        onClick={(e) => {
          e.preventDefault();
          setTheme(ThemeEnum.Light);
        }}
      >
        <img src={LIGHT_ICON} alt="light theme" />
      </StyledDesktopThemeSwitcherButtonLight>
      <StyledDesktopThemeSwitcherButtonDark
        onClick={(e) => {
          e.preventDefault();
          setTheme(ThemeEnum.Dark);
        }}
      >
        <img src={DARK_ICON} alt="dark theme" />
      </StyledDesktopThemeSwitcherButtonDark>
    </StyledDesktopThemeSwitcher>
  );
};

export default ThemeSwitcher;
