import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { StepStatusEnum } from 'helpers/enums/StepStatusEnum';
import { registrationSteps, Step } from './steps';

const RegistrationStepWizard: React.FC = () => {
  const { tHtml } = useTranslation();
  const location = useLocation();
  const [steps, setSteps] = useState<Array<Step>>([]);

  useEffect(() => {
    const tempSteps = registrationSteps;
    const currentIdx = tempSteps.findIndex((x) => x.route === location.pathname);

    if (currentIdx < 0) {
      return;
    }

    for (let i = 0; i < tempSteps.length; i++) {
      if (tempSteps[i].status === StepStatusEnum.Hidden) continue;

      if (i < currentIdx) {
        tempSteps[i].status = StepStatusEnum.Complete;
      } else if (i == currentIdx) {
        tempSteps[i].status = StepStatusEnum.Active;
      } else {
        tempSteps[i].status = StepStatusEnum.Incomplete;
      }
    }

    setSteps(tempSteps);
  }, [location]);

  const getClassName = (status: StepStatusEnum) => {
    switch (status) {
      case StepStatusEnum.Complete:
        return 'completed';
      case StepStatusEnum.Active:
        return 'active';
      default:
        return '';
    }
  };

  return (
    <div className="steps">
      {steps.map((step, idx) => {
        if (step.status == StepStatusEnum.Hidden) return '';

        return (
          <div key={step.name} className={`step ${getClassName(step.status)}`}>
            <div className="number">{idx + 1}</div>
            <div className="text">{tHtml('authentication.step_' + step.name)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default RegistrationStepWizard;
