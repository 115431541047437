import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { ModalBody, ModalHeader } from 'reactstrap';

import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import HighlightButton from 'components/Button/HighlightButton';
import { PrimaryButton } from 'components/Formik';
import { success, error } from 'services/toastr';
import Api from 'api';

interface Props extends ModalComponentProps {
  projectId: string;
}

const WantToInvest: React.FC<Props> = ({ projectId, ...props }) => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    try {
      Api.investor.projects.wantToInvestInProject(projectId).then(() => {
        success(tHtml('common.success'));
        setIsLoading(false);
        hideModal();
      });
    } catch (e) {
      error(tHtml('common.error'));
    }
  };

  return (
    <React.Fragment>
      <ModalComponent size={'md'} {...props} contentClassName={'modal-investment'}>
        <div className={'p-3'}>
          <ModalHeader
            toggle={hideModal}
            close={<ModalCloseButton />}
            tag={'h2'}
            className={'mt-2'}
          />
          <ModalBody>
            <div className={'w-100'}>
              <p>{tHtml('projects.want_to_invest_modal')}</p>
              <div className={'d-flex justify-content-around mt-3'}>
                <HighlightButton
                  className={'btn btn-highlight w-25 fw-normal me-3'}
                  onClick={hideModal}
                >
                  {tHtml('common.cancel')}
                </HighlightButton>
                <PrimaryButton
                  className={'btn btn-primary w-25 fw-normal'}
                  submitting={isLoading}
                  onClick={() => {
                    setIsLoading(true);
                    onSubmit();
                  }}
                >
                  {tHtml('common.yes')}
                </PrimaryButton>
              </div>
            </div>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default WantToInvest;
