import React from 'react';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import Api from 'api';
import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { PrimaryButton } from 'components/Formik';

interface Props extends ModalComponentProps {
  body_text: string;
  value: string;
}

const QuizAlertModal: React.FC<Props> = ({ body_text, value, ...props }) => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const onAgreedWithRules = () => {
    Api.investor.quiz.storeInvestorQuizRiskAgreement({ alert: value }).then(() => {
      hideModal();
    });
  };

  return (
    <React.Fragment>
      <ModalComponent size={'lg'} {...props} backdrop={'static'}>
        <div className={'p-2 p-lg-5'}>
          <ModalHeader className={'h2'}>{tHtml('modal.quiz.title')}</ModalHeader>
          <ModalBody className={''}>
            <p className={'text-pre'}>
              <div className={`html-block word-break`}>{tHtml(body_text)}</div>
            </p>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-center'}>
            <div className={'w-50'}>
              <PrimaryButton onClick={onAgreedWithRules}>
                {tHtml('quiz.quiz_alert_agreement')}
              </PrimaryButton>
            </div>
          </ModalFooter>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default QuizAlertModal;
