import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const ScrollToTop: React.FC<RouteComponentProps> = ({ history }) => {
  const [previousPathname, setPreviousPathname] = useState<string>('');

  useEffect(() => {
    const unlisten = history.listen((e) => {
      if (previousPathname != e.pathname) {
        window.scrollTo(0, 0);
        setPreviousPathname(() => e.pathname);
      }
    });
    return () => {
      unlisten();
    };
  }, [history, previousPathname, setPreviousPathname]);

  return null;
};

export default withRouter(ScrollToTop);
