import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const DashboardSkeleton: React.FC = () => (
  <>
    <div className={'page-layout wallet mb-8'}>
      <div className={'mb-6'}>
        <Row className={'justify-content-center'}>
          <Col lg={4}>
            <div className={'page-layout wallet'}>
              <div className={'panel mb-3'}>
                <Card body className={'light-pink balance py-4 px-3'}>
                  <Row className={'balance-items'}>
                    <Col lg={12} md={6} className={'balance-information'}>
                      <Skeleton />
                    </Col>
                    <Col lg={12} md={6} className={'balance-information d-flex align-items-center'}>
                      <Skeleton />
                    </Col>
                  </Row>
                </Card>
              </div>
            </div>
          </Col>
          <Col lg={8}>
            <Skeleton />
          </Col>
        </Row>
        <Row className={'justify-content-center'}>
          <Col lg={4}>
            <Skeleton />
          </Col>
          <Col lg={8}>
            <Skeleton />
          </Col>
        </Row>
      </div>
    </div>
    <div className={'my-6'}>
      <Skeleton />
    </div>
    <Skeleton />
  </>
);

export default DashboardSkeleton;
