import React from 'react';

import { RouteList } from 'routes';
import * as Roles from 'helpers/auth/roles';
import AuthRoute from 'routes/AuthRoute';
import { Overview, TopUp, Transactions, Withdraw } from './index';
import PaymentSuccess from 'scopes/shared/pages/Wallet/redirects/PaymentSuccess';
import PaymentCancel from 'scopes/shared/pages/Wallet/redirects/PaymentCancel';
import PaymentError from 'scopes/shared/pages/Wallet/redirects/PaymentError';
import LayoutWrapper from '../../containers/LayoutWrapper';
import { Permission } from 'helpers/auth/permissions';

const WalletRoutes: any = () => [
  <AuthRoute
    path={RouteList.SHARED.WALLET.OVERVIEW}
    component={Overview}
    layout={LayoutWrapper}
    permissions={[Permission.WALLET_VERIFIED]}
    redirect={Roles.User.redirect}
    key={RouteList.SHARED.WALLET.OVERVIEW}
    exact
  />,
  <AuthRoute
    path={RouteList.SHARED.WALLET.WITHDRAW}
    component={Withdraw}
    layout={LayoutWrapper}
    permissions={[Permission.WALLET_VERIFIED]}
    redirect={Roles.User.redirect}
    key={RouteList.SHARED.WALLET.WITHDRAW}
    exact
  />,
  <AuthRoute
    path={RouteList.SHARED.WALLET.TOP_UP}
    component={TopUp}
    layout={LayoutWrapper}
    permissions={[Permission.WALLET_VERIFIED]}
    redirect={Roles.User.redirect}
    key={RouteList.SHARED.WALLET.TOP_UP}
    exact
  />,
  <AuthRoute
    path={RouteList.SHARED.WALLET.TRANSACTIONS}
    component={Transactions}
    layout={LayoutWrapper}
    permissions={[Permission.WALLET_VERIFIED]}
    redirect={Roles.User.redirect}
    key={RouteList.SHARED.WALLET.TRANSACTIONS}
    exact
  />,
  <AuthRoute
    path={RouteList.SHARED.WALLET.PAYMENTS.SUCCESS}
    component={PaymentSuccess}
    layout={LayoutWrapper}
    permissions={[Permission.WALLET_VERIFIED]}
    redirect={Roles.User.redirect}
    key={RouteList.SHARED.WALLET.PAYMENTS.SUCCESS}
    exact
  />,
  <AuthRoute
    path={RouteList.SHARED.WALLET.PAYMENTS.CANCEL}
    component={PaymentCancel}
    layout={LayoutWrapper}
    permissions={[Permission.WALLET_VERIFIED]}
    redirect={Roles.User.redirect}
    key={RouteList.SHARED.WALLET.PAYMENTS.CANCEL}
    exact
  />,
  <AuthRoute
    path={RouteList.SHARED.WALLET.PAYMENTS.ERROR}
    component={PaymentError}
    layout={LayoutWrapper}
    permissions={[Permission.WALLET_VERIFIED]}
    redirect={Roles.User.redirect}
    key={RouteList.SHARED.WALLET.PAYMENTS.ERROR}
    exact
  />,
];

export default WalletRoutes;
