import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { useLocation, withRouter } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import Api from 'api';
import StepFormHeader from 'containers/StepFormLayout/StepFormHeader';
import { PrimaryButton } from 'components/Button';
import { useAuth } from 'services/useAuth';

interface LocationProps {
  state: {
    id: string;
  };
}

const CreateCompanySuccess: React.FC = () => {
  const { tHtml } = useTranslation();
  const location: LocationProps = useLocation();
  const { id } = location.state;
  const { login } = useAuth();

  const loginAsCompany = async (companyId: string) => {
    if (companyId) {
      const response = await Api.shared.loginAs.companyLogin(companyId);

      if (response.access_token) {
        await login(response.access_token, response.permissions);
      }
    }
  };

  return (
    <>
      <StepFormHeader showFinishLater={false} />
      <Container className={'main-content d-block mx-auto px-2 px-sm-4 px-md-6'}>
        <Row className="col-12 col-md-10 col-lg-8 mx-auto pt-6">
          <h1 className="headline__success">
            <i className="icon-circle-check me-2" />
            <span>{tHtml('company.created_successfully')}</span>
          </h1>
          <div className="mt-4 mb-4">
            <PrimaryButton onClick={() => loginAsCompany(id)} className="btn btn-primary w-50">
              {tHtml('common.close')}
            </PrimaryButton>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(CreateCompanySuccess);
