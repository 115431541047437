import React from 'react';

import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import * as Roles from 'helpers/auth/roles';
import PageLayout from 'scopes/developer/containers/PageLayout';
import { MyProjectList } from '.';

const MyProjectRoutes: any = () => [
  <AuthRoute
    path={RouteList.PROJECT_DEVELOPER.PROJECTS.LIST}
    component={MyProjectList}
    layout={PageLayout}
    permissions={Roles.User.permissions} // TODO only project-developer can access this route
    redirect={Roles.User.redirect}
    key={RouteList.PROJECT_DEVELOPER.PROJECTS.LIST}
    exact
  />,
];

export default MyProjectRoutes;
