import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Card, Col, Row } from 'reactstrap';

import { RouteList } from 'routes';
import { FunctionalButton } from 'components/Button';
import MoneyOutForm from './components/MoneyOutForm';
import StoreBankAccountForm from './components/StoreBankAccountForm';
import { WalletBankAccountStatusEnum } from 'helpers/enums/wallet/WalletBankAccountStatusEnum';
import SectionFaq from './components/SectionFaq';
import WalletAmount from 'components/Wallet/WalletAmount';
import { useWalletQuery } from 'api/queries/useWalletQuery';
import { isWalletProviderObsolete } from 'helpers/enums/wallet/WalletProviderEnum';

const Withdraw: React.FC = () => {
  const { tHtml } = useTranslation();

  const { data: wallet, isFetching } = useWalletQuery();

  return (
    <div className={'page-layout wallet'}>
      <div className={'mb-5 d-flex justify-content-start'}>
        <Link to={RouteList.SHARED.WALLET.OVERVIEW}>
          <FunctionalButton>
            <i className={'icon icon-arrow-left'} /> {tHtml('common.wallet')}
          </FunctionalButton>
        </Link>
      </div>
      <div>
        <h2>{tHtml('wallet.withdraw_money')}</h2>
        <div className={'panel'}>
          <div>
            <Card body className={'light-pink balance'}>
              <Row className={'balance-items'}>
                <Col lg={8} md={6} className={'balance-information'}>
                  <WalletAmount />
                  <div className={'balance-description'}>{tHtml('wallet.account_balance')}</div>
                </Col>
              </Row>
            </Card>
          </div>
          <div className={'content'}>
            <div className={'wallet-actions'}>
              <StoreBankAccountForm
                isDisabled={
                  isFetching ||
                  !!wallet?.bank_accounts?.some(
                    (bankAccount) =>
                      bankAccount.status === WalletBankAccountStatusEnum.ToBeVerified,
                  ) ||
                  isWalletProviderObsolete(wallet?.provider)
                }
              />
            </div>
            <div className={'wallet-actions'}>
              <MoneyOutForm bankAccounts={wallet?.bank_accounts ?? []} />
            </div>
          </div>
        </div>
      </div>
      <div className={'wallet-faq-wrapper'}>
        <SectionFaq />
      </div>
    </div>
  );
};

export default withRouter(Withdraw);
