import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { action } from 'typesafe-actions';

import Api from 'api';
import { StoreApplicationDocumentsRequestDto } from 'api/types/project-developer/application';
import { setGlobalLoading } from 'modules/app/actions';
import { PrimaryButton } from 'components/Formik';
import { success } from 'services/toastr';
import FileUpload, { FileUploadHandle } from 'components/FileUpload/FileUpload';
import { delay } from 'helpers/utils';
import { ApplicationContext } from './module/ApplicationContext';
import { UPDATE_APPLICATION } from './module/ApplicationReducer';
import { ApplicationPageProps } from './steps';
import { redirect } from './CreateApplication';

const Development: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
  isLastStep,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();
  const documentUploadRef = React.useRef<FileUploadHandle>(null);
  const pictureUploadRef = React.useRef<FileUploadHandle>(null);

  const { state, setApplicationId, dispatch } = useContext(ApplicationContext);

  useEffect(() => {
    if (applicationId !== undefined) {
      setApplicationId(applicationId);
    }

    setGlobalLoading(false);
  }, [t, applicationId, setApplicationId]);

  const [initialFormValues, setInitialFormValues] = useState<StoreApplicationDocumentsRequestDto>({
    documents: [],
    pictures: [],
  });

  useEffect(() => {
    setInitialFormValues({
      documents: state.application?.documents?.documents ?? [],
      pictures: state.application?.documents?.pictures ?? [],
    });
  }, [state]);

  const StoreApplicationDocumentsSchema = Yup.object().shape({
    documents: Yup.array(),
    pictures: Yup.array(),
  });

  const onSubmit = useCallback(
    async (
      request: StoreApplicationDocumentsRequestDto,
      helpers: FormikHelpers<StoreApplicationDocumentsRequestDto>,
    ) => {
      try {
        // this method returns true, if new files added, which indicates to resubmit form
        if (
          pictureUploadRef?.current?.isFilesUploading() ||
          documentUploadRef.current?.isFilesUploading()
        ) {
          await delay();
          return await helpers.submitForm();
        }

        await Api.projectDeveloper.applications
          .storeApplicationDocuments(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));

            if (isLastStep) {
              success(tHtml('application.created_successfully'));
            }

            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [applicationId, dispatch, history, isLastStep, nextPage, tHtml],
  );

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          <h1 className="mb-4 mb-md-6">{tHtml('application.documents_title')}</h1>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={StoreApplicationDocumentsSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="mb-3">
                    <h4>{tHtml('application.documents')}</h4>
                    <p>
                      <small>{tHtml('application.documents.description')}</small>
                    </p>

                    <FileUpload
                      ref={documentUploadRef}
                      name={'documents'}
                      onPresign={(request) =>
                        Api.projectDeveloper.applications.uploadDocuments(request)
                      }
                      disabled={isSubmitting}
                    />
                  </div>
                  <hr />
                  <div className="mb-3">
                    <h4>{tHtml('application.pictures')}</h4>
                    <p>
                      <small>{tHtml('application.pictures.description')}</small>
                    </p>
                    <FileUpload
                      ref={pictureUploadRef}
                      name={'pictures'}
                      onPresign={(request) =>
                        Api.projectDeveloper.applications.uploadPictures(request)
                      }
                      disabled={isSubmitting}
                    />
                  </div>
                  <hr />
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <PrimaryButton
                      className={'btn btn-grey w-25'}
                      type={'button'}
                      onClick={() => {
                        redirect(history, previousPage, applicationId);
                      }}
                    >
                      {tHtml('common.back')}
                    </PrimaryButton>
                    <PrimaryButton className={'btn btn-primary w-25'} submitting={isSubmitting}>
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </div>
      </Row>
    </>
  );
};

export default withRouter(Development);
