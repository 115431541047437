import { useField, Field, FieldProps, ErrorMessage } from 'formik';
import React, { ReactElement, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import ScrollToOnError from './ScrollToOnError';

export interface Props
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'placeholder'
  > {
  name: string;
  placeholder?: string | ReactElement;
  handleClick?: (value: boolean) => void;
  hideLabel?: boolean;
}

const YesNoSelector: React.FC<Props> = ({ hideLabel = false, ...props }) => {
  const { tHtml } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  React.useEffect(() => {
    helper.setTouched(true, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const [checked, setChecked] = useState<boolean>(meta.value);

  if (!props.placeholder) {
    props.placeholder = tHtml('placeholder.' + props.value);
  }

  const isError = meta?.touched && !!meta?.error;

  return (
    <ScrollToOnError name={props.name} isError={isError}>
      {hideLabel ? null : <span className="form-question-label">{props.placeholder}</span>}{' '}
      <div className={`d-flex gap-2 py-2 ${isError ? 'is-invalid' : ''}`}>
        <Field
          component={({ form }: FieldProps) => (
            <button
              name={props.name}
              type="button"
              onClick={() => {
                form.getFieldHelpers(props.name).setValue(true);
                setChecked(true);
                if (props.handleClick) {
                  props.handleClick(true);
                }
              }}
              value="true"
              className={`site btn btn-highlight ${checked === true ? 'active' : ''}`}
            >
              {tHtml('common.yes')}
            </button>
          )}
        />
        <Field
          component={({ form }: FieldProps) => (
            <button
              name={props.name}
              onClick={() => {
                form.getFieldHelpers(props.name).setValue(false);
                setChecked(false);
                if (props.handleClick) {
                  props.handleClick(false);
                }
              }}
              type="button"
              value=""
              className={`site btn btn-highlight ${checked === false ? 'active' : ''}`}
            >
              {tHtml('common.no')}
            </button>
          )}
        />
      </div>
      {isError && (
        <div className={'invalid-feedback ms-2'}>
          {props.name && <ErrorMessage name={props.name} />}
        </div>
      )}
    </ScrollToOnError>
  );
};

export default YesNoSelector;
