import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import SidebarImage from 'assets/images/img-registration.png';
import { BaseLayout } from 'containers';
import ResponsiveLogo from 'components/Logo/ResponsiveLogo';
import LocaleSwitcher from 'components/LocaleSwitcher';
import RegistrationStepWizard from './RegistrationStepWizard';
import FillLaterWizard from './FillLaterWizard';
import { HighlightButton } from 'components/Button';
import useTranslation from 'helpers/useTranslation';
import { LANDING_ROUTES } from 'routes';
import { useAuth } from 'services/useAuth';

const RegistrationStepLayout: React.FC = ({ children }) => {
  const { tHtml, currentLanguage } = useTranslation();
  const { logout } = useAuth();

  return (
    <React.Fragment>
      <BaseLayout>
        <header />
        <div className="registration-step-layout vertical-center">
          <Container>
            <Row>
              <Col xl={8} className="header d-flex justify-content-center">
                <div className="content w-100">
                  <Row>
                    <Col
                      sm={12}
                      md={9}
                      className={'d-flex justify-content-between align-items-center'}
                    >
                      <ResponsiveLogo />
                      <div className={'d-flex ms-2'}>
                        <div>
                          <a href={LANDING_ROUTES[currentLanguage].HOME}>
                            <HighlightButton
                              className={'btn btn-highlight btn-lg px-3 py-2'}
                              onClick={() => {
                                logout();
                              }}
                            >
                              {tHtml('common.logout')}
                            </HighlightButton>
                          </a>
                        </div>
                        <div className={'align-self-center'}>
                          <LocaleSwitcher />
                        </div>
                      </div>
                    </Col>
                    <Col sm={0} md={3} className={'d-none d-md-flex'}>
                      <FillLaterWizard />
                    </Col>
                  </Row>
                  <div>
                    <RegistrationStepWizard />
                  </div>
                  <div className="d-flex flex-column justify-content-center justify-content-xl-start w-100">
                    <div className="content">{children}</div>
                    <div className={'d-flex d-md-none'}>
                      <FillLaterWizard />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} className="d-none d-xl-block">
                <div className="sidebar d-flex justify-content-center">
                  <img src={SidebarImage} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </BaseLayout>
    </React.Fragment>
  );
};
export default withRouter(RegistrationStepLayout);
