import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import useTranslation from 'helpers/useTranslation';

import Api from 'api';
import { UpdateUserPasswordRequestDto } from 'api/types/shared/user/profile';
import { RouteList } from 'routes';
import PasswordInput from 'components/Formik/PasswordInput';
import { success } from 'services/toastr';
import { navigate } from 'helpers';
import { PrimaryButton } from 'components/Formik';

const Password: React.FC = () => {
  const { t, tHtml } = useTranslation();

  const onSubmit = useCallback(
    async (
      request: UpdateUserPasswordRequestDto,
      helpers: FormikHelpers<UpdateUserPasswordRequestDto>,
    ) => {
      try {
        await Api.shared.user.profile.updateUserProfilePassword(request);
        success(tHtml('authentication.password_changed_successfully'));
        navigate(RouteList.USER.PROFILE.HOME);
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [tHtml],
  );

  const [updatePasswordRequest] = useState<UpdateUserPasswordRequestDto>({
    old_password: '',
    password: '',
    password_confirmation: '',
  });

  const UpdatePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required(),
    password: Yup.string().password().required(),
    password_confirmation: Yup.string().passwordMatch('password').required(),
  });

  return (
    <React.Fragment>
      <h1>{tHtml('menu.password')}</h1>
      <div>
        <Formik
          initialValues={updatePasswordRequest}
          validationSchema={UpdatePasswordSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <div className={'form-input'}>
                <PasswordInput name="old_password" />
              </div>
              <div className={'form-input'}>
                <PasswordInput name="password" placeholder={t('placeholder.new_password')} />
              </div>
              <div className={'form-input'}>
                <PasswordInput name="password_confirmation" />
              </div>
              <div className={'mt-5 d-flex flex-row-reverse'}>
                <PrimaryButton
                  type={'submit'}
                  submitting={isSubmitting}
                  className={'site btn btn-primary'}
                >
                  {tHtml('common.save')}
                </PrimaryButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Password);
