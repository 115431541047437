import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface Props {
  loading: boolean;
  customPlaceholder?: React.ReactElement<{ [k: string]: any }> | null;
}

const SkeletonLoader: React.FC<Props> = ({ loading, customPlaceholder, children }) => {
  return (
    <React.Fragment>
      {loading && (customPlaceholder || <SkeletonElement />)}
      <div hidden={loading}>{children}</div>
    </React.Fragment>
  );
};

export default SkeletonLoader;

const SkeletonElement: React.FC = () => (
  <React.Fragment>
    <Skeleton height={500} className={'mb-6'} />
  </React.Fragment>
);
