import React, { useCallback, useContext, useEffect, useState } from 'react';
import { generatePath, RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import { action } from 'typesafe-actions';

import Api from 'api';
import { setGlobalLoading } from 'modules/app/actions';
import { PrimaryButton } from 'components/Formik';
import { ApplicationTypeEnum } from 'scopes/guest/helpers/enums/ApplicationTypeEnum';
import { ApplicationContext } from '../module/ApplicationContext';
import { UPDATE_APPLICATION } from '../module/ApplicationReducer';
import { ApplicationPageProps } from '../steps';
import { ProspectApplicationDetailsDto } from 'api/types/guest/prospectApplication';
import { RealEstateDevelopmentFormSection } from './RealEstateDevelopmentFormSection';
import { RefinancingFormSection } from './RefinancingFormSection';
import { RealEstateAcquisitionFormSection } from './RealEstateAcquisitionFormSection';
import { WorkingCapitalFormSection } from './WorkingCapitalFormSection';
import { OtherFormSection } from './OtherFormSection';
import { createDynamicValidationSchema } from './validation';
import Loader from 'components/Loader';
import { mergeSelectedInitialValues, initialValuesMapping } from './initialValues';
import { redirect } from 'scopes/guest/helpers/utils';

const CreditDetails: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();
  const { state, dispatch } = useContext(ApplicationContext);
  const [initialFormValues, setInitialFormValues] = useState<ProspectApplicationDetailsDto | null>(
    null,
  );

  const creditPurpose = state.application?.prospect_application.credit_purpose;
  const validationSchema = createDynamicValidationSchema(creditPurpose ?? []);

  useEffect(() => {
    if (creditPurpose == undefined) {
      return;
    } else {
      setInitialFormValues(mergeSelectedInitialValues(creditPurpose));
      setGlobalLoading(false);
    }
  }, [t, applicationId, creditPurpose, dispatch]);

  useEffect(() => {
    const details = state.application?.prospect_application.details;

    const getInitialValueForType = (type: ApplicationTypeEnum, detail: any) => {
      if (!creditPurpose?.includes(type)) return null;
      return detail ?? initialValuesMapping[type];
    };

    if (details) {
      const initialValues = {
        [ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT]: getInitialValueForType(
          ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT,
          details.real_estate_development,
        ),
        [ApplicationTypeEnum.REFINANCING]: getInitialValueForType(
          ApplicationTypeEnum.REFINANCING,
          details.refinancing,
        ),
        [ApplicationTypeEnum.REAL_ESTATE_ACQUISITION]: getInitialValueForType(
          ApplicationTypeEnum.REAL_ESTATE_ACQUISITION,
          details.real_estate_acquisition,
        ),
        [ApplicationTypeEnum.WORKING_CAPITAL]: getInitialValueForType(
          ApplicationTypeEnum.WORKING_CAPITAL,
          details.working_capital,
        ),
        [ApplicationTypeEnum.OTHER]: getInitialValueForType(
          ApplicationTypeEnum.OTHER,
          details.other,
        ),
      };

      setInitialFormValues(initialValues);
    }
  }, [state.application, creditPurpose]);

  const onSubmit = useCallback(
    async (
      request: ProspectApplicationDetailsDto,
      helpers: FormikHelpers<ProspectApplicationDetailsDto>,
    ) => {
      try {
        await Api.guest.prospect_application
          .storeApplicationCreditDetails(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          {initialFormValues ? (
            <Formik
              enableReinitialize={true}
              initialValues={initialFormValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    {creditPurpose?.includes(ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT) && (
                      <RealEstateDevelopmentFormSection />
                    )}
                    {creditPurpose?.includes(ApplicationTypeEnum.REFINANCING) && (
                      <RefinancingFormSection />
                    )}
                    {creditPurpose?.includes(ApplicationTypeEnum.WORKING_CAPITAL) && (
                      <WorkingCapitalFormSection />
                    )}
                    {creditPurpose?.includes(ApplicationTypeEnum.REAL_ESTATE_ACQUISITION) && (
                      <RealEstateAcquisitionFormSection
                        country={state.application?.prospect_application.country ?? ''}
                      />
                    )}
                    {creditPurpose?.includes(ApplicationTypeEnum.OTHER) && <OtherFormSection />}
                    <hr />
                    <div className={'d-flex justify-content-between align-items-center'}>
                      {state.id && (
                        <PrimaryButton
                          className={'btn btn-grey w-25'}
                          type={'button'}
                          onClick={() => {
                            if (!state.id || !previousPage) return;
                            const path = generatePath(previousPage, { uuid: state.id });
                            history.push(path);
                          }}
                        >
                          {tHtml('common.back')}
                        </PrimaryButton>
                      )}
                      <PrimaryButton className={'btn btn-primary w-25'} submitting={isSubmitting}>
                        {tHtml('common.continue')}
                      </PrimaryButton>
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default withRouter(CreditDetails);
