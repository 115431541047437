import React from 'react';
import Watchers from 'scopes/investor/pages/Project/components/Watchers';
import { ProjectContextState, withProject } from 'components/Project/Scope/ProjectProvider';
import ProjectGallery from 'components/Project/ProjectGallery';
import { AnimatedProgressProvider, ProgressBar } from 'components/ProgressBar';
import { round } from 'lodash';
import CalculatorWithCollection from './components/CalculatorWithCollection';
import useTranslation from 'helpers/useTranslation';

const ProjectTop: React.FC<ProjectContextState> = ({ project }) => {
  const { tHtml } = useTranslation();

  if (!project) return <></>;

  const getPercent = (firstNumber: number, secondNumber: number) => {
    if (!secondNumber) return 0;

    return round((firstNumber / secondNumber) * 100, 2);
  };

  return (
    <>
      <h2>{tHtml('projects.header', { id: project.pid })}</h2>
      <div className={'project-section'}>
        <div className={'project-top'}>
          <div className={'project-view'}>
            {project.watchers !== null && <Watchers number={project.watchers} />}
            <ProjectGallery pictures={project.pictures} />
          </div>
          <div className={'project-calculator'}>
            <div className={'top'}>
              <h1>{project.project_name}</h1>
              <div className={'city'}>{project.project_address}</div>
            </div>
            <div className={'body'}>
              <div className={'percent'}>
                <div className={'w-50'}>
                  <AnimatedProgressProvider
                    valueEnd={getPercent(project.invested_amount, project.required_amount)}
                  >
                    {({ value }) => <ProgressBar percentage={value} />}
                  </AnimatedProgressProvider>
                </div>
              </div>
              <hr />
              <CalculatorWithCollection />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withProject(ProjectTop);
