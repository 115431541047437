import React from 'react';
import useTranslation from 'helpers/useTranslation';

type Props = {
  amount: number;
  is_receiver: boolean;
};

const WalletOperationAmountBadge: React.FC<Props> = ({ amount, is_receiver }) => {
  const { tHtml } = useTranslation();

  return (
    <div className={`operation-amount-${is_receiver ? 'positive' : 'negative'}`}>
      {tHtml('common.money_signed', {
        value: is_receiver ? `${amount}` : 0 - amount,
      })}
    </div>
  );
};

export default WalletOperationAmountBadge;
