import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Column, Row as TableRow, RowPropGetter } from 'react-table';
import useTranslation from 'helpers/useTranslation';

import { PieChartDataEntry } from 'components/PieChart/PieChart';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { InvestorDashboardStatisticsByNameResponseDto } from 'api/types/investor/dashboard';
import InvestorPortfolioPieChartWithTable from './InvestorPortfolioPieChartWithTable';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';
import {
  calculateChartTotalAmount,
  getChartValue,
  getShareValue,
  InvestorStatisticsByNameChartData,
  InvestorStatisticsDataByName,
} from './chartHelpers';
import { ITEMS_PER_PAGE } from 'components/Table/Table';

interface Props {
  projectsData: InvestorStatisticsDataByName;
  amountColumn: string;
}

const InvestorPortfolioByProject: React.FC<Props> = ({ projectsData, amountColumn }) => {
  const { t } = useTranslation();

  const [pieChartData, setPieChartData] = useState<PieChartDataEntry[]>([]);
  const [tableData, setTableData] =
    useState<PaginationData<InvestorDashboardStatisticsByNameResponseDto>>();
  const [pieChartActiveIndex, setPieChartActiveIndex] = useState<number>(-1);

  const [projectsDataArr, setProjectsData] = useState<InvestorStatisticsByNameChartData>([]);

  useEffect(() => {
    setProjectsData(
      Object.keys(projectsData)
        .map((key) => {
          return { name: key, ...projectsData[key] };
        })
        .filter((val) => val[amountColumn as keyof typeof val] > 0),
    );
  }, [setProjectsData, projectsData, amountColumn]);

  const totalAmount = calculateChartTotalAmount(projectsDataArr, amountColumn);

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.pid'),
        accessor: 'project_name',
        sortType: 'string',
        Cell: (cell: any) => {
          return (
            <ProjectTitleLink
              title={cell.row.original.project_name}
              projectId={cell.row.original.project_id}
            />
          );
        },
      },
      {
        Header: t('table.share'),
        accessor: 'share',
        sortType: 'string',
        Cell: (cell: any) => {
          return t('common.percents', { value: cell.value });
        },
      },
      {
        Header: t('table.amount'),
        accessor: amountColumn,
        sortType: 'string',
        Cell: (cell: any) => {
          return t('common.money', { value: cell.value });
        },
      },
    ],
    [t, amountColumn],
  );

  useEffect(() => {
    setPieChartData(
      projectsDataArr.map((item: InvestorDashboardStatisticsByNameResponseDto) => {
        return {
          name: item['pid'],
          value: getChartValue(item, totalAmount, amountColumn),
        };
      }),
    );
  }, [amountColumn, projectsDataArr, totalAmount]);

  const fetchData = useCallback(
    async (paginationFilter?: PaginationDataFilter) => {
      const { page = 1, limit = ITEMS_PER_PAGE } = paginationFilter || {};

      const startIdx = (page - 1) * limit;
      const endIdx = startIdx + limit;

      const slicedData = projectsDataArr.slice(startIdx, endIdx);

      const statisticsData = {
        data: slicedData.map((item, idx: number) => ({
          ...item,
          share: getShareValue(item, totalAmount, amountColumn),
          idx: startIdx + idx,
          isSelected: false,
        })),
        meta: {
          current_page: page,
          from: startIdx + 1,
          last_page: Math.ceil(projectsDataArr.length / limit),
          to: Math.min(endIdx, projectsDataArr.length),
          total: projectsDataArr.length,
        },
      };

      setTableData(statisticsData);
    },
    [amountColumn, projectsDataArr, totalAmount],
  );

  const getRowProps = (
    row: TableRow<Record<string, unknown>>,
  ): RowPropGetter<Record<string, unknown>> => {
    const result: Record<string, unknown> = {
      onMouseEnter: () => {
        setPieChartActiveIndex(row.original.idx as number);
      },
    };

    if (row.original?.isSelected) {
      result['className'] = 'active-row';
    }

    return result;
  };

  const onChange = (index: number) => {
    if (tableData?.data) {
      tableData.data = tableData.data.map(
        (item: InvestorDashboardStatisticsByNameResponseDto, itemIndex) => ({
          ...item,
          isSelected: index == itemIndex,
        }),
      );

      setTableData({
        ...tableData,
      });
    }
  };

  return (
    <InvestorPortfolioPieChartWithTable
      data={pieChartData}
      tableData={tableData}
      columns={columns}
      onFetchData={fetchData}
      onChange={onChange}
      tableRowProps={getRowProps}
      indexState={{ index: pieChartActiveIndex, setIndex: setPieChartActiveIndex }}
    />
  );
};

export default InvestorPortfolioByProject;
