import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Col, ColProps, Row } from 'reactstrap';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';
import DataBlockStacked from 'scopes/investor/pages/SecondaryMarket/components/DataBlockStacked';
import { ProjectHelper } from 'helpers/project';

type Props = SecondaryMarketInvestmentContextState;

const TabInvestmentInformation: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  const { tHtml } = useTranslation();

  if (!secondaryMarketInvestment) return null;

  const colProps: ColProps = {
    lg: 3,
    md: 6,
    sm: 6,
    xs: 12,
  };

  return (
    <>
      <section>
        <Row className={'mb-4 gy-3'}>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.investment_information.type')}
              content={tHtml(
                `application.type.${secondaryMarketInvestment.investment_information.type}`,
              )}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml(
                'secondary_market.project.tab.investment_information.investment_purpose',
              )}
              content={
                secondaryMarketInvestment.investment_information.investment_purpose
                  ? tHtml(
                      'projects.investment_purposes.' +
                        secondaryMarketInvestment.investment_information.investment_purpose,
                    )
                  : '-'
              }
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.investment_information.required_amount')}
              content={tHtml('common.money', {
                value: secondaryMarketInvestment.investment_information.required_amount,
              })}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.investment_information.credit_duration')}
              content={tHtml(`common.month`, {
                value: secondaryMarketInvestment.investment_information.credit_duration,
              })}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={secondaryMarketInvestment.loan_ratio}
              content={
                secondaryMarketInvestment.investment_information.loan_ratio_external
                  ? ProjectHelper.formatLoanRatio(
                      secondaryMarketInvestment.investment_information.loan_ratio_external,
                      secondaryMarketInvestment.investment_information.loan_ratio_max,
                    )
                  : '-'
              }
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.investment_information.remaining_amount')}
              content={tHtml('common.money', {
                value: secondaryMarketInvestment.investment_information.remaining_amount,
              })}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.investment_information.credit_return')}
              content={tHtml(
                `placeholder.credit_return.${secondaryMarketInvestment.investment_information.credit_return}`,
              )}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml(
                'secondary_market.project.tab.investment_information.interest_frequency',
              )}
              content={tHtml(
                `placeholder.interest_frequency.${secondaryMarketInvestment.investment_information.interest_frequency}`,
              )}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.investment_information.source_of_funds')}
              content={secondaryMarketInvestment.investment_information.source_of_funds}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.investment_information.interest_rate')}
              content={
                secondaryMarketInvestment.investment_information.basic_interest
                  ? ProjectHelper.formatInterestRate(
                      secondaryMarketInvestment.investment_information.basic_interest,
                      secondaryMarketInvestment.investment_information.increased_interest,
                    )
                  : '-'
              }
            />
          </Col>
        </Row>
        <p>{tHtml('secondary_market.project.tab.investment_information.disclaimer')}</p>
      </section>
    </>
  );
};

export default withSecondaryMarketInvestment(TabInvestmentInformation);
