import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { BonusWrapper } from './InvestmentModal.styles';
import SelectPromoCodesInput from '../SelectPromoCodesInput';
import * as Yup from 'yup';
import { ApplyPromoCodesRequest } from 'api/types/investor/project';
import Api from 'api';
import useInvestmentModalBonusStore from './InvestmentModalBonusStore';

interface Props {
  project: any;
  amount: number;
  isLoading: boolean;
  setLoading: (value: boolean) => void;
}

interface IPromoCodeFormValues {
  bonus_codes: string[];
}

const ApplyBonus: React.FC<Props> = ({ project, amount, isLoading, setLoading }) => {
  const bonusStore = useInvestmentModalBonusStore();
  const { tHtml } = useTranslation();
  const formik = useFormikContext();
  const [codeRequest] = useState<IPromoCodeFormValues>({
    bonus_codes: [],
  });

  const codeSchema = Yup.object().shape({
    bonus_codes: Yup.array().of(Yup.string().required()),
  });

  useEffect(() => {
    return () => {
      bonusStore.hardReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCodeApply = async (
    request: IPromoCodeFormValues,
    helpers: FormikHelpers<IPromoCodeFormValues>,
  ) => {
    const currentCallVersion = JSON.parse(JSON.stringify(bonusStore.getId()));

    setLoading(true);
    if (request.bonus_codes.length === 0) {
      bonusStore.reset();
      setLoading(false);
      return;
    }

    const req: ApplyPromoCodesRequest = {
      investment_amount: amount,
      bonus_codes: request.bonus_codes,
    };

    await Api.investor.projects
      .applyPromoCode(project.id, req)
      .then((response) => {
        if (currentCallVersion !== bonusStore.getId()) {
          return;
        }
        bonusStore.setBonusAmount(response.bonus_amount);
        bonusStore.setBonusPercentage(response.bonus_interest);
        bonusStore.setCodes(response.bonus_codes);
        bonusStore.setTotalAmount(response.total_amount);
        bonusStore.setTotalInterest(response.total_interest);
        bonusStore.setIsSellable(response.is_sellable);
        bonusStore.setCodeValidations(response.code_validations);
      })
      .catch((e) => {
        helpers.setErrors(e.response?.errors);
        formik.setErrors(e.response?.errors);
        bonusStore.reset();
      });
    setLoading(false);
  };

  return (
    <>
      <Formik
        initialValues={codeRequest}
        validationSchema={codeSchema}
        onSubmit={onCodeApply}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <div className={'w-100'}>
            {isLoading && <div>{tHtml('common.calculating')}</div>}
            <Form onSubmit={handleSubmit}>
              <hr />
              <div className={'d-flex justify-content-center mb-4'}>
                <BonusWrapper className={'d-flex flex-column'}>
                  <h6>{tHtml('projects.investment_modal.bonus_codes.title')}</h6>
                  <SelectPromoCodesInput
                    name={'bonus_codes'}
                    project_id={project.id}
                    investmentAmount={amount}
                    setFieldValue={setFieldValue}
                    isDisabled={isSubmitting}
                    isSubmittable={true}
                    validations={bonusStore.code_validations}
                  />
                </BonusWrapper>
              </div>
              <hr />
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

export default ApplyBonus;
