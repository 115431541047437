import React from 'react';
import { StyledCard, StyledCardLightBlue, StyledCardLightPink, StyledCardPink } from './Card.style';

interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  color?: 'default' | 'light-pink' | 'light-blue' | 'pink';
  className?: string;
}

const Card: React.FC<Props> = ({ color = 'default', children, className }) => {
  const getByType = () => {
    switch (color) {
      case 'default':
        return <StyledCard className={className}>{children}</StyledCard>;
      case 'light-pink':
        return <StyledCardLightPink className={className}>{children}</StyledCardLightPink>;
      case 'light-blue':
        return <StyledCardLightBlue className={className}>{children}</StyledCardLightBlue>;
      case 'pink':
        return <StyledCardPink className={className}>{children}</StyledCardPink>;
    }
  };

  return getByType();
};

export default Card;
