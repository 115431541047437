import styled from 'styled-components';

export const StyledSidebarParams = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.4rem;
  justify-content: center;
`;

export const StyledDesktopSidebarWrapperVerticalMenu = styled.ul`
  margin: 0;
`;

export const StyledDesktopSidebarWrapper = styled(StyledSidebarParams)`
  padding: 0.6rem 0 2rem 3.75rem;

  ul {
    li {
      a {
        padding: .6rem 1.1rem;
        position: relative;
        font-size: 0.875rem;
        font-weight: 400;
        margin: 0 0.6rem;
        border-radius: 0.18rem;
        display: flex;
        align-items: center;
        color: var(--profitus-sidebar-link-color);

        .icon {
          display: inline-block;
          min-width: 1.5rem;
          padding-bottom: .125em;
          font-size: 1.05rem;
          line-height: 1.40625rem;
          vertical-align: middle;
          background-color: var(--profitus-sidebar-link-icon-color);
        }
        
        &:hover {
          color: var(--profitus-link-active-color);

          .icon {
            background-color: var(--profitus-link-active-color);
          }
        }

        &.active {
          color: var(--profitus-link-active-color);
          
          .icon {
            background-color: var(--profitus-link-active-color);
          }
        }
      }
    }
`;
