import { ErrorMessage, useField, useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import useTranslation from 'helpers/useTranslation';
import CreatableSelect from 'react-select/creatable';
import { CreatableProps } from 'react-select/dist/declarations/src/Creatable';
import ScrollToOnError from './ScrollToOnError';
import { SelectOption } from './SelectInput';
import { ErrorMessageItem } from './CreatableSelectInput.styles';
export interface ReactSelectProps extends Omit<CreatableProps<any, any, any>, 'options'> {
  name: string;
  options: SelectOption[] | undefined;
  hideLabel?: boolean;
  placeholder?: string;
  noOptionsNewMessage?: string;
  isSubmittable?: boolean;
}

const CreatableSelectInput: React.FC<ReactSelectProps> = ({
  name,
  options,
  hideLabel,
  noOptionsNewMessage,
  isSubmittable = false,
  ...props
}) => {
  const { t, currentLanguage } = useTranslation();
  const [field, meta, helper] = useField(name);

  const { submitForm } = useFormikContext();

  if (!props.placeholder) {
    props.placeholder = t('label.' + name);
  }

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const isError = meta?.touched && !!meta?.error;

  const selectedValue = useCallback(() => {
    if (field?.value === undefined || field?.value === null) {
      return undefined;
    }

    if (Array.isArray(field?.value)) {
      return field?.value.map((value: any) => ({ value, label: value }));
    }

    return { value: field?.value };
  }, [field?.value]);

  return (
    <>
      <div
        className={`${hideLabel ? '' : 'form-label-group force-show in-border '}${
          isError ? 'is-invalid' : ''
        }`}
      >
        <ScrollToOnError name={name} isError={isError}>
          <CreatableSelect
            className={'fancy-select'}
            classNamePrefix={'fancy-select'}
            options={options}
            name={name}
            loadingMessage={() => t('common.loading')}
            noOptionsMessage={() => noOptionsNewMessage ?? t('common.no_options')}
            placeholder={() => t('common.select')}
            createOptionPosition={'last'}
            onChange={(options: any) => {
              if (Array.isArray(options)) {
                helper.setValue((options as SelectOption[]).map((option) => option.value));
              } else {
                helper.setValue(options?.value);
              }

              if (isSubmittable) submitForm();
            }}
            value={selectedValue()}
            isLoading={options === undefined}
            {...props}
          />
          {!hideLabel && <label htmlFor={name}>{props.placeholder}</label>}
        </ScrollToOnError>
      </div>
      {isError && (
        <div className={'invalid-feedback'}>
          {name && (
            <ErrorMessage
              name={name}
              render={(msg: string | string[]) => {
                if (typeof msg === 'string') {
                  return msg;
                }

                return msg.map((msg, key) => (
                  <ErrorMessageItem className={'pb-2'} key={key}>
                    {msg}
                  </ErrorMessageItem>
                ));
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
export default CreatableSelectInput;
