import { useEffect, useState } from 'react';
import { WalletInformationResponse } from '../api/types/common';
import useOnboarding from './useOnboarding';
import useTranslation from './useTranslation';
import { isWalletProviderIgnored } from './enums/wallet/WalletProviderEnum';
import { useWalletQuery } from '../api/queries/useWalletQuery';

type VerifiedWallet = {
  wallet: WalletInformationResponse | undefined;
  isWalletVerified: boolean;
  errorMessage: string;
};

const DEFAULT_STATE: VerifiedWallet = {
  wallet: undefined,
  isWalletVerified: false,
  errorMessage: '',
};

const useVerifiedWallet = () => {
  const { t } = useTranslation();
  const { isAccredited, isWalletVerified } = useOnboarding();

  const { data: wallet } = useWalletQuery();

  const [state, setState] = useState<VerifiedWallet>(DEFAULT_STATE);

  useEffect(() => {
    const newState = { ...DEFAULT_STATE };

    newState.wallet = wallet;
    newState.isWalletVerified = false;
    if (!isAccredited) {
      newState.errorMessage = t('wallet.error.not_accredited');
    } else if (!isWalletVerified) {
      newState.errorMessage = t('wallet.error.wallet_not_verified');
    } else if (wallet && isWalletProviderIgnored(wallet.provider)) {
      newState.errorMessage = t('wallet.error.wallet_not_supported');
    } else {
      newState.errorMessage = '';
      newState.isWalletVerified = true;
    }

    setState(newState);
  }, [isAccredited, isWalletVerified, t, wallet, wallet?.provider]);

  return state;
};

export default useVerifiedWallet;
