import styled from 'styled-components';

export const StyledPayments = styled.div``;

export const StyledPaymentsHeader = styled.h1`
  color: var(--profitus-h2-color);
  font-size: 1.5rem;
  font-weight: 700;
`;

export const StyledPaymentsLabel = styled.h1`
  color: var(--profitus-h2-color);
  font-size: 1rem;
  font-weight: 700;
`;
