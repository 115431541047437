import React from 'react';
import { ToastContent } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import * as H from 'history';

export enum NotificationLevel {
  Default = 'default',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

interface NotificationProps {
  level: NotificationLevel;
  text: ToastContent | string;
  cta?: string;
  ctaAction?: (history: H.History) => void;
}

interface NotificationActionProps {
  actionText: string;
  action: (history: H.History) => void;
}

export const NotificationAction: React.FC<NotificationActionProps> = ({ action, actionText }) => {
  const history = useHistory();

  return (
    <button className={`btn btn-primary px-4 py-1 mt-2`} onClick={() => action(history)}>
      {actionText}
    </button>
  );
};

const Notification: React.FC<NotificationProps> = ({ level, text, cta, ctaAction }) => {
  return (
    <React.Fragment>
      <div className={`profitus-notification profitus-notification-${level} p-4`}>
        <div className={'flex-row align-items-center'}>
          <Col xl={12}>
            <Row>
              <Col xs={12} sm={9} lg={9} xl={9}>
                <div className={'me-auto'}>{text}</div>
              </Col>
              <Col xs={12} sm={3} lg={3} xl={3}>
                {cta && ctaAction && <NotificationAction actionText={cta} action={ctaAction} />}
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Notification;
