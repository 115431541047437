import React, { ReactElement } from 'react';
import useTranslation from 'helpers/useTranslation';

interface CheckboxBlockProps {
  label?: string | ReactElement;
}

const DataNotFoundBlock: React.FC<CheckboxBlockProps> = ({ label }) => {
  const { tHtml } = useTranslation();

  return (
    <div className={'d-flex flex-column justify-content-center align-items-center'}>
      <i className={'icon icon-data-not-found'} />
      <p className={'text-center m-0'}>{label ?? tHtml('common.no_data')}</p>
    </div>
  );
};

export default DataNotFoundBlock;
