import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ProjectSkeleton: React.FC = () => (
  <React.Fragment>
    <div className={'project'}>
      <div className={'project-section'}>
        <div className={'project-top'}>
          <div className={'project-view'}>
            <Skeleton height={500} />
          </div>
          <div className={'project-calculator p-0'}>
            <Skeleton height={500} />
          </div>
        </div>
      </div>
      <div className={'project-section'}>
        <Skeleton height={200} />
      </div>
      <div className={'project-section-with-side'}>
        <div className={'project-body'}>
          <Skeleton height={500} />
        </div>
        <div className={'project-side'}>
          <Skeleton height={500} />
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default ProjectSkeleton;
