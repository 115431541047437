import { ApplicationTypeEnum } from 'scopes/developer/helpers/enums/ApplicationTypeEnum';
import {
  OtherDetails,
  ProspectApplicationDetailsDto,
  RealEstateAcquisitionDetails,
  RealEstateDevelopmentDetails,
  RefinancingDetails,
  WorkingCapitalDetails,
} from 'api/types/guest/prospectApplication';
import { ApplicationRealEstateType } from 'scopes/guest/helpers/enums/ApplicationRealEstateType';

export const mergeSelectedInitialValues = (selectedTypes: ApplicationTypeEnum[]) => {
  const initialValues: ProspectApplicationDetailsDto = {
    [ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT]: null,
    [ApplicationTypeEnum.REFINANCING]: null,
    [ApplicationTypeEnum.REAL_ESTATE_ACQUISITION]: null,
    [ApplicationTypeEnum.WORKING_CAPITAL]: null,
    [ApplicationTypeEnum.OTHER]: null,
  };

  selectedTypes.forEach((type: ApplicationTypeEnum) => {
    if (type === ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT) {
      initialValues[type] = initialValuesMapping[type] as RealEstateDevelopmentDetails;
    } else if (type === ApplicationTypeEnum.REFINANCING) {
      initialValues[type] = initialValuesMapping[type] as RefinancingDetails;
    } else if (type === ApplicationTypeEnum.WORKING_CAPITAL) {
      initialValues[type] = initialValuesMapping[type] as WorkingCapitalDetails;
    } else if (type === ApplicationTypeEnum.OTHER) {
      initialValues[type] = initialValuesMapping[type] as OtherDetails;
    } else if (type === ApplicationTypeEnum.REAL_ESTATE_ACQUISITION) {
      initialValues[type] = initialValuesMapping[type] as RealEstateAcquisitionDetails;
    }
  });

  return initialValues;
};

export const initialValuesMapping: ProspectApplicationDetailsDto = {
  [ApplicationTypeEnum.REAL_ESTATE_DEVELOPMENT]: {
    project_name: '',
    project_address: '',
    start_construction_at: '',
    end_construction_at: '',
    total_area: null,
    project_estimate: null,
    existing_investment_total: null,
    existing_investment_own_funds: null,
    existing_investment_lend_funds: null,
    project_plot_price: null,
    project_engineering_price: null,
    project_documentation_price: null,
    project_construction_price: null,
    existing_investment_plot_price: null,
    existing_investment_engineering_price: null,
    existing_investment_documentation_price: null,
    existing_investment_construction_price: null,
    presale_contracts: null,
    presale_contracts_amount: null,
    building_permit: true,
    assets_to_be_sold: Object.values(ApplicationRealEstateType).map((asset) => {
      return {
        real_estate_type: asset,
        real_estate_count: null,
        real_estate_plot: null,
        real_estate_value: null,
      };
    }),
  },
  [ApplicationTypeEnum.REFINANCING]: {
    debt_due_date: '',
    debt_amount: null,
    debt_purpose: '',
    debt_security_deposit: '',
    debt_security_deposit_value: null,
    debt_refinancing_reason: '',
  },
  [ApplicationTypeEnum.WORKING_CAPITAL]: {
    loan_details_working_capitals: '',
  },
  [ApplicationTypeEnum.REAL_ESTATE_ACQUISITION]: {
    re_price_without_vat: null,
    re_price_vat: null,
    own_funds_amount: null,
    own_funds_origin: '',
    re_seller_name: '',
    re_address: '',
    re_purpose: '',
    re_value: null,
    re_evaluated_by: '',
  },
  [ApplicationTypeEnum.OTHER]: {
    loan_details_others: '',
  },
};
