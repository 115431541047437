import React from 'react';
import useTranslation from 'helpers/useTranslation';

import { PageHeader } from 'components/Header';
import SectionFaq from './components/SectionFaq';
import AutoInvestmentProvider, { withAutoInvestmentData } from './AutoInvestmentProvider';
import AutoInvestmentSections from './components/AutoInvestmentSections';

const AutoInvestment: React.FC = () => {
  const { tHtml } = useTranslation();

  return (
    <AutoInvestmentProvider>
      <div className={'auto-investments page-layout'}>
        <PageHeader title={tHtml('investments.auto_investment')} />
        <AutoInvestmentSections />
        <SectionFaq />
      </div>
    </AutoInvestmentProvider>
  );
};

export default withAutoInvestmentData(AutoInvestment);
