import useWindowSize, { smallerThanLg } from './useWindowsSize';
import { default as env } from '../env.json';
import { useAddToHomescreenPrompt } from 'helpers/hooks/useAddToHomescreenPrompt';

const useDisplay = () => {
  const { width } = useWindowSize();
  const isMobile = smallerThanLg(width);
  const { appInstalled } = useAddToHomescreenPrompt();
  const isNativeApp = window.location.hostname === 'pwa.profitus.com';
  const isApp = isNativeApp || env.IS_APP === 'true' || appInstalled;

  return { isMobile, isApp, isNativeApp };
};

export default useDisplay;
