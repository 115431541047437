import React, { useEffect, useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import useTranslation from 'helpers/useTranslation';
import Select, { MultiValue } from 'react-select';

import { SelectOption } from './SelectInput';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

export interface SelectBoxProps extends StateManagerProps {
  name: string;
  selectOptions: SelectOption[];
  defaultValue?: string | number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  isSelectAllEnabled?: boolean;
}

const SelectBox: React.FC<SelectBoxProps> = ({
  selectOptions,
  setFieldValue,
  isSelectAllEnabled = false,
  ...props
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props.name);

  const [defaultSelectedValues, setDefaultSelectedValues] = useState<SelectOption[]>([]);

  const isError = meta?.touched && !!meta?.error;

  const selectAllOption = React.useMemo(
    () => ({
      value: 'select_all',
      label: t('common.select_all'),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const whereOptionIsSelectAll = (option: SelectOption) => option.value === selectAllOption.value;
  const whereOptionIsNotSelectAll = (option: SelectOption) =>
    option.value !== selectAllOption.value;

  const onChange = (options: MultiValue<SelectOption>) => {
    if (options.find(whereOptionIsSelectAll)) {
      setFieldValue(
        field.name,
        selectOptions.filter(whereOptionIsNotSelectAll).map((item) => item.value),
      );
    } else {
      setFieldValue(
        field.name,
        options.filter(whereOptionIsNotSelectAll).map((item) => item.value),
      );
    }
  };

  useEffect(() => {
    if (isSelectAllEnabled) {
      selectOptions.unshift(selectAllOption);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedValues: SelectOption[] = [];

    field.value.map((value: string) => {
      selectOptions.map((option) => {
        if (option.value == value && option.value !== selectAllOption.value) {
          selectedValues.push(option);
        }
      });
    });

    setDefaultSelectedValues(selectedValues);
  }, [field.value, selectOptions, isSelectAllEnabled, selectAllOption]);

  return (
    <>
      <Select
        value={defaultSelectedValues}
        isMulti={true}
        options={selectOptions}
        className={`basic-multi-select mt-3 select select-multi-value ${
          isError ? 'is-invalid' : ''
        }`}
        classNamePrefix={'select'}
        placeholder={t('common.select')}
        onChange={(option: any) => {
          onChange(option);
        }}
        noOptionsMessage={() => t('common.no_options')}
        {...props}
      />
      {isError && (
        <div className={'invalid-feedback'}>{field.name && <ErrorMessage name={field.name} />}</div>
      )}
    </>
  );
};

export default SelectBox;
