import React from 'react';
import {
  BarChart as RechartsBarChart,
  Bar as RechartsBar,
  XAxis as RechartsXAxis,
  YAxis as RechartsYAxis,
  Tooltip as RechartsTooltip,
  Cell as RechartsCell,
  Legend as RechartsLegend,
  ResponsiveContainer as RechartsResponsiveContainer,
} from 'recharts';
import useTranslation from 'helpers/useTranslation';

export interface StackedBarChartColumnProps {
  key: string;
  fill: string;
}

interface Props {
  data: any[];
  columns: StackedBarChartColumnProps[];
  showLegend: boolean;
  legendTitle?: string;
  height?: number | string;
  showTooltipName?: boolean;
}

const StackedBarChart: React.FC<Props> = ({
  data,
  columns,
  showLegend,
  legendTitle,
  height,
  showTooltipName,
}) => {
  const { tHtml } = useTranslation();

  const Tooltip = (props: any) => {
    if (props.active && props.payload && props.payload.length) {
      return (
        <div className={'custom-tooltip'}>
          {props.payload.map((item: any, index: number) => (
            <div key={index}>
              {showTooltipName && <>{tHtml('chart.' + item.name)}:</>}{' '}
              <span>{item.payload[item.name]}</span>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  const Legend = (props: any) => {
    if (props.payload && props.payload.length) {
      return (
        <div>
          {legendTitle && <p>{legendTitle}</p>}
          <div className={'custom-legend-wrapper'}>
            {props.payload.map((item: any, index: number) => (
              <div key={index} className={'legend'}>
                <span className={'square'} style={{ backgroundColor: item.color }} />
                {tHtml('chart.' + item.value)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={'stacked-bar-chart'} style={{ height: height }}>
      <RechartsResponsiveContainer width={'100%'} height={height ?? 350}>
        <RechartsBarChart data={data}>
          <RechartsXAxis dataKey="name" />
          <RechartsYAxis />
          <RechartsTooltip content={Tooltip} />
          {showLegend && <RechartsLegend content={Legend} />}

          {columns.map((column) => (
            <RechartsBar key={column.key} dataKey={column.key} stackId={'a'} fill={column.fill}>
              {data.map((entry, entryIndex: number) => {
                // TODO think of way to dynamically calculate border radius dependant on different counts
                // first bar with count > 0 rounded [0, 0, 10, 10]
                // top with count > 0 bar [10,10,10,10]
                // middle bars [0, 0, 0] check if not first or last with count
                return <RechartsCell key={`cell-${entryIndex}`} />;
              })}
            </RechartsBar>
          ))}
        </RechartsBarChart>
      </RechartsResponsiveContainer>
    </div>
  );
};

export default StackedBarChart;
