import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import { PrimaryButton } from 'components/Button';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';

interface Props extends ModalComponentProps {
  percent: number;
  validity: number;
  onAccept: () => void;
  onDecline: () => void;
}

export const WithdrawalBonusModal: React.FC<Props> = ({
  percent,
  validity,
  onAccept,
  onDecline,
  ...props
}) => {
  const { t, tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  return (
    <React.Fragment>
      <ModalComponent size={'lg'} {...props} backdrop={'static'}>
        <div className={'p-2 p-lg-5 withdraw-bonus-modal'}>
          <ModalHeader className={'h2'}>{tHtml('modal.withdrawal_bonus.title')}</ModalHeader>
          <ModalBody className={''}>
            <p className={'text-pre body'}>
              <div className={`html-block word-break`}>
                {tHtml('modal.withdrawal_bonus.body', {
                  value: t('common.percents', { value: percent }),
                })}
              </div>
            </p>
          </ModalBody>
          <ModalFooter className={'d-flex justify-content-start'}>
            <p className={'text-pre policy'}>
              <div className={`html-block word-break`}>
                {tHtml('modal.withdrawal_bonus.policy', { value: validity })}
              </div>
            </p>
            <div className="d-flex action">
              <PrimaryButton
                onClick={() => {
                  hideModal();
                  onAccept();
                }}
                className="btn btn-primary w-100 "
              >
                {t('modal.withdrawal_bonus.accept')}
              </PrimaryButton>
              <PrimaryButton
                onClick={() => {
                  hideModal();
                  onDecline();
                }}
                className="btn btn-primary w-100 decline"
              >
                {t('modal.withdrawal_bonus.decline')}
              </PrimaryButton>
            </div>
          </ModalFooter>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};
