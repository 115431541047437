export enum RatingEnum {
  AAA = 'AAA',
  AAPLUS = 'AA+',
  AA = 'AA',
  AAMINUS = 'AA-',
  APLUS = 'A+',
  A = 'A',
  AMINUS = 'A-',
  BPLUS = 'BBB+',
  B = 'BBB',
  BMINUS = 'BBB-',
}
