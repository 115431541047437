import React from 'react';
import useTranslation from 'helpers/useTranslation';
import TextInput from 'components/Formik/TextInput';
import CreatableSelectInput from 'components/Formik/CreatableSelectInput';
import { EVALUATORS } from 'scopes/guest/helpers/realEstateEvaluators';

export const RealEstateAcquisitionFormSection: React.FC<{ country: string }> = ({ country }) => {
  const { t, tHtml } = useTranslation();
  const evaluatorOptions =
    EVALUATORS[country]?.map((evaluator) => ({
      value: evaluator,
      label: evaluator,
    })) || [];
  return (
    <>
      <h2 className="mt-5 mb-4">{tHtml('prospect_application.real_estate_acquisition_details')}</h2>
      <div className="mb-4">
        <TextInput
          type={'number'}
          name={'real_estate_acquisition.re_price_without_vat'}
          placeholder={t(
            'placeholder.prospect_application.real_estate_acquisition.re_price_without_vat',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          type={'number'}
          name={'real_estate_acquisition.re_price_vat'}
          placeholder={t('placeholder.prospect_application.real_estate_acquisition.re_price_vat')}
        />
      </div>
      <div className="mb-4">
        <TextInput
          type={'number'}
          name={'real_estate_acquisition.own_funds_amount'}
          placeholder={t(
            'placeholder.prospect_application.real_estate_acquisition.own_funds_amount',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name={'real_estate_acquisition.own_funds_origin'}
          placeholder={t(
            'placeholder.prospect_application.real_estate_acquisition.own_funds_origin',
          )}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name={'real_estate_acquisition.re_seller_name'}
          placeholder={t('placeholder.prospect_application.real_estate_acquisition.re_seller_name')}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name={'real_estate_acquisition.re_address'}
          placeholder={t('placeholder.prospect_application.real_estate_acquisition.re_address')}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name={'real_estate_acquisition.re_purpose'}
          placeholder={t('placeholder.prospect_application.real_estate_acquisition.re_purpose')}
        />
      </div>
      <div className="mb-4">
        <TextInput
          type={'number'}
          name={'real_estate_acquisition.re_value'}
          placeholder={t('placeholder.prospect_application.real_estate_acquisition.re_value')}
        />
      </div>
      <div className="mb-3">
        <CreatableSelectInput
          name={'real_estate_acquisition.re_evaluated_by'}
          formatOptionLabel={(o: any) => o.value}
          formatCreateLabel={(val: string) => (
            <>
              {t(
                'placeholder.prospect_application.real_estate_acquisition.re_evaluated_by_create',
                {
                  value: val,
                },
              )}
            </>
          )}
          options={evaluatorOptions}
          placeholder={t(
            'placeholder.prospect_application.real_estate_acquisition.re_evaluated_by',
          )}
        />
      </div>
    </>
  );
};
