import styled from 'styled-components';
import { breakpointDown, breakpointUp } from 'styles/helpers/responsive';

export const StyledDesktopHeader = styled.header`
  .navbar {
    padding-left: 4.5rem;

    &-brand {
      ${breakpointUp.MD} {
        margin-right: 1rem;
      }
    }

    &-nav {
      gap: 2.125rem;
    }
  }

  .nav-item {
    display: flex;
    align-self: center;
  }

  .navbar-collapse {
    ${breakpointDown.SM} {
      margin-top: 1rem;
    }

    .item {
      display: flex;
      justify-content: center;
      margin: 0.5rem 0;

      &-no-margin {
        margin: 0 0;
      }

      ${breakpointDown.SM} {
        margin: 0.5rem;
      }
    }
  }
`;
