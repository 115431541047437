import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Card } from 'reactstrap';

import { RouteList } from 'routes';
import ProjectDeveloperKYB from './ProjectDeveloperKybV1';
import ContactInfo from './ContactInfo';
import MarketingSettings from './MarketingSettings';
import ProfileInvestorQuiz from './ProfileInvestorQuiz';
import { PrimaryButton } from 'components/Button';
import { RegistrationStepsCompany } from 'components/RegistrationSteps';
import useOnboarding from 'helpers/useOnboarding';
import useUserType from 'helpers/useUserType';
import InfoBlock from 'components/InfoBlock/InfoBlock';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';
import FinancialRiskCalculator from './FinancialRiskCalculator';
import InvestorKYBV2 from './InvestorKybV2';
import { KybVersionEnum } from 'helpers/enums/KybVersionEnum';
import InvestorKYB from './InvestorKybV1';
import ProjectDeveloperKYBV2 from './ProjectDeveloperKybV2';
import NotFilledProfileSection from '../../../../components/Profile/NotFilledProfileSection';

const Profile: React.FC = () => {
  const { tHtml } = useTranslation();
  const { isInvestor } = useUserType();

  const onBoarding = useOnboarding();
  const { data: companyProfileData } = useUserCompanyProfileQuery();

  const resolveKyb = (
    userType: UserTypeEnum,
    version: KybVersionEnum,
  ): React.ReactElement | null => {
    if (userType === UserTypeEnum.INVESTOR) {
      switch (version) {
        case KybVersionEnum.V1:
          return <InvestorKYB />;
        case KybVersionEnum.V2:
          return <InvestorKYBV2 />;
      }
    }

    if (userType === UserTypeEnum.PROJECT_DEVELOPER) {
      switch (version) {
        case KybVersionEnum.V1:
          return <ProjectDeveloperKYB />;
        case KybVersionEnum.V2:
          return <ProjectDeveloperKYBV2 />;
      }
    }

    return null;
  };

  return (
    <div className={'profile'}>
      <div className={'site profile-cards'}>
        {!onBoarding.isCompleted && (
          <Card body className={'light-pink'}>
            <RegistrationStepsCompany />
            <hr />
            <div className={'d-flex flex-row-reverse'}>
              {!onBoarding.isKnowYourBusinessFilled && (
                <Link to={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}>
                  <PrimaryButton>
                    {tHtml('profile.account_registration.button.complete_filling')}
                  </PrimaryButton>
                </Link>
              )}
            </div>
          </Card>
        )}
        {companyProfileData?.company.type === UserTypeEnum.INVESTOR &&
          !companyProfileData?.company.has_investor_quiz_filled && (
            <InfoBlock
              color={'pink'}
              title={tHtml('profile.info_block.investor_quiz.title')}
              description={tHtml('profile.info_block.investor_quiz.description')}
            >
              <Link to={RouteList.INVESTOR.QUIZ.VIEW}>
                <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
              </Link>
            </InfoBlock>
          )}
        {!companyProfileData?.know_your_business && (
          <InfoBlock
            color={'pink'}
            title={tHtml('profile.info_block.kyb.title')}
            description={tHtml('profile.info_block.kyb.description')}
          >
            <Link to={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}>
              <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
            </Link>
          </InfoBlock>
        )}
      </div>

      <div className={'profile-content'}>
        <section id={'contact-info'}>
          <ContactInfo />
        </section>
        <section id={'know-your-business'}>
          {companyProfileData?.company.type && companyProfileData?.know_your_business ? (
            resolveKyb(
              companyProfileData.company.type as UserTypeEnum,
              companyProfileData.know_your_business?.version,
            )
          ) : (
            <NotFilledProfileSection
              title={tHtml('menu.kyb')}
              buttonLink={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}
            />
          )}
        </section>
        {isInvestor() && (
          <>
            <section id={'investor-quiz'}>
              <ProfileInvestorQuiz />
            </section>
            <section id={'financial-risk-calculator'}>
              <FinancialRiskCalculator />
            </section>
          </>
        )}
        <section id={'marketing-settings'}>
          <MarketingSettings />
        </section>
      </div>
    </div>
  );
};

export default withRouter(Profile);
