import Client from '../../client';
import Http from '../../../http';
import {
  FinancialRiskCalculatorRequestDto,
  FinancialRiskCalculatorResponseDto,
  DeleteTwoFactorAuthRequestDto,
  EnableTwoFactorAuthRequestDto,
  UpdateUserPasswordRequestDto,
  UpdateUserProfileContactInfoRequestDto,
  UserKnowYourCustomerRequestDto,
  UserProfileMarketingSettingsRequestDto,
  UserProfileResponseDto,
  UserProfileTwoFactorAuthResponseDto,
} from '../../../types/shared/user/profile';

class SharedUserProfile extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      PROFILE: this.protectedUrl(`${this.http.baseUrl}/user/profile`),
      PROFILE_MARKETING_SETTINGS: this.protectedUrl(
        `${this.http.baseUrl}/user/profile/marketing-settings`,
      ),
      PROFILE_KYC: this.protectedUrl(`${this.http.baseUrl}/user/profile/kyc`),
      PROFILE_PASSWORD: this.protectedUrl(`${this.http.baseUrl}/user/profile/password`),
      PROFILE_CONTACT_INFO: this.protectedUrl(`${this.http.baseUrl}/user/profile/contact-info`),
      PROFILE_FINANCIAL_RISK_CALCULATOR: this.protectedUrl(
        `${this.http.baseUrl}/user/profile/financial-risk-calculator`,
      ),
      PROFILE_FINANCIAL_RISK_CALCULATOR_CALCULATE: this.protectedUrl(
        `${this.http.baseUrl}/user/profile/financial-risk-calculator/calculate`,
      ),
      PROFILE_2FA: this.protectedUrl(`${this.http.baseUrl}/user/profile/2fa`),
    };
  }

  fetchUserProfile = (): Promise<UserProfileResponseDto> => {
    return this.http.get(this.api.PROFILE);
  };

  updateUserProfileMarketingSettings = (
    data: UserProfileMarketingSettingsRequestDto,
  ): Promise<UserProfileResponseDto> => {
    return this.http.put(this.api.PROFILE_MARKETING_SETTINGS, data);
  };

  storeKYC = (data: UserKnowYourCustomerRequestDto): Promise<UserProfileResponseDto> => {
    return this.http.post(this.api.PROFILE_KYC, data);
  };

  updateUserProfilePassword = (data: UpdateUserPasswordRequestDto): Promise<any> => {
    return this.http.put(this.api.PROFILE_PASSWORD, data);
  };

  updateUserProfileContactInfo = (
    data: UpdateUserProfileContactInfoRequestDto,
  ): Promise<UserProfileResponseDto> => {
    return this.http.put(this.api.PROFILE_CONTACT_INFO, data);
  };

  getFinancialRiskCalculatorSubmit = (): Promise<FinancialRiskCalculatorResponseDto> => {
    return this.http.get(this.api.PROFILE_FINANCIAL_RISK_CALCULATOR, {
      ignoreException: true,
    });
  };

  calculateFinancialRisk = (
    data: FinancialRiskCalculatorRequestDto,
  ): Promise<FinancialRiskCalculatorResponseDto> => {
    return this.http.post(this.api.PROFILE_FINANCIAL_RISK_CALCULATOR_CALCULATE, data);
  };

  calculateAndStoreFinancialRisk = (
    data: FinancialRiskCalculatorRequestDto,
  ): Promise<FinancialRiskCalculatorResponseDto> => {
    return this.http.post(this.api.PROFILE_FINANCIAL_RISK_CALCULATOR, data);
  };

  generateTwoFactorAuthQrCode = (): Promise<UserProfileTwoFactorAuthResponseDto> => {
    return this.http.get(this.api.PROFILE_2FA);
  };

  createTwoFactorAuth = (data: EnableTwoFactorAuthRequestDto): Promise<any> => {
    return this.http.post(this.api.PROFILE_2FA, data);
  };

  deleteTwoFactorAuth = (data: DeleteTwoFactorAuthRequestDto): Promise<any> => {
    return this.http.delete(this.api.PROFILE_2FA, null, {
      data,
    });
  };
}

export default SharedUserProfile;
