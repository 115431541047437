import React from 'react';

import SecondaryMarketInvestmentProvider from './SecondaryMarketInvestmentProvider';
import ProjectTop from './ProjectTop/ProjectTop';
import ProjectTopMobile from './ProjectTop/ProjectTopMobile';
import ProjectVerifiedUser from './ProjectVerifiedUser';
import useDisplay from 'helpers/useDisplay';

interface Props {
  secondaryMarketInvestmentId: string;
}

const SecondaryMarketInvestmentDetails: React.FC<Props> = ({ secondaryMarketInvestmentId }) => {
  const { isMobile } = useDisplay();

  return (
    <div>
      <SecondaryMarketInvestmentProvider secondaryMarketInvestmentId={secondaryMarketInvestmentId}>
        {isMobile ? <ProjectTopMobile /> : <ProjectTop />}
        <ProjectVerifiedUser />
      </SecondaryMarketInvestmentProvider>
    </div>
  );
};

export default SecondaryMarketInvestmentDetails;
