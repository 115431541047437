import React, { useCallback, useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { PrimaryButton, TextInput } from 'components/Formik';
import FileUploadDirect from 'components/FileUpload/FileUploadDirect';
import { StoreWalletBankAccountRequest } from 'api/types/common';
import Api from 'api';
import { success } from 'services/toastr';
import { LEMONWAY_DOCUMENT_TYPES } from 'helpers/constants/LemonwayDocumentTypes';
import { useWalletQuery } from 'api/queries/useWalletQuery';

interface Props {
  isDisabled: boolean;
}

const DEFAULT_REQUEST: StoreWalletBankAccountRequest = {
  iban: '',
  bank_name: '',
  holder: '',
  document: null,
};
const StoreBankAccountForm: React.FC<Props> = (props) => {
  const { tHtml } = useTranslation();

  const [bankAccountRequest, setBankAccountRequest] =
    useState<StoreWalletBankAccountRequest>(DEFAULT_REQUEST);

  const { data: wallet, setQueryData: setWallet } = useWalletQuery();

  useEffect(() => {
    if (wallet?.holder) {
      setBankAccountRequest((prev) => ({
        ...prev,
        holder: wallet?.holder.full_name,
      }));
    }
  }, [wallet]);

  const bankAccountSchema = Yup.object().shape({
    iban: Yup.string().iban().required(),
    bank_name: Yup.string().nullable(),
    document: Yup.mixed().required(),
  });

  const onBankAccountSubmit = useCallback(
    async (
      request: StoreWalletBankAccountRequest,
      helpers: FormikHelpers<StoreWalletBankAccountRequest>,
    ) => {
      await Api.wallet
        .storeWalletBankAccount(request)
        .then((response) => {
          setWallet(response);
          success(tHtml('common.created_successfully'));
          setBankAccountRequest(DEFAULT_REQUEST);
        })
        .catch((e) => {
          helpers.setErrors(e.response?.errors);
        });
    },
    [setWallet, tHtml],
  );

  return (
    <>
      <h2>{tHtml('wallet.add_bank_account')}</h2>
      <Formik
        initialValues={bankAccountRequest}
        validationSchema={bankAccountSchema}
        onSubmit={onBankAccountSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className={'form-input mb-4 pt-1'}>
              <TextInput name="iban" disabled={props.isDisabled} />
            </div>
            <div className={'form-input mb-4'}>
              <TextInput name="bank_name" disabled={props.isDisabled} />
            </div>
            <div className={'form-input mb-4'}>
              <TextInput name="holder" disabled={true} />
            </div>
            <div className={'form-input mb-4'}>
              <p>
                <small>{tHtml('wallet.add_document_description')}</small>
              </p>
              <FileUploadDirect
                name={'document'}
                maxFiles={1}
                accept={LEMONWAY_DOCUMENT_TYPES}
                disabled={isSubmitting || props.isDisabled}
              />
            </div>
            <div>
              <PrimaryButton
                type="submit"
                className={'site btn btn-primary w-50 mt-3'}
                disabled={isSubmitting || props.isDisabled}
              >
                {tHtml('placeholder.save')}
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default StoreBankAccountForm;
