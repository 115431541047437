import Client from '../../client';
import Http from '../../../http';
import { CurrentUserResponseDto, RefreshUserResponseDto } from 'api/types/shared/user/current';

class SharedUserCurrent extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      CURRENT: this.protectedUrl(`${this.http.baseUrl}/user/current`),
      REFRESH: this.protectedUrl(`${this.http.baseUrl}/user/refresh`),
      LOGOUT: this.protectedUrl(`${this.http.baseUrl}/user/logout`),
    };
  }

  fetchCurrentUser = (): Promise<CurrentUserResponseDto> => {
    return this.http.get(this.api.CURRENT);
  };

  refreshToken = (): Promise<RefreshUserResponseDto> => {
    return this.http.put(this.api.REFRESH);
  };

  logout = (): Promise<any> => {
    return this.http.post(this.api.LOGOUT);
  };
}

export default SharedUserCurrent;
