import React from 'react';
import { format, setMonth, getMonth, getYear } from 'date-fns';
import { enIN, lt } from 'date-fns/locale';
import useTranslation from 'helpers/useTranslation';

import { IconCircleArrowRight, IconCircleArrowLeft } from 'components/Icons';
import { range } from './helpers';

interface Props {
  locale: string;
  date: Date;

  increaseMonth(): void;

  decreaseMonth(): void;

  changeYear(year: number): void;

  changeMonth(month: number): void;

  startFromYear: number;
  endYearRange: number;
  hasFutureRange: boolean;
  showTitle?: boolean;
}

const DatePickerHeader: React.FC<Props> = ({
  locale,
  date,
  increaseMonth,
  decreaseMonth,
  changeYear,
  changeMonth,
  startFromYear,
  endYearRange,
  hasFutureRange,
  showTitle = true,
}) => {
  const { tHtml, t } = useTranslation();
  let years = range(startFromYear, endYearRange);

  if (hasFutureRange) {
    years = range(startFromYear + 100, 200);
  }

  const months = new Array(12).fill(null).map((_, i) => ({
    value: i,
    label: format(setMonth(new Date(), i), 'MMMM', {
      locale: locale === 'lt' ? lt : enIN,
    }),
  }));

  return (
    <>
      {showTitle && (
        <>
          <p>
            <strong>{tHtml('placeholder.select_date')}</strong>
          </p>
          <hr />
        </>
      )}

      <div className={'d-flex justify-content-between align-items-center'}>
        <button
          className={'btn-no-style'}
          onClick={(e) => {
            e.preventDefault();
            decreaseMonth();
          }}
        >
          <IconCircleArrowLeft fill={'white'} classes={'react-datepicker__icons-month'} />
        </button>
        <div className={'d-flex'}>
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(parseInt(value))}
            className={'form-control me-1'}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            className={'form-control'}
            value={months[getMonth(date)].value}
            onChange={({ target: { value } }) => {
              changeMonth(months[parseInt(value)].value);
            }}
          >
            {months.map((option) => (
              <option
                key={option.value}
                value={option.value}
                style={{ textTransform: 'capitalize' }}
              >
                {t(`month.${option.value}`)}
              </option>
            ))}
          </select>
        </div>
        <button
          className={'btn-no-style'}
          onClick={(e) => {
            e.preventDefault();
            increaseMonth();
          }}
        >
          <IconCircleArrowRight fill={'white'} classes={'react-datepicker__icons-month'} />
        </button>
      </div>
    </>
  );
};

export default DatePickerHeader;
