import { ProjectInvestmentTypeEnum } from 'helpers/enums/ProjectInvestmentTypeEnum';
import Tooltip from 'components/Tooltip';
import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Cell } from 'react-table';
import { InvestorInvestmentListResponseDto } from 'api/types/investor/investment';

interface Props {
  cell: Cell<InvestorInvestmentListResponseDto>;
}

export const TransferTypeInvestmentTooltip = ({ cell }: Props) => {
  const { tHtml } = useTranslation();

  const randomValue = Math.floor(Math.random() * 100);
  const tooltipId = `transfer-${cell.row.index}-${randomValue}`;

  if (cell.row.original.investment_type === ProjectInvestmentTypeEnum.TRANSFER) {
    return (
      <>
        <i className={'ms-1 icon icon-info-sm'} id={tooltipId} />
        <Tooltip id={tooltipId} text={tHtml('investments.tooltip.transfer')} placement={'right'} />
      </>
    );
  }

  return <></>;
};
