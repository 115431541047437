import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { success } from 'services/toastr';

interface DataBlockProps {
  title: string;
  data: string;
}

export const CopyDataBlock: React.FC<DataBlockProps> = ({ title, data }) => {
  const { tHtml } = useTranslation();
  const copyText = async () => {
    await navigator.clipboard.writeText(data);
    success(tHtml('common.clipboard_success'));
  };

  return (
    <div className={'copy-data-block'}>
      <div>
        <div className={'title'}>{title}</div>
        <div className={'text notranslate'} onClick={() => copyText()}>
          {data}
        </div>
      </div>
      <i className={'icon icon-copy'} onClick={() => copyText()} />
    </div>
  );
};

export default CopyDataBlock;
