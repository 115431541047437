import React from 'react';
import { Card } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { InvestorProjectListResponse } from 'api/types/investor/project';
import { AnimatedProgressProvider, LinearProgressBar } from 'components/ProgressBar';
import { Rating } from '.';
import ProjectCardActionButton from './ProjectCardActionButton';
import { ProjectHelper } from 'helpers/project';
import ProjectCardLink from 'components/ProjectCardLink';
import Flag from 'components/Flag';
import { round } from 'lodash';

interface ProjectCardProps {
  project: InvestorProjectListResponse;
}

const ProjectCardApp: React.FC<ProjectCardProps> = ({ project }) => {
  const { tHtml } = useTranslation();

  const getPercent = (firstNumber: number, secondNumber: number) => {
    return round((firstNumber / secondNumber) * 100, 2);
  };

  return (
    <>
      <Card className={'investment project-card app mb-4 gap-1'}>
        <div className={'header'}>
          <div className={'info'}>
            <Rating projectId={project.id} rating={project.initial_rating} />
            {project.country && <Flag countryCode={project.country} />}
            <div className={'loan_ratio'}>
              {`${project.loan_ratio} ${ProjectHelper.formatLoanRatio(
                project.loan_ratio_external,
                project.loan_ratio_max,
              )}`}
            </div>
          </div>
          <div className={'thumbnail'}>
            <ProjectCardLink {...project} />
          </div>
        </div>
        <div className={'body d-flex flex-column justify-content-between'}>
          <div className={'top d-flex justify-content-between align-items-center h-100 gap-2'}>
            <div className={'title'}>{project.project_name}</div>
            <div className={'interest'}>
              {ProjectHelper.formatInterestRate(project.basic_interest, project.max_bonus_interest)}
            </div>
          </div>

          <div>
            <AnimatedProgressProvider
              valueEnd={getPercent(project.invested_amount, project.required_amount)}
            >
              {({ value }) => <LinearProgressBar percentage={value} />}
            </AnimatedProgressProvider>
            <div className={'d-flex justify-content-between align-items-center mb-2'}>
              <div className="data-block">
                <div className={'notranslate money'}>
                  {tHtml('common.money', { value: project.invested_amount })}
                </div>
                <div className={'title'}>{tHtml('placeholder.invested_amount')}</div>
              </div>
              <div className="data-block">
                <div className={'notranslate money'}>
                  {tHtml('common.money', { value: project.required_amount })}
                </div>
                <div className={'title text-right'}>{tHtml('placeholder.objective')}</div>
              </div>
            </div>
            <div className="mb-2 investment-purpose">
              {project.investment_purpose &&
                project.initial_rating &&
                `${tHtml('projects.investment_purposes.' + project.investment_purpose)} • ${
                  project.initial_rating
                }`}
            </div>
            <div className={'bottom d-flex justify-content-between align-items-center gap-2'}>
              <div className={'item'}>
                <i className={'icon icon-timer'} />
                {project.funded_duration ??
                  tHtml('common.days', {
                    value: project.days_to_get_money,
                  })}
              </div>
              <div className={'item'}>
                <i className={'icon icon-investors'} />
                {project.investors}
              </div>
              <div className={'item'}>
                <i className={'icon icon-calendar'} />
                {tHtml(`common.month`, { value: project?.credit_duration })}
              </div>
            </div>
            <div>
              <ProjectCardActionButton project={project} />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ProjectCardApp;
