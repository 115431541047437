import React, { useEffect } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import Api from 'api';
import { RouteList } from 'routes';
import { setGlobalLoading } from 'modules/app/actions';
import { success } from 'services/toastr';
import usePermissions from 'helpers/usePermissions';
import { Permission } from 'helpers/auth/permissions';
import { AuthUtils } from 'services/useAuth/Auth.utils';
import { useAuth } from 'services/useAuth';

const ConfirmEmail: React.FC<RouteComponentProps> = ({ history }) => {
  const { tHtml } = useTranslation();
  const { token } = useParams<{ token: string }>();
  const permissions = usePermissions();
  const { refresh } = useAuth();

  useEffect(() => {
    if (permissions.hasAny(Permission.EMAIL_VERIFIED)) {
      history.push(RouteList.GUEST.AUTHENTICATION.REGISTER_DATA_FILLING);
      return;
    }

    if (token) {
      Api.guest.auth
        .confirmEmail({ token: token })
        .then(async () => {
          success(tHtml('authentication.email_confirmed'));

          // TODO fix this by adding rxjs if statement when to refresh session
          if (AuthUtils.getPayload()) {
            await refresh();
            history.push(RouteList.GUEST.AUTHENTICATION.REGISTER_DATA_FILLING);
          } else {
            history.push(RouteList.GUEST.AUTHENTICATION.LOGIN);
          }
        })
        .catch(() => {
          history.push(RouteList.GUEST.AUTHENTICATION.LOGIN);
        });
    } else {
      setGlobalLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return <></>;
};

export default withRouter(ConfirmEmail);
