import { generatePath } from 'react-router-dom';
import { History } from 'history';

export const redirect = (
  history: History,
  page: string | undefined,
  applicationId: string | number,
): void => {
  if (page != undefined) {
    const path = generatePath(page, { applicationId });
    history.push(path);
  }
};
