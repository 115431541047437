import React from 'react';
import { Col, Row } from 'reactstrap';
import ProjectCardSkeleton from '../ProjectCard/ProjectCardSkeleton';

const ProjectListSkeleton: React.FC = () => (
  <Row>
    {[...Array(8)].map((_, key) => (
      <Col xl="3" lg="4" sm="6" key={key}>
        <ProjectCardSkeleton />
      </Col>
    ))}
  </Row>
);

export default ProjectListSkeleton;
