import React, { createContext, useContext, useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import SkeletonLoader from 'components/SkeletonLoader';
import { SecondaryMarketSellerProjectResponseDto } from 'api/types/investor/secondary_market';
import ProjectInvestmentSkeleton from './ProjectInvestmentSkeleton';
import Api from 'api';

export interface ProjectInvestmentContextState {
  loading: boolean;
  projectInvestment: SecondaryMarketSellerProjectResponseDto | undefined;
}

const ProjectInvestmentContext = createContext<ProjectInvestmentContextState>({
  projectInvestment: undefined,
  loading: true,
});

interface ProjectInvestmentProviderProps {
  projectInvestmentId: string;
}

export const ProjectInvestmentProvider: React.FC<ProjectInvestmentProviderProps> = ({
  projectInvestmentId,
  children,
}) => {
  const [projectInvestment, setProjectInvestment] =
    useState<SecondaryMarketSellerProjectResponseDto>();
  const [loading, setLoading] = useState<boolean>(true);
  const { currentLanguage } = useTranslation();

  useEffect(() => {
    Api.investor.secondary_market
      .fetchPersonalActiveInvestment(projectInvestmentId)
      .then((response) => {
        setProjectInvestment(response);
        setLoading(false);
      });
  }, [projectInvestmentId, currentLanguage]);

  return (
    <ProjectInvestmentContext.Provider
      value={{ projectInvestment: projectInvestment, loading: loading }}
    >
      <SkeletonLoader loading={loading} customPlaceholder={<ProjectInvestmentSkeleton />}>
        {children}
      </SkeletonLoader>
    </ProjectInvestmentContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function withProjectInvestment<
  P extends ProjectInvestmentContextState = ProjectInvestmentContextState,
>(Component: React.ComponentType<P>) {
  const WithProjectInvestment = ({ ...props }: Optionalize<P, ProjectInvestmentContextState>) => {
    const c = useContext(ProjectInvestmentContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithProjectInvestment.displayName = `withProjectInvestment${Component.displayName}`;

  return WithProjectInvestment;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useProjectInvestment = () => {
  return useContext(ProjectInvestmentContext);
};

export default ProjectInvestmentProvider;
