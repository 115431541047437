import React from 'react';
import { InvestorProjectListResponse } from 'api/types/investor/project';
import { generatePath, Link } from 'react-router-dom';
import { RouteList } from 'routes';
import { PrimaryButton } from 'components/Button';
import useTranslation from 'helpers/useTranslation';
import { ProjectStatusEnum } from 'helpers/enums/ProjectStatusEnum';
import InvestorProjectGuard from 'helpers/guards/investor';

interface Props {
  project: InvestorProjectListResponse;
}

const ProjectCardActionButton: React.FC<Props> = ({ project }) => {
  const { tHtml } = useTranslation();

  if (project.has_invested) {
    return (
      <Link
        to={{
          pathname: generatePath(RouteList.GUEST.PROJECTS.VIEW, {
            projectId: project.id,
          }),
        }}
      >
        <PrimaryButton
          className={
            'site btn btn-highlight w-100 mt-4 d-flex align-items-center justify-content-center gap-1'
          }
        >
          {InvestorProjectGuard.canInvest(project)
            ? tHtml('project_card.invest')
            : tHtml('project_card.learn_more')}
          <i className={'icon-circle-check'} />
        </PrimaryButton>
      </Link>
    );
  }

  return (
    <Link
      to={{
        pathname: generatePath(RouteList.GUEST.PROJECTS.VIEW, {
          projectId: project.id,
        }),
      }}
    >
      {project.status === ProjectStatusEnum.COMING_SOON ? (
        <PrimaryButton className={'site btn btn-primary w-100 mt-4'}>
          {tHtml('project_card.coming_soon')}
        </PrimaryButton>
      ) : (
        <PrimaryButton className={'site btn btn-primary w-100 mt-4'}>
          {InvestorProjectGuard.canInvest(project)
            ? tHtml('project_card.invest')
            : tHtml('project_card.learn_more')}
        </PrimaryButton>
      )}
    </Link>
  );
};

export default ProjectCardActionButton;
