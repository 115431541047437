import React from 'react';

import { ProjectInvestmentContextState, withProjectInvestment } from '../ProjectInvestmentProvider';

type Props = ProjectInvestmentContextState;

const ProjectTopTitle: React.FC<Props> = ({ projectInvestment }) => {
  if (!projectInvestment) return <></>;

  return (
    <>
      <div className={'mb-3'}>
        <div className={'project-title-block'}>
          <h2 className={'project-title mb-0'}>{projectInvestment.project_name}</h2>
          <h2 className={'project-id-badge mb-0'}>{projectInvestment.project_id}</h2>
        </div>
        <span className={'mb-3'}>{projectInvestment.project_address}</span>
      </div>
    </>
  );
};

export default withProjectInvestment(ProjectTopTitle);
