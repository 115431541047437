import React, { FunctionComponent, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import { HighlightButton } from '../Button';
import useDisplay from 'helpers/useDisplay';

export interface TabInfo<Props> {
  name: string;
  iconClass?: string;
  component: FunctionComponent<Props>;
  permission?: string | string[];
}

interface TabDataProps<T> {
  tabs: TabInfo<T>[];
  props?: T;
  vertical?: boolean;
  className?: string;
  numberOfLinksInSection?: number;
}

const defaultProps = {
  numberOfLinksInSection: 2,
};

const TabData: React.FC<TabDataProps<any>> = ({
  tabs,
  numberOfLinksInSection,
  props,
  vertical,
  className,
}) => {
  const { isMobile } = useDisplay();
  const { tHtml } = useTranslation();

  const [activeTab, setActiveTab] = useState<TabInfo<any>>(tabs[0] ?? null);
  const [activeLinkSection, setActiveLinkSection] = useState<number>(0);

  if (activeTab == null || numberOfLinksInSection! < 1) return <></>;

  const changeTab = (tab: TabInfo<any>) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const changeActiveLinkSection = (offset: number) => {
    const newValue = activeLinkSection + offset;
    if (newValue >= 0 && newValue < numberOfLinkSections) {
      setActiveLinkSection((prevState) => prevState + offset);
    }
  };

  const numberOfLinkSections = Math.ceil(tabs.length / numberOfLinksInSection!);

  tabs = isMobile
    ? tabs.slice(
        activeLinkSection * numberOfLinksInSection!,
        (activeLinkSection + 1) * numberOfLinksInSection!,
      )
    : tabs;

  const tabLinks = tabs.map((tab, key) => {
    return (
      <NavItem key={key}>
        <NavLink
          active={activeTab.name === tab.name}
          onClick={() => changeTab(tab)}
          className={'cursor'}
        >
          {tab.iconClass && <i className={tab.iconClass} />}
          <span>{tHtml(tab.name)}</span>
        </NavLink>
      </NavItem>
    );
  });

  return (
    <React.Fragment>
      <div className={`nav-direction-${vertical ? 'vertical' : 'horizontal'}`}>
        <Nav tabs justified={true} className={className}>
          {isMobile && activeLinkSection != 0 && (
            <HighlightButton
              icon={'icon icon-arrow-left'}
              onClick={() => changeActiveLinkSection(-1)}
            />
          )}
          {tabLinks}
          {isMobile && activeLinkSection != numberOfLinkSections - 1 && (
            <HighlightButton
              icon={'icon icon-arrow-right'}
              onClick={() => changeActiveLinkSection(1)}
            />
          )}
        </Nav>
        <TabContent activeTab={activeTab.name} className={'mt-4'}>
          {tabs.map(({ name, component: Component }, key) => (
            <TabPane tabId={name} key={key}>
              {activeTab.name === name && activeTab.component === Component && (
                <Component {...props} />
              )}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </React.Fragment>
  );
};

TabData.defaultProps = defaultProps;

export default TabData;
