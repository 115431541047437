import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { default as env } from 'env.json';
import { LanguageEnum } from 'helpers/enums/LanguageEnum';
import { LandingPageFaqUniqueIdEnum } from './Enum/LandingPageFaqUniqueIdEnum';
import { LandingPageFaqCategoryEnum } from './Enum/LandingPageFaqCategoryEnum';
import RiskCategoryTooltipStyle from './RiskCategoryTooltip.style';

export const RiskCategoryTooltip = () => {
  const { currentLanguage } = useTranslation();

  const handleOnClickRedirect = () => {
    const params =
      '?category=' +
      LandingPageFaqCategoryEnum.FOR_THE_INVESTOR +
      '&uniqueId=' +
      LandingPageFaqUniqueIdEnum.WHAT_IS_RISK_CATEGORY;

    switch (currentLanguage) {
      case LanguageEnum.LT:
        window.open(
          env.LANDING_PAGE_URL_LT + '/dazniausiai-uzduodami-klausimai' + params,
          '_blank',
        );
        break;
      case LanguageEnum.DE:
        window.open(env.LANDING_PAGE_URL_DE + '/haufig-gestellte-fragen' + params, '_blank');
        break;
      case LanguageEnum.EN:
      default:
        window.open(env.LANDING_PAGE_URL_EN + '/frequently-asked-questions' + params, '_blank');
        break;
    }
  };

  return (
    <RiskCategoryTooltipStyle>
      <i onClick={handleOnClickRedirect} className={'ms-1 icon icon-info-sm'} />
    </RiskCategoryTooltipStyle>
  );
};
