import {
  InvestorAutoInvestmentConfigI,
  InvestorAutoInvestmentConfigPackEnum,
} from 'api/types/investor/investment';
import React from 'react';
import { useAutoInvestmentData } from '../AutoInvestmentProvider';
import useTranslation from 'helpers/useTranslation';
import Tooltip from 'components/Tooltip';

const scrollToNewStrategy = () => {
  const section = document.getElementById('new-strategies');
  if (section) {
    section.scrollIntoView({ block: 'start' });
  } else {
    console.error('Failed to find the element with ID "new-strategies".');
  }
};

const scrollToMyStrategies = () => {
  const section = document.getElementById('auto-invest-strategy-info');
  if (section) {
    section.scrollIntoView({ block: 'start' });
  } else {
    console.error('Failed to find the element with ID "auto-invest-strategy-info".');
  }
};

const PackageDetails = ({
  pack,
  onClick,
  disableButton,
  isMaxStrategiesReached,
}: {
  pack: InvestorAutoInvestmentConfigI;
  onClick: () => void;
  disableButton: boolean;
  isMaxStrategiesReached: boolean;
}) => {
  const { pack_name, min_amount, criteria } = pack;
  const { tHtml, t } = useTranslation();

  return (
    <div className="auto-investments-config">
      <h2>{tHtml(`investments.auto_investment.package.${pack_name}`)}</h2>
      <p className="config-amount">
        <span className="config-text">{tHtml(`investments.auto_investment.package.amount`)} </span>
        {t(`common.money_no_cents`, { value: min_amount })}
      </p>
      <div
        id={`max-strategies-tooltip-${pack_name}`}
        className="auto-investments-config-create d-flex justify-content-center flex-column"
      >
        <button
          className={'site btn btn-primary'}
          onClick={() => {
            onClick();
          }}
          disabled={disableButton || isMaxStrategiesReached}
        >
          {t(`investments.auto_investment.package.add`)}
        </button>

        {disableButton && (
          <span className="auto-investments-config-create-description text-muted mt-2">
            {tHtml(`investments.auto_investment.package.disabled`)}
          </span>
        )}
      </div>
      {isMaxStrategiesReached && (
        <Tooltip
          id={`max-strategies-tooltip-${pack_name}`}
          text={tHtml('investments.auto_investment.max_strategies_reached')}
        />
      )}
      <div className={'auto-investments-config-items'}>
        {Object.keys(criteria).map((key, i) => {
          return (
            <p key={i} className={criteria[key] ? '' : 'text-muted'}>
              <div
                className={`config-criteria-${
                  criteria[key] ? 'enabled' : 'disabled'
                } d-flex flex-nowrap align-items-center`}
              >
                <i className={`icon icon-status-${criteria[key] ? 'success' : 'danger'} me-2`} />
                <span>{tHtml(`investments.auto_investment.package.${key}`)}</span>
              </div>
            </p>
          );
        })}
      </div>
    </div>
  );
};

export const SectionConfigs = () => {
  const { autoInvestmentConfig, autoInvestmentData, newStrategy, setNewStrategy, timesLoaded } =
    useAutoInvestmentData();
  const isMaxStrategiesReached = autoInvestmentData && autoInvestmentData.length >= 5;

  React.useEffect(() => {
    if (newStrategy) {
      scrollToNewStrategy();
    }
  }, [newStrategy]);

  React.useEffect(() => {
    if (autoInvestmentData && autoInvestmentData.length > 0 && !newStrategy && timesLoaded > 1)
      setTimeout(() => scrollToMyStrategies(), 0);
  }, [autoInvestmentData, newStrategy, timesLoaded]);

  const handleAddPackage = (selectedPackage: InvestorAutoInvestmentConfigPackEnum) => {
    setNewStrategy(selectedPackage);
    scroll();
  };

  return autoInvestmentConfig ? (
    <div className="d-flex flex-wrap gap-3 justify-content-evenly" id="auto-invest-configs">
      {Object.values(InvestorAutoInvestmentConfigPackEnum).map((packKey) => (
        <PackageDetails
          key={packKey}
          pack={autoInvestmentConfig[packKey]}
          disableButton={!!newStrategy}
          isMaxStrategiesReached={!!isMaxStrategiesReached}
          onClick={() => handleAddPackage(packKey)}
        />
      ))}
    </div>
  ) : null;
};
