import React from 'react';
import useTranslation from 'helpers/useTranslation';
import Tooltip from 'components/Tooltip';
import { Link } from 'react-router-dom';
import { RouteList } from 'routes';
import useVerifiedWallet from 'helpers/useVerifiedWallet';

export const WithdrawButton: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const { isWalletVerified, errorMessage } = useVerifiedWallet();

  return (
    <>
      <div {...props} id={'wallet-withdraw-button'}>
        {isWalletVerified ? (
          <Link
            to={RouteList.SHARED.WALLET.WITHDRAW}
            className="d-flex flex-column align-items-center gap-2"
          >
            <div className={'site btn btn-primary btn-round'}>
              <i className={`icon icon-arrow-tail `} />
            </div>
            {tHtml('wallet.withdraw')}
          </Link>
        ) : (
          <div className="d-flex flex-column align-items-center gap-2">
            <div className={'site btn btn-primary btn-round disabled'}>
              <i className={`icon icon-plus`} />
            </div>
            {tHtml('wallet.withdraw')}
          </div>
        )}
      </div>
      {errorMessage && <Tooltip id={'wallet-withdraw-button'} text={errorMessage} />}
    </>
  );
};

export default WithdrawButton;
