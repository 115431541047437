import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Column, Row as TableRow, RowPropGetter } from 'react-table';
import useTranslation from 'helpers/useTranslation';

import { PieChartDataEntry } from 'components/PieChart/PieChart';
import { PaginationData } from 'api/types';
import { InvestorDashboardStatisticsCommonResponseDto } from 'api/types/investor/dashboard';
import InvestorPortfolioPieChartWithTable from './InvestorPortfolioPieChartWithTable';
import {
  calculateChartTotalAmount,
  getChartValue,
  getShareValue,
  InvestorStatisticsChartData,
  InvestorStatisticsDataByKey,
  transformToChartData,
} from './chartHelpers';
import { DEFAULT_META } from 'components/Table/Table';

interface Props {
  investmentTypesData: InvestorStatisticsDataByKey;
  amountColumn: string;
}

const InvestorPortfolioByLoanStatus: React.FC<Props> = ({ investmentTypesData, amountColumn }) => {
  const { t } = useTranslation();

  const [pieChartData, setPieChartData] = useState<PieChartDataEntry[]>([]);
  const [tableData, setTableData] =
    useState<PaginationData<InvestorDashboardStatisticsCommonResponseDto>>();
  const [pieChartActiveIndex, setPieChartActiveIndex] = useState<number>(-1);
  const [investmentTypesDataArr, setInvestmentTypesDataArr] = useState<InvestorStatisticsChartData>(
    [],
  );

  useEffect(() => {
    setInvestmentTypesDataArr(
      transformToChartData(investmentTypesData).filter(
        (val) => val[amountColumn as keyof typeof val] > 0,
      ),
    );
  }, [setInvestmentTypesDataArr, investmentTypesData, amountColumn]);

  const totalAmount = calculateChartTotalAmount(investmentTypesDataArr, amountColumn);

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.name'),
        accessor: 'name',
        sortType: 'string',
        Cell: (cell: any) => {
          return t('chart.' + cell.value);
        },
      },
      {
        Header: t('table.share'),
        accessor: 'share',
        sortType: 'string',
        Cell: (cell: any) => {
          return t('common.percents', { value: cell.value });
        },
      },
    ],
    [t],
  );

  useEffect(() => {
    setPieChartData(
      investmentTypesDataArr.map((item) => {
        return {
          name: item['name'],
          value: getChartValue(item, totalAmount, amountColumn),
        };
      }),
    );
  }, [amountColumn, investmentTypesDataArr, totalAmount, t]);

  const fetchData = useCallback(async () => {
    const statisticsData = {
      data: investmentTypesDataArr.map((item, idx: number) => ({
        ...item,
        share: getShareValue(item, totalAmount, amountColumn),
        idx,
        isSelected: false,
      })),
      meta: DEFAULT_META,
    };

    setPieChartData(
      statisticsData.data.map((item) => {
        return {
          name: t(`dashboard.portfolio.statistics.investment_type.${item['name']}`),
          value: getChartValue(item, totalAmount, amountColumn),
        };
      }),
    );

    setTableData(statisticsData);
  }, [amountColumn, investmentTypesDataArr, totalAmount, t]);

  const getRowProps = (
    row: TableRow<Record<string, unknown>>,
  ): RowPropGetter<Record<string, unknown>> => {
    const result: Record<string, unknown> = {
      onMouseEnter: () => {
        setPieChartActiveIndex(row.original.idx as number);
      },
    };

    if (row.original?.isSelected) {
      result['className'] = 'active-row';
    }

    return result;
  };

  const onChange = (index: number) => {
    if (tableData?.data) {
      tableData.data = tableData.data.map(
        (item: InvestorDashboardStatisticsCommonResponseDto, itemIndex) => ({
          ...item,
          isSelected: index == itemIndex,
        }),
      );

      setTableData({
        ...tableData,
      });
    }
  };

  return (
    <InvestorPortfolioPieChartWithTable
      data={pieChartData}
      tableData={tableData}
      columns={columns}
      onFetchData={fetchData}
      isPlacementVertical={true}
      onChange={onChange}
      tableRowProps={getRowProps}
      indexState={{ index: pieChartActiveIndex, setIndex: setPieChartActiveIndex }}
    />
  );
};

export default InvestorPortfolioByLoanStatus;
