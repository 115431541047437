import Client from '../client';
import Http from '../../http';
import {
  MyActiveAverageAnnualInterestResponseDto,
  InvestorDashboardStatisticsResponseDto,
  MyActiveInvestmentsResponseDto,
  MyInterestReceivedResponseDto,
  TopInvestorResponseDto,
  PaymentsResponseDto,
} from '../../types/investor/dashboard';
import { PaginationData, PaginationDataFilter } from '../../types';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import { ExternalMediaResponseDto } from '../../types/common';

class InvestorDashboard extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_INVESTOR_DASHBOARD_AVG_ANNUAL_INTEREST: this.protectedUrl(
        `${this.http.baseUrl}/investor/statistic/active-average-annual-interest`,
      ),
      FETCH_INVESTOR_INTEREST_RECEIVED: this.protectedUrl(
        `${this.http.baseUrl}/investor/statistic/interest-received`,
      ),
      EXPORT_INVESTOR_INTEREST_RECEIVED: this.protectedUrl(
        `${this.http.baseUrl}/investor/statistic/interest-received/export/{type}`,
      ),
      FETCH_INVESTOR_ACTIVE_INVESTMENTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/statistic/active-investments`,
      ),
      FETCH_INVESTOR_DASHBOARD_TOP_INVESTOR: this.protectedUrl(
        `${this.http.baseUrl}/investor/statistic/top-investor`,
      ),
      FETCH_INVESTOR_STATISTICS_ACTIVE: this.protectedUrl(
        `${this.http.baseUrl}/investor/statistic/chart-data/active`,
      ),
      FETCH_INVESTOR_STATISTICS_HISTORIC: this.protectedUrl(
        `${this.http.baseUrl}/investor/statistic/chart-data/all`,
      ),
      FETCH_INVESTOR_UPCOMING_PAYMENTS: this.protectedUrl(
        `${this.http.baseUrl}/investor/payouts/upcoming`,
      ),
      FETCH_INVESTOR_LATE_PAYMENTS: this.protectedUrl(`${this.http.baseUrl}/investor/payouts/late`),
    };
  }

  fetchAverageAnnualInterest = (): Promise<MyActiveAverageAnnualInterestResponseDto> => {
    return this.http.get(this.api.FETCH_INVESTOR_DASHBOARD_AVG_ANNUAL_INTEREST);
  };

  fetchActiveInvestments = (): Promise<MyActiveInvestmentsResponseDto> => {
    return this.http.get(this.api.FETCH_INVESTOR_ACTIVE_INVESTMENTS, { params: { limit: 10000 } });
  };

  fetchTopInvestor = (): Promise<TopInvestorResponseDto> => {
    return this.http.get(this.api.FETCH_INVESTOR_DASHBOARD_TOP_INVESTOR);
  };

  fetchActiveInvestmentPortfolioStatistics =
    (): Promise<InvestorDashboardStatisticsResponseDto> => {
      return this.http.get(this.api.FETCH_INVESTOR_STATISTICS_ACTIVE);
    };

  fetchHistoricInvestmentPortfolioStatistics =
    (): Promise<InvestorDashboardStatisticsResponseDto> => {
      return this.http.get(this.api.FETCH_INVESTOR_STATISTICS_HISTORIC);
    };

  fetchDashboardInterestReceived = (
    start: string | null = null,
    end: string | null = null,
  ): Promise<MyInterestReceivedResponseDto> => {
    return this.http.get(this.api.FETCH_INVESTOR_INTEREST_RECEIVED, { params: { start, end } });
  };

  exportInterestReceived = (
    type: CommonDocumentTypeEnum,
    start: string | null = null,
    end: string | null = null,
  ): Promise<ExternalMediaResponseDto> => {
    const url = this.buildUrl(this.api.EXPORT_INVESTOR_INTEREST_RECEIVED, { type });
    return this.http.get(url, { params: { start, end } });
  };

  fetchUpcomingPayments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<PaymentsResponseDto>> => {
    return this.http.get(this.api.FETCH_INVESTOR_UPCOMING_PAYMENTS, { params: request });
  };

  fetchLatePayments = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<PaymentsResponseDto>> => {
    return this.http.get(this.api.FETCH_INVESTOR_LATE_PAYMENTS, { params: request });
  };
}

export default InvestorDashboard;
