import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { BaseLayout } from 'containers';
import SidebarImage from 'assets/images/img.png';
import Logo from 'components/Logo/Logo';
import LocaleSwitcher from 'components/LocaleSwitcher';
import Footer from 'components/Footer';
import { LANDING_ROUTES } from 'routes';
import useTranslation from 'helpers/useTranslation';
import useDisplay from 'helpers/useDisplay';

const AuthenticationLayout: React.FC = ({ children }) => {
  const { currentLanguage } = useTranslation();
  const { isApp } = useDisplay();

  return (
    <React.Fragment>
      <BaseLayout>
        <header />
        <div className="guest-layout vertical-center">
          <Container>
            <Row>
              <Col className="d-flex justify-content-center">
                <div className="content">
                  <div className="header d-flex justify-content-between">
                    <Logo path={isApp ? '#' : LANDING_ROUTES[currentLanguage].HOME} isApp={isApp} />
                    <LocaleSwitcher />
                  </div>
                  <div>{children}</div>
                </div>
              </Col>
              <Col className="d-none d-md-block">
                <div className="sidebar d-flex justify-content-center">
                  <img src={SidebarImage} alt="" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {!isApp && <Footer />}
      </BaseLayout>
    </React.Fragment>
  );
};
export default withRouter(AuthenticationLayout);
