import React from 'react';
import { Navbar, NavbarBrand, Container, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { RouteList } from 'routes';
import ResponsiveLogo from 'components/Logo/ResponsiveLogo';
import Api from 'api';
import { useAccountQuery } from 'api/queries';

interface Props {
  to?: string;
  text?: string;
  showFinishLater?: boolean;
  showSaveDraft?: boolean;
  request: any;
}

const StepFormHeaderSubmit: React.FC<Props> = ({
  to,
  text,
  showFinishLater,
  showSaveDraft,
  request,
}) => {
  const { tHtml } = useTranslation();
  const { invalidate: invalidateAccount } = useAccountQuery();
  const submitDraft = async () => {
    Api.investor.quiz
      .storeInvestorQuizAnswersDraft({
        ...request,
        agreed_alerts: [],
      })
      .then(() => {
        invalidateAccount();
      });
  };

  return (
    <Container className={'px-2 px-sm-4 px-md-6'}>
      <Navbar>
        <NavbarBrand tag={'span'}>
          <ResponsiveLogo />
        </NavbarBrand>
        {showFinishLater && to && text && (
          <div className={'d-flex align-items-center'}>
            {showSaveDraft && (
              <>
                <Nav>
                  <NavItem>
                    <NavLink to={to} className={'navbar-link'} tag={Link} onClick={submitDraft}>
                      {tHtml('common.save')}
                    </NavLink>
                  </NavItem>
                </Nav>
                <div>{tHtml('authentication.or')}</div>
              </>
            )}
            <Nav>
              <NavItem>
                <NavLink to={to} className={'navbar-link'} tag={Link}>
                  {tHtml(text)}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        )}
      </Navbar>
      <hr className={'my-2'} />
    </Container>
  );
};

export default StepFormHeaderSubmit;

StepFormHeaderSubmit.defaultProps = {
  showFinishLater: true,
  showSaveDraft: true,
  to: RouteList.DASHBOARD,
  text: 'common.close',
};
