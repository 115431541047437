import Client from '../client';
import Http from '../../http';
import {
  NotificationResponseDto,
  NotificationShortListResponseDto,
  NotificationShortResponseDto,
} from '../../types/app';
import { PaginationData, PaginationDataFilter } from '../../types';

class Notifications extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_LATEST_NOTIFICATIONS: this.protectedUrl(`${this.http.baseUrl}/notifications/latest`),
      FETCH_ALL_NOTIFICATIONS: this.protectedUrl(`${this.http.baseUrl}/notifications`),
      FETCH_NOTIFICATION: this.protectedUrl(
        `${this.http.baseUrl}/notifications/notification/{notificationId}`,
      ),
      MARK_ALL_AS_READ: this.protectedUrl(`${this.http.baseUrl}/notifications/mark-all-as-read`),
    };
  }

  fetchShortNotifications = (): Promise<NotificationShortListResponseDto> => {
    return this.http.get(this.api.FETCH_LATEST_NOTIFICATIONS, {
      validateStatus: false,
    });
  };

  markAllAsRead = (): Promise<Response> => {
    return this.http.post(this.api.MARK_ALL_AS_READ);
  };

  fetchNotifications = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<NotificationShortResponseDto>> => {
    return this.http.get(this.api.FETCH_ALL_NOTIFICATIONS, { params: request });
  };

  fetchNotification = (notificationId: string): Promise<NotificationResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_NOTIFICATION, { notificationId });
    return this.http.get(url, {
      ignoreException: true,
    });
  };
}

export default Notifications;
