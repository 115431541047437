import React, { useEffect, useState } from 'react';
import { ArrayHelpers, useField } from 'formik';
import { Card } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import {
  StoreProspectUserCompanyBeneficiaryKYBRequestDto,
  StoreProspectUserCompanyShareholderKYBRequestDto,
} from 'api/types/shared/user/company';
import { DataBlock } from 'components/DataBlock';
import { FunctionalButton } from 'components/Button';
import { SelectInput, TextInput } from 'components/Formik';
import { SelectCountryInput } from 'components/Formik/Selects';
import YesNoSelector from 'components/Formik/YesNoSelector';

const SingleBeneficiaryForm: React.FC<{
  dependencyFieldName: string;
  selectorDisabledFieldName: string;
  beneficiary: StoreProspectUserCompanyBeneficiaryKYBRequestDto;
  index: number;
  fieldName: string;
  formikHelpers: any;
  formikArrayHelpers: ArrayHelpers;
}> = ({
  dependencyFieldName,
  selectorDisabledFieldName,
  beneficiary,
  index,
  fieldName,
  formikHelpers,
  formikArrayHelpers,
}) => {
  const { t, tHtml } = useTranslation();
  const [show, setShow] = useState<boolean>(true);
  const [dependencyField] = useField(dependencyFieldName);
  const [selectorDisabledField] = useField(selectorDisabledFieldName);

  const fullName = `${fieldName}[${index}].full_name`;
  const shares_direct = `${fieldName}[${index}].shares_direct`;
  const shares_not_direct = `${fieldName}[${index}].shares_not_direct`;
  const govCode = `${fieldName}[${index}].gov_code`;
  const nationality = `${fieldName}[${index}].nationality`;
  const taxResidencyCountry = `${fieldName}[${index}].tax_residency_country`;
  const isPublicOfficial = `${fieldName}[${index}].is_public_official`;
  const shareholderId = `${fieldName}[${index}].shareholder_id`;

  const setVisibility = async () => {
    const errors = await formikHelpers.validateForm();

    let show = false;
    if (
      errors != undefined &&
      errors.beneficiaries != undefined &&
      errors.beneficiaries[index] != undefined
    ) {
      show = true;
    }

    setShow(show);
  };

  useEffect(() => {
    setVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    index,
    formikHelpers.validateForm,
    formikHelpers.submitCount,
    formikHelpers.values.beneficiaries.length,
  ]);

  if (!show) {
    return (
      <div className={'form-cards'}>
        <Card body className={'light-pink'}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <DataBlock
              title={tHtml('placeholder.company.beneficiary.full_name')}
              data={beneficiary.full_name}
            />
            <DataBlock
              title={tHtml('placeholder.company.beneficiary.gov_code')}
              data={beneficiary.gov_code}
            />
            <DataBlock
              title={tHtml('placeholder.company.beneficiary.nationality')}
              data={t('countries.' + beneficiary.nationality)}
            />
            <div className="buttons">
              <FunctionalButton onClick={() => setShow(true)}>
                {tHtml('common.edit')}
              </FunctionalButton>
              <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
                {tHtml('common.remove')}
              </FunctionalButton>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="sub-form">
      <div className={'mb-4'}>
        <TextInput placeholder={t('placeholder.company.beneficiary.full_name')} name={fullName} />
      </div>
      <div className="mb-1 pt-xxl-0 pt-2">
        <TextInput placeholder={t('placeholder.company.beneficiary.gov_code')} name={govCode} />
      </div>
      <div className={'mb-1'}>
        <SelectCountryInput
          name={nationality}
          placeholder={t('placeholder.company.beneficiary.nationality')}
        />
      </div>
      <div className={'mb-3'}>
        <SelectCountryInput
          name={taxResidencyCountry}
          placeholder={t('placeholder.company.beneficiary.tax_residency_country')}
        />
      </div>
      <div className={'mb-3 d-flex justify-content-between'}>
        <div>
          <TextInput
            placeholder={t('placeholder.company.beneficiary.shares_direct')}
            name={shares_direct}
            type={'number'}
          />
        </div>
        <div>
          <TextInput
            placeholder={t('placeholder.company.beneficiary.shares_not_direct')}
            name={shares_not_direct}
            type={'number'}
          />
        </div>
      </div>
      {selectorDisabledField.value && (
        <div className="mb-3 pt-xxl-0 pt-2">
          <SelectInput
            isDisabled={dependencyField.value.length === 0}
            name={shareholderId}
            options={dependencyField.value.map(
              (value: StoreProspectUserCompanyShareholderKYBRequestDto) => {
                return {
                  label: value.name,
                  value: value.name,
                };
              },
            )}
            placeholder={t('placeholder.company.beneficiary.shareholder_id')}
          />
        </div>
      )}
      <div className={'mb-3'}>
        <YesNoSelector
          name={isPublicOfficial}
          placeholder={tHtml('placeholder.company.beneficiary.is_public_official')}
        />
      </div>
      <div className="mb-3">
        <div className="buttons">
          <FunctionalButton onClick={() => setVisibility()}>
            {tHtml('common.save')}
          </FunctionalButton>
          <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
            {tHtml('common.remove')}
          </FunctionalButton>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default SingleBeneficiaryForm;
