import React from 'react';

import { StyledSidebar } from './Sidebar.style';
import SidebarMobile from './Mobile/SidebarMobile';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <div className={`d-block d-lg-none`}>
        <StyledSidebar className={`vertical-menu ${props.isOpen ? '' : 'hide-sidebar'}`}>
          <div data-simplebar={true} className={'h-auto'}>
            <SidebarMobile isOpen={props.isOpen} setIsOpen={props.setIsOpen} />
          </div>
        </StyledSidebar>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
