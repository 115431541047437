import React from 'react';
import { DataBlock } from 'components/DataBlock';
import { PrimaryButton } from 'components/Button';
import useTranslation from 'helpers/useTranslation';
import { ProjectDeveloperProjectResponseDto } from 'api/types/project-developer/project';
import { openMedia } from 'services/media';

interface Props {
  project: ProjectDeveloperProjectResponseDto;
}

const AfterInvestment: React.FC<Props> = (props) => {
  const { t, tHtml } = useTranslation();

  const downloadLoanAgreement = () => {
    if (props.project.loan_agreement_url) {
      openMedia(props.project.loan_agreement_url);
    }
  };

  const downloadPaymentSchedule = () => {
    if (props.project.payment_schedule_url) {
      openMedia(props.project.payment_schedule_url);
    }
  };

  const FundedDurationInfo: React.FC = () => {
    if (
      !(
        !props.project.funded_duration &&
        props.project.term_left !== null &&
        props.project.term_left > 0
      ) &&
      !props.project.funded_duration
    ) {
      return null;
    }

    return (
      <div className={'after-investment-info'}>
        {!props.project.funded_duration &&
          props.project.term_left !== null &&
          props.project.term_left > 0 && (
            <DataBlock
              title={tHtml('projects.investment_information.term_left')}
              data={tHtml('common.days', { value: props.project.term_left })}
            />
          )}
        {props.project.funded_duration && (
          <DataBlock
            title={tHtml('projects.investment_information.funded_duration')}
            data={props.project.funded_duration}
          />
        )}
        <hr />
      </div>
    );
  };

  return (
    <div className={'after-investment'}>
      <div className={'after-investment-info'}>
        <DataBlock
          title={t('projects.project_invested_collected_amount')}
          data={t('common.money', { value: props.project.invested_amount })}
        />
        <hr />
      </div>
      <FundedDurationInfo />
      <div className={'after-investment-buttons'}>
        <PrimaryButton
          onClick={downloadLoanAgreement}
          className={`form btn btn-primary w-100 mt-4 ${
            props.project.loan_agreement_url ? '' : 'disabled'
          }`}
        >
          {tHtml('projects.loan_agreement')}
        </PrimaryButton>
        <PrimaryButton
          onClick={downloadPaymentSchedule}
          className={`form btn btn-primary w-100 mt-3 ${
            props.project.payment_schedule_url ? '' : 'disabled'
          }`}
        >
          {tHtml('projects.payment_schedule')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default AfterInvestment;
