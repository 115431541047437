import React, { useEffect, useState } from 'react';
import { ArrayHelpers } from 'formik';
import { Card } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { DataBlock } from 'components/DataBlock';
import { FunctionalButton } from 'components/Button';
import { SelectInput, TextInput } from 'components/Formik';
import { SingleSignatureIForm } from './ApplicationSignatures';
import { SelectOption } from 'components/Formik/SelectInput';
import { DokobitLanguageEnum } from 'scopes/guest/helpers/enums/DokobitLanguageEnum';
import CountryLabel from 'components/Labels/CountryLabel';

const SingleSignatureForm: React.FC<{
  signature: SingleSignatureIForm;
  index: number;
  fieldName: string;
  formikHelpers: any;
  formikArrayHelpers: ArrayHelpers;
}> = ({ signature, index, fieldName, formikHelpers, formikArrayHelpers }) => {
  const { t, tHtml } = useTranslation();
  const [show, setShow] = useState<boolean>(true);

  const firstName = `${fieldName}[${index}].first_name`;
  const lastName = `${fieldName}[${index}].last_name`;
  const email = `${fieldName}[${index}].email`;
  const responsibilities = `${fieldName}[${index}].responsibilities`;
  const language = `${fieldName}[${index}].language`;

  const options: SelectOption[] = Object.values(DokobitLanguageEnum).map((status) => ({
    value: status,
    label: <CountryLabel code={status} />,
    customText: t('countries.' + status),
  }));

  const setVisibility = async () => {
    const errors = await formikHelpers.validateForm();

    let show = false;
    if (
      errors != undefined &&
      errors.signatures != undefined &&
      errors.signatures[index] != undefined
    ) {
      show = true;
    }

    setShow(show);
  };

  useEffect(() => {
    setVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    index,
    formikHelpers.validateForm,
    formikHelpers.submitCount,
    formikHelpers.values.signatures.length,
  ]);

  if (!show) {
    return (
      <div className={'form-cards'}>
        <Card body className={'light-pink'}>
          <div className={'d-flex justify-content-between align-items-center flex-wrap gap-2'}>
            {signature.first_name && (
              <DataBlock title={tHtml('placeholder.first_name')} data={signature.first_name} />
            )}{' '}
            {signature.last_name && (
              <DataBlock title={tHtml('placeholder.last_name')} data={signature.last_name} />
            )}
            <DataBlock
              title={tHtml('placeholder.responsibilities')}
              data={signature.responsibilities}
            />
            <DataBlock title={tHtml('placeholder.email')} data={signature.email} />
            {!signature.disable && (
              <div className="buttons">
                <FunctionalButton onClick={() => setShow(true)}>
                  {tHtml('common.edit')}
                </FunctionalButton>
                <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
                  {tHtml('common.remove')}
                </FunctionalButton>
              </div>
            )}
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="sub-form">
      <div className={'mb-4'}>
        <TextInput placeholder={t('placeholder.first_name')} name={firstName} />
      </div>
      <div className={'mb-4'}>
        <TextInput placeholder={t('placeholder.last_name')} name={lastName} />
      </div>
      <div className={'mb-4'}>
        <TextInput placeholder={t('placeholder.email')} name={email} />
      </div>
      <div className={'mb-3'}>
        <TextInput placeholder={t('placeholder.responsibilities')} name={responsibilities} />
      </div>
      <div className={'mb-3'}>
        <SelectInput
          name={language}
          options={options}
          hideLabel={true}
          placeholder={tHtml('placeholder.language')}
        />
      </div>

      <div className="mb-3">
        <div className="buttons">
          <FunctionalButton onClick={() => setVisibility()}>
            {tHtml('common.save')}
          </FunctionalButton>
          <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
            {tHtml('common.remove')}
          </FunctionalButton>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default SingleSignatureForm;
