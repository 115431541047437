import React from 'react';
import useTranslation from 'helpers/useTranslation';
import TextInput from 'components/Formik/TextInput';
import { DatepickerInput } from 'components/Formik/Inputs';

export const RefinancingFormSection = () => {
  const { t, tHtml } = useTranslation();

  return (
    <>
      <h2 className="mt-5 mb-4">{tHtml('prospect_application.refinancing_details')}</h2>
      <div className="mb-4">
        <TextInput
          type={'number'}
          name={'refinancing.debt_amount'}
          placeholder={t('placeholder.prospect_application.refinancing.debt_amount')}
        />
      </div>
      <div className="mb-4">
        <DatepickerInput
          hasFutureRange={true}
          name={'refinancing.debt_due_date'}
          label={t('placeholder.prospect_application.refinancing.debt_due_date')}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name={'refinancing.debt_purpose'}
          placeholder={t('placeholder.prospect_application.refinancing.debt_purpose')}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name={'refinancing.debt_security_deposit'}
          placeholder={t('placeholder.prospect_application.refinancing.debt_security_deposit')}
        />
      </div>
      <div className="mb-4">
        <TextInput
          type={'number'}
          name={'refinancing.debt_security_deposit_value'}
          placeholder={t(
            'placeholder.prospect_application.refinancing.debt_security_deposit_value',
          )}
        />
      </div>
      <div className="mb-3">
        <TextInput
          name={'refinancing.debt_refinancing_reason'}
          placeholder={t('placeholder.prospect_application.refinancing.debt_refinancing_reason')}
        />
      </div>
    </>
  );
};
