import Client from '../../client';
import Http from '../../../http';
import { SignUploadRequestDto } from '../../../types/app';
import {
  StoreUserCompanyRequestDto,
  StoreUserCompanyResponseDto,
} from '../../../types/shared/user/company';
import {
  InvestorKybDataV2RequestDto,
  ProjectDeveloperKybDataV2RequestDto,
} from '../../../types/shared/company/profile';

class SharedUserCompany extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      STORE_COMPANY: this.protectedUrl(`${this.http.baseUrl}/user/company`),
      STORE_COMPANY_KYB_PROJECT_DEVELOPER: this.protectedUrl(
        `${this.http.baseUrl}/user/company/{companyId}/kyb/project-developer`,
      ),
      STORE_COMPANY_KYB_INVESTOR: this.protectedUrl(
        `${this.http.baseUrl}/user/company/{companyId}/kyb/investor`,
      ),
      UPLOAD_COMPANY_DOCUMENTS: this.protectedUrl(`${this.http.baseUrl}/user/company/documents`),
    };
  }

  storeCompany = (payload: StoreUserCompanyRequestDto): Promise<StoreUserCompanyResponseDto> => {
    return this.http.post(this.api.STORE_COMPANY, payload);
  };

  storeCompanyProjectDeveloperKYB = (
    companyId: string,
    payload: ProjectDeveloperKybDataV2RequestDto,
  ): Promise<string | never> => {
    const url = this.buildUrl(this.api.STORE_COMPANY_KYB_PROJECT_DEVELOPER, { companyId });

    return this.http.post(url, payload);
  };

  storeCompanyInvestorKYB = (
    companyId: string,
    payload: InvestorKybDataV2RequestDto,
  ): Promise<string | never> => {
    const url = this.buildUrl(this.api.STORE_COMPANY_KYB_INVESTOR, { companyId });

    return this.http.post(url, payload);
  };

  uploadCompanyDocuments = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.post(this.api.UPLOAD_COMPANY_DOCUMENTS, request);
  };
}

export default SharedUserCompany;
