import React, { useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { RouteList } from '../routes';
import useUserType from '../helpers/useUserType';
import { ProjectStatusEnum } from '../helpers/enums/ProjectStatusEnum';
import Placeholder from 'assets/images/placeholder_300x200.svg';

interface ProjectProps {
  id: string;
  image_url: string | null;
  status?: ProjectStatusEnum | null;
}

const ProjectCardLink: React.FC<ProjectProps> = ({ id, image_url, status }) => {
  const userType = useUserType();
  const [routeParam, setRouteParam] = useState(RouteList.GUEST.PROJECTS.VIEW);

  useEffect(() => {
    if (userType.isDeveloper()) {
      setRouteParam(RouteList.PROJECT_DEVELOPER.PROJECTS.VIEW);
    }
  }, [userType]);

  if (!userType.isDeveloper() && status && status === ProjectStatusEnum.COMING_SOON) {
    return (
      <>
        {image_url && <img src={image_url} alt={'Project'} />}
        {!image_url && <img src={Placeholder} alt={'Project'} />}
      </>
    );
  }

  return (
    <Link
      to={{
        pathname: generatePath(routeParam, {
          projectId: id,
        }),
      }}
    >
      {image_url && <img src={image_url} alt={'Project'} />}
      {!image_url && <img src={Placeholder} alt={'Project'} />}
    </Link>
  );
};
export default ProjectCardLink;
