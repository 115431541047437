import React, { useEffect } from 'react';
import { Row } from 'reactstrap';
import { DashboardHeader } from 'scopes/developer/pages/Dashboard/components/DashboardHeader';
import ProjectList from './components/ProjectsList/ProjectList';
import { ApplicationList } from './components/ApplicationsList';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';
import { useAccountQuery } from 'api/queries';
import { useStepsModalStateStore } from '../../../investor/modals/RequiredSteps/store';
import DeveloperRequiredStepsModal from '../../modals/DeveloperRequiredStepsModal';

const Dashboard: React.FC = () => {
  const { showModal } = useGlobalModalContext();
  const { data: userCompanyProfileData } = useUserCompanyProfileQuery();
  const { data: userAccount } = useAccountQuery();
  const modalState = useStepsModalStateStore();

  useEffect(() => {
    if (
      modalState.shouldBeShown &&
      userAccount &&
      userCompanyProfileData &&
      userCompanyProfileData.company &&
      (userAccount.main_user_verified != true ||
        userCompanyProfileData.know_your_business == null ||
        userAccount.is_investor_kyb_expired == true ||
        userAccount.is_document_expired == true)
    ) {
      const delay = 2000;
      const timeoutId = setTimeout(() => {
        showModal(<DeveloperRequiredStepsModal />);
      }, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCompanyProfileData]);

  return (
    <>
      <div className={'mb-2'}>
        <Row>
          <DashboardHeader />
        </Row>
      </div>
      <div className={'mb-6'}>
        <Row>
          <ProjectList />
        </Row>
        <Row>
          <ApplicationList />
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
