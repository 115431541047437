import React from 'react';
import * as Roles from 'helpers/auth/roles';
import AuthRoute from 'routes/AuthRoute';
import { StepFormLayout } from 'containers';
import {
  otherSteps,
  realEstateDevelopmentSteps,
  realEstatePurchaseSteps,
  refinancingSteps,
  workingCapitalSteps,
} from './steps';

const ApplicationRoutes: any = () => [
  realEstateDevelopmentSteps.map((step) => (
    <AuthRoute
      path={step.route}
      component={step.component}
      layout={StepFormLayout}
      key={step.route}
      permissions={Roles.User.permissions}
      redirect={Roles.User.redirect}
      exact
    />
  )),
  refinancingSteps.map((step) => (
    <AuthRoute
      path={step.route}
      component={step.component}
      layout={StepFormLayout}
      key={step.route}
      permissions={Roles.User.permissions}
      redirect={Roles.User.redirect}
      exact
    />
  )),
  workingCapitalSteps.map((step) => (
    <AuthRoute
      path={step.route}
      component={step.component}
      layout={StepFormLayout}
      key={step.route}
      permissions={Roles.User.permissions}
      redirect={Roles.User.redirect}
      exact
    />
  )),
  realEstatePurchaseSteps.map((step) => (
    <AuthRoute
      path={step.route}
      component={step.component}
      layout={StepFormLayout}
      key={step.route}
      permissions={Roles.User.permissions}
      redirect={Roles.User.redirect}
      exact
    />
  )),
  otherSteps.map((step) => (
    <AuthRoute
      path={step.route}
      component={step.component}
      layout={StepFormLayout}
      key={step.route}
      permissions={Roles.User.permissions}
      redirect={Roles.User.redirect}
      exact
    />
  )),
];

export default ApplicationRoutes;
