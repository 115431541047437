import React from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { ModalComponent } from 'components/Modal/ModalComponent';
import { ModalWrapper, StepDiv, StepsWrapper, StepText } from './RequiredStepsModal.style';
import { PrimaryButton } from 'components/Button';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';
import { RouteList } from 'routes';
import { navigate } from 'helpers';
import StepsModalCloseButton from './components/StepsModalCloseButton';
import { useAccountQuery } from 'api/queries';

const InvestorRequiredStepsModal: React.FC = ({ ...props }) => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const { data } = useUserCompanyProfileQuery();
  const { data: userAccount } = useAccountQuery();

  const steps = () => {
    return (
      userAccount &&
      data && (
        <ModalWrapper>
          <StepsWrapper>
            <StepDiv>
              <div className="steps">
                <div
                  className={`step ${
                    userAccount.main_user_verified === true &&
                    userAccount.is_document_expired != true
                      ? 'completed'
                      : 'active'
                  }`}
                >
                  <div className="number">{1}</div>
                </div>
              </div>
              <StepText>{tHtml('modal.steps.owner')}</StepText>
              {userAccount.main_user_verified === true &&
              userAccount.is_document_expired !== true ? null : (
                <PrimaryButton
                  onClick={() => {
                    navigate(RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION);
                    hideModal();
                  }}
                >
                  {tHtml('modal.steps.complete')}
                </PrimaryButton>
              )}
            </StepDiv>
            <StepDiv>
              <div className="steps">
                <div
                  className={`step ${
                    data.know_your_business != null && userAccount.is_investor_kyb_expired != true
                      ? 'completed'
                      : 'active'
                  }`}
                >
                  <div className="number">{2}</div>
                </div>
              </div>
              <StepText>{tHtml('modal.steps.kyb')}</StepText>
              {data.know_your_business != null &&
              userAccount.is_investor_kyb_expired != true ? null : (
                <PrimaryButton
                  onClick={() => {
                    navigate(RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS);
                    hideModal();
                  }}
                >
                  {tHtml('modal.steps.complete')}
                </PrimaryButton>
              )}
            </StepDiv>
            <StepDiv lastElement={true}>
              <div className="steps">
                <div
                  className={`step ${
                    data.company.has_investor_quiz_filled! &&
                    userAccount.is_investor_quiz_expired != true
                      ? 'completed'
                      : 'active'
                  }`}
                >
                  <div className="number">{3}</div>
                </div>
              </div>
              <StepText>{tHtml('modal.steps.quiz')}</StepText>
              {data.company.has_investor_quiz_filled! &&
              userAccount.is_investor_quiz_expired != true ? null : (
                <PrimaryButton
                  onClick={() => {
                    navigate(RouteList.INVESTOR.QUIZ.VIEW);
                    hideModal();
                  }}
                >
                  {tHtml('modal.steps.complete')}
                </PrimaryButton>
              )}
            </StepDiv>
          </StepsWrapper>
        </ModalWrapper>
      )
    );
  };

  return (
    <React.Fragment>
      <ModalComponent
        size={'md'}
        {...props}
        contentClassName={'modal-investment'}
        backdrop="static"
      >
        <div className={'p-3'}>
          <ModalHeader toggle={hideModal} close={<StepsModalCloseButton />} tag={'h1'}>
            {tHtml('modal.steps.title')}
          </ModalHeader>
          <ModalBody>{steps()}</ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default InvestorRequiredStepsModal;
