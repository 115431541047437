import { LoginUserResponseDto } from './authenticate';

export enum SocialNetworkType {
  Unknown = 'unknown',
  Google = 'google',
  Facebook = 'facebook',
}

export type SocialLoginRequestDto = {
  social_network_type: keyof typeof SocialNetworkType | string;
  token: string;
};
export type SocialSignUpRequestDto = {
  social_network_type: keyof typeof SocialNetworkType | string;
  token: string;
  tac: boolean;
  direct_marketing: boolean | null;
  affiliate_code: string | null;
  tc_code: string | null;
};

export type SocialLoginResponseDto = LoginUserResponseDto;
export type SocialSignUpResponseDto = LoginUserResponseDto;
export type ExternalSocialSignUpResponse = LoginUserResponseDto;
