import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Nav } from 'reactstrap';

import { RouteList } from 'routes';
import {
  StyledDesktopSidebarWrapper,
  StyledDesktopSidebarWrapperVerticalMenu,
} from './SidebarDashboard.style';
import { Permission } from 'helpers/auth/permissions';
import usePermissions from 'helpers/usePermissions';

export interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarDashboard: React.FC = () => {
  const { t } = useTranslation();
  const { can } = usePermissions();

  interface MenuItem {
    name: string;
    path: string;
    icon: string;
    permission?: string;
  }

  const menuItems: MenuItem[] = [
    {
      name: t('menu.account_overview'),
      path: RouteList.DASHBOARD,
      icon: 'icon-home-alt',
    },
    {
      name: t('menu.invest'),
      path: RouteList.INVESTOR.PROJECTS.LIST,
      icon: 'icon-euro-circle',
    },
    {
      name: t('menu.secondary_market'),
      path: RouteList.INVESTOR.SECONDARY_MARKET.BUYER.OVERVIEW,
      icon: 'icon-money-insert',
      permission: Permission.ENABLED_SECONDARY_MARKET,
    },
    {
      name: t('menu.auto_investment'),
      path: RouteList.INVESTOR.AUTO_INVESTMENT.VIEW,
      icon: 'icon-graph-bar',
      permission: Permission.ENABLED_AUTO_INVESTMENTS,
    },
    {
      name: t('menu.affiliate'),
      path: RouteList.INVESTOR.AFFILIATE.VIEW,
      icon: 'icon-handshake',
    },
    {
      name: t('menu.my_investments'),
      path: RouteList.INVESTOR.MY_INVESTMENTS.LIST,
      icon: 'icon-bag',
    },
    {
      name: t('menu.transactions'),
      path: RouteList.INVESTOR.TRANSACTIONS.LIST,
      icon: 'icon-arrows-down-up',
    },
    {
      name: t('menu.documents'),
      path: RouteList.INVESTOR.DOCUMENTS.LIST,
      icon: 'icon-file-text',
    },
  ];

  const renderMenu = (menu: MenuItem[]) => {
    return menu.map((menuItem, index) => {
      if (menuItem.permission && !can(menuItem.permission)) {
        return <React.Fragment key={index} />;
      }

      return (
        <li key={index}>
          <Link to={menuItem.path ?? '#'}>
            <i className={`icon ${menuItem.icon} me-2`} />
            <span>{menuItem.name}</span>
          </Link>
        </li>
      );
    });
  };

  return (
    <React.Fragment>
      <Nav vertical={true}>
        <StyledDesktopSidebarWrapper>
          <StyledDesktopSidebarWrapperVerticalMenu className={'list-unstyled'}>
            {renderMenu(menuItems)}
          </StyledDesktopSidebarWrapperVerticalMenu>
        </StyledDesktopSidebarWrapper>
      </Nav>
    </React.Fragment>
  );
};
export default SidebarDashboard;
