import React, { ReactElement } from 'react';
import { Col, Row, Table } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import SanitizedHtmlBlock from 'components/SanitizedHtmlBlock';
import { ReadMore } from 'components/ReadMore';
import { ProjectContextState, withProject } from 'components/Project/Scope/ProjectProvider';
import { ProjectDeveloperProjectResponseDto } from 'api/types/project-developer/project';
import { InvestorProjectResponse } from 'api/types/investor/project';
import HighlightedInformation from './HighlightedInformation';
import Files from './Files';
import ProjectOwner from './ProjectOwner';
import Articles from './Articles';
import { GuestProjectResponse } from 'api/types/guest/project';
import { notZero } from 'helpers/utils';
import ProjectRiskTable from '../../Table/ProjectRiskTable/ProjectRiskTable';

type ProjectResponseType =
  | GuestProjectResponse
  | ProjectDeveloperProjectResponseDto
  | InvestorProjectResponse;

type MobileTableValueProps = {
  title: string | ReactElement;
  value: any;
};

const ProjectBody: React.FC<ProjectContextState> = ({ project }) => {
  const { t, tHtml } = useTranslation();
  const p: ProjectResponseType | undefined = project as ProjectResponseType;
  if (!p?.authorized_details) return <></>;

  const MobileTableValue: React.FC<MobileTableValueProps> = ({ title, value }) => {
    return (
      <Row className="mobile-table-card-value">
        <Col xs={5} className="header-value">
          <span className="span-align-top">{title}</span>
        </Col>
        <Col xs={7} className={'value-cell'}>
          {value}
        </Col>
      </Row>
    );
  };

  return (
    <div className={'project-section-with-side'}>
      <div className={'project-body'}>
        {p.authorized_details.project_description && (
          <section>
            <h2>{tHtml('projects.project_information')}</h2>
            <ReadMore>
              <SanitizedHtmlBlock content={p.authorized_details.project_description} />
            </ReadMore>
          </section>
        )}

        {p.authorized_details.early_return_rules && (
          <section>
            <div className={'text-not-important'}>
              {tHtml('projects.investment_information.early_return_rules')}
            </div>
            <p className={'word-break p-important mb-3'}>
              {p.authorized_details.early_return_rules}{' '}
            </p>
          </section>
        )}

        <section className={'mb-5'}>
          <h2>{tHtml('projects.technical_information')}</h2>
          <div className={'bottom-border-radius-0'}>
            <HighlightedInformation
              information={[
                {
                  title: t('projects.technical_information.object_energy_class'),
                  value: p.authorized_details.object_energy_class,
                },
              ]}
            />
          </div>
          <div className={'border-radius-0'}>
            <HighlightedInformation
              information={[
                {
                  title: t('projects.technical_information.number_of_real_estates'),
                  value: p.authorized_details.number_of_re,
                },
                {
                  title: t('projects.technical_information.area_of_object'),
                  value: p.authorized_details.area_of_object,
                },
              ]}
            />
          </div>
          <div className={'top-border-radius-0'}>
            <HighlightedInformation
              information={[
                {
                  title: t('projects.technical_information.construction_start'),
                  value: p.authorized_details.start_construction_at,
                },
                {
                  title: t('projects.technical_information.construction_end'),
                  value: p.authorized_details.end_construction_at,
                },
              ]}
            />
          </div>
        </section>

        {p.authorized_details.project_place_description && (
          <section className={'mb-5'}>
            <div className={'text-not-important mt-3'}>
              {tHtml('projects.technical_information.project_place_description')}
            </div>
            <p className={'word-break mt-2'}>{p.authorized_details.project_place_description} </p>
          </section>
        )}

        <section className={'mb-5'}>
          <h2>{tHtml('projects.security_measures')}</h2>
          {p.authorized_details.asset_description && (
            <SanitizedHtmlBlock
              content={p.authorized_details.asset_description}
              className={'p-important'}
            />
          )}
          <div className={'mb-4'}>
            <HighlightedInformation
              information={[
                {
                  title: t('projects.description.security_deposit_appraisers_title'),
                  value: p.authorized_details.security_deposit_appraisers,
                },
                {
                  title: t(
                    p.security_deposits_includes_vat === null
                      ? 'projects.description.security_deposit_value_title'
                      : p.security_deposits_includes_vat
                      ? 'projects.description.security_deposit_value_vat'
                      : 'projects.description.security_deposit_value_no_vat',
                  ),
                  value: `${t('common.money', {
                    value: p.authorized_details.security_deposit_value,
                  })}`,
                },
              ]}
            />
          </div>
          {p.authorized_details.security_deposits &&
            p.authorized_details.security_deposits.length > 0 && (
              <div>
                <div className={'d-none d-xl-block'}>
                  <Table>
                    <thead>
                      <th>{tHtml('projects.security_measures.real_estate_type')}</th>
                      <th>{tHtml('projects.security_measures.real_estate_address')}</th>
                      <th>{tHtml('projects.security_measures.real_estate_unique')}</th>
                      <th>{tHtml('projects.security_measures.real_estate_plot')}</th>
                      <th>
                        {tHtml(
                          p.security_deposits_includes_vat === null
                            ? 'projects.security_measures.real_estate_value'
                            : p.security_deposits_includes_vat
                            ? 'projects.description.security_deposit_appraisers_value_vat'
                            : 'projects.description.security_deposit_appraisers_value_no_vat',
                        )}
                      </th>
                    </thead>
                    <tbody>
                      {p.authorized_details.security_deposits.map((deposit, key) => (
                        <tr key={key}>
                          <td>
                            {tHtml('placeholder.real_estate_type.' + deposit.real_estate_type)}
                          </td>
                          <td>{deposit.real_estate_address}</td>
                          <td>{deposit.real_estate_unique}</td>
                          <td>{deposit.real_estate_plot}</td>
                          <td>
                            {deposit.real_estate_value
                              ? tHtml('common.money', { value: deposit.real_estate_value })
                              : '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className={'d-block d-xl-none custom-table mobile-table-view'}>
                  {p.authorized_details.security_deposits.map((deposit, key) => (
                    <div key={key} className="mobile-table-card">
                      <MobileTableValue
                        title={tHtml('projects.security_measures.real_estate_type')}
                        value={tHtml('placeholder.real_estate_type.' + deposit.real_estate_type)}
                      />
                      <MobileTableValue
                        title={tHtml('projects.security_measures.real_estate_address')}
                        value={deposit.real_estate_address}
                      />
                      <MobileTableValue
                        title={tHtml('projects.security_measures.real_estate_unique')}
                        value={deposit.real_estate_unique}
                      />
                      <MobileTableValue
                        title={tHtml('projects.security_measures.real_estate_plot')}
                        value={deposit.real_estate_plot}
                      />
                      <MobileTableValue
                        title={tHtml('projects.security_measures.real_estate_value')}
                        value={
                          deposit.real_estate_value
                            ? tHtml('common.money', { value: deposit.real_estate_value })
                            : '-'
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
        </section>
        <section>
          <ProjectRiskTable
            riskCategory={
              p.authorized_details.risk_category
                ? p.authorized_details.risk_category.toString()
                : null
            }
          />
        </section>
        {p.authorized_details?.financial_info &&
          Object.values(p.authorized_details?.financial_info).some((info) => info !== null) && (
            <section>
              <h2>{tHtml('projects.financial_information')}</h2>
              <Table>
                <tbody>
                  {notZero(p.authorized_details.financial_info.amount_of_own_funds) && (
                    <tr>
                      <td>{tHtml('projects.financial_information.amount_of_own_funds')}</td>
                      <td className={'text-important text-right'}>
                        {tHtml('common.money', {
                          value: p.authorized_details.financial_info.amount_of_own_funds,
                        })}
                      </td>
                    </tr>
                  )}
                  {notZero(p.authorized_details.financial_info.area_of_property_on_sale) && (
                    <tr>
                      <td>{tHtml('projects.financial_information.area_of_property_on_sale')}</td>
                      <td className={'text-important text-right'}>
                        {p.authorized_details.financial_info.area_of_property_on_sale ?? '-'}
                      </td>
                    </tr>
                  )}
                  {notZero(p.authorized_details.financial_info.selling_price) && (
                    <tr>
                      <td>{tHtml('projects.financial_information.selling_price')}</td>
                      <td className={'text-important text-right'}>
                        {tHtml('common.money', {
                          value: p.authorized_details.financial_info.selling_price,
                        })}
                      </td>
                    </tr>
                  )}
                  {notZero(p.authorized_details.financial_info.value_of_parcel_of_land) && (
                    <tr>
                      <td>{tHtml('projects.financial_information.value_of_parcel_of_land')}</td>
                      <td className={'text-important text-right'}>
                        {tHtml('common.money', {
                          value: p.authorized_details.financial_info.value_of_parcel_of_land,
                        })}
                      </td>
                    </tr>
                  )}
                  {notZero(p.authorized_details.financial_info.construction_cost) && (
                    <tr>
                      <td>{tHtml('projects.financial_information.construction_cost')}</td>
                      <td className={'text-important text-right'}>
                        {tHtml('common.money', {
                          value: p.authorized_details.financial_info.construction_cost,
                        })}
                      </td>
                    </tr>
                  )}
                  {notZero(p.authorized_details.financial_info.project_cost) && (
                    <tr>
                      <td>{tHtml('projects.financial_information.project_cost')}</td>
                      <td className={'text-important text-right'}>
                        {tHtml('common.money', {
                          value: p.authorized_details.financial_info.project_cost,
                        })}
                      </td>
                    </tr>
                  )}
                  {notZero(p.authorized_details.financial_info.loan_cost) && (
                    <tr>
                      <td>{tHtml('projects.financial_information.loan_cost')}</td>
                      <td className={'text-important text-right'}>
                        {tHtml('common.money', {
                          value: p.authorized_details.financial_info.loan_cost,
                        })}
                      </td>
                    </tr>
                  )}
                  {notZero(p.authorized_details.financial_info.total_project_cost) && (
                    <tr>
                      <td>{tHtml('projects.financial_information.total_project_cost')}</td>
                      <td className={'text-important text-right'}>
                        {tHtml('common.money', {
                          value: p.authorized_details.financial_info.total_project_cost,
                        })}
                      </td>
                    </tr>
                  )}
                  {notZero(p.authorized_details.financial_info.total_income) && (
                    <tr>
                      <td>{tHtml('projects.financial_information.total_income')}</td>
                      <td className={'text-important text-right'}>
                        {tHtml('common.money', {
                          value: p.authorized_details.financial_info.total_income,
                        })}
                      </td>
                    </tr>
                  )}
                  {notZero(p.authorized_details.financial_info.project_profit) && (
                    <tr>
                      <td>{tHtml('projects.financial_information.project_profit')}</td>
                      <td className={'text-important text-right'}>
                        {tHtml('common.money', {
                          value: p.authorized_details.financial_info.project_profit,
                        })}
                      </td>
                    </tr>
                  )}
                  {p.authorized_details.financial_info.maximum_costs_note && (
                    <tr>
                      <td>{tHtml('projects.financial_information.maximum_costs_note')}</td>
                      <td className={'text-important text-right'}>
                        {p.authorized_details.financial_info.maximum_costs_note}
                      </td>
                    </tr>
                  )}
                  {p.authorized_details.financial_info.preliminary_data_note && (
                    <tr>
                      <td>{tHtml('projects.financial_information.preliminary_data_note')}</td>
                      <td className={'text-important text-right'}>
                        {p.authorized_details.financial_info.preliminary_data_note}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </section>
          )}
      </div>
      {(p.authorized_details.articles ||
        p.authorized_details.documents ||
        p.authorized_details.project_owner_name) && (
        <div className={'project-side'}>
          {p.authorized_details.articles && p.authorized_details.articles.length > 0 && (
            <div className={'project-side-component'}>
              <div className={'project-side-title'}>{tHtml('projects.articles')}</div>
              <Articles articles={p.authorized_details.articles} />
            </div>
          )}
          {p.authorized_details.documents &&
            (p.authorized_details.documents.length > 0 || p.authorized_details.project_risks) && (
              <>
                <hr />
                <div className={'project-side-component'}>
                  <div className={'project-side-title'}>{tHtml('projects.documents')}</div>
                  <Files
                    files={p.authorized_details.documents}
                    projectRisks={p.authorized_details.project_risks}
                  />
                </div>
              </>
            )}
          {p.authorized_details.project_owner_name && (
            <>
              <hr />
              <div className={'project-side-component'}>
                <div className={'project-side-title'}>{tHtml('projects.owner')}</div>
                <ProjectOwner />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default withProject(ProjectBody);
