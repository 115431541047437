import styled from 'styled-components';

export const StyledNotificationDropdown = styled.div`
  padding: 0.2rem;

  .dropdown {
    &.show {
      .dropdown-menu {
        &.show {
          width: 18.5rem;
        }
      }
    }
  }
`;
