import React, { ReactElement } from 'react';
import useTranslation from 'helpers/useTranslation';

interface YesNoBlockProps {
  label: string | ReactElement;
  checked?: boolean;
}

const YesNoBlock: React.FC<YesNoBlockProps> = ({ label, checked = false }) => {
  const { tHtml } = useTranslation();

  return (
    <div className={'mb-2'}>
      <span className="form-question-label">{label}</span>
      <div className="d-flex gap-2 py-2">
        <button className={`site btn btn-highlight ${checked ? 'active' : ''}`} disabled>
          {tHtml('common.yes')}
        </button>

        <button className={`site btn btn-highlight ${!checked ? 'active' : ''}`} disabled>
          {tHtml('common.no')}
        </button>
      </div>
    </div>
  );
};

export default YesNoBlock;
