const updatePath = (url: string, params: { [key: string]: string | number }): string => {
  for (const key in params) {
    const value = params[key];
    url = url.replace(':' + key, value.toString());
  }

  return url;
};

export default updatePath;
