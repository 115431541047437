import React from 'react';
import { Navbar, NavbarBrand, Container, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import ResponsiveLogo from 'components/Logo/ResponsiveLogo';

interface Props {
  to?: string;
  text?: string;
  showFinishLater?: boolean;
}

const StepFormHeader: React.FC<Props> = ({ to, text, showFinishLater }) => {
  const { tHtml } = useTranslation();
  return (
    <Container className={'px-2 px-sm-4 px-md-6'}>
      <Navbar>
        <NavbarBrand tag={'span'}>
          <ResponsiveLogo />
        </NavbarBrand>
        {showFinishLater && to && text && (
          <Nav>
            <NavItem>
              <NavLink to={to} className={'navbar-link'} tag={Link}>
                {tHtml(text)}
              </NavLink>
            </NavItem>
          </Nav>
        )}
      </Navbar>
      <hr className={'my-2'} />
    </Container>
  );
};

export default StepFormHeader;

StepFormHeader.defaultProps = {
  showFinishLater: true,
  to: RouteList.DASHBOARD,
  text: 'common.fill_later',
};
