import { InvestorInvestmentListResponseDto } from 'api/types/investor/investment';
import { useDifferenceBetweenTwoDatesToString } from 'helpers/utils';
import useTranslation from 'helpers/useTranslation';

export const useCalculateCreditDuration = () => {
  const { t } = useTranslation();
  const differenceBetweenTwoDatesToString = useDifferenceBetweenTwoDatesToString();

  return (investment: InvestorInvestmentListResponseDto) => {
    const creditDuration = t('common.month', { value: investment.credit_duration });

    // If finished at is set, then don't add countdown, because project is already finished.

    if (investment.finished_at) {
      return creditDuration;
    }

    if (investment.confirmed_at) {
      const confirmedAt = new Date(investment.confirmed_at);
      confirmedAt.setHours(0, 0, 0, 0);

      const finishedAtForecast = new Date(
        confirmedAt.getFullYear(),
        confirmedAt.getMonth() + investment.credit_duration,
        confirmedAt.getDate(),
      );
      finishedAtForecast.setHours(0, 0, 0, 0);

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (today >= finishedAtForecast) {
        return creditDuration;
      }

      const countdown = differenceBetweenTwoDatesToString(
        today.toString(),
        finishedAtForecast.toString(),
      );

      return `${creditDuration} (${countdown})`;
    }

    return creditDuration;
  };
};
