import Client from 'api/services/client';
import Http from 'api/http';
import { PaginationData, PaginationDataFilter } from 'api/types';
import {
  ApplyPromoCodeResponse,
  ApplyPromoCodesRequest,
  InvestInProjectRequest,
  InvestInProjectResponse,
  InvestorProjectListResponse,
  InvestorProjectResponse,
  WantToTransferInvestmentsRequest,
} from 'api/types/investor/project';

class InvestorProject extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_PROJECT: this.protectedUrl(
        `${this.http.baseUrl}/investor/projects/project/{projectId}`,
      ),
      FETCH_PROJECTS: this.protectedUrl(`${this.http.baseUrl}/investor/projects`),
      APPLY_PROMO_CODE: this.protectedUrl(
        `${this.http.baseUrl}/investor/projects/project/{projectId}/apply-promo-code`,
      ),
      INVEST_IN_PROJECT: this.protectedUrl(
        `${this.http.baseUrl}/investor/projects/project/{projectId}/invest`,
      ),
      WANT_TO_INVEST_IN_PROJECT: this.protectedUrl(
        `${this.http.baseUrl}/investor/projects/project/{projectId}/want-to-invest`,
      ),
      WANT_TO_TRANSFER_INVESTMENT_FOR_PROJECT: this.protectedUrl(
        `${this.http.baseUrl}/investor/projects/project/{projectId}/want-to-transfer-investment`,
      ),
      PREVIEW_IIPS: this.protectedUrl(
        `${this.http.baseUrl}/investor/projects/project/{projectId}/preview-iips`,
      ),
    };
  }

  fetchProject = (projectId: string | number): Promise<InvestorProjectResponse> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT, { projectId });
    return this.http.get(url);
  };

  fetchProjects = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<InvestorProjectListResponse>> => {
    return this.http.get(this.api.FETCH_PROJECTS, { params: request });
  };

  applyPromoCode = (
    projectId: string | number,
    request: ApplyPromoCodesRequest,
  ): Promise<ApplyPromoCodeResponse> => {
    const url = this.buildUrl(this.api.APPLY_PROMO_CODE, { projectId });
    return this.http.post(url, request, {
      ignoreException: true,
    });
  };

  investInProject = (
    projectId: string | number,
    request: InvestInProjectRequest,
  ): Promise<InvestInProjectResponse> => {
    const url = this.buildUrl(this.api.INVEST_IN_PROJECT, { projectId });
    return this.http.post(url, request);
  };

  wantToInvestInProject = (projectId: string | number): any => {
    const url = this.buildUrl(this.api.WANT_TO_INVEST_IN_PROJECT, { projectId });
    return this.http.post(url);
  };

  wantToTransferInvestmentForProject = (
    projectId: string | number,
    request: WantToTransferInvestmentsRequest,
  ): any => {
    const url = this.buildUrl(this.api.WANT_TO_TRANSFER_INVESTMENT_FOR_PROJECT, { projectId });
    return this.http.post(url, request);
  };

  previewIIPS = (projectId: string | number): Promise<any> => {
    const url = this.buildUrl(this.api.PREVIEW_IIPS, { projectId });

    return this.http.get(url, {
      responseType: 'blob',
    });
  };
}

export default InvestorProject;
