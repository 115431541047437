//----------- exception
import { RouteList } from 'routes';
import * as H from 'history';

export enum InvestorExceptionEnum {
  InsufficientFundsException = 'InsufficientFundsException',
  InvestorQuizNotFilledException = 'InvestorQuizNotFilledException',
}

export enum SecondaryMarketExceptionEnum {
  InvestmentSaleCanceledException = 'InvestmentSaleCanceledException',
  InvestmentAlreadySoldException = 'InvestmentAlreadySoldException',
}

export const ExceptionCta = {
  [InvestorExceptionEnum.InvestorQuizNotFilledException]: {
    CTA_ACTION: (history: H.History) => history.push(RouteList.INVESTOR.QUIZ.VIEW),
  },
};
