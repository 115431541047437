import Table from 'components/Table/Table';
import { FunctionalButton } from 'components/Button';
import React, { useCallback, useState } from 'react';
import { PaginationData, PaginationDataFilter } from 'api/types';
import Api from 'api';
import { InvestorExpandableDocumentReformattedResponseDto } from 'api/types/investor/document';
import { TableInitialSortBy } from 'api/types/common';
import useTranslation from 'helpers/useTranslation';
import useDisplay from 'helpers/useDisplay';

interface DocumentsTableProps {
  columns: any;
  selectedTableOption: string;
}

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'project_name',
    desc: false,
  },
];

const DocumentsTable = ({ columns, selectedTableOption }: DocumentsTableProps) => {
  const { tHtml, currentLanguage } = useTranslation();
  const [data, setData] = useState<
    PaginationData<InvestorExpandableDocumentReformattedResponseDto> | undefined
  >(undefined);
  const [displayData, setDisplayData] = useState<
    PaginationData<InvestorExpandableDocumentReformattedResponseDto> | undefined
  >(undefined);

  const [clickedShowMore, setClickedShowMore] = useState<boolean>(false);

  const { isMobile } = useDisplay();

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.investor.documents
        .filterProjectDocuments(request, selectedTableOption)
        .then((response) => {
          const reformattedResponse: PaginationData<InvestorExpandableDocumentReformattedResponseDto> =
            {
              meta: { ...response.meta },
              data: [],
            };

          for (const item of response.data) {
            reformattedResponse.data.push({
              ...item,
              subRows: item.sub_rows,
            });
          }

          setData(reformattedResponse);

          if (clickedShowMore) {
            setDisplayData(reformattedResponse);
          } else {
            const displayDataMinimalMaxFour = { ...reformattedResponse };
            displayDataMinimalMaxFour.data = displayDataMinimalMaxFour.data.slice(0, 4);

            setDisplayData(displayDataMinimalMaxFour);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clickedShowMore, selectedTableOption, currentLanguage],
  );

  const handleShowMore = () => {
    setDisplayData(data);
    setClickedShowMore(true);
  };

  return (
    <>
      <Table
        columns={columns}
        data={displayData}
        onFetchData={fetchData}
        showPageSizes={false}
        defaultLimit={10}
        showPagination={clickedShowMore}
        mobile={true}
        hideSubRowColumns={['project_name']}
        hideSortFields={[
          'expander',
          'investment_conditions',
          'loan_agreement',
          'payment_schedule',
          'other_documents',
          'expander-mobile',
        ]}
        hideColumnsOnDesktop={['expander-mobile']}
        hideColumnsOnMobile={['expander']}
        initialSortBy={INITIAL_SORT_ORDER}
      />
      <div className={'mt-3'}>
        {!isMobile && !clickedShowMore && data && data.data?.length > 4 && (
          <div className={'d-flex justify-content-end'}>
            <FunctionalButton onClick={handleShowMore}>
              {tHtml('common.more')} <i className={'icon icon-arrow-right'} />
            </FunctionalButton>
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentsTable;
