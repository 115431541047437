import React, { forwardRef } from 'react';
import { DropzoneOptions } from 'react-dropzone';

import { SignUploadFilesResponseDto, SignUploadRequestDto } from 'api/types/app';
import { FileUploadContextProvider } from './module/FileUploadContext';
import FileUploadComponent from './FileUploadComponent';

export interface FileUploadProps extends DropzoneOptions {
  name: string;
  onPresign: (request: SignUploadRequestDto) => Promise<SignUploadFilesResponseDto>;
  disableLoadSpinner?: boolean;
}

export type FileUploadHandle = {
  isFilesUploading: () => boolean;
};

export const getClassName = (
  isFocused: boolean,
  isDragAccept: boolean,
  isDragReject: boolean,
  isDisabled: boolean | undefined,
): string => {
  const baseStyle = 'file-upload-zone';
  const disabledStyle = `${baseStyle} disabled`;
  const focusedStyle = `${baseStyle} focused`;
  const acceptStyle = `${baseStyle} accept`;
  const rejectStyle = `${baseStyle} reject`;

  if (isDisabled) return disabledStyle;
  if (isFocused) return focusedStyle;
  if (isDragAccept) return acceptStyle;
  if (isDragReject) return rejectStyle;
  return baseStyle;
};

export const SHOWN_ERRORS = ['file-invalid-type'];

const FileUpload: React.ForwardRefRenderFunction<FileUploadHandle, FileUploadProps> = (
  props,
  fileUploadRef,
) => {
  return (
    <FileUploadContextProvider>
      <FileUploadComponent ref={fileUploadRef} {...props} />
    </FileUploadContextProvider>
  );
};

export default forwardRef(FileUpload);
