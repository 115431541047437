import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Col, ColProps, Row } from 'reactstrap';
import { ProjectHelper } from 'helpers/project';
import { ProjectContextState, withProject } from '../Scope/ProjectProvider';
import InvestmentDataBlock from './InvestmentDataBlock';
import { ProjectDeveloperProjectResponseDto } from 'api/types/project-developer/project';
import { InvestorProjectResponse } from 'api/types/investor/project';
import { GuestProjectResponse } from 'api/types/guest/project';
import { LoanRatioEnum } from 'helpers/enums/LoanRatioEnum';
import Tooltip from '../../Tooltip';
import { newRatingToOldRating } from 'scopes/investor/pages/ProjectList/components/ProjectCard/Rating';
import { LANDING_ROUTES } from 'routes';
import { LandingPageFaqCategoryEnum } from './Tooltips/Enum/LandingPageFaqCategoryEnum';
import { LandingPageFaqUniqueIdEnum } from './Tooltips/Enum/LandingPageFaqUniqueIdEnum';

type ProjectResponseType =
  | GuestProjectResponse
  | ProjectDeveloperProjectResponseDto
  | InvestorProjectResponse;

const ProjectInformation: React.FC<ProjectContextState> = ({ project }) => {
  const { tHtml, currentLanguage } = useTranslation();
  const colProps: ColProps = {
    lg: 3,
    md: 6,
  };

  const p: ProjectResponseType | undefined = project as ProjectResponseType;

  if (!p) return <></>;

  const getTitleKey = () => {
    if (p.loan_ratio === LoanRatioEnum.LTV) {
      return p.security_deposits_includes_vat === null
        ? 'projects.investment_information.ltv_title'
        : p.security_deposits_includes_vat
        ? 'projects.investment_information.ltv_title_vat'
        : 'projects.investment_information.ltv_title_no_vat';
    } else {
      return p.security_deposits_includes_vat === null
        ? 'projects.investment_information.ltc_title'
        : p.security_deposits_includes_vat
        ? 'projects.investment_information.ltc_title_vat'
        : 'projects.investment_information.ltc_title_no_vat';
    }
  };

  const getLinkToRatingFaq = () => {
    const params =
      '?category=' +
      LandingPageFaqCategoryEnum.FOR_THE_INVESTOR +
      '&uniqueId=' +
      LandingPageFaqUniqueIdEnum.WHAT_IS_PROJECT_RATING;

    return LANDING_ROUTES[currentLanguage].FAQ + params;
  };

  return (
    <div className={'project-section'}>
      <div className={'project-information'}>
        <h2>{tHtml('projects.investment_information')}</h2>
        <Row className={'mt-3'}>
          {p.required_amount && (
            <Col {...colProps}>
              <InvestmentDataBlock
                icon={'icon-money-stack'}
                title={tHtml('projects.investment_information.required_amount_title')}
                data={tHtml('common.money', { value: p.required_amount })}
              />
            </Col>
          )}
          {p.initial_rating && (
            <Col {...colProps}>
              <InvestmentDataBlock
                icon={'icon-graph-bar'}
                title={tHtml('projects.investment_information.initial_rating')}
                data={
                  <>
                    <Tooltip
                      id={'rating-' + p.pid}
                      text={tHtml('placeholder.initial_rating.tooltip', {
                        old_rating: newRatingToOldRating(p.initial_rating),
                      })}
                    />
                    <div id={'rating-' + p.pid}>{p.initial_rating}</div>
                  </>
                }
                infoLink={getLinkToRatingFaq()}
              />
            </Col>
          )}
          {p.loan_ratio_external && (
            <Col {...colProps}>
              <InvestmentDataBlock
                icon={'icon-timer'}
                title={tHtml(getTitleKey())}
                data={ProjectHelper.formatLoanRatio(p.loan_ratio_external, p.loan_ratio_max)}
              />
            </Col>
          )}
          {p.credit_duration && (
            <Col {...colProps}>
              <InvestmentDataBlock
                icon={'icon-calendar-alt'}
                title={tHtml('projects.investment_information.credit_duration_title')}
                data={tHtml(`common.month`, { value: p.credit_duration })}
              />
            </Col>
          )}
          {p.interest_frequency && (
            <Col {...colProps}>
              <InvestmentDataBlock
                icon={'icon-timer'}
                title={tHtml('projects.investment_information.interest_frequency_title')}
                data={tHtml('placeholder.interest_frequency.' + p.interest_frequency)}
              />
            </Col>
          )}
          {p.security_measures && (
            <Col {...colProps}>
              <InvestmentDataBlock
                icon={'icon-shield-check'}
                title={tHtml('projects.investment_information.security_measures_title')}
                data={tHtml('placeholder.security_measures.' + p.security_measures)}
              />
            </Col>
          )}
          {p.basic_interest && (
            <Col {...colProps}>
              <InvestmentDataBlock
                icon={'icon-timer'}
                title={tHtml('projects.investment_information.basic_interest_title')}
                data={ProjectHelper.formatInterestRate(p.basic_interest, p.max_bonus_interest)}
              />
            </Col>
          )}
          {p.investment_purpose && (
            <Col {...colProps}>
              <InvestmentDataBlock
                icon={'icon-building'}
                title={tHtml('projects.investment_information.investment_purpose_title')}
                data={tHtml('projects.investment_purposes.' + p.investment_purpose)}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default withProject(ProjectInformation);
