import React, { useCallback, useState } from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import { Trans } from 'react-i18next';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { CheckboxInput, PrimaryButton } from 'components/Formik';
import { BuyInvestmentRequestDto } from 'api/types/investor/secondary_market';
import { success } from 'services/toastr';
import Api from 'api';
import { RouteList } from 'routes';
import { InvestorExceptionEnum, SecondaryMarketExceptionEnum } from 'api/types/investor/exception';

interface Props extends ModalComponentProps {
  amount: number;
  secondaryMarketInvestmentId: string;
}

interface IFormValues extends BuyInvestmentRequestDto {
  agreement_all: boolean;
}

const BuyInvestmentModal: React.FC<Props> = ({ amount, secondaryMarketInvestmentId, ...props }) => {
  const { t, tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const history = useHistory();

  const [formRequest] = useState<IFormValues>({
    agreement_all: false,
    agreement_transfer_of_rights: false,
    agreement_risk: false,
    agreement_rules: false,
  });

  const schema = Yup.object().shape({
    agreement_all: Yup.boolean(),
    agreement_transfer_of_rights: Yup.boolean().required().isTrue(),
    agreement_risk: Yup.boolean().required().isTrue(),
    agreement_rules: Yup.boolean().required().isTrue(),
  });

  const onSubmit = useCallback(
    async (request: IFormValues) => {
      const buyRequest: BuyInvestmentRequestDto = {
        agreement_transfer_of_rights: request.agreement_transfer_of_rights,
        agreement_risk: request.agreement_risk,
        agreement_rules: request.agreement_rules,
      };

      await Api.investor.secondary_market
        .buyInvestment(buyRequest, secondaryMarketInvestmentId)
        .then(() => {
          success(tHtml('secondary_market.project.buy_investment.success'));
          hideModal();
          history.push(RouteList.INVESTOR.SECONDARY_MARKET.BUYER.OVERVIEW);
        })
        .catch((e) => {
          hideModal();
          switch (e.response?.exception) {
            case InvestorExceptionEnum.InsufficientFundsException:
              history.push(RouteList.SHARED.WALLET.OVERVIEW);
              break;
            case SecondaryMarketExceptionEnum.InvestmentSaleCanceledException:
            case SecondaryMarketExceptionEnum.InvestmentAlreadySoldException:
              history.push(RouteList.INVESTOR.SECONDARY_MARKET.BUYER.OVERVIEW);
              break;
          }
        });
    },
    [hideModal, history, secondaryMarketInvestmentId, tHtml],
  );

  const handleCheckAll = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    setFieldValue('agreement_transfer_of_rights', event.target.checked, false);
    setFieldValue('agreement_risk', event.target.checked, false);
    setFieldValue('agreement_rules', event.target.checked, false);
  };

  // TODO: informuoti, ar investicija nemoki
  return (
    <React.Fragment>
      <ModalComponent size={'lg'} {...props} contentClassName={'modal-investment'}>
        <div className={'p-3'}>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />} tag={'h1'}>
            {tHtml('secondary_market.project.buy_investment.title')}
          </ModalHeader>
          <ModalBody>
            <div className={'w-100'}>
              <div className={'d-flex align-items-center mb-3'}>
                <div className={'balance-description m-1 pe-1'}>
                  {tHtml('secondary_market.project.buy_investment.price')}
                </div>
                <div className={'text m-1'}>{tHtml('common.money', { value: amount })}</div>
              </div>
              <Formik
                initialValues={formRequest}
                validationSchema={schema}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validateOnBlur={true}
                validateOnChange={true}
              >
                {({ handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* TODO: links to rules */}
                    <div className={'form-input mb-3'}>
                      <CheckboxInput
                        name={'agreement_all'}
                        onChange={(event) => handleCheckAll(event, setFieldValue)}
                      />
                    </div>
                    <div className={'form-input mb-3'}>
                      <CheckboxInput
                        name={'agreement_transfer_of_rights'}
                        customLabel={
                          <div className={'ms-2'}>
                            <Trans
                              components={[
                                <a key={0} href="#" className={'link-secondary fw-bold'} />,
                              ]}
                            >
                              {t('placeholder.agreement_transfer_of_rights')}
                            </Trans>
                          </div>
                        }
                      />
                    </div>
                    <div className={'form-input mb-3'}>
                      <CheckboxInput name={'agreement_risk'} />
                    </div>
                    <div className={'form-input mb-3'}>
                      <CheckboxInput
                        name={'agreement_rules'}
                        customLabel={
                          <div className={'ms-2'}>
                            <Trans
                              components={[
                                <a key={0} href="#" className={'link-secondary fw-bold'} />,
                              ]}
                            >
                              {t('placeholder.agreement_rules')}
                            </Trans>
                          </div>
                        }
                      />
                    </div>
                    <PrimaryButton
                      className={'btn btn-primary btn-lg mt-3 fw-normal'}
                      disabled={isSubmitting}
                    >
                      {tHtml('common.buy')}
                    </PrimaryButton>
                  </Form>
                )}
              </Formik>
            </div>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default BuyInvestmentModal;
