import {
  InvestorDashboardStatisticsByNameResponseDto,
  InvestorDashboardStatisticsCommonResponseDto,
} from 'api/types/investor/dashboard';

export type InvestorStatisticsDataByKey = {
  [key: string]: InvestorDashboardStatisticsCommonResponseDto;
};

export type InvestorStatisticsDataByName = {
  [key: string]: InvestorDashboardStatisticsByNameResponseDto;
};

export type InvestorStatisticsChartData = (InvestorDashboardStatisticsCommonResponseDto & {
  name: string;
  share?: number;
})[];

export type InvestorStatisticsByNameChartData = (InvestorDashboardStatisticsByNameResponseDto & {
  name: string;
  share?: number;
})[];

export const transformToChartData = (data: InvestorStatisticsDataByKey) =>
  Object.keys(data).map((key) => {
    return { name: key, ...data[key] };
  });

export const calculateChartTotalAmount = (
  chartData: InvestorStatisticsChartData,
  amountColumn: string,
) =>
  chartData.reduce(
    (total, item) =>
      total + item[amountColumn as keyof InvestorDashboardStatisticsCommonResponseDto],
    0,
  );

export const getChartValue = (
  item: InvestorDashboardStatisticsCommonResponseDto,
  totalAmount: number,
  amountColumn: string,
) => {
  return (
    +(
      (item[amountColumn as keyof InvestorDashboardStatisticsCommonResponseDto] / totalAmount) *
      100
    ).toFixed(2) ?? 0
  );
};

export const getShareValue = (
  item: InvestorDashboardStatisticsCommonResponseDto,
  totalAmount: number,
  amountColumn: string,
) => {
  return (
    (item[amountColumn as keyof InvestorDashboardStatisticsCommonResponseDto] / totalAmount) *
      100 ?? 0
  );
};
