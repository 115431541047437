import { handleActions } from 'redux-actions';
import { ApplicationResponseDto } from 'api/types/project-developer/application';

export const UPDATE_APPLICATION = 'APPLICATION.UPDATE_APPLICATION';
export const INITIALIZE_APPLICATION = 'APPLICATION.INITIALIZE_APPLICATION';

export interface ApplicationState {
  id?: number | string | null;
  application?: ApplicationResponseDto;
}

export const initialState: ApplicationState = {
  id: undefined,
  application: undefined,
};

export const ApplicationReducer = handleActions<ApplicationState, any>(
  {
    [UPDATE_APPLICATION]: (
      state: ApplicationState,
      action: { payload: ApplicationResponseDto },
    ) => {
      const id = action.payload?.id;
      const application = action.payload;

      return {
        ...state,
        id,
        application,
      };
    },
    [INITIALIZE_APPLICATION]: (state: ApplicationState, action: { payload: string }) => {
      const application: ApplicationResponseDto = {
        id: null,
        project_name: null,
        type: action.payload,
        required_amount: null,
        minimal_credit_amount: null,
        credit_return: null,
        interest_frequency: null,
        credit_duration: null,
        source_of_funds: null,
        credit_purpose: null,
        refinancing: null,
        purchase: null,
        development: null,
        estimate: null,
        security_deposits: null,
        documents: null,
      };

      return {
        ...state,
        application: application,
      };
    },
  },
  initialState,
);
