import React, { useCallback } from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import { PrimaryButton } from 'components/Formik';
import { success } from 'services/toastr';
import { HighlightButton } from 'components/Button';
import Api from 'api';

interface Props extends ModalComponentProps {
  secondaryMarketInvestmentId: string;
  onCancel: () => void;
}

const CancelForSaleInvestmentModal: React.FC<Props> = ({
  secondaryMarketInvestmentId,
  onCancel,
  ...props
}) => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const onSubmit = useCallback(async () => {
    await Api.investor.secondary_market
      .cancelInvestment(secondaryMarketInvestmentId)
      .then(() => {
        success(tHtml('secondary_market.project.cancel_for_sale_investment.success'));
      })
      .finally(() => {
        onCancel();
        hideModal();
      });
  }, [hideModal, onCancel, secondaryMarketInvestmentId, tHtml]);

  return (
    <React.Fragment>
      <ModalComponent size={'md'} {...props} contentClassName={'modal-investment'}>
        <div className={'p-3'}>
          <ModalHeader
            toggle={hideModal}
            close={<ModalCloseButton />}
            tag={'h2'}
            className={'mt-2'}
          >
            {tHtml('secondary_market.project.cancel_for_sale_investment.title')}
          </ModalHeader>
          <ModalBody>
            <div className={'w-100'}>
              <div className={'d-flex justify-content-around mt-3'}>
                <HighlightButton
                  className={'btn btn-highlight w-25 fw-normal me-3'}
                  onClick={hideModal}
                >
                  {tHtml('common.no')}
                </HighlightButton>
                <PrimaryButton className={'btn btn-primary w-25 fw-normal'} onClick={onSubmit}>
                  {tHtml('common.yes')}
                </PrimaryButton>
              </div>
            </div>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default CancelForSaleInvestmentModal;
