import React, { useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Form } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';

import Api from 'api';
import { SocialNetworkType } from 'api/types/guest/social';
import { LoginUserRequestDto } from 'api/types/guest/authenticate';
import { RouteList } from 'routes';
import { setGlobalLoading } from 'modules/app/actions';
import TextInput from 'components/Formik/TextInput';
import PasswordInput from 'components/Formik/PasswordInput';
import PrimaryButton from 'components/Formik/PrimaryButton';
import { success, warning } from 'services/toastr';
import SocialButton from 'components/Formik/SocialButton';
import { SocialNetworkFactory } from 'services/social/SocialNetworkFactory';
import { useAuth } from 'services/useAuth';
import { GoogleTagManager } from 'GoogleTagManager';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import LoginTwoFactorStepModal from './Modals/LoginTwoFactorStepModal';
import useAppState from 'modules/appState';
import useDisplay from 'helpers/useDisplay';

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const { initialRoute } = useAppState();
  const { isApp } = useDisplay();

  const { tHtml } = useTranslation();
  const { login } = useAuth();
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    SocialNetworkFactory.setupAll();
    setGlobalLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSocialLogin = useCallback(
    async (socialNetworkType: SocialNetworkType) => {
      const sn = SocialNetworkFactory.get(socialNetworkType.toString());

      let externalResponse = undefined;

      try {
        externalResponse = await sn.signUp();
      } catch (e) {}

      if (!externalResponse) {
        return true;
      }

      try {
        const response = await Api.guest.social.socialLogin({
          social_network_type: socialNetworkType,
          token: externalResponse.token,
        });

        if (response.two_factor_auth_required && response.two_factor_auth_token) {
          showModal(
            <LoginTwoFactorStepModal history={history} token={response.two_factor_auth_token} />,
          );

          return;
        }

        if (response.access_token) {
          await login(
            response.access_token,
            response.permissions,
            response.force_redirect_to_after_login,
          );
          success(tHtml('authentication.login_success'));

          GoogleTagManager.push({
            event: 'user_logged_id',
          });
        } else {
          warning(tHtml('authentication.user_not_registered'));
          history.push(RouteList.GUEST.AUTHENTICATION.REGISTER);
        }
      } catch (e) {}
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, login, tHtml],
  );

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const [loginRequest] = useState<LoginUserRequestDto>({
    email: '',
    password: '',
  });

  const onSubmit = useCallback(
    async (request: LoginUserRequestDto, helpers: FormikHelpers<LoginUserRequestDto>) => {
      try {
        const response = await Api.guest.auth.login(request);

        if (response.two_factor_auth_required && response.two_factor_auth_token) {
          showModal(
            <LoginTwoFactorStepModal history={history} token={response.two_factor_auth_token} />,
          );

          return;
        }

        if (response.access_token) {
          await login(
            response.access_token,
            response.permissions,
            response.force_redirect_to_after_login ?? initialRoute,
          );
          success(tHtml('authentication.login_success'));
          GoogleTagManager.push({
            event: 'user_logged_id',
          });
        }
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, login, tHtml],
  );

  return (
    <React.Fragment>
      <div className={'top-title d-flex flex-row'}>
        <h4 className={'me-auto mb-0 align-self-center'}>{tHtml('common.login')}</h4>
        <Link className={'align-self-center'} to={RouteList.GUEST.AUTHENTICATION.REGISTER}>
          {tHtml('authentication.create_profile')}
        </Link>
      </div>
      <Formik initialValues={loginRequest} validationSchema={LoginSchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className={'clearfix'}>
              <TextInput name="email" />
            </div>
            <div className={'mb-4'}>
              <PasswordInput name="password" />
            </div>
            <div className="mt-6 mb-3">
              <PrimaryButton id={'login-button'} submitting={isSubmitting}>
                {tHtml('common.login')}
              </PrimaryButton>
            </div>
            <div className={'mb-3'}>
              <SocialButton
                type={'button'}
                icon={'icon-google'}
                onClick={() => onSocialLogin(SocialNetworkType.Google)}
              >
                {tHtml('authentication.login_google')}
              </SocialButton>
            </div>
            <div className={'mb-5'}>
              <SocialButton
                type={'button'}
                icon={'icon-facebook'}
                onClick={() => onSocialLogin(SocialNetworkType.Facebook)}
              >
                {tHtml('authentication.login_facebook')}
              </SocialButton>
            </div>
            <div className={'mb-4 d-flex justify-content-end'}>
              <Link to={RouteList.GUEST.AUTHENTICATION.RESET_PASSWORD}>
                {tHtml('authentication.forgot_password')}
              </Link>
            </div>

            {isApp && (
              <>
                <hr />
                <div
                  className={'mb-4 d-flex flex-column align-items-center justify-content-center'}
                >
                  <div> {tHtml('authentication.privacy_policy')}</div>
                  <div> {tHtml('authentication.general_terms_investors')}</div>
                  <div> {tHtml('authentication.general_terms_developers')}</div>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default withRouter(Login);
