import React from 'react';

interface DataBlockProps {
  title: string | JSX.Element;
  data: string | JSX.Element;
}

export const DataBlock: React.FC<DataBlockProps> = ({ title, data }) => {
  return (
    <div className={'data-block'}>
      <div className={'title'}>{title}</div>
      <div className={'text notranslate'}>{data}</div>
    </div>
  );
};

export default DataBlock;
