import React, { useState } from 'react';
import { NavLink, NavLinkProps, withRouter } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import useOnboarding from 'helpers/useOnboarding';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';
import { useAccountQuery } from 'api/queries/useAccountQuery';

const IconNavLink: React.FC<NavLinkProps> = (props) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <NavLink
      to={props.to}
      isActive={(match) => {
        setIsActive(!!match);
        return !!match;
      }}
    >
      {isActive && <i className={'input-icon icon-eye'} />} {props.children}
    </NavLink>
  );
};

const Sidebar: React.FC = () => {
  const { tHtml } = useTranslation();
  const onBoarding = useOnboarding();

  const { data: userProfile } = useUserProfileQuery();
  const { data } = useAccountQuery();
  return (
    <React.Fragment>
      <div className={'profile-sidebar w-100'}>
        <div className={'profile-info'}>
          {data?.main_user_avatar_url && (
            <img src={data?.main_user_avatar_url} alt="Avatar" className={'avatar'} />
          )}
          {userProfile?.user?.natural?.first_name
            ? tHtml('profile.greeting_with_name', { value: userProfile?.user?.natural?.first_name })
            : tHtml('profile.greeting')}
        </div>
        <div className={'profile-menu mt-5'}>
          <Nav vertical>
            <NavItem>
              <IconNavLink to={RouteList.USER.EDIT.CONTACT_INFO}>
                {tHtml('menu.contact_info')}
              </IconNavLink>
            </NavItem>
            {!onBoarding.isAccredited && (
              <NavItem>
                <IconNavLink to={RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION}>
                  {tHtml('menu.id_verification')}
                </IconNavLink>
              </NavItem>
            )}
            <NavItem>
              <IconNavLink to={RouteList.USER.EDIT.KNOW_YOUR_CUSTOMER}>
                {tHtml('menu.kyc')}
              </IconNavLink>
            </NavItem>
            <NavItem>
              <IconNavLink to={RouteList.INVESTOR.FINANCIAL_RISK_CALCULATOR.VIEW}>
                {tHtml('menu.financial_risk_calculator')}
              </IconNavLink>
            </NavItem>
            <NavItem>
              <IconNavLink to={RouteList.USER.EDIT.PASSWORD}>{tHtml('menu.password')}</IconNavLink>
            </NavItem>
            <NavItem>
              <IconNavLink to={RouteList.USER.EDIT.MARKETING_SETTINGS}>
                {tHtml('menu.marketing_settings')}
              </IconNavLink>
            </NavItem>
          </Nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Sidebar);
