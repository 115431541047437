import React from 'react';
import useUserType from 'helpers/useUserType';
import InvestorPageLayout from '../../investor/containers/PageLayout';
import DeveloperPageLayout from '../../developer/containers/PageLayout';

export const LayoutWrapper: React.FC = ({ children }) => {
  const userType = useUserType();

  if (userType.isInvestor()) {
    return <InvestorPageLayout>{children}</InvestorPageLayout>;
  }
  if (userType.isDeveloper()) {
    return <DeveloperPageLayout>{children}</DeveloperPageLayout>;
  }

  return <></>;
};

export default LayoutWrapper;
