import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { default as env } from 'env.json';
import { Subject } from 'rxjs';
import { HttpError } from './types/app';
import i18n from 'i18next';
import { ExceptionCta } from './types/investor/exception';
import { warning } from '../services/toastr';
import { AuthUtils } from '../services/useAuth/Auth.utils';

export type CustomAxiosError = AxiosError & {
  status: number | null;
};

type RequestConfig = AxiosRequestConfig & {
  ignoreException?: boolean;
};

export type RequestUrl = {
  url: string;
  protected: boolean;
};

type RequestInterface = <T = any, R = AxiosResponse<T>>(
  url: RequestUrl,
  data?: any,
  config?: RequestConfig | undefined,
) => Promise<R>;

class Http {
  axios: AxiosInstance;

  post: RequestInterface;

  get: RequestInterface;

  put: RequestInterface;

  delete: RequestInterface;

  baseUrl = env.BASE_URL;

  session: Subject<Date>;

  constructor() {
    this.session = new Subject<Date>();

    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Expose-Headers': 'Access-Control-*',
      'Access-Control-Allow-Headers':
        'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      'Access-Control-Allow-Origin': '*',
      Allow: 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
      crossdomain: true,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    this.axios = axios.create({
      withCredentials: true,
      headers: defaultHeaders,
    });

    this.post = (url, data, config) => {
      return this.axios.post(url.url, data, config as AxiosRequestConfig);
    };

    this.get = (url, config) => {
      return this.axios.get(url.url, config as AxiosRequestConfig);
    };

    this.put = (url, data, config) => {
      return this.axios.put(url.url, data, config as AxiosRequestConfig);
    };

    this.delete = (url, data, config) => {
      return this.axios.delete(url.url, config as AxiosRequestConfig);
    };

    this.axios.interceptors.request.use((request) => {
      request.headers['locale'] = i18n.resolvedLanguage ?? 'lt';
      return request;
    }, this.errorInterceptor);

    this.axios.interceptors.response.use((response: AxiosResponse) => {
      return response.data;
    }, this.errorInterceptor);
  }

  setBearer(accessToken: string | null): void {
    this.axios.defaults.headers.common = {
      ...this.axios.defaults.headers.common,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  removeBearer(): void {
    this.axios.defaults.headers.common = {
      ...this.axios.defaults.headers.common,
      Authorization: undefined,
    };
  }

  errorInterceptor = async (error: AxiosError): Promise<CustomAxiosError | any> => {
    const status = error.response?.status || null;
    const x = error.config as RequestConfig;

    const data =
      error?.response?.data instanceof Blob
        ? JSON.parse(await error.response.data.text())
        : error?.response?.data;

    error.message = data?.message || data?.error || i18n.t('common.error');

    const httpError: HttpError = {
      message: error.message,
      response: data || {},
      status: error?.response?.status || 500,
    };

    error.response = data || {};

    if (!x.ignoreException) {
      if (
        httpError.response.exception &&
        ExceptionCta.hasOwnProperty(httpError.response.exception as keyof typeof ExceptionCta)
      ) {
        warning(
          error.message,
          i18n.t('exception.' + httpError.response.exception + '.cta'),
          ExceptionCta[httpError.response.exception as keyof typeof ExceptionCta].CTA_ACTION,
        );
      } else {
        warning(error.message);
      }
    }

    if (status === 401) {
      AuthUtils.removeJwt();
    }

    return Promise.reject({
      ...error,
      status: status,
    });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  convertObjectToQueryString = (params: any): string => {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  };
}

export default Http;
