import React from 'react';
import * as Roles from 'helpers/auth/roles';
import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import { StepFormLayout } from 'scopes/investor/containers';
import { CreateCompany, CreateCompanyKYB, CreateCompanySuccess } from '.';

const CreateCompanyRoutes: any = () => [
  <AuthRoute
    path={RouteList.USER.CREATE_COMPANY.COMPANY}
    component={CreateCompany}
    layout={StepFormLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.USER.CREATE_COMPANY.COMPANY}
    exact
  />,
  <AuthRoute
    path={RouteList.USER.CREATE_COMPANY.KNOW_YOUR_BUSINESS}
    component={CreateCompanyKYB}
    layout={StepFormLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.USER.CREATE_COMPANY.KNOW_YOUR_BUSINESS}
    exact
  />,
  <AuthRoute
    path={RouteList.USER.CREATE_COMPANY.SUCCESS}
    component={CreateCompanySuccess}
    layout={StepFormLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.USER.CREATE_COMPANY.SUCCESS}
    exact
  />,
];

export default CreateCompanyRoutes;
