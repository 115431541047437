import React from 'react';
import useTranslation from 'helpers/useTranslation';

import PromotionBlock from 'components/PromotionBlock';

const SecondaryMarketPromotionBlock: React.FC = () => {
  const { tHtml } = useTranslation();

  return (
    <PromotionBlock>
      <p className={'promotion-block-body-text'}>{tHtml('secondary_market.promotion.block_1')}</p>
      <p className={'promotion-block-body-text'}>{tHtml('secondary_market.promotion.block_2')}</p>
    </PromotionBlock>
  );
};

export default SecondaryMarketPromotionBlock;
