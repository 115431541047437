import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import {
  registrationStepsCompany,
  Step,
} from 'scopes/investor/containers/RegistrationStepLayout/steps';
import { StepStatusEnum } from 'helpers/enums/StepStatusEnum';
import usePermission from 'helpers/usePermissions';
import useOnboarding from 'helpers/useOnboarding';
import Tooltip from 'components/Tooltip';

const RegistrationSteps: React.FC = () => {
  const { tHtml } = useTranslation();
  const [steps, setSteps] = useState<Array<Step>>([]);
  const { hasAll } = usePermission();

  const { currentStep } = useOnboarding();

  useEffect(() => {
    const tempSteps = [...registrationStepsCompany];

    const tempCurrentStep = currentStep ?? 1;

    for (let i = 0; i < tempSteps.length; i++) {
      if (tempSteps[i].status === StepStatusEnum.Hidden) continue;

      if (i < tempCurrentStep) {
        tempSteps[i].status = StepStatusEnum.Complete;
      } else if (i == currentStep) {
        tempSteps[i].status = StepStatusEnum.Active;
      } else {
        tempSteps[i].status = StepStatusEnum.Incomplete;
      }
    }

    setSteps(tempSteps);
  }, [currentStep, hasAll]);

  const getClassName = (status: StepStatusEnum) => {
    switch (status) {
      case StepStatusEnum.Complete:
        return 'completed';
      case StepStatusEnum.Active:
        return 'active';
      default:
        return '';
    }
  };

  return (
    <>
      <div className={'info'}>
        {tHtml('profile.account_registration.message.account_registration')}
        <strong>
          {(
            (currentStep ?? 1) *
            (100 / (registrationStepsCompany.length > 0 ? registrationStepsCompany.length : 1))
          ).toFixed()}
          % {tHtml('profile.account_registration.message.completed')}
        </strong>
      </div>
      <div className="steps">
        {steps.map((step, idx) => {
          if (step.status == StepStatusEnum.Hidden) return '';

          return (
            <div key={step.name} className={`step ${getClassName(step.status)}`}>
              <div className="number">{idx + 1}</div>
              <div className={'d-flex flex-column'}>
                <div className="text">{tHtml('authentication.step_' + step.name)}</div>
                {step.name === registrationStepsCompany[2].name && currentStep === 2 && (
                  <div className="position-relative mt-1">
                    <span style={{ color: 'grey' }}>{tHtml('common.waiting')}</span>
                    {step.tooltip && (
                      <>
                        <i
                          className={'icon icon-info position-absolute bottom-0 ms-1'}
                          id={`company-registration-${idx}`}
                        />
                        <Tooltip
                          id={`company-registration-${idx}`}
                          text={tHtml('authentication.' + step.tooltip)}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RegistrationSteps;
