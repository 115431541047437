import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { withRouter, Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import { RouteList } from 'routes';
import { PrimaryButton } from 'components/Button';
import StepFormHeader from 'containers/StepFormLayout/StepFormHeader';

const CreateCompanySuccess: React.FC = () => {
  const { tHtml } = useTranslation();

  return (
    <>
      <StepFormHeader showFinishLater={false} />
      <Container className={'main-content mx-auto px-2 px-sm-4 px-md-6'}>
        <Row className="col-12 col-md-10 col-lg-8 mx-auto pt-6">
          <h1 className="headline__success">
            <i className="icon-circle-check me-2" />
            <span>{tHtml('application.created_successfully')}</span>
          </h1>
          <div className="mt-4 mb-4">
            <Link to={RouteList.HOME}>
              <PrimaryButton className="btn btn-primary w-50">
                {tHtml('common.close')}
              </PrimaryButton>
            </Link>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(CreateCompanySuccess);
