import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { ModalBody, ModalHeader } from 'reactstrap';

import { ModalComponent } from 'components/Modal/ModalComponent';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import ModalCloseButton from 'components/Modal/ModalCloseButton';

const InvestedWithoutQuizModal = () => {
  const { tHtml } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  return (
    <React.Fragment>
      <ModalComponent size={'md'} contentClassName={'modal-investment'}>
        <div className={'p-3'}>
          <ModalHeader
            toggle={hideModal}
            close={<ModalCloseButton />}
            tag={'h2'}
            className={'mt-2'}
          >
            {tHtml('modal.invest_without_quiz.title')}
          </ModalHeader>
          <ModalBody>
            <div className={'w-100'}>
              <span className={'text-muted mt-3'}>
                {tHtml('modal.invest_without_quiz.description')}
              </span>
            </div>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default InvestedWithoutQuizModal;
