import React, { useRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { FunctionalButton } from 'components/Button';
import ProjectTop from './ProjectTop';
import ProjectInformation from 'components/Project/Authenticated/ProjectInformation';
import { ProjectProvider } from 'components/Project';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';
import { LANDING_ROUTES } from 'routes';

const ProjectWrapper: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();

  return (
    <ProjectProvider projectId={projectId} type={UserTypeEnum.GUEST}>
      <Project />
    </ProjectProvider>
  );
};

const Project: React.FC = () => {
  const { tHtml, currentLanguage } = useTranslation();
  const projectTopRef = useRef<HTMLDivElement>(null);
  return (
    <div className={'project'}>
      <div className={'mb-3'}>
        <a href={LANDING_ROUTES[currentLanguage].INVEST}>
          <FunctionalButton>{tHtml('common.back')}</FunctionalButton>
        </a>
      </div>
      <div ref={projectTopRef}>
        <ProjectTop />
      </div>
      <ProjectInformation />
      <div className={'project-section-with-side'}>
        <div className={'project-body'}>
          <h2>{tHtml('projects.project_information')}</h2>
          <p className={'word-break mb-3 text-center'}>
            {tHtml('projects.project_information_guest')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProjectWrapper);
