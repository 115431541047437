import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { InvestorKybForm, ProjectDeveloperKybForm } from 'components/KYB';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';

const CreateCompanyKYB: React.FC = () => {
  const { tHtml } = useTranslation();
  const { data: companyProfileData } = useUserCompanyProfileQuery();
  return (
    <div>
      <h1 className="mb-4 mb-md-6">{tHtml('placeholder.company.get_information')}</h1>
      {companyProfileData?.company.type === UserTypeEnum.INVESTOR && (
        <InvestorKybForm companyId={companyProfileData?.company.id} isEdit={true} />
      )}
      {companyProfileData?.company.type === UserTypeEnum.PROJECT_DEVELOPER && (
        <ProjectDeveloperKybForm companyId={companyProfileData?.company.id} isEdit={true} />
      )}
    </div>
  );
};

export default withRouter(CreateCompanyKYB);
