import React, { ReactElement } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Placement } from '@popperjs/core';

interface Props {
  id: string;
  text: string | ReactElement;
  placement?: Placement;
}

const Tooltip: React.FC<Props> = ({ id, text, placement = 'top' }) => (
  <UncontrolledTooltip
    placement={placement}
    target={id}
    trigger={'hover'}
    className={'custom-tooltip'}
  >
    {text}
  </UncontrolledTooltip>
);

export default Tooltip;
