import React from 'react';
import { sanitize } from 'dompurify';

interface Props {
  className?: string;
  content: string;
}

const SanitizedHtmlBlock: React.FC<Props> = ({ className, content }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitize(content),
      }}
      className={`html-block word-break ${className ?? ''}`}
    />
  );
};
export default SanitizedHtmlBlock;
