import React from 'react';

import DownloadButtonAuthorized from './DownloadButtonAuthorized';

interface Props {
  url: string;
  type: string;
}

const InlineFileDownloadLink: React.FC<Props> = ({ url, type }) => (
  <DownloadButtonAuthorized url={url} className={'btn btn-borderless pointer p-0'}>
    <div className="document-table-icon">
      {type} <i className="icon icon-download-file" />
    </div>
  </DownloadButtonAuthorized>
);

export default InlineFileDownloadLink;
