import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { enIN, lt } from 'date-fns/locale';
import { format } from 'date-fns';
import useTranslation from 'helpers/useTranslation';
import { ErrorMessage, useField } from 'formik';

import ScrollToOnError from '../../ScrollToOnError';
import DatePickerHeader from './DatePickerHeader';
import { DateFormatEnum } from 'helpers/enums/DateFormatEnum';

interface Props {
  name: string;
  label: string;
  startFromYear?: number;
  endYearRange?: number;
  hasFutureRange?: boolean;
  maxDate?: Date;
  disabled?: boolean;
}

const DatepickerInput: React.FC<Props> = ({
  name,
  label,
  startFromYear = new Date().getUTCFullYear(),
  endYearRange = 100,
  hasFutureRange = false,
  maxDate,
  disabled = false,
}) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [field, meta, helper] = useField(name);
  const { currentLanguage } = useTranslation();

  useEffect(() => {
    if (!field.value) {
      setStartDate(undefined);
    } else {
      setStartDate(new Date(field.value));
    }
  }, [field.value]);

  const isError = meta?.touched && !!meta?.error;

  return (
    <div className={`form-label-group in-border site-input ${isError ? 'is-invalid' : ''}`}>
      <ScrollToOnError name={name} isError={isError}>
        <div>
          <DatePicker
            name={name}
            id={name}
            value={field.value}
            selected={startDate}
            onChange={(date: Date) => {
              if (date) {
                setStartDate(date);
                const d = format(date, DateFormatEnum.Calendar);
                helper.setValue(d);
              }
            }}
            showMonthDropdown
            showYearDropdown
            disabled={disabled}
            fixedHeight
            maxDate={maxDate}
            dropdownMode="select"
            className={`datepicker-input form-control${isError ? ' is-invalid' : ''}`}
            dateFormat={DateFormatEnum.Calendar}
            locale={currentLanguage === 'lt' ? lt : enIN}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            renderCustomHeader={({
              date,
              increaseMonth,
              decreaseMonth,
              changeYear,
              changeMonth,
            }) => (
              <DatePickerHeader
                increaseMonth={increaseMonth}
                decreaseMonth={decreaseMonth}
                locale={currentLanguage}
                date={date}
                changeMonth={changeMonth}
                changeYear={changeYear}
                startFromYear={startFromYear}
                endYearRange={endYearRange}
                hasFutureRange={hasFutureRange}
              />
            )}
          ></DatePicker>
          <label className={`${field.value && 'datepicker-active-label'}`}>{label}</label>
        </div>
      </ScrollToOnError>
      {meta.error && (
        <div className={'invalid-feedback text-pre'} style={{ display: 'block' }}>
          <ErrorMessage
            component={'div'}
            name={name}
            render={(msg: string | string[]) => {
              if (typeof msg === 'string') {
                return msg;
              }

              return msg.join('\n');
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DatepickerInput;
