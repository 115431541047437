import React, { useCallback, useEffect, useState } from 'react';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import { ModalBody, ModalHeader } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import Api from 'api';
import HighlightButton from 'components/Button/HighlightButton';
import PrimaryButton from 'components/Button/PrimaryButton';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'routes';
import { success } from 'services/toastr';
import {
  InvestorProjectsWithTransferableInvestmentsInvestmentResponse,
  WantToTransferInvestmentsRequest,
} from 'api/types/investor/project';
import SelectInput, { SelectOption } from 'components/Formik/SelectInput';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAccountQuery } from 'api/queries';

interface Props extends ModalComponentProps {
  project_id: string;
  project_pid: string;
  project_name: string;
  refinancing_project_id: string;
  refinancing_project_pid: string;
  refinancing_project_name: string;
  investments: InvestorProjectsWithTransferableInvestmentsInvestmentResponse[];
  onTransfer: () => void;
}

const INITIAL_REQUEST: WantToTransferInvestmentsRequest = {
  investments: [],
};

const InvestorTransferInvestmentsModal: React.FC<Props> = ({
  project_id,
  project_name,
  refinancing_project_id,
  refinancing_project_name,
  investments,
  onTransfer,
  ...props
}) => {
  const { hideModal } = useGlobalModalContext();
  const { t } = useTranslation();
  const [options, setOptions] = useState<SelectOption[] | undefined>();
  const { invalidate } = useAccountQuery();
  const [wantToTransferInvestmentsRequest] =
    useState<WantToTransferInvestmentsRequest>(INITIAL_REQUEST);

  const handleAgreedToTransferInvestment = useCallback(
    async (request: WantToTransferInvestmentsRequest) => {
      await Api.investor.projects
        .wantToTransferInvestmentForProject(project_id, request)
        .then(() => {
          hideModal();
          success(t('modal.investor.has_transferable_investments.agreed'));
          onTransfer();
          invalidate();
        });
    },
    [project_id, hideModal, t, onTransfer, invalidate],
  );

  useEffect(() => {
    setOptions(() => {
      if (investments) {
        return [
          ...investments.map((i) => ({
            label: t('common.money', { value: i.amount }),
            value: i.id,
          })),
        ];
      }

      return undefined;
    });
  }, [investments, t]);

  const wantToTransferInvestmentSchema = Yup.object().shape({
    investments: Yup.array().of(Yup.string().required()),
  });

  return (
    <React.Fragment>
      <ModalComponent size={'lg'} {...props} className={'modal position-relative'}>
        <i className={'icon icon-close mb-4 cursor pointer'} onClick={hideModal} />
        <div className={'p-2 p-lg-4'}>
          <ModalHeader className={'d-flex justify-content-between align-items-center'}>
            <span>{t('modal.investor.has_transferable_investments.title')}</span>
          </ModalHeader>
          <ModalBody className={'modal-body'}>
            {t('modal.investor.has_transferable_investments.body', {
              project: project_name,
              refinancing_project: refinancing_project_name,
            })}
            <Formik
              initialValues={wantToTransferInvestmentsRequest}
              enableReinitialize={true}
              onSubmit={handleAgreedToTransferInvestment}
              validationSchema={wantToTransferInvestmentSchema}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <div className={'mt-4 w-50'}>
                    <SelectInput
                      name={'investments'}
                      options={options}
                      isMulti={true}
                      hideLabel={true}
                      placeholder={t(
                        'modal.investor.has_transferable_investments.pick_investments',
                      )}
                    />
                  </div>
                  <div className={'mt-4 d-lg-flex w-lg-50'}>
                    <div className={'d-block mb-lg-0 me-2'}>
                      <PrimaryButton
                        className={'site btn btn-primary w-100 mt-2'}
                        disabled={isSubmitting}
                      >
                        {t('modal.investor.has_transferable_investments.button.agree')}
                      </PrimaryButton>
                    </div>
                    <div>
                      <a
                        rel="noreferrer"
                        target={'_blank'}
                        href={generatePath(RouteList.GUEST.PROJECTS.VIEW, {
                          projectId: refinancing_project_id,
                        })}
                      >
                        <HighlightButton
                          className={'site btn btn-primary w-100 mt-2'}
                          type={'button'}
                        >
                          {t('modal.investor.has_transferable_investments.button.more_information')}
                        </HighlightButton>
                      </a>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default InvestorTransferInvestmentsModal;
