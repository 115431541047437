import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory, useParams, withRouter } from 'react-router-dom';
import { ProjectProvider } from 'components/Project';
import { Project } from '../../../investor/pages/Project/Project';
import Api from 'api';
import { useAuth } from 'services/useAuth';
import useAppState from 'modules/appState';

interface PreviewProjectParams {
  projectId: string;
  previewId: string;
}

const PreviewProject: React.FC<RouteComponentProps> = () => {
  const { isQuietImpersonated, setIsQuietImpersonated } = useAppState();
  const { projectId, previewId } = useParams<PreviewProjectParams>();
  const params = new URLSearchParams(window.location.search);
  const impersonateHash = params.get('impersonate') ?? '';
  const { loginQuietImpersonate } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!isQuietImpersonated && impersonateHash) {
      Api.guest.auth.quietImpersonate({ hash: impersonateHash }).then(async (response) => {
        await loginQuietImpersonate(response.access_token, response.permissions);
      });
    } else {
      setIsQuietImpersonated(true);
    }
    history.replace(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuietImpersonated, impersonateHash]);

  if (!isQuietImpersonated) {
    return <React.Fragment />;
  }

  return (
    <ProjectProvider type={'preview'} projectId={projectId} previewId={previewId}>
      <Project />
    </ProjectProvider>
  );
};

export default withRouter(PreviewProject);
