import styled from 'styled-components';

export const StyledCard = styled.div`
  background-color: var(--profitus-bg-color-2);
  border-radius: 0.5rem;
  padding: 1.6rem 1.8rem;
  flex: 1 1 auto;
`;

export const StyledCardLightPink = styled(StyledCard)`
  background-color: var(--profitus-card-light-pink-bg-color);
  border: var(--profitus-card-light-pink-border);
`;

export const StyledCardLightBlue = styled(StyledCard)`
  background-color: var(--profitus-card-light-blue-bg-color);
  border: var(--profitus-card-light-blue-border);
`;

export const StyledCardPink = styled(StyledCard)`
  background-color: var(--profitus-card-pink-bg-color);
  border: var(--profitus-card-pink-border);
`;
