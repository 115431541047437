import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import { UPDATE_APPLICATION } from './module/ApplicationReducer';
import { action } from 'typesafe-actions';
import * as Yup from 'yup';
import Api from 'api';
import { CheckboxInput, PrimaryButton } from 'components/Formik';
import transformErrors from 'helpers/formik';
import { ApplicationContext } from './module/ApplicationContext';
import { redirect } from 'scopes/guest/helpers/utils';
import { ApplicationPageProps } from './steps';
import { ProspectUserCompanyDocumentsDto } from 'api/types/guest/prospectApplication';
import Loader from 'components/Loader';
import FileUpload, { FileUploadHandle } from 'components/FileUpload/FileUpload';
import { ApplicationDocumentsCollectionEnum } from 'scopes/guest/helpers/enums/ApplicationDocumentsCollectionEnum';

const CompanyDocuments: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const { state, dispatch } = useContext(ApplicationContext);
  const [initialFormValues, setInitialFormValues] =
    useState<ProspectUserCompanyDocumentsDto | null>(null);

  useEffect(() => {
    if (state.application?.prospect_application.id !== applicationId) return;
    const companyInfo = state.application?.prospect_application.company_documents;
    if (companyInfo) {
      setInitialFormValues(companyInfo);
    } else {
      setInitialFormValues({
        has_confirmed_data_integrity: false,
        has_confirmed_business_relations: false,
        has_confirmed_fact_checking: false,
        financial_statement_documents: [],
        founding_documents: [],
        representative_proof_documents: [],
        non_criminal_record_documents: [],
        company_group_structure_documents: [],
        company_shareholder_documents: [],
      });
    }
  }, [t, applicationId, state]);

  const onSubmit = useCallback(
    async (
      request: ProspectUserCompanyDocumentsDto,
      helpers: FormikHelpers<ProspectUserCompanyDocumentsDto>,
    ) => {
      try {
        await Api.guest.prospect_application
          .storeApplicationCompanyDocuments(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  const schema = Yup.object().shape({
    has_confirmed_data_integrity: Yup.boolean().required().isTrue(),
    has_confirmed_business_relations: Yup.boolean().required().isTrue(),
    has_confirmed_fact_checking: Yup.boolean().required().isTrue(),
    financial_statement_documents: Yup.array().min(1).required(),
    founding_documents: Yup.array().min(1).required(),
    representative_proof_documents: Yup.array().optional(),
    non_criminal_record_documents: Yup.array().optional(),
    company_group_structure_documents: Yup.array().optional(),
    company_shareholder_documents: Yup.array().optional(),
  });

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          {initialFormValues ? (
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              initialValues={initialFormValues}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {(helpers) => (
                <Form onSubmit={helpers.handleSubmit}>
                  <FormGroup>
                    <h2 className={'mb-5'}>{tHtml('prospect_application.documents')}</h2>
                    <div className="mb-3">
                      <span>
                        {tHtml('prospect_application.documents.financial_statement_document')}
                      </span>
                      <FileUpload
                        ref={fileUploadRef}
                        name={'financial_statement_documents'}
                        onPresign={(request) =>
                          Api.guest.prospect_application.uploadProspectApplicationDocuments({
                            files: request,
                            collection:
                              ApplicationDocumentsCollectionEnum.COLLECTION_PROSPECT_COMPANY_FINANCIAL_STATEMENT_DOCUMENT,
                          })
                        }
                        disabled={helpers.isSubmitting}
                        disableLoadSpinner={true}
                      />
                      <div className={'invalid-feedback-visible ms-2'}>
                        <ErrorMessage name={'financial_statement_documents'} />
                      </div>
                    </div>
                    <hr />
                    <div className="mb-3">
                      <span>{tHtml('prospect_application.documents.founding_document')}</span>
                      <FileUpload
                        ref={fileUploadRef}
                        name={'founding_documents'}
                        onPresign={(request) =>
                          Api.guest.prospect_application.uploadProspectApplicationDocuments({
                            files: request,
                            collection:
                              ApplicationDocumentsCollectionEnum.COLLECTION_PROSPECT_COMPANY_FOUNDING_DOCUMENT,
                          })
                        }
                        disabled={helpers.isSubmitting}
                        disableLoadSpinner={true}
                      />
                      <div className={'invalid-feedback-visible ms-2'}>
                        <ErrorMessage name={'founding_documents'} />
                      </div>
                    </div>
                    <hr />
                    <div className="mb-3">
                      <span>
                        {tHtml('prospect_application.documents.representative_proof_document')}
                      </span>
                      <FileUpload
                        ref={fileUploadRef}
                        name={'representative_proof_documents'}
                        onPresign={(request) =>
                          Api.guest.prospect_application.uploadProspectApplicationDocuments({
                            files: request,
                            collection:
                              ApplicationDocumentsCollectionEnum.COLLECTION_PROSPECT_COMPANY_REPRESENTATIVE_PROOF_DOCUMENT,
                          })
                        }
                        disabled={helpers.isSubmitting}
                        disableLoadSpinner={true}
                      />
                      <div className={'invalid-feedback-visible ms-2'}>
                        <ErrorMessage name={'representative_proof_documents'} />
                      </div>
                    </div>
                    <hr />
                    <div className="mb-3">
                      <span>
                        {tHtml('prospect_application.documents.non_criminal_record_document')}
                      </span>
                      <FileUpload
                        ref={fileUploadRef}
                        name={'non_criminal_record_documents'}
                        onPresign={(request) =>
                          Api.guest.prospect_application.uploadProspectApplicationDocuments({
                            files: request,
                            collection:
                              ApplicationDocumentsCollectionEnum.COLLECTION_PROSPECT_COMPANY_NON_CRIMINAL_RECORD_DOCUMENT,
                          })
                        }
                        disabled={helpers.isSubmitting}
                        disableLoadSpinner={true}
                      />
                      <div className={'invalid-feedback-visible ms-2'}>
                        <ErrorMessage name={'non_criminal_record_documents'} />
                      </div>
                    </div>
                    <hr />
                    <div className="mb-3">
                      <span>
                        {tHtml('prospect_application.documents.company_group_structure_document')}
                      </span>
                      <FileUpload
                        ref={fileUploadRef}
                        name={'company_group_structure_documents'}
                        onPresign={(request) =>
                          Api.guest.prospect_application.uploadProspectApplicationDocuments({
                            files: request,
                            collection:
                              ApplicationDocumentsCollectionEnum.COLLECTION_PROSPECT_COMPANY_GROUP_STRUCTURE_DOCUMENT,
                          })
                        }
                        disabled={helpers.isSubmitting}
                        disableLoadSpinner={true}
                      />
                      <div className={'invalid-feedback-visible ms-2'}>
                        <ErrorMessage name={'company_group_structure_documents'} />
                      </div>
                    </div>
                    <hr />
                    <div className="mb-3">
                      <span>
                        {tHtml('prospect_application.documents.company_shareholder_document')}
                      </span>
                      <FileUpload
                        ref={fileUploadRef}
                        name={'company_shareholder_documents'}
                        onPresign={(request) =>
                          Api.guest.prospect_application.uploadProspectApplicationDocuments({
                            files: request,
                            collection:
                              ApplicationDocumentsCollectionEnum.COLLECTION_PROSPECT_COMPANY_SHAREHOLDER_DOCUMENT,
                          })
                        }
                        disabled={helpers.isSubmitting}
                        disableLoadSpinner={true}
                      />
                      <div className={'invalid-feedback-visible ms-2'}>
                        <ErrorMessage name={'company_shareholder_documents'} />
                      </div>
                    </div>
                    <hr />
                    <div className={'mb-4'}>
                      <CheckboxInput
                        name={'has_confirmed_data_integrity'}
                        placeholder={tHtml('placeholder.confirm_data_integrity')}
                      />
                    </div>
                    <div className={'mb-4'}>
                      <CheckboxInput
                        name={'has_confirmed_business_relations'}
                        placeholder={tHtml('placeholder.confirm_business_relations')}
                      />
                    </div>
                    <div className={'mb-4'}>
                      <CheckboxInput
                        name={'has_confirmed_fact_checking'}
                        placeholder={tHtml('placeholder.confirm_fact_checking')}
                      />
                    </div>
                  </FormGroup>
                  <hr />
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <PrimaryButton
                      className={'btn btn-grey w-25'}
                      type={'button'}
                      onClick={() => {
                        redirect(history, previousPage, applicationId);
                      }}
                    >
                      {tHtml('common.back')}
                    </PrimaryButton>
                    <PrimaryButton
                      className={'btn btn-primary w-25'}
                      submitting={helpers.isSubmitting}
                    >
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default withRouter(CompanyDocuments);
