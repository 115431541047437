import Table from 'components/Table/Table';
import React, { useCallback, useMemo, useState } from 'react';
import { Cell, Column } from 'react-table';
import useTranslation from 'helpers/useTranslation';

import Api from 'api';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { ProjectDeveloperBorrowerPayoutListResponseDto } from 'api/types/project-developer/payment';
import { PrimaryButton } from 'components/Button';
import DataBlock from '../DataBlock/DataBlock';
import {
  StyledUpcomingPaymentsTable,
  StyledUpcomingPaymentsTableSubComponent,
  StyledUpcomingPaymentsWrapper,
} from './UpcomingPayments.style';
import { StyledPaymentsHeader } from '../../Payments.style';
import { success } from 'services/toastr';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import ConfirmationModal from 'components/Modal/Modals/ConfirmationModal';
import { PayoutPaymentStatusEnum } from 'helpers/enums/PayoutPaymentStatusEnum';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';
import PayoutPaymentStatusBadge from 'components/Badge/PayoutPaymentStatusBadge';
import { useWalletQuery } from 'api/queries/useWalletQuery';

const UpcomingPayments: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [data, setData] = useState<PaginationData<ProjectDeveloperBorrowerPayoutListResponseDto>>();

  const { invalidate: invalidateWallet } = useWalletQuery();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.payment_date'),
        accessor: 'payment_date',
        disableSortBy: true,
        Cell: (cell: Cell<ProjectDeveloperBorrowerPayoutListResponseDto>) => {
          return <span>{tHtml('common.date', { date: cell.value })}</span>;
        },
      },
      {
        Header: t('table.pid'),
        accessor: 'pid',
        disableSortBy: true,
        Cell: (cell: Cell<ProjectDeveloperBorrowerPayoutListResponseDto>) => {
          return <span>{cell.value}</span>;
        },
      },
      {
        Header: t('table.title'),
        accessor: 'project_name',
        disableSortBy: true,
        Cell: (cell: Cell<ProjectDeveloperBorrowerPayoutListResponseDto>) => {
          const row = cell.row.original;
          return row.project_name ? (
            <ProjectTitleLink title={row.project_name} projectId={row.project_id} />
          ) : null;
        },
      },
      {
        Header: t('table.payment_purpose'),
        accessor: 'payment_purpose',
        disableSortBy: true,
        Cell: (cell: Cell<ProjectDeveloperBorrowerPayoutListResponseDto>) => {
          return <span>{tHtml('payments.borrower_payouts.type.' + cell.value)}</span>;
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'amount',
        disableSortBy: true,
        Cell: (cell: Cell<ProjectDeveloperBorrowerPayoutListResponseDto>) => {
          return <span>{tHtml('common.money', { value: cell.value })}</span>;
        },
      },
      {
        Header: t('table.payment_status'),
        accessor: 'payment_status',
        disableSortBy: true,
        Cell: (cell: Cell<ProjectDeveloperBorrowerPayoutListResponseDto>) => {
          return <PayoutPaymentStatusBadge status={cell.value} />;
        },
      },
      {
        disableSortBy: true,
        accessor: 'id',
        Cell: (cell: Cell<ProjectDeveloperBorrowerPayoutListResponseDto> | any) => {
          const row = cell.row.original;
          return (
            row.payment_status != PayoutPaymentStatusEnum.PAID && (
              <PrimaryButton
                onClick={() =>
                  showModal(
                    <ConfirmationModal
                      title={tHtml('payments.make_payment.confirmation')}
                      onSubmit={() =>
                        Api.projectDeveloper.payments.makePayment(row.id).then(() => {
                          success(tHtml('payments.make_payment.success'));
                          invalidateWallet();
                          cell.dispatch({ type: 'refreshData' });
                        })
                      }
                    />,
                  )
                }
              >
                {tHtml('payments.make_payment')}
              </PrimaryButton>
            )
          );
        },
      },
    ],
    [invalidateWallet, showModal, t, tHtml],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.projectDeveloper.payments
        .filterUpcomingPayments(request)
        .then((response) => setData(response));
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [t],
  );

  return (
    <StyledUpcomingPaymentsWrapper>
      <StyledPaymentsHeader>
        {tHtml('payments.borrower_payouts.upcoming_payments')}
      </StyledPaymentsHeader>
      <StyledUpcomingPaymentsTable>
        <Table
          columns={columns}
          data={data}
          onFetchData={fetchData}
          defaultLimit={5}
          mobile={true}
          searchable={false}
          sortable={false}
          renderRowSubComponent={(row) => {
            const originalRow = row.row.original;
            return (
              <StyledUpcomingPaymentsTableSubComponent>
                <DataBlock
                  title={t('payments.borrower_payouts.loan_repayment')}
                  value={t('common.money', { value: originalRow.loan_repayment ?? 0 })}
                  mobile={true}
                />
                <DataBlock
                  title={t('payments.borrower_payouts.basic_interest')}
                  value={t('common.money', { value: originalRow.basic_interest })}
                  mobile={true}
                />
                <DataBlock
                  title={t('payments.borrower_payouts.additional_interest')}
                  value={t('common.money', { value: originalRow.additional_interest })}
                  mobile={true}
                />
                <DataBlock
                  title={t('payments.borrower_payouts.other_fees')}
                  value={t('common.money', { value: originalRow.other_fees })}
                  mobile={true}
                />
              </StyledUpcomingPaymentsTableSubComponent>
            );
          }}
        />
      </StyledUpcomingPaymentsTable>
    </StyledUpcomingPaymentsWrapper>
  );
};

export default UpcomingPayments;
