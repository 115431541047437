import React, { useEffect, useState } from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';

import Api from 'api';
import { StyledPartialReturn } from './PartialReturn.style';
import { ProjectDeveloperPartialReturnProjectResponseDto } from 'api/types/project-developer/payment';
import ProjectInformation from './components/ProjectInformation/ProjectInformation';
import { RouteList } from 'routes';
import { FunctionalButton } from 'components/Button';
import useTranslation from 'helpers/useTranslation';
import PartialReturnForm from './components/PartialReturnForm/PartialReturnForm';
import SkeletonLoader from 'components/SkeletonLoader';
import PartialReturnSkeleton from './PartialReturnSkeleton';

const PartialReturn: React.FC = () => {
  const { t, tHtml } = useTranslation();

  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<ProjectDeveloperPartialReturnProjectResponseDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    Api.projectDeveloper.payments.fetchProject(projectId).then((r) => {
      setProject(r);
      setIsLoading(false);
    });
  }, [projectId, t]);

  return (
    <StyledPartialReturn>
      <Link to={RouteList.PROJECT_DEVELOPER.PAYMENTS.VIEW}>
        <FunctionalButton>
          <i className={'icon icon-arrow-left'} /> {tHtml('common.back')}
        </FunctionalButton>
      </Link>
      <SkeletonLoader loading={isLoading} customPlaceholder={<PartialReturnSkeleton />}>
        {project && (
          <>
            <ProjectInformation project={project} />
            <PartialReturnForm project={project} />
          </>
        )}
      </SkeletonLoader>
    </StyledPartialReturn>
  );
};

export default withRouter(PartialReturn);
