import React from 'react';
import useTranslation from 'helpers/useTranslation';

import { WalletOperationStatusEnum } from 'helpers/enums/wallet/WalletOperationStatusEnum';

type Props = {
  status: WalletOperationStatusEnum;
};

const WalletOperationStatusBadge: React.FC<Props> = ({ status }) => {
  const { tHtml } = useTranslation();

  const mapIcon = (status: WalletOperationStatusEnum) => {
    switch (status) {
      case WalletOperationStatusEnum.Success:
        return 'success';
      case WalletOperationStatusEnum.Pending:
      case WalletOperationStatusEnum.Reserved:
        return 'warning';
      case WalletOperationStatusEnum.Failed:
        return 'danger';
    }
  };

  return (
    <div className={`operation-status-${status} d-flex flex-nowrap align-items-center`}>
      <i className={`icon icon-status-${mapIcon(status)} me-2`} />
      {tHtml(`wallet.operation_status.${status}`)}
    </div>
  );
};

export default WalletOperationStatusBadge;
