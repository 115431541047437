import Client from '../client';
import Http from '../../http';
import { LoginAsCompanyResponseDto, LoginAsUserResponseDto } from '../../types/shared/login-as';
import { LoginUserResponseDto } from '../../types/guest/authenticate';

class SharedLoginAs extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      COMPANY_LOGIN: this.defaultUrl(`${this.http.baseUrl}/login/company/{companyId}`),
      USER_LOGIN: this.defaultUrl(`${this.http.baseUrl}/login/user/self`),
      ATTEMPT_LOGIN_AS: this.defaultUrl(`${this.http.baseUrl}/login/attempt/{id}`),
    };
  }

  companyLogin = (companyId: number | string): Promise<LoginAsCompanyResponseDto> => {
    const url = this.buildUrl(this.api.COMPANY_LOGIN, { companyId });

    return this.http.post(url);
  };

  userLogin = (): Promise<LoginAsUserResponseDto> => {
    return this.http.post(this.api.USER_LOGIN);
  };

  attemptLoginAs = (id: number | string): Promise<LoginUserResponseDto> => {
    const url = this.buildUrl(this.api.ATTEMPT_LOGIN_AS, { id });

    return this.http.post(url, {
      ignoreException: true,
    });
  };
}

export default SharedLoginAs;
