import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '../../helpers';
import { CommonEnum } from '../../helpers/enums/CommonEnum';
import { ThemeEnum } from '../../helpers/enums/ThemeEnum';
import { Link } from 'react-router-dom';
import LightLogo from 'assets/images/logo/logo-light.svg';
import AppLogo from 'assets/images/logo/app-logo.svg';
import AppSmallLogo from 'assets/images/logo/app-small-logo.svg';
import DarkLogo from 'assets/images/logo/logo-dark.svg';
import SmallLogoLight from 'assets/images/logo/small-logo-light.svg';
import SmallLogoDark from 'assets/images/logo/small-logo-dark.svg';
import { StyledLogo } from './ResponsiveLogo.style';
import { NavbarBrand } from 'reactstrap';
import { RouteList } from '../../routes';
import useDisplay from 'helpers/useDisplay';

const ResponsiveLogo: React.FC = () => {
  const [theme] = useLocalStorage<string>(CommonEnum.Theme, ThemeEnum.Light);
  const { isApp } = useDisplay();
  const [currentSmallLogo, setCurrentSmallLogo] = useState<any>();
  const [currentLogo, setCurrentLogo] = useState<any>();

  useEffect(() => {
    if (isApp) return;
    switch (theme) {
      case ThemeEnum.Light:
        setCurrentLogo(LightLogo);
        setCurrentSmallLogo(SmallLogoLight);
        break;
      case ThemeEnum.Dark:
        setCurrentLogo(DarkLogo);
        setCurrentSmallLogo(SmallLogoDark);
        break;
    }
  }, [theme, isApp]);

  return (
    <StyledLogo>
      <NavbarBrand to={RouteList.INVESTOR.PROJECTS.LIST} tag={Link}>
        <img
          src={isApp ? AppLogo : currentLogo}
          className={'d-none d-md-inline logo'}
          alt={'logo'}
        />
        <img
          src={isApp ? AppSmallLogo : currentSmallLogo}
          className={'d-md-none d-inline logo'}
          alt={'logo'}
        />
      </NavbarBrand>
    </StyledLogo>
  );
};

export default ResponsiveLogo;
