import React from 'react';
import { scroller } from 'react-scroll';

export interface ScrollspyLinkProps {
  children: JSX.Element | JSX.Element[];
  id: string;
  onClick?: () => void;
  delay?: number;
  className?: string;
}

const ScrollspyLinkDelayed: React.FC<ScrollspyLinkProps> = ({
  children,
  id,
  onClick,
  className,
  delay = 0,
}) => {
  const delayScroll = async () => {
    await new Promise((resolve) => setTimeout(resolve, delay));
  };

  return (
    <a
      onClick={() => {
        if (onClick) onClick();
        delayScroll().then(() => {
          scroller.scrollTo(id, {
            duration: 0,
            smooth: true,
          });
        });
      }}
      className={className}
    >
      {children}
    </a>
  );
};

export default ScrollspyLinkDelayed;
