import React, { ReactElement } from 'react';
import { TFunctionResult } from 'i18next';

import {
  StyledDataBlockBetween,
  StyledDataBlockBetweenContent,
  StyledDataBlockBetweenTitle,
  StyledDataBlockBetweenTitleIcon,
} from './DataBlockBetween.style';
import Tooltip from '../../Tooltip';

export interface Props {
  title: string | ReactElement;
  content: string | number | TFunctionResult | ReactElement | null;
  tooltipId?: string;
  tooltipText?: string | ReactElement;
}

const DataBlockBetween: React.FC<Props> = ({ title, content, tooltipId, tooltipText }) => {
  return (
    <StyledDataBlockBetween>
      <StyledDataBlockBetweenTitle>
        <span>{title}</span>
        {tooltipText && tooltipId && (
          <span>
            <StyledDataBlockBetweenTitleIcon
              className={'icon icon-info'}
              id={`tooltip-icon-${tooltipId}`}
            />
            <Tooltip id={`tooltip-icon-${tooltipId}`} text={tooltipText} />
          </span>
        )}
      </StyledDataBlockBetweenTitle>
      <StyledDataBlockBetweenContent>
        {content !== null ? content : '-'}
      </StyledDataBlockBetweenContent>
    </StyledDataBlockBetween>
  );
};

export default DataBlockBetween;
