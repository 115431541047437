import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { NotificationShortResponseDto } from 'api/types/app';
import timeAgo from 'helpers/timeAgo';
import { useNotifications } from './NotificationsProvider';
import { RouteList } from 'routes';
import SanitizedHtmlBlock from '../SanitizedHtmlBlock';
import Loader from 'components/Loader';
import useDisplay from 'helpers/useDisplay';

type Props = NotificationShortResponseDto;

const NotificationsSidebarItem: React.FC<Props> = ({ ...props }) => {
  const [hasBeenRead, setHasBeenRead] = useState<boolean | null>(props.has_been_read);
  const [hasBeenSelected, setHasBeenSelected] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);

  const { selectedNotification, isLoading } = useNotifications();
  const { isMobile } = useDisplay();

  useEffect(() => {
    setHasBeenRead(props.has_been_read);
  }, [props.has_been_read]);

  useEffect(() => {
    if (selectedNotification?.id === props.id) {
      if (clicked && isMobile && selectedNotification) {
        setClicked(false);
      }

      setHasBeenSelected(true);
      setHasBeenRead(true);
    } else {
      setHasBeenSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, selectedNotification, clicked]);

  const onNotificationClick = useCallback(() => {
    setClicked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, selectedNotification]);

  if (isMobile) {
    return (
      <div
        className={`notifications-sidebar-item ${hasBeenRead ? 'text-muted' : 'unread'} ${
          hasBeenSelected ? 'notifications-sidebar-item-active' : ''
        } ${isLoading && clicked && isMobile ? 'notifications-sidebar-item-loading' : ''}`}
      >
        <Link
          to={{
            pathname: RouteList.NOTIFICATIONS.LIST,
            hash: props.id,
          }}
          onClick={onNotificationClick}
        >
          <h4 className={'title'}>{props.title}</h4>
          <p className="mb-2 d-flex align-items-center">
            <i className={'icon icon-clock align-middle'} />
            {timeAgo(props.created_at)}
          </p>
        </Link>
        {isLoading && clicked ? (
          <Loader />
        ) : (
          <SanitizedHtmlBlock
            content={
              hasBeenSelected && selectedNotification ? selectedNotification.body : props.body ?? ''
            }
          />
        )}
      </div>
    );
  }

  return (
    <Link
      className={`notifications-sidebar-item ${hasBeenRead ? 'text-muted' : 'unread'} ${
        hasBeenSelected ? 'notifications-sidebar-item-active' : ''
      } ${isLoading && clicked && isMobile ? 'notifications-sidebar-item-loading' : ''}`}
      to={{
        pathname: RouteList.NOTIFICATIONS.LIST,
        hash: props.id,
      }}
      onClick={onNotificationClick}
    >
      <h4 className={'title'}>{props.title}</h4>
      <p className="mb-2 d-flex align-items-center">
        <i className={'icon icon-clock align-middle'} />
        {timeAgo(props.created_at)}
      </p>
      <SanitizedHtmlBlock content={props.body ?? ''} />
    </Link>
  );
};

export default NotificationsSidebarItem;
