import React from 'react';

import AuthRoute from 'routes/AuthRoute';
import { RouteList } from 'routes';
import PageLayout from 'scopes/investor/containers/PageLayout';
import * as Roles from 'helpers/auth/roles';
import Notifications from './Notifications';

const NotificationsRoutes: any = () => [
  <AuthRoute
    path={RouteList.NOTIFICATIONS.LIST}
    component={Notifications}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.NOTIFICATIONS.LIST}
    exact
  />,
];

export default NotificationsRoutes;
