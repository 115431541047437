import { defaultOptions, UseQueryOptionsObject } from '../queries.utils';
import { useMakeQuery } from '../useMakeQuery';
import { queries } from '../index';
import Api from '../../index';
import { NotificationShortListResponseDto } from '../../types/app';
import useUserType from '../../../helpers/useUserType';

const useNotificationsQuery = (
  options?: UseQueryOptionsObject<NotificationShortListResponseDto>,
) => {
  const { isGuest } = useUserType();

  return useMakeQuery<NotificationShortListResponseDto>(
    [queries.notifications],
    () => Api.notifications.fetchShortNotifications(),
    {
      staleTime: 5 * 1000,
      enabled: !isGuest(),
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useNotificationsQuery };
