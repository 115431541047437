import React from 'react';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';

type Props = SecondaryMarketInvestmentContextState;

const ProjectTopTitle: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  if (!secondaryMarketInvestment) return null;

  return (
    <>
      <div className={'project-title-block mb-3'}>
        <h2 className={'project-title'}>{secondaryMarketInvestment.project_name}</h2>
        <h2 className={'project-id-badge'}>{secondaryMarketInvestment.project_id}</h2>
      </div>
    </>
  );
};

export default withSecondaryMarketInvestment(ProjectTopTitle);
