import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import Tooltip from 'components/Tooltip';
import useDisplay from 'helpers/useDisplay';
import RefundModal from 'components/Modal/Modals/RefundModal';

interface Props {
  investmentId: string;
  is_project_funded: boolean;
  onSuccess: () => Promise<void>;
}

const RefundInvestment: React.FC<Props> = ({ investmentId, is_project_funded, onSuccess }) => {
  const { t, tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();

  const { isMobile } = useDisplay();

  return isMobile ? (
    <button
      onClick={() =>
        showModal(
          <RefundModal
            title={tHtml('investments.refund_modal.confirmation')}
            investmentId={investmentId}
            is_project_funded={is_project_funded}
            onSuccess={onSuccess}
          />,
        )
      }
      className="btn btn-functional mt-4"
    >
      {t('investments.refund_investment')}
    </button>
  ) : (
    <>
      <span
        id={'t-' + investmentId}
        onClick={() =>
          showModal(
            <RefundModal
              title={tHtml('investments.refund_modal.confirmation')}
              investmentId={investmentId}
              onSuccess={onSuccess}
              is_project_funded={is_project_funded}
            />,
          )
        }
      >
        <i className="icon icon-delete-document cursor" />
      </span>

      <div className={'hidden-sm hidden-xs'}>
        <Tooltip id={'t-' + investmentId} text={t('investments.refund_investment')} />
      </div>
    </>
  );
};

export default RefundInvestment;
