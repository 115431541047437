import React, { useCallback, useMemo, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Column, Cell } from 'react-table';
import Api from 'api';
import { PaymentsResponseDto } from 'api/types/investor/dashboard';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';

import { PaginationData, PaginationDataFilter } from 'api/types';
import Table, { AmountFilter, DateFilter } from 'components/Table/Table';
import { RouteList } from 'routes';
import { FunctionalButton } from 'components/Button';
import PayoutPaymentStatusBadge from 'components/Badge/PayoutPaymentStatusBadge';

const INITIAL_SORT_ORDER = [
  {
    id: 'payment_date',
    desc: false,
  },
];

const LatePayments: React.FC = () => {
  const { t, currentLanguage, tHtml } = useTranslation();
  const [data, setData] = useState<PaginationData<PaymentsResponseDto>>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.pid'),
        accessor: 'pid',
        sortType: 'string',
        Cell: (cell: Cell<PaymentsResponseDto>) => {
          return (
            <ProjectTitleLink
              title={cell.row.original.project_name ?? ''}
              projectId={cell.row.original.project_id}
            />
          );
        },
      },
      {
        Header: t('table.date'),
        accessor: 'payment_date',
        sortType: 'datetime',
        Filter: DateFilter,
        Cell: (cell: Cell<PaymentsResponseDto>) => (
          <span>{tHtml('common.date', { date: cell.value })}</span>
        ),
      },
      {
        Header: t('table.investor_incoming_payments_type'),
        accessor: 'type',
        sortType: 'string',
        Cell: (cell: Cell<PaymentsResponseDto>) => {
          return <span>{tHtml(`payments.borrower_payouts.type.${cell.value}`)}</span>;
        },
      },
      {
        Header: t('table.investor_incoming_payments_status'),
        accessor: 'status',
        sortType: 'string',
        disableSortBy: true,
        Cell: (cell: Cell<PaymentsResponseDto>) => {
          return <PayoutPaymentStatusBadge status={cell.value} />;
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'amount',
        sortType: 'number',
        Filter: AmountFilter,
        filterProps: {
          start: 0,
          ignoreLimits: true,
        },
        Cell: (cell: Cell<PaymentsResponseDto>) => {
          return <span>{tHtml('common.money', { value: cell.value })}</span>;
        },
      },
    ],
    [t, tHtml],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.investor.dashboard
        .fetchLatePayments(request)
        .then((response) => setData(response));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage],
  );

  return (
    <div className={'page-layout wallet'}>
      <div className={'mb-5 d-flex justify-content-start'}>
        <Link to={RouteList.DASHBOARD}>
          <FunctionalButton>
            <i className={'icon icon-arrow-left'} /> {tHtml('common.dashboard')}
          </FunctionalButton>
        </Link>
      </div>

      <div>
        <h2>{tHtml('dashboard.late_payments')}</h2>
        <div>
          <Table
            columns={columns}
            data={data}
            onFetchData={fetchData}
            showPageSizes={true}
            searchable={true}
            defaultLimit={20}
            initialSortBy={INITIAL_SORT_ORDER}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(LatePayments);
