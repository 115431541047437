import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  TFunction,
  Trans,
  useTranslation as i18nUseTranslation,
  UseTranslationResponse,
} from 'react-i18next';

type UseTranslationResponseExtended = UseTranslationResponse<string> & {
  tHtml: (key: any, options?: any) => ReactElement;
  currentLanguage: string;
};

const useTranslation = (): UseTranslationResponseExtended => {
  const translation = i18nUseTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(translation.i18n.resolvedLanguage ?? 'lt');

  useEffect(() => {
    if (!translation.i18n.resolvedLanguage) {
      setCurrentLanguage('lt');
    } else {
      setCurrentLanguage(translation.i18n.resolvedLanguage);
    }
  }, [translation.i18n.resolvedLanguage]);

  const tHtml: TFunction<any> = useCallback(
    (key: any, options?: any) => (
      <Trans components={{ i: <i />, b: <strong />, u: <u />, a: <a />, span: <span /> }}>
        {translation.t(key, options)}
      </Trans>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translation.t],
  );

  return { ...translation, tHtml, currentLanguage } as UseTranslationResponseExtended;
};

export default useTranslation;
