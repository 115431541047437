import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { Cell, Column, Row as TableRow, RowPropGetter } from 'react-table';

import { PieChart } from 'components/PieChart';
import { PaginationData, PaginationDataFilter } from 'api/types';
import Table from 'components/Table/Table';
import { MyActiveInvestmentResponseDto } from 'api/types/investor/dashboard';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';
import Api from 'api';
import Loader from 'components/Loader';
import DataNotFoundBlock from 'components/DataNotFoundBlock';
import { useStatisticQuery } from 'api/queries/dashboard';

const ActiveInvestmentsMobileTable: React.FC = () => {
  const { t, tHtml, currentLanguage } = useTranslation();
  const [tableData, setTableData] = useState<PaginationData<MyActiveInvestmentResponseDto>>();
  const [pieChart, setPieChart] = useState<any[]>([]);
  const [index, setIndex] = useState<number>(-1);

  const { data: activeInvestmentData, isFetching } = useStatisticQuery(
    ['activeInvestments', currentLanguage],
    () => Api.investor.dashboard.fetchActiveInvestments(),
  );

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.pid'),
        accessor: 'name',
        sortType: 'string',
        disableSortBy: true,
        Cell: (cell: Cell<MyActiveInvestmentResponseDto>) => {
          return <ProjectTitleLink title={cell.value} projectId={cell.row.original.project_id} />;
        },
      },
      {
        Header: t('table.share'),
        accessor: 'share',
        sortType: 'string',
        disableSortBy: true,
        Cell: (cell: Cell<MyActiveInvestmentResponseDto>) => {
          const number = parseFloat(cell.row.original.share.toFixed(2));
          return <span>{number === 0 ? '>0' : number}%</span>;
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'remaining_amount',
        sortType: 'string',
        disableSortBy: true,
        Cell: (cell: Cell<MyActiveInvestmentResponseDto>) => {
          return (
            <span>{tHtml('common.money', { value: cell.row.original.remaining_amount })}</span>
          );
        },
      },
    ],
    [t, tHtml],
  );

  const getRowProps = (
    row: TableRow<Record<string, unknown>>,
  ): RowPropGetter<Record<string, unknown>> => {
    const result: Record<string, unknown> = {
      onMouseEnter: () => {
        setIndex(row.original.i as number);
      },
    };

    if (row.original?.isSelected) {
      result['className'] = 'active-row';
    }

    return result;
  };

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      const data = activeInvestmentData?.investments;
      const { page = 1, limit = 5 } = request || {};

      const startIdx = (page - 1) * limit;
      const endIdx = startIdx + limit;

      if (!data) {
        return;
      }
      const slicedData = data.data.slice(startIdx, endIdx);

      if (request != undefined && request.search != undefined) {
        data.data = data.data.filter((item: Record<string, any>) => {
          return item.name
            .toLocaleLowerCase()
            .includes(String(request.search?.toLocaleLowerCase()));
        });
      }

      data.data = data.data.map((item: any, i: number) => ({
        ...item,
        i,
        isSelected: false,
      }));

      setPieChart(
        data.data.map((item: any) => {
          return {
            name: item['pid'],
            value: parseFloat(item['remaining_amount']),
          };
        }),
      );

      setTableData({
        data: slicedData,
        meta: {
          current_page: page,
          from: startIdx + 1,
          last_page: Math.ceil(data.data.length / limit),
          to: Math.min(endIdx, data.data.length),
          total: data.data.length,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeInvestmentData, currentLanguage],
  );

  const onChange = (index: number) => {
    if (tableData?.data) {
      tableData.data = tableData.data.map((item: MyActiveInvestmentResponseDto, itemIndex) => ({
        ...item,
        isSelected: index == itemIndex,
      }));

      setTableData({
        ...tableData,
      });
    }
  };

  return isFetching ? (
    <div className="loader-center">
      <Loader />
    </div>
  ) : (
    <>
      {activeInvestmentData?.investments?.data.length === 0 ? (
        <>
          <div className="mobile-table-card text-center mt-2">
            <DataNotFoundBlock label={tHtml('investments.no_active_investments')} />
          </div>
        </>
      ) : (
        <Row>
          <Col xs={12} sm={12} xl={5}>
            <PieChart data={pieChart} onChange={onChange} indexState={{ index, setIndex }} />
          </Col>
          <Col xs={12} sm={12} xl={7}>
            <Table
              columns={columns}
              data={tableData}
              onFetchData={fetchData}
              tableRowProps={getRowProps}
              mobile={true}
              minimizeCard={true}
              sortable={false}
              showPagination={true}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default ActiveInvestmentsMobileTable;
