import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Col, ColProps, Row } from 'reactstrap';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';
import DataBlockStacked from 'scopes/investor/pages/SecondaryMarket/components/DataBlockStacked';
import SanitizedHtmlBlock from 'components/SanitizedHtmlBlock';

type Props = SecondaryMarketInvestmentContextState;

const TabProjectInformation: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  const { tHtml } = useTranslation();

  if (!secondaryMarketInvestment?.project_information) return null;

  const colProps: ColProps = {
    lg: 3,
    md: 6,
    sm: 6,
    xs: 12,
  };

  return (
    <>
      <section>
        <Row className={'mb-4 gy-3'}>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.project_information.project_name')}
              content={secondaryMarketInvestment.project_information.project_name}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.project_information.project_address')}
              content={secondaryMarketInvestment.project_information.project_address}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.project_information.area_of_object')}
              content={tHtml('common.area', {
                value: secondaryMarketInvestment.project_information.area_of_object,
              })}
            />
          </Col>
        </Row>
        <SanitizedHtmlBlock
          content={secondaryMarketInvestment.project_information.project_description ?? ''}
        />
      </section>
    </>
  );
};

export default withSecondaryMarketInvestment(TabProjectInformation);
