import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import SanitizedHtmlBlock from 'components/SanitizedHtmlBlock';
import { FunctionalButton } from 'components/Button';
import useDisplay from 'helpers/useDisplay';

const ReadMore: React.FC = (props: any) => {
  const { isMobile } = useDisplay();
  const textLength = isMobile ? 150 : 550;
  const { tHtml } = useTranslation();
  const text = props.children.props.content;
  const textLimitReached = text.length > textLength;
  const [isReadMore, setIsReadMore] = useState(textLimitReached);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div className={'read-more'}>
      {isReadMore ? (
        <SanitizedHtmlBlock content={text.slice(0, textLength) + `...`} />
      ) : (
        <SanitizedHtmlBlock content={text} />
      )}
      {textLimitReached && (
        <div className={'surrounded-item'}>
          <div className={'line'} />
          <div className={'item'}>
            <FunctionalButton onClick={toggleReadMore}>
              {isReadMore ? tHtml('common.more') : tHtml('common.less')}
            </FunctionalButton>
          </div>
          <div className={'line'} />
        </div>
      )}
    </div>
  );
};

export default ReadMore;
