import React from 'react';
import { ReactSortable } from 'react-sortablejs';

import { useAutoInvestmentData } from '../AutoInvestmentProvider';
import Loader from 'components/Loader';
import StrategyListItem from './StrategyListItem';
import useTranslation from 'helpers/useTranslation';
import { InvestorAutoStrategy } from 'api/types/investor/investment';
import Api from 'api';
import { success } from 'services/toastr';
import NewStrategyListItem from './NewStrategyListItem';
import DataNotFoundBlock from 'components/DataNotFoundBlock';
import Tooltip from 'components/Tooltip';

const scroll = () => {
  const section = document.getElementById('auto-invest-configs');
  if (section) {
    section.scrollIntoView({ block: 'center' });
  } else {
    console.error('Failed to find the element with ID "auto-invest-configs".');
  }
};

export const MAX_STRATEGIES = 5;

export const StrategyList = () => {
  const { autoInvestmentData, autoInvestmentConfig, newStrategy, setNewStrategy } =
    useAutoInvestmentData();
  const [strategies, setStrategies] = React.useState<InvestorAutoStrategy[]>([]);
  const [orderLoading, setOrderLoading] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  const { t, tHtml } = useTranslation();

  React.useEffect(() => {
    if (autoInvestmentData) {
      setStrategies(autoInvestmentData);
    }
  }, [autoInvestmentData]);

  React.useEffect(() => {
    if (clicked) {
      scroll();
      setClicked(false);
    }
  }, [clicked]);

  const updateOrder = React.useCallback(
    async (request) => {
      setOrderLoading(true);
      await Api.investor.investments.updateAutoInvestmentStrategyOrder(request);
      success(t('common.updated_successfully'));
      setOrderLoading(false);
    },
    [t],
  );

  const strategyLimitReached = autoInvestmentData?.length === MAX_STRATEGIES;

  if (!autoInvestmentConfig || !autoInvestmentData) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="d-flex gap-3 justify-content-between mt-4">
        <h2 className={'d-flex gap-3 align-items-center mt-3'}>
          {tHtml('investments.auto_investment.my_strategies')}
          {orderLoading && <Loader />}
        </h2>
        <div>
          <button
            className={'site btn btn-primary'}
            onClick={() => {
              setClicked(true);
            }}
            disabled={strategyLimitReached || newStrategy !== undefined}
          >
            {t(`investments.auto_investment.create_new_strategy`)}
          </button>
          {(strategyLimitReached || newStrategy !== undefined) && (
            <>
              <i className={'icon icon-info'} id={`auto-invest-create-button`} />
              <Tooltip
                id={`auto-invest-create-button`}
                text={
                  strategyLimitReached
                    ? t(`investments.auto_investment.max_strategies_reached`)
                    : t(`investments.auto_investment.tooltip.strategy_not_saved`)
                }
              />
            </>
          )}
        </div>
      </div>
      <ReactSortable
        list={strategies}
        disabled={orderLoading}
        setList={setStrategies}
        handle="#sortable-handle"
        onEnd={() => {
          const request = { strategy_ids: strategies.map((strategy) => strategy.id) };
          updateOrder(request);
        }}
      >
        {strategies.map((strategy) => (
          <div key={strategy.id}>
            <StrategyListItem strategy={strategy} autoInvestmentConfig={autoInvestmentConfig} />
          </div>
        ))}
      </ReactSortable>
      {strategies.length === 0 && (
        <DataNotFoundBlock label={tHtml('investments.auto_investment.strategies_not_found')} />
      )}

      <div id="new-strategies">
        {newStrategy && (
          <NewStrategyListItem
            selectedPackage={newStrategy}
            autoInvestmentConfig={autoInvestmentConfig}
            onClick={() => setNewStrategy(undefined)}
          />
        )}
      </div>
    </div>
  );
};
