import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Link, withRouter } from 'react-router-dom';

import { RouteList } from 'routes';
import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';
import NotFilledProfileSection from 'components/Profile/NotFilledProfileSection';
import { FunctionalButton } from 'components/Button';
import useProfileMenuState from 'scopes/user/pages/Profile/ProfileMenuState';
import { useAccountQuery } from 'api/queries';
import { InvestorExperienceLevelTypeEnum } from 'helpers/enums/InvestorExperienceLevelTypeEnum';
import { MultipleButtonDiv } from './ProfileInvestorQuiz.style';

const ProfileInvestorQuiz: React.FC = () => {
  const { tHtml } = useTranslation();

  const { toggleProfileMenu } = useProfileMenuState();
  const { data: accountData } = useAccountQuery();

  const returnDescription = () => {
    return accountData?.investor_level === InvestorExperienceLevelTypeEnum.EXPERIENCED
      ? tHtml('menu.experienced_investor_description')
      : tHtml('menu.novice_investor_description');
  };

  if (!accountData?.has_investor_quiz_filled && !accountData?.has_investor_quiz_draft_active) {
    return (
      <NotFilledProfileSection
        title={tHtml('menu.investor_quiz')}
        buttonLink={RouteList.INVESTOR.QUIZ.VIEW}
        description={returnDescription()}
      />
    );
  }

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <div
          className={'d-flex w-100 justify-content-between align-items-center'}
          onClick={() => toggleProfileMenu(UserMenuEnum.InvestorQuiz)}
        >
          <div>
            <h2 className={'pointer me-2 m-0'}>{tHtml('menu.investor_quiz')}</h2>
            <span>{returnDescription()}</span>
          </div>
          <MultipleButtonDiv>
            <Link
              to={{
                pathname: RouteList.INVESTOR.QUIZ.VIEW,
                state: { isQuizNew: false },
              }}
            >
              <FunctionalButton
                className={'site btn btn-functional edit-button'}
                icon={'icon-pencil-edit'}
              >
                {tHtml('common.edit')}
              </FunctionalButton>
            </Link>
            {accountData?.has_investor_quiz_draft_active && (
              <>
                {tHtml('authentication.or')}
                <Link
                  to={{
                    pathname: RouteList.INVESTOR.QUIZ.VIEW,
                    state: { isQuizNew: true },
                  }}
                >
                  <FunctionalButton
                    className={'site btn btn-functional edit-button'}
                    icon={'icon-pencil-edit'}
                  >
                    {tHtml('common.new')}
                  </FunctionalButton>
                </Link>
              </>
            )}
          </MultipleButtonDiv>
        </div>
      </div>
    </>
  );
};

export default withRouter(ProfileInvestorQuiz);
