import { InvestorProjectResponse } from 'api/types/investor/project';
import { ProjectStatusEnum } from '../../enums/ProjectStatusEnum';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const canInvest = (arg: any): arg is InvestorProjectResponse => {
  return (
    'status' in arg &&
    arg.status === ProjectStatusEnum.OPEN_FOR_INVESTMENTS &&
    'is_investable' in arg &&
    arg.is_investable
  );
};

const InvestorProjectGuard = {
  canInvest,
};

export default InvestorProjectGuard;
