import styled from 'styled-components';
import { PrimaryButton } from 'components/Formik';

export const BonusWrapper = styled.div`
  width: 20rem;
`;

export const InvestButton = styled(PrimaryButton)`
  width: 25%;

  @media (max-width: 600px) {
    width: 50%;
  }
`;
