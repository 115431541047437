import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Col, ColProps, Row } from 'reactstrap';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';
import ProjectTopData from './ProjectTopData';
import ProjectTopTitle from './ProjectTopTitle';
import ProjectTopBuyButton from './ProjectTopBuyButton';
import ProjectGallery from 'components/Project/ProjectGallery';

type Props = SecondaryMarketInvestmentContextState;

const ProjectTop: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  const { tHtml } = useTranslation();

  if (!secondaryMarketInvestment) return null;

  const colProps: ColProps = {
    md: 6,
    lg: 6,
  };

  return (
    <>
      <div className={'mb-4'}>
        <h2>{tHtml('secondary_market.project')}</h2>
      </div>
      <section>
        <Row>
          <Col {...colProps}>
            <ProjectGallery pictures={secondaryMarketInvestment.pictures} />
          </Col>
          <Col {...colProps}>
            <ProjectTopTitle />
            <ProjectTopData />
            <ProjectTopBuyButton className={'float-end mb-4'} />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default withSecondaryMarketInvestment(ProjectTop);
