import React, { useState } from 'react';
import { Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { BaseLayout } from 'containers';
import Header from './Header/Desktop/Header';
import Footer from 'components/Footer';
import useDisplay from 'helpers/useDisplay';
import { StyledSidebarPageLayout } from './SidebarPageLayout.style';
import Sidebar from './Sidebar/Sidebar';
import HeaderMobile from './Header/Mobile/HeaderMobile';

const GuestPageLayout: React.FC = ({ children }) => {
  const { isMobile, isApp } = useDisplay();
  const [isOpen, setIsOpen] = useState(!isMobile);
  const toggle = () => setIsOpen((value) => !value);

  if (!isOpen && !isMobile) setIsOpen(true);

  return (
    <React.Fragment>
      <BaseLayout>
        <StyledSidebarPageLayout>
          <div id="layout-wrapper">
            <>
              <div className={'d-block d-lg-none'}>
                <HeaderMobile toggleMenuCallback={toggle} />
              </div>
              <div className={'d-none d-lg-block'}>
                <Header toggleMenuCallback={toggle} />
              </div>
              <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
            </>
            <Container className="mb-5 main-content-guest">
              <Row>{children}</Row>
            </Container>
          </div>
          {!isApp && <Footer />}
        </StyledSidebarPageLayout>
      </BaseLayout>
    </React.Fragment>
  );
};

export default withRouter(GuestPageLayout);
