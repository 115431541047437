import React from 'react';
import { Col, ColProps, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';
import DataBlockStacked from 'scopes/investor/pages/SecondaryMarket/components/DataBlockStacked';

type Props = SecondaryMarketInvestmentContextState;

const TabProjectOwner: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  const { tHtml } = useTranslation();

  if (!secondaryMarketInvestment?.project_owner) return null;

  const colProps: ColProps = {
    lg: 3,
    md: 6,
    sm: 6,
    xs: 12,
  };

  return (
    <>
      <section>
        <Row className={'gy-3'}>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('placeholder.type')}
              content={tHtml(
                `placeholder.legal_entity.${secondaryMarketInvestment.project_owner.legal_entity}`,
              )}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('placeholder.company.name')}
              content={secondaryMarketInvestment.project_owner.name}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('placeholder.company.legal_code')}
              content={secondaryMarketInvestment.project_owner.legal_code}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('placeholder.vat_code')}
              content={secondaryMarketInvestment.project_owner.vat_code}
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default withSecondaryMarketInvestment(TabProjectOwner);
