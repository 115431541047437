import React from 'react';

import { ProjectInvestmentContextState, withProjectInvestment } from '../ProjectInvestmentProvider';
import ProjectTopTitle from './ProjectTopTitle';
import ProjectTopData from './ProjectTopData';
import ProjectTopCalculator from './ProjectTopCalculator';
import ProjectGallery from 'components/Project/ProjectGallery';

type Props = ProjectInvestmentContextState;

const ProjectTopMobile: React.FC<Props> = ({ projectInvestment }) => {
  if (!projectInvestment) return <></>;

  return (
    <>
      <div className={'project-top'}>
        <ProjectTopTitle />
        <div className={'mb-4'}>
          <ProjectGallery pictures={projectInvestment.pictures} />
        </div>
        <ProjectTopData />
        <ProjectTopCalculator />
      </div>
    </>
  );
};

export default withProjectInvestment(ProjectTopMobile);
