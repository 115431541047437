import React, { ReactElement } from 'react';

interface CheckboxBlockProps {
  label: string | ReactElement;
  checked?: boolean;
  readonly?: boolean;
}

const CheckboxBlock: React.FC<CheckboxBlockProps> = ({
  label,
  checked = false,
  readonly = true,
}) => {
  return (
    <div className={'form-check'}>
      <input type="checkbox" className={'form-check-input'} readOnly={readonly} checked={checked} />
      <div className={'label'}>{checked ? <strong>{label}</strong> : <>{label}</>}</div>
    </div>
  );
};

export default CheckboxBlock;
