import React from 'react';

interface StepsDisplayerProps {
  numberOfSteps: number;
  currentStep: number;
  isLastStep: boolean;
}

const StepsDisplayer: React.FC<StepsDisplayerProps> = ({ ...props }): JSX.Element => {
  const getClassName = (step: number): string => {
    if (step === props.currentStep) {
      return 'step-wizard--number__active';
    } else if (props.currentStep > step) {
      return 'step-wizard--number__completed';
    }
    return '';
  };

  const returnSteps = () => {
    if (props.currentStep === 0 || props.isLastStep) {
      return <></>;
    }
    return (
      <>
        {props.currentStep - 1 > 0 && (
          <div key={props.currentStep - 1} className={'step-wizard'}>
            <div className={`step-wizard--number ${getClassName(props.currentStep - 1)}`}>
              {props.currentStep - 1}
            </div>
            <div className={'me-2'} />
            <i className={'icon-chevron-right'} />
          </div>
        )}
        <div key={props.currentStep} className={'step-wizard'}>
          <div className={`step-wizard--number ${getClassName(props.currentStep)}`}>
            {props.currentStep}
          </div>
          <div className={'me-2'} />
          {!isLastStep(props.currentStep) && <i className={'icon-chevron-right'} />}
        </div>
        {props.currentStep + 1 < props.numberOfSteps && (
          <div key={props.currentStep + 1} className={'step-wizard'}>
            <div className={`step-wizard--number ${getClassName(props.currentStep + 1)}`}>
              {props.currentStep + 1}
            </div>
            <div className={'me-1'} />
            <i className={'icon-more'} />
          </div>
        )}

        {props.currentStep !== props.numberOfSteps && (
          <div key={props.numberOfSteps} className={'step-wizard'}>
            <div className={`step-wizard--number ${getClassName(props.numberOfSteps)}`}>
              {props.numberOfSteps}
            </div>
            <div className={'me-4'} />
          </div>
        )}
      </>
    );
  };

  const isLastStep = (step: number): boolean => {
    return step === props.numberOfSteps;
  };

  return (
    <div className={'mb-4 mb-md-6'}>
      <div className={'d-flex'}>{returnSteps()}</div>
    </div>
  );
};
export default StepsDisplayer;
