import React, { useCallback, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import useTranslation from 'helpers/useTranslation';
import { useHistory } from 'react-router-dom';
import { RouteList } from 'routes';

import { MoneyOutRequestDto, WalletBankAccountResponse } from 'api/types/common';
import Api from 'api';
import { success } from 'services/toastr';
import SelectInput, { SelectOption } from 'components/Formik/SelectInput';
import { WalletBankAccountStatusEnum } from 'helpers/enums/wallet/WalletBankAccountStatusEnum';
import TransferBlockFormik from './TransferBlockFormik';
import { PrimaryButton } from 'components/Formik';
import { useWalletQuery } from 'api/queries/useWalletQuery';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { WithdrawalEventSettingsDto } from 'api/types/investor/bonus_system';
import { WithdrawalBonusModal } from './WithdrawalBonusModal';
import useDisplay from 'helpers/useDisplay';
import useUserType from 'helpers/useUserType';

interface Props {
  bankAccounts: Array<WalletBankAccountResponse>;
  defaultAmount?: number;
}

const defaultProps = {
  defaultAmount: 10,
};

const MoneyOutForm: React.FC<Props> = (props) => {
  const { t, tHtml } = useTranslation();
  const { isMobile } = useDisplay();
  const { showModal } = useGlobalModalContext();
  const history = useHistory();
  const { isInvestor } = useUserType();

  const [withdrawalEventSettings, setWithdrawalEventSettings] =
    React.useState<WithdrawalEventSettingsDto | null>(null);

  const [isWithdrawalBtnDisabled, setWithdrawalBtnDisabled] = useState(false);

  React.useEffect(() => {
    if (isInvestor()) {
      Api.investor.bonus_system.fetchWithdrawalEventSettings().then((data) => {
        setWithdrawalEventSettings(data);
      });
    }
  }, [isInvestor]);

  const { data: wallet, setQueryData: setWallet } = useWalletQuery();

  const [moneyOutRequest] = useState<MoneyOutRequestDto>({
    bank_account: '',
    amount: props.defaultAmount!,
  });

  const moneyOutSchema = Yup.object().shape({
    bank_account: Yup.string().id().required(),
    amount: Yup.number().money().required(),
  });

  const onMoneyOutSubmit = useCallback(
    async (request: MoneyOutRequestDto) => {
      if (
        withdrawalEventSettings &&
        Object.keys(withdrawalEventSettings).length !== 0 &&
        withdrawalEventSettings.bonus === null &&
        request.amount >= withdrawalEventSettings.min_amount
      ) {
        showModal(
          <WithdrawalBonusModal
            percent={withdrawalEventSettings.percent}
            validity={withdrawalEventSettings.single_bonus_validity_days}
            onAccept={() =>
              Api.investor.bonus_system.rewardWithdrawalBonus().then(() => {
                history.push(RouteList.INVESTOR.BONUS_SYSTEM.VIEW);
              })
            }
            onDecline={() => {
              setWithdrawalBtnDisabled(true);
              Api.wallet.moneyOutStart(request).then((response) => {
                setWallet(response);
                success(tHtml('wallet.withdraw_success'));
                setWithdrawalBtnDisabled(false);
              });
            }}
          />,
        );
      } else {
        await Api.wallet.moneyOutStart(request).then((response) => {
          setWallet(response);
          success(tHtml('wallet.withdraw_success'));
        });
      }
    },
    [history, setWallet, showModal, tHtml, withdrawalEventSettings],
  );

  const getBankAccountOptions = (): Array<SelectOption> => {
    return props.bankAccounts.map((bankAccount) => ({
      value: bankAccount.id,
      label: `${bankAccount.bank_name} (${bankAccount.iban}) - ${t(
        'wallet.bank_account.status.' + bankAccount.status,
      )}`,
      isDisabled: bankAccount.status != WalletBankAccountStatusEnum.Enabled,
    }));
  };

  return (
    <>
      <h2>{tHtml('wallet.withdraw_money')}</h2>
      <Formik
        initialValues={moneyOutRequest}
        validationSchema={moneyOutSchema}
        onSubmit={onMoneyOutSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className={'form-input mb-3 pt-1'}>
              <SelectInput
                name={'bank_account'}
                options={getBankAccountOptions()}
                noOptionNewMessage={
                  isMobile ? t('wallet.no_options_mobile') : t('wallet.no_options')
                }
              />
            </div>
            <TransferBlockFormik
              name={'amount'}
              label={tHtml('wallet.withdraw_transaction_label')}
              max={wallet?.balance}
              tax={wallet?.tax}
            />
            <div className="text-muted small mt-2">{tHtml('wallet.withdrawal_note')}</div>
            <PrimaryButton
              className={'site btn btn-primary w-50 mt-4'}
              disabled={isSubmitting || isWithdrawalBtnDisabled}
            >
              {tHtml('wallet.withdraw')}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

MoneyOutForm.defaultProps = defaultProps;

export default MoneyOutForm;
