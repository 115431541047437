import React, { useEffect, useState } from 'react';
import { ArrayHelpers } from 'formik';
import { Card } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { DataBlock } from 'components/DataBlock';
import { FunctionalButton } from 'components/Button';
import { DatepickerInput, TextInput } from 'components/Formik';
import { ProspectFinancialObligationsDto } from 'api/types/guest/prospectApplication';

const SingleCompanyExperienceForm: React.FC<{
  financialObligation: ProspectFinancialObligationsDto;
  index: number;
  formikHelpers: any;
  formikArrayHelpers: ArrayHelpers;
}> = ({ financialObligation, index, formikHelpers, formikArrayHelpers }) => {
  const { t, tHtml } = useTranslation();
  const [show, setShow] = useState<boolean>(true);

  const amount = `financial_obligations[${index}].amount`;
  const repaymentDeadline = `financial_obligations[${index}].repayment_deadline`;
  const securityMeasure = `financial_obligations[${index}].security_measure`;
  const purpose = `financial_obligations[${index}].purpose`;
  const creditor = `financial_obligations[${index}].creditor`;

  const setVisibility = async () => {
    const errors = await formikHelpers.validateForm();

    let show = false;
    if (
      errors != undefined &&
      errors.financial_obligations != undefined &&
      errors.financial_obligations[index] != undefined
    ) {
      show = true;
    }

    setShow(show);
  };

  useEffect(() => {
    setVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, formikHelpers.validateForm, formikHelpers.submitCount]);

  if (!show) {
    return (
      <div className={'step-form-cards'}>
        <Card body className={'light-pink'}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <DataBlock
              title={`${financialObligation.amount} ${t(
                'prospect_application.financial_obligations.amount',
              )}`}
              data={`€ ${financialObligation.amount}`}
            />
            <DataBlock
              title={t(`prospect_application.financial_obligations.purpose`)}
              data={financialObligation.purpose ?? ''}
            />
            <div className="buttons">
              <FunctionalButton onClick={() => setShow(true)}>
                {tHtml('common.edit')}
              </FunctionalButton>
              <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
                {tHtml('common.remove')}
              </FunctionalButton>
            </div>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="sub-form">
      <div className="mb-4">
        <TextInput
          type={'number'}
          name={amount}
          placeholder={t('placeholder.prospect_application.financial_obligations.amount')}
        />
      </div>
      <div className="mb-4">
        <DatepickerInput
          name={repaymentDeadline}
          label={t('placeholder.prospect_application.financial_obligations.repayment_deadline')}
          hasFutureRange={true}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name={securityMeasure}
          placeholder={t('placeholder.prospect_application.financial_obligations.security_measure')}
        />
      </div>
      <div className="mb-4">
        <TextInput
          name={purpose}
          placeholder={t('placeholder.prospect_application.financial_obligations.purpose')}
        />
      </div>
      <div className="mb-3">
        <TextInput
          name={creditor}
          placeholder={t('placeholder.prospect_application.financial_obligations.creditor')}
        />
      </div>

      <div className="mb-3">
        <div className="buttons">
          <FunctionalButton onClick={() => setVisibility()}>
            {tHtml('common.save')}
          </FunctionalButton>
          <FunctionalButton onClick={() => formikArrayHelpers.remove(index)}>
            {tHtml('common.remove')}
          </FunctionalButton>
        </div>
      </div>
    </div>
  );
};

export default SingleCompanyExperienceForm;
