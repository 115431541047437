import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import CreatableSelectInput, { ReactSelectProps } from '../CreatableSelectInput';
import { SelectOption } from '../SelectInput';

interface Props extends Omit<ReactSelectProps, 'options'> {
  name: string;
  placeholder: string;
}

const MONTHS = [6, 12, 18, 24, 30, 36];

const SelectCreditDurationInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [options] = useState<SelectOption[]>(
    MONTHS.map((type) => ({
      value: type.toString(),
      label: t('common.month', { value: type }),
    })),
  );

  return (
    <CreatableSelectInput
      isValidNewOption={(s, value, options) => {
        const val = parseInt(s);

        return val > 0 && val <= 120 && !options.find((x) => x.value?.toString() === s);
      }}
      formatCreateLabel={(val: string) => t('common.month', { value: val })}
      formatOptionLabel={(o: any) => t('common.month', { value: o.value })}
      options={options}
      {...props}
    />
  );
};

export default SelectCreditDurationInput;
