export enum ApplicationDocumentsCollectionEnum {
  COLLECTION_PROSPECT_APPLICATION_ASSESSMENT_REPORT_DOCUMENT = 'prospect_application_assessment_report_document',
  COLLECTION_PROSPECT_APPLICATION_DOCUMENT = 'prospect_application_document',
  COLLECTION_PROSPECT_COMPANY_EXECUTIVE_DOCUMENT = 'prospect_user_company_executive_document',
  COLLECTION_PROSPECT_COMPANY_REPRESENTATIVE_DOCUMENT = 'prospect_user_company_representative_document',
  COLLECTION_PROSPECT_COMPANY_FINANCIAL_STATEMENT_DOCUMENT = 'prospect_user_company_financial_statement_document',
  COLLECTION_PROSPECT_COMPANY_FOUNDING_DOCUMENT = 'prospect_user_company_founding_document',
  COLLECTION_PROSPECT_COMPANY_REPRESENTATIVE_PROOF_DOCUMENT = 'prospect_user_company_representative_proof_document',
  COLLECTION_PROSPECT_COMPANY_NON_CRIMINAL_RECORD_DOCUMENT = 'prospect_user_company_non_criminal_record_document',
  COLLECTION_PROSPECT_COMPANY_GROUP_STRUCTURE_DOCUMENT = 'prospect_user_company_group_structure_document',
  COLLECTION_PROSPECT_COMPANY_SHAREHOLDER_DOCUMENT = 'prospect_user_company_shareholder_document',
}
