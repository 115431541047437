// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import _ from 'lodash';

const transformErrors = (errors: { [key: string]: Array<string> }): any => {
  const keys = Object.keys(errors);

  let buildErrors: any = {};

  keys.forEach((key) => {
    const exploded = key.split('.').reverse();

    let currentValue: any = {};
    for (let i = 0; i < exploded.length; i++) {
      const currentKey = exploded[i];

      if (i === 0) {
        // if first key is number, then it must be array
        if (!isNaN(+currentKey)) {
          currentValue = [];
          currentValue[+currentKey] = errors[key];
        } else {
          currentValue = errors[key];
        }
      } else {
        // if number, that means, it is part of array, create an array
        if (!isNaN(+currentKey)) {
          const arrayValue = [];
          arrayValue[+currentKey] = { ...currentValue };
          currentValue = {
            [exploded[i + 1]]: arrayValue,
          };
        } else {
          if (Array.isArray(currentValue)) {
            currentValue = {
              [currentKey]: [...currentValue],
            };
          } else {
            currentValue = {
              [currentKey]: { ...currentValue },
            };
          }
        }
      }
    }

    buildErrors = _.merge(currentValue, buildErrors);
  });

  return buildErrors;
};

export default transformErrors;
