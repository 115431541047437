import React from 'react';

interface GradientSVGProps {
  startColor: string;
  endColor: string;
  id: string;
  rotation?: number;
}

const GradientSVG: React.FC<GradientSVGProps> = ({ startColor, endColor, id, rotation = 0 }) => {
  const gradientTransform = `rotate(${rotation})`;

  return (
    <svg style={{ height: 0, width: 0 }}>
      <defs>
        <linearGradient id={id} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GradientSVG;
