import React from 'react';
import useTranslation from 'helpers/useTranslation';

import SanitizedHtmlBlock from 'components/SanitizedHtmlBlock';
import { ProjectDeveloperProjectResponseDto } from 'api/types/project-developer/project';
import { InvestorProjectResponse } from 'api/types/investor/project';
import { ProjectContextState, withProject } from '../Scope/ProjectProvider';
import { GuestProjectResponse } from '../../../api/types/guest/project';
import { RiskCategoryTooltip } from './Tooltips/RiskCategoryTooltip';
import { RiskCategory } from '../../Table/ProjectRiskTable/ProjectRiskTable';

type ProjectResponseType =
  | GuestProjectResponse
  | ProjectDeveloperProjectResponseDto
  | InvestorProjectResponse;

const ProjectOwner: React.FC<ProjectContextState> = ({ project }) => {
  const { tHtml } = useTranslation();

  const p: ProjectResponseType | undefined = project as ProjectResponseType;

  if (!p || !p.authorized_details) return <></>;

  return (
    <>
      <div className={'project-owner'}>
        {p.authorized_details.project_owner_legal_entity && (
          <div className={'data-block'}>
            <div className={'title'}>{tHtml('projects.owner.legal_entity_title')}</div>
            <div className={'text'}>
              {tHtml('placeholder.legal_entity.' + p.authorized_details.project_owner_legal_entity)}
            </div>
          </div>
        )}
        {p.authorized_details.project_owner_name && (
          <div className={'data-block'}>
            <div className={'title'}>{tHtml('projects.owner.owner_name_title')}</div>
            <div className={'text'}>{p.authorized_details.project_owner_name}</div>
          </div>
        )}
        {p.authorized_details.project_owner_legal_code && (
          <div className={'data-block'}>
            <div className={'title'}>{tHtml('projects.owner.legal_code_title')}</div>
            <div className={'text'}>{p.authorized_details.project_owner_legal_code}</div>
          </div>
        )}
        {p.authorized_details.project_owner_address && (
          <div className={'data-block'}>
            <div className={'title'}>{tHtml('projects.owner.address_title')}</div>
            <div className={'text'}>{p.authorized_details.project_owner_address}</div>
          </div>
        )}
        {p.authorized_details.project_owner_activity && (
          <div className={'data-block'}>
            <div className={'title'}>{tHtml('projects.owner.activity_title')}</div>
            <div className={'text'}>{p.authorized_details.project_owner_activity}</div>
          </div>
        )}
        {p.authorized_details.project_owner_description && (
          <div className={'data-block'}>
            <div className={'title'}>{tHtml('projects.owner.description_title')}</div>
            <SanitizedHtmlBlock
              content={p.authorized_details.project_owner_description}
              className={'text'}
            />
          </div>
        )}
        {p.authorized_details.project_owner_executive && (
          <div className={'data-block'}>
            <div className={'title'}>{tHtml('projects.owner.executive_title')}</div>
            <div className={'text'}>{p.authorized_details.project_owner_executive}</div>
          </div>
        )}
        {p.authorized_details.project_owner_established_at && (
          <div className={'data-block'}>
            <div className={'title'}>{tHtml('projects.owner.established_at_title')}</div>
            <div className={'text'}>{p.authorized_details.project_owner_established_at}</div>
          </div>
        )}
        {p.authorized_details.risk_category && (
          <div className={'data-block'}>
            <div className={'title d-inline-flex'}>
              {tHtml('projects.owner.risk_category')}
              <RiskCategoryTooltip />
            </div>
            <div className={'text'}>{RiskCategory[p.authorized_details.risk_category]}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default withProject(ProjectOwner);
