/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { action } from 'typesafe-actions';

import {
  ApplicationState,
  ApplicationReducer,
  initialState,
  UPDATE_APPLICATION,
} from './ApplicationReducer';
import Api from 'api';

interface IApplicationContext {
  state: ApplicationState;
  dispatch: React.Dispatch<any>;
  setApplicationId: (value: number | string | undefined) => void;
}

export const ApplicationContext = createContext<IApplicationContext>({
  state: initialState,
  dispatch: () => {
    return;
  },
  setApplicationId: (): void => {
    throw new Error('setApplicationId function must be overridden');
  },
});

export const ApplicationContextProvider: React.FC = ({ children }): JSX.Element => {
  const [applicationId, setApplicationId] = useState<number | string | undefined>(undefined);
  const [state, dispatch] = useReducer(ApplicationReducer, initialState);

  const fetchApplication = async (applicationId: number | string) => {
    if (applicationId == state.id) {
      return;
    }

    try {
      await Api.projectDeveloper.applications
        .fetchCurrentApplication(applicationId)
        .then((response) => {
          dispatch(action(UPDATE_APPLICATION, response));
        });
    } catch (e) {
      return;
    }
  };

  useEffect(() => {
    if (applicationId === undefined) return;

    fetchApplication(applicationId);
  }, [applicationId]);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <ApplicationContext.Provider
      value={{
        state: state,
        dispatch: dispatch,
        setApplicationId,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
