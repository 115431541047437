import React from 'react';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';
import { PaginationDataFilter } from 'api/types';
import Loader from 'components/Loader';

interface Props {
  icon?: 'download-file';
  onClick: (request: PaginationDataFilter | undefined) => () => Promise<any>;
  request: PaginationDataFilter | undefined;
  type: CommonDocumentTypeEnum;
}

const DownloadFileIcon: React.FC = () => (
  <div className={'download-icon'}>
    <i className="icon icon-double-angle-down" />
  </div>
);

const ExportTableButton: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClicked = async () => {
    setIsLoading(true);

    try {
      await props.onClick(props.request)();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <a onClick={handleClicked} download target={'_blank'} rel="noreferrer">
      <div className={'download-icon-wrapper text-uppercase py-2 justify-content-center'}>
        {isLoading ? (
          <Loader size={'sm'} />
        ) : (
          <>
            {' '}
            <DownloadFileIcon />
            <div>
              <h4 className={'mb-0 active'}>{props.type}</h4>
            </div>
          </>
        )}
      </div>
    </a>
  );
};

export default ExportTableButton;
