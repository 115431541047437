import { ErrorMessage, useField } from 'formik';
import React, { useEffect } from 'react';
import useTranslation from 'helpers/useTranslation';

import ScrollToOnError from 'components/Formik/ScrollToOnError';

export interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  hideLabel?: boolean;
}

const TextInputLabelHighlighted: React.FC<Props> = ({ ...props }) => {
  const { t, currentLanguage } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  if (!props.placeholder) {
    props.placeholder = t('placeholder.' + props.name);
  }

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const isError = meta?.touched && !!meta?.error;

  return (
    <>
      <div className={isError ? 'is-invalid' : ''}>
        <ScrollToOnError name={props.name} isError={isError}>
          {!props.hideLabel && (
            <label className={'form-question-label'} htmlFor={props.name}>
              {props.placeholder}
            </label>
          )}
          <input
            onChange={field.onChange}
            onBlur={field.onBlur}
            onInput={() => helper.setTouched(true, true)}
            value={field.value}
            id={props.name}
            className={`form-control${isError ? ' is-invalid' : ''}`}
            {...props}
            placeholder={''}
          />
        </ScrollToOnError>
      </div>
      {isError && (
        <div className={'invalid-feedback text-pre'}>
          {props.name && (
            <ErrorMessage
              component={'div'}
              name={props.name}
              render={(msg: string | string[]) => {
                if (typeof msg === 'string') {
                  return msg;
                }

                return msg.join('\n');
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
export default TextInputLabelHighlighted;
