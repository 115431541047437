import { Col, Row } from 'reactstrap';
import React from 'react';
import { Cell } from 'react-table';

interface SubRowTableProps {
  cell: Cell<any>;
}

const SubRowTable = ({ cell }: SubRowTableProps) => {
  return (
    <Row className="mobile-table-card-value">
      <Col xs={6} className="header-value">
        {cell.render('Header')}
      </Col>
      <Col
        xs={typeof cell.render('Header') !== 'string' || !cell.render('Header') ? 12 : 6}
        {...cell.getCellProps()}
        className={
          typeof cell.render('Header') !== 'string' || !cell.render('Header')
            ? 'center-cell'
            : 'value-cell'
        }
      >
        {cell.render('Cell')}
      </Col>
    </Row>
  );
};

export default SubRowTable;
