import React, { useCallback, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import useTranslation from 'helpers/useTranslation';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { success } from 'services/toastr';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { AffiliateSendModal } from './index';
import ReferralEmailInput from './components/ReferralEmailInput';
import Tooltip from 'components/Tooltip';
import { RouteList } from 'routes';
import { Link } from 'react-router-dom';
import { HighlightButton } from 'components/Button';
import Api from 'api';
import AffiliateTierBlock from './components/AffiliateTierBlock';

interface EmailFormFields {
  email: string;
}

const CopyOrLoadBlock: React.FC<{ value: string | undefined | null }> = ({ value }) => {
  const { tHtml } = useTranslation();

  const copyText = async (data: string) => {
    await navigator.clipboard.writeText(data);
    success(tHtml('common.clipboard_success'));
  };

  if (value) {
    return (
      <>
        <span>{value}</span>
        <span className={'copy-text'} onClick={() => copyText(value)}>
          {tHtml('common.copy')}
        </span>
      </>
    );
  }

  return (
    <>
      <div className="spinner-border spinner-border-sm" />
      <span>&nbsp;</span>
    </>
  );
};

interface TileInterface {
  color: 'pink' | 'grey';
  amount: number;
}

const MINIMUM_TILE_COUNT = 11;

const AffiliateInformationBlock: React.FC<{
  affiliate_url?: string;
  affiliate_code?: string;
  total_earned?: number;
  invited_people?: number;
}> = ({ affiliate_url, affiliate_code, total_earned, invited_people = 0 }) => {
  const [emailFormRequest] = useState<EmailFormFields>({ email: '' });
  const { tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const greyTileCount =
    MINIMUM_TILE_COUNT - invited_people <= 0 ? 1 : MINIMUM_TILE_COUNT - invited_people;

  const tiles: TileInterface[] = [];

  const getAmountByInviteeCount = (inviteeCount: number) => {
    const amounts = [10, 10, 20, 30, 40, 50, 50, 50, 50, 50];

    if (inviteeCount > 10) {
      return amounts[9];
    }

    return amounts[inviteeCount - 1];
  };

  const showTileNumber = (index: number) => {
    if (index === 0) {
      return 1;
    }

    if (index === tiles.length - 1) {
      return tiles.length;
    }

    return undefined;
  };

  for (let i = 0; i < invited_people; i++) {
    tiles.push({
      color: 'pink',
      amount: getAmountByInviteeCount(i + 1),
    });
  }

  for (let i = 0; i < greyTileCount; i++) {
    tiles.push({
      color: 'grey',
      amount: getAmountByInviteeCount(invited_people + i + 1),
    });
  }

  const onSubmit = useCallback(
    async (request: EmailFormFields, helpers: FormikHelpers<EmailFormFields>) => {
      try {
        await Api.investor.affiliate.sendReferralLinkByEmail(request);
        showModal(<AffiliateSendModal />);
        helpers.resetForm();
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [showModal],
  );

  const EmailSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const scrollableDivRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollLeft =
        scrollableDivRef.current.scrollWidth - scrollableDivRef.current.clientWidth;
    }
  }, []);

  return (
    <section className={'information-section'}>
      <Row>
        <Col lg={12} xl={6} className={'mb-5'}>
          <h4 className={'mb-3'}>{tHtml('affiliate.labels.share_affiliate_link')}</h4>
          <div className={'form-control form-control-disabled'}>
            <CopyOrLoadBlock value={affiliate_url} />
          </div>
        </Col>
        <Col lg={12} xl={6} className={'mb-5'}>
          <div className={'d-flex'}>
            <h4 className={'mb-3'}>{tHtml('affiliate.labels.send_affiliate_email')}</h4>
            <i className={'icon icon-info'} id={'invite-tooltip-icon-1'} />
            <Tooltip
              id={'invite-tooltip-icon-1'}
              text={tHtml('affiliate.tooltips.invite_by_email')}
            />
          </div>

          <Formik
            initialValues={emailFormRequest}
            onSubmit={onSubmit}
            enableReinitialize={true}
            validationSchema={EmailSchema}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div
                  className={`form-control d-flex justify-content-between input-field-block ${
                    isSubmitting ? 'form-control-disabled' : ''
                  }`}
                >
                  <div className={'form-input w-75'}>
                    <ReferralEmailInput name="email" disabled={isSubmitting} autoComplete={'off'} />
                  </div>
                  <button className={'btn-form'} type={'submit'} disabled={isSubmitting}>
                    {tHtml('common.send')}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
        <Col lg={12} xl={6} className={'mb-5'}>
          <div className={'d-flex'}>
            <h4 className={'mb-3'}>{tHtml('affiliate.labels.share_affiliate_code')}</h4>
            <i className={'icon icon-info'} id={'invite-tooltip-icon-2'} />
            <Tooltip
              id={'invite-tooltip-icon-2'}
              text={tHtml('affiliate.tooltips.invite_by_code')}
            />
          </div>
          <div className={'form-control form-control-disabled'}>
            <CopyOrLoadBlock value={affiliate_code} />
          </div>
        </Col>
        <Col lg={12} xl={6}>
          <h4 className={'mb-3'}>{tHtml('affiliate.labels.affiliate_profit')}</h4>
          <div className={'row'}>
            <div className={'col col-4 d-fled align-items-center'}>
              <p className={'money'}>
                {total_earned !== undefined ? tHtml('common.money', { value: total_earned }) : '-'}
              </p>
            </div>
            <div className={'col col-8'}>
              <Link to={RouteList.INVESTOR.BONUS_SYSTEM.VIEW}>
                <HighlightButton className={'btn btn-highlight btn-lg'}>
                  {tHtml('bonus_system.my_active_bonuses.title')}
                </HighlightButton>
              </Link>
            </div>
          </div>
        </Col>
        <Col lg={12} xl={12} className={'mt-2'}>
          <h4 id={'affiliate-tier-program'} className={'mb-4'}>
            {tHtml('affiliate.labels.affiliate_program_tier_title')}
          </h4>
          <div className={'d-flex affiliate-tier-wrapper'}>
            <div className="d-flex flex-column justify-content-between position-relative">
              <span className="text-muted small">
                {tHtml('affiliate.labels.affiliate_program_invited_people')}
              </span>
              <span className="text-muted small mb-4">
                {tHtml('affiliate.labels.affiliate_program_bonus_sum')}
              </span>
              {tiles.length > MINIMUM_TILE_COUNT && <div className="affiliate-tiles-shadow" />}
            </div>
            <div
              className="affiliate-tiles"
              style={
                tiles.length === MINIMUM_TILE_COUNT
                  ? undefined
                  : { overflowX: 'auto', width: '100%' }
              }
              ref={tiles.length === MINIMUM_TILE_COUNT ? null : scrollableDivRef}
            >
              {tiles.map((tile, i) => (
                <AffiliateTierBlock
                  key={i}
                  color={tile.color}
                  amount={tile.amount}
                  tileNumber={i + 1}
                  invitedPeople={invited_people}
                  isMore={tiles.length > MINIMUM_TILE_COUNT}
                  numberPosition={showTileNumber(i) === 1 ? 'left' : 'right'}
                  isLast={tiles.length - 1 === i}
                />
              ))}
            </div>
          </div>
        </Col>
        <Col lg={12} xl={12} className={'mt-2'}>
          <h4 className={'mb-4'}>{tHtml('affiliate.labels.affiliate_rules_title')}</h4>
          <ul className={'text-muted'}>
            <li>{tHtml('affiliate.affiliate_rules.rule1')}</li>
            <li>{tHtml('affiliate.affiliate_rules.rule2')}</li>
            <li>{tHtml('affiliate.affiliate_rules.rule3')}</li>
            <li>{tHtml('affiliate.affiliate_rules.rule4')}</li>
            <li>{tHtml('affiliate.affiliate_rules.rule5')}</li>
            <li>{tHtml('affiliate.affiliate_rules.rule6')}</li>
          </ul>
        </Col>
      </Row>
    </section>
  );
};

export default AffiliateInformationBlock;
