import React from 'react';
import { withRouter } from 'react-router-dom';

import StepFormLayoutSkeleton from './StepFormLayoutSkeleton';
import useAppState from '../../modules/appState';

const StepFormLayout: React.FC = ({ children }) => {
  const { globalLoading } = useAppState();

  return (
    <div className="step-form-layout">{globalLoading ? <StepFormLayoutSkeleton /> : children}</div>
  );
};

export default withRouter(StepFormLayout);
