import React, { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Step } from './Step';

interface Props {
  steps: Step[];
}

const StepFormStepsWizard: React.FC<Props> = ({ steps }): JSX.Element => {
  const { tHtml } = useTranslation();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<Step | null>(null);
  const [filteredSteps, setFilteredSteps] = useState<Step[]>([]);

  useEffect(() => {
    const filteredSteps = steps.filter((step: Step) => {
      if (!step.hideInWizard) return step;
    });

    setFilteredSteps(filteredSteps);

    filteredSteps.forEach((step: Step) => {
      if (!!matchPath(location.pathname, step.route)) {
        setCurrentStep(step);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, steps]);

  const getClassName = (step: Step): string => {
    if (currentStep) {
      if (step === currentStep && !!matchPath(location.pathname, step.route)) {
        return 'step-wizard--number__active';
      } else if (currentStep.step > step.step) {
        return 'step-wizard--number__completed';
      }
    }

    return '';
  };

  const isLastStep = (step: Step): boolean => {
    const lastStep = filteredSteps[filteredSteps.length - 1];
    return lastStep.step === step.step;
  };

  return (
    <div className={'mb-4 mb-md-6'}>
      <div className={'d-flex flex-sm-row flex-wrap'}>
        {filteredSteps.map((step: Step, idx: number) => {
          return (
            <div key={idx} className={'step-wizard'}>
              <div className={`step-wizard--number ${getClassName(step)}`}>{step.step}</div>
              {step.name && (
                <div className={'step-wizard--text mr-0-75 text-center'}>{tHtml(step.name)}</div>
              )}
              {!isLastStep(step) && <i className={'icon-chevron-right'} />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepFormStepsWizard;
