import React, { useState } from 'react';
import { PrimaryButton } from 'components/Button';
import useTranslation from 'helpers/useTranslation';
import { useAccountQuery } from 'api/queries';
import useAppState from 'modules/appState';
import Api from 'api';
import { useAuth } from 'services/useAuth';
import { navigate } from 'helpers';
import { RouteList } from 'routes';
import Loader from '../../../../../components/Loader';

const RedirectToMainUserIdVerificationButton = () => {
  const { tHtml } = useTranslation();
  const { data } = useAccountQuery();
  const { setIsSwitchingAccount } = useAppState();
  const { login } = useAuth();
  const [redirecting, setRedirecting] = useState<boolean>(false);

  const handleAction = async () => {
    setRedirecting(true);
    const account = data?.user;
    if (!account || redirecting) {
      return;
    }

    try {
      setIsSwitchingAccount(true);
      const response = await Api.shared.loginAs.userLogin();

      await login(response.access_token, response.permissions);
      navigate(RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION);
    } finally {
      setIsSwitchingAccount(false);
      setRedirecting(false);
    }
  };

  return (
    <PrimaryButton onClick={handleAction}>
      {redirecting ? <Loader size={'sm'} /> : tHtml('common.start')}
    </PrimaryButton>
  );
};

export default RedirectToMainUserIdVerificationButton;
