import { ProjectInvestmentTypeEnum } from 'helpers/enums/ProjectInvestmentTypeEnum';
import Tooltip from 'components/Tooltip';
import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Cell } from 'react-table';
import { InvestorInvestmentListResponseDto } from 'api/types/investor/investment';

interface Props {
  cell: Cell<InvestorInvestmentListResponseDto>;
}

export const AutomaticInvestmentTooltip = ({ cell }: Props) => {
  const { tHtml } = useTranslation();

  const randomValue = Math.floor(Math.random() * 100);
  const tooltipId = `investedat-${cell.row.index}-${randomValue}`;

  if (cell.row.original.investment_type === ProjectInvestmentTypeEnum.AUTO) {
    return (
      <>
        <i className={'ms-1 icon icon-automatic-circle'} id={tooltipId} />
        <Tooltip id={tooltipId} text={tHtml('investments.tooltip.automatic')} placement={'right'} />
      </>
    );
  }

  return <></>;
};
