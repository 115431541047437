import Client from '../client';
import Http from '../../http';
import { ProjectDeveloperDashboardStatisticResponseDto } from '../../types/project-developer/dashboard';

class ProjectDeveloperDashboard extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_DEVELOPER_DASHBOARD_META: this.protectedUrl(
        `${this.http.baseUrl}/project-developer/dashboard`,
      ),
    };
  }

  fetchMeta = (): Promise<ProjectDeveloperDashboardStatisticResponseDto> => {
    return this.http.get(this.api.FETCH_DEVELOPER_DASHBOARD_META);
  };
}

export default ProjectDeveloperDashboard;
