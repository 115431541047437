import React from 'react';
import Skeleton from 'react-loading-skeleton';

const StrategyListSkeleton: React.FC = () => (
  <div>
    <div className={'strategy-item'}>
      <Skeleton height={36} />
    </div>
    <div className={'strategy-item'}>
      <Skeleton height={36} />
    </div>
  </div>
);

export default StrategyListSkeleton;
