import {
  InvestorAutoInvestmentConfigPackEnum,
  InvestorAutoInvestmentFullConfig,
} from 'api/types/investor/investment';
import { HighlightButton } from 'components/Button';
import useTranslation from 'helpers/useTranslation';
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { StrategyForm } from './StrategyForm';

const NewStrategyListItem: React.FC<{
  selectedPackage: InvestorAutoInvestmentConfigPackEnum;
  onClick: () => void;
  autoInvestmentConfig: InvestorAutoInvestmentFullConfig;
}> = ({ selectedPackage, onClick, autoInvestmentConfig }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { tHtml } = useTranslation();

  return (
    <div className={'strategy-item new'}>
      <div className={'collapsable-block__header'} onClick={() => setIsOpen(!isOpen)}>
        <h2 className={'d-flex justify-content-between align-items-center'}>
          <div className={'d-flex align-items-center gap-3'} id="sortable-handle">
            <span className={'pe-4'}>
              {tHtml(`investments.auto_investment.strategy_without_name`)}
            </span>
          </div>

          <span>
            <HighlightButton
              type="button"
              className={'btn btn-highlight fw-normal me-3 px-3'}
              onClick={onClick}
            >
              {tHtml('investments.auto_investment.delete')}
            </HighlightButton>
            {isOpen ? (
              <i className={'icon icon-arrow-up'} />
            ) : (
              <i className={'icon icon-arrow-down'} />
            )}
          </span>
        </h2>
      </div>
      <Collapse isOpen={isOpen} className={'collapsable-block__content'}>
        <div className={'pb-4 pt-4'}>
          <StrategyForm
            autoInvestmentConfig={autoInvestmentConfig}
            selectedConfig={selectedPackage}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default NewStrategyListItem;
