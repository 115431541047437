import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { Cell, Column } from 'react-table';

import Table from 'components/Table/Table';
import Api from 'api';
import {
  BonusConditionEnum,
  BonusSystemListResponseDto,
  BonusSystemProjectDto,
  BonusSystemType,
} from 'api/types/investor/bonus_system';
import { PageHeader } from 'components/Header';
import { RouteList } from 'routes';
import { Link } from 'react-router-dom';
import { FunctionalButton, PrimaryButton } from 'components/Button';
import { ColumnCodeStyle, ColumnProjectsStyle } from './BonusSystem.style';
import Tooltip from 'components/Tooltip';

const INITIAL_SORT_ORDER = [
  {
    id: 'expire_at',
    desc: true,
  },
];

const BonusSystem: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const [data, setData] = useState<PaginationData<BonusSystemListResponseDto>>();

  const columns = useMemo<Column<BonusSystemListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.bonus_system.type'),
        sortType: 'string',
        accessor: 'type',
        width: 50,
        maxWidth: 250,
        Cell: (cell: Cell<BonusSystemListResponseDto>) => {
          const id = cell.row.id;
          return (
            <ColumnCodeStyle>
              {tHtml('bonus_system.types.' + cell.value)}
              {cell.value === BonusSystemType.REFERRAL_REWARD ? (
                <>
                  <i className={'icon icon-info'} id={`tooltip-icon-${id}`} />
                  <Tooltip
                    id={`tooltip-icon-${id}`}
                    text={tHtml('bonus_system.tooltip.referral_reward', {
                      value: cell.row.original.affiliate_name,
                    })}
                    placement={'right'}
                  />
                </>
              ) : null}
              {[BonusSystemType.AFFILIATE_REWARD, BonusSystemType.AFFILIATE_REWARD_V2].includes(
                cell.value,
              ) ? (
                <>
                  <i className={'icon icon-info'} id={`tooltip-icon-${id}`} />
                  <Tooltip
                    id={`tooltip-icon-${id}`}
                    text={tHtml('bonus_system.tooltip.affiliate_reward', {
                      value: cell.row.original.referral_name,
                    })}
                    placement={'right'}
                  />
                </>
              ) : null}
            </ColumnCodeStyle>
          );
        },
      },
      {
        Header: t('table.bonus_system.code'),
        sortType: 'string',
        accessor: 'code',
        width: 50,
        maxWidth: 250,
        Cell: (cell: Cell<BonusSystemListResponseDto>) => {
          return <ColumnCodeStyle>{cell.value}</ColumnCodeStyle>;
        },
      },
      {
        Header: t('table.bonus_system.project_name'),
        sortType: 'string',
        accessor: 'projects',
        Cell: (cell: Cell<BonusSystemListResponseDto>) => {
          if (cell?.value.length === 0) {
            return (
              <ColumnProjectsStyle>
                {tHtml('bonus_system.table.applies_all_projects')}
              </ColumnProjectsStyle>
            );
          }

          const projects =
            cell.value?.map(function (project: BonusSystemProjectDto) {
              return [project.private_id, project.project_name].filter((v) => !!v).join(' - ');
            }) ?? [];

          return <ColumnProjectsStyle>{projects.join(', ')}</ColumnProjectsStyle>;
        },
      },
      {
        Header: t('table.bonus_system.status'),
        sortType: 'string',
        accessor: 'status',
        width: 20,
        Cell: (cell: Cell<BonusSystemListResponseDto>) => {
          return t('bonus_system.status.' + cell.value);
        },
      },
      {
        Header: t('table.bonus_system.summable'),
        sortType: 'bool',
        accessor: 'summable',
        width: 20,
        Cell: (cell: Cell<BonusSystemListResponseDto>) => {
          const val = cell.row.original;
          return <span>{val.summable ? tHtml('common.yes') : tHtml('common.no')}</span>;
        },
      },
      {
        Header: t('table.bonus_system.sellable'),
        sortType: 'bool',
        accessor: 'sellable',
        width: 20,
        Cell: (cell: Cell<BonusSystemListResponseDto>) => {
          const val = cell.row.original;
          return <span>{val.sellable ? tHtml('common.yes') : tHtml('common.no')}</span>;
        },
      },
      {
        Header: t('table.bonus_system.amount'),
        sortType: 'string',
        accessor: 'amount',
        maxWidth: 20,
        Cell: (cell: Cell<BonusSystemListResponseDto>) => {
          if (cell.row.original.bonus_condition === BonusConditionEnum.PERCENTAGE) {
            return <div>{tHtml('common.percents', { value: cell.row.original.percentage })}</div>;
          } else if (cell.row.original.bonus_condition === BonusConditionEnum.AMOUNT) {
            return <div>{tHtml('common.money', { value: cell.row.original.amount })}</div>;
          }
        },
      },
      {
        Header: t('table.bonus_system.expire_at'),
        accessor: 'expire_at',
        width: 120,
        Cell: (cell: Cell<BonusSystemListResponseDto>) => (
          <div>{cell.value ? tHtml('common.date', { date: cell.value }) : '-'}</div>
        ),
      },
    ],
    [t, tHtml],
  );

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      return Api.investor.bonus_system.fetchFilteredBonusSystem(request).then((response) => {
        setData(response);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  );

  return (
    <div className={'bonus-system-page mb-6'}>
      <div className={'back-button-after-page-header'}>
        <Link to={RouteList.INVESTOR.AFFILIATE.VIEW}>
          <FunctionalButton>{tHtml('common.back')}</FunctionalButton>
        </Link>
      </div>
      <PageHeader title={tHtml('bonus_system.my_active_bonuses.title')}>
        <Link to={RouteList.INVESTOR.PROJECTS.LIST}>
          <PrimaryButton>{t('common.invest')}</PrimaryButton>
        </Link>
      </PageHeader>
      <div>
        <Table
          columns={columns}
          data={data}
          onFetchData={fetchData}
          initialSortBy={INITIAL_SORT_ORDER}
          searchable={false}
          enableQueryFilter={false}
          mobile={true}
        />
      </div>
    </div>
  );
};

export default BonusSystem;
