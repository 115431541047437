import { defaultOptions, UseQueryOptionsObject } from '../queries.utils';
import { useMakeQuery } from '../useMakeQuery';
import { queries } from '../index';
import Api from '../../index';
import { CompanyProfileResponseDto } from '../../types/shared/company/profile';
import useScope from 'helpers/useScope';

const useUserCompanyProfileQuery = (options?: UseQueryOptionsObject<CompanyProfileResponseDto>) => {
  const { isCompany } = useScope();

  return useMakeQuery<CompanyProfileResponseDto>(
    [queries.profile],
    () => Api.shared.company.profile.fetchCompanyProfile(),
    {
      staleTime: 120 * 1000,
      enabled: isCompany(),
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useUserCompanyProfileQuery };
