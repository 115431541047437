import React, { useRef } from 'react';

import { useScrollToOnError } from 'helpers/useScrollToOnError';

interface ScrollToOnErrorProps {
  name: string;
  isError?: boolean;
}

const ScrollToOnError: React.FC<ScrollToOnErrorProps> = ({ name, isError, children }) => {
  const targetRef = useRef<HTMLDivElement>(null);
  useScrollToOnError(targetRef, name);

  return (
    <div className={isError ? ' is-invalid' : ''} ref={targetRef}>
      {children}
    </div>
  );
};

ScrollToOnError.defaultProps = {
  isError: false,
};

export default ScrollToOnError;
