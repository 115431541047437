import { create } from 'zustand';
import { ScopeEnum } from '../helpers/enums/ScopeEnum';
import * as H from 'history';

interface AppState {
  globalLoading: boolean;
  isImpersonated: boolean;
  isQuietImpersonated: boolean;
  setGlobalLoading: (globalLoading: boolean) => void;
  setIsImpersonated: (isImpersonated: boolean) => void;
  setIsQuietImpersonated: (isQuietImpersonated: boolean) => void;
  isSwitchingAccount: boolean;
  setIsSwitchingAccount: (isSwitchingAccount: boolean) => void;
  isAppStarted: boolean;
  setAppStarted: (isAppLoading: boolean) => void;
  initialRoute: H.Location | null;
  setInitialRoute: (route: H.Location) => void;

  scope: ScopeEnum;
  setScope: (scope: ScopeEnum) => void;
}

const useAppState = create<AppState>()((set) => ({
  globalLoading: false,
  setGlobalLoading: (globalLoading) => set({ globalLoading }),
  setIsImpersonated: (isImpersonated) => set({ isImpersonated: isImpersonated }),
  isImpersonated: false,
  setIsQuietImpersonated: (isQuietImpersonated) =>
    set({ isQuietImpersonated: isQuietImpersonated }),
  isQuietImpersonated: false,
  isSwitchingAccount: false,
  setIsSwitchingAccount: (isSwitchingAccount) => set({ isSwitchingAccount }),
  isAppStarted: false,
  setAppStarted: (isAppLoading) => set({ isAppStarted: isAppLoading }),
  initialRoute: null,
  setInitialRoute: (route) => set({ initialRoute: route }),

  scope: ScopeEnum.Unknown,
  setScope: (scope) => set({ scope }),
}));

export default useAppState;
