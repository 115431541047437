import React from 'react';
import useTranslation from 'helpers/useTranslation';

import { ProjectLoanActivityStatus } from 'helpers/enums/ProjectLoanActivityStatus';

type Props = {
  type: ProjectLoanActivityStatus;
};

const ProjectLoanActivityStatusBadge: React.FC<Props> = ({ type }) => {
  const { tHtml } = useTranslation();

  const mapIcon = (type: ProjectLoanActivityStatus) => {
    switch (type) {
      case ProjectLoanActivityStatus.Active:
        return 'success';
      case ProjectLoanActivityStatus.Inactive:
        return 'warning';
      case ProjectLoanActivityStatus.Late:
        return 'danger';
    }
  };

  return (
    <div className={`status-${type} d-flex flex-nowrap align-items-center`}>
      <i className={`icon icon-status-${mapIcon(type)} me-2`} />
      {tHtml(`secondary_market.status.${type}`)}
    </div>
  );
};

export default ProjectLoanActivityStatusBadge;
