import React, { ReactElement, useEffect } from 'react';
import useTranslation from 'helpers/useTranslation';
import { ErrorMessage, useField } from 'formik';

import { NumberInput } from 'components/Inputs';

interface Props {
  name: string;
  label: string | ReactElement;
  tax?: number;
  max?: number;
}

const defaultProps = {
  tax: 0,
  max: undefined,
};

const TransferBlockFormik: React.FC<Props> = (props) => {
  const { tHtml, currentLanguage } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const isError = meta?.touched && !!meta?.error;

  const getTotalAmount = () => {
    return field.value + props.tax!;
  };

  return (
    <div className={'transfer-block'}>
      <NumberInput
        label={props.label}
        name={props.name}
        value={field.value}
        onChange={(val) => {
          helper.setValue(val);
          helper.setTouched(true);
        }}
        isHighlight={true}
        isError={isError}
        max={props.max}
      />
      {isError && (
        <div className={'invalid-feedback text-pre'}>
          {props.name && (
            <ErrorMessage
              component={'div'}
              name={props.name}
              render={(msg: string | string[]) => {
                if (typeof msg === 'string') {
                  return msg;
                }

                return msg.join('\n');
              }}
            />
          )}
        </div>
      )}
      <div className={'transfer-block-overview'}>
        <div className={'wallet-overview-data-block'}>
          <div className={'title'}>{tHtml('common.tax')}</div>
          <div className={'data'}>{tHtml('common.money', { value: props.tax })}</div>
        </div>
        <div className={'wallet-overview-data-block'}>
          <div className={'title'}>{tHtml('common.total')}</div>
          <div className={'data'}>{tHtml('common.money', { value: getTotalAmount() })}</div>
        </div>
      </div>
    </div>
  );
};

TransferBlockFormik.defaultProps = defaultProps;
export default TransferBlockFormik;
