import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { RouteList } from 'routes';
import PrimaryButton from 'components/Formik/PrimaryButton';

const RegistrationSuccess: React.FC<RouteComponentProps> = () => {
  const { tHtml } = useTranslation();

  return (
    <React.Fragment>
      <div className={'top-title d-flex flex-row mb-4'}>
        <h4>{tHtml('authentication.registration_success')}</h4>
      </div>
      <div className={'mb-4'}>
        <Link to={RouteList.HOME}>
          <PrimaryButton>{tHtml('common.start')}</PrimaryButton>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default withRouter(RegistrationSuccess);
