import React from 'react';
import Tooltip from 'components/Tooltip';
import { RouteList } from 'routes';
import { Link } from 'react-router-dom';
import WalletAmount from 'components/Wallet/WalletAmount';
import useVerifiedWallet from 'helpers/useVerifiedWallet';

export const WalletButton: React.FC = () => {
  const { isWalletVerified, errorMessage } = useVerifiedWallet();

  return (
    <>
      <div id={'wallet-button'} className={'flex-center'}>
        {isWalletVerified ? (
          <Link to={RouteList.SHARED.WALLET.OVERVIEW} className={'site btn btn-functional'}>
            <WalletAmount loaderSize={'sm'} />
          </Link>
        ) : (
          <div className={`disabled site btn btn-functional`}>
            <WalletAmount loaderSize={'sm'} />
          </div>
        )}
      </div>
      {errorMessage && <Tooltip id={'wallet-button'} text={errorMessage} />}
    </>
  );
};

export default WalletButton;
