import Client from '../client';
import Http from '../../http';
import {
  QuizAgreedToAlertRequestDto,
  QuizAnswersRequestDto,
  QuizAnswersResponseDto,
  QuizResponseDto,
} from '../../types/investor/quiz';
import { SignUploadRequestDto } from '../../types/app';

class InvestorQuiz extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      INVESTOR_QUIZ: this.protectedUrl(`${this.http.baseUrl}/investor/quiz`),
      INVESTOR_QUIZ_ANSWERS: this.protectedUrl(`${this.http.baseUrl}/investor/quiz/answers`),
      INVESTOR_QUIZ_NEW: this.protectedUrl(`${this.http.baseUrl}/investor/quiz/new`),
      INVESTOR_QUIZ_ANSWERS_DRAFT: this.protectedUrl(`${this.http.baseUrl}/investor/quiz/draft`),
      INVESTOR_AUDIT_RISK_AGREEMENT: this.protectedUrl(
        `${this.http.baseUrl}/investor/quiz/risk-agreement`,
      ),
      UPLOAD_INVESTOR_QUIZ: this.protectedUrl(
        `${this.http.baseUrl}/investor/quiz/expertise-documents/upload`,
      ),
    };
  }

  fetchInvestorQuiz = (): Promise<QuizResponseDto> => {
    return this.http.get(this.api.INVESTOR_QUIZ);
  };

  storeInvestorQuizAnswers = (payload: QuizAnswersRequestDto): Promise<QuizAnswersResponseDto> => {
    return this.http.post(this.api.INVESTOR_QUIZ_ANSWERS, payload);
  };

  storeInvestorQuizAnswersDraft = (
    payload: QuizAnswersRequestDto,
  ): Promise<QuizAnswersResponseDto> => {
    return this.http.post(this.api.INVESTOR_QUIZ_ANSWERS_DRAFT, payload);
  };

  fetchInvestorQuizAnswers = (): Promise<QuizAnswersResponseDto> => {
    return this.http.get(this.api.INVESTOR_QUIZ_ANSWERS);
  };

  fetchInvestorQuizAnswersNew = (): Promise<QuizAnswersResponseDto> => {
    return this.http.get(this.api.INVESTOR_QUIZ_NEW);
  };

  storeInvestorQuizRiskAgreement = (payload: QuizAgreedToAlertRequestDto): Promise<any> => {
    return this.http.post(this.api.INVESTOR_AUDIT_RISK_AGREEMENT, payload);
  };

  uploadInvestorQuizDocuments = (request: SignUploadRequestDto): Promise<any> => {
    return this.http.post(this.api.UPLOAD_INVESTOR_QUIZ, request);
  };
}

export default InvestorQuiz;
