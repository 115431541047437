import React from 'react';
import useTranslation from 'helpers/useTranslation';
import TextAreaInput from 'components/Formik/TextAreaInput';

export const OtherFormSection = () => {
  const { t, tHtml } = useTranslation();

  return (
    <>
      <h2 className="mt-5 mb-4">{tHtml('prospect_application.other_details')}</h2>
      <div className="mb-3">
        <TextAreaInput
          name={'other.loan_details_others'}
          placeholder={t('placeholder.prospect_application.other.loan_details_others')}
        />
      </div>
    </>
  );
};
