import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { action } from 'typesafe-actions';

import Api from 'api';
import { StoreApplicationDevelopmentRequestDto } from 'api/types/project-developer/application';
import { setGlobalLoading } from 'modules/app/actions';
import { PrimaryButton } from 'components/Formik';
import { DatepickerInput } from 'components/Formik/Inputs';
import TextInput from 'components/Formik/TextInput';
import { ApplicationContext } from './module/ApplicationContext';
import { UPDATE_APPLICATION } from './module/ApplicationReducer';
import { ApplicationPageProps } from './steps';
import { redirect } from './CreateApplication';
import Tooltip from 'components/Tooltip';

interface IForm {
  project_address: string;
  object_name: string;
  project_status: string;
  start_construction_at: string;
  end_construction_at: string;
  number_of_re: string;
  total_area: string;
  useful_area: string;
}

const Development: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { state, setApplicationId, dispatch } = useContext(ApplicationContext);

  useEffect(() => {
    if (applicationId !== undefined) {
      setApplicationId(applicationId);
    }

    setGlobalLoading(false);
  }, [t, applicationId, setApplicationId]);

  const [initialFormValues, setInitialFormValues] = useState<IForm>({
    project_address: '',
    object_name: '',
    project_status: '',
    start_construction_at: '',
    end_construction_at: '',
    number_of_re: '',
    total_area: '',
    useful_area: '',
  });

  useEffect(() => {
    setInitialFormValues({
      project_address: String(state.application?.development?.project_address ?? ''),
      object_name: String(state.application?.development?.object_name ?? ''),
      project_status: String(state.application?.development?.project_status ?? ''),
      start_construction_at: String(state.application?.development?.start_construction_at ?? ''),
      end_construction_at: String(state.application?.development?.end_construction_at ?? ''),
      number_of_re: String(state.application?.development?.number_of_re ?? ''),
      total_area: String(state.application?.development?.total_area ?? ''),
      useful_area: String(state.application?.development?.useful_area ?? ''),
    });
  }, [state]);

  const CreateApplicationSchema = Yup.object().shape({
    project_address: Yup.string().min(1).max(255).required(),
    object_name: Yup.string().min(1).max(255),
    project_status: Yup.string().min(1).max(255).required(),
    start_construction_at: Yup.date().required(),
    end_construction_at: Yup.date(),
    number_of_re: Yup.number().min(0),
    total_area: Yup.number().min(0),
    useful_area: Yup.number().min(0),
  });

  const formatApplicationDevelopmentStoreRequest = useCallback(
    (request: IForm): StoreApplicationDevelopmentRequestDto => {
      return {
        project_address: request.project_address,
        object_name: request.object_name,
        project_status: request.project_status,
        start_construction_at: request.start_construction_at,
        end_construction_at: request.end_construction_at,
        number_of_re: Number(request.number_of_re),
        total_area: Number(request.total_area),
        useful_area: Number(request.useful_area),
      } as StoreApplicationDevelopmentRequestDto;
    },
    [],
  );

  const onSubmit = useCallback(
    async (request: IForm, helpers: FormikHelpers<IForm>) => {
      try {
        const applicationDevelopmentRequest: StoreApplicationDevelopmentRequestDto =
          formatApplicationDevelopmentStoreRequest(request);

        await Api.projectDeveloper.applications
          .storeApplicationDevelopment(applicationId, applicationDevelopmentRequest)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [applicationId, dispatch, formatApplicationDevelopmentStoreRequest, history, nextPage],
  );

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          <h1 className="mb-4 mb-md-6">{tHtml('application.application_development')}</h1>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormValues}
            validationSchema={CreateApplicationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="mb-3">
                    <TextInput
                      name={'project_address'}
                      placeholder={t('placeholder.application.project_address')}
                    />
                  </div>
                  <div className="mb-3">
                    <Tooltip
                      id={'development-application-project-address'}
                      text={tHtml('placeholder.application.object_name_tooltip')}
                    />
                    <TextInput
                      id={'development-application-project-address'}
                      name={'object_name'}
                      placeholder={t('placeholder.application.object_name')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      name={'project_status'}
                      placeholder={t('placeholder.application.project_status')}
                    />
                  </div>
                  <div className="mb-3">
                    <DatepickerInput
                      hasFutureRange={true}
                      name={'start_construction_at'}
                      label={t('placeholder.application.start_construction_at')}
                    />
                  </div>
                  <div className="mb-3">
                    <DatepickerInput
                      hasFutureRange={true}
                      name={'end_construction_at'}
                      label={t('placeholder.application.end_construction_at')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      name={'number_of_re'}
                      type={'number'}
                      placeholder={t('placeholder.application.number_of_re')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      name={'total_area'}
                      type={'number'}
                      placeholder={t('placeholder.application.total_area')}
                    />
                  </div>
                  <div className="mb-3">
                    <TextInput
                      name={'useful_area'}
                      type={'number'}
                      placeholder={t('placeholder.application.useful_area')}
                    />
                  </div>
                  <hr />
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <PrimaryButton
                      className={'btn btn-grey w-25'}
                      type={'button'}
                      onClick={() => {
                        redirect(history, previousPage, applicationId);
                      }}
                    >
                      {tHtml('common.back')}
                    </PrimaryButton>
                    <PrimaryButton className={'btn btn-primary w-25'} submitting={isSubmitting}>
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </FormGroup>
              </Form>
            )}
          </Formik>
        </div>
      </Row>
    </>
  );
};

export default withRouter(Development);
