import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import * as Yup from 'yup';
import { action } from 'typesafe-actions';
import Api from 'api';
import { setGlobalLoading } from 'modules/app/actions';
import { PrimaryButton, SelectBox } from 'components/Formik';
import TextInput from 'components/Formik/TextInput';
import SelectInput, { SelectOption } from 'components/Formik/SelectInput';
import { ApplicationTypeEnum } from '../../helpers/enums/ApplicationTypeEnum';
import { ApplicationInterestFrequencyEnum } from '../../helpers/enums/ApplicationInterestFrequencyEnum';
import { ApplicationContext } from './module/ApplicationContext';
import { INITIALIZE_APPLICATION, UPDATE_APPLICATION } from './module/ApplicationReducer';
import { ApplicationPageProps } from './steps';
import SelectCreditDurationInput from 'components/Formik/Selects/SelectCreditDurationInput';
import { DatepickerInput, PhoneNumberInput } from 'components/Formik/Inputs';
import TextAreaInput from 'components/Formik/TextAreaInput';
import { SelectCountryInput } from 'components/Formik/Selects';
import { redirect } from 'scopes/guest/helpers/utils';
import Loader from 'components/Loader';
import YesNoSelector from 'components/Formik/YesNoSelector';

interface IForm {
  prospect_user: {
    name: string;
    email: string;
    phone: string;
  };
  prospect_application: {
    has_sales_manager: boolean;
    sales_manager_id: string | null;
    country: string;
    credit_purpose: ApplicationTypeEnum[];
    project_description: string;
    required_amount: string;
    minimal_amount: string;
    maximum_amount: string | null;
    own_funds_amount: string;
    own_funds_origin: string;
    interest_frequency: string;
    credit_duration: number;
    source_of_funds: string;
    complete_funding_until: string | null;
  };
}

export type UserBriefResponseDto = {
  id: string;
  name: string | null;
};

const CreateApplication: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { uuid } = useParams<{ uuid: string }>();
  const { state, setUuid, dispatch } = useContext(ApplicationContext);
  const isLoading = uuid && !state.application;

  useEffect(() => {
    if (uuid == undefined) {
      setUuid(null);
      dispatch(action(INITIALIZE_APPLICATION));
    } else {
      setUuid(uuid);
    }

    setGlobalLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const [applicationInterestFrequencies] = useState<SelectOption[]>(() =>
    Object.values(ApplicationInterestFrequencyEnum).map((value) => {
      return {
        label: t(`placeholder.interest_frequency.${value}`),
        value: value,
      };
    }),
  );

  const [salesManagerOptions, setSalesManagerOptions] = useState<SelectOption[]>([]);

  const fetchSalesManagers = useCallback(async () => {
    const response = await Api.guest.prospect_application.fetchSalesManagers();
    return response.map((salesManager: UserBriefResponseDto) => ({
      value: salesManager.id ?? '',
      label: salesManager.name ?? '',
    }));
  }, []);

  useEffect(() => {
    const loadSalesManagers = async () => {
      const options = await fetchSalesManagers();
      setSalesManagerOptions(options);
    };

    loadSalesManagers();
  }, [fetchSalesManagers]);

  const [initialFormValues, setInitialFormValues] = useState<IForm>({
    prospect_user: {
      name: '',
      email: '',
      phone: '',
    },
    prospect_application: {
      has_sales_manager: false,
      sales_manager_id: null,
      country: '',
      credit_purpose: [],
      project_description: '',
      required_amount: '',
      minimal_amount: '',
      maximum_amount: null,
      own_funds_amount: '',
      own_funds_origin: '',
      interest_frequency: '',
      credit_duration: 6,
      source_of_funds: '',
      complete_funding_until: '',
    },
  });

  useEffect(() => {
    if (uuid != undefined)
      setInitialFormValues({
        prospect_user: {
          name: String(state.application?.prospect_user?.name ?? ''),
          email: String(state.application?.prospect_user?.email ?? ''),
          phone: String(state.application?.prospect_user?.phone ?? ''),
        },
        prospect_application: {
          has_sales_manager: state.application?.prospect_application?.has_sales_manager ?? true,
          sales_manager_id: state.application?.prospect_application?.sales_manager_id ?? null,
          country: state.application?.prospect_application?.country ?? '',
          credit_purpose: state.application?.prospect_application?.credit_purpose ?? [],
          project_description: state.application?.prospect_application?.project_description ?? '',
          required_amount: String(state.application?.prospect_application?.required_amount ?? ''),
          minimal_amount: String(state.application?.prospect_application?.minimal_amount ?? ''),
          maximum_amount: String(state.application?.prospect_application?.maximum_amount ?? ''),
          own_funds_amount: String(state.application?.prospect_application?.own_funds_amount ?? ''),
          own_funds_origin: state.application?.prospect_application?.own_funds_origin ?? '',
          interest_frequency: String(
            state.application?.prospect_application?.interest_frequency ?? '',
          ),
          credit_duration: state.application?.prospect_application?.credit_duration ?? 6,
          source_of_funds: state.application?.prospect_application?.source_of_funds ?? '',
          complete_funding_until: String(
            state.application?.prospect_application?.complete_funding_until ?? '',
          ),
        },
      });
  }, [state, uuid]);

  const CreateApplicationSchema = Yup.object().shape({
    prospect_user: Yup.object().shape({
      name: Yup.string().fullName().required(),
      email: Yup.string().email(t('validation.invalid')).required(),
      phone: Yup.string().phone().required(),
    }),
    prospect_application: Yup.object().shape({
      sales_manager_id: Yup.string().nullable(),
      country: Yup.string().required(),
      credit_purpose: Yup.array()
        .min(1)
        .of(
          Yup.string()
            .oneOf(Object.values(ApplicationTypeEnum).map((value) => value))
            .required(),
        )
        .required(),
      project_description: Yup.string().required().maxLengthLongText(),
      required_amount: Yup.number().min(0).required(),
      minimal_amount: Yup.number()
        .lessThanOrEqualField('required_amount', t('placeholder.application.required_amount'))
        .min(0)
        .required(),
      maximum_amount: Yup.number().nullable(),
      own_funds_amount: Yup.number().min(0).required(),
      own_funds_origin: Yup.string().max(255).required(),
      interest_frequency: Yup.string()
        .oneOf(Object.values(ApplicationInterestFrequencyEnum).map((value) => value))
        .required(),
      credit_duration: Yup.number().min(1).max(120).required(),
      source_of_funds: Yup.string().min(1).max(255).required(),
      complete_funding_until: Yup.date().min(new Date()),
    }),
  });

  const formatApplicationStoreRequest = useCallback((request: IForm) => {
    return {
      prospect_user: {
        name: request.prospect_user.name,
        email: request.prospect_user.email,
        phone: request.prospect_user.phone,
      },
      prospect_application: {
        country: request.prospect_application.country,
        credit_purpose: request.prospect_application.credit_purpose,
        project_description: request.prospect_application.project_description,
        required_amount: Number(request.prospect_application.required_amount),
        minimal_amount: Number(request.prospect_application.minimal_amount),
        maximum_amount: request.prospect_application.maximum_amount
          ? Number(request.prospect_application.maximum_amount)
          : null,
        own_funds_amount: Number(request.prospect_application.own_funds_amount),
        own_funds_origin: request.prospect_application.own_funds_origin,
        interest_frequency: request.prospect_application.interest_frequency,
        credit_duration: request.prospect_application.credit_duration,
        source_of_funds: request.prospect_application.source_of_funds,
        complete_funding_until: request.prospect_application.complete_funding_until,
        has_sales_manager: request.prospect_application.has_sales_manager,
        sales_manager_id: request.prospect_application.sales_manager_id,
      },
    };
  }, []);

  const onSubmit = useCallback(
    async (request: IForm, helpers: FormikHelpers<IForm>) => {
      try {
        const applicationRequest = formatApplicationStoreRequest(request);
        if (uuid == undefined) {
          await Api.guest.prospect_application
            .storeApplication(applicationRequest)
            .then((response) => {
              dispatch(action(UPDATE_APPLICATION, response));
              setUuid(response.prospect_application.uuid);
              redirect(history, nextPage, response.prospect_application.id);
            });
        } else {
          if (state.application?.prospect_application.id) {
            await Api.guest.prospect_application
              .updateApplication(state.application.prospect_application.id, applicationRequest)
              .then((response) => {
                dispatch(action(UPDATE_APPLICATION, response));
                state.application?.prospect_application.id &&
                  redirect(history, nextPage, state.application.prospect_application.id);
              });
          }
        }
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatApplicationStoreRequest, uuid, nextPage, history, dispatch, setUuid],
  );

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          {!isLoading ? (
            <Formik
              enableReinitialize={true}
              initialValues={initialFormValues}
              validationSchema={CreateApplicationSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div className="mb-4">
                      <YesNoSelector
                        name={'prospect_application.has_sales_manager'}
                        placeholder={tHtml('placeholder.sales_manager')}
                      />
                    </div>
                    {values.prospect_application?.has_sales_manager && (
                      <div className="mb-4">
                        <SelectInput
                          name={'prospect_application.sales_manager_id'}
                          options={salesManagerOptions}
                          placeholder={t('placeholder.sales_manager')}
                          onChange={(option: any) => {
                            setFieldValue('sales_manager_id', option?.value);
                          }}
                        />
                      </div>
                    )}
                    <h2 className="mb-4">{tHtml('prospect_application.contact')}</h2>
                    <div className="mb-4">
                      <TextInput name={'prospect_user.name'} placeholder={t('placeholder.name')} />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'prospect_user.email'}
                        placeholder={t('placeholder.email')}
                      />
                    </div>
                    <div className={'mb-4'}>
                      <PhoneNumberInput
                        name={'prospect_user.phone'}
                        label={t('placeholder.phone')}
                      />
                    </div>
                    <h2 className="mb-4">{tHtml('prospect_application.credit')}</h2>
                    <div className="mb-4">
                      <SelectBox
                        defaultValue={undefined}
                        selectOptions={Object.values(ApplicationTypeEnum).map((value) => {
                          return {
                            label: t(`prospect_application.${value}`),
                            value: value,
                          };
                        })}
                        name={'prospect_application.credit_purpose'}
                        setFieldValue={setFieldValue}
                        closeMenuOnSelect={false}
                      />
                    </div>
                    <div className="mb-1">
                      <TextAreaInput
                        name="prospect_application.project_description"
                        placeholder={t('placeholder.prospect_application.project_description')}
                      />
                    </div>
                    <div className="mb-4">
                      <SelectCountryInput
                        name="prospect_application.country"
                        placeholder={t('placeholder.prospect_application.project_country')}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'prospect_application.required_amount'}
                        type={'number'}
                        placeholder={t('placeholder.application.required_amount')}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'prospect_application.minimal_amount'}
                        type={'number'}
                        placeholder={t('placeholder.prospect_application.minimal_amount')}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'prospect_application.maximum_amount'}
                        type={'number'}
                        placeholder={t('placeholder.prospect_application.maximum_amount')}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'prospect_application.own_funds_amount'}
                        type={'number'}
                        placeholder={t('placeholder.application.own_funds_amount')}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'prospect_application.own_funds_origin'}
                        placeholder={t('placeholder.application.own_funds_origin')}
                      />
                    </div>
                    <div className="mb-4">
                      <SelectInput
                        name={'prospect_application.interest_frequency'}
                        options={applicationInterestFrequencies}
                        placeholder={tHtml('placeholder.application.interest_frequency')}
                      />
                    </div>
                    <div className="mb-4">
                      <SelectCreditDurationInput
                        name={'prospect_application.credit_duration'}
                        placeholder={t('placeholder.application.credit_duration')}
                      />
                    </div>
                    <div className="mb-4">
                      <TextInput
                        name={'prospect_application.source_of_funds'}
                        placeholder={t('placeholder.application.source_of_funds')}
                      />
                    </div>
                    <div className="mb-4">
                      <DatepickerInput
                        name={'prospect_application.complete_funding_until'}
                        label={t('placeholder.prospect_application.complete_funding_until')}
                        hasFutureRange={true}
                      />
                    </div>
                    <hr />
                    <div className={'d-flex justify-content-end align-items-center'}>
                      <PrimaryButton className={'btn btn-primary w-25'} submitting={isSubmitting}>
                        {tHtml('common.continue')}
                      </PrimaryButton>
                    </div>
                  </FormGroup>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default withRouter(CreateApplication);
