import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';

export const StyledSidebar = styled.div`
  width: 18rem;
  z-index: 950;
  bottom: 0;
  margin-top: 3rem;
  position: -webkit-sticky; /* for Safari users */
  position: sticky;
  float: left;
  top: 0;

  ${breakpointDown.LG} {
    width: 45%;
    margin-top: 0;
    transition: 0.5s;
    right: 0;
    position: fixed;
    background-color: var(--profitus-bg-color-1);
    border-radius: 0.5rem 0 0 0.5rem;
    box-shadow: 0 0 1rem 0.5rem rgb(0 0 0 / 12%);
    padding-left: 0;
    height: auto;
    outline: none;
    z-index: 100;

    &.hide-sidebar {
      transition: 0.5s;
      width: 0;
    }
  }

  ${breakpointDown.MD} {
    width: 45%;
  }

  ${breakpointDown.SM} {
    width: 67%;
  }

  ${breakpointDown.XSM} {
    width: 85%;
  }
`;
