import Echo, { Channel } from 'laravel-echo';
import Pusher from 'pusher-js';
import { AuthUtils } from './useAuth/Auth.utils';
import { default as env } from 'env.json';

class LaravelPusher {
  echo: Echo;
  channel: Channel;

  constructor(channel: string, event: string, callback: (data: any) => void) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pusher = Pusher;

    this.echo = new Echo({
      broadcaster: 'pusher',
      key: env.PUSHER_APP_KEY,
      cluster: env.PUSHER_APP_CLUSTER,
      forceTLS: true,
      authEndpoint: env.BASE_DEFAULT_URL + '/broadcasting/auth',
      auth: {
        headers: {
          Authorization: 'Bearer ' + AuthUtils.getJwt().token,
        },
      },
    });

    this.channel = this.echo.private(channel);
    this.channel.listen(event, callback);
  }

  stopListening(event: string) {
    this.channel.stopListening(event);
    this.echo.leaveAllChannels();
    this.echo.disconnect();
  }
}

export default LaravelPusher;
