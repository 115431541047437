import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormGroup, Row } from 'reactstrap';
import { action } from 'typesafe-actions';
import * as Yup from 'yup';
import Api from 'api';
import { CheckboxInput, PrimaryButton } from 'components/Formik';
import transformErrors from 'helpers/formik';
import { redirect } from 'scopes/guest/helpers/utils';
import { CompanyDetailsDto } from 'api/types/guest/prospectApplication';
import Loader from 'components/Loader';
import YesNoSelector from 'components/Formik/YesNoSelector';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ShareholdersFormBlock from './ShareholdersFormBlock';
import BeneficiariesFormBlock from './BeneficiariesFormBlock';
import PepsFormBlock from './PepsFormBlock';
import { ApplicationPageProps } from '../steps';
import { ApplicationContext } from '../module/ApplicationContext';
import { UPDATE_APPLICATION } from '../module/ApplicationReducer';

const ProspectCompanyBeneficiaries: React.FC<RouteComponentProps & ApplicationPageProps> = ({
  history,
  previousPage,
  nextPage,
}) => {
  const { t, tHtml } = useTranslation();
  const { applicationId } = useParams<{ applicationId: string }>();

  const { state, dispatch } = useContext(ApplicationContext);
  const [initialFormValues, setInitialFormValues] = useState<CompanyDetailsDto | null>(null);

  useEffect(() => {
    if (state.application?.prospect_application.id !== applicationId) return;
    const companyInfo = state.application?.prospect_application.company?.kyb_data;

    setInitialFormValues({
      management_structure: companyInfo?.management_structure ?? '',
      activities: companyInfo?.activities ?? [],
      other_activities_text: companyInfo?.other_activities_text ?? null,
      cash_usage: companyInfo?.cash_usage ?? '',
      countries_of_activities: companyInfo?.countries_of_activities ?? [],
      business_partners: companyInfo?.business_partners ?? '',
      non_performance_credit_agreement: companyInfo?.non_performance_credit_agreement ?? false,
      has_bankruptcy_procedure: companyInfo?.has_bankruptcy_procedure ?? false,
      is_not_operating_in_russia_belarus_ukraine_occupied_territories:
        companyInfo?.is_not_operating_in_russia_belarus_ukraine_occupied_territories ?? false,
      shareholders: companyInfo?.shareholders ?? [],
      beneficiaries: companyInfo?.beneficiaries ?? [],
      peps: companyInfo?.peps ?? [],
      has_shareholders_with_more_than_25_percent:
        companyInfo?.has_shareholders_with_more_than_25_percent ?? false,
      public_positions_held_by_related_individuals:
        companyInfo?.public_positions_held_by_related_individuals ?? false,
      convicted: companyInfo?.convicted ?? false,
    });
  }, [t, applicationId, state]);

  const onSubmit = useCallback(
    async (request: CompanyDetailsDto, helpers: FormikHelpers<CompanyDetailsDto>) => {
      try {
        await Api.guest.prospect_application
          .storeApplicationCompanyBeneficiariesDetails(applicationId, request)
          .then((response) => {
            dispatch(action(UPDATE_APPLICATION, response));
            redirect(history, nextPage, applicationId);
          });
      } catch (e) {
        helpers.setErrors(transformErrors(e.response?.errors));
      }
    },
    [applicationId, dispatch, history, nextPage],
  );

  const schema = Yup.object().shape({
    has_shareholders_with_more_than_25_percent: Yup.boolean().required(),
    shareholders: Yup.array().shareholderArray(),
    public_positions_held_by_related_individuals: Yup.boolean().required(),
    beneficiaries: Yup.array().beneficiaryArray(),
    peps: Yup.array().pepArray(),
    convicted: Yup.boolean().required(),
  });

  return (
    <>
      <Row>
        <div className={'col-12 col-md-10 col-lg-6 mx-auto'}>
          {initialFormValues ? (
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              initialValues={initialFormValues}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {(helpers) => (
                <Form onSubmit={helpers.handleSubmit}>
                  <FormGroup>
                    <h2 className={'mb-4'}>
                      {tHtml('prospect_application.has_shareholders_with_more_than_25_percent')}
                    </h2>
                    <div className="mb-4">
                      <YesNoSelector
                        name={'has_shareholders_with_more_than_25_percent'}
                        placeholder={t(
                          'placeholder.prospect_application.has_shareholders_with_more_than_25_percent',
                        )}
                      />
                    </div>
                    {helpers.values?.has_shareholders_with_more_than_25_percent === true && (
                      <>
                        <hr />
                        <h5 className="mb-4 mt-4">
                          {tHtml('placeholder.company.shareholders.specify')}
                        </h5>
                        <ShareholdersFormBlock
                          fieldName={'shareholders'}
                          values={helpers.values.shareholders}
                          formikHelpers={helpers}
                        />
                        <hr />
                      </>
                    )}
                    <h5 className="mb-4 mt-4">
                      {tHtml('placeholder.company.beneficiary.specify')}
                    </h5>
                    <BeneficiariesFormBlock
                      dependencyFieldName={'shareholders'}
                      selectorDisabledFieldName={'has_shareholders_with_more_than_25_percent'}
                      fieldName={'beneficiaries'}
                      values={helpers.values.beneficiaries}
                      formikHelpers={helpers}
                    />
                    <div className="mb-4">
                      <YesNoSelector
                        name={'public_positions_held_by_related_individuals'}
                        placeholder={t(
                          'placeholder.prospect_application.public_positions_held_by_related_individuals',
                        )}
                      />
                    </div>
                    {helpers.values?.public_positions_held_by_related_individuals === true && (
                      <>
                        <hr />
                        <PepsFormBlock
                          fieldName={'peps'}
                          values={helpers.values.peps}
                          formikHelpers={helpers}
                        />
                        <hr />
                      </>
                    )}
                    <div className="mb-4">
                      <CheckboxInput
                        name="convicted"
                        showPlaceholder={true}
                        placeholder={tHtml('placeholder.prospect_application.convicted')}
                      />
                    </div>
                  </FormGroup>
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <PrimaryButton
                      className={'btn btn-grey w-25'}
                      type={'button'}
                      onClick={() => {
                        redirect(history, previousPage, applicationId);
                      }}
                    >
                      {tHtml('common.back')}
                    </PrimaryButton>
                    <PrimaryButton
                      className={'btn btn-primary w-25'}
                      submitting={helpers.isSubmitting}
                    >
                      {tHtml('common.continue')}
                    </PrimaryButton>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="d-flex justify-content-center">
              <Loader />
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default withRouter(ProspectCompanyBeneficiaries);
