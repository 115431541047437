import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { PageHeader } from 'components/Header';
import SecondaryMarketPromotionBlock from '../components/SecondaryMarketPromotionBlock';
import SecondaryMarketInvestmentList from './components/SecondaryMarketInvestmentList';
import SecondaryMarketFAQ from '../components/SecondaryMarketFAQ';

const SecondaryMarketBuyer: React.FC = () => {
  const { tHtml } = useTranslation();

  return (
    <div className={'page-layout secondary-market-page'}>
      <PageHeader title={tHtml('secondary_market.title')} />
      <SecondaryMarketPromotionBlock />
      <SecondaryMarketInvestmentList />
      <SecondaryMarketFAQ />
    </div>
  );
};

export default withRouter(SecondaryMarketBuyer);
