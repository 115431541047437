import React, { useCallback, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import useTranslation from 'helpers/useTranslation';
import { Col, ColProps, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

import Api from 'api';
import { RouteList } from 'routes';
import {
  StyledPartialReturnForm,
  StyledPartialReturnFormCard,
  StyledPartialReturnFormSection,
  StyledPartialReturnFormSubmitSection,
  StyledPartialReturnFormSubmitSectionButton,
  StyledPartialReturnFormSubmitSectionTotal,
} from './PartialReturnForm.style';
import {
  StyledPartialReturnHeader,
  StyledPartialReturnHeaderWrapper,
  StyledPartialReturnLabel,
  StyledPartialReturnParagraph,
} from '../../PartialReturn.style';
import { PrimaryButton, SelectInput } from 'components/Formik';
import DataBlockLarge from 'components/DataBlock/DataBlockLarge/DataBlockLarge';
import { success } from 'services/toastr';
import { SelectOption } from 'components/Formik/SelectInput';
import { PartialReturnCalculationTypeEnum } from 'helpers/enums/PartialReturnCalculationTypeEnum';
import {
  ProjectDeveloperPartialReturnCalculationResponseDto,
  ProjectDeveloperPartialReturnProjectResponseDto,
  ProjectDeveloperPartialReturnRequestDto,
} from 'api/types/project-developer/payment';
import Loader from 'components/Loader';
import NumberInput from 'components/Formik/NumberInput';
import ConfirmationModal from 'components/Modal/Modals/ConfirmationModal';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';

type Props = {
  project: ProjectDeveloperPartialReturnProjectResponseDto;
};

const INITIAL_REQUEST: ProjectDeveloperPartialReturnRequestDto = {
  type: undefined,
  amount: 0,
};

const PartialReturnForm: React.FC<Props> = ({ project }) => {
  const { t, tHtml } = useTranslation();
  const history = useHistory();
  const { showModal } = useGlobalModalContext();

  const [partialReturnRequest] = useState<ProjectDeveloperPartialReturnRequestDto>(INITIAL_REQUEST);
  const [partialReturnCalculation, setPartialReturnCalculation] =
    useState<ProjectDeveloperPartialReturnCalculationResponseDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const partialReturnSchema = Yup.object().shape({
    type: Yup.string()
      .maxLength()
      .oneOf(Object.values(PartialReturnCalculationTypeEnum).map((value) => value))
      .required(),
    amount: Yup.number().money().required(),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(
    debounce((request: ProjectDeveloperPartialReturnRequestDto) => {
      if (request && partialReturnSchema.isValidSync(request)) {
        setIsLoading(true);
        Api.projectDeveloper.payments.fetchCalculation(project.id, request).then((response) => {
          setPartialReturnCalculation(response);
          setIsLoading(false);
        });
      }
    }, 300),
    [project.id],
  );

  const onSubmit = useCallback(
    async (
      request: ProjectDeveloperPartialReturnRequestDto,
      helpers: FormikHelpers<ProjectDeveloperPartialReturnRequestDto>,
    ) => {
      await Api.projectDeveloper.payments
        .payPartialReturn(project.id, request)
        .then(() => {
          success(tHtml('payments.partial_return.success'));
          history.push(RouteList.PROJECT_DEVELOPER.PAYMENTS.VIEW);
        })
        .catch((e) => {
          helpers.setErrors(e.response?.errors);
        });
    },
    [history, project.id, tHtml],
  );

  const options: SelectOption[] = [
    {
      label: t('payments.partial_return.type.' + PartialReturnCalculationTypeEnum.EXACT),
      value: PartialReturnCalculationTypeEnum.EXACT,
    },
    {
      label: t('payments.partial_return.type.' + PartialReturnCalculationTypeEnum.GUESS),
      value: PartialReturnCalculationTypeEnum.GUESS,
    },
  ];

  const colProps: ColProps = {
    lg: 6,
    sm: 12,
    className: 'mb-2',
  };

  return (
    <StyledPartialReturnForm>
      <StyledPartialReturnFormCard>
        <StyledPartialReturnHeaderWrapper>
          <StyledPartialReturnHeader>
            {tHtml('payments.partial_return.title')}
          </StyledPartialReturnHeader>
          {isLoading && <Loader />}
        </StyledPartialReturnHeaderWrapper>
        <Formik
          initialValues={partialReturnRequest}
          validationSchema={partialReturnSchema}
          onSubmit={async (
            request: ProjectDeveloperPartialReturnRequestDto,
            helpers: FormikHelpers<ProjectDeveloperPartialReturnRequestDto>,
          ) => {
            showModal(
              <ConfirmationModal
                title={tHtml('payments.partial_return.confirmation')}
                onSubmit={async () => {
                  await onSubmit(request, helpers);
                }}
              />,
            );

            return;
          }}
          enableReinitialize={true}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <StyledPartialReturnFormSection>
                <StyledPartialReturnLabel>
                  {tHtml('payments.partial_return.type')}
                </StyledPartialReturnLabel>
                <SelectInput
                  name={'type'}
                  options={options}
                  hideLabel={true}
                  placeholder={tHtml('payments.partial_return.type.select')}
                  onFieldChange={() => onChange(values)}
                  isDisabled={project.has_late_payouts}
                />
              </StyledPartialReturnFormSection>
              <StyledPartialReturnFormSection>
                <StyledPartialReturnLabel>
                  {tHtml('payments.partial_return.amount')}
                </StyledPartialReturnLabel>
                <NumberInput
                  name={'amount'}
                  hideLabel={true}
                  onFieldChange={() => onChange(values)}
                  disabled={project.has_late_payouts}
                />
              </StyledPartialReturnFormSection>
              <StyledPartialReturnFormSection>
                <StyledPartialReturnLabel>
                  {tHtml('payments.partial_return.calculation')}
                </StyledPartialReturnLabel>
                <StyledPartialReturnParagraph>
                  {tHtml('payments.partial_return.calculation.information')}
                </StyledPartialReturnParagraph>
              </StyledPartialReturnFormSection>
              <Row>
                <Col {...colProps}>
                  <DataBlockLarge
                    title={t('payments.partial_return.calculation.basic_interest')}
                    content={t('common.money', {
                      value: partialReturnCalculation?.basic_interest ?? 0,
                    })}
                  />
                </Col>
                <Col {...colProps}>
                  <DataBlockLarge
                    title={t('payments.partial_return.calculation.fine_on_pre_return')}
                    content={t('common.money', {
                      value: partialReturnCalculation?.fine_on_pre_return ?? 0,
                    })}
                  />
                </Col>
                <Col {...colProps}>
                  <DataBlockLarge
                    title={t('payments.partial_return.calculation.additional_interest')}
                    content={t('common.money', {
                      value: partialReturnCalculation?.additional_interest ?? 0,
                    })}
                  />
                </Col>
                <Col {...colProps}>
                  <DataBlockLarge
                    title={t('payments.partial_return.calculation.debt_fee')}
                    content={t('common.money', { value: partialReturnCalculation?.debt_fee ?? 0 })}
                  />
                </Col>
                <Col {...colProps}>
                  <DataBlockLarge
                    title={t('payments.partial_return.calculation.loan_repayment')}
                    content={t('common.money', {
                      value: partialReturnCalculation?.loan_repayment ?? 0,
                    })}
                  />
                </Col>
              </Row>
              <hr />
              <StyledPartialReturnFormSubmitSection>
                <StyledPartialReturnFormSubmitSectionTotal>
                  <DataBlockLarge
                    title={t('payments.partial_return.calculation.total')}
                    content={t('common.money', { value: partialReturnCalculation?.total ?? 0 })}
                    isInline={true}
                  />
                </StyledPartialReturnFormSubmitSectionTotal>
                <StyledPartialReturnFormSubmitSectionButton>
                  <PrimaryButton disabled={isSubmitting || isLoading || project.has_late_payouts}>
                    {tHtml('payments.partial_return.submit')}
                  </PrimaryButton>
                </StyledPartialReturnFormSubmitSectionButton>
              </StyledPartialReturnFormSubmitSection>
            </Form>
          )}
        </Formik>
      </StyledPartialReturnFormCard>
    </StyledPartialReturnForm>
  );
};

export default PartialReturnForm;
