import React from 'react';
import * as Roles from 'helpers/auth/roles';
import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import { ProfileLayout } from 'scopes/company/containers';
import Profile from '.';

const ProfileRoutes: any = () => [
  <AuthRoute
    path={RouteList.COMPANY.PROFILE.HOME}
    component={Profile}
    layout={ProfileLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.COMPANY.PROFILE.HOME}
    exact
  />,
];

export default ProfileRoutes;
