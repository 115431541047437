import { defaultOptions, UseQueryOptionsObject } from '../queries.utils';
import { useMakeQuery } from '../useMakeQuery';
import { queries } from '../index';
import Api from '../../index';
import { WalletInformationResponse } from '../../types/common';
import useUserType from '../../../helpers/useUserType';

const useWalletQuery = (options?: UseQueryOptionsObject<WalletInformationResponse>) => {
  const { isGuest } = useUserType();

  return useMakeQuery<WalletInformationResponse>(
    [queries.wallet],
    () => Api.wallet.fetchWalletInformation(),
    {
      staleTime: 60 * 1000,
      enabled: !isGuest(),
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useWalletQuery };
