import React, { ReactElement } from 'react';
import {
  StyledInfoBlock,
  StyledInfoBlockButtons,
  StyledInfoBlockDescription,
  StyledInfoBlockItems,
  StyledInfoBlockTitle,
} from './InfoBlock.style';
import Card from '../Card/Card';

interface Props {
  color?: 'default' | 'light-pink' | 'light-blue' | 'pink';
  title: string | ReactElement;
  description: string | ReactElement;
  className?: string;
}

const InfoBlock: React.FC<Props> = ({ color = 'default', title, description, children }) => {
  return (
    <Card color={color}>
      <StyledInfoBlockItems>
        <StyledInfoBlock>
          <StyledInfoBlockTitle>{title}</StyledInfoBlockTitle>
          <StyledInfoBlockDescription>{description}</StyledInfoBlockDescription>
        </StyledInfoBlock>
        <StyledInfoBlockButtons>{children}</StyledInfoBlockButtons>
      </StyledInfoBlockItems>
    </Card>
  );
};

export default InfoBlock;
