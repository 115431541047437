import { useEffect } from 'react';
import useDisplay from 'helpers/useDisplay';

const Chat = () => {
  const host = window.location.hostname;
  const { isApp } = useDisplay();

  const getPlaybookUuid = () => {
    switch (host) {
      case 'ss.profitus.ee':
        return '5e862248-0f9f-4895-ace9-998aa4e6b429';
      case 'ss.profitus.lt':
        return '352edcf9-0c85-48c8-943f-4e34d381a929';
      default:
        return 'df015075-bb6e-464b-bbcb-6a60c0304b8d';
    }
  };

  useEffect(() => {
    if (isApp) return;
    if (window.LeadBooster) {
      console.warn('Chat already exists');
    } else {
      window.LeadBooster = {
        q: [],
        on: function (n, h) {
          this.q.push({ t: 'o', n: n, h: h });
        },
        trigger: function (n) {
          this.q.push({ t: 't', n: n });
        },
      };

      const script = document.createElement('script');
      script.src = 'https://leadbooster-chat.pipedrive.com/assets/loader.js';
      script.async = true;
      document.body.appendChild(script);

      const configScript = document.createElement('script');
      configScript.innerHTML = `
        window.pipedriveLeadboosterConfig = {
          base: 'leadbooster-chat.pipedrive.com',
          companyId: 11992386,
          playbookUuid: '${getPlaybookUuid()}',
          version: 2
        };
      `;
      configScript.async = true;
      document.body.appendChild(configScript);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Chat;
