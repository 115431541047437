import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import ApplicationFund from 'scopes/developer/containers/PageLayout/ApplicationFund';
import Tooltip from 'components/Tooltip';
import Api from 'api';
import { useStatisticQuery } from 'api/queries/dashboard';
import Loader from 'components/Loader';

const DashboardHeader: React.FC = () => {
  const { tHtml } = useTranslation();

  const { data: dashboard, isLoading } = useStatisticQuery(
    ['meta'],
    Api.projectDeveloper.dashboard.fetchMeta,
  );

  return (
    <div>
      <Row className={'m-auto'}>
        <Card className={'investment mb-4'}>
          <div className={'dashboard-header'}>
            <div className={'h-fit dashboard-bg'}>
              <Col className={'d-block'}>
                <div className={'dashboard-col-1-div-upper dashboard-text'}>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {dashboard?.total_funding != null
                        ? tHtml('common.money', { value: dashboard.total_funding })
                        : '-'}
                    </>
                  )}
                </div>
                <div className={'dashboard-col-1-div-mid'}>
                  {tHtml('dashboard.total_funding_received')}
                  <i
                    className={'icon icon-info position-absolute'}
                    id={'total-funding-received-icon'}
                  />
                </div>
                <Tooltip
                  id={'total-funding-received-icon'}
                  text={tHtml('dashboard.developer.tooltip.total_funding_received')}
                />
                <div className={'item mb-4'}>
                  <ApplicationFund />
                </div>
              </Col>
              <Col className={'d-block'}>
                <div className={'dashboard-col-1-div-upper dashboard-text'}>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {dashboard?.loan_left != null
                        ? tHtml('common.money', { value: dashboard.loan_left })
                        : '-'}
                    </>
                  )}
                </div>
                <div className={'dashboard-col-1-div-mid'}>
                  {tHtml('dashboard.loan_left_to_return')}
                  <i
                    className={'icon icon-info position-absolute'}
                    id={'loan-left-to-return-icon'}
                  />
                </div>
                <Tooltip
                  id={'loan-left-to-return-icon'}
                  text={tHtml('dashboard.developer.tooltip.loan_left_to_return')}
                />
              </Col>
            </div>
            <div className={'dashboard-center'}>
              <Col className={'d-block mb-3'}>
                <div className={'dashboard-col-1-div-bottom dashboard-text'}>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {dashboard?.interest_left != null
                        ? tHtml('common.money', { value: dashboard.interest_left })
                        : '-'}
                    </>
                  )}
                </div>
                <div className={'fw-600'}>
                  {tHtml('dashboard.interest_left_to_return')}
                  <i
                    className={'icon icon-info position-absolute'}
                    id={'interest-left-to-return-icon'}
                  />
                </div>
                <Tooltip
                  id={'interest-left-to-return-icon'}
                  text={tHtml('dashboard.developer.tooltip.interest_left_to_return')}
                />
              </Col>
              <Col className={'d-block mb-3'}>
                <div className={'dashboard-col-1-div-bottom dashboard-text'}>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {dashboard?.average_annual_interest != null
                        ? tHtml('common.percents', { value: dashboard.average_annual_interest })
                        : '-'}
                    </>
                  )}
                </div>
                <div className={'fw-600'}>
                  {tHtml('dashboard.average_annual_interest')}
                  <i
                    className={'icon icon-info position-absolute'}
                    id={'average-annual-interest-icon'}
                  />
                </div>
                <Tooltip
                  id={'average-annual-interest-icon'}
                  text={tHtml('dashboard.developer.tooltip.average_annual_interest')}
                />
              </Col>
            </div>
          </div>
        </Card>
      </Row>
    </div>
  );
};

export default DashboardHeader;
