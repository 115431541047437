import jwt_decode from 'jwt-decode';
import { AuthJwtObject, JwtInterface, jwtStorageKey } from './Auth.types';
import { isAuthJwtObject } from './Auth.guards';
import { DataStorage } from '../dataStorage';
import { ScopeEnum } from '../../helpers/enums/ScopeEnum';

class AuthUtils {
  private static readonly defaultState: AuthJwtObject = {
    token: '',
    expires: 0,
  };

  public static getJwt = (): AuthJwtObject => {
    const jwt = DataStorage.get(jwtStorageKey);

    if (jwt === null) {
      return AuthUtils.defaultState;
    }

    const results = AuthUtils.make(jwt);

    if (results === null) {
      return AuthUtils.defaultState;
    }

    return results;
  };

  public static setJwt = (token: string | AuthJwtObject): void => {
    if (isAuthJwtObject(token)) {
      DataStorage.set(jwtStorageKey, token.token);
      return;
    }

    DataStorage.set(jwtStorageKey, token);
  };

  public static removeJwt = (): void => {
    DataStorage.remove(jwtStorageKey);
  };

  public static isExpired = (jwt?: AuthJwtObject): boolean => {
    return Date.now() > (jwt?.expires ?? AuthUtils.getJwt().expires);
  };

  public static getPayload = (): null | JwtInterface => {
    if (AuthUtils.isExpired()) {
      return null;
    }

    return AuthUtils.decode(AuthUtils.getJwt().token);
  };

  public static isImpersonated = (jwt?: AuthJwtObject): boolean => {
    const token = jwt ? jwt.token : AuthUtils.getJwt().token;

    return !!AuthUtils.decode(token)?.original_id;
  };

  public static getScope = (jwt?: AuthJwtObject): ScopeEnum => {
    const token = jwt ? jwt.token : AuthUtils.getJwt().token;

    const authObject: JwtInterface | null = AuthUtils.decode(token);

    if (authObject === undefined || authObject === null) {
      return ScopeEnum.Unknown;
    }

    if (authObject?.company_id) {
      return ScopeEnum.Company;
    }

    return ScopeEnum.User;
  };

  public static decode = (token: string): JwtInterface | null => {
    try {
      return jwt_decode<JwtInterface>(token);
    } catch (e) {
      console.error('Failed to decode', e);
    }

    return null;
  };

  public static make = (token: string): AuthJwtObject | null => {
    const decoded = AuthUtils.decode(token);

    if (!decoded) {
      return null;
    }

    return {
      token,
      expires: decoded.exp * 1000,
    };
  };
}

export { AuthUtils };
