import React from 'react';
import { RouteList } from '../../../routes';
import { DropdownItem } from 'reactstrap';
import {
  AuthenticatedAccountBriefResponseDto,
  RefreshAuthenticatedAccountResponseDto,
} from '../../../api/types/shared/authenticatedAccount';
import { useAuth } from '../../../services/useAuth';
import useAppState from '../../../modules/appState';
import { useStepsModalStateStore } from '../../investor/modals/RequiredSteps/store';
import SUITCASE_ICON from '../../../assets/images/icons/suitcase.svg';

type Props = {
  account: AuthenticatedAccountBriefResponseDto;
  onSwitch: (id: string, type: string) => Promise<RefreshAuthenticatedAccountResponseDto>;
  isCompany?: boolean;
  avatar?: string;
};

const SwitchAuthenticatedUser: React.FC<Props> = ({ account, onSwitch, isCompany, avatar }) => {
  const { login } = useAuth();
  const { setIsSwitchingAccount } = useAppState();
  const modalState = useStepsModalStateStore();

  const switchAccount = async () => {
    modalState.setShouldBeShown({ state: true });
    if (!account) {
      return;
    }

    try {
      setIsSwitchingAccount(true);
      const response = await onSwitch(account.id, account.type);

      await login(response.access_token, response.permissions);
    } finally {
      setIsSwitchingAccount(false);
    }
  };

  return (
    <DropdownItem
      className={'dropdown-item'}
      to={RouteList.HOME}
      onClick={async () => {
        await switchAccount();
      }}
    >
      {isCompany ? (
        <img src={SUITCASE_ICON} alt="Avatar" className={'company avatar'} />
      ) : (
        <img src={avatar} alt="Avatar" className={'avatar'} />
      )}
      {account.name}
    </DropdownItem>
  );
};

export default SwitchAuthenticatedUser;
