import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';

export const StyledMobileHeader = styled.header<{ top: string }>`
  width: 100%;
  top: ${(props) => props.top || '0'};
  position: fixed;
  z-index: 100;
`;

export const StyledMobileBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .path {
    font-size: 10px;
  }
`;

export const StyledMobileHeaderStart = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  a {
    margin-right: 0;
  }
`;

export const StyledMobileHeaderEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;

  .sidebar-toggler {
    padding: 0;
    border: none;
    font-size: 14px;
  }

  ${breakpointDown.LG} {
    width: 100%;
    gap: 1.5rem;
  }
`;
