import { defaultOptions, UseQueryOptionsObject } from '../queries.utils';
import { useMakeQuery } from '../useMakeQuery';
import Api from '../../index';
import { AuthenticatedAccountPermissionsResponseDto } from '../../types/shared/authenticatedAccount';

const usePermissionsQuery = (
  options?: UseQueryOptionsObject<AuthenticatedAccountPermissionsResponseDto>,
) => {
  return useMakeQuery<AuthenticatedAccountPermissionsResponseDto>(
    ['permissions'],
    () => Api.shared.authenticatedAccount.fetchPermissions(),
    {
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );
};

export { usePermissionsQuery };
