import styled from 'styled-components';

export const StyledDataBlock = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'row' : 'column')};
  gap: 0.75rem;
`;

export const StyledDataBlockLabel = styled.div<{ isMobile: boolean }>`
  color: var(--profitus-data-block-text-color);
  font-weight: 700;
  font-size: 0.688rem;
  width: ${(props) => (props.isMobile ? '45%' : 'auto')};
`;

export const StyledDataBlockValue = styled.div`
  color: var(--profitus-copy-data-block-title-color);
  font-size: 0.875rem;
`;
