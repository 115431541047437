import React from 'react';

import { RouteList } from 'routes';
import * as Roles from 'helpers/auth/roles';
import PageLayout from 'scopes/investor/containers/PageLayout';
import AuthRoute from 'routes/AuthRoute';
import { Dashboard } from '.';
import UpcomingPayments from '../Payouts/UpcomingPayments';
import LatePayments from '../Payouts/LatePayments';

const DashboardRoutes: any = () => [
  <AuthRoute
    path={RouteList.DASHBOARD}
    component={Dashboard}
    layout={PageLayout}
    permissions={Roles.User.permissions} // TODO only project-developer can access this route
    redirect={Roles.User.redirect}
    key={RouteList.DASHBOARD}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.PAYOUTS.UPCOMING_PAYMENTS}
    component={UpcomingPayments}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.INVESTOR.PAYOUTS.UPCOMING_PAYMENTS}
    exact
  />,
  <AuthRoute
    path={RouteList.INVESTOR.PAYOUTS.LATE_PAYMENTS}
    component={LatePayments}
    layout={PageLayout}
    permissions={Roles.User.permissions}
    redirect={Roles.User.redirect}
    key={RouteList.INVESTOR.PAYOUTS.LATE_PAYMENTS}
    exact
  />,
];

export default DashboardRoutes;
