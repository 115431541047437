import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { Cell, Column, Row as TableRow, RowPropGetter } from 'react-table';

import { PieChart } from 'components/PieChart';
import { PaginationData, PaginationDataFilter } from 'api/types';
import Table from 'components/Table/Table';
import Tooltip from 'components/Tooltip';
import { MyActiveInvestmentResponseDto } from 'api/types/investor/dashboard';
import ProjectTitleLink from 'components/Project/ProjectTitleLink';
import Api from 'api';
import Loader from 'components/Loader';
import DataNotFoundBlock from 'components/DataNotFoundBlock';
import SimpleBar from 'simplebar-react';
import { useStatisticQuery } from 'api/queries/dashboard';
import useDisplay from 'helpers/useDisplay';

const ActiveInvestments: React.FC = () => {
  const { t, tHtml, currentLanguage } = useTranslation();
  const { isMobile } = useDisplay();

  const [data, setData] = useState<PaginationData<any>>();
  const [projectsData, setProjectsData] = useState<{ [key: string]: any }[]>([]);
  const [piechart, setPiechart] = useState<any[]>([]);
  const [index, setIndex] = useState<number>(-1);

  const ref = useRef<SimpleBar>();

  const { data: activeInvestmentData, isFetching } = useStatisticQuery(
    ['activeInvestments', currentLanguage],
    () => Api.investor.dashboard.fetchActiveInvestments(),
  );

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('table.pid'),
        accessor: 'name',
        sortType: 'string',
        disableSortBy: true,
        Cell: (cell: Cell<MyActiveInvestmentResponseDto>) => {
          return <ProjectTitleLink title={cell.value} projectId={cell.row.original.project_id} />;
        },
      },
      {
        Header: t('table.share'),
        accessor: 'share',
        sortType: 'string',
        disableSortBy: true,
        Cell: (cell: Cell<MyActiveInvestmentResponseDto>) => {
          const number = parseFloat(cell.row.original.share.toFixed(2));
          return <span>{number === 0 ? '>0' : number}%</span>;
        },
      },
      {
        Header: t('table.amount'),
        accessor: 'remaining_amount',
        sortType: 'string',
        disableSortBy: true,
        Cell: (cell: Cell<MyActiveInvestmentResponseDto>) => {
          return (
            <span>{tHtml('common.money', { value: cell.row.original.remaining_amount })}</span>
          );
        },
      },
    ],
    [t, tHtml],
  );

  const getRowProps = (
    row: TableRow<Record<string, unknown>>,
  ): RowPropGetter<Record<string, unknown>> => {
    const result: Record<string, unknown> = {
      onMouseEnter: () => {
        setIndex(row.original.i as number);
      },
    };

    if (row.original?.isSelected) {
      result['className'] = 'active-row';
    }

    return result;
  };

  const fetchData = useCallback(
    async (request: PaginationDataFilter | undefined) => {
      const data = activeInvestmentData?.investments;

      if (!data) {
        return;
      }

      if (request != undefined && request.search != undefined) {
        data.data = data.data.filter((item: Record<string, any>) => {
          return item.name
            .toLocaleLowerCase()
            .includes(String(request.search?.toLocaleLowerCase()));
        });
      }

      data.data = data.data.map((item: any, i: number) => ({
        ...item,
        i,
        isSelected: false,
      }));

      setPiechart(
        data.data.map((item: any) => {
          return {
            name: item['name'],
            value: parseFloat(item['remaining_amount']),
          };
        }),
      );

      setProjectsData(data.data);
      setData(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeInvestmentData, currentLanguage],
  );

  const onChange = (index: number) => {
    if (projectsData.length && data?.data) {
      data.data = projectsData.map((item: any, i) => ({
        ...item,
        isSelected: index === i,
      }));

      setData({
        ...data,
      });
    }
  };

  return (
    <>
      <div className={'page-layout wallet h-100'}>
        <div className={'panel mb-0 h-100'}>
          <Card body className={'light-pink balance py-2 py-md-4 px-1 px-md-3 full-height-mobile'}>
            <Row className={'balance-items'}>
              <Col md={12} className={'balance-information'}>
                {isFetching ? (
                  <Loader />
                ) : (
                  <div className={'balance-amount'}>
                    {activeInvestmentData?.total_sum
                      ? tHtml('common.money', { value: activeInvestmentData?.total_sum })
                      : '-'}
                  </div>
                )}
                <div className={'balance-description'}>
                  {tHtml('dashboard.active_investments')}
                  <i
                    className={'icon icon-info position-absolute'}
                    id={'active-investments-icon'}
                  />
                </div>
                <Tooltip
                  id={'active-investments-icon'}
                  text={tHtml('dashboard.investor.tooltip.active_investments')}
                />
              </Col>
            </Row>
          </Card>
          {!isMobile && (
            <>
              {isFetching ? (
                <div className="loader-center">
                  <Loader />
                </div>
              ) : (
                <>
                  {activeInvestmentData?.investments?.data.length === 0 ? (
                    <>
                      <div className="mobile-table-card text-center mt-2">
                        <DataNotFoundBlock label={tHtml('investments.no_active_investments')} />
                      </div>
                    </>
                  ) : (
                    <Row>
                      <Col xs={12} sm={12} xl={5}>
                        <PieChart
                          data={piechart}
                          onChange={onChange}
                          indexState={{ index, setIndex }}
                        />
                      </Col>
                      <Col xs={12} sm={12} xl={7}>
                        <SimpleBar
                          ref={ref as React.LegacyRef<SimpleBar>}
                          className={'table-w-o-shadow table active-investments-table'}
                        >
                          <Table
                            columns={columns}
                            data={data}
                            onFetchData={fetchData}
                            showPagination={false}
                            tableRowProps={getRowProps}
                          />
                        </SimpleBar>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ActiveInvestments;
