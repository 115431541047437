import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import Api from 'api';
import { ProjectDeveloperProjectListItemResponseDto } from 'api/types/project-developer/project';
import SkeletonLoader from 'components/SkeletonLoader';
import { MyProjectsListSkeleton } from './components/MyProjectsListSkeleton';
import { MyProjectCard } from './components/MyProjectCard';

const MyProjectList: React.FC = () => {
  const { tHtml, currentLanguage } = useTranslation();
  const [openProjects, setOpenProjects] = useState<ProjectDeveloperProjectListItemResponseDto[]>(
    [],
  );
  const [activeProjects, setActiveProjects] = useState<
    ProjectDeveloperProjectListItemResponseDto[]
  >([]);
  const [closedProjects, setClosedProjects] = useState<
    ProjectDeveloperProjectListItemResponseDto[]
  >([]);
  const [preparingProjects, setPreparingProjects] = useState<
    ProjectDeveloperProjectListItemResponseDto[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    Api.projectDeveloper.projects.fetchProjects().then((data) => {
      setOpenProjects(data.open_projects ?? []);
      setActiveProjects(data.active_projects ?? []);
      setClosedProjects(data.closed_projects ?? []);
      setPreparingProjects(data.preparing_projects ?? []);

      setIsLoading(false);
    });
  }, [isLoading, currentLanguage]);

  return (
    <SkeletonLoader loading={isLoading} customPlaceholder={<MyProjectsListSkeleton />}>
      {preparingProjects.length > 0 && (
        <div className={'mb-6'}>
          <h2 className={'mb-2'}>{tHtml('projects.preparing_projects')}</h2>
          <div>
            {preparingProjects?.map((project, idx) => (
              <Row key={idx}>
                <Col>
                  <MyProjectCard {...project} />
                </Col>
              </Row>
            ))}
          </div>
        </div>
      )}
      {openProjects.length > 0 && (
        <div className={'mb-6'}>
          <h2 className={'mb-2'}>{tHtml('projects.open_projects')}</h2>
          <div>
            {openProjects?.map((project, idx) => (
              <Row key={idx}>
                <Col>
                  <MyProjectCard {...project} />
                </Col>
              </Row>
            ))}
          </div>
        </div>
      )}
      {activeProjects.length > 0 && (
        <div className={'mb-6'}>
          <h2 className={'mb-2'}>{tHtml('projects.active_projects')}</h2>
          <div>
            {activeProjects?.map((project, idx) => (
              <Row key={idx}>
                <Col>
                  <MyProjectCard {...project} />
                </Col>
              </Row>
            ))}
          </div>
        </div>
      )}
      {closedProjects.length > 0 && (
        <div className={'mb-6'}>
          <h2 className={'mb-2'}>{tHtml('projects.closed_projects')}</h2>
          <div>
            {closedProjects?.map((project, idx) => (
              <Row key={idx}>
                <Col>
                  <MyProjectCard {...project} />
                </Col>
              </Row>
            ))}
          </div>
        </div>
      )}
      {preparingProjects.length === 0 &&
        openProjects.length === 0 &&
        activeProjects.length === 0 &&
        closedProjects.length === 0 && (
          <div className={'my-6'}>
            <h2 className={'mb-2 text-center'}>{tHtml('projects.empty_projects')}</h2>
          </div>
        )}
    </SkeletonLoader>
  );
};

export default MyProjectList;
