import React from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import SecondaryMarketPromotionBlock from '../components/SecondaryMarketPromotionBlock';
import SecondaryMarketFAQ from '../components/SecondaryMarketFAQ';
import { HighlightButton } from 'components/Button';
import { RouteList } from 'routes';
import ProjectInvestmentForSale from './components/ProjectInvestmentForSale';
import { PageHeader } from 'components/Header';

const ProjectInvestment: React.FC = () => {
  const { projectInvestmentId } = useParams<{ projectInvestmentId: string }>();
  const { tHtml } = useTranslation();

  return (
    <div className={'page-layout secondary-market-page'}>
      <PageHeader title={tHtml('secondary_market.title')}>
        <Link to={RouteList.INVESTOR.SECONDARY_MARKET.SELLER.OVERVIEW}>
          <HighlightButton
            className={'btn btn-highlight back-button'}
            icon={'icon icon-angle-left'}
          >
            {tHtml('secondary_market.project.back_to_all_projects')}
          </HighlightButton>
        </Link>
      </PageHeader>
      <SecondaryMarketPromotionBlock />
      <ProjectInvestmentForSale projectInvestmentId={projectInvestmentId} />
      <SecondaryMarketFAQ />
    </div>
  );
};

export default withRouter(ProjectInvestment);
