import Client from '../../client';
import Http from '../../../http';
import {
  OndatoStartIdentificationResponseDto,
  UpdateUserNaturalRequestDto,
  UpdateUserNaturalResponseDto,
} from '../../../types/shared/user/signUpSteps';
import { ConfirmAccountRequestDto } from '../../../types/guest/authenticate';

class SharedUserSignUpSteps extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      REGISTRATION_DATA_FILLING: this.protectedUrl(`${this.http.baseUrl}/sign-up/user`),
      REGISTRATION_VERIFICATION: this.protectedUrl(`${this.http.baseUrl}/sign-up/verification`),
      REGISTRATION_RESEND_CONFIRMATION_MAIL: this.protectedUrl(
        `${this.http.baseUrl}/sign-up/resend-confirmation-mail`,
      ),
      REGISTRATION_CONFIRM_ACCOUNT: this.protectedUrl(
        `${this.http.baseUrl}/guest/auth/confirm-code`,
      ),
    };
  }

  confirmAccount = (data: ConfirmAccountRequestDto): Promise<any> => {
    return this.http.post(this.api.REGISTRATION_CONFIRM_ACCOUNT, data);
  };

  resendConfirmationMail = (): Promise<any> => {
    return this.http.post(this.api.REGISTRATION_RESEND_CONFIRMATION_MAIL);
  };

  registrationDataFilling = (
    data: UpdateUserNaturalRequestDto,
  ): Promise<UpdateUserNaturalResponseDto> => {
    return this.http.put(this.api.REGISTRATION_DATA_FILLING, data);
  };

  registrationVerification = (): Promise<OndatoStartIdentificationResponseDto> => {
    return this.http.post(this.api.REGISTRATION_VERIFICATION);
  };
}

export default SharedUserSignUpSteps;
