import React from 'react';

import { RouteList } from 'routes';
import * as Roles from 'helpers/auth/roles';
import AuthRoute from 'routes/AuthRoute';
import PageLayout from 'scopes/developer/containers/PageLayout';
import { Dashboard } from '.';

const DashboardRoutes: any = () => [
  <AuthRoute
    path={RouteList.DASHBOARD}
    component={Dashboard}
    layout={PageLayout}
    permissions={Roles.User.permissions} // TODO only project-developer can access this route
    redirect={Roles.User.redirect}
    key={RouteList.DASHBOARD}
    exact
  />,
];

export default DashboardRoutes;
