import React, { ReactElement, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import Table, { TableProps } from 'components/Table/Table';
import { FunctionalButton } from 'components/Button';
import useDisplay from 'helpers/useDisplay';

interface Props extends TableProps {
  title?: string | ReactElement;
  limitOverview?: number;
  limitExpanded?: number;
}

const TableExpandable: React.FC<Props> = ({
  title,
  limitOverview = 5,
  limitExpanded = 10,
  ...props
}) => {
  const { tHtml } = useTranslation();

  const { isMobile } = useDisplay();

  const [expanded, setExpanded] = useState<boolean>(isMobile);

  return (
    <div className={props?.data?.data.length && props.data.data.length > 0 ? '' : 'mb-4'}>
      {title && <h2>{title}</h2>}
      <div className={'mt-4'}>
        <Table
          showPagination={expanded}
          mobile={true}
          defaultLimit={expanded ? limitExpanded : limitOverview}
          {...props}
        />
      </div>
      {!expanded && props.data && props.data.data.length > 0 && (
        <div className={'mt-4 d-flex justify-content-end'}>
          <FunctionalButton onClick={() => setExpanded(true)}>
            {tHtml('common.more')} <i className={'icon icon-arrow-right'} />
          </FunctionalButton>
        </div>
      )}
    </div>
  );
};

export default TableExpandable;
