import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { InvestorKybDataV1ResponseDto } from 'api/types/shared/company/profile';
import { RouteList } from 'routes';
import YesNoBlock from 'components/YesNoBlock';
import { DataBlock } from 'components/DataBlock';
import NotFilledProfileSection from 'components/Profile/NotFilledProfileSection';
import CompanyProfileSection from 'scopes/company/components/CompanyProfileSection';
import { CompanyMenuEnum } from 'helpers/enums/CompanyMenuEnum';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';

const InvestorKybV1: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const { data: companyProfileData } = useUserCompanyProfileQuery();

  const kyb = companyProfileData?.know_your_business?.kyb_data as
    | InvestorKybDataV1ResponseDto
    | undefined;

  if (!kyb) {
    return (
      <NotFilledProfileSection
        title={tHtml('menu.kyb')}
        buttonLink={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}
      />
    );
  }

  const activityRegions = kyb?.activity_regions
    .map((item) => {
      if (item === 'other') {
        return kyb?.other_activity_regions_text;
      }
      return t(`placeholder.${item}`);
    })
    .join('; ');

  return (
    <CompanyProfileSection
      name={CompanyMenuEnum.KnowYourBusiness}
      title={tHtml('menu.kyb')}
      buttonLink={RouteList.COMPANY.EDIT.KNOW_YOUR_BUSINESS}
    >
      <div className={'content'}>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.company.planned_investment_amount')}
            data={
              kyb?.planned_investment_amount
                ? tHtml(`placeholder.${kyb?.planned_investment_amount}`)
                : ''
            }
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.company.activity_regions')}
            data={activityRegions ?? ''}
          />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml('placeholder.company.has_cash_operations')}
            checked={kyb?.has_cash_operations}
          />
          {kyb?.has_cash_operations && (
            <DataBlock
              title={tHtml('placeholder.company.ytd_cash_operations_amount')}
              data={
                kyb?.ytd_cash_operations_amount
                  ? tHtml(`placeholder.${kyb?.ytd_cash_operations_amount}`)
                  : ''
              }
            />
          )}
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.company.investment_amount_source')}
            data={kyb?.investment_amount_source ? kyb?.investment_amount_source : ''}
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={tHtml('placeholder.company.ytd_income')}
            data={kyb?.ytd_income ? kyb?.ytd_income.toString() : ''}
          />
        </div>
        <div className={'mb-4'}>
          <DataBlock
            title={t('placeholder.company.management_structure')}
            data={
              kyb?.management_structure
                ? tHtml(`placeholder.company.management_structure.${kyb?.management_structure}`)
                : ''
            }
          />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml('placeholder.company.has_bankruptcy_procedure')}
            checked={kyb?.has_bankruptcy_procedure}
          />
        </div>
        <div className={'mb-4'}>
          <YesNoBlock
            label={tHtml(
              'placeholder.company.is_shareholder_beneficiary_family_member_public_official',
            )}
            checked={kyb?.is_shareholder_beneficiary_family_member_public_official}
          />
        </div>
      </div>
    </CompanyProfileSection>
  );
};

export default withRouter(InvestorKybV1);
