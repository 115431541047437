import Client from '../client';
import Http from '../../http';
import { InvestorProjectResponse } from '../../types/investor/project';

class SharedProject extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      FETCH_PROJECT: this.defaultUrl(
        `${this.http.baseUrl}/projects/project/{projectId}/{previewId}`,
      ),
    };
  }

  fetchProject = (
    projectId: string | number,
    previewId: string | number,
  ): Promise<InvestorProjectResponse> => {
    const url = this.buildUrl(this.api.FETCH_PROJECT, { projectId, previewId });
    return this.http.get(url);
  };
}

export default SharedProject;
