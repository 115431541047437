import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { RouteList } from 'routes';
import useUserType from 'helpers/useUserType';
import { HighlightButton } from 'components/Button';
import DeveloperPageLayout from 'scopes/developer/containers/PageLayout';
import InvestorPageLayout from 'scopes/investor/containers/PageLayout';
import EditProfileLayoutSkeleton from './EditProfileLayoutSkeleton';
import Sidebar from './Sidebar';
import useTranslation from 'helpers/useTranslation';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';

const EditProfileLayout: React.FC = ({ children }) => {
  const type = useUserType();
  const { tHtml } = useTranslation();
  const { isFetching } = useUserCompanyProfileQuery();

  const PageLayoutType = type.isDeveloper() ? DeveloperPageLayout : InvestorPageLayout;

  return (
    <React.Fragment>
      {isFetching ? (
        <PageLayoutType isProfileLayout={true}>
          <EditProfileLayoutSkeleton />
        </PageLayoutType>
      ) : (
        <PageLayoutType isProfileLayout={true}>
          <div className={'edit-profile-layout'}>
            <Container className={'g-0'}>
              <Row>
                <Col className={'sidebar d-none d-lg-flex'} xs={0} lg={4}>
                  <Sidebar />
                </Col>
                <Col className={'content d-flex justify-content-center'} xs={12} lg={8}>
                  <div className={'close'}>
                    <Link to={RouteList.COMPANY.PROFILE.HOME}>
                      <HighlightButton>{tHtml('common.back')}</HighlightButton>
                    </Link>
                  </div>
                  <div className={'data'}>{children}</div>
                </Col>
              </Row>
            </Container>
          </div>
        </PageLayoutType>
      )}
    </React.Fragment>
  );
};

export default withRouter(EditProfileLayout);
