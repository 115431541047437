import Client from '../client';
import Http from '../../http';
import {
  AuthenticatedAccountPermissionsResponseDto,
  AuthenticatedAccountResponseDto,
  RefreshAuthenticatedAccountResponseDto,
} from '../../types/shared/authenticatedAccount';

class SharedAuthenticatedAccount extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      PERMISSION: this.protectedUrl(`${this.http.baseUrl}/authenticated/permissions`),
      CURRENT: this.protectedUrl(`${this.http.baseUrl}/authenticated/current`),
      REFRESH: this.protectedUrl(`${this.http.baseUrl}/authenticated/refresh`),
      LOGOUT: this.protectedUrl(`${this.http.baseUrl}/authenticated/logout`),
    };
  }

  fetchCurrent = (): Promise<AuthenticatedAccountResponseDto> => {
    return this.http.get(this.api.CURRENT);
  };

  fetchPermissions = (): Promise<AuthenticatedAccountPermissionsResponseDto> => {
    return this.http.get(this.api.PERMISSION);
  };

  refreshCurrent = (): Promise<RefreshAuthenticatedAccountResponseDto> => {
    return this.http.put(this.api.REFRESH);
  };

  logoutCurrent = (): Promise<void> => {
    return this.http.post(this.api.LOGOUT);
  };
}

export default SharedAuthenticatedAccount;
