import React from 'react';
import { StyledDataBlock, StyledDataBlockLabel, StyledDataBlockValue } from './DataBlock.style';
import useDisplay from 'helpers/useDisplay';

interface Props {
  title: string;
  value: string;
  mobile?: boolean;
}

const DataBlock: React.FC<Props> = ({ title, value, mobile = false }) => {
  const { isMobile } = useDisplay();

  return (
    <StyledDataBlock isMobile={mobile && isMobile}>
      <StyledDataBlockLabel isMobile={isMobile}>{title}</StyledDataBlockLabel>
      <StyledDataBlockValue>{value}</StyledDataBlockValue>
    </StyledDataBlock>
  );
};

export default DataBlock;
