import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Column, Row as TableRow, RowPropGetter } from 'react-table';

import PieChart, { PieChartDataEntry, PieChartProps } from 'components/PieChart/PieChart';
import { PaginationData, PaginationDataFilter } from 'api/types';
import Table from 'components/Table/Table';
import SimpleBar from 'simplebar-react';
import useDisplay from 'helpers/useDisplay';

interface TableProps extends PieChartProps {
  tableData: PaginationData<any> | undefined;
  columns: Column<any>[];
  tableRowProps?: (
    row: TableRow<Record<string, unknown>>,
  ) => RowPropGetter<Record<string, unknown>>;
  onFetchData: (request: PaginationDataFilter | undefined) => Promise<any>;
  isPlacementVertical?: boolean;
  showMobilePieChart?: boolean;
}

type Props = TableProps;

const InvestorPortfolioPieChartWithTable: React.FC<Props> = ({
  showMobilePieChart = true,
  ...props
}) => {
  const { isMobile } = useDisplay();

  const [pieChartData, setPieChartData] = useState<PieChartDataEntry[]>([]);
  const [tableData, setTableData] = useState<PaginationData<any>>();

  const showPieChart = showMobilePieChart || !isMobile;
  const ref = React.useRef<SimpleBar>();

  const tableProps = {
    data: tableData,
    columns: props.columns,
    onFetchData: props.onFetchData,
    showPagination: true,
    searchable: false,
    tableRowProps: props.tableRowProps,
    mobile: true,
    sortable: false,
    minimizeCard: true,
  };

  useEffect(() => {
    if (props.data) {
      setPieChartData(props.data);
    }

    if (props.tableData) {
      setTableData(props.tableData);
    }
  }, [props.data, props.tableData]);

  return (
    <Row>
      {showPieChart && (
        <Col xs={12} lg={props.isPlacementVertical ? 12 : 6}>
          <PieChart data={pieChartData} indexState={props.indexState} onChange={props.onChange} />
        </Col>
      )}
      <Col xs={12} lg={props.isPlacementVertical ? 12 : 6}>
        {isMobile ? (
          <Table {...tableProps} />
        ) : (
          <SimpleBar ref={ref as React.LegacyRef<SimpleBar>} className={'table-w-o-shadow table'}>
            <Table {...tableProps} />
          </SimpleBar>
        )}
      </Col>
    </Row>
  );
};

export default InvestorPortfolioPieChartWithTable;
