import React, { useEffect, useState } from 'react';
import { Animate } from 'react-move';
import { easeQuadInOut } from 'd3-ease';

interface AnimatedProgressProviderProps {
  valueStart?: number;
  valueEnd: number;
  duration?: number;
  easingFunction?: any;
  children: (props: { value: number }) => React.ReactElement<any, any>;
}

const AnimatedProgressProvider: React.FC<AnimatedProgressProviderProps> = ({
  valueStart = 0,
  valueEnd,
  duration = 0,
  easingFunction = easeQuadInOut,
  children,
}) => {
  const forceUpdate: () => void = useState({})[1].bind(null, {});
  useEffect(() => {
    forceUpdate();
    // eslint-disable-next-line
  }, []);

  return (
    <Animate
      start={() => ({
        value: valueStart,
      })}
      update={() => ({
        value: [valueEnd],
        timing: {
          duration: duration * 1000,
          ease: easingFunction,
        },
      })}
      show
    >
      {({ value }) => {
        return children({ value: isNaN(value) ? 0 : value });
      }}
    </Animate>
  );
};

export default AnimatedProgressProvider;
