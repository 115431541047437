import React, { useState, useEffect } from 'react';
import { Formik, Form, setNestedObjectValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Col, Row } from 'reactstrap';
import { AvailableCountriesEnum } from 'helpers/enums/AvailableCountriesEnum';
import { RatingEnum } from 'helpers/enums/RatingEnum';
import { InvestmentPurposeEnum } from 'helpers/enums/InvestmentPurposeEnum';
import { SecurityMeasuresEnum } from 'helpers/enums/SecurityMeasuresEnum';
import {
  AutoInvestPrediction,
  InvestorAutoInvestmentConfigCriteriaEnum,
  InvestorAutoInvestmentConfigPackEnum,
  InvestorAutoInvestmentFullConfig,
  InvestorAutoStrategy,
  StoreStrategyDto,
} from 'api/types/investor/investment';
import { Label, PrimaryButton, SelectInput, TextInput } from 'components/Formik';
import NumberInput from 'components/Formik/NumberInput';
import useTranslation from 'helpers/useTranslation';
import SliderWithRange from 'components/SliderWithRange';
import { HighlightButton } from 'components/Button';
import Api from 'api';
import YesNoSelector from 'components/Formik/YesNoSelector';
import MultiSelectProjectRatingInput from 'components/Formik/Selects/Investor/MultiSelectProjectRatingInput';
import MultiSelectAvailableProjectCountries from 'components/Formik/Selects/Investor/MultiSelectAvailableProjectCountries';
import MultiSelectInvestmentPurpose from 'components/Formik/Selects/Investor/MultiSelectInvestmentPurpose';
import MultiSelectSecurityMeasures from 'components/Formik/Selects/Investor/MultiSelectSecurityMeasures';
import { useAutoInvestmentData } from '../AutoInvestmentProvider';
import DataBlockLarge from 'components/DataBlock/DataBlockLarge/DataBlockLarge';
import { success } from 'services/toastr';
import CheckboxInput from 'components/Formik/CheckboxInput';
import Card from 'components/Card/Card';
import { SelectOption } from 'components/Formik/SelectInput';
import { RiskCategoryEnum } from 'helpers/enums/RiskCategoryEnum';
import RadioInputGroup from 'components/Formik/RadioGroup/RadioInputGroup';

const DEFAULT_CREDIT_DURATION_FROM = 3;
const DEFAULT_CREDIT_DURATION_TO = 36;
const DEFAULT_INTEREST_FROM = 5;
const DEFAULT_INTEREST_TO = 10;
const MIN_INVESTMENT = 100;
const MAX_INVESTMENT = 100000;
const SHARES_PER_BORROWER = [50, 60, 70, 80, 90];
const SHARES_PER_LONG_TERM = [10, 15, 20];

type PackSpecificValues = {
  country: AvailableCountriesEnum[];
  investment_purpose: InvestmentPurposeEnum[];
  rating_profitus: RatingEnum[];
  security_measures: SecurityMeasuresEnum[];
};

export const StrategyForm: React.FC<{
  strategy?: InvestorAutoStrategy;
  selectedConfig: InvestorAutoInvestmentConfigPackEnum;
  autoInvestmentConfig: InvestorAutoInvestmentFullConfig;
}> = ({ strategy, selectedConfig, autoInvestmentConfig }) => {
  const { updateAutoInvestmentData, setNewStrategy, maxShareLongTerm, maxSharePerBorrower } =
    useAutoInvestmentData();
  const [selectedPack, setSelectedPack] = useState(selectedConfig);
  const { t, tHtml } = useTranslation();

  const [creditDurationFrom, setCreditDurationFrom] = useState(DEFAULT_CREDIT_DURATION_FROM);
  const [creditDurationTo, setCreditDurationTo] = useState(DEFAULT_CREDIT_DURATION_TO);
  const [interestFrom, setInterestFrom] = useState(DEFAULT_INTEREST_FROM);
  const [interestTo, setInterestTo] = useState(DEFAULT_INTEREST_TO);

  const [prediction, setPrediction] = useState<AutoInvestPrediction | null>(null);

  useEffect(() => {
    if (strategy) {
      setCreditDurationFrom(strategy.credit_duration_from);
      setCreditDurationTo(strategy.credit_duration_to);
      setInterestFrom(strategy.interest_from);
      setInterestTo(strategy.interest_to);
    }
  }, [strategy]);

  const getInitialValues = (config: InvestorAutoInvestmentConfigPackEnum) => {
    return {
      ...Object.keys(autoInvestmentConfig[config].criteria).reduce(
        (
          values: { [key: string]: string[] | '' },
          key: string | InvestorAutoInvestmentConfigCriteriaEnum,
        ) => {
          if (!autoInvestmentConfig[config].criteria[key]) {
            switch (key) {
              case InvestorAutoInvestmentConfigCriteriaEnum.COUNTRY:
                values[key] = Object.values(AvailableCountriesEnum);
                break;
              case InvestorAutoInvestmentConfigCriteriaEnum.RATING_PROFITUS:
                values[key] = Object.values(RatingEnum);
                break;
              case InvestorAutoInvestmentConfigCriteriaEnum.INVESTMENT_PURPOSE:
                values[key] = Object.values(InvestmentPurposeEnum);
                break;
              case InvestorAutoInvestmentConfigCriteriaEnum.SECURITY_MEASURES:
                values[key] = Object.values(SecurityMeasuresEnum);
                break;
              default:
                values[key] = [];
            }
          } else {
            values[key] = (strategy as any)?.[key] ?? [];
          }
          return values;
        },

        {},
      ),
    } as PackSpecificValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const initialValues: StoreStrategyDto = {
    ...getInitialValues(selectedConfig),
    amount: strategy?.amount ?? autoInvestmentConfig[selectedConfig].min_amount,
    name: strategy?.name ?? '',
    risk_category_to: strategy?.risk_category_to ?? null,
    can_invest_less: strategy?.can_invest_less ?? null,
    interest_from: strategy?.interest_from ?? DEFAULT_INTEREST_FROM,
    interest_to: strategy?.interest_to ?? DEFAULT_INTEREST_TO,
    credit_duration_from: strategy?.credit_duration_from ?? DEFAULT_CREDIT_DURATION_FROM,
    credit_duration_to: strategy?.credit_duration_to ?? DEFAULT_CREDIT_DURATION_TO,
    agreement: false,
    max_share_long_term: maxShareLongTerm,
    max_share_per_borrower: maxSharePerBorrower,
    is_max_share_per_borrower: maxSharePerBorrower ? 'share' : 'all',
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number().min(MIN_INVESTMENT).max(MAX_INVESTMENT).required(),
    name: Yup.string().max(255).required(),
    can_invest_less: Yup.boolean().required(),
    interest_from: Yup.number().min(4).max(15).required(),
    interest_to: Yup.number().min(4).max(15).required(),
    risk_category_to: Yup.number().min(1).max(12).required(),
    credit_duration_from: Yup.number().min(3).max(36).required(),
    credit_duration_to: Yup.number().min(3).max(36).required(),
    country: Yup.array().min(1).required(),
    rating_profitus: Yup.array().min(1).required(),
    investment_purpose: Yup.array().min(1).required(),
    security_measures: Yup.array().min(1).required(),
    agreement: Yup.boolean().required().isTrue(),
    max_share_per_long_term: Yup.number(),
    is_max_share_borrower: Yup.string(),
    max_share_per_borrower: Yup.number().when('is_max_share_long_borrower', {
      is: 'share',
      then: Yup.number().required(),
      otherwise: Yup.number().nullable(),
    }),
  });

  const handleAmountChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValues: (values: StoreStrategyDto) => void,
    values: StoreStrategyDto,
  ) => {
    const amount = Number(e.target.value ?? 0);
    let pack;
    if (
      amount >= autoInvestmentConfig[InvestorAutoInvestmentConfigPackEnum.SECOND_PACK].min_amount
    ) {
      pack = InvestorAutoInvestmentConfigPackEnum.SECOND_PACK;
    } else if (
      amount >= autoInvestmentConfig[InvestorAutoInvestmentConfigPackEnum.FIRST_PACK].min_amount
    ) {
      pack = InvestorAutoInvestmentConfigPackEnum.FIRST_PACK;
    } else {
      pack = InvestorAutoInvestmentConfigPackEnum.BASIC_PACK;
    }
    setValues({
      ...getInitialValues(pack),
      amount: amount == 0 ? undefined : amount,
      name: values.name,
      can_invest_less: values.can_invest_less,
      interest_from: values.interest_from,
      interest_to: values.interest_to,
      risk_category_to: values.risk_category_to,
      credit_duration_from: values.credit_duration_from,
      credit_duration_to: values.credit_duration_to,
      agreement: values.agreement,
      max_share_long_term: values.max_share_long_term,
      max_share_per_borrower: values.max_share_per_borrower,
      is_max_share_per_borrower: values.is_max_share_per_borrower,
    });
    setSelectedPack(pack);
  };

  const onSubmit = React.useCallback(
    async (request: StoreStrategyDto, helpers: FormikHelpers<StoreStrategyDto>) => {
      const strategyRequest = {
        ...request,
        interest_from: interestFrom,
        interest_to: interestTo,
        credit_duration_from: creditDurationFrom,
        credit_duration_to: creditDurationTo,
        max_share_per_borrower:
          request.is_max_share_per_borrower === 'all' ? null : request.max_share_per_borrower,
      };

      try {
        if (strategy) {
          await Api.investor.investments
            .updateAutoInvestmentStrategy(strategyRequest, strategy.id)
            .then((response) => {
              success(tHtml('investments.auto_investment.updated_successfully'));
              updateAutoInvestmentData(response);
            });
        } else {
          await Api.investor.investments
            .storeAutoInvestmentStrategy(strategyRequest)
            .then((response) => {
              success(tHtml('investments.auto_investment.created_successfully'));
              setNewStrategy(undefined);
              updateAutoInvestmentData(response);
            });
        }
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [
      tHtml,
      strategy,
      interestFrom,
      interestTo,
      creditDurationFrom,
      creditDurationTo,
      updateAutoInvestmentData,
      setNewStrategy,
    ],
  );

  const predictInvestments = React.useCallback(
    async (request: StoreStrategyDto, setErrors) => {
      const strategyRequest = {
        ...request,
        interest_from: interestFrom,
        interest_to: interestTo,
        credit_duration_from: creditDurationFrom,
        credit_duration_to: creditDurationTo,
      };

      try {
        const response = await Api.investor.investments.predictAutoInvestmentStrategy(
          strategyRequest,
        );
        setPrediction(response);
      } catch (e) {
        setErrors(e.response?.errors);
      }
    },
    [interestFrom, interestTo, creditDurationFrom, creditDurationTo],
  );

  const deleteStrategy = React.useCallback(async () => {
    if (strategy) {
      await Api.investor.investments.deleteAutoInvestmentStrategy(strategy.id).then((response) => {
        updateAutoInvestmentData(response);
        success(tHtml('common.deleted_successfully'));
      });
    }
  }, [strategy, tHtml, updateAutoInvestmentData]);

  const [riskCategoryOptions] = useState<SelectOption[]>(() =>
    Object.values(RiskCategoryEnum)
      .filter((key) => !isNaN(Number(key)))
      .map((value) => {
        return {
          label: t(`investments.auto_investment.risk_category.${value}`),
          value: value,
        };
      }),
  );

  const [maxSharePerBorrowerOptions] = useState<SelectOption[]>(() =>
    SHARES_PER_BORROWER.map((value) => {
      return {
        label: t('common.percents', { value }),
        value: value,
      };
    }),
  );

  const [maxSharePerLongTermOptions] = useState<SelectOption[]>(() =>
    SHARES_PER_LONG_TERM.map((value) => {
      return {
        label: t('common.percents', { value }),
        value: value,
      };
    }),
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        isSubmitting,
        touched,
        validateForm,
        setTouched,
        setFieldValue,
        setSubmitting,
        setErrors,
        setValues,
        setFormikState,
      }) => (
        <Form className={'strategy-form'}>
          <Card className={'card'}>
            <div className="mb-3">
              <Label
                htmlFor={'name'}
                title={tHtml('investments.auto_investment.strategy_name')}
                isBold={true}
                hasTooltip={true}
                tooltipContent={tHtml('investments.auto_investment.tooltip.strategy_name')}
              />
              <TextInput
                name="name"
                hideLabel={true}
                placeholder={t('investments.auto_investment.strategy_name')}
              />
            </div>
            <Row>
              <Col xs={12} md={3} lg={6}>
                <div>
                  <Label
                    htmlFor={'amount'}
                    title={tHtml('investments.auto_investment.amount')}
                    isBold={true}
                    hasTooltip={true}
                    tooltipContent={tHtml('investments.auto_investment.tooltip.amount')}
                  />
                  <NumberInput
                    hideLabel={true}
                    name="amount"
                    value={values.amount}
                    placeholder={t('investments.auto_investment.amount')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleAmountChange(e, setValues, values);
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={3} lg={6}>
                <div className={'mb-3'}>
                  <Label
                    htmlFor={'can_invest_less'}
                    title={tHtml('investments.auto_investment.can_invest_less')}
                    isBold={true}
                    hasTooltip={true}
                    tooltipContent={tHtml('investments.auto_investment.tooltip.can_invest_less')}
                  />
                  <YesNoSelector hideLabel={true} name={'can_invest_less'} />
                </div>
              </Col>
            </Row>
          </Card>

          <Card className={'card'}>
            <Row>
              <Col xs={12} md={9} lg={6}>
                <div>
                  <Label
                    htmlFor={'credit_duration'}
                    title={tHtml('investments.auto_investment.credit_duration')}
                    isBold={true}
                    hasTooltip={true}
                    tooltipContent={tHtml('investments.auto_investment.tooltip.credit_duration')}
                  />
                  <SliderWithRange
                    min={3}
                    max={36}
                    values={[creditDurationFrom, creditDurationTo]}
                    onChange={(values) => {
                      setCreditDurationFrom(values[0]);
                      setCreditDurationTo(values[1]);
                    }}
                    showLimits={false}
                  />
                  <div className={'d-flex justify-content-between'}>
                    <div>
                      <NumberInput
                        placeholder={t('common.min')}
                        name={'credit_duration_from'}
                        value={creditDurationFrom}
                        onChange={(e) => setCreditDurationFrom(Number(e.target.value))}
                      />
                    </div>
                    <div>
                      <NumberInput
                        placeholder={t('common.max')}
                        name={'credit_duration_to'}
                        value={creditDurationTo}
                        onChange={(e) => setCreditDurationTo(Number(e.target.value))}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={9} lg={6}>
                <div>
                  <Label
                    htmlFor={'interest'}
                    title={tHtml('investments.auto_investment.interest')}
                    isBold={true}
                    hasTooltip={true}
                    tooltipContent={tHtml('investments.auto_investment.tooltip.interest')}
                  />
                  <SliderWithRange
                    min={4}
                    max={15}
                    step={0.1}
                    values={[interestFrom, interestTo]}
                    onChange={(values) => {
                      setInterestFrom(values[0]);
                      setInterestTo(values[1]);
                    }}
                    showLimits={false}
                  />
                  <div className={'d-flex justify-content-between'}>
                    <div>
                      <NumberInput
                        placeholder={t('common.min')}
                        name={'interest_from'}
                        value={interestFrom}
                        onChange={(e) => setInterestFrom(Number(e.target.value))}
                      />
                    </div>
                    <div>
                      <NumberInput
                        placeholder={t('common.max')}
                        name={'interest_to'}
                        value={interestTo}
                        onChange={(e) => setInterestTo(Number(e.target.value))}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} lg={6}>
                <div className="mb-3">
                  <Label
                    htmlFor={'risk_category_to'}
                    title={tHtml('investments.auto_investment.risk_category_to')}
                    isBold={true}
                    hasTooltip={true}
                    tooltipContent={tHtml('investments.auto_investment.tooltip.risk_category_to')}
                  />
                  <SelectInput
                    name={'risk_category_to'}
                    hideLabel={true}
                    options={riskCategoryOptions}
                    placeholder={tHtml('investments.auto_investment.risk_category_to')}
                  />
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="mb-3">
                  <Label
                    htmlFor={'max_share_long_term'}
                    title={tHtml('investments.auto_investment.max_share_long_term')}
                    isBold={true}
                    hasTooltip={true}
                    tooltipContent={tHtml(
                      'investments.auto_investment.tooltip.max_share_long_term',
                    )}
                  />
                  <SelectInput
                    name={'max_share_long_term'}
                    hideLabel={true}
                    options={maxSharePerBorrowerOptions}
                    placeholder={tHtml('investments.auto_investment.max_share_long_term')}
                  />
                  {touched.max_share_long_term && (
                    <p className={'text-muted mb-3 small'}>
                      {tHtml('investments.auto_investment.global_change_strategies')}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <div className="mb-3">
                  <Label
                    htmlFor={'is_max_share_per_borrower'}
                    title={tHtml('investments.auto_investment.is_max_share_per_borrower')}
                    isBold={true}
                    hasTooltip={true}
                    tooltipContent={tHtml(
                      'investments.auto_investment.tooltip.is_max_share_per_borrower',
                    )}
                  />
                  <RadioInputGroup
                    name={'is_max_share_per_borrower'}
                    values={['all', 'share']}
                    showPlaceholder={false}
                    placeholderValuesPrefix={
                      'investments.auto_investment.is_max_share_per_borrower.'
                    }
                  />
                  {touched.is_max_share_per_borrower && (
                    <p className={'text-muted mb-3 small'}>
                      {tHtml('investments.auto_investment.global_change_strategies')}
                    </p>
                  )}
                </div>
              </Col>
              {values.is_max_share_per_borrower === 'share' && (
                <Col xs={12} lg={6}>
                  <div className="mb-3">
                    <Label
                      htmlFor={'max_share_per_borrower'}
                      title={tHtml('investments.auto_investment.max_share_per_borrower')}
                      isBold={true}
                      hasTooltip={true}
                      tooltipContent={tHtml(
                        'investments.auto_investment.tooltip.max_share_per_borrower',
                      )}
                    />
                    <SelectInput
                      name={'max_share_per_borrower'}
                      hideLabel={true}
                      options={maxSharePerLongTermOptions}
                      placeholder={tHtml('investments.auto_investment.max_share_per_borrower')}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </Card>
          <Card className={'card'}>
            <div className={'form-input mb-3'}>
              <Label
                htmlFor={'rating_profitus'}
                title={tHtml('placeholder.select_initial_ratings')}
                isBold={true}
                hasTooltip={true}
                tooltipContent={tHtml('investments.auto_investment.tooltip.initial_rating')}
              />
              <MultiSelectProjectRatingInput
                name={'rating_profitus'}
                setFieldValue={setFieldValue}
                isDisabled={
                  !autoInvestmentConfig[selectedPack].criteria[
                    InvestorAutoInvestmentConfigCriteriaEnum.RATING_PROFITUS
                  ]
                }
                isSelectAllEnabled={true}
              />
            </div>
            <div className={'form-input'}>
              <Label
                htmlFor={'country'}
                title={tHtml('placeholder.select_country')}
                isBold={true}
                hasTooltip={true}
                tooltipContent={tHtml('investments.auto_investment.tooltip.country')}
              />
              <MultiSelectAvailableProjectCountries
                name={'country'}
                setFieldValue={setFieldValue}
                isDisabled={
                  !autoInvestmentConfig[selectedPack].criteria[
                    InvestorAutoInvestmentConfigCriteriaEnum.COUNTRY
                  ]
                }
                isSelectAllEnabled={true}
              />
            </div>
          </Card>
          <Card className={'card'}>
            <div className={'form-input mb-3'}>
              <Label
                htmlFor={'investment_purpose'}
                title={tHtml('placeholder.select_investment_purpose')}
                isBold={true}
                hasTooltip={true}
                tooltipContent={tHtml('investments.auto_investment.tooltip.investment_purpose')}
              />
              <MultiSelectInvestmentPurpose
                name={'investment_purpose'}
                setFieldValue={setFieldValue}
                isDisabled={
                  !autoInvestmentConfig[selectedPack].criteria[
                    InvestorAutoInvestmentConfigCriteriaEnum.INVESTMENT_PURPOSE
                  ]
                }
                isSelectAllEnabled={true}
              />
            </div>
            <div className={'form-input'}>
              <Label
                htmlFor={'security_measures'}
                title={tHtml('placeholder.select_security_measures')}
                isBold={true}
                hasTooltip={true}
                tooltipContent={tHtml('investments.auto_investment.tooltip.security_measures')}
              />
              <MultiSelectSecurityMeasures
                name={'security_measures'}
                setFieldValue={setFieldValue}
                isDisabled={
                  !autoInvestmentConfig[selectedPack].criteria[
                    InvestorAutoInvestmentConfigCriteriaEnum.SECURITY_MEASURES
                  ]
                }
                isSelectAllEnabled={true}
              />
            </div>
          </Card>
          <div className={'form-input mb-3'}>
            <CheckboxInput
              name={'agreement'}
              placeholder={tHtml('placeholder.auto_investment_agreement')}
              showPlaceholder={true}
            />
          </div>
          <p className={'text-muted mb-3'}>{tHtml('investments.auto_investment.description')}</p>
          <div className="d-flex justify-content-end">
            {strategy ? (
              <HighlightButton
                type="button"
                className={'btn btn-highlight w-50 fw-normal me-3'}
                onClick={() => deleteStrategy()}
                disabled={!!strategy.activated_at}
              >
                {tHtml('investments.auto_investment.delete')}
              </HighlightButton>
            ) : (
              <HighlightButton
                type="button"
                className={'btn btn-highlight w-50 fw-normal me-3'}
                onClick={() => {
                  setFormikState((state) => ({
                    ...state,
                    submitCount: state.submitCount + 1,
                  }));

                  validateForm().then((validationErrors) => {
                    if (Object.keys(validationErrors).length > 0) {
                      setTouched(setNestedObjectValues(validationErrors, true));
                      return;
                    } else {
                      setSubmitting(true);
                      predictInvestments(values, setErrors).finally(() => {
                        setSubmitting(false);
                      });
                    }
                  });
                }}
                disabled={isSubmitting}
              >
                {tHtml('investments.auto_investment.predict')}
              </HighlightButton>
            )}
            <PrimaryButton
              className={'btn btn-primary w-50 fw-normal'}
              type="submit"
              disabled={isSubmitting}
            >
              {strategy
                ? tHtml('investments.auto_investment.update')
                : tHtml('investments.auto_investment.create')}
            </PrimaryButton>
          </div>
          {prediction && (
            <Row className={'mt-5'}>
              <Col xl={4} lg={6} md={12} className={'mb-3'}>
                <DataBlockLarge
                  title={t('placeholder.estimated_investment_amount')}
                  content={t('common.money', { value: prediction.estimated_investment_amount })}
                />
              </Col>
              <Col xl={4} lg={6} md={12} className={'mb-3'}>
                <DataBlockLarge
                  title={t('placeholder.estimated_project_count')}
                  content={String(prediction.estimated_project_count)}
                />
              </Col>
              <Col xl={4} lg={6} md={12} className={'mb-3'}>
                <DataBlockLarge
                  title={t('placeholder.real_project_count')}
                  content={String(prediction.real_project_count)}
                />
              </Col>
            </Row>
          )}
        </Form>
      )}
    </Formik>
  );
};
