import React from 'react';
import useTranslation from 'helpers/useTranslation';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';
import ProjectTopData from './ProjectTopData';
import ProjectTopTitle from './ProjectTopTitle';
import ProjectTopBuyButton from './ProjectTopBuyButton';
import ProjectGallery from 'components/Project/ProjectGallery';

type Props = SecondaryMarketInvestmentContextState;

const ProjectTopMobile: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  const { tHtml } = useTranslation();

  if (!secondaryMarketInvestment) return null;

  return (
    <>
      <div className={'mb-4'}>
        <h2>{tHtml('secondary_market.project')}</h2>
      </div>
      <section>
        <ProjectTopTitle />
        <div className={'mb-4'}>
          <ProjectGallery pictures={secondaryMarketInvestment.pictures} />
        </div>
        <ProjectTopData />
        <ProjectTopBuyButton className={'mb-2'} />
      </section>
    </>
  );
};

export default withSecondaryMarketInvestment(ProjectTopMobile);
