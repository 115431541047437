import React from 'react';
import { BlurredWrapper } from './DataBlockBlurred.style';

interface DataBlockProps {
  title: string;
  data: string;
}

export const DataBlockBlurred: React.FC<DataBlockProps> = ({ title, data }) => {
  return (
    <div className={'copy-data-block'}>
      <BlurredWrapper>
        <div className={'title'}>{title}</div>
        <div className={'text notranslate'}>{data}</div>
      </BlurredWrapper>
    </div>
  );
};

export default DataBlockBlurred;
