import { ErrorMessage, useField } from 'formik';
import React, { useEffect } from 'react';
import useTranslation from 'helpers/useTranslation';
import Select, { ActionMeta } from 'react-select';

import ScrollToOnError from './ScrollToOnError';
import { ReactSelectOption } from './Selects/ReactSelectInput';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

export interface SelectProps extends StateManagerProps {
  name: string;
  options: OptionsOrGroups | undefined;
  defaultValue?: string | number | undefined;
  hideLabel?: boolean;
  highlightLabel?: boolean;
  onFieldChange?: (value: any) => void;
  noOptionNewMessage?: string;
}

export type SelectEnumProps = Omit<SelectProps, 'options'>;

export interface SelectOption {
  label: string | JSX.Element;
  value?: string | number | boolean | undefined;
  customText?: string;
  isDisabled?: boolean;
}

export interface GroupOptions {
  readonly options: readonly SelectOption[];
  readonly label?: string;
}

export type OptionsOrGroups = (SelectOption | GroupOptions)[];

const SelectInput: React.FC<SelectProps> = ({
  options,
  hideLabel,
  highlightLabel = false,
  onChange,
  onFieldChange,
  noOptionNewMessage,
  ...props
}) => {
  const { tHtml } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  if (!props.placeholder) {
    props.placeholder = tHtml('placeholder.' + props.name);
  }

  const selectValue = () => {
    if (!options) return '';

    const v = (value: string | number | undefined) => {
      const t = options.reduce<SelectOption | undefined>((element, o) => {
        if (element) {
          return element;
        }

        if ((o as GroupOptions)?.options) {
          const val = (o as GroupOptions).options.find((o) => o.value === value);

          if (val?.value) {
            return val;
          }
        }

        if ((o as SelectOption)?.value === value) {
          return o as SelectOption;
        }

        return undefined;
      }, undefined);

      return t ?? '';
    };

    if (Array.isArray(field.value)) {
      return field.value.map((value) => {
        return v(value) ?? '';
      });
    }
    return v(field.value);
  };

  useEffect(() => {
    if (onFieldChange) onFieldChange(field.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const isError = meta?.touched && !!meta?.error;
  return (
    <>
      <div
        className={`${!highlightLabel ? 'form-label-group force-show in-border site-input' : ''} ${
          isError ? 'is-invalid' : ''
        }`}
      >
        <ScrollToOnError name={props.name} isError={isError}>
          {!hideLabel && (
            <label className={highlightLabel ? 'form-question-label' : ''} htmlFor={props.name}>
              {props.placeholder}
            </label>
          )}
          <Select
            options={options}
            onBlur={field.onBlur}
            value={selectValue()}
            className={'fancy-select'}
            classNamePrefix={'fancy-select'}
            isLoading={options === undefined}
            loadingMessage={() => tHtml('common.loading')}
            noOptionsMessage={() => noOptionNewMessage ?? tHtml('common.no_options')}
            placeholder={() => tHtml('common.select')}
            onMenuScrollToBottom={(event) => {
              if (event.cancelable) event.preventDefault();
            }}
            onMenuScrollToTop={(event) => {
              if (event.cancelable) event.preventDefault();
            }}
            onChange={(option: any, meta: ActionMeta<any>) => {
              if (Array.isArray(option)) {
                helper.setValue((option as ReactSelectOption[]).map((option) => option.value));
              } else {
                helper.setValue(option?.value);
              }
              helper.setTouched(true, true);
              onChange && onChange(option, meta);
            }}
            {...props}
          />
        </ScrollToOnError>
      </div>
      {isError && (
        <div className={'invalid-feedback'}>{props.name && <ErrorMessage name={props.name} />}</div>
      )}
    </>
  );
};

SelectInput.defaultProps = {
  hideLabel: false,
};

export default SelectInput;
