const Breakpoints = {
  XSM: 376,
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
};

export default Breakpoints;
