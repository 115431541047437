import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import useTranslation from 'helpers/useTranslation';

import DatePickerHeader from 'components/Formik/Inputs/DatepickerInput/DatePickerHeader';
import { DateInput } from 'components/Inputs';
import Button from './Buttons/Button';
import CancelButton from './Buttons/CancelButton';
import PredefinedDates from './Other/PredefinedDates';
import AdvanceFilter, { AdvanceFilterProps } from './AdvanceFilter';
import { endOfDay } from 'date-fns';

const DateFilter: React.FC<AdvanceFilterProps> = ({
  start,
  end,
  hidePredefinedDates,
  column,
  placement,
  fireEventAfterSaved,
  fireEventAfterCleared,
}) => {
  const { t, currentLanguage, tHtml } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date | null>(start);
  const [endDate, setEndDate] = useState<Date | null>(end);

  const title = useMemo<string>(() => {
    if (startDate && endDate) {
      return `${t('common.date', { date: startDate })} - ${t('common.date', { date: endDate })}`;
    } else if (startDate) {
      return `${t('common.date', { date: startDate })}`;
    }

    return '';
    // eslint-disable-next-line
  }, [t, startDate, endDate]);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };

  const clearValues = () => {
    setStartDate(null);
    setEndDate(null);
    column.setFilter({ startDate: null, endDate: null });

    if (fireEventAfterCleared) {
      fireEventAfterCleared(null, null);
    }
  };

  const onCancelButtonClicked = () => {
    clearValues();
  };

  const onSaveButtonClicked = async () => {
    setIsOpen(false);

    const endD = endDate ? endOfDay(endDate) : null;
    column.setFilter({ startDate: startDate, endDate: endD });

    if (fireEventAfterSaved) {
      fireEventAfterSaved(startDate, endD);
    }
  };

  useEffect(() => {
    !!start && setStartDate(start);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    !!end && setEndDate(end);
  }, [end]);

  return (
    <AdvanceFilter
      hidePredefinedDates={hidePredefinedDates}
      icon={'icon-calendar'}
      title={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      placement={placement}
    >
      <div className={'title'}>{tHtml('dashboard.set_the_time_frame')}</div>
      {!hidePredefinedDates && (
        <div>
          <PredefinedDates startDate={startDate} endDate={endDate} onChange={onChange} />
        </div>
      )}
      <hr className={'divider'} />

      <div className={'action-options-inputs'}>
        <DateInput
          label={t('common.from')}
          name={'start'}
          value={t('common.date', { date: startDate })}
          onChange={(e) => e.preventDefault()}
        />
        <DateInput
          label={t('common.to')}
          name={'end'}
          value={t('common.date', { date: endDate }) as string}
          onChange={(e) => e.preventDefault()}
        />
      </div>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        calendarStartDay={1}
        endDate={endDate}
        disabledKeyboardNavigation
        selectsRange
        inline
        renderCustomHeader={({ date, increaseMonth, decreaseMonth, changeYear, changeMonth }) => (
          <DatePickerHeader
            increaseMonth={increaseMonth}
            decreaseMonth={decreaseMonth}
            locale={currentLanguage}
            date={date}
            changeMonth={changeMonth}
            changeYear={changeYear}
            startFromYear={new Date().getFullYear()}
            endYearRange={30}
            hasFutureRange={false}
            showTitle={false}
          />
        )}
      />
      <div className={'action-options-btns'}>
        <CancelButton onClick={() => onCancelButtonClicked()}>{t('common.clear')}</CancelButton>
        <Button onClick={() => onSaveButtonClicked()}>{t('common.save')}</Button>
      </div>
    </AdvanceFilter>
  );
};

export default DateFilter;
