import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormGroup } from 'reactstrap';

import Api from 'api';
import {
  CompanyAddressResponseDto,
  UpdateCompanyProfileContactInfoRequestDto,
} from 'api/types/shared/company/profile';
import { navigate } from 'helpers';
import { RouteList } from 'routes';
import { success } from 'services/toastr';
import { setGlobalLoading } from 'modules/app/actions';
import PrimaryButton from 'components/Button/PrimaryButton';
import { CheckboxInput, TextInput } from 'components/Formik';
import { CompanyAddressTypeEnum } from 'scopes/user/helpers/enums/CompanyAddressTypeEnum';
import {
  SelectCompanyManagementStructureInput,
  SelectCountryInput,
} from 'components/Formik/Selects';
import { SelectRepresentativeTitleInput } from 'components/Formik/Selects/Company';
import { SelectGovDocumentTypeInput } from 'components/Formik/Selects/User';
import { DatepickerInput, PhoneNumberInput } from 'components/Formik/Inputs';
import { CompanyRepresentativeTitleEnum } from 'scopes/user/helpers/enums/CompanyRepresentativeTitleEnum';
import { CompanyValidationSchema } from '../../../user/pages/CreateCompany/ValidationSchemas/CompanyValidationSchema';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';

interface IFormValues extends UpdateCompanyProfileContactInfoRequestDto {
  residential_address_street: string;
  residential_address_address: string;
  residential_address_city: string;
  contact_address_street: string;
  contact_address_address: string;
  contact_address_city: string;
  residence: string;
  is_contact_address_match_residential: boolean;
}

const ContactInfo: React.FC = () => {
  const { t, tHtml } = useTranslation();

  const { data: companyProfileData, setQueryData: updateCompanyProfileData } =
    useUserCompanyProfileQuery();

  useEffect(() => {
    if (companyProfileData) {
      setInitialFormValues({
        name: companyProfileData?.company.name ?? '',
        legal_code: companyProfileData?.company.legal_code ?? '',
        vat_code: companyProfileData?.company.vat_code ?? '',
        email: companyProfileData?.company.email ?? '',
        phone: companyProfileData?.company.phone ?? '',
        country: companyProfileData?.company.country ?? '',
        representative_title: companyProfileData?.company.representative_title ?? '',
        is_representative_executive:
          companyProfileData?.company.is_representative_executive ?? false,
        established_at: companyProfileData?.company.established_at ?? '',
        executive_first_name: companyProfileData?.company.executive_first_name ?? '',
        executive_last_name: companyProfileData?.company.executive_last_name ?? '',
        executive_address_house_number:
          companyProfileData?.company.executive_address_house_number ?? '',
        executive_address_street: companyProfileData?.company.executive_address_street ?? '',
        executive_address_city: companyProfileData?.company.executive_address_city ?? '',
        executive_address_country: companyProfileData?.company.executive_address_country ?? '',
        executive_phone: companyProfileData?.company.executive_phone ?? '',
        executive_email: companyProfileData?.company.executive_email ?? '',
        executive_tax_country: companyProfileData?.company.executive_tax_country ?? '',
        executive_nationality: companyProfileData?.company.executive_nationality ?? '',
        executive_gov_code: companyProfileData?.company.executive_gov_code ?? '',
        executive_gov_document_type: companyProfileData?.company.executive_gov_document_type ?? '',
        executive_gov_document_number:
          companyProfileData?.company.executive_gov_document_number ?? '',
        executive_gov_document_issue_country:
          companyProfileData?.company.executive_gov_document_issue_country ?? '',
        executive_gov_document_expiration_date:
          companyProfileData?.company.executive_gov_document_expiration_date ?? '',
        executive_is_pep: companyProfileData?.company.executive_is_pep ?? false,
        contact_address_street:
          companyProfileData?.addresses?.find((adr) => adr.type === CompanyAddressTypeEnum.CONTACT)
            ?.street ?? '',
        contact_address_address:
          companyProfileData?.addresses?.find((adr) => adr.type === CompanyAddressTypeEnum.CONTACT)
            ?.address ?? '',
        contact_address_city:
          companyProfileData?.addresses?.find((adr) => adr.type === CompanyAddressTypeEnum.CONTACT)
            ?.city ?? '',
        residential_address_address:
          companyProfileData?.addresses?.find(
            (adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL,
          )?.address ?? '',
        residential_address_city:
          companyProfileData?.addresses?.find(
            (adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL,
          )?.city ?? '',
        residential_address_street:
          companyProfileData?.addresses?.find(
            (adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL,
          )?.street ?? '',
        residence:
          companyProfileData?.addresses?.find(
            (adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL,
          )?.country ?? '',
        addresses: [],
        is_contact_address_match_residential: contactMatchesResidential(
          companyProfileData?.addresses ?? [],
        ),
        bank_name: companyProfileData?.company.bank_name ?? '',
        iban: companyProfileData?.company.iban ?? '',
        management_structure: companyProfileData?.company.management_structure ?? '',
      });
      setGlobalLoading(false);
    }
  }, [companyProfileData]);

  const contactMatchesResidential = (addrs: CompanyAddressResponseDto[] | undefined) => {
    if (!addrs) return true;
    const residentialAddress = addrs.find((adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL);
    const contactAddress = addrs.find((adr) => adr.type === CompanyAddressTypeEnum.CONTACT);
    if (!residentialAddress || !contactAddress) return true;
    return (
      contactAddress.street === residentialAddress.street &&
      contactAddress.address === residentialAddress.address &&
      contactAddress.city === residentialAddress.city
    );
  };

  const [initialFormValues, setInitialFormValues] = useState<IFormValues>({
    name: companyProfileData?.company.name ?? '',
    legal_code: companyProfileData?.company.legal_code ?? '',
    vat_code: companyProfileData?.company.vat_code ?? '',
    email: companyProfileData?.company.email ?? '',
    phone: companyProfileData?.company.phone ?? '',
    country: companyProfileData?.company.country ?? '',
    representative_title: companyProfileData?.company.representative_title ?? '',
    is_representative_executive: companyProfileData?.company.is_representative_executive ?? false,
    established_at: companyProfileData?.company.established_at ?? '',
    executive_first_name: companyProfileData?.company.executive_first_name ?? '',
    executive_last_name: companyProfileData?.company.executive_last_name ?? '',
    executive_address_house_number:
      companyProfileData?.company.executive_address_house_number ?? '',
    executive_address_street: companyProfileData?.company.executive_address_street ?? '',
    executive_address_city: companyProfileData?.company.executive_address_city ?? '',
    executive_address_country: companyProfileData?.company.executive_address_country ?? '',
    executive_phone: companyProfileData?.company.executive_phone ?? '',
    executive_email: companyProfileData?.company.executive_email ?? '',
    executive_tax_country: companyProfileData?.company.executive_tax_country ?? '',
    executive_nationality: companyProfileData?.company.executive_nationality ?? '',
    executive_gov_code: companyProfileData?.company.executive_gov_code ?? '',
    executive_gov_document_type: companyProfileData?.company.executive_gov_document_type ?? '',
    executive_gov_document_number: companyProfileData?.company.executive_gov_document_number ?? '',
    executive_gov_document_issue_country:
      companyProfileData?.company.executive_gov_document_issue_country ?? '',
    executive_gov_document_expiration_date:
      companyProfileData?.company.executive_gov_document_expiration_date ?? '',
    executive_is_pep: companyProfileData?.company.executive_is_pep ?? false,
    contact_address_street:
      companyProfileData?.addresses?.find((adr) => adr.type === CompanyAddressTypeEnum.CONTACT)
        ?.street ?? '',
    contact_address_address:
      companyProfileData?.addresses?.find((adr) => adr.type === CompanyAddressTypeEnum.CONTACT)
        ?.address ?? '',
    contact_address_city:
      companyProfileData?.addresses?.find((adr) => adr.type === CompanyAddressTypeEnum.CONTACT)
        ?.city ?? '',
    residential_address_address:
      companyProfileData?.addresses?.find((adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL)
        ?.address ?? '',
    residential_address_city:
      companyProfileData?.addresses?.find((adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL)
        ?.city ?? '',
    residential_address_street:
      companyProfileData?.addresses?.find((adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL)
        ?.street ?? '',
    residence:
      companyProfileData?.addresses?.find((adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL)
        ?.country ?? '',
    addresses: [],
    is_contact_address_match_residential: contactMatchesResidential(
      companyProfileData?.addresses ?? [],
    ),
    bank_name: companyProfileData?.company.bank_name ?? '',
    iban: companyProfileData?.company.iban ?? '',
    management_structure: companyProfileData?.company.management_structure ?? '',
  });

  const formatCompanyUpdateRequest = useCallback(
    (request: IFormValues): UpdateCompanyProfileContactInfoRequestDto => {
      const {
        is_contact_address_match_residential,
        residential_address_street,
        residential_address_address,
        residential_address_city,
        contact_address_street,
        contact_address_address,
        contact_address_city,
        residence,
      } = request;

      const residentialAddress = {
        street: residential_address_street,
        address: residential_address_address,
        city: residential_address_city,
        country: residence,
        type: CompanyAddressTypeEnum.RESIDENTIAL,
      };

      const contactAddress = { ...residentialAddress };
      contactAddress.type = CompanyAddressTypeEnum.CONTACT;

      if (!is_contact_address_match_residential) {
        contactAddress.street = contact_address_street;
        contactAddress.address = contact_address_address;
        contactAddress.city = contact_address_city;
        contactAddress.country = residence;
      }

      const executiveDetails: {
        executive_address_house_number: string;
        executive_address_street: string;
        executive_address_city: string;
        executive_address_country: string;
        executive_first_name: string;
        executive_gov_document_type: string;
        executive_last_name: string;
        executive_phone: string;
        is_representative_executive: boolean;
        executive_gov_document_expiration_date: string;
        executive_gov_document_number: string;
        executive_email: string;
        executive_tax_country: string;
        executive_nationality: string;
        executive_gov_code: string;
        executive_gov_document_issue_country: string;
      } = {
        is_representative_executive: request.is_representative_executive,
        executive_first_name: request.executive_first_name ?? '',
        executive_last_name: request.executive_last_name ?? '',
        executive_address_house_number: request.executive_address_house_number ?? '',
        executive_address_street: request.executive_address_street ?? '',
        executive_address_city: request.executive_address_city ?? '',
        executive_address_country: request.executive_address_country ?? '',
        executive_phone: request.executive_phone ?? '',
        executive_email: request.executive_email ?? '',
        executive_tax_country: request.executive_tax_country ?? '',
        executive_nationality: request.executive_nationality ?? '',
        executive_gov_code: request.executive_gov_code ?? '',
        executive_gov_document_type: request.executive_gov_document_type ?? '',
        executive_gov_document_number: request.executive_gov_document_number ?? '',
        executive_gov_document_issue_country: request.executive_gov_document_issue_country ?? '',
        executive_gov_document_expiration_date:
          request.executive_gov_document_expiration_date ?? '',
      };

      return {
        name: request.name,
        legal_code: request.legal_code,
        vat_code: request.vat_code,
        email: request.email,
        phone: request.phone,
        country: request.residence,
        representative_title: request.representative_title,
        established_at: request.established_at,
        ...executiveDetails,
        executive_is_pep: request.executive_is_pep,
        bank_name: request.bank_name,
        iban: request.iban,
        management_structure: request.management_structure,
        addresses: [residentialAddress, contactAddress],
      };
    },
    [],
  );

  const onSubmit = useCallback(
    async (request: IFormValues, helpers: FormikHelpers<IFormValues>) => {
      try {
        const formattedRequest = formatCompanyUpdateRequest(request);
        const response = await Api.shared.company.profile.storeUserProfileContactInfo(
          formattedRequest,
        );
        updateCompanyProfileData(response);
        success(tHtml('common.updated_successfully'));
        navigate(RouteList.COMPANY.PROFILE.HOME);
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [formatCompanyUpdateRequest, tHtml, updateCompanyProfileData],
  );

  return (
    <React.Fragment>
      <h1>{tHtml('menu.contact_info')}</h1>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          validationSchema={CompanyValidationSchema(false, !!companyProfileData?.verified)}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <div className={'form-input'}>
                  <TextInput
                    disabled={companyProfileData?.verified ?? true}
                    name={'name'}
                    placeholder={t('placeholder.company.name')}
                  />
                </div>
                <div className={'form-input'}>
                  <TextInput
                    disabled={companyProfileData?.verified ?? true}
                    name={'legal_code'}
                    placeholder={t('placeholder.company.legal_code')}
                  />
                </div>
                <div className={'form-input'}>
                  <TextInput
                    disabled={companyProfileData?.verified ?? true}
                    name={'vat_code'}
                    placeholder={t('placeholder.vat_code')}
                  />
                </div>
                <div className={'form-input'}>
                  <DatepickerInput
                    disabled={companyProfileData?.verified ?? true}
                    name={'established_at'}
                    label={t('placeholder.company.established_at')}
                  />
                </div>
                <div className={'form-input'}>
                  <TextInput name={'email'} placeholder={t('placeholder.email')} />
                </div>
                <div className={'form-input'}>
                  <PhoneNumberInput name="phone" label={t('placeholder.phone')} />
                </div>
                <div className={'form-input'}>
                  <TextInput name={'bank_name'} placeholder={t('placeholder.company.bank_name')} />
                </div>
                <div className={'form-input'}>
                  <TextInput name={'iban'} placeholder={t('placeholder.company.iban')} />
                </div>
                <div className={'mb-3'}>
                  <SelectCompanyManagementStructureInput
                    name={'management_structure'}
                    placeholder={tHtml('placeholder.company.management_structure')}
                  />
                </div>
                <div className={'form-input'}>
                  <SelectCountryInput
                    name={'residence'}
                    placeholder={t('placeholder.residence')}
                    hasTooltip={true}
                    // isDisabled={companyProfileData?.verified ?? true}
                    tooltipContent={tHtml('company.contact_info.residence.tooltip')}
                  />
                </div>
                <div className={'form-input'}>
                  <TextInput
                    name={'residential_address_street'}
                    placeholder={t('placeholder.company.street')}
                  />
                </div>
                <div className={'form-input'}>
                  <TextInput
                    name={'residential_address_address'}
                    placeholder={t('placeholder.company.house_number')}
                  />
                </div>
                <div className={'form-input'}>
                  <TextInput
                    name={'residential_address_city'}
                    placeholder={t('placeholder.company.city')}
                  />
                </div>
                <div className={'form-input'}>
                  <CheckboxInput
                    name={'is_contact_address_match_residential'}
                    showPlaceholder={true}
                    placeholder={tHtml('placeholder.contact_address_matches_residence_address')}
                  />
                </div>
                {!values.is_contact_address_match_residential ? (
                  <>
                    <h2 className="mb-3">{tHtml('placeholder.company.contact_details')}</h2>
                    <div className={'form-input'}>
                      <TextInput
                        name={'contact_address_street'}
                        placeholder={t('placeholder.company.street')}
                      />
                    </div>
                    <div className={'form-input'}>
                      <TextInput
                        name={'contact_address_address'}
                        placeholder={t('placeholder.company.house_number')}
                      />
                    </div>
                    <div className={'form-input'}>
                      <TextInput
                        name={'contact_address_city'}
                        placeholder={t('placeholder.company.city')}
                      />
                    </div>
                  </>
                ) : null}
                <hr />
                <h2 className="mb-3">{tHtml('placeholder.company.executive.details')}</h2>
                <div className={'mb-3'}>
                  <SelectRepresentativeTitleInput
                    isDisabled={companyProfileData?.verified ?? true}
                    name={'representative_title'}
                    placeholder={tHtml('placeholder.company.representative_title')}
                  />
                </div>
                {values.representative_title !== CompanyRepresentativeTitleEnum.DIRECTOR && (
                  <>
                    <div className={'form-input'}>
                      <CheckboxInput
                        disabled={companyProfileData?.verified ?? true}
                        name={'is_representative_executive'}
                        showPlaceholder={true}
                        placeholder={tHtml('placeholder.company.executive.is_representative')}
                      />
                    </div>
                    {!values.is_representative_executive ? (
                      <>
                        <div className={'form-input'}>
                          <TextInput
                            disabled={companyProfileData?.verified ?? true}
                            name={'executive_first_name'}
                            placeholder={t('placeholder.first_name')}
                          />
                        </div>
                        <div className={'form-input'}>
                          <TextInput
                            disabled={companyProfileData?.verified ?? true}
                            name={'executive_last_name'}
                            placeholder={t('placeholder.last_name')}
                          />
                        </div>
                        <div className={'form-input'}>
                          <TextInput
                            name={'executive_address_house_number'}
                            placeholder={t('placeholder.house_number')}
                          />
                        </div>
                        <div className={'form-input'}>
                          <TextInput
                            name={'executive_address_street'}
                            placeholder={t('placeholder.street')}
                          />
                        </div>
                        <div className={'mb-3'}>
                          <TextInput
                            name={'executive_address_city'}
                            placeholder={t('placeholder.city')}
                          />
                        </div>
                        <div className={'form-input'}>
                          <SelectCountryInput
                            isDisabled={companyProfileData?.verified ?? true}
                            name={'executive_address_country'}
                            placeholder={t('placeholder.country')}
                          />
                        </div>
                        <div className={'form-input'}>
                          <PhoneNumberInput
                            name={'executive_phone'}
                            label={t('placeholder.phone')}
                            dependencyFieldName="executive_address_country"
                          />
                        </div>
                        <div className={'mb-3'}>
                          <TextInput
                            name={'executive_email'}
                            placeholder={t('placeholder.email')}
                          />
                        </div>
                        <div className={'mb-3'}>
                          <SelectCountryInput
                            isDisabled={companyProfileData?.verified ?? true}
                            name={'executive_tax_country'}
                            placeholder={t('placeholder.executive_tax_country')}
                          />
                        </div>
                        <div className={'form-input'}>
                          <SelectCountryInput
                            isDisabled={companyProfileData?.verified ?? true}
                            name={'executive_nationality'}
                            placeholder={t('placeholder.executive_nationality')}
                          />
                        </div>
                        <div className={'form-input'}>
                          <TextInput
                            disabled={companyProfileData?.verified ?? true}
                            name={'executive_gov_code'}
                            placeholder={t('placeholder.gov_code')}
                          />
                        </div>
                        <div className={'form-input'}>
                          <SelectGovDocumentTypeInput
                            disabled={companyProfileData?.verified ?? true}
                            name={'executive_gov_document_type'}
                            placeholder={tHtml('placeholder.gov.document.type')}
                          />
                        </div>
                        <div className={'mb-3'}>
                          <TextInput
                            disabled={companyProfileData?.verified ?? true}
                            name={'executive_gov_document_number'}
                            placeholder={t('placeholder.gov.document.number')}
                          />
                        </div>
                        <div className={'form-input'}>
                          <SelectCountryInput
                            isDisabled={companyProfileData?.verified ?? true}
                            name={'executive_gov_document_issue_country'}
                            placeholder={t('placeholder.gov.document.issuer_country')}
                          />
                        </div>
                        <div className={'form-input'}>
                          <DatepickerInput
                            disabled={companyProfileData?.verified ?? true}
                            hasFutureRange={true}
                            name={'executive_gov_document_expiration_date'}
                            label={t('placeholder.gov.document.expiration_date')}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                )}
                <div className={'form-input'}>
                  <CheckboxInput
                    name={'executive_is_pep'}
                    showPlaceholder={true}
                    placeholder={tHtml('placeholder.company.executive.is_pep')}
                  />
                </div>
                <div className={'mt-5 mb-4 d-flex flex-row-reverse'}>
                  <PrimaryButton
                    className={'btn btn-primary w-50'}
                    submitting={isSubmitting}
                    type={'submit'}
                  >
                    {tHtml('common.submit')}
                  </PrimaryButton>
                </div>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactInfo);
