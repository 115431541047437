import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { CopyDataBlock } from 'components/DataBlock';
import { useWalletQuery } from 'api/queries/useWalletQuery';
import DataBlockBlurred from 'components/DataBlock/DataBlockBlurred';
import PrimaryButton from 'components/Formik/PrimaryButton';
import Api from 'api';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import ConfirmationModal from 'components/Modal/Modals/ConfirmationModal';
import Loader from 'components/Loader';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';

interface MoneyInByBankTransferProps {
  userType?: UserTypeEnum;
}

const MoneyInByBank: React.FC<MoneyInByBankTransferProps> = ({ ...props }) => {
  const { t, tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [loading, setLoading] = useState<boolean>(false);

  const { data: wallet, setQueryData } = useWalletQuery();

  const viban = wallet?.viban;

  const handleCreateViban = async () => {
    setLoading(true);
    Api.wallet.createVirtualIban().then((res) => {
      setQueryData({ viban: res.viban });
      setLoading(false);
    });
  };

  return (
    <div className={'wallet-details'}>
      <p> {tHtml('wallet.create_new_payment')}</p>
      {viban ? (
        <>
          <CopyDataBlock title={t('wallet.recipient_name')} data={viban?.holder ?? '-'} />
          <CopyDataBlock title={t('wallet.iban')} data={viban?.iban ?? '-'} />
          <CopyDataBlock title={t('wallet.swift')} data={viban?.bic ?? '-'} />
          <CopyDataBlock
            title={t('wallet.transfer_reason_title')}
            data={
              props && props.userType === UserTypeEnum.PROJECT_DEVELOPER
                ? t('wallet.developer_transfer_reason')
                : t('wallet.transfer_reason')
            }
          />
        </>
      ) : (
        <>
          <DataBlockBlurred title={t('wallet.recipient_name')} data={'PROFITUS'} />
          <DataBlockBlurred title={t('wallet.iban')} data={'FR1234567891234567891FAKEIBAN'} />
          <DataBlockBlurred title={t('wallet.swift')} data={'FAKESWIFTBIC'} />
          <DataBlockBlurred
            title={t('wallet.transfer_reason_title')}
            data={
              props && props.userType === UserTypeEnum.PROJECT_DEVELOPER
                ? t('wallet.developer_transfer_reason')
                : t('wallet.transfer_reason')
            }
          />
          <div className={'d-flex justify-content-center'}>
            {loading ? (
              <PrimaryButton disabled={true}>
                <Loader size={'sm'} />
              </PrimaryButton>
            ) : (
              <PrimaryButton
                className={'btn btn-primary w-75 mt-5 mx-auto'}
                onClick={() =>
                  showModal(
                    <ConfirmationModal
                      title={tHtml('wallet.confirm_viban_creation')}
                      onSubmit={handleCreateViban}
                    />,
                  )
                }
              >
                {tHtml('wallet.create_viban')}
              </PrimaryButton>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MoneyInByBank;
