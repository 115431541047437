import { ErrorMessage, useField } from 'formik';
import React, { useEffect } from 'react';
import useTranslation from 'helpers/useTranslation';
import ScrollToOnError from './ScrollToOnError';

export interface TextInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name: string;
  onFieldChange?: (value: any) => void;
  hideLabel?: boolean;
  isHighlight?: boolean;
  labelRender?: JSX.Element;
}

const TextInput: React.FC<TextInputProps> = ({
  onChange,
  onFieldChange,
  labelRender,
  ...props
}) => {
  const { t, currentLanguage } = useTranslation();
  const [field, meta, helper] = useField(props.name);

  if (!props.placeholder) {
    props.placeholder = t('placeholder.' + props.name);
  }

  useEffect(() => {
    if (meta.touched) {
      helper.setTouched(true, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  useEffect(() => {
    if (onFieldChange) onFieldChange(field.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  const isError = meta?.touched && !!meta?.error;

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e);
    if (onChange) onChange(e);
  };

  const getClassName = (isHighlight: boolean, isError: boolean): string => {
    let baseStyle = 'form-label-group in-border';

    baseStyle = isHighlight
      ? baseStyle.concat(' site-input highlight')
      : baseStyle.concat(' site-input');
    baseStyle = isError ? baseStyle.concat(' is-invalid') : baseStyle;

    return baseStyle;
  };

  const { hideLabel, ...otherProps } = props;

  return (
    <>
      <div className={getClassName(!!props.isHighlight, !!isError)}>
        <ScrollToOnError name={props.name} isError={isError}>
          <input
            onChange={onValueChange}
            onBlur={field.onBlur}
            onInput={() => helper.setTouched(true, true)}
            value={field.value}
            placeholder={props.placeholder}
            className={`form-control${isError ? ' is-invalid' : ''}`}
            {...otherProps}
          />

          {!hideLabel &&
            (labelRender !== undefined ? (
              labelRender
            ) : (
              <label htmlFor={props.name} className="pb-3 label">
                {props.placeholder}
              </label>
            ))}
        </ScrollToOnError>
      </div>
      {isError && (
        <div className={'invalid-feedback text-pre'}>
          {props.name && (
            <ErrorMessage
              component={'div'}
              name={props.name}
              render={(msg: string | string[]) => {
                if (typeof msg === 'string') {
                  return msg;
                }

                return msg.join('\n');
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
export default TextInput;
