/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
// import { useSwiperSlide } from 'swiper/react/swiper-react';
import ReactPlayer from 'react-player';
import { Item as LightboxItem } from 'react-photoswipe-gallery/dist/item';
import ReactDOMServer, { renderToStaticMarkup } from 'react-dom/server';
import { useSwiperSlide } from 'swiper/react';

interface Props {
  videoUrl: string;
  imageUrl: string;
  thumbnailUrl: string;
}

const Video: React.FC<Props> = ({ videoUrl, imageUrl, thumbnailUrl }) => {
  const swiperSlide = useSwiperSlide();
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>({
    width: 100,
    height: 100,
  });
  const imageRef = useRef<any>();

  const [videoPlaying, setVideoPlaying] = useState<boolean>(true);
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!swiperSlide.isActive) {
      setVideoPlaying(false);
    }
  }, [videoUrl, swiperSlide, setVideoPlaying]);

  if (videoLoaded) {
    return (
      <ReactPlayer
        url={videoUrl}
        controls={true}
        playing={videoPlaying}
        onPlay={() => setVideoPlaying(true)}
        width={'100%'}
        height={'100%'}
      />
    );
  }
  const videoPlayer = () => {
    return (
      <ReactPlayer
        url={videoUrl}
        controls={true}
        playing={videoPlaying}
        onPlay={() => setVideoPlaying(true)}
        width={'100%'}
        height={'100%'}
      />
    );
  };

  return (
    <>
      <LightboxItem
        original={imageUrl}
        thumbnail={thumbnailUrl}
        width={imageSize.width}
        height={imageSize.height}
      >
        {/*<LightboxItem html={ReactDOMServer.renderToString(videoPlayer())}>*/}
        {({ ref, open }) => (
          <div
            ref={ref as React.MutableRefObject<any>}
            // onClick={open}
            className={'video'}
            onClick={() => {
              open();
              setVideoLoaded(true);
            }}
          >
            <i className={'icon icon-play-video'} />
            <img
              src={imageUrl}
              alt={'Video'}
              ref={imageRef as React.MutableRefObject<HTMLImageElement>}
              onLoad={() => {
                const image = imageRef.current as HTMLImageElement;
                setImageSize({ width: image.naturalWidth, height: image.naturalHeight });
              }}
            />
          </div>
        )}
      </LightboxItem>
      {/*</div>*/}
    </>
  );
};
export default Video;
