import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import { FilterValue } from 'react-table';
import { PaginationData } from 'api/types';

interface Props {
  isLoading: boolean;
  globalFilter: any;
  setGlobalFilter: (filterValue: FilterValue) => void;
  readonly data: PaginationData<any> | undefined;
  overrideTotalCount?: number;
}

const SearchInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState<string>(
    props.globalFilter == undefined ? '' : props.globalFilter,
  );

  useEffect(() => {
    setSearchText(props.globalFilter ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.globalFilter]);

  return (
    <>
      <form
        className={'search-input'}
        onSubmit={(e) => {
          e.preventDefault();
          props.setGlobalFilter(searchText);
        }}
      >
        <Input
          value={searchText}
          disabled={props.isLoading}
          placeholder={
            props.isLoading
              ? t('common.loading_ellipsis')
              : props.overrideTotalCount ?? props.data?.meta.total
              ? t('table.search_records_count', {
                  count: props.overrideTotalCount ?? props.data?.meta.total,
                })
              : t('table.search_records')
          }
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </form>
    </>
  );
};

export default SearchInput;
