import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { ErrorMessage, FormikProps } from 'formik';

import Api from 'api';
import { TextInput } from 'components/Formik';
import { CompanyExecutiveDto } from 'api/types/guest/prospectApplication';
import { SelectCountryInput } from 'components/Formik/Selects';
import YesNoSelector from 'components/Formik/YesNoSelector';
import FileUpload, { FileUploadHandle } from 'components/FileUpload/FileUpload';
import { PhoneNumberInput } from 'components/Formik/Inputs';
import { ApplicationDocumentsCollectionEnum } from 'scopes/guest/helpers/enums/ApplicationDocumentsCollectionEnum';

const RepresentativeForm: React.FC<FormikProps<CompanyExecutiveDto>> = (helpers) => {
  const { t, tHtml } = useTranslation();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);

  return (
    <div>
      <div className="mb-3">
        <h3>{tHtml('prospect_application.company_executive.representative_info')}</h3>
        <span>{tHtml('prospect_application.company_executive.documents_upload')}</span>
        <FileUpload
          ref={fileUploadRef}
          name={'representative_documents'}
          onPresign={(request) =>
            Api.guest.prospect_application.uploadProspectApplicationDocuments({
              files: request,
              collection:
                ApplicationDocumentsCollectionEnum.COLLECTION_PROSPECT_COMPANY_REPRESENTATIVE_DOCUMENT,
            })
          }
          disabled={helpers.isSubmitting}
        />
        <div className={'invalid-feedback-visible ms-2'}>
          <ErrorMessage name={'representative_documents'} />
        </div>
      </div>
      <div className="mb-4 mt-4">
        <TextInput name={'representative_first_name'} placeholder={t('placeholder.first_name')} />
      </div>
      <div className="mb-4">
        <TextInput name={'representative_last_name'} placeholder={t('placeholder.last_name')} />
      </div>
      <div className="mb-4 pt-xxl-0 pt-2">
        <TextInput name={'representative_gov_code'} placeholder={t('placeholder.gov_code')} />
      </div>
      <div className="mb-4">
        <TextInput name={'representative_address'} placeholder={t('placeholder.address')} />
      </div>
      <div className="mb-4">
        <PhoneNumberInput name={'representative_phone'} label={t('placeholder.phone')} />
      </div>
      <div className="mb-1">
        <TextInput name={'representative_email'} placeholder={t('placeholder.email')} />
      </div>
      <div className="mb-2">
        <SelectCountryInput
          name="representative_country"
          placeholder={t('placeholder.prospect_application.country')}
        />
      </div>
      <div className="mb-3">
        <YesNoSelector
          name={'representative_is_public_official'}
          placeholder={t('placeholder.prospect_application.is_public_official')}
        />
      </div>
      <hr />
    </div>
  );
};

export default RepresentativeForm;
