// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { default as env } from 'env.json';
import { ISocialNetwork } from './ISocialNetwork';
import { ExternalSocialSignUpResponse } from '../../api/types/guest/social';

export class GoogleSocialNetwork implements ISocialNetwork {
  setup(): Promise<void> {
    return new Promise((resolve) => {
      if (window.gapi) {
        resolve();

        return;
      }

      window.init = function () {
        const gapi = window.gapi;
        gapi.load('client:auth2', {
          callback: function () {
            window.googleApiInit = true;

            gapi.client.init({
              apiKey: env.GOOGLE_API_KEY,
              clientId: env.GOOGLE_CLIENT_ID,
              scope:
                'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
            });

            resolve();
          },
        });
      };

      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        const js = d.createElement(s);
        js.id = id;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        js.src = 'https://apis.google.com/js/platform.js?onload=init';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'google-jssdk');
    });
  }

  signUp(): Promise<ExternalSocialSignUpResponse> {
    return new Promise((resolve, reject) => {
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(
          function (currentUser) {
            const authResponse = currentUser?.getAuthResponse(true);
            if (authResponse) {
              resolve({
                token: authResponse.access_token,
              });
            } else {
              reject();
            }
          },
          function () {
            reject();
          },
        );
    });
  }
}
