import React from 'react';
import styled from 'styled-components';

interface Props {
  percentage: number;
}

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 16px;
  background-color: var(--profitus-project-card-app-bottom-color);
  position: relative;
  border-radius: 8px;
`;

const ProgressBar = styled.div<{ percentage: number }>`
  position: absolute;
  background: linear-gradient(
    to right,
    var(--profitus-progress-bar-end-color) 0%,
    var(--profitus-progress-bar-start-color) ${(props) => props.percentage}%,
    transparent ${(props) => props.percentage}%,
    transparent 100%
  );
  width: 100%;
  height: 16px;
  border-radius: 8px;
`;

const LinearProgressBar: React.FC<Props> = ({ percentage }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBar percentage={percentage} />
    </ProgressBarWrapper>
  );
};

export default LinearProgressBar;
