import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Row, Col } from 'reactstrap';

const EditProfileLayoutSkeleton: React.FC = () => {
  return (
    <Row>
      <Col className={'sidebar'} sm={4}>
        <Skeleton height={68} className={'mb-5'} />
        <Skeleton count={5} height={20} className={'mb-3'} />
      </Col>
      <Col className={'content'} sm={8}>
        <Skeleton height={1000} className={'rounded'} />
      </Col>
    </Row>
  );
};

export default EditProfileLayoutSkeleton;
