import { t } from 'i18next';

export class ProjectHelper {
  static formatLoanRatio(loan_ratio_external: number, loan_ratio_max: number | null): string {
    let r = t('project_card.loan_ratio', { value: loan_ratio_external });

    if (loan_ratio_max) {
      r += ' (' + t('project_card.loan_ratio_max', { value: loan_ratio_max }) + ')';
    }
    return r;
  }

  static formatInterestRate(interest_rate: number, max_interest_rate: number | null): string {
    if (max_interest_rate) {
      return interest_rate + ' - ' + t('common.percents', { value: max_interest_rate });
    }

    return t('common.percents', { value: interest_rate });
  }
}
