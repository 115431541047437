import React from 'react';
import { IconProps } from '.';

const IconArrowUp: React.FC<IconProps> = ({ fill, classes, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 6"
      className={classes}
      fill={fill ?? 'currentColor'}
      width={size}
    >
      <path d="M3.853.773c.326-.334.843-.362 1.2-.083l.094.083 3.15 3.224c.243.249.268.632.074.908l-.073.087-.29.296c-.248.254-.64.28-.916.076l-.088-.076L4.5 2.725 1.996 5.288c-.248.254-.64.28-.917.076l-.087-.076-.29-.296C.46 4.744.435 4.36.63 4.085l.072-.088L3.853.773z" />
    </svg>
  );
};

export default IconArrowUp;

IconArrowUp.defaultProps = {
  size: '16',
};
