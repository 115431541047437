import React from 'react';

import { RouteList } from 'routes';
import AuthRoute from 'routes/AuthRoute';
import * as Roles from 'helpers/auth/roles';
import PageLayout from 'scopes/investor/containers/PageLayout';
import { BonusSystem } from './index';

const BonusSystemRoutes: any = () => [
  <AuthRoute
    path={RouteList.INVESTOR.BONUS_SYSTEM.VIEW}
    component={BonusSystem}
    layout={PageLayout}
    permissions={Roles.All.permissions}
    key={RouteList.INVESTOR.BONUS_SYSTEM.VIEW}
    exact
  />,
];

export default BonusSystemRoutes;
