import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { Col, ColProps, Row } from 'reactstrap';

import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';
import DataBlockStacked from 'scopes/investor/pages/SecondaryMarket/components/DataBlockStacked';
import SanitizedHtmlBlock from 'components/SanitizedHtmlBlock';

type Props = SecondaryMarketInvestmentContextState;

const TabSecurityDeposits: React.FC<Props> = ({ secondaryMarketInvestment }) => {
  const { tHtml } = useTranslation();

  if (!secondaryMarketInvestment?.security_deposits) return null;

  const colProps: ColProps = {
    lg: 3,
    md: 6,
    sm: 6,
    xs: 12,
  };

  const securityDeposits = secondaryMarketInvestment.security_deposits.security_deposits?.map(
    (securityDeposit, key) => (
      <tr key={key}>
        <td>{securityDeposit.real_estate_name}</td>
        <td>{securityDeposit.real_estate_address}</td>
        <td>{securityDeposit.real_estate_unique}</td>
        <td>{securityDeposit.real_estate_plot}</td>
      </tr>
    ),
  );

  return (
    <>
      <section>
        <Row className={'mb-3 gy-3'}>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml(
                'secondary_market.project.tab.security_deposits.security_deposit_address',
              )}
              content={secondaryMarketInvestment.security_deposits.security_deposit_address}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml(
                'secondary_market.project.tab.security_deposits.security_deposit_appraisers',
              )}
              content={secondaryMarketInvestment.security_deposits.security_deposit_appraisers}
            />
          </Col>
          <Col {...colProps}>
            <DataBlockStacked
              title={tHtml('secondary_market.project.tab.security_deposits.security_deposit_value')}
              content={tHtml('common.money', {
                value: secondaryMarketInvestment.security_deposits.security_deposit_value,
              })}
            />
          </Col>
        </Row>
        <SanitizedHtmlBlock
          content={secondaryMarketInvestment.security_deposits.asset_description ?? ''}
        />
        {securityDeposits && (
          <div>
            <table className={'table table-compact'}>
              <thead>
                <th scope={'col'}>
                  {tHtml('secondary_market.project.tab.security_deposits.real_estate_name')}
                </th>
                <th scope={'col'}>
                  {tHtml('secondary_market.project.tab.security_deposits.real_estate_address')}
                </th>
                <th scope={'col'}>
                  {tHtml('secondary_market.project.tab.security_deposits.real_estate_unique')}
                </th>
                <th scope={'col'}>
                  {tHtml('secondary_market.project.tab.security_deposits.real_estate_plot')}
                </th>
              </thead>
              <tbody>{securityDeposits}</tbody>
            </table>
          </div>
        )}
      </section>
    </>
  );
};

export default withSecondaryMarketInvestment(TabSecurityDeposits);
