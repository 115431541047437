import React from 'react';
import { ButtonProps } from 'reactstrap';

export const HighlightButton: React.FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
  children,
  icon,
  ...props
}) => {
  return (
    <button className={'site btn btn-highlight '} {...props}>
      {icon && (
        <>
          <i className={`align-middle ${icon}`} />{' '}
        </>
      )}
      {children}
    </button>
  );
};

export default HighlightButton;
