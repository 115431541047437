import React from 'react';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import { ModalComponent, ModalComponentProps } from 'components/Modal/ModalComponent';
import { ModalBody, ModalHeader } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';
import HighlightButton from 'components/Button/HighlightButton';
import PrimaryButton from 'components/Button/PrimaryButton';
import { generatePath, Link } from 'react-router-dom';
import { RouteList } from 'routes';
import { InvestorProjectsWithTransferableInvestmentsInvestmentResponse } from 'api/types/investor/project';

interface Props extends ModalComponentProps {
  project_id: string;
  project_pid: string;
  project_name: string;
  refinancing_project_id: string;
  refinancing_project_pid: string;
  refinancing_project_name: string;
  investments: InvestorProjectsWithTransferableInvestmentsInvestmentResponse[];
}

const InvestorHasTransferableInvestmentsModal: React.FC<Props> = ({
  project_name,
  refinancing_project_id,
  refinancing_project_name,
  ...props
}) => {
  const { hideModal } = useGlobalModalContext();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ModalComponent size={'lg'} {...props} className={'modal position-relative'}>
        <i className={'icon icon-close mb-4 cursor pointer'} onClick={hideModal} />
        <div className={'p-2 p-lg-4'}>
          <ModalHeader className={'d-flex justify-content-between align-items-center'}>
            <span>{t('modal.investor.has_transferable_investments.title')}</span>
          </ModalHeader>
          <ModalBody className={'modal-body'}>
            {t('modal.investor.has_transferable_investments.body', {
              project: project_name,
              refinancing_project: refinancing_project_name,
            })}
            <div className={'mt-3 d-lg-flex w-lg-50'}>
              <div className={'d-block mb-lg-0 me-2 mt-2'}>
                <Link to={RouteList.INVESTOR.MY_INVESTMENTS.TRANSFERABLE}>
                  <PrimaryButton onClick={hideModal}>
                    {t('modal.investor.has_transferable_investments.pick_investments')}
                  </PrimaryButton>
                </Link>
              </div>
              <div>
                <a
                  rel="noreferrer"
                  target={'_blank'}
                  href={generatePath(RouteList.GUEST.PROJECTS.VIEW, {
                    projectId: refinancing_project_id,
                  })}
                >
                  <HighlightButton className={'site btn btn-primary w-100 mt-2'} type={'button'}>
                    {t('modal.investor.has_transferable_investments.button.more_information')}
                  </HighlightButton>
                </a>
              </div>
            </div>
          </ModalBody>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default InvestorHasTransferableInvestmentsModal;
