import React, { useCallback, useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { useHistory } from 'react-router-dom';

import {
  InvestorBonusInterest,
  InvestorProjectResponse,
  InvestorProjectsWithTransferableInvestmentsResponse,
} from 'api/types/investor/project';
import { DataBlock } from 'components/DataBlock';
import { PrimaryButton } from 'components/Button';
import { ProjectContextState, withProject } from 'components/Project/Scope/ProjectProvider';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import InvestmentModal from '../InvestmentModal/InvestmentModal';
import InvestorProjectGuard from 'helpers/guards/investor';
import { UseNumbers } from 'helpers/useNumbers';
import Guard, { ActionGuard } from 'helpers/guards';
import useOnboarding from 'helpers/useOnboarding';
import { warning } from 'services/toastr';
import { RouteList } from 'routes';
import useScope from 'helpers/useScope';
import { canInvestOnlyRemainingAmount } from 'helpers/investmentsHelper';
import { useWalletQuery } from 'api/queries/useWalletQuery';
import { isWalletProviderObsolete } from 'helpers/enums/wallet/WalletProviderEnum';
import { handleDecimalsOnValue } from 'helpers/utils';
import { ProjectStatusEnum } from 'helpers/enums/ProjectStatusEnum';
import WantToInvestModal from '../WantToInvestModal';
import { useAccountQuery } from 'api/queries';
import AcceptTermsAndConditionsModal from 'scopes/user/pages/Profile/Modals/TermsAndConditions/AcceptTermsAndConditionsModal';
import InvestorHasTransferableInvestmentsModal from '../../../../modals/transferableInvestments/InvestorHasTransferableInvestmentsModal';

const DEFAULT_INVESTMENT = 2000;
const FAST_SELECT_OPTIONS = [200, 500];

const Calculator: React.FC<ProjectContextState> = ({ project, setProject }) => {
  const { tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const history = useHistory();

  const { isWalletVerified, isAccredited } = useOnboarding();
  const { isUser } = useScope();

  const { data: wallet } = useWalletQuery();
  const { data: userAccount } = useAccountQuery();
  const [investment, setInvestment] = useState<string>('0');
  const [interest, setInterest] = useState<InvestorBonusInterest>({
    amount: 0,
    bonus_interest: 0,
  });
  const [refinancingProject, setRefinancingProject] = useState<
    InvestorProjectsWithTransferableInvestmentsResponse | undefined
  >(undefined);

  const p: InvestorProjectResponse = project as InvestorProjectResponse;
  const biggestPossibleInvestment = +(p.required_amount - p.invested_amount).toFixed(2);
  const userCanInvestMax = Math.max(
    wallet ? Math.min(wallet.balance, biggestPossibleInvestment) : biggestPossibleInvestment,
    p.minimal_investment,
  );

  const investmentGuards: ActionGuard[] = [
    {
      rejectIf: isWalletProviderObsolete(wallet?.provider),
      onReject: () => warning(tHtml('projects.investment_modal.guards.wrong_provider')),
    },
    {
      rejectIf: !isAccredited,
      onReject: () => {
        if (isUser()) {
          history.push(RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION);
          warning(tHtml('projects.investment_modal.guards.not_accredited.user'));
        } else {
          warning(tHtml('projects.investment_modal.guards.not_accredited.company'));
        }
      },
    },
    {
      rejectIf: !isWalletVerified,
      onReject: () => warning(tHtml('projects.investment_modal.guards.wallet_not_verified')),
    },
  ];

  const showInvestorHasTransferableInvestmentsModal = useCallback(
    (data: InvestorProjectsWithTransferableInvestmentsResponse) => {
      if (data && data.refinancing_project_id === null) return;
      const delay = 2000;
      const timeoutId = setTimeout(() => {
        showModal(
          <InvestorHasTransferableInvestmentsModal
            project_id={data.project_id}
            project_pid={data.project_pid}
            project_name={data.project_name}
            refinancing_project_pid={data.refinancing_project_pid}
            refinancing_project_id={data.refinancing_project_id}
            refinancing_project_name={data.refinancing_project_name}
            investments={data.investments}
          />,
        );
      }, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    },
    [showModal],
  );

  const fetchTransferableProjects = useCallback(
    (projects) => {
      if (!projects.length) return;

      if (!project?.id) return;

      const transferableProject = projects.find(
        (p: InvestorProjectsWithTransferableInvestmentsResponse) =>
          p.refinancing_project_id === project.id,
      );

      if (transferableProject?.investments.length > 0) {
        setRefinancingProject(transferableProject);
      }
    },
    [project],
  );

  useEffect(() => {
    const interest = findInterest(Number(investment), p?.bonus_interests ?? []);
    setInterest(interest);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment, p, setInterest]);

  useEffect(() => {
    if (p) {
      if (canInvestOnlyRemainingAmount(p)) {
        setInvestment((p.required_amount - p.invested_amount).toFixed(2));
      } else {
        setInvestment(DEFAULT_INVESTMENT.toFixed(1));
      }
    }
  }, [p]);

  useEffect(() => {
    if (userAccount?.has_transferable_investments && userAccount?.transferable_projects) {
      fetchTransferableProjects(userAccount?.transferable_projects);
    }
  }, [fetchTransferableProjects, userAccount]);

  const findInterest = (
    amount: number,
    interests: InvestorBonusInterest[],
  ): InvestorBonusInterest => {
    const validInterests = interests.filter((interest) => amount >= interest.amount);

    return validInterests.length > 0 ? validInterests[0] : { amount: 0, bonus_interest: 0 };
  };

  const calculateProfit = (amount: number, interest: InvestorBonusInterest): number => {
    return (
      ((amount * (p.basic_interest + interest.bonus_interest)) / 100 / 12) *
      (p.credit_duration ?? 0)
    );
  };

  function checkValue(value: string | number) {
    if (+value > biggestPossibleInvestment) {
      setInvestment(biggestPossibleInvestment.toString());
    } else setInvestment(handleDecimalsOnValue(value.toString()));
  }

  if (!p) return <></>;

  return (
    <div className={'calculator'}>
      <div className={'calculator-invest'}>
        {!canInvestOnlyRemainingAmount(p) && (
          <div className="d-flex w-100 justify-content-between gap-2">
            {FAST_SELECT_OPTIONS.map((option, i) => (
              <button
                className={`btn fast-select ${+investment === option ? 'active' : ''}`}
                key={i}
                onClick={() => checkValue(option)}
              >
                {tHtml('common.money_no_cents', { value: option })}
              </button>
            ))}
            <button
              className={`btn fast-select ${+investment === userCanInvestMax ? 'active' : ''}`}
              onClick={() => checkValue(userCanInvestMax)}
            >
              {tHtml('common.money', { value: userCanInvestMax })}
            </button>
          </div>
        )}
        <input
          type="number"
          className="form-control"
          id="two"
          disabled={canInvestOnlyRemainingAmount(p)}
          min={p.minimal_investment}
          max={p.required_amount}
          value={investment}
          onChange={(e) => checkValue(String(e.target.value))}
          onWheel={(e) => e.currentTarget.blur()}
          onKeyPress={UseNumbers.preventNonNumericalInput}
        />
      </div>
      <div className={'calculator-profit'}>
        <DataBlock
          title={tHtml('projects.interest')}
          data={tHtml('common.percents', { value: p.basic_interest + interest.bonus_interest })}
        />
        <DataBlock
          title={tHtml('projects.earnings')}
          data={tHtml('common.money', { value: calculateProfit(Number(investment), interest) })}
        />
      </div>
      <div className={'calculator-profit'}>
        {!p.funded_duration && p.term_left !== null && p.term_left > 0 && (
          <DataBlock
            title={tHtml('projects.investment_information.term_left')}
            data={tHtml('common.days', { value: p.term_left })}
          />
        )}
        {p.funded_duration && (
          <DataBlock
            title={tHtml('projects.investment_information.funded_duration')}
            data={p.funded_duration}
          />
        )}
      </div>
      {project?.status === ProjectStatusEnum.COMING_SOON && (
        <PrimaryButton
          className={'form btn btn-primary w-100 mt-4'}
          onClick={() => {
            showModal(<WantToInvestModal projectId={p.id} />);
          }}
        >
          {tHtml('projects.want_to_invest')}
        </PrimaryButton>
      )}
      {InvestorProjectGuard.canInvest(project) && (
        <div className={'calculation-button'}>
          <PrimaryButton
            className={'form btn btn-primary w-100 mt-4'}
            onClick={() => {
              if (userAccount?.terms_and_conditions_accepted) {
                if (Guard.isValid(investmentGuards)) {
                  showModal(
                    <InvestmentModal
                      amount={Number(investment)}
                      project={project}
                      setProject={setProject}
                    />,
                  );
                }
              } else {
                showModal(<AcceptTermsAndConditionsModal />);
              }
            }}
          >
            {tHtml('projects.invest')}
          </PrimaryButton>
        </div>
      )}
      {refinancingProject && (
        <PrimaryButton
          className={'form btn btn-primary w-100 mt-4'}
          onClick={() => showInvestorHasTransferableInvestmentsModal(refinancingProject)}
        >
          {tHtml('modal.investor.has_transferable_investments.want_to_transfer_investments')}
        </PrimaryButton>
      )}
      <div className={'calculator-notice'}>
        <div className={'mt-2'}>{tHtml('projects.preliminary_calculations_notice')}</div>
        <div className={'mt-2'}>{p.exceptional_investment_conditions_description}</div>
      </div>
    </div>
  );
};

export default withProject(Calculator);
