import React from 'react';
import Watchers from 'scopes/investor/pages/Project/components/Watchers';
import { AnimatedProgressProvider, ProgressBar } from 'components/ProgressBar';
import { ProjectContextState, withProject } from 'components/Project/Scope/ProjectProvider';
import { ProjectDeveloperProjectResponseDto } from 'api/types/project-developer/project';
import AfterInvestment from './components/AfterInvestment';
import ProjectGallery from 'components/Project/ProjectGallery';
import { round } from 'lodash';

const ProjectTop: React.FC<ProjectContextState> = ({ project }) => {
  const p: ProjectDeveloperProjectResponseDto | undefined =
    project as ProjectDeveloperProjectResponseDto;

  if (!p) return <></>;

  const getPercent = (firstNumber: number, secondNumber: number) => {
    if (!secondNumber) return 0;

    return round((firstNumber / secondNumber) * 100, 2);
  };

  return (
    <div className={'project-section'}>
      <div className={'project-top'}>
        <div className={'project-view'}>
          {p.watchers !== null && <Watchers number={p.watchers} />}
          <ProjectGallery pictures={p.pictures} />
        </div>
        <div className={'project-calculator'}>
          <div className={'top'}>
            <h1>
              {p.project_name} ({p.pid})
            </h1>
            <div className={'city'}>{p.project_address}</div>
          </div>
          <div className={'body'}>
            <div className={'percent'}>
              <div className={'w-50'}>
                <AnimatedProgressProvider
                  valueEnd={getPercent(p.invested_amount, p.required_amount ?? 0)}
                >
                  {({ value }) => <ProgressBar percentage={value} />}
                </AnimatedProgressProvider>
              </div>
            </div>
            <hr />
            <AfterInvestment project={p} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withProject(ProjectTop);
