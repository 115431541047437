import DownloadButtonAuthorized from 'components/DownloadButtonAuthorized';
import React from 'react';

interface DocumentDownloadLinkInterface {
  file: DocumentFileInterface;
}

interface DocumentFileInterface {
  name: string;
  url: string;
}

export const DocumentDownloadLink = ({ file }: DocumentDownloadLinkInterface) => {
  return (
    <DownloadButtonAuthorized url={file.url} className={'btn btn-borderless pointer p-0'}>
      <div className={'d-flex justify-content-center'}>
        <div className="document-table-icon">
          <span className="document-download-span">
            PDF
            <i className="icon icon-download-file span-icon-align" />
          </span>
        </div>
      </div>
    </DownloadButtonAuthorized>
  );
};
