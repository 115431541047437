import { defaultOptions, UseQueryOptionsObject } from '../queries.utils';
import { useMakeQuery } from '../useMakeQuery';
import { queries } from '../index';
import Api from '../../index';
import { AuthenticatedAccountResponseDto } from '../../types/shared/authenticatedAccount';

const useAccountQuery = (options?: UseQueryOptionsObject<AuthenticatedAccountResponseDto>) => {
  return useMakeQuery<AuthenticatedAccountResponseDto>(
    [queries.account],
    () => Api.shared.authenticatedAccount.fetchCurrent(),
    {
      staleTime: 120 * 1000,
      retry: false,
      ...defaultOptions,
      ...(options ?? {}),
    },
  );
};

export { useAccountQuery };
