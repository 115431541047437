import React, { createContext, useContext, useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import SkeletonLoader from 'components/SkeletonLoader';
import ProjectSkeleton from './ProjectSkeleton';
import Api from 'api';
import { ForSaleInvestmentProjectResponseDto } from 'api/types/investor/secondary_market';

export interface SecondaryMarketInvestmentContextState {
  loading: boolean;
  secondaryMarketInvestment: ForSaleInvestmentProjectResponseDto | undefined;
}

const SecondaryMarketInvestmentContext = createContext<SecondaryMarketInvestmentContextState>({
  secondaryMarketInvestment: undefined,
  loading: true,
});

interface SecondaryMarketInvestmentProviderProps {
  secondaryMarketInvestmentId: string;
}

export const SecondaryMarketInvestmentProvider: React.FC<SecondaryMarketInvestmentProviderProps> =
  ({ secondaryMarketInvestmentId, children }) => {
    const [secondaryMarketInvestment, setSecondaryMarketInvestment] =
      useState<ForSaleInvestmentProjectResponseDto>();
    const [loading, setLoading] = useState<boolean>(true);
    const { currentLanguage } = useTranslation();

    useEffect(() => {
      Api.investor.secondary_market
        .fetchForSaleInvestment(secondaryMarketInvestmentId)
        .then((response) => {
          setSecondaryMarketInvestment(response);
          setLoading(false);
        });
    }, [secondaryMarketInvestmentId, currentLanguage]);

    return (
      <SecondaryMarketInvestmentContext.Provider
        value={{ secondaryMarketInvestment: secondaryMarketInvestment, loading: loading }}
      >
        <SkeletonLoader loading={loading} customPlaceholder={<ProjectSkeleton />}>
          {children}
        </SkeletonLoader>
      </SecondaryMarketInvestmentContext.Provider>
    );
  };

type Optionalize<T extends K, K> = Omit<T, keyof K>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function withSecondaryMarketInvestment<
  P extends SecondaryMarketInvestmentContextState = SecondaryMarketInvestmentContextState,
>(Component: React.ComponentType<P>) {
  const WithSecondaryMarketInvestment = ({
    ...props
  }: Optionalize<P, SecondaryMarketInvestmentContextState>) => {
    const c = useContext(SecondaryMarketInvestmentContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithSecondaryMarketInvestment.displayName = `withSecondaryMarketInvestment${Component.displayName}`;

  return WithSecondaryMarketInvestment;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSecondaryMarketInvestment = () => {
  return useContext(SecondaryMarketInvestmentContext);
};

export default SecondaryMarketInvestmentProvider;
