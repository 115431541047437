import React, { useCallback, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Form, Formik, FormikHelpers } from 'formik';
import TransferBlockFormik from '../TransferBlockFormik';
import { PrimaryButton } from 'components/Button';
import { MoneyInRequestDto } from 'api/types/common';
import * as Yup from 'yup';
import Api from 'api';

const MoneyInByCard: React.FC = () => {
  const { t, tHtml } = useTranslation();

  const [moneyInRequest] = useState<MoneyInRequestDto>({
    amount: 100,
  });

  const [isTopUpDisabled, setTopUpDisabled] = useState(false);

  const moneyInSchema = Yup.object().shape({
    amount: Yup.number().money().required(),
  });

  const onMoneyInSubmit = useCallback(
    async (request: MoneyInRequestDto, helpers: FormikHelpers<MoneyInRequestDto>) => {
      try {
        await Api.wallet.moneyInStart(request).then((response) => {
          setTopUpDisabled(true);
          window.open(response.url, '_self', 'noopener,noreferrer');
        });
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [],
  );

  return (
    <div className={'wallet-actions'}>
      <p>{tHtml('wallet.after_entering_the_amount')}</p>
      <Formik
        initialValues={moneyInRequest}
        validationSchema={moneyInSchema}
        onSubmit={onMoneyInSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <TransferBlockFormik name={'amount'} label={t('wallet.top_up_transaction_label')} />
            <PrimaryButton
              className={'site btn btn-primary w-50 mt-4'}
              disabled={isSubmitting || isTopUpDisabled}
              loading={isSubmitting || isTopUpDisabled}
            >
              {t('common.next')}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MoneyInByCard;
