import styled from 'styled-components';

export const StyledMobileSidebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSidebarParams = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0.4rem;
  justify-content: center;
`;

export const StyledMobileSidebarWrapper = styled(StyledSidebarParams)`
  padding: 0.6rem;

  span {
    vertical-align: middle;
  }

  .icon-up-arrow {
    transition: 0.5s;
    transform: rotate(-360deg);
  }

  .nav-link {
    &.active {
      .active-arrow {
        .icon-up-arrow {
          transition: 0.5s;
          transform: rotate(-180deg);
        }
      }
    }

    &:hover {
      color: var(--profitus-link-color);
    }
  }

  a {
    padding: 0.5rem 0.2rem 0.5rem 0.8rem;
    font-size: 14px;
    font-weight: unset;

    .icon {
      display: inline-block;
      min-width: 1.5rem;
      padding-bottom: 0.125em;
      font-size: 1.05rem;
      line-height: 1.40625rem;
      vertical-align: middle;
      background-color: var(--profitus-sidebar-link-icon-color);
    }

    &.active {
      color: var(--profitus-link-active-color);
      .icon {
        background-color: var(--profitus-link-active-color);
      }
    }
  }
`;

export const StyledMobileLocaleSwitcher = styled.div``;

export const StyledMobileProfileIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`;

export const StyledMobileSidebarBlock = styled.div`
  display: flex;
`;

export const StyledMobileBurgerButton = styled.div`
  display: flex;
  align-items: center;

  .sidebar-toggler {
    padding: 0;
    border: none;
    font-size: 0.875rem;
  }
`;
