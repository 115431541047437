import React from 'react';
import SidebarDashboard from './Desktop/SidebarDashboard';
import SidebarMobile from './Mobile/SidebarMobile';
import { StyledSidebar, OuterSidebar } from './Sidebar.style';
import useAppState from 'modules/appState';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: React.FC<Props> = (props) => {
  const { isImpersonated } = useAppState();

  return (
    <React.Fragment>
      {props.isOpen && <OuterSidebar onClick={() => props.setIsOpen(false)} />}
      <StyledSidebar
        className={`vertical-menu ${props.isOpen ? '' : 'hide-sidebar'}`}
        top={isImpersonated ? '30px' : '0'}
      >
        <div data-simplebar={true} className={'h-100'}>
          <div className={'h-100 d-block d-lg-none'}>
            <SidebarMobile isOpen={props.isOpen} setIsOpen={props.setIsOpen} />
          </div>
          <div className={'d-none d-lg-block'}>
            <SidebarDashboard />
          </div>
        </div>
      </StyledSidebar>
    </React.Fragment>
  );
};

export default Sidebar;
