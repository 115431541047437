import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { RouteList } from 'routes';

const Home: React.FC = () => (
  <Route exact path={RouteList.HOME} key={RouteList.HOME}>
    <Redirect to={RouteList.GUEST.AUTHENTICATION.LOGIN} />
  </Route>
);

export default Home;
