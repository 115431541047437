import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import useTranslation from 'helpers/useTranslation';

import useUserType from 'helpers/useUserType';
import InvestorPageLayout from 'scopes/investor/containers/PageLayout';
import DeveloperPageLayout from 'scopes/developer/containers/PageLayout';
import ProfileLayoutSkeleton from './ProfileLayoutSkeleton';
import ProfileSidebar from './ProfileSidebar';
import { RouteList } from 'routes';
import { FunctionalButton } from 'components/Button';
import { useUserCompanyProfileQuery } from 'api/queries/useUserCompanyProfileQuery';

const ProfileLayout: React.FC = ({ children }) => {
  const { tHtml } = useTranslation();
  const type = useUserType();

  const { isFetching } = useUserCompanyProfileQuery();

  const PageLayoutType = type.isDeveloper() ? DeveloperPageLayout : InvestorPageLayout;

  return (
    <React.Fragment>
      <PageLayoutType isProfileLayout={true}>
        <div className={'mb-3 d-flex justify-content-start'}>
          <Link to={RouteList.DASHBOARD}>
            <FunctionalButton>
              <i className={'icon icon-arrow-left'} /> {tHtml('profile.back_to_ss')}
            </FunctionalButton>
          </Link>
        </div>
        <div className={'profile-layout'}>
          <Container className={'g-0'}>
            {isFetching ? (
              <ProfileLayoutSkeleton />
            ) : (
              <Row>
                <Col className={'sidebar d-none d-lg-flex'} xs={0} lg={4}>
                  <ProfileSidebar />
                </Col>
                <Col className={'content'} xs={12} lg={8}>
                  {children}
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </PageLayoutType>
    </React.Fragment>
  );
};

export default withRouter(ProfileLayout);
