import React, { useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';

import { InvestorBonusInterest, InvestorProjectResponse } from 'api/types/investor/project';
import { DataBlock } from 'components/DataBlock';
import { PrimaryButton } from 'components/Button';
import { ProjectContextState, withProject } from 'components/Project/Scope/ProjectProvider';
import { UseNumbers } from 'helpers/useNumbers';
import { canInvestOnlyRemainingAmount } from 'helpers/investmentsHelper';
import { NavLink as Link } from 'react-router-dom';
import { RouteList } from 'routes';
import { handleDecimalsOnValue } from 'helpers/utils';

const DEFAULT_INVESTMENT = 2000;
const FAST_SELECT_OPTIONS = [200, 500];

const Calculator: React.FC<ProjectContextState> = ({ project }) => {
  const { tHtml } = useTranslation();

  const [investment, setInvestment] = useState<string>('0');
  const [interest, setInterest] = useState<InvestorBonusInterest>({
    amount: 0,
    bonus_interest: 0,
  });

  const p: InvestorProjectResponse = project as InvestorProjectResponse;
  const biggestPossibleInvestment = +(p.required_amount - p.invested_amount).toFixed(2);

  useEffect(() => {
    const interest = findInterest(Number(investment), p?.bonus_interests ?? []);
    setInterest(interest);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment, p, setInterest]);

  useEffect(() => {
    if (p) {
      if (canInvestOnlyRemainingAmount(p)) {
        setInvestment((p.required_amount - p.invested_amount).toFixed(2));
      } else {
        setInvestment(DEFAULT_INVESTMENT.toFixed(1));
      }
    }
  }, [p]);

  const findInterest = (
    amount: number,
    interests: InvestorBonusInterest[],
  ): InvestorBonusInterest => {
    const validInterests = interests.filter((interest) => amount >= interest.amount);

    return validInterests.length > 0 ? validInterests[0] : { amount: 0, bonus_interest: 0 };
  };

  const calculateProfit = (amount: number, interest: InvestorBonusInterest): number => {
    return (
      ((amount * (p.basic_interest + interest.bonus_interest)) / 100 / 12) *
      (p.credit_duration ?? 0)
    );
  };

  function checkValue(value: string | number) {
    if (+value > biggestPossibleInvestment) {
      setInvestment(biggestPossibleInvestment.toString());
    } else setInvestment(handleDecimalsOnValue(value.toString()));
  }

  if (!p) return <></>;

  return (
    <div className={'calculator'}>
      <div className={'calculator-invest'}>
        {!canInvestOnlyRemainingAmount(p) && (
          <div className="d-flex w-100 justify-content-between gap-2">
            {FAST_SELECT_OPTIONS.map((option, i) => (
              <button
                className={`btn fast-select ${+investment === option ? 'active' : ''}`}
                key={i}
                onClick={() => checkValue(option)}
              >
                {tHtml('common.money_no_cents', { value: option })}
              </button>
            ))}
            <button
              className={`btn fast-select ${
                +investment === biggestPossibleInvestment ? 'active' : ''
              }`}
              onClick={() => checkValue(biggestPossibleInvestment)}
            >
              {tHtml('common.money', { value: biggestPossibleInvestment })}
            </button>
          </div>
        )}
        <input
          type="number"
          className="form-control"
          id="two"
          disabled={canInvestOnlyRemainingAmount(p)}
          min={p.minimal_investment}
          max={p.required_amount}
          value={investment}
          onWheel={(e) => e.currentTarget.blur()}
          onChange={(e) => checkValue(String(e.target.value))}
          onKeyPress={UseNumbers.preventNonNumericalInput}
        />
      </div>
      <div className={'calculator-profit'}>
        <DataBlock
          title={tHtml('projects.interest')}
          data={tHtml('common.percents', { value: p.basic_interest + interest.bonus_interest })}
        />
        <DataBlock
          title={tHtml('projects.earnings')}
          data={tHtml('common.money', { value: calculateProfit(Number(investment), interest) })}
        />
      </div>
      <div className={'calculator-profit'}>
        {!p.funded_duration && p.term_left !== null && p.term_left > 0 && (
          <DataBlock
            title={tHtml('projects.investment_information.term_left')}
            data={tHtml('common.days', { value: p.term_left })}
          />
        )}
        {p.funded_duration && (
          <DataBlock
            title={tHtml('projects.investment_information.funded_duration')}
            data={p.funded_duration}
          />
        )}
      </div>

      <div className={'calculation-button'}>
        <Link to={RouteList.GUEST.AUTHENTICATION.LOGIN}>
          <PrimaryButton className={'form btn btn-primary w-100 mt-4'}>
            {tHtml('common.login')}
          </PrimaryButton>
        </Link>
      </div>
      <div className={'calculator-notice'}>
        <div className={'mt-2'}>{tHtml('projects.preliminary_calculations_notice')}</div>
        <div className={'mt-2'}>{p.exceptional_investment_conditions_description}</div>
      </div>
    </div>
  );
};

export default withProject(Calculator);
