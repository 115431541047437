import { ScopeEnum } from './enums/ScopeEnum';
import { useCallback } from 'react';
import useAppState from '../modules/appState';

const useScope = () => {
  const { scope } = useAppState();

  const isUser = useCallback((): boolean => {
    return scope === ScopeEnum.User;
  }, [scope]);

  const isCompany = useCallback((): boolean => {
    return scope === ScopeEnum.Company;
  }, [scope]);

  return { isUser, isCompany };
};

export default useScope;
