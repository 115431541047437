import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { RouteList } from 'routes';

import ProjectListRoutes from './pages/ProjectList/ProjectListRoutes';
import ProjectRoutes from './pages/Project/ProjectRoutes';
import DashboardRoutes from './pages/Dashboard/DashboardRoutes';
import DocumentRoutes from './pages/Documents/DocumentRoutes';
import TransactionRoutes from './pages/Transactions/TransactionRoutes';
import MyInvestmentRoutes from './pages/MyInvestments/MyInvestmentRoutes';
import AuthenticationRoutes from './pages/Authentication/AuthenticationRoutes';
import QuizRoutes from './pages/Quiz/QuizRoutes';
import NotificationsRoutes from './pages/Notifications/NotificationsRoutes';
import AutoInvestmentRoutes from './pages/AutoInvestment/AutoInvestmentRoutes';
import SecondaryMarketRoutes from './pages/SecondaryMarket/SecondaryMarketRoutes';
import BonusSystemRoutes from './pages/BonusSystem/BonusSystemRoutes';
import NotFoundRoutes from './pages/NotFound/NotFoundRoutes';
import AffiliateRoutes from './pages/Affiliate/AffiliateRoutes';

const InvestorRoutes: any = () => [
  <Route path={RouteList.HOME} key={RouteList.HOME} exact>
    <Redirect to={RouteList.DASHBOARD} />
  </Route>,
  ...ProjectListRoutes(),
  ...ProjectRoutes(),
  ...SecondaryMarketRoutes(),
  ...DashboardRoutes(),
  ...DocumentRoutes(),
  ...TransactionRoutes(),
  ...MyInvestmentRoutes(),
  ...AuthenticationRoutes(),
  ...QuizRoutes(),
  ...NotificationsRoutes(),
  ...AffiliateRoutes(),
  ...AutoInvestmentRoutes(),
  ...BonusSystemRoutes(),
  ...NotFoundRoutes(),
];

export default InvestorRoutes;
