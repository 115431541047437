import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { DataBlock } from 'components/DataBlock';
import { RouteList } from 'routes';
import CountryLabel from 'components/Labels/CountryLabel';
import useOnboarding from 'helpers/useOnboarding';
import NotFilledProfileSection from 'components/Profile/NotFilledProfileSection';
import UserProfileSection from 'scopes/user/components/UserProfileSection';
import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';
import { useUserProfileQuery } from 'api/queries/useUserProfileQuery';

const ContactInfo: React.FC = () => {
  const { tHtml } = useTranslation();
  const { isContactDetailsFilled } = useOnboarding();

  const { data: userProfileData } = useUserProfileQuery();

  if (!isContactDetailsFilled)
    return (
      <NotFilledProfileSection
        title={tHtml('menu.contact_info')}
        buttonLink={RouteList.USER.EDIT.CONTACT_INFO}
      />
    );

  return (
    <UserProfileSection
      menu={UserMenuEnum.ContactInfo}
      title={tHtml('menu.contact_info')}
      buttonLink={RouteList.USER.EDIT.CONTACT_INFO}
    >
      <div className={'content'}>
        <DataBlock
          title={tHtml('placeholder.first_name')}
          data={userProfileData?.user?.natural?.first_name ?? ''}
        />
        {userProfileData?.user?.natural?.middle_name && (
          <DataBlock
            title={tHtml('placeholder.middle_name')}
            data={userProfileData?.user?.natural?.middle_name}
          />
        )}
        <DataBlock
          title={tHtml('placeholder.last_name')}
          data={userProfileData?.user?.natural?.last_name ?? ''}
        />
        <DataBlock title={tHtml('placeholder.email')} data={userProfileData?.user?.email ?? ''} />
        <DataBlock
          title={tHtml('placeholder.date_of_birth')}
          data={userProfileData?.user?.natural?.dob ?? ''}
        />
        <DataBlock
          title={tHtml('placeholder.residence')}
          data={
            userProfileData?.user?.natural?.residence ? (
              <CountryLabel code={userProfileData?.user?.natural?.residence} />
            ) : (
              ''
            )
          }
        />
        <DataBlock
          title={tHtml('placeholder.city')}
          data={userProfileData?.user?.natural?.city ?? ''}
        />
        <DataBlock
          title={tHtml('placeholder.street')}
          data={userProfileData?.user?.natural?.street ?? ''}
        />
        <DataBlock
          title={tHtml('placeholder.house_number')}
          data={userProfileData?.user?.natural?.house_number ?? ''}
        />
        <DataBlock
          title={tHtml('placeholder.phone')}
          data={userProfileData?.user?.natural?.phone ?? ''}
        />
        <DataBlock
          title={tHtml('placeholder.gov_code')}
          data={userProfileData?.user?.natural?.gov_code ?? ''}
        />
      </div>
    </UserProfileSection>
  );
};

export default withRouter(ContactInfo);
