import Client from '../../client';
import Http from '../../../http';
import {
  CurrentUserCompanyResponseDto,
  RefreshUserCompanyResponseDto,
} from '../../../types/shared/company/current';

class SharedCompanyCurrent extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      CURRENT: this.protectedUrl(`${this.http.baseUrl}/company/current`),
      REFRESH: this.protectedUrl(`${this.http.baseUrl}/company/refresh`),
      LOGOUT: this.protectedUrl(`${this.http.baseUrl}/company/logout`),
    };
  }

  fetchCurrentCompany = (): Promise<CurrentUserCompanyResponseDto> => {
    return this.http.get(this.api.CURRENT);
  };

  refreshToken = (): Promise<RefreshUserCompanyResponseDto> => {
    return this.http.put(this.api.REFRESH);
  };

  logout = (): Promise<any> => {
    return this.http.post(this.api.LOGOUT);
  };
}

export default SharedCompanyCurrent;
