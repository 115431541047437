import React from 'react';
import useTranslation from 'helpers/useTranslation';
import { DataBlock } from 'components/DataBlock';
import { Calculator } from './Calculator';
import { ProjectContextState, withProject } from 'components/Project/Scope/ProjectProvider';

const CalculatorWithCollection: React.FC<ProjectContextState> = ({ project }) => {
  const { tHtml } = useTranslation();
  if (!project) return <></>;

  return (
    <>
      <div className={'collection'}>
        {project.required_amount && (
          <DataBlock
            title={tHtml('projects.invested_amount')}
            data={tHtml('common.money', { value: project.invested_amount })}
          />
        )}
        {project.required_amount && (
          <DataBlock
            title={tHtml('projects.required_amount')}
            data={tHtml('common.money', { value: project.required_amount })}
          />
        )}
      </div>
      <Calculator />
    </>
  );
};

export default withProject(CalculatorWithCollection);
