import React from 'react';
import useTranslation from 'helpers/useTranslation';
import GIFT_ICON from 'assets/images/icons/gift.svg';
import useDisplay from 'helpers/useDisplay';

interface Props {
  color: 'pink' | 'grey';
  amount: number;
  tileNumber: number;
  numberPosition?: 'left' | 'right';
  invitedPeople?: number;
  isLast?: boolean;
  isMore?: boolean;
}

const AffiliateTierBlock = ({
  color,
  amount,
  tileNumber,
  invitedPeople,
  numberPosition = 'left',
  isLast,
  isMore,
}: Props) => {
  const { tHtml } = useTranslation();
  const colorCode = color === 'pink' ? '#C4007A' : '#EEEEEE';
  const isActiveTier = tileNumber === (invitedPeople ?? 0) + 1;

  const { isMobile } = useDisplay();

  return (
    <div
      className={'affiliate-tier-block-wrapper'}
      style={isMore && tileNumber === 1 ? { marginLeft: 20 } : undefined}
    >
      <div
        className="d-flex flex-column align-items-center position-relative"
        style={isMore ? { minWidth: 47 } : undefined}
      >
        <div
          className={'affiliate-span-wrapper font-size-16-11 mb-3'}
          style={{ textAlign: numberPosition }}
        >
          <span className={'fw-600'}>{isLast ? tileNumber + '+' : tileNumber}</span>
        </div>
        <div
          className={'affiliate-tier-tile'}
          style={{
            background: isActiveTier ? '#C4007A' : colorCode,
            boxShadow: `0 0 0 2px ${colorCode}, 0 0 0 5px var(--profitus-bg-color-2), 0 0 0 7px ${colorCode}`,
          }}
        >
          {!isLast && (
            <div
              className={'affiliate-tier-tile-divider'}
              style={{
                background: colorCode,
              }}
            />
          )}
        </div>
        <div
          className={'affiliate-tier-tile-line'}
          style={{
            background: colorCode,
          }}
        />
        <div
          className={'affiliate-tier-tile-bubble'}
          style={{
            background: colorCode,
          }}
        />
      </div>

      <div className={'w-100 text-center'}>
        <span
          style={color === 'pink' ? { color: colorCode } : undefined}
          className={'font-size-16-11'}
        >
          {tHtml('common.money_no_cents', { value: amount })}
        </span>
        {tileNumber === 10 && (
          <>
            <br />
            <div className={'d-flex justify-content-center'}>
              {isMobile ? (
                <img src={GIFT_ICON} alt="gift" />
              ) : (
                <span className={'text-nowrap font-size-11'}>
                  {tHtml('affiliate.labels.profitus_gift')}
                </span>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AffiliateTierBlock;
