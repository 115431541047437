export enum ApplicationStatusEnum {
  PENDING = 'pending',
  SUBMITTED = 'submitted',
  ANALYZING = 'analyzing',
  COMMITTEE_REVIEW = 'committee_review',
  CHIEF_COMMITTEE_MEMBER_REVIEW = 'chief_committee_member_review',
  CONFIRMED = 'confirmed',
  OFFER_ACCEPTED = 'offer_accepted',
  REJECTED = 'rejected',
}
