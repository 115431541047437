import React from 'react';
import useTranslation from 'helpers/useTranslation';

import { PrimaryButton } from 'components/Button';
import {
  SecondaryMarketInvestmentContextState,
  withSecondaryMarketInvestment,
} from '../SecondaryMarketInvestmentProvider';
import BuyInvestmentModal from '../../BuyInvestmentModal';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import Guard, { ActionGuard } from 'helpers/guards';
import useOnboarding from 'helpers/useOnboarding';
import { warning } from 'services/toastr';
import { RouteList } from 'routes';
import useInvestor from 'helpers/useInvestor';
import useScope from 'helpers/useScope';
import { ExceptionCta, InvestorExceptionEnum } from 'api/types/investor/exception';
import { useHistory } from 'react-router-dom';
import { useWalletQuery } from 'api/queries/useWalletQuery';
import { isWalletProviderObsolete } from 'helpers/enums/wallet/WalletProviderEnum';

interface Props extends SecondaryMarketInvestmentContextState {
  className?: string;
}

const ProjectTopBuyButton: React.FC<Props> = ({ secondaryMarketInvestment, className }) => {
  const { t, tHtml } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const history = useHistory();

  const { isWalletVerified, isAccredited } = useOnboarding();
  const { isUser } = useScope();
  const { hasFilledQuiz } = useInvestor();

  const { data: wallet } = useWalletQuery();

  if (secondaryMarketInvestment?.more_information && !secondaryMarketInvestment?.is_personal) {
    const investmentGuards: ActionGuard[] = [
      {
        rejectIf: isWalletProviderObsolete(wallet?.provider),
        onReject: () => warning(tHtml('projects.investment_modal.guards.wrong_provider')),
      },
      {
        rejectIf: !hasFilledQuiz,
        onReject: () =>
          warning(
            t('projects.investment_modal.guards.quiz_not_filled'),
            t('exception.' + InvestorExceptionEnum.InvestorQuizNotFilledException + '.cta'),
            ExceptionCta[InvestorExceptionEnum.InvestorQuizNotFilledException].CTA_ACTION,
          ),
      },
      {
        rejectIf: !isAccredited,
        onReject: () => {
          if (isUser()) {
            history.push(RouteList.GUEST.AUTHENTICATION.REGISTER_ID_VERIFICATION);
            warning(tHtml('projects.investment_modal.guards.not_accredited.user'));
          } else {
            warning(tHtml('projects.investment_modal.guards.not_accredited.company'));
          }
        },
      },
      {
        rejectIf: !isWalletVerified,
        onReject: () => warning(tHtml('projects.investment_modal.guards.wallet_not_verified')),
      },
      {
        rejectIf: (wallet?.balance ?? 0) < Number(secondaryMarketInvestment.sale_price),
        onReject: () => warning(tHtml('projects.investment_modal.guards.insufficient_funds')),
      },
    ];

    return (
      <PrimaryButton
        className={`btn btn-primary secondary-market-buy-button ${className}`}
        onClick={() => {
          if (Guard.isValid(investmentGuards)) {
            showModal(
              <BuyInvestmentModal
                amount={secondaryMarketInvestment.sale_price}
                secondaryMarketInvestmentId={secondaryMarketInvestment.id}
              />,
            );
          }
        }}
      >
        {tHtml('common.buy')}
      </PrimaryButton>
    );
  }

  return (
    <PrimaryButton
      className={`btn btn-primary secondary-market-buy-button ${className}`}
      disabled={true}
    >
      {tHtml('common.buy')}
    </PrimaryButton>
  );
};

export default withSecondaryMarketInvestment(ProjectTopBuyButton);
