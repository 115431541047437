import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';
import { Nav, NavbarToggler, NavLink } from 'reactstrap';

import { RouteList } from 'routes';
import LocaleSwitcher from 'components/LocaleSwitcher';

import {
  StyledMobileBurgerButton,
  StyledMobileLocaleSwitcher,
  StyledMobileProfileIcon,
  StyledMobileSidebarBlock,
  StyledMobileSidebar,
  StyledMobileSidebarWrapper,
} from './SidebarMobile.style';
import ThemeSwitcher from 'components/ThemeSwitcher/ThemeSwitcher';

export interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SidebarMobile: React.FC<Props> = (props) => {
  const { t, tHtml } = useTranslation();
  const toggle = () => props.setIsOpen((value) => !value);

  interface MenuItem {
    name: string;
    path: string;
    icon: string;
  }

  const menuItems: MenuItem[] = [
    {
      name: t('menu.home'),
      path: RouteList.HOME,
      icon: 'icon-home-alt',
    },
  ];

  const renderMenu = (menu: MenuItem[]) => {
    return menu.map((menuItem, index) => (
      <li key={index}>
        <NavLink to={menuItem.path ?? '#'} exact tag={Link}>
          <i className={`icon ${menuItem.icon} me-2`} />
          <span>{menuItem.name}</span>
        </NavLink>
      </li>
    ));
  };

  return (
    <React.Fragment>
      <Nav vertical={true}>
        <StyledMobileSidebarWrapper>
          <StyledMobileSidebar>
            <StyledMobileLocaleSwitcher>
              <LocaleSwitcher />
            </StyledMobileLocaleSwitcher>
            <StyledMobileSidebarBlock>
              <StyledMobileProfileIcon>
                <React.Fragment>
                  <Link to={RouteList.GUEST.AUTHENTICATION.LOGIN}>{tHtml('common.login')}</Link>
                </React.Fragment>
              </StyledMobileProfileIcon>
              <ThemeSwitcher />
              <StyledMobileBurgerButton>
                <NavbarToggler onClick={toggle} className={'sidebar-toggler'} />
              </StyledMobileBurgerButton>
            </StyledMobileSidebarBlock>
          </StyledMobileSidebar>

          {renderMenu(menuItems)}
        </StyledMobileSidebarWrapper>
      </Nav>
    </React.Fragment>
  );
};

export default SidebarMobile;
