import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';

export const StyledInvoicesWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;

  ${breakpointDown.LG} {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
  }
`;

export const StyledInvoicesHeader = styled.h1`
  color: var(--profitus-h2-color);
  font-size: 1rem;
  font-weight: 700;
`;

export const StyledInvoicesTable = styled.div`
  margin-top: 2.25rem;

  ${breakpointDown.LG} {
    margin-top: 0.5rem;
  }
`;

export const StyledInvoicesSubComponent = styled.div`
  background-color: var(--profitus-table-sub-component-background-color);
  padding: 1rem;
  border-radius: 0.5rem;
`;
