import styled from 'styled-components';
import { breakpointDown } from 'styles/helpers/responsive';

export const StyledSidebarPageLayout = styled.div<{ isApp: boolean }>`
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3rem;

    ${breakpointDown.LG} {
      padding-top: 1rem;
      justify-content: ${(props) => (props.isApp ? 'flex-end' : 'space-between')};
    }

    ${breakpointDown.MD} {
      padding-top: 1rem;
    }

    &__title {
      color: var(--profitus-h2-color);
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0;
    }

    &__divider {
      border-top: 3px solid var(--profitus-page-header-divider-color);
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      opacity: 1;

      ${breakpointDown.MD} {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  ${breakpointDown.LG} {
    .header-line {
      width: inherit;
    }

    .change-bells-position {
      margin-right: 0;
    }

    .dropdown {
      .dropdown-toggle {
        .avatar {
          margin-right: 0;
        }
      }

      .badge {
        &.rounded-pill {
          font-size: 0.625rem;
          left: 0.8rem;
        }
      }

      .only-icon-phone {
        display: none;
      }
    }
  }
`;
