import React from 'react';
import { ModalProps } from 'reactstrap/es/Modal';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useGlobalModalContext } from './GlobalModal';

export interface ModalComponentProps extends Omit<ModalProps, 'title'> {
  body?: JSX.Element;
  footer?: JSX.Element;
  title?: string | JSX.Element;
}

export const ModalComponent: React.FC<ModalComponentProps> = ({
  title,
  body,
  footer,
  children,
  ...props
}) => {
  const { hideModal } = useGlobalModalContext();
  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <Modal
      {...props}
      centered
      fade
      toggle={handleModalToggle}
      isOpen={true}
      onClose={handleModalToggle}
    >
      {children ?? (
        <>
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>{body}</ModalBody>
          <ModalFooter>{footer}</ModalFooter>
        </>
      )}
    </Modal>
  );
};
