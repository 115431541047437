import React, { useCallback, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { MoneyInByBankRequestDto } from 'api/types/common';
import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import Api from 'api';
import { PrimaryButton } from 'components/Button';
import NumberInput from '../NumberInput';
import SelectCountryInputGroup from './SelectBankCountryInput';

const MoneyInByBank: React.FC = () => {
  const { t, tHtml } = useTranslation();

  const [moneyInRequest] = useState<MoneyInByBankRequestDto>({
    amount: 1000,
    country: 'lt',
  });

  const [isTopUpDisabled, setTopUpDisabled] = useState(false);

  const moneyInSchema = Yup.object().shape({
    amount: Yup.number().money().required(),
    country: Yup.string().required(),
  });

  const onMoneyInSubmit = useCallback(
    async (request: MoneyInByBankRequestDto, helpers: FormikHelpers<MoneyInByBankRequestDto>) => {
      try {
        await Api.wallet.moneyInByBankStart(request).then((response) => {
          setTopUpDisabled(true);
          window.open(response.url, '_self', 'noopener,noreferrer');
        });
      } catch (e) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [],
  );

  return (
    <div className={'wallet-actions'}>
      <p>{tHtml('wallet.pay_by_bank.description')}</p>
      <Formik
        initialValues={moneyInRequest}
        validationSchema={moneyInSchema}
        onSubmit={onMoneyInSubmit}
        enableReinitialize={true}
        initialTouched={{
          amount: true,
          country: true,
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <div className={'transfer-block'}>
              <NumberInput
                name={'amount'}
                label={t('wallet.top_up_transaction_label')}
                max={9999999999}
              />
              <SelectCountryInputGroup
                name={'country'}
                placeholder={t('placeholder.bank_country')}
              />
              <div className={'transfer-block-overview'}>
                <div className={'wallet-overview-data-block'}>
                  <div className={'title'}>{tHtml('common.tax')}</div>
                  <div className={'data'}>{tHtml('common.money', { value: 0 })}</div>
                </div>
                <div className={'wallet-overview-data-block'}>
                  <div className={'title'}>{tHtml('common.total')}</div>
                  <div className={'data'}>{tHtml('common.money', { value: values.amount })}</div>
                </div>
              </div>
            </div>
            <PrimaryButton
              className={'site btn btn-primary w-50 mt-4'}
              disabled={isSubmitting || isTopUpDisabled}
              loading={isSubmitting || isTopUpDisabled}
            >
              {t('common.next')}
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MoneyInByBank;
