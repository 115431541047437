import React, { createContext, useContext, useEffect, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import Api from 'api';
import SkeletonLoader from 'components/SkeletonLoader';
import { ProjectDeveloperProjectResponseDto } from 'api/types/project-developer/project';
import { InvestorProjectResponse } from 'api/types/investor/project';
import { UserTypeEnum } from 'helpers/enums/UserTypeEnum';
import { GuestProjectResponse } from 'api/types/guest/project';
import ProjectSkeleton from './ProjectSkeleton';

type ProjectType =
  | GuestProjectResponse
  | InvestorProjectResponse
  | ProjectDeveloperProjectResponseDto;

type AvailableUserType =
  | UserTypeEnum.GUEST
  | UserTypeEnum.PROJECT_DEVELOPER
  | UserTypeEnum.INVESTOR
  | 'preview';

export interface ProjectContextState {
  loading: boolean;
  project: ProjectType | undefined;
  setProject: ((project: ProjectType) => void) | undefined;
  type: AvailableUserType;
}

const ProjectContext = createContext<ProjectContextState>({
  project: undefined,
  setProject: undefined,
  loading: true,
  type: UserTypeEnum.GUEST,
});

interface ProjectProviderProps {
  projectId: string;
  type: AvailableUserType;
  previewId?: string;
}

export const ProjectProvider: React.FC<ProjectProviderProps> = ({
  projectId,
  type,
  children,
  previewId,
}) => {
  const [project, setProject] = useState<ProjectType>();
  const [loading, setLoading] = useState<boolean>(true);
  const { currentLanguage } = useTranslation();

  useEffect(() => {
    setLoading(true);

    if (type === UserTypeEnum.GUEST) {
      Api.guest.projects.fetchProject(projectId).then((response) => {
        setProject(response);
        setLoading(false);
      });
    } else if (type === UserTypeEnum.INVESTOR) {
      Api.investor.projects.fetchProject(projectId).then((response) => {
        setProject(response);
        setLoading(false);
      });
    } else if (type === UserTypeEnum.PROJECT_DEVELOPER) {
      Api.projectDeveloper.projects.fetchProject(projectId).then((response) => {
        setProject(response);
        setLoading(false);
      });
    } else if (type === 'preview') {
      if (previewId) {
        Api.shared.projects.fetchProject(projectId, previewId).then((response) => {
          setProject(response);
          setLoading(false);
        });
      }
    }
  }, [projectId, currentLanguage, type, previewId]);

  return (
    <ProjectContext.Provider
      value={{ project: project, setProject: setProject, loading: loading, type: type }}
    >
      <SkeletonLoader loading={loading} customPlaceholder={<ProjectSkeleton />}>
        {children}
      </SkeletonLoader>
    </ProjectContext.Provider>
  );
};

type Optionalize<T extends K, K> = Omit<T, keyof K>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function withProject<P extends ProjectContextState = ProjectContextState>(
  Component: React.ComponentType<P>,
) {
  const WithProject = ({ ...props }: Optionalize<P, ProjectContextState>) => {
    const c = useContext(ProjectContext);
    return <Component {...c} {...(props as P)} />;
  };

  WithProject.displayName = `withProject${Component.displayName}`;

  return WithProject;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useProject = () => {
  return useContext(ProjectContext);
};

export default ProjectProvider;
