import React, { useCallback, useMemo, useState } from 'react';
import useTranslation from 'helpers/useTranslation';
import { Column } from 'react-table';
import Api from 'api';
import { PaginationData, PaginationDataFilter } from 'api/types';
import { InvestorDocumentResponseDto } from 'api/types/investor/document';
import Table from 'components/Table/Table';
import InlineFileDownloadLink from 'components/InlineFileDownloadLink';
import { TableInitialSortBy } from 'api/types/common';

const INITIAL_SORT_ORDER: TableInitialSortBy[] = [
  {
    id: 'date',
    desc: true,
  },
];

const PersonalDocumentsTable: React.FC = () => {
  const { t, tHtml } = useTranslation();
  const [data, setData] = useState<PaginationData<InvestorDocumentResponseDto>>();

  const columns = useMemo<Column[] | any>(
    () => [
      {
        Header: t('common.created_at_date'),
        accessor: 'date',
        sortType: '',
      },
      {
        Header: t('document.name'),
        accessor: 'name',
      },
      {
        accessor: 'type',
        disableSortBy: true,
        Cell: (cell: any) => {
          const row = cell.row.original;
          return (
            <div className={'d-flex justify-content-end'}>
              <InlineFileDownloadLink url={row.url} type={row.type} />
            </div>
          );
        },
      },
    ],
    [t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    return Api.investor.documents
      .filterPersonalDocuments(request)
      .then((response) => setData(response));
  }, []);

  return (
    <div className={'mb-6'}>
      <h2 className={'mb-2'}>{tHtml('document.my')}</h2>
      <div>
        <Table
          columns={columns}
          data={data}
          onFetchData={fetchData}
          mobile={true}
          initialSortBy={INITIAL_SORT_ORDER}
        />
      </div>
    </div>
  );
};

export default PersonalDocumentsTable;
