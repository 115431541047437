import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalComponent, ModalComponentProps } from '../ModalComponent';
import useTranslation from 'helpers/useTranslation';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { Form, ModalBody, ModalHeader } from 'reactstrap';

import Api from 'api';
import { SocialSignUpRequestDto } from 'api/types/guest/social';
import { RouteList } from 'routes';
import { SocialNetworkFactory } from 'services/social/SocialNetworkFactory';
import { success } from 'services/toastr';
import CheckboxInput from 'components/Formik/CheckboxInput';
import SocialButton from 'components/Formik/SocialButton';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import ModalCloseButton from 'components/Modal/ModalCloseButton';
import TextInput from 'components/Formik/TextInput';
import { useAuth } from 'services/useAuth';

interface SocialLoginModalBodyProps {
  initialValues: SocialSignUpRequestDto;
}

export interface SocialLoginModalProps extends ModalComponentProps, SocialLoginModalBodyProps {}

const SocialSignUpModal: React.FC<SocialLoginModalProps> = ({ initialValues, ...props }) => {
  const { t, tHtml } = useTranslation();
  const history = useHistory();
  const { hideModal } = useGlobalModalContext();
  const { login } = useAuth();

  const SocialSignUpSchema = Yup.object().shape({
    tac: Yup.boolean().oneOf([true], t('validation.required')),
    direct_marketing: Yup.boolean(),
    affiliate_code: Yup.string().nullable(),
  });

  useEffect(() => {
    SocialNetworkFactory.setup(initialValues.social_network_type.toString());
  }, [initialValues.social_network_type]);

  const onSubmit = useCallback(
    async (request: SocialSignUpRequestDto, helpers: FormikHelpers<SocialSignUpRequestDto>) => {
      const sn = SocialNetworkFactory.get(request.social_network_type.toString());

      let externalResponse = undefined;

      try {
        externalResponse = await sn.signUp();
      } catch (e) {}

      if (!externalResponse) {
        return true;
      }

      //TODO Generic errors
      try {
        const response = await Api.guest.social.socialSignUp({
          ...request,
          token: externalResponse.token,
        });

        if (response?.access_token) {
          hideModal();
          await login(response.access_token, response.permissions);
          success(tHtml('authentication.registration_success'));

          // // TODO: route to initially intended page.
          history.push(RouteList.GUEST.AUTHENTICATION.REGISTER_MAIL_VERIFICATION);
        }
      } catch (e) {
        // TODO global err
        helpers.setErrors(e.response?.errors);
      }

      return;
    },
    [hideModal, history, login, tHtml],
  );

  return (
    <React.Fragment>
      <ModalComponent toggle={hideModal} {...props}>
        <div className={'social-modal'}>
          <Formik
            initialValues={initialValues}
            validateOnMount={false}
            validationSchema={SocialSignUpSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, values }) => (
              <Form className={'p-2 p-lg-5'} onSubmit={handleSubmit}>
                <ModalHeader className={'p-0 mb-5'} close={<ModalCloseButton />}>
                  {tHtml('authentication.registration_confirmation')}
                </ModalHeader>
                <div className={'mb-4'}>
                  <TextInput name="affiliate_code" />
                </div>
                <ModalBody className={'p-0'}>
                  <div className="mb-3">
                    <CheckboxInput
                      name="tac"
                      showPlaceholder={true}
                      customLabel={<div className={'ms-2'}>{tHtml('placeholder.tac')}</div>}
                    />
                  </div>
                  <div className="mb-3">
                    <CheckboxInput name="direct_marketing" showPlaceholder={true} />
                  </div>
                  <div className="mt-4">
                    <SocialButton type={'submit'} icon={`icon-${values.social_network_type}`}>
                      {tHtml('common.signup_with_' + values.social_network_type)}
                    </SocialButton>
                  </div>
                </ModalBody>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default SocialSignUpModal;
