import Client from 'api/services/client';
import Http from 'api/http';
import {
  ExternalMediaResponseDto,
  MoneyInBankCountriesResponseDto,
  MoneyInByBankRequestDto,
  MoneyInRedirectResponseDto,
  MoneyInRequestDto,
  MoneyOutRequestDto,
  StoreWalletBankAccountRequest,
  WalletInformationResponse,
} from 'api/types/common';
import { PaginationData, PaginationDataFilter } from '../../types';
import { OperationsResponseDto } from '../../types/shared/wallet';
import { AvailableGenericType } from 'components/Table/AdvanceFilters/ColumnFilter';
import { CommonDocumentTypeEnum } from 'helpers/enums/CommonDocumentTypeEnum';

class Wallet extends Client {
  constructor(http: Http) {
    super(http);

    this.api = {
      INFORMATION: this.protectedUrl(`${this.http.baseUrl}/wallet`),
      MONEY_IN_BANK_COUNTRIES: this.protectedUrl(
        `${this.http.baseUrl}/wallet/bank/money-in/countries`,
      ),
      MONEY_IN_BANK: this.protectedUrl(`${this.http.baseUrl}/wallet/bank/money-in/start`),
      MONEY_IN_START: this.protectedUrl(`${this.http.baseUrl}/wallet/money-in-start`),
      MONEY_OUT_START: this.protectedUrl(`${this.http.baseUrl}/wallet/money-out-start`),
      OPERATIONS: this.protectedUrl(`${this.http.baseUrl}/wallet/operations`),
      WALLET_BANK_ACCOUNT: this.protectedUrl(`${this.http.baseUrl}/wallet/bank-account`),
      AVAILABLE_OPERATION_TYPES: this.protectedUrl(`${this.http.baseUrl}/wallet/operations/types`),
      CREATE_VIRTUAL_IBAN: this.protectedUrl(`${this.http.baseUrl}/wallet/virtual-iban`),

      EXPORT_OPERATIONS: this.protectedUrl(`${this.http.baseUrl}/wallet/operations/export/{type}`),
    };
  }

  fetchWalletInformation = (): Promise<WalletInformationResponse> => {
    return this.http.get(this.api.INFORMATION);
  };

  storeWalletBankAccount = (
    request: StoreWalletBankAccountRequest,
  ): Promise<WalletInformationResponse> => {
    const formData = new FormData();

    Object.keys(request).forEach((key) => {
      const k = key as keyof StoreWalletBankAccountRequest;

      if (typeof request[k] == 'string') {
        formData.append(key, request[k] as string);
      } else {
        const file = request[k] as File;
        formData.append(key, file);
      }
    });

    return this.http.post(this.api.WALLET_BANK_ACCOUNT, formData);
  };

  moneyInStart = (request: MoneyInRequestDto): Promise<MoneyInRedirectResponseDto> => {
    return this.http.post(this.api.MONEY_IN_START, request);
  };

  moneyInByBankStart = (request: MoneyInByBankRequestDto): Promise<MoneyInRedirectResponseDto> => {
    return this.http.post(this.api.MONEY_IN_BANK, request);
  };

  moneyInByBankCountries = (): Promise<MoneyInBankCountriesResponseDto> => {
    return this.http.get(this.api.MONEY_IN_BANK_COUNTRIES);
  };

  moneyOutStart = (request: MoneyOutRequestDto): Promise<WalletInformationResponse> => {
    return this.http.post(this.api.MONEY_OUT_START, request);
  };

  operations = (request: PaginationDataFilter): Promise<PaginationData<OperationsResponseDto>> => {
    return this.http.get(this.api.OPERATIONS, { params: request });
  };

  operationsWithTypeFilter = (): Promise<PaginationData<OperationsResponseDto>> => {
    return this.http.get(this.api.OPERATIONS);
  };

  availableOperationTypes = (): Promise<AvailableGenericType[]> => {
    return this.http.get(this.api.AVAILABLE_OPERATION_TYPES);
  };

  createVirtualIban = (): Promise<WalletInformationResponse> => {
    return this.http.post(this.api.CREATE_VIRTUAL_IBAN);
  };

  exportOperations = (
    type: CommonDocumentTypeEnum,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<ExternalMediaResponseDto> => {
    const url = this.buildUrl(this.api.EXPORT_OPERATIONS, { type });
    return this.http.get(url, { params: request });
  };
}

export default Wallet;
