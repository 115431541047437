import React from 'react';
import { withRouter } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import { DataBlock } from 'components/DataBlock';
import UserProfileSection from 'scopes/user/components/UserProfileSection';
import { UserMenuEnum } from 'helpers/enums/UserMenuEnum';

const Password: React.FC = () => {
  const { tHtml } = useTranslation();

  return (
    <UserProfileSection
      menu={UserMenuEnum.Password}
      title={tHtml('menu.password')}
      buttonLink={RouteList.USER.EDIT.PASSWORD}
    >
      <div className={'content'}>
        <DataBlock title={tHtml('placeholder.password')} data={'**********'} />
      </div>
    </UserProfileSection>
  );
};

export default withRouter(Password);
