import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useTranslation from 'helpers/useTranslation';

import { RouteList } from 'routes';
import { registrationSteps } from './steps';

const FillLaterWizard: React.FC = () => {
  const { tHtml } = useTranslation();
  const location = useLocation();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const step = registrationSteps.find((x) => x.route === location.pathname);
    if (step === undefined || step.showFillLater === undefined) {
      return;
    }

    setShow(step.showFillLater);
  }, [location, setShow]);

  if (show) {
    return (
      <Link className={'align-self-center'} to={RouteList.HOME}>
        {tHtml('common.fill_later')}
      </Link>
    );
  } else {
    return null;
  }
};

export default FillLaterWizard;
