class Queue {
  private items: Array<number>;

  constructor() {
    this.items = [];
  }

  enqueue() {
    // console.log('adding in queu');
    this.items.push(Math.random());
  }

  dequeue() {
    // console.log('rm from q');

    if (this.isEmpty()) {
      return null;
    }
    return this.items.shift();
  }

  isEmpty() {
    return this.items.length === 0;
  }

  size() {
    return this.items.length;
  }

  front() {
    if (this.isEmpty()) {
      return null;
    }
    return this.items[0];
  }

  clear() {
    this.items = [];
  }
}

export default Queue;
