import React, { useState } from 'react';
import useTranslation from 'helpers/useTranslation';

interface Props {
  number: number;
}

const Watchers: React.FC<Props> = ({ number }) => {
  const [show, setShow] = useState<boolean>(true);
  const { tHtml } = useTranslation();
  return (
    <div className={'watchers'}>
      <div className={show ? 'watchers-wrapper' : 'watchers-wrapper hidden'} hidden={!show}>
        <div>{tHtml('projects.currently_watching', { value: number })}</div>
        <i className={'icon icon-close-sm cursor pointer'} onClick={() => setShow(false)} />
      </div>
    </div>
  );
};

export default Watchers;
