import {
  InvestorAutoInvestmentConfigPackEnum,
  InvestorAutoInvestmentFullConfig,
  InvestorAutoStrategy,
} from 'api/types/investor/investment';
import { SwitchInput } from 'components/Inputs';
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { StrategyForm } from './StrategyForm';
import useTranslation from 'helpers/useTranslation';
import { StrategyStatistics } from './StrategyStatistics';
import Api from 'api';
import { Form, Formik, FormikHelpers } from 'formik';
import { success } from 'services/toastr';
import { useGlobalModalContext } from 'components/Modal/GlobalModal';
import ConfirmationModal from 'components/Modal/Modals/ConfirmationModal';
import { useAutoInvestmentData } from '../AutoInvestmentProvider';

const StrategyListItem: React.FC<{
  strategy: InvestorAutoStrategy;
  autoInvestmentConfig: InvestorAutoInvestmentFullConfig;
  defaultOpen?: boolean;
}> = ({ strategy, autoInvestmentConfig, defaultOpen = false }) => {
  const { tHtml, t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { updateAutoInvestmentData } = useAutoInvestmentData();

  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const [isStrategy, setIsStrategy] = useState<boolean>(true);

  const getSelectedConfig = () => {
    const amount = strategy.amount ?? 0;
    let pack;
    if (
      amount >= autoInvestmentConfig[InvestorAutoInvestmentConfigPackEnum.SECOND_PACK].min_amount
    ) {
      pack = InvestorAutoInvestmentConfigPackEnum.SECOND_PACK;
    } else if (
      amount >= autoInvestmentConfig[InvestorAutoInvestmentConfigPackEnum.FIRST_PACK].min_amount
    ) {
      pack = InvestorAutoInvestmentConfigPackEnum.FIRST_PACK;
    } else {
      pack = InvestorAutoInvestmentConfigPackEnum.BASIC_PACK;
    }

    return pack;
  };

  const onSubmit = React.useCallback(
    async (request: { is_active: boolean }, helpers: FormikHelpers<{ is_active: boolean }>) => {
      try {
        showModal(
          <ConfirmationModal
            title={
              request.is_active
                ? tHtml('investments.auto_investment.activate_strategy_confirm')
                : tHtml('investments.auto_investment.deactivate_strategy_confirm')
            }
            onClose={() => helpers.setValues({ is_active: !!strategy.activated_at })}
            onSubmit={async () => {
              await Api.investor.investments
                .updateStrategyStatusAutoInvestment(request, strategy.id)
                .then((response) => {
                  success(
                    tHtml(
                      request.is_active
                        ? 'investments.auto_investment.updated_successfully'
                        : 'investments.auto_investment.deactivated_successfully',
                    ),
                  );
                  updateAutoInvestmentData(response);
                });
            }}
            backdrop={'static'}
          />,
        );
      } catch (e) {
        helpers.setValues({ is_active: !!strategy.activated_at });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [strategy.activated_at, strategy.id, tHtml],
  );

  return (
    <div className={'strategy-item'}>
      <div className={'collapsable-block__header'} onClick={() => setIsOpen(!isOpen)}>
        <h2 className={'d-flex justify-content-between align-items-center flex-wrap gap-3'}>
          <div className={'d-flex align-items-center gap-3 cursor'} id="sortable-handle">
            <i className={`icon icon-grip`} />
            <span className={'strategy-item-name pe-4 text-break'}>{strategy.name}</span>
          </div>
          <div className="d-flex align-items-center gap-5">
            <Formik
              enableReinitialize={true}
              initialValues={{ is_active: !!strategy.activated_at }}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, submitForm, handleSubmit, values }) => (
                <Form onSubmit={handleSubmit}>
                  <SwitchInput
                    label={
                      values.is_active
                        ? t('placeholder.strategy_active')
                        : t('placeholder.strategy_inactive')
                    }
                    name={'is_active'}
                    isDisabled={isSubmitting}
                    onChange={submitForm}
                  />
                </Form>
              )}
            </Formik>
            <span>
              {isOpen ? (
                <i className={'icon icon-arrow-up'} />
              ) : (
                <i className={'icon icon-arrow-down'} />
              )}
            </span>
          </div>
        </h2>
      </div>
      <Collapse isOpen={isOpen} className={'collapsable-block__content'}>
        <div className={'pb-4 pt-4'}>
          <div
            className={'d-flex align-items-center flex-nowrap tab-container justify-content-end'}
          >
            <div className="d-flex gap-3 tabs">
              <button
                className={`filter-button ${isStrategy ? 'active' : ''}`}
                onClick={() => setIsStrategy(true)}
              >
                {tHtml('investments.auto_investment.strategy')}
              </button>
              <button
                className={`filter-button ${isStrategy ? '' : 'active'}`}
                onClick={() => setIsStrategy(false)}
              >
                {tHtml('investments.auto_investment.statistics')}
              </button>
            </div>
          </div>

          {isStrategy ? (
            <StrategyForm
              strategy={strategy}
              autoInvestmentConfig={autoInvestmentConfig}
              selectedConfig={getSelectedConfig()}
            />
          ) : (
            <StrategyStatistics strategyId={strategy.id} />
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default StrategyListItem;
