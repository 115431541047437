import React from 'react';
import { Container, Navbar, NavbarToggler } from 'reactstrap';

import ResponsiveLogo from 'components/Logo/ResponsiveLogo';
import {
  StyledMobileBody,
  StyledMobileHeader,
  StyledMobileHeaderEnd,
  StyledMobileHeaderStart,
} from './HeaderMobile.style';

interface Props {
  toggleMenuCallback: () => unknown;
}

const HeaderMobile: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <header>
        <StyledMobileHeader>
          <div className={'header-line'} />
          <Navbar expand={'xl'}>
            <Container>
              <StyledMobileBody>
                <StyledMobileHeaderStart>
                  <ResponsiveLogo />
                </StyledMobileHeaderStart>
                <StyledMobileHeaderEnd>
                  <NavbarToggler onClick={props.toggleMenuCallback} className={'sidebar-toggler'} />
                </StyledMobileHeaderEnd>
              </StyledMobileBody>
            </Container>
          </Navbar>
        </StyledMobileHeader>
      </header>
    </React.Fragment>
  );
};

export default HeaderMobile;
