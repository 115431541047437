import { TFunctionResult } from 'i18next';
import React from 'react';

import {
  StyledDataBlockLarge,
  StyledDataBlockLargeContent,
  StyledDataBlockLargeTitle,
} from './DataBlockLarge.style';

export interface DataBlockLargeProps extends StyledDataBlockLargeProps {
  title: string;
  content: string | number | TFunctionResult | null;
}

export interface StyledDataBlockLargeProps {
  isInline?: boolean;
}

const DataBlockLarge: React.FC<DataBlockLargeProps> = ({ title, content, isInline = false }) => {
  return (
    <StyledDataBlockLarge isInline={isInline}>
      <StyledDataBlockLargeTitle isInline={isInline}>{title}</StyledDataBlockLargeTitle>
      <StyledDataBlockLargeContent>{content !== null ? content : '-'}</StyledDataBlockLargeContent>
    </StyledDataBlockLarge>
  );
};

export default DataBlockLarge;
