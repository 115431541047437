import React, { useState } from 'react';

import { RatingEnum } from 'helpers/enums/RatingEnum';
import SelectBox, { SelectBoxProps } from 'components/Formik/SelectBox';
import { SelectOption } from 'components/Formik/SelectInput';

const MultiSelectProjectRatingInput: React.FC<Omit<SelectBoxProps, 'selectOptions'>> = (props) => {
  const [optionsRatings] = useState<SelectOption[]>(() =>
    Object.values(RatingEnum).map((value) => {
      return {
        label: value,
        value: value,
      };
    }),
  );

  return (
    <>
      <SelectBox {...props} selectOptions={optionsRatings} closeMenuOnSelect={false} />
    </>
  );
};

export default MultiSelectProjectRatingInput;
